import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { TabMenu } from 'primereact/tabmenu';
import { useHistory } from 'react-router-dom'


const ReportsMenu = ({ activeItem }) => {
	const history = useHistory()
	const { t } = useTranslation(['labels']);
	const [items, setItems] = useState([])
	const [active, setActive] = useState(items[0])

	useEffect(() => {
		let flag = false;
		let initItems = [];
		// getUserConfig().then(value=> {
		// console.log(value)
		//setFlag(value.permanentBookingFlag == 'Y' ? true: false  )
		//	value.permanentBookingFlag === 'Y' ? flag = true: flag = false
		initItems = [
			{
				label: t('AWB charges report'),
				command: (event) => {
					history.push('/reports/awb-charges')
				},
				className: 'awb-charges',
			},
			// Commented for hiding DAP tab for prod release on 19-Jun-2023
			/*{
				label: t('Delivered as planned report'),
				command: (event) => {
					history.push('/reports/dap')
				},
				className: 'flown-as-booked',
			},*/
		]
		if (activeItem !== null) {
			setActive(initItems[activeItem])
			let menuItems = [...initItems]
			menuItems[
				activeItem
			].className = `${menuItems[activeItem].className} p-highlight`
			setItems(menuItems)
		}
		//})
	}, [])


	useEffect(() => {
		if (activeItem !== null && active !== undefined) {
			setActive(items[activeItem])
			let menuItems = [...items]
			menuItems[
				activeItem
			].className = `${menuItems[activeItem].className} p-highlight`
			setItems(menuItems)
		}
	}, [activeItem])

	const onTabChange = (e) => { }

	return (
		<div>
			<div className='booking-heading'>
				<div>
					<h3 className='page-heading mt-5'>{t('COMMON.REPORTS')}</h3>
				</div>
			</div>
			<div className='portal-tab-menu'>
				<TabMenu
					className='tab-booking'
					model={items.filter(item => item)}
					onTabChange={(e) => onTabChange(e)}
					activeItem={active}
				/>
			</div>
		</div>
	)
}

export default ReportsMenu;