import { CSN_FLIGHT_SUMMARY_FILTER, CSN_FLIGHT_FFM_SUMMARY_FILTER } from '../constants'

const initialState = {
	flightSummaryFilter: null,
	flightSummaryFilterFFM: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case CSN_FLIGHT_SUMMARY_FILTER.APPLY:
			return {
				...state,
				flightSummaryFilter: action.payload,
			}
		case CSN_FLIGHT_SUMMARY_FILTER.CLEAR:
			return {
				...state,
				flightSummaryFilter: action.payload,
			}
		case CSN_FLIGHT_FFM_SUMMARY_FILTER.APPLY:
			return {
				...state,
				flightSummaryFilterFFM: action.payload,
			}
		case CSN_FLIGHT_FFM_SUMMARY_FILTER.CLEAR:
			return {
				...state,
				flightSummaryFilterFFM: action.payload,
			}
		default:
			return state
	}
}
