import React, { useEffect, useState } from 'react';
import './Admin.scss';
import XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { SheetJSFT } from './Types';
import { Dropdown } from '../../common/DropDown';
import { ADMIN_MASTER_TYPES, TERMS_AND_CONDITIONS_LEVEL_TYPES } from '../../constants';
import { saveTemplateError } from '../../redux/actions/saveTemplateActions';
import { Alert } from '../../common/Alert';
import { ExportAdmindata } from './ExportAdmindata';
import ExportTermsConditionsData from './ExportTermsConditionsData';
import { getTermsConditionsResponse, getTermsCountries } from '../../services/adminMasterServices';
import LoadingIndicator from '../LoadingIndicator';
import CustomSelect from '../../common/CustomSelect/CustomSelect';

//Faheem
const ViewTerms = ({
    getSaveTermsMasterResponse,
    saveTermsMasterResponse,
    saveTermsMasterError,
    clearSaveTermsMasterResponse
}) => {

    const [fileName, setFileName] = useState(undefined);
    const [masterType, setMasterType] = useState('Select');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [mappedResponse, setMappedResponse] = useState(undefined);
    const [error, setError] = useState(null);
    const [fieldError, setFieldError] = useState('');
    const [fieldCountryError, setFieldCountryError] = useState('');
    const [levelError, setLevelError] = useState('');
    const [pdfData, setPdfData] = useState({
        pdfVal: []
    });
    const [countryData, setCountryData] = useState({
        countryVal: []
    })
    const [isLoading, setIsLoading] = useState(false);
    //=============================================================================//

    const handleChange = (files) => {
        setFieldError('')
        setFieldCountryError('')
        var f = files[0]; // FileList object
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (e) => {
            var binaryData = e.target.result;
            //Converting Binary Data to base 64
            var base64String = window.btoa(binaryData);
            //showing file converted to base64
            setFileName(f?.name);
            setMappedResponse(base64String);

        }
        reader.readAsBinaryString(f);
    }


    const handleUpload = (type) => {
        if (type === "Select") {
            setLevelError('Choose any level before upload');
        } else if (type === 'Global') {
            const payload = {
                "requestId": uuidv4(),
                "termsAndConditions": {
                    "level": "Global",
                    "locationCode": "General",
                    "fileName": fileName,
                    "termsAndConditionsFile": mappedResponse
                }
            }
            mappedResponse != undefined ? getSaveTermsMasterResponse(payload) : setFieldError("Choose any pdf file to upload")
        } else if (type === "Country") {
            const payload = {
                "requestId": uuidv4(),
                "termsAndConditions": {
                    "level": "Country",
                    "locationCode": selectedCountry?.countryCode,
                    "fileName": fileName,
                    "termsAndConditionsFile": mappedResponse
                }
            }
            if (mappedResponse === undefined && (selectedCountry === undefined || selectedCountry === null)) {
                setFieldError("Choose any pdf file to upload")
                setFieldCountryError("Select country")
            } else if (mappedResponse != undefined) {
                if (selectedCountry != null) {
                    getSaveTermsMasterResponse(payload)
                } else {
                    setFieldCountryError("Select country")
                }
            } else if (selectedCountry != null) {
                if (mappedResponse != undefined) {
                    getSaveTermsMasterResponse(payload)
                } else {
                    setFieldError("Choose any pdf file to upload")
                }
            } else {

            }

        } else return;
    }

    const handleAlertClose = () => {
        setError('')
        clearSaveTermsMasterResponse();
        setFileName(undefined);
        setMasterType('Select');
        setMappedResponse([]);
        setTimeout(async () => {
            setIsLoading(true)
            try {
                await getTermsConditionsResponse()
                    .then((response) => {
                        setIsLoading(false)
                        if (response && response.termsAndConditionsList) {
                            return setPdfData({
                                pdfVal: response.termsAndConditionsList
                            })
                        } else {
                            return null
                        }
                    })
                setIsLoading(false)
            } catch (error) {
                console.log(error.message)
                return
            } finally {
                setIsLoading(false)
                setFileName(undefined);
                setMasterType('Select');
                setSelectedCountry('');
                setMappedResponse(undefined);
                setError(null);
                setFieldError('');
                setFieldCountryError('');
            }
            // window.location.reload(true)
        }, 1000);
    }

    const handleAlertCloseSuccess = () => {
        // setError('')
        clearSaveTermsMasterResponse();
        setFileName(undefined);
        setMasterType('Select');
        setMappedResponse([]);
        setTimeout(async () => {
            setIsLoading(true)
            try {
                await getTermsConditionsResponse()
                    .then((response) => {
                        setIsLoading(false)
                        if (response && response.termsAndConditionsList) {
                            return setPdfData({
                                pdfVal: response.termsAndConditionsList
                            })
                        } else {
                            return null
                        }
                    })
                setIsLoading(false)
            } catch (error) {
                console.log(error.message)
                return
            } finally {
                setIsLoading(false)
                setFileName(undefined);
                setMasterType('Select');
                setSelectedCountry('');
                setMappedResponse(undefined);
                setError(null);
                setFieldError('');
                setFieldCountryError('');
            }
            // window.location.reload(true)
        }, 1000);
    }

    useEffect(() => {
        ; (async () => {
            setIsLoading(true)
            try {
                await getTermsConditionsResponse()
                    .then((response) => {
                        setIsLoading(false)
                        if (response && response.termsAndConditionsList) {
                            return setPdfData({
                                pdfVal: response.termsAndConditionsList
                            })
                        } else {
                            return null
                        }
                    })
                setIsLoading(false)
            } catch (error) {
                console.log(error.message)
                return
            } finally {
                setIsLoading(false)
            }
        })();
        ; (async () => {
            setIsLoading(true)
            try {
                await getTermsCountries()
                    .then((response) => {
                        setIsLoading(false)
                        if (response && response) {
                            return setCountryData({
                                countryVal: response
                            })
                        } else {
                            return null
                        }
                    })
                setIsLoading(false)
            } catch (error) {
                console.log(error.message)
                return
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])
    const selectCountryCallback = (val) => {
        setFieldCountryError('');
        setSelectedCountry(val);
    }

    return (
        <>
            {error != null &&
                <div className="master-alert-wrap">
                    <Alert
                        isError={error != null}
                        showAlert={true}
                        message={error != null && error}
                        onAlertClose={handleAlertClose}
                        setTimeout={true}
                    />
                </div>}
            {(saveTermsMasterResponse?.status === "S") &&
                <div className="master-alert-wrap">
                    <Alert
                        isError={false}
                        showAlert={true}
                        message={`Successfully uploaded`}
                        onAlertClose={handleAlertCloseSuccess}
                        setTimeout={true}
                    />
                </div>
            }
            <div className='admin-page__upload-panel'>
                <div className='form-group col-md-3 pr-0 pr-md-3'>
                    <div className='form-group'>
                        <label htmlFor='masterType' className='form-item__label'>
                            Level
                        </label>
                        <div style={{ height: "45px" }}>
                            <Dropdown
                                id='masterType'
                                className='cust-dropdown'
                                value={masterType}
                                options={TERMS_AND_CONDITIONS_LEVEL_TYPES}
                                placeholder="Select"
                                onChange={(e) => {
                                    setFieldError('')
                                    setLevelError('')
                                    setMasterType(e.target.value)
                                }}
                            >
                            </Dropdown>
                            <span className='search-panel__errormsg mt-2'>
                                {levelError != '' ? levelError : ''}
                            </span>
                        </div>
                    </div>
                </div>
                {masterType === 'Country' &&
                    <div className='form-group col-md-3'>
                        <div className='form-group'>
                            <label htmlFor='masterType' className='form-item__label'>
                                {masterType}
                            </label>
                            <div className='form-item' style={{ height: "45px" }}>
                                <CustomSelect
                                    selectedCountry={selectedCountry?.countryCode}
                                    setSelectedCountry={selectCountryCallback}
                                />
                                <span className='search-panel__errormsg mt-2'>
                                    {fieldCountryError != '' ? fieldCountryError : ''}
                                </span>
                            </div>
                        </div>
                    </div>}
                <div className='choose-file-master-panel'>
                    <div className='selected-file-panel'>
                        <label for='fileButton' className='button-choose'>
                            <div className='choose-file-button-panel'>
                                Choose file
                            </div>
                        </label>
                        <div className='selected-showcase'>
                            {fileName != undefined ? fileName : ''}
                        </div>
                    </div>
                    <input
                        type='file'
                        id='fileButton'
                        accept="application/pdf"
                        onChange={(e) => {
                            handleChange(e.target.files)
                            e.target.value = ''
                        }}
                        style={{ display: 'none' }}
                    />
                    <span className='search-panel__errormsg mt-0' style={{ height: "6px" }}>
                        {fieldError != '' ? fieldError : ''}
                    </span>
                </div>
                <button className='l-btn--primary' onClick={() => handleUpload(masterType)}>
                    Upload
                </button>
            </div>
            {!isLoading ?
                <div className='admin-page__download-panel'>
                    <label className='heading'>Terms & Conditions list</label>
                    <div className='list-container'>
                        {pdfData?.pdfVal?.map((terms, key) => (
                            <div className='master-list-items'>
                                <div className='master-list-items__first-element'>
                                    <p>{terms?.level}</p>
                                </div>
                                <div className='master-list-items__first-element'>
                                    {terms?.countryName ? terms?.countryName : ''}
                                </div>
                                <div className='master-list-items__first-element'>
                                    {terms?.fileName}
                                </div>
                                <div className='master-list-items__second-element'>
                                    <p>
                                        <ExportTermsConditionsData
                                            pdfvalue={terms?.fileName} />
                                    </p>
                                </div>
                            </div>))}
                    </div>
                </div> : <LoadingIndicator />}
        </>
    )
}

export default ViewTerms;