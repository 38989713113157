import React, { useState } from 'react'
import './route-details.scss'
import siteLogo from '../../../assets/images/logo.png'
import flightIcon from '../../../assets/images/cargo_flight.svg'
import truckIcon from '../../../assets/images/cargo_truck.svg'
import moment from 'moment'
import {
	getWeightUnit,
	getDecimalFormattedNumber,
	WEIGHT_UNIT,
} from '../../../constants'
import plusIcon from '../../../assets/images/plus.svg'
import minusIcon from '../../../assets/images/minus.svg'
import downArrow from '../../../assets/images/down-arrow.png'
import upArrow from '../../../assets/images/up-arrow.png'
import { useTranslation } from 'react-i18next'
const RouteDetails = ({
	selectedFlight,
	searchFormData,
	viewFlag,
	isDetailsUpdate,
	showRates,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels','errors'])
	const [isExpanded, setExpanded] = useState(false)
	const [isAddrExpanded, setAddrExpanded] = useState(false)

	const [viewDetails, setViewDetails] = useState(isDetailsUpdate ? false : true)
	const routes = selectedFlight.flightSegmentList
		? selectedFlight.flightSegmentList.length - 1
		: 0
	const departureDate = selectedFlight.departureDateTime
		? moment(selectedFlight.departureDateTime, 'DD-MMM-YYYY')
		: ''
	const departureTime = selectedFlight.departureDateTime
		? moment(selectedFlight.departureDateTime)
		: ''
	const arrivalTime = selectedFlight.arrivalDateTime
		? moment(selectedFlight.arrivalDateTime)
		: ''
	const arrivalDate = selectedFlight.arrivalDateTime
		? moment(selectedFlight.arrivalDateTime,'DD-MMM-YYYY')
		: ''
	const origin = selectedFlight.origin
	const destination = selectedFlight.destination
	const isHideRate = (selectedFlight.isForceQueued ||
					   selectedFlight.overHangShipment ||
		selectedFlight.bigShipment)
			? true
			: false
	const getFormatedTime = (value) => {
		let formattedTime = ''
		if (value) {
			let tempArray = value.split(':')
			formattedTime =
				parseInt(tempArray[0], 10) +
				'Hrs ' +
				(tempArray[1] && tempArray[1] != '00' ? tempArray[1] + ' Min' : '')
		}
		return formattedTime
	}

	const handleToggle = () => {
		setViewDetails(!viewDetails)
	}

	return (
		<>
			<div className='quote-details__filghtDetails'>
				{isDetailsUpdate && (
					<button className='toggle-route-details' onClick={handleToggle}>
						<img
							src={viewDetails ? minusIcon : plusIcon}
							className='image-wrapper'
							alt=''
						></img>
					</button>
				)}
				<div className='col-12 flight-details__summary'>
					<span className='d-flex d-xl-none flight-details__summary-trigger' onClick={() => setExpanded(!isExpanded)}></span>
					<div className='flight-details__summary-route'>
						<div className='logo d-none d-xl-block'>
							<img src={siteLogo} alt='Etihad' title='Etihad' />
						</div>
						<div className='flight-details__route'>
							{routes ? (
									<span className='flight-details__routes'>
										<span className='flight-details__routes-arrival d-inline-block d-lg-none'>{departureTime.format('HH:mm')}</span>
										<span className='flight-details__routes-count'>{routes} {t('COMMON.STOP')}</span>
										<span className='flight-details__routes-depart d-inline-block d-lg-none'>{arrivalTime.format('HH:mm')}</span>
									</span>
							) : (
								<>
								<span className='flight-details__routes'>
									<span className='flight-details__routes-arrival d-inline-block d-lg-none'>{departureTime.format('HH:mm')}</span>
									<span className='flight-details__routes-count'></span>
									<span className='flight-details__routes-depart d-inline-block d-lg-none'>{arrivalTime.format('HH:mm')}</span>
								</span>
								</>
							)}
							<ul className='flight-details__timing'>
								<li className='flight-details__departure'>
									<strong className="d-none d-lg-inline-block">{departureTime.format('HH:mm')}</strong>
									<span>{departureDate.format('ddd DD MMM')}</span>
									<span>{origin}</span>
								</li>
								<div className='flight-details__connection'>
									<div className='origin-destination-icon'></div>
									<div className='transit-line' />
									<div className='iconsSection'>
										<i>
											{selectedFlight.flightSegmentList[0].flightType != 'T' ? (
												<img src={flightIcon} alt='Flight' title='Flight' />
											) : (
												<img src={truckIcon} alt='Flight' title='Flight' />
											)}
										</i>
									</div>
									<div className='transit-line' />
									<div className='origin-destination-icon'></div>
								</div>

								<li className='flight-details__arrival'>
									<strong className="d-none d-lg-inline-block">{arrivalTime.format('HH:mm')}</strong>
									<span>{arrivalDate.format('ddd DD MMM')}</span>
									<span>{destination}</span>
								</li>
							</ul>
							<span className='d-none d-lg-block flight-details__elapse'>
								{selectedFlight.cumulativeJourneyTime
									? getFormatedTime(selectedFlight.cumulativeJourneyTime)
									: ''}
							</span>
						</div>
					</div>
					{showRates && <>
					{viewFlag && selectedFlight.ratingDetails && selectedFlight.ratingDetails.length &&
						Number(selectedFlight.ratingDetails[0]?.grandTotal)
						&& selectedFlight.ratingDetails[0].totalRate > 0
						&& !isHideRate ? (
						<div className='flight-details__right'>
							<div className='flight-details__right__sub__pricing'>
								<div className='flight-details__right__sub__amount'>
									<label>
										{selectedFlight.ratingDetails[0].currency}{' '}
										{getDecimalFormattedNumber(
											selectedFlight.ratingDetails[0].grandTotal
										)}
									</label>
								</div>
								{selectedFlight.ratingDetails[0].totalFreightRate !== 0 ?
									<div className='flight-details__right__sub__rate'>
										<label>
											{selectedFlight.ratingDetails[0].currency}{' '}
											{getDecimalFormattedNumber(
												selectedFlight.ratingDetails[0].totalFreightRate
											)}
											{'/'}
											{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
										</label>
									</div> : <div></div>
								}
							</div>
							<span className='flight-details__right__arrow d-flex d-lg-none'>
								<i>
									{isExpanded ? (
										<img src={upArrow} alt='Flight' title='Flight' />
									) : (
										<img src={downArrow} alt='Flight' title='Flight' />
									)}
								</i>
							</span>
						</div>
					) : (
						<div className='flight-details__right'>
							<div className='flight-details__right__sub__pricing no_pricing'>
								<div className='d-flex d-xl-none no-rates pr-3'>
										<span>{t('COMMON.NO_RATES')}</span>
								</div>
								<div className='d-flex d-xl-none no-instant-booking mb-0'>
									<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
								</div>
							</div>
							<span className='flight-details__right__arrow d-flex d-lg-none'>
								<i>
									{isExpanded ? (
										<img src={upArrow} alt='Flight' title='Flight' />
									) : (
										<img src={downArrow} alt='Flight' title='Flight' />
									)}
								</i>
							</span>
						</div>
					)}
					</>}
				</div>
				
				{viewDetails && (
					<div className='col-12 d-none d-xl-flex flight-details__expandedDetails'>
						<div className='form-row'>
						<div className='col-12 col-lg-6 flight-details__segment-info'>
							{selectedFlight && selectedFlight.flightSegmentList.length ? (
								selectedFlight.flightSegmentList.map((segment, index) => {
									return (
										<>
											<div className='flight-details__routeDetails mb-2'>
												<div className='flight-details__flightNo'>
													<div>
														{segment.flightCarrierCode}
														{segment.flightNumber}
													</div>
													<div>{segment.aircraftType}</div>
												</div>
												<div className='flight-details__route'>
													<ul className='flight-details__timing'>
														<li className='flight-details__departure'>
															<strong>
																{moment(segment.departureDateTime).format(
																	'HH:mm'
																)}
															</strong>
															<span>
																{moment(segment.departureDateTime, 'DD-MMM-YYYY').format(
																	'ddd DD MMM'
																)}
															</span>
															<span>{segment.segmentOrigin}</span>
														</li>
														<div className='flight-details__connection'>
															<div className='origin-destination-icon'></div>
															<div className='transit-line' />
															<div className='iconsSection'>
																<i>
																	{selectedFlight.flightSegmentList[0]
																		.flightType != 'T' ? (
																		<img
																			src={flightIcon}
																			alt='Flight'
																			title='Flight'
																		/>
																	) : (
																		<img
																			src={truckIcon}
																			alt='Flight'
																			title='Flight'
																		/>
																	)}
																</i>
															</div>
															<div className='transit-line' />
															<div className='origin-destination-icon'></div>
														</div>

														<li className='flight-details__arrival'>
															<strong>
																{moment(segment.arrivalDateTime).format('HH:mm')}
															</strong>
															<span>
																{moment(segment.arrivalDateTime,'DD-MMM-YYYY').format(
																	'ddd DD MMM'
																)}
															</span>
															<span>{segment.segmentDestination}</span>
														</li>
													</ul>
													<span className='flight-details__elapse'>
															{segment.flyingHours
																? getFormatedTime(segment.flyingHours)
																: ''}
													</span>
												</div>
											</div>
												{segment.segmentDestination != destination &&
													segment.layoverTime ? (
													<div className='flight-details__layover'>
														<div className='segment-transit-line' />
														<span>
															{segment.layoverTime} Hrs in{' '}
															{segment.segmentDestination}
														</span>
														<div className='segment-transit-line' />
													</div>
												) : (
													<div className='flight-details__layover mb-4'></div>
												)}
										</>
									)
								})
							) : (
								<></>
							)}
						</div>
						<div className='col-12 col-lg-6 flight-details__more-info d-none d-lg-block'>
							
							<div className='form-row address-container'>
								<div className='col-12 col-md-6 address-info pr-3'>
									<h4>{t('COMMON.DROP_OFF')}</h4>
									<div className='details'>
										{selectedFlight && selectedFlight.dropOffAddress ? 
											(<>
												{selectedFlight.dropOffAddress.name
													? selectedFlight.dropOffAddress.name
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.streetAndHouseNumber
													? selectedFlight.dropOffAddress.streetAndHouseNumber
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.country
													? selectedFlight.dropOffAddress.country
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.city
													? selectedFlight.dropOffAddress.city
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.postCode
													? selectedFlight.dropOffAddress.postCode
													: ''}
											</>) : 
											(<>
												-
											</>)
										}
									</div>
								</div>
								<div className='col-12 col-md-6 address-info address-info-pickup pl-4'>
									<h4>{t('COMMON.PICK_UP')}</h4>
									<div className='details'>
										{
											selectedFlight && selectedFlight.pickUpAddress ?
												(<>
													{selectedFlight.pickUpAddress.name
														? selectedFlight.pickUpAddress.name
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.streetAndHouseNumber
														? selectedFlight.pickUpAddress.streetAndHouseNumber
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.country
														? selectedFlight.pickUpAddress.country
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.city
														? selectedFlight.pickUpAddress.city
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.postCode
														? selectedFlight.pickUpAddress.postCode
														: ''}
												</>) : 
												(<>
													-
												</>)
										}
									</div>
								</div>
							</div>
							<div className='form-row mt-3 lat-container'>
								{selectedFlight.lastAcceptanceTime && (
									<div className='col-12 col-md-6 lat-info pr-3'>
										<h4>
											LAT (<span>{t('CHOOSE_FLT.LAT_FILTER')}</span>)
										</h4>
										<div className='details'>
											{moment(selectedFlight.lastAcceptanceTime, 'DD-MMM-YYYY').format(
												'ddd, DD MMM'
											)}{' '}
											<span className='time-field'>
												{' '}
												{moment(selectedFlight.lastAcceptanceTime).format(
													'HH:mm'
												)}{' '}
											</span>
										</div>
									</div>
								)}
								{selectedFlight.earliestDeliveryTime && (
									<div className='col-12 col-md-6 toa-info pl-4'>
										<h4>
											TOA (<span>{t('CHOOSE_FLT.TOA_FILTER')}</span>)
										</h4>
										<div className='details'>
											{moment(selectedFlight.earliestDeliveryTime,'DD-MMM-YYYY').format(
												'ddd, DD MMM'
											)}{' '}
											<span className='time-field'>
												{' '}
												{moment(selectedFlight.earliestDeliveryTime).format(
													'HH:mm'
												)}{' '}
											</span>
										</div>
									</div>
								)}
							</div>
							{/* <div className='toa-container'>
							</div>*/}
						</div>
					</div>
					</div>
				)}
				{isExpanded && viewDetails && (
					<div className='col-12 d-block d-lg-none flight-details__expandedDetails'>
						<div className='form-row'>
						<div className='col-12 col-lg-6 flight-details__segment-info'>
							{selectedFlight && selectedFlight.flightSegmentList.length ? (
								selectedFlight.flightSegmentList.map((segment, index) => {
									return (
										<>
											<div className='flight-details__routeDetails mb-2'>
												<div className='flight-details__flightNo'>
													<div>
														{segment.flightCarrierCode}
														{segment.flightNumber}
													</div>
													<div>{segment.aircraftType}</div>
												</div>
												<div className='flight-details__route'>
													<ul className='flight-details__timing'>
														<li className='flight-details__departure'>
															<strong>
																{moment(segment.departureDateTime).format(
																	'HH:mm'
																)}
															</strong>
															<span>
																{moment(segment.departureDateTime, 'DD-MMM-YYYY').format(
																	'ddd DD MMM'
																)}
															</span>
															<span>{segment.segmentOrigin}</span>
														</li>
														<div className='flight-details__connection'>
															<div className='origin-destination-icon'></div>
															<div className='transit-line' />
															<div className='iconsSection'>
																<i>
																	{selectedFlight.flightSegmentList[0]
																		.flightType != 'T' ? (
																		<img
																			src={flightIcon}
																			alt='Flight'
																			title='Flight'
																		/>
																	) : (
																		<img
																			src={truckIcon}
																			alt='Flight'
																			title='Flight'
																		/>
																	)}
																</i>
															</div>
															<div className='transit-line' />
															<div className='origin-destination-icon'></div>
														</div>

														<li className='flight-details__arrival'>
															<strong>
																{moment(segment.arrivalDateTime).format('HH:mm')}
															</strong>
															<span>
																{moment(segment.arrivalDateTime,'DD-MMM-YYYY').format(
																	'ddd DD MMM'
																)}
															</span>
															<span>{segment.segmentDestination}</span>
														</li>
													</ul>
													<span className='flight-details__elapse'>
															{segment.flyingHours
																? getFormatedTime(segment.flyingHours)
																: ''}
													</span>
												</div>
											</div>
												{segment.segmentDestination != destination &&
													segment.layoverTime ? (
													<div className='flight-details__layover'>
														<div className='segment-transit-line' />
														<span>
															{segment.layoverTime} Hrs in{' '}
															{segment.segmentDestination}
														</span>
														<div className='segment-transit-line' />
													</div>
												) : (
													<div className='flight-details__layover mb-4'></div>
												)}
										</>
									)
								})
							) : (
								<></>
							)}
						</div>
						<div className='col-12 col-md-6 flight-details__more-info d-none d-lg-block'>
							
							<div className='form-row address-container'>
								<div className='col-12 col-md-6 address-info pr-3'>
									<h4>{t('COMMON.DROP_OFF')}</h4>
									<div className='details'>
										{selectedFlight && selectedFlight.dropOffAddress ? 
											(<>
												{selectedFlight.dropOffAddress.name
													? selectedFlight.dropOffAddress.name
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.streetAndHouseNumber
													? selectedFlight.dropOffAddress.streetAndHouseNumber
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.country
													? selectedFlight.dropOffAddress.country
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.city
													? selectedFlight.dropOffAddress.city
													: ''}
												{', '}
												{selectedFlight.dropOffAddress.postCode
													? selectedFlight.dropOffAddress.postCode
													: ''}
											</>) : 
											(<>
												-
											</>)
										}
									</div>
								</div>
								<div className='col-12 col-md-6 address-info address-info-pickup pl-4'>
									<h4>{t('COMMON.PICK_UP')}</h4>
									<div className='details'>
										{
											selectedFlight && selectedFlight.pickUpAddress ?
												(<>
													{selectedFlight.pickUpAddress.name
														? selectedFlight.pickUpAddress.name
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.streetAndHouseNumber
														? selectedFlight.pickUpAddress.streetAndHouseNumber
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.country
														? selectedFlight.pickUpAddress.country
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.city
														? selectedFlight.pickUpAddress.city
														: ''}
													{', '}
													{selectedFlight.pickUpAddress.postCode
														? selectedFlight.pickUpAddress.postCode
														: ''}
												</>) : 
												(<>
													-
												</>)
										}
									</div>
								</div>
							</div>
							<div className='form-row mt-3 lat-container'>
								{selectedFlight.lastAcceptanceTime && (
									<div className='col-12 col-md-6 lat-info pr-3'>
										<h4>
											LAT (<span>{t('CHOOSE_FLT.LAT_FILTER')}</span>)
										</h4>
										<div className='details'>
											{moment(selectedFlight.lastAcceptanceTime, 'DD-MMM-YYYY').format(
												'ddd, DD MMM'
											)}{' '}
											<span className='time-field'>
												{' '}
												{moment(selectedFlight.lastAcceptanceTime).format(
													'HH:mm'
												)}{' '}
											</span>
										</div>
									</div>
								)}
								{selectedFlight.earliestDeliveryTime && (
									<div className='col-12 col-md-6 toa-info pl-4'>
										<h4>
											TOA (<span>{t('CHOOSE_FLT.TOA_FILTER')}</span>)
										</h4>
										<div className='details'>
											{moment(selectedFlight.earliestDeliveryTime,'DD-MMM-YYYY').format(
												'ddd, DD MMM'
											)}{' '}
											<span className='time-field'>
												{' '}
												{moment(selectedFlight.earliestDeliveryTime).format(
													'HH:mm'
												)}{' '}
											</span>
										</div>
									</div>
								)}
							</div>
							{/* <div className='toa-container'>
							</div>*/}
						</div>
					</div>
					</div>
				)}
			</div>
			<div className='mobile_partition-wrap d-flex d-lg-none'>
				<div className='mobile_partition-row' onClick={() => setAddrExpanded(!isAddrExpanded)}>
					<span className='mobile_partition-title'>Pick-up/drop-off details</span>
					<span className='mobile_partition-icon'>
						<i>
							{isAddrExpanded ? (
								<img src={upArrow} alt='Flight' title='Flight' />
							) : (
								<img src={downArrow} alt='Flight' title='Flight' />
							)}
						</i>
					</span>
				</div>
				{isAddrExpanded ? (
				<div className='mobile_partition-expanded'>
					<div className='flight-details__more-info'>
								
								<div className='address-container'>
									<div className='address-info'>
										<h4>{t('COMMON.DROP_OFF')}</h4>
										<div className='details'>
											{selectedFlight && selectedFlight.dropOffAddress ? 
												(<>
													{selectedFlight.dropOffAddress.name
														? selectedFlight.dropOffAddress.name
														: ''}
													{', '}
													{selectedFlight.dropOffAddress.streetAndHouseNumber
														? selectedFlight.dropOffAddress.streetAndHouseNumber
														: ''}
													{', '}
													{selectedFlight.dropOffAddress.country
														? selectedFlight.dropOffAddress.country
														: ''}
													{', '}
													{selectedFlight.dropOffAddress.city
														? selectedFlight.dropOffAddress.city
														: ''}
													{', '}
													{selectedFlight.dropOffAddress.postCode
														? selectedFlight.dropOffAddress.postCode
														: ''}
												</>) : 
												(<>
													-
												</>)
											}
										</div>
									</div>
									{selectedFlight.lastAcceptanceTime && (
										<div className='address-info lat-info'>
											<h4>
												LAT (<span>{t('CHOOSE_FLT.LAT_FILTER')}</span>)
											</h4>
											<div className='details'>
												{moment(selectedFlight.lastAcceptanceTime, 'DD-MMM-YYYY').format(
													'ddd, DD MMM'
												)}{' '}
												<span className='time-field'>
													{' '}
													{moment(selectedFlight.lastAcceptanceTime).format(
														'HH:mm'
													)}{' '}
												</span>
											</div>
										</div>
									)}
									<div className='address-info address-info-pickup'>
										<h4>{t('COMMON.PICK_UP')}</h4>
										<div className='details'>
											{
												selectedFlight && selectedFlight.pickUpAddress ?
													(<>
														{selectedFlight.pickUpAddress.name
															? selectedFlight.pickUpAddress.name
															: ''}
														{', '}
														{selectedFlight.pickUpAddress.streetAndHouseNumber
															? selectedFlight.pickUpAddress.streetAndHouseNumber
															: ''}
														{', '}
														{selectedFlight.pickUpAddress.country
															? selectedFlight.pickUpAddress.country
															: ''}
														{', '}
														{selectedFlight.pickUpAddress.city
															? selectedFlight.pickUpAddress.city
															: ''}
														{', '}
														{selectedFlight.pickUpAddress.postCode
															? selectedFlight.pickUpAddress.postCode
															: ''}
													</>) : 
													(<>
														-
													</>)
											}
										</div>
									</div>
									{selectedFlight.earliestDeliveryTime && (
										<div className='address-info toa-info'>
											<h4>
												TOA (<span>{t('CHOOSE_FLT.TOA_FILTER')}</span>)
											</h4>
											<div className='details'>
												{moment(selectedFlight.earliestDeliveryTime,'DD-MMM-YYYY').format(
													'ddd, DD MMM'
												)}{' '}
												<span className='time-field'>
													{' '}
													{moment(selectedFlight.earliestDeliveryTime).format(
														'HH:mm'
													)}{' '}
												</span>
											</div>
										</div>
									)}
								</div>
					</div>
				</div>) : (<></>)}
			</div>
		</>
	)
}
export default RouteDetails
