const getOktaConfigs = (env) => {
	switch (env) {
		case 'local':
			return {
				enterpriseSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterpriselocal_1/exk1175yaoJVkKn575d7/sso/saml',
				consumerSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoconsumerlocal_1/exk11789liYVXjZea5d7/sso/saml',
			}
		case 'dev':
			return {
				enterpriseSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterprisedev_1/exk15l2skcypKGEFN5d7/sso/saml',
				consumerSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterprisedev_1/exk15l2skcypKGEFN5d7/sso/saml',
			}
		case 'test':
			return {
				enterpriseSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterprise_2/exk105r02qoTtQpVn5d7/sso/saml',
				consumerSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoconsumer_1/exkzgozotIoJnNFiR5d6/sso/saml',
			}
		case 'stage':
			return {
				enterpriseSamlLink:
					'https://etihad.oktapreview.com/app/etihad_etihadcargobookingportalstage_1/exk10lzdjbb2JYRy50h8/sso/saml',
				consumerSamlLink:
					'https://eappp.okta-emea.com/app/eappp_etihadcargobookingportalstage_1/exk65la6tlaI1xql40i7/sso/saml',
			}
		case 'k8s':
			return {
				enterpriseSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterprisek8s_1/exk1flq3pbO5juWm05d7/sso/saml',
				consumerSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterprisek8s_1/exk1flq3pbO5juWm05d7/sso/saml',
			}
		default:
			return {
				enterpriseSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoenterpriselocal_1/exk1175yaoJVkKn575d7/sso/saml',
				consumerSamlLink:
					'https://dev-7196454.okta.com/app/dev-7196454_eybookingcargoconsumerlocal_1/exk11789liYVXjZea5d7/sso/saml',
			}
	}
}

export default getOktaConfigs
