import { POPUP } from '../constants'

export const showPopup = (
	header,
	message,
	primaryBtnLbl,
	acceptFn,
	secondaryBtnLbl,
	rejectFn
) => ({
	type: POPUP.SHOW,
	payload: {
		header,
		message,
		primaryBtnLbl,
		acceptFn,
		secondaryBtnLbl,
		rejectFn,
	},
})

export const closePopup = () => ({
	type: POPUP.CLOSE,
})
