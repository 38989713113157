import { removeDuplicates } from '../SearchPanel/templateUtils'
import { getAirportsByCode } from '../../services/searchPanelServices'
import { getCountryByCode } from '../../services/dataCaptureService'
import { PAYMENT_TYPES } from '../../constants'

export const mapAWBResponse = async (awbDetail) => {
	let awbShipperDetailResponse = awbDetail.awbShipperDetails
		? awbDetail.awbShipperDetails
		: null
	let awbConsigneeDetailResponse = awbDetail.awbConsigneeDetails
		? awbDetail.awbConsigneeDetails
		: null

	const shipperCountry = awbShipperDetailResponse.customerCountryCode
		? await getCountryByCode(awbShipperDetailResponse.customerCountryCode)
		: null
	const consigneeCountry = awbConsigneeDetailResponse.customerCountryCode
		? await getCountryByCode(awbConsigneeDetailResponse.customerCountryCode)
		: null

	let awbResponse = {
		awbConsignmentDetails: awbDetail.awbConsignmentDetails
			? awbDetail.awbConsignmentDetails
			: null,
		awbShipperDetails: awbShipperDetailResponse
			? {
				shipperName: awbShipperDetailResponse.customerName
					? awbShipperDetailResponse.customerName
					: '',
				shipperAddress1: awbShipperDetailResponse.customerStreetAddress
					? awbShipperDetailResponse.customerStreetAddress
					: '',
				shipperCity: awbShipperDetailResponse.customerPlace
					? awbShipperDetailResponse.customerPlace
					: '',
				shipperState: awbShipperDetailResponse.customerState
					? awbShipperDetailResponse.customerState
					: '',
				shipperCountry: shipperCountry ? shipperCountry : '',
				shipperPostalCode: awbShipperDetailResponse.customerZipCode
					? awbShipperDetailResponse.customerZipCode
					: '',
				shipperAccountNumber: awbShipperDetailResponse.customerAccountNumber
					? awbShipperDetailResponse.customerAccountNumber
					: '',
				shipperPhone:
					awbShipperDetailResponse.customerContactDetails &&
						awbShipperDetailResponse.customerContactDetails.customerContact &&
						awbShipperDetailResponse.customerContactDetails.customerContact[0]
							.customerPhoneNumber
						? awbShipperDetailResponse.customerContactDetails
							.customerContact[0].customerPhoneNumber
						: '',
				shipperMail:
					awbShipperDetailResponse.customerContactDetails &&
						awbShipperDetailResponse.customerContactDetails.customerContact &&
						awbShipperDetailResponse.customerContactDetails.customerContact[0]
							.customerEMailId
						? awbShipperDetailResponse.customerContactDetails
							.customerContact[0].customerEMailId
						: '',
			}
			: null,
		awbConsigneeDetails: awbConsigneeDetailResponse
			? {
				consigneeName: awbConsigneeDetailResponse.customerName
					? awbConsigneeDetailResponse.customerName
					: '',
				consigneeAddress1: awbConsigneeDetailResponse.customerStreetAddress
					? awbConsigneeDetailResponse.customerStreetAddress
					: '',
				consigneeCity: awbConsigneeDetailResponse.customerPlace
					? awbConsigneeDetailResponse.customerPlace
					: '',
				consigneeState: awbConsigneeDetailResponse.customerState
					? awbConsigneeDetailResponse.customerState
					: '',
				consigneeCountry: consigneeCountry ? consigneeCountry : '',
				consigneePostalCode: awbConsigneeDetailResponse.customerZipCode
					? awbConsigneeDetailResponse.customerZipCode
					: '',
				consigneeAccountNumber:
					awbConsigneeDetailResponse.customerAccountNumber
						? awbConsigneeDetailResponse.customerAccountNumber
						: '',
				consigneePhone:
					awbConsigneeDetailResponse.customerContactDetails &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact[0]
							.customerPhoneNumber
						? awbConsigneeDetailResponse.customerContactDetails
							.customerContact[0].customerPhoneNumber
						: '',
				consigneeMail:
					awbConsigneeDetailResponse.customerContactDetails &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact[0]
							.customerEMailId
						? awbConsigneeDetailResponse.customerContactDetails
							.customerContact[0].customerEMailId
						: '',
			}
			: null,
		awbAgentDetails: awbDetail.awbAgentDetails
			? awbDetail.awbAgentDetails
			: null,
	}

	return awbResponse
}

export const mapHawbResponse = async (awbDetail) => {
	let houseDetails = awbDetail.houseDetails[0]
	let commodityCode = houseDetails?.awbRateLineDetails?.awbRateLine[0]?.commodityCode
	const origin = await getAirportsByCode(houseDetails.origin)
	const destination = await getAirportsByCode(houseDetails.destination)
	const shipperCountry = await getCountryByCode(houseDetails.shipperCountry)
	const consigneeCountry = await getCountryByCode(houseDetails.consigneeCountry)
	let hawbResponse = houseDetails && {
		shipmentDetails: {
			origin: origin ? origin : null,
			destination: destination ? destination : null,
			pieces: houseDetails.statedPieces ? houseDetails.statedPieces : '',
			weight: houseDetails.statedWeight ? houseDetails.statedWeight : '',
			weightUnit: houseDetails.statedWeightUnit
				? houseDetails.statedWeightUnit
				: '',
			volume: houseDetails.statedVolume ? houseDetails.statedVolume : '',
			volumeUnit: houseDetails.statedVolumeUnit
				? houseDetails.statedVolumeUnit
				: '',
			slacPieces: houseDetails.slacPieces,
			shipmentDescription: houseDetails.shipmentDescription,
			// scc: houseDetails.scc && getSccList(houseDetails.scc)
			scc: houseDetails.scc,
			declaredValueForCustoms:
				houseDetails.awbChargeDeclaration &&
					houseDetails.awbChargeDeclaration.declaredValueForCustoms
					? houseDetails.awbChargeDeclaration.declaredValueForCustoms
					: null,
			currencyCode:
				houseDetails.awbChargeDeclaration &&
					houseDetails.awbChargeDeclaration.awbCurrencyCode
					? houseDetails.awbChargeDeclaration.awbCurrencyCode
					: '',
			paymentType: houseDetails.awbChargeDeclaration
				&& houseDetails.awbChargeDeclaration.prepaidCollectChargeDeclaration
				&& houseDetails.awbChargeDeclaration.prepaidCollectChargeDeclaration.prepaidCollectIndicatorWgtValChgs
				? houseDetails.awbChargeDeclaration.prepaidCollectChargeDeclaration.prepaidCollectIndicatorWgtValChgs
				: PAYMENT_TYPES.PP,
			handlingInfo: houseDetails.awbHandlingInfoDetails !== null ? houseDetails.awbHandlingInfoDetails.otherServiceInformation[0] : '',
		},

		commodityCode: commodityCode ? commodityCode : null,
		shipperDetails: {
			shipperName: houseDetails.shipperName ? houseDetails.shipperName : '',
			shipperAddress1: houseDetails.shipperAddress1
				? houseDetails.shipperAddress1
				: '',
			shipperCity: houseDetails.shipperCity ? houseDetails.shipperCity : '',
			shipperState: houseDetails.shipperState ? houseDetails.shipperState : '',
			shipperCountry: shipperCountry ? shipperCountry : null,
			shipperPostalCode: houseDetails.shipperPostalCode
				? houseDetails.shipperPostalCode
				: '',
			shipperAccountNumber: houseDetails.shipperAccountNumber
				? houseDetails.shipperAccountNumber
				: '',
			shipperPhone: houseDetails.shipperPhone ? houseDetails.shipperPhone : '',
			shipperMail: houseDetails.shipperEmail ? houseDetails.shipperEmail : '',
		},
		consigneeDetails: {
			consigneeName: houseDetails.consigneeName
				? houseDetails.consigneeName
				: '',
			consigneeAddress1: houseDetails.consigneeAddress1
				? houseDetails.consigneeAddress1
				: '',
			consigneeCity: houseDetails.consigneeCity
				? houseDetails.consigneeCity
				: '',
			consigneeState: houseDetails.consigneeState
				? houseDetails.consigneeState
				: '',
			consigneeCountry: consigneeCountry ? consigneeCountry : null,
			consigneePostalCode: houseDetails.consigneePostalCode
				? houseDetails.consigneePostalCode
				: '',
			consigneeAccountNumber: houseDetails.consigneeAccountNumber
				? houseDetails.consigneeAccountNumber
				: '',
			consigneePhone: houseDetails.consigneePhone
				? houseDetails.consigneePhone
				: '',
			consigneeMail: houseDetails.consigneeEmail
				? houseDetails.consigneeEmail
				: '',
		},

		sci: houseDetails.customsOriginCode ? houseDetails.customsOriginCode : null,
		hccs: getHCC(houseDetails?.awbRateLineDetails?.awbRateLine),
		awbChargeDeclaration: houseDetails.awbChargeDeclaration,
		customsInformationDetails: getCustomsInformationDetails(houseDetails),
		currency: houseDetails.awbChargeDeclaration.awbCurrencyCode
			? houseDetails.awbChargeDeclaration.awbCurrencyCode
			: '',
		otherCustomsDetails: {
			sci: houseDetails.customsOriginCode ? houseDetails.customsOriginCode : null,
			hccs: getHCC(houseDetails?.awbRateLineDetails?.awbRateLine),
		},
	}
	return hawbResponse
}

const getHCC = (awbRateLine) => {

	if (
		awbRateLine &&
		awbRateLine[0]?.harmonizedCommodityCodes &&
		awbRateLine[0]?.harmonizedCommodityCodes?.harmonizedCommodityCode?.length
	) {
		return awbRateLine[0]?.harmonizedCommodityCodes?.harmonizedCommodityCode.join()
	} else {
		return ''
	}
}


const getSccList = (sccCodes) => {
	if (sccCodes && sccCodes !== '') {
		const sccArr = sccCodes.split(',')
		let sccDetails = []
		sccArr.forEach(function (scc) {
			const sccDetail = {
				scc: scc,
				sccDescription: null,
			}
			sccDetails.push(sccDetail)
		})
		return removeDuplicates(sccDetails)
	}
}

const getCustomsInformationDetails = (details) => {
	const customsInformationDetails = details.otherCustomsInformationDetails
	let customsDetails = []
	if (
		customsInformationDetails &&
		customsInformationDetails.otherCustomsInformation &&
		customsInformationDetails.otherCustomsInformation.length
	) {
		customsInformationDetails.otherCustomsInformation.forEach(function (
			info,
			index
		) {
			let customsInfo = {
				informationId: info.informationIdentifier,
				customsInformationId: info.customsInformationIdentifier,
				supplementaryCustomsInformation:
					info.supplementaryCustomsControlInformation,
				countryCode: info.countryCode,
			}
			if (index > 0) {
				customsInfo.isNew = true
			}
			customsDetails.push(customsInfo)
		})
		return customsDetails
	} else {
		const info = {
			informationIdentifier: '',
			customsInformationIdentifier: '',
			supplementaryCustomsControlInformation: '',
			countryCode: '',
		}
		customsDetails.push(info)
		return customsDetails
	}


}
