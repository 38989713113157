import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import getPrincipal from '../../../utils/getPrincipal'
import getCurrentUser from '../../../utils/getCurrentUser'
import { handleSaveAcceptTnC } from '../captureAwbUtils'
import LoadingIndicator from '../../LoadingIndicator'
import { setToLocalStorage } from '../../../utils/StorageUtils'
import sessionKeys from '../../../constants/sessionKeys'
import './index.scss'
import { useTranslation } from 'react-i18next'
const Declaration = ({ setShowTC }) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	const [agreeTC, setAgreeTC] = useState(true)
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const handleSubmit = async () => {
		if (agreeTC) {
			setError('')
			const status = await handleSaveAcceptTnC(agreeTC, setIsLoading)
			if (status) {
				const userDetails = getCurrentUser()
				if (userDetails) {
					userDetails.dataCaptureTnCFlag = true
					setToLocalStorage(
						sessionKeys.USER_DETAILS,
						JSON.stringify(userDetails)
					)
				}
				setShowTC(false)
			}
		} else {
			setError(t('E_AWB.AGRMNT_SUBMIT_MSG'))
		}
	}

	return (
		<div className='capture-awb data-capture-wrapper'>
			<div className='form-row'>
				<div className='col-12'>
					<h3 className='page-heading pl-0 pt-0 mt-0'>
						{t('COMMON.DATA_CAPTURE')}
					</h3>
				</div>
			</div>
			<div className='form-row'>
				<div className='col-12 col-md-12'>
					<div className='data-capture-declaration'>
						<h3 className=''>{t('E_AWB.AGRMNT_TITLE')}</h3>
						<div className='declaration-content'>
							<h4 className='mt-0'>{t('E_AWB.AGRMNT_SUB_PREAMBLE')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_PREAMBLE_DES')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_CONSENT')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_CONSENT_DES')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_OPRTNS')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_OPRTNS_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_SHPMNT_RECORD')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_SHPMNT_RECORD_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_CARGO_CNTRCT')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_CARGO_CNTRCT_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_CNTRCT_PARTY')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_CNTRCT_PARTY_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_SHPMNTS')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_SHPMNTS_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_CONFDNTALTY')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_CONFDNTALTY_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_LIABILITY')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_LIABILITY_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_SEVERABILITY')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_SEVERABILITY_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_INTRPTATN')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_INTRPTATN_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_NWR')}</h4>
							<p className='paragraph-text'>{t('E_AWB.AGRMNT_SUB_NWR_DESC')}</p>
							<h4>{t('E_AWB.AGRMNT_SUB_RES_DS')}</h4>
							<h5>{t('E_AWB.AGRMNT_SUB_ARBITRATION')}</h5>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_RES_DS_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_GOV_LAW')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_GOV_LAW_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_OTHER')}</h4>
							<p className='paragraph-text'>
								{t('E_AWB.AGRMNT_SUB_OTHER_DESC')}
							</p>
							<h4>{t('E_AWB.AGRMNT_SUB_CNDTNS')}</h4>
							<p className='paragraph-text'>{t('E_AWB.AGRMNT_SUB_CNDTNS_DESC')}</p>
						</div>
					</div>
					<div
						className='form-item form-item--checkbox mt-5'
						data-module='molecules/form-item/FormItem'
					>
						<input
							id='notifyShipper'
							checked={agreeTC}
							value={agreeTC}
							type='checkbox'
							name='notifyShipper'
							className='form-item__field'
							onChange={(e) => {
								setAgreeTC(!agreeTC)
								setError('')
							}}
						/>
						<label htmlFor='notifyShipper' className='form-item__label'>
						{t('E_AWB.AGRMNT_AGREE')}
						</label>
						<span className='form-item__error'>{error}</span>
					</div>
					<div className='mt-5'>
						<button className='button' onClick={handleSubmit}>
						{t('E_AWB.AGRMNT_SUBMIT_BUTTON')}
						</button>
					</div>
				</div>
			</div>
			{isLoading ? <LoadingIndicator /> : <></>}
		</div>
	)
}

export default Declaration
