

export const openURLEncodedBase64NewTab = (urlEncodedBase64Pdf) => {
  const base64Pdf = urlDecode(urlEncodedBase64Pdf)
  const blob = base64toBlob(base64Pdf);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, "awbdoc.pdf");
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
}

export const openBase64NewTab = (base64Pdf, name) => {
	const blob = base64toBlob(base64Pdf)
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, name)
	} else {
		const blobURL = URL.createObjectURL(blob)
		const pdfWindow = window.open(blobURL)
		const printDoc = pdfWindow.document
		printDoc.title = name
		const printScript = document.createElement('script')
		function injectThis() {
			window.print()
		}
		printScript.innerHTML = `window.onload = ${injectThis.toString()};`
		printDoc.body.appendChild(printScript)
	}
}

const urlDecode = (str) => decodeURIComponent((str+'').replace(/\+/g, '%20'))

const base64toBlob = (base64Data) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: "application/pdf" });
}
