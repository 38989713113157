import React, { useState, useEffect } from 'react'
import './../../../ULDItemForm/item-form.scss'
import {
	FORM_FIELDS,
	WEIGHT_UNIT,
	DIMENSION_UNIT,
	getInitLooseItemDetail,
	getDecimalFormattedNumber,
} from '../../../../constants'
import { Button } from 'primereact/button'
import ULDOverflow from '../../../ULDOverflow'
import ULDLineItem from '../../../ULDItemForm/ULDLineItem'
import { calculateWeightToKg } from '../../../../utils/common'
import { useTranslation } from 'react-i18next'
import { getULDTypeByCode } from '../../../../services/searchPanelServices'

const ULDItemForm = ({
	handleAddLineItem,
	error,
	setError,
	totalWeightInKilo,
	setTotalPivoteWeightInKg,
	totalPivoteWeightInKg,
	envConfig,
	awbDetails,
	setAwbDetails,
	setNotificationMessage,
	setNotificationType,
}) => {
	//for Mutilingual
	const { t, i18n } = useTranslation(['labels'])

	const setULDDetails = (uldDetails) => {
		setAwbDetails((prevState) => ({
			...prevState,
			ULDDetails: uldDetails,
		}))
	}
	let { weightUnit, mixLooseDetails, expandAccordian, ULDDetails } = awbDetails

	const setWeightUnit = (unit) => {
		setAwbDetails((prevState) => ({
			...prevState,
			weightUnit: unit,
		}))
	}

	const setTotalWeightInKg = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			totalWeightInKg: val,
		}))
	}

	const setTotalGrossWeight = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			totalWeight: val,
		}))
	}

	const setChargeableWeight = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			chargeableWeight: val,
		}))
	}

	const setMixLooseDetails = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			mixLooseDetails: val,
		}))
	}

	const setTotalPieces = (nos) => {
		setAwbDetails((prevState) => ({
			...prevState,
			totalPieces: nos,
		}))
	}

	const setExpandAccordian = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			expandAccordian: val,
		}))
	}

	const setActualVolume = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			actualVolume: val,
		}))
	}

	const setActualGrossWeight = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			actualGrossWeight: val,
		}))
	}

	const setTotalVolumeInMeters = (val) => {
		setAwbDetails((prevState) => ({
			...prevState,
			totalVolumeInMtrs: val,
		}))
	}

	const [totalWeight, setTotalWeight] = useState(null)
	const [totalUldWeightInKg, setTotalUldWeightInKg] = useState(0)
	const [totalPivoteWeight, setTotalPivoteWeight] = useState(null)

	//Mix Booking
	const [looseItemsTotalWeight, setLooseItemsTotalWeight] = useState(
		awbDetails ? awbDetails.totalWeight : null
	)
	const [looseItemsGrossWeight, setLooseItemsGrossWeight] = useState(0)
	const [dimensionUnit, setDimensionUnit] = useState(
		awbDetails ? awbDetails.dimensionUnit : DIMENSION_UNIT.CENTIMETERS
	)
	const [isAllStackable, setAllStackable] = useState(
		awbDetails ? awbDetails.isAllStackable : false
	)
	const [isAllTiltable, setAllTiltable] = useState(
		awbDetails ? awbDetails.isAllTiltable : false
	)
	const [totalLooseWeightInKg, setTotalLooseWeightInKg] = useState(
		awbDetails ? awbDetails.totalLooseWeightInKg : 0
	)
	const [totalLooseVolumeInMtrs, setTotalLooseVolumeInMeters] = useState(
		awbDetails ? awbDetails.totalLooseVolumeInMtrs : 0
	)
	const [actualLooseTotalVolume, setActualLooseTotalVolume] = useState(
		awbDetails ? awbDetails.actualLooseTotalVolume : 0
	)
	const [calculatedTotalWeight, setCalculatedTotalWeight] = useState(0)
	const [showValidation, setshowValidation] = useState(false)
	const [totalLoosePieces, setTotalLoosePieces] = useState(
		awbDetails ? awbDetails.totalLoosePieces : 0
	)
	const [looseChargeableWeight, setLooseChargeableWeight] = useState(
		awbDetails ? awbDetails.looseChargeableWeight : 0
	)
	const [removedRowCount, setRemovedRowCount] = useState(0)

	const [uldInfo, setUldInfo] = useState({})

	useEffect(() => {
		; (async () => {
			let uldList = []
			for (const item of ULDDetails) {
				let response;
				if (!item.uldVolume) {
					if (uldInfo && uldInfo[item.uldType]) {
						item.uldVolume = uldInfo[item.uldType]?.maxvol
					} else {
						response = await getULDTypeByCode(item.uldType)
					}
				}
				if (response) {
					let uldInfoTemp = { ...uldInfo }
					uldInfo[item.uldType] = response
					setUldInfo(uldInfoTemp)
					item.uldVolume = response?.maxvol
				}
				uldList.push(item)
			}
			setAwbDetails((prevState) => ({
				...prevState,
				ULDDetails: uldList,
			}))

		})()
	}, [])

	useEffect(() => {
		handleSummaryChange()
		calculateTotalWeight()
		calculateTotalPivotWeight()
	}, [ULDDetails, weightUnit])

	useEffect(() => {
		calculateTotalWeight()
		calculateTotalPivotWeight()
	}, [ULDDetails])

	useEffect(() => {
		setTotalUldWeightInKg(calculateWeightToKg(totalWeight, weightUnit))
		let totalWeightInKg = calculateWeightToKg(totalWeight, weightUnit)
		if (looseItemsGrossWeight) {
			totalWeightInKg =
				totalWeightInKg * 1 +
				calculateWeightToKg(looseItemsGrossWeight, weightUnit) * 1
		}
		let actualWeight = totalWeight * 1 + looseItemsGrossWeight * 1
		setActualGrossWeight(actualWeight)
		totalWeightInKg = (totalWeightInKg * 1).toFixed(2)
		setTotalWeightInKg(totalWeightInKg)
		setTotalGrossWeight(actualWeight)
	}, [totalWeight, weightUnit, looseItemsGrossWeight])

	useEffect(() => {
		setTotalPivoteWeightInKg(
			calculateWeightToKg(totalPivoteWeight, WEIGHT_UNIT.KILOGRAM)
		)
	}, [totalPivoteWeight])

	useEffect(() => {
		if (totalLoosePieces > 0) {
			handleSummaryChange()
			/*if (totalLoosePieces && totalLoosePieces > 0) {
				setExpandAccordian(true)
			} else {
				setExpandAccordian(false)
			}*/
		}
	}, [totalLoosePieces, totalLooseVolumeInMtrs, actualLooseTotalVolume])

	useEffect(() => {
		let chargeableWeight = 0
		if (totalPivoteWeightInKg) {
			chargeableWeight = Math.max(
				totalWeightInKilo * 1,
				totalPivoteWeightInKg * 1
			)
		}
		if (awbDetails.actualChargeableWeight < chargeableWeight) {
		    setChargeableWeight(chargeableWeight)
		}
	}, [totalWeightInKilo, totalPivoteWeightInKg, looseItemsGrossWeight])

	const calculateKgWeight = (weight, weightUnit) => {
		if (!weight) {
			return 0
		}
		if (weightUnit == WEIGHT_UNIT.TON) {
			return (weight * 907.185).toFixed(2)
		} else if (weightUnit == WEIGHT_UNIT.POUND) {
			return (weight * 0.453592).toFixed(2)
		} else {
			return weight.toString()
		}
	}

	const handleSummaryChange = () => {
		let quantity =
			ULDDetails.reduce((curr, next) => ({
				pieces:
					(curr && curr.pieces ? curr.pieces * 1 : 0) +
					(next && next.pieces ? next.pieces * 1 : 0),
			})).pieces || 0
		let totalVolumeinMeters = 0
		let actualVolumeInSelectedUnit = 0
		let totalVolumeinSelectedUnit = 0
		ULDDetails.forEach((item) => {
			totalVolumeinMeters +=
				(item && item.uldVolume ? item.uldVolume * 1 : 0) *
				(item && item.pieces ? item.pieces * 1 : 0)
		})
		ULDDetails.forEach((item) => {
			if (dimensionUnit == DIMENSION_UNIT.CENTIMETERS) {
				actualVolumeInSelectedUnit +=
					(item && item.uldVolume ? item.uldVolume * 1000000 : 0) *
					(item && item.pieces ? item.pieces * 1 : 0)
			}
			if (dimensionUnit == DIMENSION_UNIT.INCHES) {
				actualVolumeInSelectedUnit +=
					(item && item.uldVolume ? item.uldVolume * 61023.7 : 0) *
					(item && item.pieces ? item.pieces * 1 : 0)
			}
		})
		if (actualVolumeInSelectedUnit) {
			totalVolumeinSelectedUnit =
				actualVolumeInSelectedUnit * 1 + actualLooseTotalVolume * 1
		}
		if (totalLooseVolumeInMtrs) {
			totalVolumeinMeters = totalVolumeinMeters * 1 + totalLooseVolumeInMtrs * 1
		}

		setTotalVolumeInMeters(totalVolumeinMeters * 1)
		setActualVolume(totalVolumeinSelectedUnit)
		if (totalVolumeinMeters && totalVolumeinMeters !== 0) {
			let volumeCBM = getDecimalFormattedNumber(totalVolumeinMeters)
			setAwbDetails((prevState) => ({
				...prevState,
				displayVolume: volumeCBM,
			}))
		}
		if (totalLoosePieces) {
			quantity = quantity * 1 + totalLoosePieces * 1
		}
		setTotalPieces(quantity)
	}

	const handleItemChange = (index, item) => {
		const tempULDDetails = JSON.parse(JSON.stringify(ULDDetails))
		tempULDDetails[index] = item
		setULDDetails([...tempULDDetails])
		calculateTotalPivotWeight()
	}

	const handleRemoveItem = (index) => {
		const itemsTemp = ULDDetails.filter((itemDetail, curr) => {
			return index !== curr
		})
		setULDDetails(itemsTemp)
		handleSummaryChange()
		setRemovedRowCount(removedRowCount * 1 + 1)
	}

	const calculateTotalWeight = () => {
		const calculatedTotalWeight = [...ULDDetails, {}].reduce((curr, next) => {
			// return {
			// 	weight:
			// 		(curr.weight ? curr.weight * 1 : 0) +
			// 		(next.weight ? next.weight * 1 : 0),
			// }
			return {
				weight:
					(curr.weight ? curr.weight * 1 : 0) *
					(curr.pieces ? curr.pieces * 1 : 0) +
					(next.weight ? next.weight * 1 : 0) *
					(next.pieces ? next.pieces * 1 : 0),
				pieces: 1,
			}
		}).weight
		setTotalWeight(calculatedTotalWeight)
	}
	const calculateTotalPivotWeight = () => {
		const calculatedTotalPivotWeight = [...ULDDetails, {}].reduce(
			(prev, current) => {
				return {
					pivotWeight:
						(prev.pivotWeight ? prev.pivotWeight * 1 : 0) *
						(prev.pieces ? prev.pieces * 1 : 0) +
						(current.pivotWeight ? current.pivotWeight * 1 : 0) *
						(current.pieces ? current.pieces * 1 : 0),
					pieces: 1,
					weight: 0,
				}
			}
		).pivotWeight
		setTotalPivoteWeight(calculatedTotalPivotWeight)
	}

	const clearItemError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.ULD_ITEMS] &&
			tempError[FORM_FIELDS.ULD_ITEMS][index]
		) {
			tempError[FORM_FIELDS.ULD_ITEMS][index][key] = null
			setError(tempError)
		}
	}
	const handleAddLooseLineItem = (e) => {
		e.preventDefault()
		setMixLooseDetails([...mixLooseDetails, getInitLooseItemDetail()])
	}

	const handleAddOverflow = (e) => {
		e.preventDefault()
		setExpandAccordian(true)
	}
	return (
		<div id='capture-awb-uld-wrapper' className='capture-awb-uld-wrapper'>
			{ULDDetails &&
				ULDDetails.map((item, index) => (
					<ULDLineItem
						item={item}
						index={index}
						selectedOrigin={awbDetails.origin}
						handleItemChange={handleItemChange}
						envConfig={envConfig}
						error={error}
						clearItemError={clearItemError}
						ULDDetails={ULDDetails}
						handleSummaryChange={handleSummaryChange}
						calculateTotalWeight={calculateTotalWeight}
						weightUnit={weightUnit}
						handleRemoveItem={handleRemoveItem}
						setWeightUnit={setWeightUnit}
						removedRowCount={removedRowCount}
						captureAWB={false}
						excludeTon={true}
						setNotificationMessage={setNotificationMessage}
						setNotificationType={setNotificationType}
					/>
				))}
			<div className='form-row'>
				<div className='form-group col-12 col-lg-10 m-b-0 pr-md-0 pr-2 pl-md-0 pl-2 cta_wrap flex-column flex-md-row'>
					<Button
						label={t('COMMON.ADD_ANOTHER')}
						icon='pi pi-plus'
						className='d-block custom-xs button__another'
						type='button'
						onClick={(e) => handleAddLineItem(e)}
					/>
				</div>
			</div>
			<div className='form-row' hidden={expandAccordian}>
				<div className='form-group col-10 m-b-0'>
					<Button
						label={t('COMMON.ADD_OVERFLOW')}
						className='p-button-link add-overflow-link'
						type='button'
						onClick={(e) => handleAddOverflow(e)}
					/>
				</div>
			</div>
			<div
				id='awb-uld-overflow-wrapper'
				className='form-row overflow-accordian'
				hidden={!expandAccordian}
			>
				<div className='form-group col-12 col-md-12'>
					<ULDOverflow
						looseDetails={mixLooseDetails}
						setLooseDetails={setMixLooseDetails}
						totalWeight={looseItemsTotalWeight}
						setTotalWeight={setLooseItemsTotalWeight}
						setLooseItemsGrossWeight={setLooseItemsGrossWeight}
						weightUnit={weightUnit}
						setWeightUnit={setWeightUnit}
						dimensionUnit={dimensionUnit}
						setDimensionUnit={setDimensionUnit}
						isAllStackable={isAllStackable}
						setAllStackable={setAllStackable}
						isAllTiltable={isAllTiltable}
						setAllTiltable={setAllTiltable}
						handleAddLineItem={handleAddLooseLineItem}
						setTotalPieces={setTotalLoosePieces}
						setTotalVolumeInMeters={setTotalLooseVolumeInMeters}
						setActualLooseTotalVolume={setActualLooseTotalVolume}
						setChargeableWeight={setLooseChargeableWeight}
						setTotalWeightInKg={setTotalLooseWeightInKg}
						totalWeightInKilo={totalLooseWeightInKg}
						totalVolumeinMtrs={totalLooseVolumeInMtrs}
						error={error}
						setError={setError}
						calculatedTotalWeight={calculatedTotalWeight}
						setCalculatedTotalWeight={setCalculatedTotalWeight}
						showValidation={showValidation}
						expandAccordian={expandAccordian}
						setExpandAccordian={setExpandAccordian}
						hideLooseUpload={false}
						excludeTon={true}
						captureAwb={true}
					/>
				</div>
			</div>
		</div>
	)
}

export default ULDItemForm
