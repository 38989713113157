import { FLIGHT_ENQUIRY } from '../constants'

const initState = {
	request: null,
	response: null,
	error: null,
}

export default function (state = initState, action = {}) {
	switch (action.type) {
		case FLIGHT_ENQUIRY.LOAD:
			return {
				...state,
				request: action.request,
				response: null,
				error: null,
			}
		case FLIGHT_ENQUIRY.SUCCESS:
			return {
				...state,
				response: action.response,
			}
		case FLIGHT_ENQUIRY.ERROR:
			return {
				...state,
				error: action.error,
			}
		case FLIGHT_ENQUIRY.CLEAR:
			return {
				...state,
				response: action.response,
			}
		default:
			return state
	}
}
