import { AUTH } from '../constants'

export const setRefreshToken = (refreshToken) => ({
	type: AUTH.SET_REFRESH_TOKEN,
	refreshToken,
})

export const setAccessToken = (accessToken) => ({
	type: AUTH.SET_ACCESS_TOKEN,
	accessToken,
})

export const setIsRefreshing = (isRefreshing) => ({
	type: AUTH.SET_IS_REFRESHING,
	isRefreshing,
})

export const clearAuth = () => ({
	type: AUTH.CLEAR,
})
