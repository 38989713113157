import { POPUP } from '../constants'

const initialState = {
	display: false,
	header: null,
	message: null,
	primaryBtnLbl: null,
	acceptFn: null,
	secondaryBtnLbl: null,
	rejectFn: null,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case POPUP.SHOW:
			return {
				...state,
				display: true,
				header: action.payload.header,
				message: action.payload.message,
				primaryBtnLbl: action.payload.primaryBtnLbl,
				acceptFn: action.payload.acceptFn,
				secondaryBtnLbl: action.payload.secondaryBtnLbl,
				rejectFn: action.payload.rejectFn,
			}
		case POPUP.CLOSE:
			return {
				...state,
				display: false,
				header: null,
				message: null,
				primaryBtnLbl: null,
				acceptFn: null,
				secondaryBtnLbl: null,
				rejectFn: null,
			}
		default:
			return state
	}
}
