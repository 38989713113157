import {
	getTemplate,
	getRecentBooking,
	getAdditionaFormInfo,
	updateTemplate,
	getDraftsDetails,
	deleteDrafts,
	listDrafts,
	getAWB,
} from '../../services/templateService'
import { getAirportsByCode } from '../../services/searchPanelServices'
import getCurrentUser from '../../utils/getCurrentUser'

import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { getInitULDItemDetail, VOLUME_CODE_UNIT_MAPPING } from '../../constants'
import { getDecimalFormattedNumber } from '../../constants'
import {
	getInitLooseItemDetail,
	DETAILS_APPLY_FOR,
	DISPLAY_SERVICES,
	PHARMA_TEMP_RANGE,
} from '../../constants'
import { getFromSessionStorage, setToSessionStorage } from '../../utils/StorageUtils'
import { getCountryByCode } from '../../services/dataCaptureService'
import { getRandomIntInclusive } from '../../utils/HttpRequestUtils'
import { getUnidInfo } from '../../services/dgBookingServices'

export const getTemplateDetails = async (
	setSearchFormDataToState,
	setAdditionalInfoData,
	templateName,
	type,
	setIsLoading,
	getlistDraftsResponse,
) => {
	const userDetails = getCurrentUser()
	const getTempRequest = {
		requestId: Math.floor(Math.random() * 89999 + 10000),
		bookingTemplate: {
			templateName: templateName,
			agentCode: userDetails.agentCode,
		},
	}



	const getDraftDetailsRequest = {
		requestId: uuidv4(),
		bookingDraft: {
			draftName: templateName,
			userId: userDetails.userId,
			// agentCode: userDetails.user,
		},
	}

	const getDraftDetailsDeleteRequest = {
		requestId: uuidv4(),
		draftName: templateName,
		userId: userDetails.userId,
	}

	const listDraftRequest = {
		requestId: uuidv4(),
		agentCode: userDetails.agentCode,
	}

	setIsLoading(true)
	if (type === 'DRAFT') {
		const responseDraft = await getDraftsDetails(getDraftDetailsRequest)

			.then(async (response) => {
				setToSessionStorage('draftSelected', templateName)
				setToSessionStorage('templateSelected', '')
				// setIsLoading(false)
				if (
					response &&
					response.bookingDraft &&
					response.bookingDraft.length > 0 &&
					response.bookingDraft[0].draftParameters
				) {
					const draftParameters = JSON.parse(
						response.bookingDraft[0].draftParameters
					)
					draftParameters.shippingDate = ''
					draftParameters.orginalShipingDate = ''
					draftParameters.arrivalDate = null
					//  DG DRAFT

					// UNID Validation

					const unidDataList = await getUnidDataList();

					let unidDataMap = {}
					let unidDataMap1 = {}
					if (unidDataList) {
						unidDataList.filter(item => {
							unidDataMap[item.id] = item.shcCodes
							unidDataMap1[item.unid] = item.shcCodes
						})
					}

					const unidList = []
					const unidSccList = []




					draftParameters?.additionalCommodityDetail?.unidInfo?.filter(item => {
						let unidMultiple = []
						let unidArr = []
						if (unidDataMap && unidDataMap[item?.id]) {
							const unidScc = unidDataMap[item?.id].split(',')
							unidScc.filter(undiItem => {
								unidSccList.push(undiItem)
							})

							unidDataList.filter(undiItem => {
								if (item?.unid === undiItem?.unid) {
									unidMultiple.push(undiItem.shcCodes)
									if (undiItem?.packingGroup === '') {

										unidArr.push(
											{
												"label": `${undiItem?.shippingName}`,
												"value": `${undiItem?.shippingName} + ${undiItem?.id}`,
												'id': undiItem?.id
											}

										);
									}

									else {

										unidArr.push(
											{
												"label": `${undiItem?.shippingName} (PG ${undiItem?.packingGroup})`,
												"value": `${undiItem?.shippingName} (PG ${undiItem?.packingGroup}) + ${undiItem?.id}`,
												'id': undiItem?.id
											}
										);
									}
								}
							})
						}
						let dropDownVal = ''
						if (item && item.packingGroup && item.packingGroup !== '') {
							dropDownVal = `${item?.shippingName} (PG ${item?.packingGroup}) + ${item?.id}`
						} else {
							dropDownVal = `${item?.shippingName} + ${item?.id}`
						}
						unidList?.push({
							id: item?.id,
							unid: item?.unid,
							unidSelectedValidation: [],
							unidName: item?.shippingName,
							specialProvison: true,
							dangerousGoods: true,
							packingGroup: item?.packingGroup,
							unidSccs: unidDataMap && unidDataMap[item?.id] ? unidDataMap[item?.id].split(',') : [],
							multiunidCheck: unidArr,
							dropdown: unidArr.length > 0 ? dropDownVal : '',
						})


					})

					draftParameters.unid = unidList
					draftParameters.dgbookcheck = unidList && unidList?.length > 0 && unidList[0]?.dangerousGoods ? true : false

					draftParameters.dgAttachementDetails = draftParameters?.specializedCommodity && draftParameters?.specializedCommodity ? draftParameters?.specializedCommodity : null

					setSearchFormDataToState(draftParameters)
					setIsLoading(false)
					// deleteDrafts(getDraftDetailsDeleteRequest)
					listDrafts(listDraftRequest)
					if (draftParameters.additionalInfo) {
						setAdditionalInfoData(draftParameters.additionalInfo)
					}
					return draftParameters
				} else {
					return null
				}
			})
			.catch((error) => {
				console.log(error)
				setIsLoading(false)
				return null
			})
		return responseDraft
	} else {
		const responseTemplate = await getTemplate(getTempRequest)
			.then(async (response) => {


				setToSessionStorage('templateSelected', templateName)
				setToSessionStorage('draftSelected', '')

				if (
					response &&
					response.bookingTemplate &&
					response.bookingTemplate.length > 0 &&
					response.bookingTemplate[0].templateParameters
				) {
					const templateParameters = JSON.parse(
						response.bookingTemplate[0].templateParameters
					)
					templateParameters.shippingDate = ''
					templateParameters.orginalShipingDate = ''
					templateParameters.arrivalDate = null

					// UNID Validation

					const unidDataList = await getUnidDataList();

					let unidDataMap = {}
					let unidDataMap1 = {}
					if (unidDataList) {
						unidDataList.filter(item => {
							unidDataMap[item.id] = item.shcCodes
							unidDataMap1[item.unid] = item.shcCodes
						})
					}

					const unidList = []
					const unidSccList = []


					templateParameters?.additionalCommodityDetail?.unidInfo?.filter(item => {
						let unidMultiple = []
						let unidArr = []
						if (unidDataMap && unidDataMap[item?.id]) {
							const unidScc = unidDataMap[item?.id].split(',')
							unidScc.filter(undiItem => {
								unidSccList.push(undiItem)
							})

							unidDataList.filter(undiItem => {
								if (item?.unid === undiItem?.unid) {
									unidMultiple.push(undiItem.shcCodes)
									if (undiItem?.packingGroup === '') {

										unidArr.push(
											{
												"label": `${undiItem?.shippingName}`,
												"value": `${undiItem?.shippingName} + ${undiItem?.id}`,
												'id': undiItem?.id
											}

										);
									}

									else {

										unidArr.push(
											{
												"label": `${undiItem?.shippingName} (PG ${undiItem?.packingGroup})`,
												"value": `${undiItem?.shippingName} (PG ${undiItem?.packingGroup}) + ${undiItem?.id}`,
												'id': undiItem?.id
											}
										);
									}
								}
							})
						}
						let dropDownVal = ''
						if (item && item.packingGroup && item.packingGroup !== '') {
							dropDownVal = `${item?.shippingName} (PG ${item?.packingGroup}) + ${item?.id}`
						} else {
							dropDownVal = `${item?.shippingName} + ${item?.id}`
						}
						unidList?.push({
							id: item?.id,
							unid: item?.unid,
							unidSelectedValidation: [],
							unidName: item?.shippingName,
							specialProvison: true,
							dangerousGoods: true,
							packingGroup: item?.packingGroup,
							unidSccs: unidDataMap && unidDataMap[item?.id] ? unidDataMap[item?.id].split(',') : [],
							multiunidCheck: unidArr,
							dropdown: unidArr.length > 0 ? dropDownVal : '',
						})


					})

					templateParameters.unid = unidList
					templateParameters.dgbookcheck = unidList && unidList?.length > 0 && unidList[0].dangerousGoods ? true : false

					setSearchFormDataToState(templateParameters)

					setIsLoading(false)
					if (templateParameters.additionalInfo) {
						setAdditionalInfoData(templateParameters.additionalInfo)
					}
					return templateParameters
				} else {
					return null
				}
			})
			.catch((error) => {
				console.log(error)
				setIsLoading(false)
				return null
			})
		return responseTemplate

	}

	// return type === 'Draft' ? responseDraft : responseTemplate
	// if (type === 'Draft') {
	// 	return responseDraft
	// } else {
	// 	responseTemplate
	// }
}

const getRecentBookingRequest = (awbNo) => {
	const userDetails = getCurrentUser()
	const docNumber = awbNo.trim().substring(3).trim()
	const request = {
		requestId: getRandomIntInclusive(10000, 99999),
		shipmentIdentifier: {
			shipmentPrefix: awbNo.trim().substring(0, 3).trim(),
			masterDocumentNumber: docNumber,
		},
		agentCode: userDetails ? userDetails.agentCode : null
	}
	return request
}

const getAWBRequest = (awbNo) => {
	const docNumber = awbNo.substring(3).trim()
	const prefix = awbNo.substring(0, 3)
	const userDetails = getCurrentUser()
	const request = {
		requestId: getRandomIntInclusive(10000, 99999),
		shipmentIdentifier: {
			shipmentPrefix: prefix,
			masterDocumentNumber: docNumber,
			agentCode: userDetails ? userDetails.agentCode : null,
		},
	}
	return request
}

export const getRecentBookingDetails = async (
	setSearchFormDataToState,
	setAdditionalInfoData,
	shippingItems,
	awbNo,
	setIsLoading,
	isModifyBooking
) => {
	setIsLoading(true)
	const details = await getRecentBooking(getRecentBookingRequest(awbNo))
		.then((response) => {
			// setIsLoading(false)
			if (response && response.bookingDetails) {
				return response
			} else {
				setIsLoading(false)
				if (
					response &&
					response.errorDetails &&
					response.errorDetails.length > 0
				) {
					return response.errorDetails[0]
				} else {
					return null
				}
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)
			return null
		})
	if (details && !details.errorDescription) {
		const data = await populateBookingDetails(
			details,
			shippingItems,
			isModifyBooking,
			setIsLoading
		)
		setSearchFormDataToState(data)
		if (data.additionalInfo) {
			setAdditionalInfoData(data.additionalInfo)
		}
		return data
	} else if (details && details.errorDescription) {
		return details
	}
}

export const getUnidDataList = async () => {
	const unidDataStored = getFromSessionStorage('UNID_DATA')
	if (unidDataStored) {
		const unidDataList = JSON.parse(unidDataStored)
		return unidDataList;
	} else {
		const unidDataList = await getUnidInfo();
		if (unidDataList && unidDataList.length > 0) {
			return unidDataList;
		} else {
			return []
		}
	}
}

export const populateBookingDetails = async (
	details,
	shippingItems,
	isModifyBooking,
	setIsLoading
) => {
	if (details && !details.errorDescription) {
		let shippingItem = getShippingItemDetails(
			shippingItems,
			details.bookingDetails.bookingCommodityDetails[0].commodityCode
		)
		if (!shippingItem || shippingItem.length === 0) {
			shippingItem = getShippingItemDetails(
				shippingItems,
				details.bookingDetails.bookingCommodityDetails[0].shipmentDescription
			)
		}
		const origin = await getAirportsByCode(
			details.bookingDetails.shipmentDetails.shipmentOrigin
		)
		const destination = await getAirportsByCode(
			details.bookingDetails.shipmentDetails.shipmentDestination
		)

		const unidDataList = await getUnidDataList();

		let unidDataMap = {}
		let unidDataMap1 = {}
		if (unidDataList) {
			unidDataList.filter(item => {
				unidDataMap[item.id] = item.shcCodes
				unidDataMap1[item.unid] = item.shcCodes
			})
		}

		const unidList = []
		const unidSccList = []
		const dgarrayListDoc = []
		details?.bookingDetails?.specializedCommodityAttachments?.filter(item => {
			dgarrayListDoc?.push({
				attachmentId: item?.attachmentId,
				fileName: item?.fileName,
				id: item?.id
			})
		})


		details?.bookingDetails?.additionalCommodityDetails?.unidInfo?.filter(item => {
			let unidMultiple = []
			let unidArr = []
			if (unidDataMap && unidDataMap[item?.id]) {
				const unidScc = unidDataMap[item?.id].split(',')
				unidScc.filter(undiItem => {
					unidSccList.push(undiItem)
				})

				unidDataList.filter(undiItem => {
					if (item?.unid === undiItem?.unid) {
						unidMultiple.push(undiItem.shcCodes)
						if (undiItem?.packingGroup === '') {

							unidArr.push(
								{
									"label": `${undiItem?.shippingName}`,
									"value": `${undiItem?.shippingName} + ${undiItem?.id}`,
									'id': undiItem?.id
								}

							);
						}

						else {

							unidArr.push(
								{
									"label": `${undiItem?.shippingName} (PG ${undiItem?.packingGroup})`,
									"value": `${undiItem?.shippingName} (PG ${undiItem?.packingGroup}) + ${undiItem?.id}`,
									'id': undiItem?.id
								}
							);
						}
					}
				})
			}
			let dropDownVal = ''
			if (item && item.packingGroup && item.packingGroup !== '') {
				dropDownVal = `${item?.shippingName} (PG ${item?.packingGroup}) + ${item?.id}`
			} else {
				dropDownVal = `${item?.shippingName} + ${item?.id}`
			}
			unidList?.push({
				id: item?.id,
				unid: item?.unid,
				unidSelectedValidation: [],
				unidName: item?.shippingName,
				specialProvison: true,
				dangerousGoods: true,
				packingGroup: item?.packingGroup,
				unidSccs: unidDataMap && unidDataMap[item?.id] ? unidDataMap[item?.id].split(',') : [],
				multiunidCheck: unidArr,
				dropdown: unidArr.length > 0 ? dropDownVal : '',
			})

		})
		const dgCheckNormal = getFromSessionStorage('ifDg')
		const shippingType =
			details.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills &&
				details.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills.length >
				0
				? 'ULD'
				: 'LOOSE'
		const data = {
			origin: {
				code: details.bookingDetails.shipmentDetails.shipmentOrigin,
				name: origin.name,
				cityCode: details.bookingDetails.shipmentDetails.shipmentOrigin,
				countryCode: origin.countryCode,
				active: origin.active,
				latitude: origin.latitude,
				longitude: origin.longitude,
				geoFencingRadius: origin.geoFencingRadius,
				geoFencingRadiusUnit: origin.geoFencingRadiusUnit,
			},
			destination: {
				code: details.bookingDetails.shipmentDetails.shipmentDestination,
				name: destination.name,
				cityCode: details.bookingDetails.shipmentDetails.shipmentDestination,
				countryCode: destination.countryCode,
				active: destination.active,
				latitude: destination.latitude,
				longitude: destination.longitude,
				geoFencingRadius: destination.geoFencingRadius,
				geoFencingRadiusUnit: destination.geoFencingRadiusUnit,
			},
			orginalShipingDate: isModifyBooking
				? getShippingDate(details.bookingDetails.shipmentDetails.shippingDate)
				: '',
			shippingDate: isModifyBooking
				? details.bookingDetails.shipmentDetails.shippingDate
				: '',
			shippingItemCode: getShippingItem(shippingItem),
			shippingType: shippingType,
			weightUnit: details.bookingDetails.weightUnit,
			dimensionUnit: 'C',
			currency: details.bookingDetails.shipmentDetails.currency,
			shipmentSccs:
				details.bookingDetails.bookingCommodityDetails[0].handlingCodes &&
				getShipmentSccs(
					details.bookingDetails.bookingCommodityDetails[0].handlingCodes
						.handlingCode
				),
			appliedSccs: getAppliedSccs(
				details.bookingDetails.sccCodes,
				details.bookingDetails.bookingCommodityDetails[0].sccCode
			),
			additionalSccs: [],
			appliedServices: getAppliedServices(details.bookingDetails.sccCodes),
			sccCodes: getSccs(details.bookingDetails.sccCodes),
			sccCodesList: details.bookingDetails.sccCodes,
			totalWeight: details.bookingDetails.shipmentDetails.totalWeight,
			totalVolumeInMtrs:
				details.bookingDetails.bookingCommodityDetails[0].volume,
			totalWeightInKg: details.bookingDetails.shipmentDetails.totalWeight,
			actualGrossWeight:
				details.bookingDetails.shipmentDetails.totalDisplayWeight,
			grossWeightInSelectedUnit:
				details.bookingDetails.shipmentDetails.totalWeight,
			calculatedTotalWeight: details.bookingDetails.shipmentDetails.totalWeight,
			actualTotalIneItemWeight:
				details.bookingDetails.shipmentDetails.totalWeight,
			actualVolume: details.bookingDetails.bookingCommodityDetails[0].volume,
			displayVolume: details.bookingDetails.bookingCommodityDetails[0].volume,
			actualChargeableWeight:
				details.bookingDetails.bookingCommodityDetails[0].chargeableWeight,
			totalPieces: details.bookingDetails.shipmentDetails.totalNumberOfPieces,
			adjustedWeight:
				details.bookingDetails &&
				details.bookingDetails.bookingCommodityDetails[0] &&
				details.bookingDetails.bookingCommodityDetails[0].adjustedWeight &&
				details.bookingDetails.bookingCommodityDetails[0].adjustedWeight,

			looseDetails:
				shippingType === 'LOOSE'
					? getLooseDetails(
						details.bookingDetails.bookingCommodityDetails[0]
							.dimensionDetaills,
						false
					)
					: null,
			isAllStackable: true,
			isAllTiltable: true,
			ULDDetails: getULDDetails(
				details.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills
			),
			chargeableWeight:
				details.bookingDetails.bookingCommodityDetails[0].chargeableWeight,
			mixLooseDetails:
				shippingType === 'ULD'
					? getMixDetails(
						details.bookingDetails.bookingCommodityDetails[0]
							.dimensionDetaills
					)
					: [getInitLooseItemDetail()],
			totalLoosePieces: details.bookingDetails.bookingCommodityDetails[0]
				.dimensionDetaills
				? details.bookingDetails.bookingCommodityDetails[0].dimensionDetaills
					.length
				: 0,
			expandAccordian: isOverFlowExpanded(
				details.bookingDetails.bookingCommodityDetails[0].dimensionDetaills,
				shippingType
			),
			priceCode: null,
			crates: details.bookingDetails.cages ? details.bookingDetails.cages : [],
			unid: unidList,
			dgAttachementDetails: dgarrayListDoc,
			unidSccList: unidSccList,
			dgbookcheck: details?.bookingDetails?.additionalCommodityDetails?.dangerousGoods ? true : false,
			handlingInformation: details?.bookingDetails?.handlingInformation,
			promoCode: details?.bookingDetails?.promoCode,
			spotRateDetails: {
				spotRate: details?.bookingDetails?.spotRate,
				allInRate: details?.bookingDetails?.allInRate,
				spotRateCode: details?.bookingDetails?.spotRateCode,
				spotRateStatus: details?.bookingDetails?.spotRate && details?.bookingDetails?.spotRate !== 0
					? details?.bookingDetails?.spotRateStatus
					: '',
				spotRateRequestedUser: details?.bookingDetails?.spotRateRequestedUser,
			},
			//spotRateCode: details?.bookingDetails?.spotRateCode,
		}

		if (isModifyBooking) {
			data.bookingFlightDetails = details.bookingDetails.bookingFlightDetails
			data.isModifyBooking = true
		}

		if (
			isModifyBooking &&
			details?.bookingDetails?.spotRate
		) {
			setToSessionStorage('spotRate', details.bookingDetails.spotRate)
			setToSessionStorage('allInRate', details.bookingDetails.allInRate)
		}

		if (
			data &&
			data.shippingItemCode &&
			(data.shippingItemCode.product === 'PharmaLife' ||
				data.shippingItemCode.product === 'FreshForward')
		) {
			const handlingcode = details.bookingDetails.bookingCommodityDetails &&
				details.bookingDetails.bookingCommodityDetails[0].handlingCodes
				? details.bookingDetails.bookingCommodityDetails[0].handlingCodes
					.handlingCode : null
			const additionalDetails = await getAdditionaFormInfoDetails(
				data.shippingItemCode,
				handlingcode,
				details.bookingDetails.sccCodes,
				setIsLoading,
				details.bookingDetails.handlingInformation
			)
			if (additionalDetails) {
				data.additionalInfo = additionalDetails
			}
		}
		return data
	}
}

const getAdditionaFormInfoDetails = async (
	shippingItemCode,
	handlingCodes,
	sccCodes,
	setIsLoading,
	handlingInformation
) => {
	const request = {
		commodity: shippingItemCode.code,
		product: shippingItemCode.serviceProduct || shippingItemCode.product,
		sccList: getSccsArr(sccCodes),
	}
	setIsLoading(true)
	const additionalDetails = await getAdditionaFormInfo(request)
		.then((response) => {
			//setIsLoading(false)
			if (response) {
				return response
			} else {
				return null
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)
			return null
		})
	const additionalInfo = {
		type: 'pharma',
		pharma: {
			shippingPharmaType: additionalDetails && additionalDetails.shipmentType,
			activeContainerType:
				additionalDetails && additionalDetails.shipmentType === 'ACTIVE'
					? getContainerType(additionalDetails.containerType)
					: null,
			hybridContainerType:
				additionalDetails && additionalDetails.shipmentType === 'HYBRID'
					? getContainerType(additionalDetails.containerType)
					: null,
			tempratureRange: handlingInformation && handlingInformation === PHARMA_TEMP_RANGE.NA ? PHARMA_TEMP_RANGE.NA : additionalDetails
				? getTemperatureRange(additionalDetails.tempratureRange)
				: null,
			isDryIceAdded:	additionalDetails && additionalDetails.dryIceInluded ? 'YES' : 'NO',
		},
	}
	return additionalInfo
}

const getContainerType = (containerTypeCode) => {
	if (containerTypeCode === 'OWN_HYBRID') {
		return 'OWN HYBRID CONTAINER'
	} else if (containerTypeCode === 'OWN_ACTIVE') {
		return 'OWN ACTIVE CONTAINER'
	} else {
		return ''
	}
}

const getTemperatureRange = (tempratureRangeCode) => {
	if (tempratureRangeCode === 'FIRST') {
		return '2-8 C'
	} else if (tempratureRangeCode === 'SECOND') {
		return '15-25 C'
	} else if (tempratureRangeCode === 'THIRD') {
		return '2-25 C'
	} else if (tempratureRangeCode === 'FROZEN') {
		return 'Frozen'
	} else {
		return ''
	}
}

const getShippingDate = (shippingDate) => {
	if (shippingDate) {
		// const date = new Date(shippingDate)
		return shippingDate
	} else {
		return ''
	}
}

const getShipmentDescription = (shipmentDescription, dataCaptureFlag) => {
	if (shipmentDescription && dataCaptureFlag === 'Y') {
		return {
			shipmentDescription: shipmentDescription,
		}
	} else {
		return {
			shipmentDescription: '',
		}
	}
}

const getMixDetails = (dimensionDetails) => {
	if (dimensionDetails && dimensionDetails.length > 0) {
		return getLooseDetails(dimensionDetails, true)
	} else {
		return [getInitLooseItemDetail()]
	}
}

const getLooseDetails = (dimensionDetails, isMix) => {
	let looseDetails = []
	if (dimensionDetails && dimensionDetails.length > 0) {
		dimensionDetails.forEach(function (dimension) {
			const looseDetail = {
				pieces: dimension.numberOfPieces,
				length: dimension.length ? Math.round(dimension.length) : '',
				width: dimension.width ? Math.round(dimension.width) : '',
				height: dimension.height ? Math.round(dimension.height) : '',
				weightType: DETAILS_APPLY_FOR.TOTAL,
				weight: dimension.weight ? dimension.weight : '',
				stackable: dimension.stackable === 'N' ? false : true,
				turnable: dimension.tiltable === 'Y' ? true : false,
			}
			looseDetails.push(looseDetail)
		})
		return looseDetails
	} else {
		return null
	}
}

export const getSccs = (sccCodes) => {
	if (sccCodes && sccCodes !== '') {
		// const sccArr = sccCodes.split(',')
		let sccDetails = []
		sccCodes.forEach(function (scc) {
			if (scc !== 'XPS') {
				const sccDetail = {
					scc: scc,
					sccDescription: null,
				}
				sccDetails.push(sccDetail)
			}
		})
		return removeDuplicates(sccDetails)
	}
}

const getAppliedServices = (sccCodes) => {
	let sccDetails = []
	if (sccCodes && sccCodes.includes('XPS')) {
		const sccDetail = {
			scc: 'XPS',
			sccDescription: DISPLAY_SERVICES['XPS'],
		}
		sccDetails.push(sccDetail)
	}
	return sccDetails
}

const getAppliedSccs = (sccCodes, sccCode) => {
	if (sccCodes && sccCodes !== '') {
		// const sccArr = sccCodes.split(',')
		let sccDetails = []
		sccCodes.forEach(function (scc) {
			if (scc !== sccCode && scc !== 'XPS') {
				const sccDetail = {
					scc: scc,
					sccDescription: null,
				}
				sccDetails.push(sccDetail)
			}
		})
		return removeDuplicates(sccDetails)
	}
}

const getSccsArr = (sccCodes) => {
	if (sccCodes && sccCodes.length > 0) {
		let sccDetails = []
		sccCodes.forEach(function (scc) {
			sccDetails.push(scc)
		})
		return sccDetails
	}
}

const getShipmentSccs = (handlingCodes) => {
	if (handlingCodes && handlingCodes.length > 0) {
		let sccDetails = []
		handlingCodes.forEach(function (handlingCode) {
			const sccDetail = {
				scc: handlingCode.code,
				sccDescription: null,
			}
			sccDetails.push(sccDetail)
		})
		return sccDetails
	}
}

const getShippingItemDetails = (shippingItems, shippingItemCode) => {
	let shippingItem = shippingItems.filter(function (item) {
		return item.code === shippingItemCode
	})
	return shippingItem
}

export const removeDuplicates = (array) => {
	if (array) {
		let setObj = new Set()
		let result = array.reduce((acc, item) => {
			if (!setObj.has(item.scc)) {
				setObj.add(item.scc, item)
				acc.push(item)
			}
			return acc
		}, [])
		return result
	}
}

export const updateTemplateDetails = async (request, setIsLoading) => {
	setIsLoading(true)
	const updateTemplateResponse = await updateTemplate(request)
		.then((response) => {
			setIsLoading(false)
			if (response) {
				return response
			} else {
				return null
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)
			return null
		})
	return updateTemplateResponse
}

// console.log("searchFormData", searchFormData)

export const getBookingFlightDetails = (searchFormData, shippingDate) => {
	if (searchFormData && searchFormData.bookingFlightDetails) {
		let bookingFlightDetails = searchFormData.bookingFlightDetails
		const flightDateSelected = shippingDate
			? moment(shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
			: null
		if (
			searchFormData.origin.code !== bookingFlightDetails[0].segmentOrigin ||
			searchFormData.destination.code !==
			bookingFlightDetails[bookingFlightDetails.length - 1].segmentDestination
		) {
			return null
		}
		if (
			flightDateSelected &&
			bookingFlightDetails[0].flightDate !== flightDateSelected
		) {
			let flightDetails = []
			bookingFlightDetails.forEach((flight, i) => {
				let flightDetail = {
					segmentOrigin: flight.segmentOrigin,
					segmentDestination: flight.segmentDestination,
					carrierCode: flight.carrierCode,
					flightNumber: flight.flightNumber,
					flightDate: flightDateSelected,
					aircraftType: flight.aircraftType,
				}
				flightDetails.push(flightDetail)
			})

			return flightDetails
		} else {
			return bookingFlightDetails
		}
	} else {
		return null
	}
}

const getULDDetails = (uldInfo) => {
	let uldDetails = []
	if (uldInfo && uldInfo.length > 0) {
		uldInfo.forEach((uld, i) => {
			let uldDetail = {
				weight: uld.uldWeight ? uld.uldWeight : '',
				uldType: uld.uldType ? uld.uldType : null,
				pieces: uld.numberOfUlds ? uld.numberOfUlds : null,
				contour: uld.contour ? uld.contour : null,
				uldVolume: uld.uldVolume ? uld.uldVolume : null,
			}
			uldDetails.push(uldDetail)
		})
		return uldDetails
	} else {
		return null
	}
}

const isOverFlowExpanded = (dimensionDetaills, shippingType) => {
	if (
		shippingType === 'ULD' &&
		dimensionDetaills &&
		dimensionDetaills.length > 0
	) {
		return true
	} else {
		return false
	}
}

export const getAWBDetails = async (
	shippingItems,
	awbNo,
	setIsLoading,
	isModifyBooking
) => {
	setIsLoading(true)
	const details = await getAWB(getAWBRequest(awbNo))
		.then((response) => {
			// setIsLoading(false)
			if (response && response.awbDetails) {
				return response
			} else {
				setIsLoading(false)
				if (
					response &&
					response.errorDetails &&
					response.errorDetails.length > 0
				) {
					return response.errorDetails[0]
				} else {
					return null
				}
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)
			return null
		})
	if (details && !details.errorDescription) {
		const data = await populateAWbDetails(
			details.awbDetails,
			shippingItems,
			isModifyBooking,
			setIsLoading
		)
		return data
	} else if (details && details.errorDescription) {
		return details
	}
}

export const populateAWbDetails = async (
	details,
	shippingItems,
	isModifyBooking,
	setIsLoading
) => {
	let shippingItem = getShippingItemDetails(
		shippingItems,
		details.awbRateLineDetails.awbRateLine[0].commodityCode
	)
	if (!shippingItem || shippingItem.length === 0) {
		shippingItem = getShippingItemDetails(
			shippingItems,
			details.awbRateLineDetails.awbRateLine[0].itemDescription
		)
	}
	const origin = await getAirportsByCode(
		details.awbConsignmentDetails.awbOriginDestination.awbOrigin
	)
	const destination = await getAirportsByCode(
		details.awbConsignmentDetails.awbOriginDestination.awbDestination
	)
	const shipperDetails = await getParticpantDetails(
		details.awbShipperDetails ? details.awbShipperDetails : {}
	)
	const consigneeDetails = await getParticpantDetails(
		details.awbConsigneeDetails ? details.awbConsigneeDetails : {}
	)
	const shippingType =
		details.awbRateLineDetails.awbRateLine[0].uldDetails &&
			details.awbRateLineDetails.awbRateLine[0].uldDetails.ulds &&
			details.awbRateLineDetails.awbRateLine[0].uldDetails.ulds.length > 0
			? 'ULD'
			: 'LOOSE'
	const freightCharge = details.awbRateLineDetails.awbRateLine[0]
		.itemTotalCharge
		? details.awbRateLineDetails.awbRateLine[0].itemTotalCharge
		: 0.0
	const netCharge = details.awbSupplementaryDetails.awbAirlineChargeDetails
		? details.awbSupplementaryDetails.awbAirlineChargeDetails.netNetCharge
		: 0.0
	const surcharges = calculateSurcharges(details)
	const totalCharge = getDecimalFormattedNumber(
		parseFloat(netCharge) + parseFloat(surcharges)
	)
	const totalCharges = calculateTotalCharges(details)
	const shipmentSccs = getAWBShipmentSccs(
		details.awbHandlingInfoDetails.specialHandlingCodes.handlingCode, shippingItem
	)
	const additionalSccs = getAWBAppliedSccs(
		details.awbHandlingInfoDetails.specialHandlingCodes.handlingCode, shippingItem
	)
	const sccCodes = [...shipmentSccs, ...additionalSccs]
	const data = {
		awb: details.awbConsignmentDetails,
		origin: {
			code: details.awbConsignmentDetails.awbOriginDestination.awbOrigin,
			name: origin.name,
			cityCode: details.awbConsignmentDetails.awbOriginDestination.awbOrigin,
			countryCode: origin.countryCode,
			active: origin.active,
			latitude: origin.latitude,
			longitude: origin.longitude,
			geoFencingRadius: origin.geoFencingRadius,
			geoFencingRadiusUnit: origin.geoFencingRadiusUnit,
		},
		destination: {
			code: details.awbConsignmentDetails.awbOriginDestination.awbDestination,
			name: destination.name,
			cityCode:
				details.awbConsignmentDetails.awbOriginDestination.awbDestination,
			countryCode: destination.countryCode,
			active: destination.active,
			latitude: destination.latitude,
			longitude: destination.longitude,
			geoFencingRadius: destination.geoFencingRadius,
			geoFencingRadiusUnit: destination.geoFencingRadiusUnit,
		},
		orginalShipingDate: isModifyBooking
			? getShippingDate(details.reqFlightDate)
			: '',
		shippingDate: details.reqFlightDate,
		flightNumber: details.reqFlightNumber,
		ubrNumber: details.ubrNumber,
		flightCarrierCode: details.reqFlightCarrierCode,
		shipmentDescriptionData: getShipmentDescription(details.awbConsignmentDetails.shipmentDescription, details.dataCaptureFlag),
		shipmentDescription: details.awbConsignmentDetails.shipmentDescription,
		dataCaptureFlag: details.dataCaptureFlag,
		shippingItemCode: getShippingItem(shippingItem),
		productCode: details.productDetails?.productCode,
		shippingType: shippingType,
		weightUnit: details.awbConsignmentDetails.quantityDetails.weightCode,
		dimensionUnit: 'C',
		volumeUnit: getVolumeUnit(details.awbConsignmentDetails.volumeDetails),
		currency: details.awbChargeDeclaration.awbCurrencyCode,
		shipmentSccs: shipmentSccs,
		appliedSccs: [],
		additionalSccs: additionalSccs,
		appliedServices: [],
		sccCodes: sccCodes, //getSccs(details.bookingDetails.sccCodes),
		totalWeight:
			details.awbConsignmentDetails.quantityDetails.totalStatedWeight,
		totalVolumeInMtrs: details.awbConsignmentDetails.volumeDetails.volumeAmount,
		totalWeightInKg:
			details.awbConsignmentDetails.quantityDetails.totalStatedWeight,
		actualGrossWeight:
			details.awbConsignmentDetails.quantityDetails.totalStatedWeight,
		grossWeightInSelectedUnit:
			details.awbConsignmentDetails.quantityDetails.totalStatedWeight,
		calculatedTotalWeight:
			details.awbConsignmentDetails.quantityDetails.totalStatedWeight,
		actualTotalIneItemWeight:
			details.awbConsignmentDetails.quantityDetails.totalStatedWeight,
		actualVolume: details.awbConsignmentDetails.volumeDetails.volumeAmount,
		displayVolume: details.awbConsignmentDetails.volumeDetails.volumeAmount,
		actualChargeableWeight:
			details.awbRateLineDetails.awbRateLine[0].chargeableWeight,
		totalPieces:
			details.awbConsignmentDetails.quantityDetails.totalNumberOfPieces,
		looseDetails:
			shippingType === 'LOOSE'
				? getAWBLooseDetails(
					details.awbRateLineDetails.awbRateLine[0].dimensionsDetails,
					false
				)
				: [getInitLooseItemDetail()],
		isAllStackable: false,
		isAllTiltable: false,
		ULDDetails:
			details.awbRateLineDetails.awbRateLine[0].uldDetails &&
				details.awbRateLineDetails.awbRateLine[0].uldDetails.ulds
				? getAWBULDDetails(
					details.awbRateLineDetails.awbRateLine[0].uldDetails.ulds
				)
				: [getInitULDItemDetail()],
		chargeableWeight:
			details.awbRateLineDetails.awbRateLine[0].chargeableWeight,
		chargeableWeightInSelectedUnit:
			details.awbRateLineDetails.awbRateLine[0].chargeableWeight,
		mixLooseDetails:
			shippingType === 'ULD'
				? getAWBMixDetails(
					details.awbRateLineDetails.awbRateLine[0].dimensionsDetails
				)
				: [getInitLooseItemDetail()],
		totalLoosePieces:
			details.awbRateLineDetails.awbRateLine[0].dimensionsDetails &&
				details.awbRateLineDetails.awbRateLine[0].dimensionsDetails.dimension
				? details.awbRateLineDetails.awbRateLine[0].dimensionsDetails.dimension
					.length
				: 0,
		expandAccordian: details.awbRateLineDetails.awbRateLine[0].dimensionsDetails
			? isOverFlowExpanded(
				details.awbRateLineDetails.awbRateLine[0].dimensionsDetails.dimension,
				shippingType
			)
			: false,
		priceCode: null,
		awbRouting: getRouting(details.awbRoutingDetails.awbRouting),
		awbRateLineDetails: {
			rate: details.awbRateLineDetails.awbRateLine[0]
				.rateOrChargeOrDiscountAmount
				? details.awbRateLineDetails.awbRateLine[0].rateOrChargeOrDiscountAmount
				: 0.0,
			freightCharge: freightCharge,
			netCharge: netCharge,
			rateClassCode: details.awbRateLineDetails.awbRateLine[0].rateClassCode,
			surcharges: surcharges,
			otherCharges:
				details.awbOtherChargeDetails &&
					details.awbOtherChargeDetails.awbOtherCharges
					? details.awbOtherChargeDetails.awbOtherCharges
					: [],
			totalCharge: totalCharges,
			paymentType:
				details.awbSupplementaryDetails.awbAirlineChargeDetails.paymentType,
			airlineTariffName:
				details.awbRateLineDetails.awbRateLine[0].airlineTariffName,
			airlineTariffSerialNumber:
				details.awbRateLineDetails.awbRateLine[0].airlineTariffSerialNumber,
			airlineTariffRateSerialNumber:
				details.awbRateLineDetails.awbRateLine[0].airlineTariffRateSerialNumber,
			airlineRateLineId:
				details.awbRateLineDetails.awbRateLine[0].airlineRateLineId,
			airlineRatingType:
				details.awbRateLineDetails.awbRateLine[0].airlineRatingType,
		},
		awbChargeDeclaration: details.awbChargeDeclaration,
		sci: details.customsOriginCode,
		customsInformationDetails: getCustomsInformationDetails(details),
		accountingInformationDetails: getAwbAccountingInformationDetails(
			details.awbAccountingInformationDetails
		),
		awbExecutionDetails: getExecutionDetails(details.awbExecutionDetails),
		slacPieces: '',
		shipperDetails: shipperDetails,
		consigneeDetails: consigneeDetails,
		agentDetails: getAgentDetails(
			details.awbAgentDetails ? details.awbAgentDetails : {}
		),
		notifyList: await getNotificationDetails(details.additionalNotificationDetails),
		handlingInfo: getHandlingInfo(details.awbHandlingInfoDetails),
		hccs: getHCC(details.awbRateLineDetails.awbRateLine[0]),
		promoCode: details.uniqueReference && details.uniqueReference.uniqueReferenceFour
			? details.uniqueReference.uniqueReferenceFour
			: '',
	}

	if (
		data.awbChargeDeclaration &&
		!data.awbChargeDeclaration.prepaidCollectChargeDeclaration
	) {
		data.awbChargeDeclaration.prepaidCollectChargeDeclaration = {
			prepaidCollectIndicatorWgtValChgs: null,
			prepaidCollectIndicatorOthChgs: null,
		}
	}

	if (
		data &&
		data.shippingItemCode &&
		(data.shippingItemCode.product === 'PharmaLife' ||
			data.shippingItemCode.product === 'FreshForward')
	) {
		const additionalDetails = await getAdditionaFormInfoDetails(
			data.shippingItemCode,
			null,
			getAWbSccsArr(sccCodes),
			setIsLoading
		)
		if (additionalDetails) {
			data.additionalInfo = additionalDetails
		}
	}
	return data
}

const getCustomsInformationDetails = (details) => {
	const customsInformationDetails = details.otherCustomsInformationDetails
	let customsDetails = []
	if (
		customsInformationDetails &&
		customsInformationDetails.otherCustomsInformation &&
		customsInformationDetails.otherCustomsInformation.length
	) {
		customsInformationDetails.otherCustomsInformation.forEach(function (
			info,
			index
		) {
			let customsInfo = {
				informationId: info.informationIdentifier,
				customsInformationId: info.customsInformationIdentifier,
				supplementaryCustomsInformation:
					info.supplementaryCustomsControlInformation,
				countryCode: info.countryCode,
			}
			if (index > 0) {
				customsInfo.isNew = true
			}
			customsDetails.push(customsInfo)
		})
		return customsDetails
	} else {
		const info = {
			informationIdentifier: '',
			customsInformationIdentifier: '',
			supplementaryCustomsControlInformation: '',
			countryCode: '',
		}
		customsDetails.push(info)
		return customsDetails
	}
}

const getAwbAccountingInformationDetails = (accountingInformationDetails) => {
	let awbAccountingInformation = []
	if (
		accountingInformationDetails &&
		accountingInformationDetails.awbAccountingInformation &&
		accountingInformationDetails.awbAccountingInformation.length
	) {
		accountingInformationDetails.awbAccountingInformation.forEach(function (
			info,
			index
		) {
			let customsInfo = {
				accountingInformationIdentifier: checkBlankField(
					info.accountingInformationIdentifier
				),
				accountingInformation: checkBlankField(info.accountingInformation),
			}
			if (index > 0) {
				customsInfo.isNew = true
			}
			awbAccountingInformation.push(customsInfo)
		})
		return awbAccountingInformation
	} else {
		const customsInfo = {
			accountingInformationIdentifier: '',
			accountingInformation: '',
		}
		awbAccountingInformation.push(customsInfo)
		return awbAccountingInformation
	}
}

const getExecutionDetails = (awbExecutionDetails) => {
	if (awbExecutionDetails) {
		const executionInfo = {
			issuedBy: awbExecutionDetails.executedUser,
			issueDate: awbExecutionDetails.awbIssueDate
				? awbExecutionDetails.awbIssueDate
				: moment(new Date()).format('DD-MMM-yyyy HH:mm:ss'),
			issuedPlace: awbExecutionDetails.placeOfExecution,
		}
		return executionInfo
	} else {
		return {
			issuedBy: '',
			issueDate: '',
			issuedPlace: '',
		}
	}
}

const getAWBMixDetails = (dimensionDetails) => {
	if (
		dimensionDetails &&
		dimensionDetails.dimension &&
		dimensionDetails.dimension.length > 0
	) {
		return getAWBLooseDetails(dimensionDetails, true)
	} else {
		return [getInitLooseItemDetail()]
	}
}

const getAWBLooseDetails = (dimensionDetails, isMix) => {
	let looseDetails = []
	if (
		dimensionDetails &&
		dimensionDetails.dimension &&
		dimensionDetails.dimension.length > 0
	) {
		dimensionDetails.dimension.forEach(function (dimension) {
			const looseDetail = {
				pieces: dimension.numberOfPieces,
				length: dimension.lengthPerPiece
					? Math.round(dimension.lengthPerPiece)
					: '',
				width: dimension.widthPerPiece
					? Math.round(dimension.widthPerPiece)
					: '',
				height: dimension.heightPerpiece
					? Math.round(dimension.heightPerpiece)
					: '',
				weightType: DETAILS_APPLY_FOR.TOTAL,
				weight: dimension.weight,
				stackable:
					dimension.stackable && dimension.stackable === 'Y' ? true : false,
				turnable:
					dimension.tiltableFlag && dimension.tiltableFlag === 'Y'
						? true
						: false,
			}
			looseDetails.push(looseDetail)
		})
		return looseDetails
	} else {
		return [getInitLooseItemDetail()]
	}
}

const getAWBULDDetails = (ulds) => {
	let uldDetails = []
	if (ulds && ulds.length > 0) {
		ulds.forEach((uld, i) => {
			let uldDetail = {
				weight: uld.uldWeight ? uld.uldWeight : '',
				uldType: uld.uldNumberDetails ? uld.uldNumberDetails.uldtype : null,
				pieces: 1,
				contour: null,
				uldVolume: null,
			}
			uldDetails.push(uldDetail)
		})
		return uldDetails
	} else {
		return [getInitULDItemDetail]
	}
}

const getParticpantDetails = async (particpantDetails) => {
	const contactInfo =
		particpantDetails &&
			particpantDetails.customerContactDetails &&
			particpantDetails.customerContactDetails.customerContact &&
			particpantDetails.customerContactDetails.customerContact.length
			? particpantDetails.customerContactDetails.customerContact[0]
			: null
	const country = particpantDetails.customerCountryCode
		? await getCountryByCode(particpantDetails.customerCountryCode)
		: null
	const shipperDetails = {
		customerRole: checkBlankField(particpantDetails.customerRole),
		customerCode: checkBlankField(particpantDetails.customerCode),
		name: checkBlankField(particpantDetails.customerName),
		accountNumber: checkBlankField(particpantDetails.customerAccountNumber),
		address: checkBlankField(particpantDetails.customerStreetAddress),
		stationCode: checkBlankField(particpantDetails.stationCode),
		city: checkBlankField(particpantDetails.customerPlace),
		state: checkBlankField(particpantDetails.customerState),
		countryCode: country ? country : null,
		zipCode: checkBlankField(particpantDetails.customerZipCode),
		customerContactDetails: particpantDetails.customerContactDetails,
		telephoneNo: contactInfo
			? checkBlankField(contactInfo.customerPhoneNumber)
			: '',
		emailAddress: contactInfo
			? checkBlankField(contactInfo.customerEMailId)
			: '',
		participantIdentifier: checkBlankField(
			particpantDetails.participantIdentifier
		),
	}
	return shipperDetails
}

const getAgentDetails = (awbAgentDetails) => {
	const agentDetails = {
		code: checkBlankField(awbAgentDetails.agentCode),
		name: checkBlankField(awbAgentDetails.agentName),
		IATACode: awbAgentDetails.commissionableAgentDetails
			? checkBlankField(
				awbAgentDetails.commissionableAgentDetails.agentIATACode
			)
			: '',
		stationCode: checkBlankField(awbAgentDetails.stationCode),
	}
	return agentDetails
}

const getAWBAppliedSccs = (handlingCodes, shippingItem) => {
	if (handlingCodes && handlingCodes.length > 0) {
		let sccDetails = []
		handlingCodes.forEach(function (handlingCode) {

			if (!shippingItem || (shippingItem && shippingItem.length > 0 && shippingItem[0].scc !== handlingCode.code)) {
				const sccDetail = {
					scc: handlingCode.code,
					sccDescription: null,
				}
				sccDetails.push(sccDetail)
			}

		})
		return sccDetails
	}
}

const getAWBShipmentSccs = (handlingCodes, shippingItem) => {
	if (handlingCodes && handlingCodes.length > 0) {
		let sccDetails = []
		handlingCodes.forEach(function (handlingCode) {
			if (shippingItem && shippingItem.length > 0 &&
				shippingItem[0].scc === handlingCode.code) {
				const sccDetail = {
					scc: handlingCode.code,
					sccDescription: null,
				}
				sccDetails.push(sccDetail)
			}
		})
		return sccDetails
	}
}

const getAWbSccsArr = (sccCodes) => {
	if (sccCodes && sccCodes.length > 0) {
		let sccDetails = []
		sccCodes.forEach(function (scc) {
			sccDetails.push(scc.scc)
		})
		return sccDetails
	}
}

const checkBlankField = (field) => {
	return field ? field.trim() : ''
}

const getRouting = (awbRouting) => {
	let routings = []
	awbRouting.forEach(function (route) {
		const routing = {
			airport: route.destination,
			carrierCode: route.carrierCode,
		}
		routings.push(routing)
	})
	const routingNew = {
		airport: '',
		carrierCode: '',
		isNew: true,
	}
	if (routings.length === 0) {
		routings.push(routingNew)
		routings.push(routingNew)
	} else if (routings.length === 1) {
		routings.push(routingNew)
	}
	return routings
}

const getHCC = (awbRateLine) => {
	if (
		awbRateLine.harmonizedCommodityCodes &&
		awbRateLine.harmonizedCommodityCodes.harmonizedCommodityCode &&
		awbRateLine.harmonizedCommodityCodes.harmonizedCommodityCode.length
	) {
		return awbRateLine.harmonizedCommodityCodes.harmonizedCommodityCode.join()
	} else {
		return ''
	}
}

const getNotificationDetails = async (additionalNotificationDetails) => {
	let notifyList = {
		notifyShipper: {
			checked: false,
		},
		notifyConsignee: {
			checked: false,
		},
		notifyOther: {
			checked: true,
			name: '',
			telephoneNo: '',
			email: '',
			address: '',
			city: '',
			country: '',
		},
	}
	if (
		additionalNotificationDetails &&
		additionalNotificationDetails.additionalNotification &&
		additionalNotificationDetails.additionalNotification.length
	) {
		const additionalNotification =
			additionalNotificationDetails.additionalNotification
		if (additionalNotificationDetails && additionalNotification.length) {
			additionalNotification.forEach(async function (notification) {
				if (notification.customerRole === 'S') {
					notifyList.notifyShipper.checked = true
				} else if (notification.customerRole === 'C') {
					notifyList.notifyConsignee.checked = true
				} else {
					const country = notification.customerCountryCode ? await getCountryByCode(notification.customerCountryCode) : ''
					notifyList.notifyOther.checked = true
					notifyList.notifyOther.name = notification.customerName
					notifyList.notifyOther.address = notification.customerStreetAddress
					notifyList.notifyOther.city = notification.customerPlace
					notifyList.notifyOther.country = country

					if (
						notification.customerContactDetails &&
						notification.customerContactDetails.customerContact &&
						notification.customerContactDetails.customerContact.length
					) {
						notifyList.notifyOther.telephoneNo = checkBlankField(
							notification.customerContactDetails.customerContact[0]
								.customerPhoneNumber
						)
						notifyList.notifyOther.email = checkBlankField(
							notification.customerContactDetails.customerContact[0]
								.customerEMailId
						)
					}
				}
			})
		}
	}
	return notifyList
}

const getVolumeUnit = (volumeDetails) => {
	let volumeUnit = 'B'
	if (volumeDetails && volumeDetails.volumeCode) {
		volumeUnit = VOLUME_CODE_UNIT_MAPPING[volumeDetails.volumeCode]
		volumeUnit = volumeUnit ? volumeUnit : 'B'
	}
	return volumeUnit
}

const calculateSurcharges = (details) => {
	const { prepaidChargeSummary, collectChargeSummary } = details
	let surcharges = 0.0
	if (prepaidChargeSummary) {
		if (prepaidChargeSummary.totalOtherChargesDueCarrier) {
			surcharges =
				surcharges +
				parseFloat(prepaidChargeSummary.totalOtherChargesDueCarrier)
		}
		if (prepaidChargeSummary.totalOtherChargesDueAgent) {
			surcharges =
				surcharges + parseFloat(prepaidChargeSummary.totalOtherChargesDueAgent)
		}
	}
	if (collectChargeSummary) {
		if (collectChargeSummary.totalOtherChargesDueCarrier) {
			surcharges =
				surcharges +
				parseFloat(collectChargeSummary.totalOtherChargesDueCarrier)
		}
		if (collectChargeSummary.totalOtherChargesDueAgent) {
			surcharges =
				surcharges + parseFloat(collectChargeSummary.totalOtherChargesDueAgent)
		}
	}
	return surcharges
}

const calculateTotalCharges = (details) => {
	const { prepaidChargeSummary, collectChargeSummary } = details
	let total = 0.0
	if (prepaidChargeSummary && prepaidChargeSummary.totalPrepaidChargesSummary) {
		total = total + parseFloat(prepaidChargeSummary.totalPrepaidChargesSummary)
	}
	if (collectChargeSummary && collectChargeSummary.totalCollectChargesSummary) {
		total = total + parseFloat(collectChargeSummary.totalCollectChargesSummary)
	}
	return total
}

const getHandlingInfo = (awbHandlingInfoDetails) => {
	if (
		awbHandlingInfoDetails.otherServiceInformation &&
		awbHandlingInfoDetails.otherServiceInformation.length
	) {
		return checkBlankField(awbHandlingInfoDetails.otherServiceInformation[0])
	} else {
		return ''
	}
}

const getShippingItem = (shippingItem) => {
	if (shippingItem && shippingItem.length) {
		const shippingItemDetails = {
			key: shippingItem[0].key,
			code: shippingItem[0].code,
			description: shippingItem[0].description,
			scc: shippingItem[0].scc,
			product: shippingItem[0].product,
			densityFactor: shippingItem[0].densityFactor,
			iataCode: shippingItem[0].iataCode,
			status: shippingItem[0].status,
			awbNumber: shippingItem[0].awbNumber,
			grossWeight: shippingItem[0].grossWeight,
			pricing: shippingItem[0].pricing,
			serviceSelection: shippingItem[0].serviceSelection,
			productType: shippingItem[0].productType,
			additionalInfo: null,
			sccDescription: shippingItem[0].sccDescription,
			productDescription: shippingItem[0].productDescription,
		}
		return shippingItemDetails
	} else {
		return null
	}
}
