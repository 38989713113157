import React, { useState } from 'react'
import './Mobile-menu-modal.scss'
import envConfigs from '../../configs/envConfigs'
import { Link } from 'react-router-dom'
import { CCollapse, CButton, CCard, CCardBody } from '@coreui/react'
const MobileMenuModal = ({ menuResponse, setShowMobileMenu, oktaUrl }) => {
	const [expandView, setExpandView] = useState(false)
	const [key, setKey] = useState(null)
	const [prevVal, setPrevVal] = useState(null)
	const [visible0, setVisible0] = useState(false)
	
	const [visible, setVisible] = useState([
		{ id: 0, open: false }, // Products menu
		{ id: 1, open: false }, // E-services menu
		{ id: 2, open: false }, // Network menu
		{ id: 3, open: false }, // Help menu
	])

	const {
		bookingPortalAppUrl,
		checkSessionInterval,
		cosumerOktaSsoUrl,
		enterpriseOktaSsoUrl,
	} = envConfigs

	const getSubmenuEServices = ({ categories, navigationsubmenu }) => {
		console.log('itemmm', categories)
		return (
			<div
				className='submenu-wrapper panel-collapse collapse in'
				id='contact-us-accordion-body-0'
				aria-labelledby='contact-us-accordion-heading-0'
			>
				<div className='shared-expcoll-panel-body-override panel-body-extra-space panel-body'>
					{categories && categories.length ? (
						categories.map((category) => (
							<div>
								<h3 className='shared-expcoll-list-group-heading'>
									{category.categorytitle}
								</h3>
								<ul className='shared-expcoll-list-group list-group'>
									{navigationsubmenu && navigationsubmenu.length ? (
										navigationsubmenu.map((submenu) => {
											return submenu.categorytype === category.categorykey ? (
												<li className='list-group-item'>
													<div className='col-xs-11'>
														<div className='shared-expcoll-body-margin-left'>
															{(submenu.externalPortalLink &&
																submenu.externalPortalLink !== '') ||
															(submenu.redirecturl &&
																submenu.redirecturl.startsWith(
																	bookingPortalAppUrl
																)) ? (
																<a
																	href={
																		submenu.externalPortalLink &&
																		submenu.externalPortalLink !== ''
																			? submenu.externalPortalLink.replace(
																					bookingPortalAppUrl,
																					''
																			  )
																			: submenu.redirecturl.replace(
																					bookingPortalAppUrl,
																					''
																			  )
																	}
																	target={submenu.newwindow}
																>
																	{submenu.title}
																</a>
															) : (
																<a
																	href={
																		oktaUrl
																			? `${oktaUrl}?RelayState=${encodeURIComponent(
																					submenu.redirecturl
																			  )}`
																			: submenu.redirecturl
																	}
																	target={submenu.newwindow}
																>
																	{submenu.title}
																</a>
															)}
														</div>
													</div>
												</li>
											) : (
												<></>
											)
										})
									) : (
										<></>
									)}
								</ul>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>
		)
	}

	const openSubMenu = (id) => {
		let checkMenu = visible.some(item => item.id === id);
		if(checkMenu){
			if(prevVal == id) {
				let newArr = [...visible]; 
				newArr[id].open = !newArr[id].open; 
				setVisible(newArr); 
			} else {
				visible.map(item => item.open = false)
				let newArr = [...visible]; 
				newArr[id].open = !newArr[id].open; 
				setVisible(newArr); 
			}
		} else {
			visible.map(item => item.open = false)
		}
		setPrevVal(id);
		};

	return (
		<div role='dialog' className='menu-main-wrapper'>
			<div className='fade modal-backdrop in'></div>
			<div
				aria-labelledby='main-navigation-modal-label'
				role='dialog'
				tabindex='-1'
				className='fade eag-common-com-modalpopup right in modal'
				style={{ display: 'block', paddingLeft: '12px' }}
			>
				<div className='modal-dialog'>
					<div className='modal-content' role='document'>
						{menuResponse && menuResponse.mobileNavigation && (
							<div className='modal-header'>
								<div>
									<div className='eag-common-modal-close-comp-cls'>
										<button
											data-dismiss='modal'
											aria-label='Navigation Menu Close'
											onClick={() => {
												setShowMobileMenu(false)
											}}
										>
											<img
												src={
													menuResponse.mobileNavigation.navigationmenucloseicon
												}
												alt={
													menuResponse.mobileNavigation
														.navigationmenucloseiconalttext
												}
												className=''
											/>
										</button>
									</div>
									<h2 id='main-navigation-modal-label' className='modal-title'>
										{menuResponse.mobileNavigation.navigationmenulabel}
									</h2>
								</div>
							</div>
						)}
						<div className='modal-body'>
							<div>
								<div className='eag-common-modal-body-cont-cls'>
									<div>
										<div className='nav-menu-main-className d-flex flex-column'>
											<div className='shared-expcoll-component-parent-container'>
												<div
													id='contact-us-accordion'
													className='shared-expcoll-panelgroup-container panel-group'
												>
													{menuResponse.navigationmenu &&
													menuResponse.navigationmenu.navigation &&
													menuResponse.navigationmenu.navigation.length ? (
														menuResponse.navigationmenu.navigation.map(
															(item, index) => (
																<div
																	className='shared-expcoll-panel-container panel panel-default'
																	key={index}
																>
																	<div onClick={(event) => {openSubMenu(index)}}>
																	<div className='panel-title'>
																		<div
																			role='button'
																			tabindex='0'
																			id={`${'contact-us-accordion-heading-'}${index}`}
																			aria-controls={`${'contact-us-accordion-body-'}${index}`}
																			//aria-expanded={expandView}
																			className='shared-expcoll-panel-title'
																		>
																			<div className='shared-expcoll-panel-rowhead-override row'>
																				<div className='col-xs-12'>
																					<div
																						className='col-xs-11'
																						id={index}
																							// onClick={(e) => {
																							// 	console.log('e', e.target.id)
																							// 	console.log('index', index)
																							// 	setKey(index)
																							// 	e.target.id === index &&
																							// 		setExpandView(!expandView)
																							// }}
																					>
																						{item.redirecturl &&
																						item.redirecturl.startsWith(
																							bookingPortalAppUrl
																						) ? (
																							<Link
																								to={item.redirecturl.replace(
																									bookingPortalAppUrl,
																									''
																								)}
																								className='navigation-menu-header'
																							>
																								{item.title}
																							</Link>
																						) : (
																							<a
																								onClick={(e) => {e.stopPropagation();}}
																								href={
																									oktaUrl
																										? `${oktaUrl}?RelayState=${encodeURIComponent(
																												item.redirecturl
																										  )}`
																										: item.redirecturl
																								}
																								target={item.newwindow}
																								className='navigation-menu-header'
																							>
																								{item.title}
																							</a>
																						)}
																					</div>
																					{/* item.title != 'Help' */}
																					{item.categories &&
																					item.categories.length ? (
																						<div className='shared-expcoll-title-collapse-icon col-sm-1 col-xs-1'>
																								{visible.find(item => item.id == index)?.open ? (
																								<img
																									src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDdMNi45OTkyOCAxTDEzIDciIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo='
																									alt={
																										menuResponse
																											.mobileNavigation
																											.navigationexpandiconalttext
																									}
																										// onClick={(e) => {
																										// 	setKey(index)
																										// 	setExpandView(false)
																										// }}
																								/>
																							) : (
																								<img
																									src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuOTk5OTk5TDYuOTk5MjggN0wxMyAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K'
																									alt={
																										menuResponse
																											.mobileNavigation
																											.navigationcollapseiconalttext
																									}
																										// onClick={() => {
																										// 	setKey(index)
																										// 	setExpandView(true)
																										// }}
																								/>
																							)}
																						</div>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																	</div>
																	<CCollapse visible={visible.find(item => item.id == index)?.open}>
																		<CCard className="mt-3">
																			<CCardBody>
																			{getSubmenuEServices(item)}
																			</CCardBody>      
																		</CCard>
																	</CCollapse>
																</div>
															)
														)
													) : (
														<></>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileMenuModal
