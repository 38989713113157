import React from 'react'
import { useTranslation } from 'react-i18next';
const PermanentBookingListHeader = ({
}) => {
    const { t, i18n } = useTranslation(['labels', 'errors']);
    return (
        <>   
            <div className='permanent-booking-list-header__container'>
                <div  id='header' className= 'row permanent-booking-list-header__flex'>
                    <th className="">{t('COMMON.ORG')}-{t('COMMON.DEST')}</th>
                    <th className="">{t('PERMANENT_BOOKING.SHIPMENT_INFO')}</th>
                    <th className="">{t('COMMON.SHP_DATE')}</th>
                    <th className="">{t('PERMANENT_BOOKING.RATE_TYPE')}</th>
                    <th className="">{t('PERMANENT_BOOKING.RATE_PER_KG')}</th>
                    <th className="">{t('PERMANENT_BOOKING.AWB')}</th>
                    <th className="">{t('COMMON.STATUS')}</th>
                    <th className=""></th>
                </div>
            </div>
    </>
    )
}


export default PermanentBookingListHeader