import React from 'react'
import '../index.scss'
import { getWeightUnit, DIMENSION_UNIT } from '../../../../constants'
import { useTranslation } from 'react-i18next'
const LooseInfoDisplay = ({
	weightUnit,
	dimensionUnit,
	looseInfocolumns,
	looseInfoDetails,
	isCrates
}) => {
	const UNIT_CENTIMETERS = 'Cm'
	//for multilingual
	const [t] = useTranslation(['labels'])
	return (
		<div className='table-container looseInfo-table-wrapper pt-1'>
			<table className='table'>
				<thead>
					<tr>
						{looseInfocolumns && looseInfocolumns.length > 0 ? (
							looseInfocolumns.map((column, columnnIndex) => (
								<th
									id='column-name-font'
									className={`table__head-th loose-head-width-${columnnIndex}`}
									key={columnnIndex}
								>
									<span>{t(column)}</span>
								</th>
							))
						) : (
							<></>
						)}
					</tr>
				</thead>
				<tbody className='weight-font'>
					{looseInfoDetails &&
						looseInfoDetails.length !== 0 &&
						looseInfoDetails.map((looseInfo, looseInfoIndex) => (
							<tr key={looseInfoIndex}>
								<td className='table__body-td column-body-width-numberOfPieces'>
									{looseInfo.numberOfPieces}{' '}
								</td>
								<td className='table__body-td column-body-width-length'>
									{looseInfo.length}
								</td>
								<td className='table__body-td column-body-width-width'>
									{looseInfo.width}
								</td>
								<td className='table__body-td column-body-width-height'>
									{looseInfo.height}
								</td>
								<td className='table__body-td column-body-width-weight'>
									{(looseInfo.weight == 0) ? '-' : looseInfo.weight}
								</td>

								<td className='table__body-td column-body-width-perItem'>
									{isCrates === 'LIVE DOGS' || isCrates === 'LIVE CATS' ? '' : t('COMMON.TOTAL')}
								</td>
								<td className='table__body-td column-body-width-packageType'>

									{isCrates === 'LIVE DOGS' || isCrates === 'LIVE CATS' ? '' :
										<>
											<div>
												{(looseInfo.stackable === 'Y' || !looseInfo.stackable)
													? t('COMMON.STACKABLE')
													: t('COMMON.NON_STACKABLE')}
											</div>
											<div>
												{looseInfo.tiltable === 'Y'
													? t('COMMON.TILTABLE')
													: t('COMMON.NON_TILTABLE')}
											</div>

										</>
									}

								</td>



							</tr>
						))}
				</tbody>
			</table>
		</div>
	)
}

export default LooseInfoDisplay
