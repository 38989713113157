import React from 'react'
import UserProfileDetails from './UserProfileDetails'
import './index.scss'

const ViewUserProfile = () => {
	return (
		<div className=''>
			<UserProfileDetails />
		</div>
	)
}

export default ViewUserProfile
