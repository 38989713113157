import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const getFlightDetails = async (reqObj) => {
    const url = `${envConfigs.csnUrl}${envConfigs.flightList}`
    //const url = `http://localhost:9002/csnservice/dashboard/flightlist`

    const response = await portalAuthHttpClient.post(url, reqObj)
    return response;
}

export const getAwbDetails = async (reqObj) => {
    //const url = `http://localhost:9002/csnservice/dashboard/flightawblist`
    const url = `${envConfigs.csnUrl}${envConfigs.awbList}`

    const response = await portalAuthHttpClient.post(url, reqObj)
    return response;
}

export const getPLACIUserDetails = async () => {
    //const url = `http://localhost:9002/csnservice/dashboard/userDetails`
    const url = `${envConfigs.csnUrl}${envConfigs.placiUserDetails}`

    const response = await portalAuthHttpClient.get(url)
    return response;
}

export const getFlighFFMDetails = async (reqObj) => {
    const url = `${envConfigs.csnUrl}${envConfigs.ffmDetails}`
    //const url = `http://localhost:9002/csnservice/dashboard/flight-ffm-details`

    const response = await portalAuthHttpClient.post(url, reqObj)
    return response;
}

export const getAirportsMaster = async () => {
    const url = `${envConfigs.csnUrl}${envConfigs.placiAirportsMaster}`
    //const url = `http://localhost:9002/csnservice/dashbord/airports-master`

    const response = await portalAuthHttpClient.get(url)
    return response;
}