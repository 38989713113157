import React, { useState } from 'react'
import ConfirmationPopUp from '../../../../common/ConfirmationPopUp'
import { useTranslation } from 'react-i18next'
const UserTable = ({
	userList,
	columns,
	initiateUpdateUserStatus,
	handleUpdateUser,
	setAlertMessage,
	setUpdateSuccess,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [displayPopup, setDisplayPopUp] = useState(false)
	const [user, setUser] = useState(null)

	const updateUserStatus = (evnt, userData) => {
		evnt.preventDefault()
		evnt.stopPropagation()

		setUser(userData)
		setDisplayPopUp(true)
	}

	const handleStatusChange = () => {
		setAlertMessage('')
		setUpdateSuccess(false)
		initiateUpdateUserStatus(user)
	}

	const handleonChange = () => {}

	const goToUpdateUserPage = (userId) => {
		handleUpdateUser(userId, true)
	}

	return (
		<>
			<div className='table-container'>
				<table className='table' id='userList'>
					<thead>
						<tr>
							{columns && columns.length > 0 ? (
								columns.map((column, columnnIndex) => (
									<th
										className={`table__head-th user-table-th column-head-width-${columnnIndex}`}
										key={columnnIndex}
									>
										<span>{t(column)}</span>
									</th>
								))
							) : (
								<></>
							)}
						</tr>
					</thead>
					<tbody>
						{userList &&
							userList.length !== 0 &&
							userList.map((user, userIndex) => (
								<tr
									key={userIndex}
									onClick={() => goToUpdateUserPage(user.userId)}
									className='clickableRow'
								>
									<td className='table__body-td column-body-width-name'>
										{user.firstName} {user.lastName}
									</td>
									<td className='table__body-td column-body-width-id'>
										{user.userId}
									</td>
									<td className='table__body-td column-body-width-time'>
										{user.lastLoginTime}
									</td>
									<td className='table__body-td'>
										<div className='switch'>
											<label className='switch-label mt-0'>
												<input
													type='checkbox'
													id='status'
													checked={user.status}
													onChange={handleonChange}
												/>
												<span
													className='switch-btn round-btn'
													aria-label='status checkbox '
													onClick={(evnt) => updateUserStatus(evnt, user)}
												></span>
											</label>
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>

			{displayPopup ? (
				<ConfirmationPopUp
					displayPopUp
					setDisplayPopUp={setDisplayPopUp}
					// message={`Are you sure you want to ${
					// 	user.status ? 'deactivate' : 'activate'
					// }
					//  ${user.firstName} ${user.lastName} ?`}
					message={`${t('UM.ACT_DEACT_DIALOG', {
						status: user.status ? t('UM.DEACTIVATE') : t('UM.ACTIVATE'),
						name: `${user.firstName} ${user.lastName}`,
					})}`}
					primaryBtnLbl={
						user.status ? t('UM.DEACTIVATE_BUTTON') : t('UM.ACTIVATE_BUTTON')
					}
					secondaryBtnLbl={t('COMMON.CANCEL')}
					acceptFn={handleStatusChange}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default UserTable
