import { LIST_BOOKING } from '../constants'

export const loadListBooking = (payload) => ({

	type: LIST_BOOKING.LOAD,
	payload,
})
export const listBookingSuccess = (response) => ({
	type: LIST_BOOKING.SUCCESS,
	response,
})
export const listBookingError = (error) => ({
	type: LIST_BOOKING.ERROR,
	error,
})
export const clearListBooking = () => ({
	type: LIST_BOOKING.CLEAR,
})
