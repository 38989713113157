import { TabPanel, TabView } from 'primereact/tabview'
import React, { useState } from 'react'
import './Admin.scss'
import ViewMasterDetails from './ViewMasterDetails'
import ViewTerms from './ViewTerms'
const Admin = ({
	getSaveCommodityResponse,
	saveCommodityResponse,
	saveCommodityError,
	clearSaveCommodityResponse,
	getSaveAirportMasterResponse,
	saveAirportMasterResponse,
	saveAirportMasterError,
	clearSaveAirportMasterResponse,
	getSaveServiceMasterResponse,
	saveServiceMasterResponse,
	saveServiceMasterError,
	clearSaveServiceMasterResponse,
	getSaveProductMasterResponse,
	saveProductMasterResponse,
	saveProductMasterError,
	clearSaveProductMasterResponse,
	getSaveSccMasterResponse,
	saveSccMasterResponse,
	saveSccMasterError,
	clearSaveSccMasterResponse,
	getSaveTermsMasterResponse,
	saveTermsMasterResponse,
	saveTermsMasterError,
	clearSaveTermsMasterResponse,
	getSaveUldTypeMasterResponse,
	getSavePivotInfoMasterResponse,
	saveUldTypeMasterResponse,
	saveUldTypeMasterError,
	savePivotWeightInfoMasterError,
	savePivotWeightInfoMasterResponse,
	getSaveSccIncompatibilityMasterResponse,
	saveSccIncompatibilityMasterResponse,
	saveSccIncompatibilityMasterError,
	getSaveStationScheduleMasterResponse,
	saveStationScheduleMasterResponse,
	saveStationScheduleMasterError,
	getSaveRegionMasterResponse,
	saveRegionMasterResponse,
	saveRegionMasterError,
	getSaveCountryMasterResponse,
	saveCountryMasterResponse,
	saveCountryMasterError,

	getSaveEmbargoResponse,
	saveEmbargoResponse,
	saveEmbargoError,
	clearSaveEmbargoResponse,

}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const handleTabChange = (index) => {
		setActiveIndex(index)
	}

	return (
		<div className='admin-page'>
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => handleTabChange(e.index)}
			>
				<TabPanel header='Master' headerClassName='admin-tab'>
					<ViewMasterDetails
						getSaveCommodityResponse={getSaveCommodityResponse}
						saveCommodityResponse={saveCommodityResponse}
						saveCommodityError={saveCommodityError}
						clearSaveCommodityResponse={clearSaveCommodityResponse}
						getSaveAirportMasterResponse={getSaveAirportMasterResponse}
						saveAirportMasterResponse={saveAirportMasterResponse}
						saveAirportMasterError={saveAirportMasterError}
						clearSaveAirportMasterResponse={clearSaveAirportMasterResponse}
						getSaveServiceMasterResponse={getSaveServiceMasterResponse}
						saveServiceMasterResponse={saveServiceMasterResponse}
						saveServiceMasterError={saveServiceMasterError}
						clearSaveServiceMasterResponse={clearSaveServiceMasterResponse}
						getSaveProductMasterResponse={getSaveProductMasterResponse}
						saveProductMasterResponse={saveProductMasterResponse}
						saveProductMasterError={saveProductMasterError}
						clearSaveProductMasterResponse={clearSaveProductMasterResponse}
						getSaveSccMasterResponse={getSaveSccMasterResponse}
						saveSccMasterResponse={saveSccMasterResponse}
						saveSccMasterError={saveSccMasterError}
						clearSaveSccMasterResponse={clearSaveSccMasterResponse}
						getSaveUldTypeMasterResponse={getSaveUldTypeMasterResponse}
						getSavePivotInfoMasterResponse={getSavePivotInfoMasterResponse}
						saveUldTypeMasterResponse={saveUldTypeMasterResponse}
						saveUldTypeMasterError={saveUldTypeMasterError}
						savePivotWeightInfoMasterError={savePivotWeightInfoMasterError}
						savePivotWeightInfoMasterResponse={
							savePivotWeightInfoMasterResponse
						}
						getSaveSccIncompatibilityMasterResponse={
							getSaveSccIncompatibilityMasterResponse
						}
						saveSccIncompatibilityMasterResponse={
							saveSccIncompatibilityMasterResponse
						}
						saveSccIncompatibilityMasterError={
							saveSccIncompatibilityMasterError
						}
						getSaveStationScheduleMasterResponse={
							getSaveStationScheduleMasterResponse
						}
						saveStationScheduleMasterResponse={
							saveStationScheduleMasterResponse
						}
						saveStationScheduleMasterError={saveStationScheduleMasterError}
						saveRegionMasterError={saveRegionMasterError}
						getSaveRegionMasterResponse={
							getSaveRegionMasterResponse
						}
						saveRegionMasterResponse={
							saveRegionMasterResponse
						}
						saveCountryMasterError={saveCountryMasterError}
						getSaveCountryMasterResponse={
							getSaveCountryMasterResponse
						}
						saveCountryMasterResponse={
							saveCountryMasterResponse
						}
						getSaveEmbargoResponse={getSaveEmbargoResponse}
						saveEmbargoResponse={saveEmbargoResponse}
						saveEmbargoError={saveEmbargoError}
						clearSaveEmbargoResponse={clearSaveEmbargoResponse}
					/>
				</TabPanel>

				<TabPanel header='Terms & conditions' headerClassName='admin-tab'>
					<ViewTerms
						getSaveTermsMasterResponse={getSaveTermsMasterResponse}
						saveTermsMasterResponse={saveTermsMasterResponse}
						saveTermsMasterError={saveTermsMasterError}
						clearSaveTermsMasterResponse={clearSaveTermsMasterResponse}
					/>
				</TabPanel>
			</TabView>
		</div>
	)
}

export default Admin
