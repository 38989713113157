import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';

class DropDown extends Component {
	static defaultProps = {
		options: null,
	}

	static propTypes = {
		options: PropTypes.array,
	}

	constructor(props) {
		super(props)
		this.state = {
			filter: '',
			focused: false,
			overlayVisible: false,
			value: this.props.value !== undefined ? this.props.value : '',
			initialValue: this.props.value,
		}

		this.onChange = this.onChange.bind(this)
		this.onFocus = this.onFocus.bind(this)
		this.onBlur = this.onBlur.bind(this)
	}

	onChange = (event) => {
		this.setState({ value: event.target.value })
		if (this.props.onChange !== undefined) {
			this.props.onChange(event)
		}
	}

	onFocus = (event) => {
		if (this.props.onFocus !== undefined) {
			this.props.onFocus(event)
		}
	}

	onBlur = (event) => {
		if (this.props.onBlur !== undefined) {
			this.props.onBlur(event)
		}
	}

	static getDerivedStateFromProps(props, current_state) {
		if (
			(current_state.value === '' ||
				current_state.value == null ||
				props.value === '' ||
				props.value === current_state.initialValue ||
				props.isUpdate) &&
			current_state.value !== props.value
		) {
			return {
				value: props.value,
			}
		}
		return null
	}

	render() {
		const { t } = this.props;
		return (
			<div
				className={
					'form-item form-item--select' +
					(this.props.disabled ? ' form-item--disabled' : '')
				}
				data-module='molecules/form-item/FormItem'
				data-value-missing={this.props.errorMsgRequired}
				data-default-error={this.props.errorMsgDefault}
			>
				<select
					id={this.props.id ? this.props.id : ''}
					name='input-select'
					className='form-item__field currency-item'
					aria-invalid={this.props.ariaInvalid ? true : `false`}
					value={this.state.value}
					disabled={this.props.disabled}
					readOnly={this.props.readOnly}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				>
					{this.props.disabled ||
						(this.props.readOnly && <option value=''>-</option>)}
					{!this.props.disabled && this.props.defaultText && (
						<option value=''>{this.props.defaultText}</option>
					)}
					{this.props.options.map((opt, k) => (
						<option key={k} value={opt.value}>
							{t(opt.label)}
						</option>
					))}
				</select>
				{this.props.label && (
					<label className='form-item__label'>{this.props.label}</label>
				)}
			</div>
		)
	}
}
const Dropdown = withTranslation()(DropDown)
export {Dropdown}