import {
	SAVE_COMMODITY,
	SAVE_AIRPORT_MASTER,
	SAVE_SERVICE_MASTER,
	SAVE_PRODUCT_MASTER,
	SAVE_SCC_MASTER,
	SAVE_TERMS_MASTER,
	SAVE_ULD_TYPE_MASTER,
	SAVE_PIVOT_INFO_MASTER,
	SAVE_SCC_INCOMPATIBLE_MASTER,
	SAVE_STATION_SCHEDULE_MASTER,
	SAVE_REGION_MASTER,
	SAVE_COUNTRY_MASTER,
	SAVE_EMBARGO_MASTER,
} from '../constants'

export const requestSaveCommodity = (payload) => ({
	type: SAVE_COMMODITY.LOAD,
	payload,
})

export const saveCommodityResponse = (response) => ({
	type: SAVE_COMMODITY.SUCCESS,
	response,
})
export const saveCommodityError = (error) => ({
	type: SAVE_COMMODITY.ERROR,
	error,
})

export const clearSaveCommodity = () => ({
	type: SAVE_COMMODITY.CLEAR,
})

export const requestSaveAirportMaster = (payload) => ({
	type: SAVE_AIRPORT_MASTER.LOAD,
	payload,
})

export const saveAirportMasterResponse = (response) => ({
	type: SAVE_AIRPORT_MASTER.SUCCESS,
	response,
})
export const saveAirportMasterError = (error) => ({
	type: SAVE_AIRPORT_MASTER.ERROR,
	error,
})

export const clearSaveAirportMaster = () => ({
	type: SAVE_AIRPORT_MASTER.CLEAR,
})

export const requestSaveServiceMaster = (payload) => ({
	type: SAVE_SERVICE_MASTER.LOAD,
	payload,
})

export const saveServiceMasterResponse = (response) => ({
	type: SAVE_SERVICE_MASTER.SUCCESS,
	response,
})
export const saveServiceMasterError = (error) => ({
	type: SAVE_SERVICE_MASTER.ERROR,
	error,
})

export const clearSaveServiceMaster = () => ({
	type: SAVE_SERVICE_MASTER.CLEAR,
})

export const requestSaveProductMaster = (payload) => ({
	type: SAVE_PRODUCT_MASTER.LOAD,
	payload,
})

export const saveProductMasterResponse = (response) => ({
	type: SAVE_PRODUCT_MASTER.SUCCESS,
	response,
})
export const saveProductMasterError = (error) => ({
	type: SAVE_PRODUCT_MASTER.ERROR,
	error,
})

export const clearSaveProductMaster = () => ({
	type: SAVE_PRODUCT_MASTER.CLEAR,
})

export const requestSaveSccMaster = (payload) => ({
	type: SAVE_SCC_MASTER.LOAD,
	payload,
})

export const saveSccMasterResponse = (response) => ({
	type: SAVE_SCC_MASTER.SUCCESS,
	response,
})
export const saveSccMasterError = (error) => ({
	type: SAVE_SCC_MASTER.ERROR,
	error,
})

export const clearSaveSccMaster = () => ({
	type: SAVE_SCC_MASTER.CLEAR,
})

export const requestSaveTermsMaster = (payload) => ({
	type: SAVE_TERMS_MASTER.LOAD,
	payload,
})

export const saveTermsMasterResponse = (response) => ({
	type: SAVE_TERMS_MASTER.SUCCESS,
	response,
})
export const savetermsMasterError = (error) => ({
	type: SAVE_TERMS_MASTER.ERROR,
	error,
})

export const clearSaveTermsMaster = () => ({
	type: SAVE_TERMS_MASTER.CLEAR,
})

export const requestSaveUldTypeMaster = (payload) => ({
	type: SAVE_ULD_TYPE_MASTER.LOAD,
	payload,
})

export const saveUldTypeMasterResponse = (response) => ({
	type: SAVE_ULD_TYPE_MASTER.SUCCESS,
	response,
})

export const saveUldTypeMasterError = (error) => ({
	type: SAVE_ULD_TYPE_MASTER.ERROR,
	error,
})

export const requestSavePivotInfoMaster = (payload) => ({
	type: SAVE_PIVOT_INFO_MASTER.LOAD,
	payload,
})

export const savePivotInfoMasterResponse = (response) => ({
	type: SAVE_PIVOT_INFO_MASTER.SUCCESS,
	response,
})

export const savePivotInfoMasterError = (error) => ({
	type: SAVE_PIVOT_INFO_MASTER.ERROR,
	error,
})

export const requestSaveSccIncompatibillityMaster = (payload) => ({
	type: SAVE_SCC_INCOMPATIBLE_MASTER.LOAD,
	payload,
})

export const saveSccIncompatibillityMasterResposne = (response) => ({
	type: SAVE_SCC_INCOMPATIBLE_MASTER.SUCCESS,
	response,
})

export const saveSccIncompatibillityMasterError = (error) => ({
	type: SAVE_SCC_INCOMPATIBLE_MASTER.ERROR,
	error,
})

export const requestSaveStationScheduleMaster = (payload) => ({
	type: SAVE_STATION_SCHEDULE_MASTER.LOAD,
	payload,
})

export const saveStationScheduleMasterResponse = (response) => ({
	type: SAVE_STATION_SCHEDULE_MASTER.SUCCESS,
	response,
})

export const saveStationScheduleMasterError = (error) => ({
	type: SAVE_STATION_SCHEDULE_MASTER.ERROR,
	error,
})

export const requestSaveRegionMaster = (payload) => ({
	type: SAVE_REGION_MASTER.LOAD,
	payload,
})

export const saveRegionMasterResponse = (response) => ({
	type: SAVE_REGION_MASTER.SUCCESS,
	response,
})

export const saveRegionMasterError = (error) => ({
	type: SAVE_REGION_MASTER.ERROR,
	error,
})

export const requestSaveCountryMaster = (payload) => ({
	type: SAVE_COUNTRY_MASTER.LOAD,
	payload,
})

export const saveCountryMasterResponse = (response) => ({
	type: SAVE_COUNTRY_MASTER.SUCCESS,
	response,
})

export const saveCountryMasterError = (error) => ({
	type: SAVE_COUNTRY_MASTER.ERROR,
	error,
})

export const requestSaveEmbargoMaster = (payload) => ({
	type: SAVE_EMBARGO_MASTER.LOAD,
	payload,
})

export const saveEmbargoMasterResponse = (response) => ({
	type: SAVE_EMBARGO_MASTER.SUCCESS,
	response,
})

export const saveEmbargoMasterError = (error) => ({
	type: SAVE_EMBARGO_MASTER.ERROR,
	error,
})

export const clearSaveEmbargoMaster = () => ({
	type: SAVE_EMBARGO_MASTER.CLEAR,
})

