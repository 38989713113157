import { SAVE_TEMPLATE } from '../constants'

export const requestSaveTemplate = (payload) => ({
	type: SAVE_TEMPLATE.LOAD,
	payload,
})

export const saveTemplateResponse = (response) => ({
	type: SAVE_TEMPLATE.SUCCESS,
	response,
})

export const saveTemplateError = (error) => ({
	type: SAVE_TEMPLATE.ERROR,
	error,
})

export const clearSaveTemplate = () => ({
	type: SAVE_TEMPLATE.CLEAR,
})
