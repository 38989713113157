import { SelectButton } from 'primereact/selectbutton'
import { RadioButton } from 'primereact/radiobutton'
import React, { useState } from 'react'
import { FORM_FIELDS, SEARCH_DATE_TOGGLE_TYPE } from '../../constants'
import { useTranslation } from 'react-i18next'
const searchDateToggleItems = [
	{ label: 'Shipping date', value: SEARCH_DATE_TOGGLE_TYPE.SHIPPING_DATE },
	{ label: 'Arrival date', value: SEARCH_DATE_TOGGLE_TYPE.ARRIVAL_DATE },
]

const DateToggleButton = ({
	isSearchByArrivalDate,
	setShippingDate,
	setArrivalDate,
	handleSetError,
	setIsSearchByArrivalDate,
}) => {

	const { t, i18n } = useTranslation(['labels','errors'])
	return (
		<div className='date-toggle-adjust'>
			<div className='custom-xs'>
				<RadioButton
					id='btn-radio-first-adjust'
					className='select-button'
					name='radio-flight-date'
					value={SEARCH_DATE_TOGGLE_TYPE.SHIPPING_DATE}
					onChange={(e) => {
						setIsSearchByArrivalDate(false)
						setArrivalDate(null)
						handleSetError(FORM_FIELDS.SHIPPING_DATE, null)
					}}
					checked={!isSearchByArrivalDate}
				></RadioButton>
				<label id='label-size-first-adjust'>{t('COMMON.SHP_DATE')}</label>
			</div>
			<div className='custom-xs'>
				<RadioButton
					value={SEARCH_DATE_TOGGLE_TYPE.ARRIVAL_DATE}
					id='btn-radio-second-adjust'
					name='radio-flight-date'
					className='select-button'
					onChange={(e) => {
						setIsSearchByArrivalDate(true)
						setShippingDate(null)
						handleSetError(FORM_FIELDS.SHIPPING_DATE, null)
					}}
					checked={isSearchByArrivalDate}
				></RadioButton>
				<label id='label-size-second-adjust'>{t('COMMON.ARR_DATE')}</label>
			</div>
		</div>
	)
}

export default DateToggleButton
