import { v4 as uuid } from 'uuid'
import Cookie from 'js-cookie'
import { getFromLocalStorage, setToLocalStorage } from './StorageUtils'
import sessionKeys from '../constants/sessionKeys'
import getPrincipal from './getPrincipal'
import getCurrentUser from './getCurrentUser'
import axios from 'axios'
import { TIMEOUT_TIME } from '../constants'
import { refreshTokens } from '../services/authService'
import { Redirect } from 'react-router'
import store from '../redux/store'
import {setRefreshToken, setAccessToken, setIsRefreshing} from '../redux/actions/authAction'
import envConfigs from '../configs/envConfigs'




const getJSessionCookie = () => Cookie.get('JSESSIONID')

const getToken = () => {
	const headers = {}
	const token = getFromLocalStorage(sessionKeys.ACCESS_TOKEN)
	if (token) {
		headers['Authorization'] = 'Bearer ' + token
	}
	return headers
}

// eslint-disable-next-line import/prefer-default-export
export const getHeaders = () => {
	const userDetails = getCurrentUser()
	let principal = getPrincipal()
	const headers = {
		'X-Correlation-Id': uuid(),
		'X-Agent-code': userDetails ? userDetails.agentCode : principal ? principal.sub : null,
		'X-User-Id': userDetails ? userDetails.userId : principal ? principal.sub : null,
		'Content-Type': 'application/json',
		...getToken(),
	}

	const jSessionCookie = getJSessionCookie()
	if (jSessionCookie) {
		headers['X-JSESSIONID'] = jSessionCookie
	}

	return headers
}

export const portalHttpClient = axios.create({
	timeout: TIMEOUT_TIME,
})

let requestBuffer = []


const select = (state) => {
	return state.auth.isRefreshing
}

export const portalAuthHttpClient = axios.create({
		timeout: TIMEOUT_TIME,
	})

portalAuthHttpClient.interceptors.request.use((request) => {
	request.headers = getHeaders()
	return request
}, (error) => {
	console.log(error)
	return Promise.reject(error)
})


portalAuthHttpClient.interceptors.response.use(
async (response) => {
	return response
}, 
async (error) => {
	const originalRequest = error.config;
	if (error.response) {
		if (error.response.status === 401  && !originalRequest._retry) {
			originalRequest._retry = true;
			const isRefreshing = select(store.getState())
			if (isRefreshing) {
				return new Promise(function(resolve, reject) {
					requestBuffer.push({ resolve, reject });
				})
				.then(() => {
					return portalAuthHttpClient(originalRequest);
				})
				.catch(err => {
					return Promise.reject(err);
				});
			} else {
				store.dispatch(setIsRefreshing(true))
				
				return new Promise(
					async (resolve, reject) => {
						try {
							const tokens = await refreshTokens()
							if (tokens.accessToken) {
								await store.dispatch(setRefreshToken(tokens.refreshToken))
								await store.dispatch(setAccessToken(tokens.accessToken))
								resolve(portalAuthHttpClient(originalRequest))
								if (requestBuffer && requestBuffer.length) {
									requestBuffer.forEach(prom => {
										prom.resolve()
									})
								}
							}
						} catch (err) {
							console.log(err)
							window.location.assign('/logout')
							reject(err);
						} finally {
							store.dispatch(setIsRefreshing(false))
						}
					}
				)
			}
		}
		return Promise.reject(error);
		
	}
})



export const getRandomIntInclusive = (min, max) => {
	min = Math.ceil(min)
	max = Math.floor(max)
	return '' + Math.floor(Math.random() * (max - min + 1) + min)
}
