import React from 'react'
import deleteIcon from '../../../assets/images/delete.svg'
import '../index.scss'
import { useTranslation } from 'react-i18next'
const ManageListTemplateTable = ({
	templates,
	columns,
	handleEditAction,
	handleDeleteAction,
}) => {
	const { t } = useTranslation(['labels'])

	return (
	<>
		<div className='table-container d-none d-lg-block'>
			<table className='table'>
				<thead>
					<tr>
						{columns && columns.length > 0 ? (
							columns.map((column, columnnIndex) => (
								<th
									className={`table__head-th user-table-th column-head-width-${columnnIndex}`}
									key={columnnIndex}
								>
									<span>{t(column)}</span>
								</th>
							))
						) : (
							<></>
						)}
						<th className='table__head-th actionHead user-table-th'>
							<span>{t('MNGTMPLT.ACTION')}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{templates &&
						templates.length !== 0 &&
						templates.map((template, templateIndex) => (
							<tr
								key={templateIndex}
								className='editTemplate'
								onClick={(evnt) => handleEditAction(evnt, template)}
							>
								<td className='table__body-td column-body-width-templateName'>
									{template.templateName}
								</td>
								<td className='table__body-td column-body-width-userId'>
									{template.userId}
								</td>
								<td className='table__body-td column-body-width-time'>
									{template.updateTime}
								</td>
								<td className='table__body-td'>
									<div>
										<i className='icon'>
											<img
												src={deleteIcon}
												alt='Delete'
												title={t('COMMON.DELETE')}
												onClick={(evnt) => handleDeleteAction(evnt, template)}
											/>
										</i>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
		<div className='template_mobile d-flex d-lg-none flex-column'>
			{templates && templates.length !== 0 && templates.map((template, templateIndex) => (
				<div className='template_mobile_wrapper' key={templateIndex}>
					<div className='template_row'>
						<div className='d-flex'>
							<div className='template_column'>
								<div className='template_name-label'>{t('MNGTMPLT.TEMPLATE_NAME')}</div>	
								<div className='template_name-value'><a onClick={(evnt) => handleEditAction(evnt, template)} href="javascript:void(0)">{template.templateName}</a></div>	
							</div>
							<div className='d-flex flex-column '>
								<div className='template_name-label'>{t('COMMON.USER_ID')}</div>	
								<div className='template_name-value'>{template.userId}</div>	
							</div>
						</div>
					</div>			
					<div className='template_row'>
						<div className='d-flex'>
							<div className='template_column'>
								<div className='template_name-label'>{t('MNGTMPLT.CREATION_TIME')}</div>	
								<div className='template_name-value'>{template.updateTime}</div>	
							</div>
							<div className='d-flex align-items-end'>
								<div className='template_name-value'>
									<a onClick={(evnt) => handleDeleteAction(evnt, template)} href="javascript:void(0)">{t('COMMON.DELETE')}</a>
								</div>	
							</div>
						</div>
					</div>			
				</div>
			))}
		</div>
	</>
	)
}

export default ManageListTemplateTable
