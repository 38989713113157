import React from 'react'
import { connect } from 'react-redux'
import { loadListBooking } from '../../../redux/actions/listBookingActions'
import ListBooking from './ListBooking'

const ListBookingContainer = ({
	listBookingResponse,
	listBookingError,
	getBookingDetails,
	retainFormData,
}) => {
	return (
		<ListBooking
			listBookingResponse={listBookingResponse}
			listBookingError={listBookingError}
			getBookingDetails={getBookingDetails}
			retainFormData={retainFormData}
		/>
	)
}

const mapStateToProps = ({ listBooking }) => ({
	listBookingResponse: listBooking.response,
	listBookingError: listBooking.error,
})

const mapDispatchToProps = (dispatch) => ({
	getBookingDetails: (bookingSearchFormData, pageNumber, defaultOrigin) => {
		const payload = { bookingSearchFormData, pageNumber, defaultOrigin }
		dispatch(loadListBooking(payload))
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListBookingContainer)
