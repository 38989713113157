import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import closeIcon from '../../assets/images/icon-close-alert.png'
import './index.scss'

const ConfirmationPopup = ({
	displayPopUp,
	setDisplayPopUp,
	header,
	message,
	primaryBtnLbl,
	acceptFn,
	secondaryBtnLbl,
}) => {
	const handleConfirmation = () => {
		closeDialog()
		acceptFn()
	}
	const modalFooter = (
		<div>
			<Button
				id='confirmButton'
				label={primaryBtnLbl}
				className='button'
				type='button'
				onClick={handleConfirmation}
			/>
			<Button
				id='cancelButton'
				label={secondaryBtnLbl}
				className='button button--secondary'
				type='button'
				onClick={() => {
					closeDialog()
				}}
			/>
		</div>
	)

	const closeDialog = () => {
		setDisplayPopUp(false)
	}

	const customClose = (
		<i>
			<img src={closeIcon} alt='close' title='close' onClick={closeDialog} />
		</i>
	)

	return displayPopUp ? (
		<div className='confirmation-dialog'>
			<Dialog
				footer={modalFooter}
				visible
				closable={false}
				modal
				icons={customClose}
				header={header}
				className='modal-dialog'
			>
				{message}
			</Dialog>
		</div>
	) : (
		<></>
	)
}

export default ConfirmationPopup
