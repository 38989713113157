import React, { useState, useEffect } from 'react'
import SearchPanel from '../SearchPanel'
import { useParams, useHistory } from 'react-router-dom'
import BookingMenu from '../BookingMenu'
import { Alert } from '../../common/Alert'

const FlightSearchPage = ({ isModifyBooking, disableBeforeClosePrompt }) => {
	let { awbNo, serialNo, templateName } = useParams()

	const history = useHistory()


	const [notificationType, setNotificationType] = useState(null)
	const [notificationMessage, setNotificationMessage] = useState('')

	useEffect(() => {
		if (
			history.location.state &&
			history.location.state.message &&
			history.location.state.type
		) {
			const message = history.location.state.message
			const type = history.location.state.type

			setNotificationMessage(message)
			setNotificationType(type)

			let state = { ...history.location.state }
			delete state.message
			delete state.type
			history.replace({ ...history.location, state })
			window.scrollTo(0, 0)
		}
	}, [history])


	const handleAlertClose = () => {
		setNotificationMessage('')
		setNotificationType(null)
	}


	const getActiveMenu = () => {
		if (!isModifyBooking && !templateName) {
			return 0
		} else if (isModifyBooking) {
			return 1
		} else if (templateName && templateName !== '') {
			return 3
		} else {
			return 0
		}
	}

	return (
		<div className='get-quote'>
			<div className='row'>
				<div className='col-lg-12'>
					<BookingMenu activeItem={getActiveMenu()} />
					{notificationMessage && notificationMessage !== '' && (
						<div className='cargo-portal-container pl-3 pr-3 pb-2 pt-0'>
							<Alert
								type={notificationType}
								showAlert={true}
								message={notificationMessage}
								setTimeout={true}
								onAlertClose={handleAlertClose}
							/>
						</div>
					)}
					<SearchPanel
						awbNo={awbNo}
						serialNo={serialNo}
						templateParam={templateName}
						isModifyBooking={isModifyBooking}
						disableBeforeClosePrompt={disableBeforeClosePrompt}
						setNotificationMessage={setNotificationMessage}
						setNotificationType={setNotificationType}
					/>
				</div>
			</div>
		</div>
	)
}

export default FlightSearchPage
