import {
	FORM_FIELDS,
	SHIPPING_TYPE,
	LOOSE_FORM_FIELDS,
	ULD_FORM_FIELDS,
	PHARMA_VACCINE_FIELDS,
	UNID_FORM_FIELDS,
	PHARMA_PRODUCT,
	FRESH_FORWARD_PRODUCT,
	PHARMA_SHIPPING_TYPE,
	PHARMA_HYBRID_TYPE,
	PHARMA_ACTIVE_TYPE,
	PRICE_CODE_TYPE,
	CAGE_FORM_FIELDS,
	SEASONAL_FIELDS,
	PHARMA_TEMP_RANGE,
} from '../../constants'
import MESSAGES from '../../constants/Messages'
import { checkShipmentIsBigOrOHG } from '../../services/quoteServices'
import { notZero } from '../../utils/common'
import { useTranslation } from 'react-i18next'
export const isNum = (val) => /[+-]?[0-9]+\.?[0-9]*/.test(val)
export const validateOrigin = (origin) =>
	origin && origin.code ? null : MESSAGES.INVALID_ORIGIN
export const validateDestination = (destination, origin) => {
	if (destination && destination.code) {
		if (origin && origin.code) {
			if (origin.code == destination.code) {
				return MESSAGES.SAME_ORG_DEST_ERROR
			} else {
				return null
			}
		} else {
			return null
		}
	} else {
		return MESSAGES.INVALID_DESTINATION
	}
}
export const validateShippingDate = (shippingDate) =>
	shippingDate ? null : MESSAGES.INVALID_SHIPPING_DATE
export const validateArrivalDate = (arrivalDate) =>
	arrivalDate ? null : MESSAGES.INVALID_ARRIVAL_DATE
export const validateShippingItem = (shippingItem) =>
	shippingItem && shippingItem.code ? null : MESSAGES.INVALID_SHIPPING_ITEM
export const validateFieldValue = (value, message) =>
	value === null || value === '' || value === undefined ? message : null

export const validateUnidDetails = (unidList) => {
	return unidList.map((detail) => {
		const error = {}
		error[UNID_FORM_FIELDS.UNID] =
			detail.unid && notZero(detail.unid)
				? null
				: MESSAGES.INVALID_UNID
		const isValid = !Object.keys(error).some((key) =>
			error[key] ? true : false
		)
		return isValid ? null : error
	})
}

export const validateCageDetails = (cageDetails) => {
	return cageDetails.map((detail) => {
		let error = {}
		error[FORM_FIELDS.CRATE_TOTAL_WEIGHT] =
			isNum(detail.weight) && notZero(detail.weight)
				? null
				: MESSAGES.VALID_TOTAL_WEIGHT
		error[FORM_FIELDS.CRATE_LENGTH] =
			detail['length'] && isNum(detail['length'])
				? null
				: MESSAGES.INVALID_LENGTH
		error[FORM_FIELDS.CRATE_WIDTH] =
			detail.width && isNum(detail.width) ? null : MESSAGES.INVALID_WIDTH
		error[FORM_FIELDS.CRATE_HEIGHT] =
			detail.height && isNum(detail.height) ? null : MESSAGES.INVALID_HEIGHT
		let isPetsDetailsValid = true
		let petsErrors = detail.pets.map((pet) => {
			let petError = {}
			petError[FORM_FIELDS.BREED] = pet.breed && pet.breed !== ''
				? (pet.snubNosed && pet.snubNosed === 'Y' ? MESSAGES.SNUB_NOSED : null)
				: MESSAGES.VALID_BREED
			petError[FORM_FIELDS.AGE_MONTHS] = ((pet.ageInMonths && pet.ageInMonths !== '') || (pet.ageInYear && pet.ageInYear !== '')) ? null : MESSAGES.VALID_MONTHS
			petError[FORM_FIELDS.AGE_YEARS] = ((pet.ageInMonths && pet.ageInMonths !== '') || (pet.ageInYear && pet.ageInYear !== '')) ? null : MESSAGES.VALID_YEARS
			if (petError[FORM_FIELDS.BREED] || petError[FORM_FIELDS.AGE_MONTHS] || petError[FORM_FIELDS.AGE_YEARS]) {
				isPetsDetailsValid = false
			}
			return petError
		})
		error[FORM_FIELDS.PETS] = petsErrors && petsErrors.length > 0 ? [...petsErrors] : null
		const isValid = !Object.keys(error).some((key) => {
			if (key === FORM_FIELDS.PETS) {
				const petsError = error[key]
				const isPetsValid = !Object.keys(petsError).some((key1) =>
					petsError[key1] ? true : false
				)
				return isPetsValid
			} else {
				return error[key] ? true : false
			}
		}
		)

		return isValid && isPetsDetailsValid ? null : error
	})
}

export const validateTotalWeight = (totalWeight, calculatedTotalWeight, isLiveAnimalBooking) => {
	return (totalWeight && isNum(totalWeight)) || calculatedTotalWeight
		? null
		: isLiveAnimalBooking ? null : MESSAGES.INVALID_WEIGHT
}
export const validateOperationalWeight = (totalWeight, adjustedWeight) => {
	if (adjustedWeight) {
		return isNum(adjustedWeight) &&
			Number(adjustedWeight) >= Number(totalWeight)
			? null
			: 'ER240'
	}
}
export const validatePharmaShippingType = (pharmaObj) =>
	pharmaObj.shippingPharmaType ? null : MESSAGES.SLCT_SHP_TYPE
export const validateHybridContainerType = (pharmaObj) => {
	if (
		pharmaObj.shippingPharmaType &&
		pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.HYBRID
	) {
		if (!pharmaObj.hybridContainerType) {
			return MESSAGES.SLCT_HYBRID_CON_TYPE
		} else {
			return null
		}
	} else {
		return null
	}
}
export const validateActiveContainerType = (pharmaObj) => {
	if (
		pharmaObj.shippingPharmaType &&
		pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.ACTIVE
	) {
		if (!pharmaObj.activeContainerType) {
			return MESSAGES.SLCT_ACTIVE_CON_TYPE
		} else {
			return null
		}
	} else {
		return null
	}
}
export const validateTempRange = (pharmaObj) => {
	if (
		pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.PASSIVE &&
		!pharmaObj.tempratureRange
	) {
		return MESSAGES.SELECT_TEMP_RANGE
	} else if (
		pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.HYBRID &&
		pharmaObj.hybridContainerType === PHARMA_HYBRID_TYPE.OWN_HYBRID &&
		!pharmaObj.tempratureRange
		// || (pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.ACTIVE &&
		// pharmaObj.activeContainerType === PHARMA_ACTIVE_TYPE.OWN_ACTIVE &&
		// !pharmaObj.tempratureRange)
	) {
		return MESSAGES.SELECT_TEMP_RANGE
	} else {
		return null
	}
}
export const validateDryIce = (pharmaObj) => {
	if (
		pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.PASSIVE &&
		pharmaObj.tempratureRange &&
		!pharmaObj.isDryIceAdded
	) {
		return MESSAGES.CMPLT_MNDTRY_FLDS
	} else if (
		(pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.HYBRID &&
			pharmaObj.hybridContainerType === PHARMA_HYBRID_TYPE.OWN_HYBRID &&
			pharmaObj.tempratureRange &&
			!pharmaObj.isDryIceAdded) ||
		(pharmaObj.shippingPharmaType === PHARMA_SHIPPING_TYPE.ACTIVE &&
			pharmaObj.activeContainerType === PHARMA_ACTIVE_TYPE.OWN_ACTIVE &&
			pharmaObj.tempratureRange &&
			!pharmaObj.isDryIceAdded)
	) {
		return MESSAGES.CMPLT_MNDTRY_FLDS
	} else {
		return null
	}
}

export const validateLineItemWeights = (looseDetails, looseDetailsErrors) => {
	const isLineItemWeight = looseDetails.some((detail) => detail.weight)
	if (isLineItemWeight) {
		looseDetails.forEach((detail, i) => {
			const errorMsg =
				detail.weight && isNum(detail.weight) ? null : MESSAGES.INVALID_WEIGHT
			if (errorMsg) {
				looseDetailsErrors[i] = looseDetailsErrors[i]
					? { ...looseDetailsErrors[i], [LOOSE_FORM_FIELDS.WEIGHT]: errorMsg }
					: { [LOOSE_FORM_FIELDS.WEIGHT]: errorMsg }
			}
		})
		return looseDetailsErrors
	} else {
		return looseDetailsErrors.map((item) =>
			item ? { ...item, [LOOSE_FORM_FIELDS.WEIGHT]: null } : null
		)
	}
}

export const validateLineItemWeightSum = (
	calculatedTotalWeight,
	totalWeight
) => {
	if (calculatedTotalWeight && totalWeight) {
		return calculatedTotalWeight > totalWeight
			? 'Sum of dimension level weight(s) cannot exceed the total weight.'
			: null
	} else {
		return null
	}
}

export const validateLooseDetails = (looseDetails) => {
	return looseDetails.map((detail) => {
		const error = {}
		error[LOOSE_FORM_FIELDS.PIECES] =
			detail.pieces && isNum(detail.pieces) && notZero(detail.pieces)
				? null
				: MESSAGES.INVALID_PIECES
		error[LOOSE_FORM_FIELDS.LENGTH] =
			detail['length'] && isNum(detail['length'])
				? null
				: MESSAGES.INVALID_LENGTH
		error[LOOSE_FORM_FIELDS.WIDTH] =
			detail.width && isNum(detail.width) ? null : MESSAGES.INVALID_WIDTH
		error[LOOSE_FORM_FIELDS.HEIGHT] =
			detail.height && isNum(detail.height) ? null : MESSAGES.INVALID_HEIGHT
		const isValid = !Object.keys(error).some((key) =>
			error[key] ? true : false
		)
		return isValid ? null : error
	})
}

export const validateULDDetails = (ULDDetails) => {
	return ULDDetails.map((detail) => {
		const error = {}
		error[ULD_FORM_FIELDS.ULD_TYPE] = detail.uldType
			? null
			: MESSAGES.INVALID_ULD_TYPE
		error[ULD_FORM_FIELDS.PIECES] =
			detail.pieces && isNum(detail.pieces) && notZero(detail.pieces)
				? null
				: MESSAGES.INVALID_PIECES
		// error[ULD_FORM_FIELDS.CONTOUR] = detail.contour ? null : 'Enter Contour'
		error[ULD_FORM_FIELDS.WEIGHT] =
			detail.weight && isNum(detail.weight) ? null : MESSAGES.INVALID_WEIGHT
		const isValid = !Object.keys(error).some((key) =>
			error[key] ? true : false
		)
		return isValid ? null : error
	})
}
export const validateMixBooking = (looseDetails) => {
	return looseDetails.map((detail) => {
		const error = {}
		error[LOOSE_FORM_FIELDS.PIECES] =
			detail.pieces && isNum(detail.pieces) ? null : MESSAGES.INVALID_PIECES
		error[LOOSE_FORM_FIELDS.LENGTH] =
			detail['length'] && isNum(detail['length'])
				? null
				: MESSAGES.INVALID_LENGTH
		error[LOOSE_FORM_FIELDS.WIDTH] =
			detail.width && isNum(detail.width) ? null : MESSAGES.INVALID_WIDTH
		error[LOOSE_FORM_FIELDS.HEIGHT] =
			detail.height && isNum(detail.height) ? null : MESSAGES.INVALID_HEIGHT
		error[LOOSE_FORM_FIELDS.WEIGHT] =
			detail.weight && isNum(detail.weight) ? null : MESSAGES.INVALID_WEIGHT
		const isValid = !Object.keys(error).some((key) =>
			error[key] ? true : false
		)
		return isValid ? null : error
	})
}

export const validateUnid2 = ({
	unid
}) => {
	const error = {}
	error[FORM_FIELDS.UNID] = validateUnidBlur(unid)
	return error
}

export const validateUnidBlur = (unid) => {
	const error = {}
	error[UNID_FORM_FIELDS.UNID] =
		unid.length < 4 || unid === ''
			? MESSAGES.INVALID_UNID
			: null
	const isValid = !Object.keys(error).some((key) =>
		error[key] ? true : false
	)
	const error2 = []
	error2.push(error)
	return isValid ? null : error2

}

export const validateAwbDates = ({
	startDate,
	endDate
}) => {
	const error = {}
	error[FORM_FIELDS.INVALID_REPORT_START_DATE] = validateStartDate(startDate)
	error[FORM_FIELDS.INVALID_REPORT_END_DATE] = validateEndDate(endDate)
	return error
}

export const validateStartDate = (startDate) => {
	if (startDate === '' || startDate === undefined || startDate === null) {
		return MESSAGES.INVALID_REPORT_START_DATE
	}
}
export const validateEndDate = (endDate) => {
	if (endDate === '' || endDate === undefined || endDate === null) {
		return MESSAGES.INVALID_REPORT_END_DATE
	}
}

export const validateProvison = (specialProvisonErrorCheck) => {
	if (!specialProvisonErrorCheck) {
		return MESSAGES.PROVISON_ERROR
	}
}

export const validateFlightList = (key, errorMsg, index) => {
	const flightErrors = {}
	if (!flightErrors) {
		flightErrors = []
		flightErrors[index] = flightErrors[index] ? flightErrors[index] : {}
		flightErrors[index][key] = errorMsg
	}
	if (flightErrors) {
		flightErrors[index] = flightErrors[index] ? flightErrors[index] : {}
		flightErrors[index][key] = errorMsg
	}
	const isValid = !Object.keys(flightErrors).some((key) =>
		flightErrors[key] ? true : false
	)
	return isValid ? null : flightErrors
}

export const validateOnDay = (weekdays, dateScheduler) => {
	return dateScheduler != 'daily' ? weekdays?.length === 0 ? "Please select an option" : null : null
}

export const validateSeasonalBooking = ({
	seasonalFormData,
	selectedOrigin,
	selectedDestination,
	startDate,
	endDate,
	unidList,
	hasDangerous,
	hasDangerous1,
	flightList,
	shippingType,
	totalWeight,
	calculatedTotalWeight,
	isLiveAnimalBooking,
	adjustedWeight,
	looseDetails,
	ULDDetails,
	shippingItem,
	mixLooseDetails,
	expandAccordian,
	isAllotmentRequired,
	isAllotmentRequiredFlightDate,
	dateScheduler,
	weekdays,
	indexArray
}) => {
	let seasonalErrorCheck = {}
	seasonalErrorCheck[FORM_FIELDS.ORIGIN] = validateOrigin(selectedOrigin)
	seasonalErrorCheck[FORM_FIELDS.DESTINATION] = validateDestination(
		selectedDestination,
		selectedOrigin
	)
	const dateError = validateAwbDates({
		startDate,
		endDate
	})
	seasonalErrorCheck = { ...seasonalErrorCheck, ...dateError };

	seasonalErrorCheck[FORM_FIELDS.SHIPPING_ITEM] = validateShippingItem(shippingItem)

	if (hasDangerous === true || hasDangerous1 === true) {
		let unidDetailsErrors = validateUnidDetails(unidList)
		const hasError = unidDetailsErrors.some((item) => (item ? true : false))
		seasonalErrorCheck[FORM_FIELDS.UNID] = hasError ? unidDetailsErrors : null
	}

	seasonalErrorCheck[SEASONAL_FIELDS.FLIGHT_LIST] = null
	flightList.map((item, index) => {
		let flightNumberListError = validateFlightList([SEASONAL_FIELDS.FLIGHT_NUMBER], validateFieldValue(item?.flightNumber, MESSAGES.ENTER_FLIGHT_NUMBER), index);
		let flightDateListError = index > 0 ? validateFlightList([SEASONAL_FIELDS.FLIGHT_DATE], validateFieldValue(item?.flightDate, MESSAGES.ENTER_VALID_FLIGHT_DATE), index) : null;
		let flightDestinationListError = validateFlightList([SEASONAL_FIELDS.DESTINATION], validateFieldValue(item?.destination, MESSAGES.INVALID_DESTINATION), index);
		// let flightAllotmentListError = isAllotmentRequired ? validateFlightList(SEASONAL_FIELDS.ALLOTMENT_ID, validateFieldValue(item?.allotmentId, "Enter a valid allotment ID"), index) : ''
		let flightAllotmentListError = isAllotmentRequired && index === 0 ? validateFlightList(SEASONAL_FIELDS.ALLOTMENT_ID, validateFieldValue(item?.allotmentId, MESSAGES.INVALID_ALLOTMENT_ID), index) : indexArray.includes(String(index)) ? validateFlightList(SEASONAL_FIELDS.ALLOTMENT_ID, validateFieldValue(item?.allotmentId, MESSAGES.INVALID_ALLOTMENT_ID), index) : validateFlightList(SEASONAL_FIELDS.ALLOTMENT_ID, null, index)
		let flightListError = null;

		if (flightNumberListError[index] || flightDestinationListError[index] || flightDateListError[index] || flightAllotmentListError[index]) {
			// if (flightNumberListError[index] || flightDateListError[index] || flightDestinationListError[index]) {
			flightListError = { ...seasonalErrorCheck[SEASONAL_FIELDS.FLIGHT_LIST] };
		}

		if (flightNumberListError[index]) {
			flightListError[index] = { ...flightListError[index], ...flightNumberListError[index] };
		}

		if (flightDateListError) {
			flightListError[index] = { ...flightListError[index], ...flightDateListError[index] };
		}

		if (flightDestinationListError[index]) {
			flightListError[index] = { ...flightListError[index], ...flightDestinationListError[index] };
		}

		if (flightAllotmentListError) {
			flightListError[index] = { ...flightListError[index], ...flightAllotmentListError[index] };
		}


		if (!flightNumberListError[index]?.FLIGHT_NUMBER && !flightDestinationListError[index]?.DESTINATION && !flightAllotmentListError[index]?.ALLOTMENT_ID) {
			seasonalErrorCheck = {
				...seasonalErrorCheck,
				[SEASONAL_FIELDS.FLIGHT_LIST]: {
					...seasonalErrorCheck[SEASONAL_FIELDS.FLIGHT_LIST],
					[index]: null
				}
			}
		} else if (flightListError) {
			seasonalErrorCheck = { ...seasonalErrorCheck, [SEASONAL_FIELDS.FLIGHT_LIST]: flightListError };
		}
	})

	if (shippingType === SHIPPING_TYPE.LOOSE) {
		seasonalErrorCheck[FORM_FIELDS.TOTAL_WEIGHT] = validateTotalWeight(
			totalWeight,
			calculatedTotalWeight,
			isLiveAnimalBooking,
		)
		seasonalErrorCheck[FORM_FIELDS.OPERATIONAL_WEIGHT] = validateOperationalWeight(
			totalWeight,
			adjustedWeight
		)
		let looseDetailsErrors = validateLooseDetails(looseDetails)
		looseDetailsErrors = validateLineItemWeights(
			looseDetails,
			looseDetailsErrors
		)
		const hasError = looseDetailsErrors.some((item) => (item ? true : false))
		seasonalErrorCheck[FORM_FIELDS.LOOSE_ITEMS] = isLiveAnimalBooking ? null : hasError ? looseDetailsErrors : null
	} else {
		const ULDDetailsErrors = validateULDDetails(ULDDetails)
		const hasError = ULDDetailsErrors.some((item) => (item ? true : false))
		seasonalErrorCheck[FORM_FIELDS.ULD_ITEMS] = isLiveAnimalBooking ? null : hasError ? ULDDetailsErrors : null
		if (expandAccordian) {
			const mixBookingErrors = validateMixBooking(mixLooseDetails)
			const hasMixBookingError = mixBookingErrors.some((item) =>
				item ? true : false
			)
			seasonalErrorCheck[FORM_FIELDS.LOOSE_ITEMS] = isLiveAnimalBooking ? null : hasMixBookingError
				? mixBookingErrors
				: null
		}
	}

	seasonalErrorCheck[SEASONAL_FIELDS.ON_DAY] = validateOnDay(weekdays, dateScheduler)

	const isAllNull = Object.values(seasonalErrorCheck[SEASONAL_FIELDS.FLIGHT_LIST]).every(value => value === null);
	isAllNull ? seasonalErrorCheck = {
		...seasonalErrorCheck,
		[SEASONAL_FIELDS.FLIGHT_LIST]: null
	} : ''
	const isValid = !Object.keys(seasonalErrorCheck).some((key) => (seasonalErrorCheck[key] ? true : false))
	return isValid ? null : seasonalErrorCheck

}

export const validateFormData = ({
	selectedOrigin,
	selectedDestination,
	isSearchByArrivalDate,
	shippingDate,
	arrivalDate,
	shippingItem,
	shippingType,
	unid,
	hasDangerous,
	hasDangerous1,
	unidList,
	totalWeight,
	adjustedWeight,
	looseDetails,
	ULDDetails,
	additionalInfoData,
	calculatedTotalWeight,
	mixLooseDetails,
	expandAccordian,
	priceCodeType,
	priceCode,
	isUnknownShipment,
	isUSBound,
	isLiveAnimalBooking,
	cageList,
	specialProvisonErrorCheck,
}) => {
	// const { t, i18n } = useTranslation(['labels', 'errors'])
	const error = {}
	error[FORM_FIELDS.PROVISON_ERROR] = validateProvison(specialProvisonErrorCheck)
	error[FORM_FIELDS.ORIGIN] = validateOrigin(selectedOrigin)
	error[FORM_FIELDS.DESTINATION] = validateDestination(
		selectedDestination,
		selectedOrigin
	)
	error[FORM_FIELDS.SHIPPING_DATE] = validateShippingDate(shippingDate)
	if (isSearchByArrivalDate) {
		error[FORM_FIELDS.ARRIVAL_DATE] = validateArrivalDate(arrivalDate)
	}
	error[FORM_FIELDS.SHIPPING_ITEM] = validateShippingItem(shippingItem)


	//validating unid
	if (hasDangerous === true || hasDangerous1 === true) {
		let unidDetailsErrors = validateUnidDetails(unidList)
		const hasError = unidDetailsErrors.some((item) => (item ? true : false))
		error[FORM_FIELDS.UNID] = hasError ? unidDetailsErrors : null
	}
	if (isLiveAnimalBooking && cageList) {
		let cageErrors = validateCageDetails(cageList)
		if (cageErrors && cageErrors.length > 0) {
			const hasError = cageErrors.some((item) => (item ? true : false))
			if (hasError) {
				error[FORM_FIELDS.CAGE_ITEMS] = cageErrors
			}
		}
	}

	if (shippingType === SHIPPING_TYPE.LOOSE) {
		error[FORM_FIELDS.TOTAL_WEIGHT] = validateTotalWeight(
			totalWeight,
			calculatedTotalWeight,
			isLiveAnimalBooking,
		)
		error[FORM_FIELDS.OPERATIONAL_WEIGHT] = validateOperationalWeight(
			totalWeight,
			adjustedWeight
		)
		let looseDetailsErrors = validateLooseDetails(looseDetails)
		looseDetailsErrors = validateLineItemWeights(
			looseDetails,
			looseDetailsErrors
		)
		const hasError = looseDetailsErrors.some((item) => (item ? true : false))
		error[FORM_FIELDS.LOOSE_ITEMS] = isLiveAnimalBooking ? null : hasError ? looseDetailsErrors : null
	} else {
		const ULDDetailsErrors = validateULDDetails(ULDDetails)
		const hasError = ULDDetailsErrors.some((item) => (item ? true : false))
		error[FORM_FIELDS.ULD_ITEMS] = isLiveAnimalBooking ? null : hasError ? ULDDetailsErrors : null
		if (expandAccordian) {
			const mixBookingErrors = validateMixBooking(mixLooseDetails)
			const hasMixBookingError = mixBookingErrors.some((item) =>
				item ? true : false
			)
			error[FORM_FIELDS.LOOSE_ITEMS] = isLiveAnimalBooking ? null : hasMixBookingError
				? mixBookingErrors
				: null
		}
	}
	if (
		priceCode &&
		(priceCode.length > 20 || priceCode.trim().split(' ').length > 1)
	) {
		error[FORM_FIELDS.PRICE_CODE] = `Enter valid ${priceCodeType === PRICE_CODE_TYPE.PROMO_RATE ? 'promo code' : 'spot id'
			}`
	}
	if (
		shippingItem &&
		(shippingItem.product === PHARMA_PRODUCT ||
			shippingItem.product == FRESH_FORWARD_PRODUCT)
	) {
		error[PHARMA_VACCINE_FIELDS.SHIPPING_TYPE] = validatePharmaShippingType(
			additionalInfoData.pharma
		)
		error[PHARMA_VACCINE_FIELDS.HYBRID_CONTAINER_TYPE] =
			validateHybridContainerType(additionalInfoData.pharma)
		error[PHARMA_VACCINE_FIELDS.ACTIVE_CONTAINER_TYPE] =
			validateActiveContainerType(additionalInfoData.pharma)
		error[PHARMA_VACCINE_FIELDS.TEMP_RANGE] = validateTempRange(
			additionalInfoData.pharma
		)
		error[PHARMA_VACCINE_FIELDS.DRY_ICE] = validateDryIce(
			additionalInfoData.pharma
		)
	}

	if (isUSBound && isUnknownShipment == null) {
		error[FORM_FIELDS.UNKNOWN_SHIPMENT] = 'Select an option'
	}
	const isValid = !Object.keys(error).some((key) => (error[key] ? true : false))
	return isValid ? null : error
}

export const handleTilteableAndStackable = (
	looseLineItem,
	isAllTiltable,
	isAllStackable
) => {
	let looseItem = looseLineItem
	if (isAllTiltable) {
		looseItem.turnable = true
	} else {
		looseItem.turnable = false
	}
	if (isAllStackable) {
		looseItem.stackable = true
	} else {
		looseItem.stackable = false
	}
	return looseItem
}

export const checkIsBackButtonAction = (history) => {
	if (
		history.location &&
		history.location.state &&
		history.location.state.isBackButtonAction
	) {
		const isBackButtonAction = history.location.state.isBackButtonAction
		/*let state = { ...history.location.state }
		delete state.modifyBooking
		history.replace({ ...history.location, state })*/
		return isBackButtonAction
	} else {
		return false
	}
}

export const checkIsBigOrOHG = async (searchObject, setIsLoading) => {
	setIsLoading(true)
	const response = await checkShipmentIsBigOrOHG(searchObject)
		.then((response) => {
			console.log(response)
			setIsLoading(false)
			if (response && !response.errorDetails) {
				return response
			} else {
				return null
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)
			return null
		})
	return response
}
export default validateFormData