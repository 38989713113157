import {
	CSN_FLIGHT_SUMMARY_FILTER, CSN_FLIGHT_FFM_SUMMARY_FILTER
} from '../constants'

export const flightSummaryApplyFilter = (payload) => ({
	type: CSN_FLIGHT_SUMMARY_FILTER.APPLY,
	payload,
})

export const flightFFMSummaryApplyFilter = (payload) => ({
	type: CSN_FLIGHT_FFM_SUMMARY_FILTER.APPLY,
	payload,
})