import React from 'react'
import { connect } from 'react-redux'
import FlightDetails from './FlightDetails'
import { showPopup } from '../../redux/actions/popupActions'
import { closePopup } from '../../redux/actions/popupActions'
import { setFilters } from '../../redux/actions/filterActions'
import { setSearchFormData } from '../../redux/actions/searchFormActions'

const FlightDetailsContainer = ({ 
	setEnquiredPresent, 
	destinationAirport, 
	searchFormData,
	filters,
	setSearchFormDataToState,
	setFiltersToState,
	showInfoPopup,
	closeInfoPopup
 }) => {
	return (
		<FlightDetails
			setEnquiredPresent={setEnquiredPresent}
			destinationAirport={destinationAirport}
			searchFormData={searchFormData}
			setSearchFormDataToState={setSearchFormDataToState}
			setFiltersToState={setFiltersToState}
			showInfoPopup={showInfoPopup}
			closeInfoPopup={closeInfoPopup}
		/>
	)
}

const mapStateToProps = ({ 
	searchForm,
	filters 
}) => ({
	searchFormData: searchForm.searchFormData,
	filters: filters.filters,
})

const mapDispatchToProps = (dispatch) => ({
	setSearchFormDataToState: (searchFormData) => {
		dispatch(setSearchFormData(searchFormData))
	},
	setFiltersToState: (filters) => {
		dispatch(setFilters(filters))
	},
	showInfoPopup: (
		header,
		message,
		primaryBtnLbl,
		acceptFn,
		secondaryBtnLbl,
		rejectFn
	) => {
		dispatch(
			showPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn
			)
		)
	},
	closeInfoPopup: () => {
		dispatch(closePopup())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(FlightDetailsContainer)
