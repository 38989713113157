import React from 'react'
import CreateUserForm from './CreateUserForm'
import './index.scss'

const CreateUser = ({
	setAddUser,
	setAddUserSuccess,
	addUserSuccess,
	agentCode,
	stationCode,
	defaultAirportCode,
	listUsers,
	isGsaUser,
	customerCode,
	companyName,
	companyCode,
}) => {
	return (
		<div className=''>
			<CreateUserForm
				setAddUser={setAddUser}
				setAddUserSuccess={setAddUserSuccess}
				addUserSuccess={addUserSuccess}
				agentCode={agentCode}
				stationCode={stationCode}
				defaultAirportCode={defaultAirportCode}
				listUsers={listUsers}
				isGsaUser={isGsaUser}
				customerCode={customerCode}
				companyName={companyName}
				companyCode={companyCode}
			/>
		</div>
	)
}

export default CreateUser
