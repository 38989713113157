import React, { useEffect, useState, useRef } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { getAdditionalSccs } from '../../../services/searchPanelServices'

const HawbSccAutoComplete = ({
	shipmentDetails,
	handleAddAdditinalScc,
	setAlertMessage,
	setSuccess,
}) => {
	const [text, setText] = useState(null)
	const [sccs, setSccs] = useState([])
	const [filteredSccItems, setfilteredSccItems] = useState([])

	const myRef = useRef()

	useEffect(() => {
		getAdditionalSccs()
			.then((sccs) => {
				setSccs(sccs)
			})
			.catch((err) => {
				setAlertMessage &&
					setAlertMessage('Additional Sccs Error' + err.message)
				setSuccess && setSuccess(false)
			})
	}, [])

	const searchSccItems = () => {
		const shippingItemByCodeStart =
			sccs && sccs.length && text && text.length
				? sccs.filter((scc) => {
						return scc.scc.toLowerCase().startsWith(text.toLowerCase())
				  })
				: []

		const shippingItemByNameStart =
			sccs && sccs.length && text && text.length
				? sccs.filter((scc) => {
						return ` ${scc.sccDescription.toLowerCase()}`.startsWith(
							text.toLowerCase()
						)
				  })
				: []

		const shippingItemByContains =
			sccs && sccs.length && text && text.length
				? sccs.filter((scc) => {
						return (
							scc.scc.toLowerCase().includes(text.toLowerCase()) ||
							scc.sccDescription.toLowerCase().includes(text.toLowerCase())
						)
				  })
				: []

		const filteredSccItems = [
			...shippingItemByNameStart,
			...shippingItemByCodeStart,
			...shippingItemByContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.scc === item.scc)
		)

		setfilteredSccItems(filteredSccItems)
	}
	//console.log('filteredSccItems',filteredSccItems)

	const itemTemplate = (scc) => {
		return (
			<div className='scc-autocomplete'>
				<span className='scc-code'>{` ${scc.scc}`}</span>
				<span className='scc-description-hawb'>{` ${scc.sccDescription}`}</span>
			</div>
		)
	}

	return (
		<>
			<AutoComplete
				value={shipmentDetails && shipmentDetails.scc 
					? shipmentDetails.scc 
					: text}
				suggestions={filteredSccItems}				
				ref={myRef}
				completeMethod={searchSccItems}
				onChange={(e) => {
					setText(e.value)
					handleAddAdditinalScc(null)
				}}
				onSelect={(e) => {
					setText(null)
					handleAddAdditinalScc(e.value.scc)
					//console.log(e)
				}}
				
				onBlur={(e)=> {

					if (filteredSccItems && filteredSccItems.length > 0) {
						const item = filteredSccItems[0]						
						handleAddAdditinalScc(filteredSccItems[0].scc)
						setText(null)
						return
					}
					setText(null)
					//console.log('e',e)
				}}
				className={'form-control autocomplete search-panel__scc-autocomplete'}
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default HawbSccAutoComplete
