import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import envConfigs from '../configs/envConfigs'
import { getNumberUUID } from '../utils/common'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'
import { getBookingListRequestBody } from '../utils/RequestBodyUtils'

export const listAllotments = async (requestAttrs) => {
	const data = {
		requestId: getNumberUUID(4),
		listAllotmentsRequest: requestAttrs,
	}
	const url = `${envConfigs.baseUrl}${envConfigs.getAllotmentsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getDashboardChartData = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getRevenueDataUrl}`
	//const url = `http://localhost:3001/dashboard/get-revenue-data`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}


export const getBookingListResponseDashboard = async (bookingListRequest) => {
	const data = getBookingListRequestBody(bookingListRequest)
	const url = `${envConfigs.baseUrl}${envConfigs.getBookingListUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getNewsFeed = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getNewsFeedUrl}`
	//const url = `http://localhost:3001/getNewsFeed`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}