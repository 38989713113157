import { OverlayPanel } from "primereact/overlaypanel"
import React, { useRef } from 'react'
import filterIcon from "../../assets/images/filter-icon.png"
import { useTranslation } from 'react-i18next';

const PermanentBookingFilter = ({
    bookingFilter,
    setBookingFilter,
}) => {

    const filterOverlayRef = useRef()
    const { t, i18n } = useTranslation(['labels', 'errors'])
    return (
        <>
            <div className='permanent-booking-list-header__container'>
                <div className='row permanent-booking-list-header__flex'>
                    <p style={{ cursor: "pointer" }}
                        onClick={(e) => filterOverlayRef.current.toggle(e)}
                    ><img src={filterIcon} style={{ maxWidth: "25px", minWidth: "25px" }} /></p>

                    {/*<i class="bi bi-funnel"></i>*/}
                    <OverlayPanel
                        ref={filterOverlayRef}
                        className='permanent-booking-list-overlay-panel'
                        appendTo={document.body}
                    >
                        <div className="row">
                            <table class="table table-hover">
                                            {/* <p className='permanent-booking-list-overlay-panel__header'>Display:</p> */}
                                            <div className='checkbox-color'>
                                                <input style={{ marginTop: "2rem" }}
                                                    id='permanent-booking-list-overlay-panel__option_confirmed'
                                                    checked={bookingFilter.confirmed}
                                                    type='checkbox'
                                                    onChange={(e) => setBookingFilter({
                                                        ...bookingFilter,
                                                        confirmed: !bookingFilter.confirmed
                                                    })}
                                                />
                                                <label
                                                    htmlFor='permanent-booking-list-overlay-panel__option_confirmed'
                                                    className='form-item__label label-text'
                                                >
                                                    {t('COMMON.CONFIRMED')}
                                                </label>
                                            </div>
                                       <hr />
                                            <div className='checkbox-color'>
                                                <input
                                                    id='permanent-booking-list-overlay-panel__option_queued'
                                                    checked={bookingFilter.queued}
                                                    type='checkbox'
                                                    className='form-item__field-checkbox'
                                                    onChange={(e) => setBookingFilter({
                                                        ...bookingFilter,
                                                        queued: !bookingFilter.queued
                                                    })}
                                                />
                                                <label
                                                    htmlFor='permanent-booking-list-overlay-panel__option_queued'
                                                    className='form-item__label label-text'
                                                >
                                                    {t('COMMON.QUEUED')}
                                                </label>
                                            </div>
                                            <hr />
                                            <div className='checkbox-color'>
                                                <input
                                                    id='permanent-booking-list-overlay-panel__option_pending'
                                                    checked={bookingFilter.tobeProcessed}
                                                    type='checkbox'
                                                    className='form-item__field'
                                                    onChange={(e) => setBookingFilter({
                                                        ...bookingFilter,
                                                        tobeProcessed: !bookingFilter.tobeProcessed
                                                    })}
                                                />
                                                <label
                                                    htmlFor='permanent-booking-list-overlay-panel__option_pending'
                                                    className='form-item__label label-text'
                                                >
                                                   {t('EXTRAS.TO_BE_PROCESSED')}
                                                </label>
                                            </div>
                                            <hr />
                                            <div className='checkbox-color'>
                                                <input
                                                    id='permanent-booking-list-overlay-panel__option_failed'
                                                    checked={bookingFilter.failed}
                                                    type='checkbox'
                                                    className='form-item__field'
                                                    onChange={(e) => setBookingFilter({
                                                        ...bookingFilter,
                                                        failed: !bookingFilter.failed
                                                    })}
                                                />
                                                <label
                                                    htmlFor='permanent-booking-list-overlay-panel__option_failed'
                                                    className='form-item__label label-text'
                                                >
                                                    {t('EXTRAS.FAILED')}
                                                </label>
                                            </div>
                                </table>
                        </div>
                    </OverlayPanel>
                </div>
            </div>
        </>
    )
}

export default PermanentBookingFilter