import { FILTERS } from '../constants'

export const setFilters = (filters) => ({
	type: FILTERS.SET_FILTERS,
	filters,
})

export const clearFilters = () => ({
	type: FILTERS.CLEAR_FILTERS,
})
