import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	getDensityUnit,
	getVolumeUnit,
	getWeightUnit,
} from '../../../../constants'
import { getProductBySystemName } from '../../../../services/manageBookingService'
import './index.scss'
import {
	clearItemFromSessionStorage, getBase64Decoded, getFromSessionStorage, setToSessionStorage, getBase64Encoded
} from '../../../../utils/StorageUtils'
const CargoDetails = ({ bookingDetails }) => {
	//for multilingual
	const [t] = useTranslation(['labels', 'errors'])

	const [cargoDetails, setCargoDetails] = useState([])
	const [productName, setProductName] = useState('')
	const dgCheckNormal = getFromSessionStorage('ifDg')

	useEffect(() => {

		if (bookingDetails && bookingDetails.bookingCargoDetails) {
			setCargoDetails(bookingDetails.bookingCargoDetails)
		}
		; (async () => {
			try {
				let product = await getProductBySystemName(
					bookingDetails.bookingCargoDetails.product
				)
				if (product) {
					setProductName(product.description)
				}
			} catch (error) {
				setProductName(bookingDetails.bookingCargoDetails.product)
				console.log(error.message)
			}
		})()
	}, [])

	const getPetsSum = (cages) => {
		let petList = cages.map((item) => {
			return item.pets.length
		})
		let sum = 0;
		for (const item of petList) {
			sum += item;
		}
		if (sum > 1) {
			return sum + ' Pets';
		}
		else {
			return sum + ' Pet';
		}
	}

	const getCageString = (length) => {
		let cageString = length > 1 ? 'Containers' : 'Container'
		return cageString;
	}

	return (
		<div id='cargo-details' className='cargo-details-wrapper mt-2 mt-lg-3 mb-3'>
			<div className='form-row'>
				<div className='col-12'>
					<h3 className='page-sub-head'>{t('COMMON.CARGO_DTLS')}</h3>
				</div>
			</div>

			<div className='form-row mt-3 cargo-details-inner-wrapper ml-0 mr-0'>
				<div className='col-12 col-lg-6'>
					<div className='cargo-details-item'>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{bookingDetails.bookingCargoDetails.product === "LiveAnimals" ? t('COMMON.QTY') : t('COMMON.PCS')}
							</label>
							<div className='col-12 col-lg-8'> {bookingDetails.bookingCargoDetails.product === "LiveAnimals" ? bookingDetails.crates && bookingDetails.crates[0]?.weight ? `${getPetsSum(bookingDetails.crates)} in ${bookingDetails.crates.length} ${getCageString(bookingDetails.crates.length)}` : '' : cargoDetails.quantity} </div>
						</div>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.GROSS_WT')}
							</label>
							<div className='col-12 col-lg-8'>
								{cargoDetails.grossWeight}
								{getWeightUnit(bookingDetails.weightUnit)}
							</div>
						</div>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.VOL')}
							</label>
							<div className='col-12 col-lg-8'>
								{' '}
								{cargoDetails.volume}
								{getVolumeUnit(bookingDetails.volumeUnit)}
							</div>
						</div>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.DENSITY')}
							</label>
							{cargoDetails.density ? (
								<div className='col-12 col-lg-8'>
									{cargoDetails.density}
									{getDensityUnit(
										bookingDetails.weightUnit,
										bookingDetails.volumeUnit
									)}
								</div>
							) : (
								<div className='col-12 col-lg-8'>--</div>
							)}
						</div>

						{bookingDetails && bookingDetails?.additionalCommodityDetails !== null &&
							bookingDetails.bookingCargoDetails.product !== "LiveAnimals" || bookingDetails && bookingDetails?.additionalCommodityDetails !== null &&
							bookingDetails.bookingCargoDetails.product !== "LiveAnimals" && dgCheckNormal === 'true' ?

							<div className='form-row'>
								<label className='col-12 col-lg-4 form-item__label'>
									{t('COMMON.UNIDSUMMARY')}
								</label>
								{/* {cargoDetails.shipmentScc&& cargoDetails.shipmentScc.map((scc) => ( */}
								<div className='col-12 col-lg-8'>
									{bookingDetails && bookingDetails?.additionalCommodityDetails
										? bookingDetails?.additionalCommodityDetails?.unidInfo?.map((e, index) =>
											bookingDetails?.additionalCommodityDetails?.unidInfo?.length - 1 === index ? `${e.unid}` : `${e.unid}, `)
										: '--'}
								</div>
							</div> : <></>
						}
					</div>
				</div>
				<div className='col-12 col-lg-6'>
					<div className='cargo-details-item'>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.PRD')}
							</label>
							{bookingDetails?.bookingCargoDetails?.product ? (
								<div className='col-12 col-lg-8'> {bookingDetails.bookingCargoDetails.product}</div>
							) : (
								<div className='col-12 col-lg-8'>--</div>
							)}
						</div>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.COMMODITY')}
							</label>
							{cargoDetails.commodity ? (
								<div className='col-12 col-lg-8'> {cargoDetails.commodity}</div>
							) : (
								<div className='col-12 col-lg-8'>--</div>
							)}
						</div>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.SERVICE')}
							</label>
							<div className='col-12 col-lg-8'>
								{cargoDetails.service ? cargoDetails.service : '--'}
							</div>
						</div>
						<div className='form-row'>
							<label className='col-12 col-lg-4 form-item__label'>
								{t('COMMON.SCC')}
							</label>
							{/* {cargoDetails.shipmentScc&& cargoDetails.shipmentScc.map((scc) => ( */}
							{cargoDetails.shipmentScc ? (
								<span className='col-12 col-lg-8 pl-0 pl-lg-1'>
									{(() => {
										return cargoDetails.shipmentScc.map((e) => e).join(", ")
									})()}
								</span>
							) : (
								<div className='col-12 col-lg-8'>--</div>
							)}
						</div>


					</div>
				</div>
			</div>
		</div>
	)
}

export default CargoDetails
