import { USER_PROFILE } from '../constants'

const initState = {
	request: null,
	response: null,
	error: null,
}

export default function (state = initState, action) {
	switch (action.type) {
		case USER_PROFILE.LOAD:
			return {
				...state,
				request: action.userCode,
				response: null,
				error: null,
			}
		case USER_PROFILE.SUCCESS:
			return {
				...state,
				response: action.response,
			}
		case USER_PROFILE.ERROR:
			return {
				...state,
				error: action.error,
			}
		case USER_PROFILE.CLEAR:
			return {
				...state,
				response: action.response,
			}
		default:
			return state
	}
}
