import React, { useEffect, useState } from 'react'
import ULDInfoDisplay from './ULDInfoDisplay'
import LooseInfoDisplay from './LooseInfoDisplay'
import { useTranslation } from 'react-i18next'
const WeightDetails = ({ bookingDetails }) => {
	//for multilingual
	const [t] = useTranslation(['labels', 'errors'])

	const [looseInfoDetails, setLooseInfoDetails] = useState([])
	const [uldInfoDetails, setUldInfoDetaila] = useState([])
	const [productStackable, setProductStackable] = useState('')
	const [productTiltable, setProductTiltable] = useState('')
	const [isCrates, setIsCrates] = useState('')
	const uldColumns = [
		'COMMON.ULD_TYPE',
		'COMMON.NO_OF_UNITS',
		'COMMON.WEIGHT_KG',
	]
	const looseInfocolumns = [
		isCrates === "LIVE DOGS" || isCrates === "LIVE CATS" ? 'COMMON.CRTS' : "COMMON.PCS",
		'COMMON.LENGTH_CM',
		'COMMON.WIDTH_CM',
		'COMMON.HEIGHT_CM',
		'COMMON.WEIGHT_KG',
		isCrates === "LIVE DOGS" || isCrates === "LIVE CATS" ? null : 'COMMON.WT_TYPE',
		isCrates === "LIVE DOGS" || isCrates === "LIVE CATS" ? null : 'COMMON.PCKG_TYPE',
	]


	useEffect(() => {
		if (bookingDetails && bookingDetails.weightDetails) {
			setLooseInfoDetails(bookingDetails.weightDetails.looseDetails)
			setUldInfoDetaila(bookingDetails.weightDetails.uldInfoDetaills)
			setIsCrates(bookingDetails.bookingCargoDetails.commodity)
			let stackableStatus = []
			let tiltableStatus = []
			bookingDetails.weightDetails.looseDetails.filter((looseDetail) => {
				looseDetail.stackable === 'Y' || !looseDetail.stackable
					? stackableStatus.push('Y')
					: stackableStatus.push('N')
				looseDetail.tiltable === 'Y'
					? tiltableStatus.push('Y')
					: tiltableStatus.push('N')
			})
			stackableStatus.includes('N') ? setProductStackable('non stackable')
				: setProductStackable('stackable')
			tiltableStatus.includes('N') ? setProductTiltable('non tiltable')
				: setProductTiltable('tiltable')

		}
	}, [])


	return (
		<div className='wight-dispaly-wrapper'>
			{uldInfoDetails.length ? (
				<div id='uld-info' className='uld-info-wrapper mt-4 mb-3'>
					<div className='form-row mb-3'>
						<div className='col-12'>
							<h3 className='page-sub-head'>{t('VIEWBKG.ULD_INFO')}</h3>
						</div>
					</div>
					<div id='weight-InfoTable'>
						<ULDInfoDisplay
							weightUnit={bookingDetails.weightUnit}
							dimensionUnit={bookingDetails.dimensionUnit}
							uldColumns={uldColumns}
							uldInfoDetails={uldInfoDetails}
						/>
					</div>
				</div>
			) : (
				<> </>
			)}
			{looseInfoDetails.length ? (
				<div id='dimension-info' className='dimension-info-mapper mt-4 mb-3'>
					<div className='form-row mb-4 mb-lg-3'>
						<div className='col-12'>
							{uldInfoDetails.length ? (
								<h3 className='page-sub-head'>{t('VIEWBKG.OVERFLOW')}</h3>
							) : (
								<div className='form-row loose-info-subwrapper'>
									<div className='col-12 col-lg-4'>
										<h3 className='page-sub-head'>
											{isCrates === "LIVE DOGS" || isCrates === "LIVE CATS" ? "Container info" : t('VIEWBKG.LOOSE_DETAILS')}
										</h3>
									</div>
									<div className='col-12 col-lg-8 stackable-tiltable-wrapper'>
										{/* {`Packing type of all products are ${productStackable} and ${productTiltable}`} */}
									</div>
								</div>
							)}
						</div>
					</div>
					<div id='weight-InfoTable'>
						<LooseInfoDisplay
							weightUnit={bookingDetails.weightUnit}
							dimensionUnit={bookingDetails.dimensionUnit}
							looseInfocolumns={looseInfocolumns}
							isCrates={isCrates}
							looseInfoDetails={looseInfoDetails}
						/>
					</div>
				</div>
			) : (
				<> </>
			)}
		</div>
	)
}
export default WeightDetails
