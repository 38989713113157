import moment from 'moment'
import { listAllotments } from '../../../services/dashboardServices'
import getCurrentUser from '../../../utils/getCurrentUser'

export const getAllotments = async (period, setIsLoading) => {
	setIsLoading(true)
	const userDetails = getCurrentUser()
	const requestAttrs = {
		fromDate: getCalendarDate(),
		toDate: getCalendarDate(period),
		globalCustomerCode: userDetails ? userDetails.agentCode : '',
	}

	const allotmentsList = await listAllotments(requestAttrs)
		.then((response) => {
			setIsLoading(false)
			if (response && response.listAllotments) {
				return response
			} else {
				setIsLoading(false)
				return null
			}
		})
		.catch((error) => {
			console.log(error)
			//addError('LIST_ALLOTMENTS', error)
			setIsLoading(false)
			return null
		})
	if (allotmentsList && allotmentsList.listAllotments) {
		return allotmentsList
	} else {
		return null
	}
}

export const getAllotmentColorClass = (percentage) => {
	if (percentage < 40) {
		return 'val-red'
	} else if (percentage < 80) {
		return 'val-grey'
	} else {
		return 'val-green'
	}
}

const getCalendarDate = (period) => {
	const today = moment()
	if (period) {
		var date = moment(today).add(period, 'days')
		return date.format('DD-MMM-YYYY')
	} else {
		return today.format('DD-MMM-YYYY')
	}
}
