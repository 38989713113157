import moment from 'moment'
import getPrincipal from '../../utils/getPrincipal'
import getCurrentUser from '../../utils/getCurrentUser'
import {
	getWeightUnit,
	WEIGHT_UNIT,
	DIMENSION_UNIT,
	getVolumeUnit,
	getDensityUnit,
	SERVICES,
	DISPLAY_SERVICES,
	getDecimalFormattedNumber,
	getFormattedNumber,
	getRoundedValue,
	BOOKING_STATUSES,
	capitalize,
} from '../../constants'
import { getPdfForPrint } from '../../services/notificationService'
import { openBase64NewTab } from '../CaptureAWB/printAwbUtils'
import {
	getBase64Decoded,
	getFromSessionStorage,
} from '../../utils/StorageUtils'
import i18n from '../../i18n'
import {calculateWeightToKg } from '../../utils/common'

export const getShareEmailRequest = (
	email,
	selectedFlight,
	searchFormData,
	screenName,
	awbNumber,
	saveBookingResponse,
	showChargesInSummary,
	showCharges,
	quoteExpiryTime,
	quoteName,
	noRates,
	emailType,
) => {
	const t = i18n.t

	const userDetails = getCurrentUser()
	const origin = selectedFlight ? selectedFlight.origin : null
	const destination = selectedFlight ? selectedFlight.destination : null
	let quoteFromSession = getFromSessionStorage('quotationDetails')
	let quotationDetails =
		quoteFromSession !== null ? getBase64Decoded(quoteFromSession) : null
	const quoteId =
		quotationDetails && quotationDetails.quotationId
			? quotationDetails.quotationId
			: null
	const language = i18n.language

	const dgCheckNormal = getFromSessionStorage('ifDg')
	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	let unidInfoArray = [];

	searchFormData?.unid?.filter((item, index) => {

		unidInfoArray.push({
			id: item?.id,
			unid: item?.unid,
			shippingName: item?.unidName,
			packingGroup: item?.packingGroup
		})

	})
	return {
		toEmailAddresses: email,
		subject: `${screenName === 'QUOTE'
			? `${t('BOOKING_SUMMARY.SHARE_QUOTE_SUBJECT', {
				ORG: origin,
				DES: destination,
			})}`
			: `${t('BKGSHARE.BKGSHARE_SUBJECT', {
				AWB: awbNumber,
				ORG: origin,
				DES: destination,
			})}`
			}`,
		templateName:
			language === 'zh'
				? `${screenName === 'QUOTE'
					? 'booking_quote'
					: searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? 'booking_confirmation_avi' : ((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true')) ? "booking_confirmation_share_dg" : "booking_confirmation"
				}`
				: `${screenName === 'QUOTE' ? 'booking_quote' : searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? 'booking_confirmation_avi' : "booking_confirmation"
				}`,
		attributes: getPdfAttributes(
			selectedFlight,
			searchFormData,
			screenName,
			awbNumber,
			saveBookingResponse,
			showChargesInSummary,
			showCharges,
			quoteExpiryTime,
			quoteName,
			userDetails,
			noRates,
			'email',
		),
		hasAttachments: true,
		attachmentName: `${screenName === 'QUOTE'
			? t('BOOKING_SUMMARY.SHARE_QUOTE_ATTACH_NAME')
			: t('BKGSHARE.BKGSHARE_ATTACH_NAME')
			}`,
		agentCode: userDetails ? userDetails.agentCode : null,
		userId: userDetails ? userDetails.userId : null,
		async: false,
		cages: searchFormData?.crates[0]?.weight ? searchFormData.crates : null,
		emailType: emailType
	}
}

export const getPrintRequest = (
	selectedFlight,
	searchFormData,
	awbNumber,
	saveBookingResponse,
	showCharges,
	noRates
) => {
	const userDetails = getCurrentUser()
	let quoteFromSession = getFromSessionStorage('quotationDetails')
	let quotationDetails =
		quoteFromSession !== null ? getBase64Decoded(quoteFromSession) : null
	const quoteId =
		quotationDetails && quotationDetails.quotationId
			? quotationDetails.quotationId
			: null
	const language = i18n.language
	const dgCheckNormal = getFromSessionStorage('ifDg')
	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	let unidInfoArray = [];

	searchFormData?.unid?.filter((item, index) => {

		unidInfoArray.push({
			id: item?.id,
			unid: item?.unid,
			shippingName: item?.unidName,
			packingGroup: item?.packingGroup
		})

	})
	return {
		templateName:
			language === 'zh' ? 'booking_confirmation_zh' : searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? 'booking_confirmation_avi' : ((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true')) ? "booking_confirmation_share_dg" : "booking_confirmation",
		attributes: getPdfAttributes(
			selectedFlight,
			searchFormData,
			null,
			awbNumber,
			saveBookingResponse,
			null,
			showCharges,
			null,
			quoteId,
			userDetails,
			noRates,
			'print',
		),
		agentCode: userDetails ? userDetails.agentCode : null,
		userId: userDetails ? userDetails.userId : null,
		cages: searchFormData?.crates[0]?.weight ? searchFormData.crates : null,
		additionalCommodityDetails: ((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true')) ?
			{
				unidInfo: unidInfoArray
			} : null
		,


	}
}

export const handlePrint = async (
	selectedFlight,
	searchFormData,
	awbNumber,
	saveBookingResponse,
	showCharges,
	setIsLoading,
) => {
	const t = i18n.t
	const noRates = selectedFlight && ((selectedFlight.ratingDetails &&
		selectedFlight.ratingDetails.length &&
		(selectedFlight.ratingDetails[0].grandTotal === 0 || selectedFlight.ratingDetails[0].totalRate === 0)) || 
		selectedFlight.isForceQueued ||
		selectedFlight.overHangShipment ||
		selectedFlight.bigShipment)
		? true
		: false

	setIsLoading(true)
	const pdfEncoded = await getPdfForPrint(
		getPrintRequest(
			selectedFlight,
			searchFormData,
			awbNumber,
			saveBookingResponse,
			showCharges,
			noRates
		)
	)
		.then((response) => {
			setIsLoading(false)
			if (response && response.pdfToPrint) {
				return response.pdfToPrint
			} else {
				return null
			}
		})
		.catch((error) => {
			setIsLoading(false)
			window.scrollTo(0, 0)
		})
	if (pdfEncoded) {
		openBase64NewTab(pdfEncoded, `${t('PRINT.PDF_NAME_BKG_CONFRMTN')}.pdf`)
	}
}

const getDropOffAddress = (selectedFlight) => {
	return `${selectedFlight.dropOffAddress.name ? selectedFlight.dropOffAddress.name : ''
		}, 
		${selectedFlight.dropOffAddress.streetAndHouseNumber
			? selectedFlight.dropOffAddress.streetAndHouseNumber
			: ''
		}, 
		${selectedFlight.dropOffAddress.country
			? selectedFlight.dropOffAddress.country
			: ''
		}, 
		${selectedFlight.dropOffAddress.city
			? selectedFlight.dropOffAddress.city
			: ''
		}, 
		${selectedFlight.dropOffAddress.postCode
			? selectedFlight.dropOffAddress.postCode
			: ''
		}`
}

const getPickUpAddress = (selectedFlight) => {
	return `${selectedFlight.pickUpAddress.name ? selectedFlight.pickUpAddress.name : ''
		}, 
		${selectedFlight.pickUpAddress.streetAndHouseNumber
			? selectedFlight.pickUpAddress.streetAndHouseNumber
			: ''
		}, 
		${selectedFlight.pickUpAddress.country
			? selectedFlight.pickUpAddress.country
			: ''
		}, 
		${selectedFlight.pickUpAddress.city ? selectedFlight.pickUpAddress.city : ''
		}', '
		${selectedFlight.pickUpAddress.postCode
			? selectedFlight.pickUpAddress.postCode
			: ''
		}`
}

const getChargeableWeight = (searchFormData) => {
	if (
		searchFormData.weightUnit &&
		searchFormData.weightUnit !== WEIGHT_UNIT.KILOGRAM
	) {
		return `${searchFormData
			? getRoundedValue(searchFormData.chargeableWeight)
			: ''
			}
			${getWeightUnit(searchFormData.weightUnit)} (${searchFormData ? calculateWeightToKg(searchFormData.chargeableWeight, searchFormData.weightUnit) : ''
			}
			${getWeightUnit(WEIGHT_UNIT.KILOGRAM)})`
	} else {
		return `${searchFormData ? getRoundedValue(searchFormData.chargeableWeight) : ''
			}
			${getWeightUnit(WEIGHT_UNIT.KILOGRAM)}`
	}
}

const getFlightSegments = (selectedFlight) => {
	var result = selectedFlight.flightSegmentList.map((segment) => ({
		flightNumber: `${segment.flightCarrierCode}${segment.flightNumber}`,
		departureTime: moment(segment.departureDateTime).format('HH:mm'),
		departureDate: moment(segment.departureDateTime, 'DD-MMM-YYYY').format('ddd DD MMM'),
		arrivalTime: moment(segment.arrivalDateTime).format('HH:mm'),
		arrivalDate: moment(segment.arrivalDateTime, 'DD-MMM-YYYY').format('ddd DD MMM'),
		origin: segment.segmentOrigin,
		destination: segment.segmentDestination,
		flightType: selectedFlight.flightSegmentList[0].flightType,
		aircraftType: segment.aircraftType,
		duration: getFormatedTime(segment.flyingHours),
	}))
	return result
}

export const getPdfAttributes = (
	selectedFlight,
	searchFormData,
	screenName,
	awbNumber,
	saveBookingResponse,
	showChargesInSummary,
	showCharges,
	quoteExpiryTime,
	quoteName,
	userDetails,
	noRates,
	requestType

) => {
	const t = i18n.t
	const language = i18n.language
	const departureTime = selectedFlight
		? moment(selectedFlight.departureDateTime)
		: null
	const arrivalTime = selectedFlight
		? moment(selectedFlight.arrivalDateTime)
		: null
	const departureDate = selectedFlight
		? moment(selectedFlight.departureDateTime, 'DD-MMM-YYYY')
		: null
	const arrivalDate = selectedFlight
		? moment(selectedFlight.arrivalDateTime, 'DD-MMM-YYYY')
		: null
	const origin = selectedFlight ? selectedFlight.origin : null
	const destination = selectedFlight ? selectedFlight.destination : null
	const flightDate =
		selectedFlight && selectedFlight.flightSegmentList
			? moment(selectedFlight.flightSegmentList[0].flightDate, 'DD-MMM-YYYY').format(
				'DD-MMM-YY'
			)
			: null
	const attributes = {
		bookingStatus:
			screenName === 'QUOTE'
				? null
				: saveBookingResponse && saveBookingResponse.bookingResponseDetails
					? t(
						BOOKING_STATUSES[
						saveBookingResponse.bookingResponseDetails.bookingStatus
						]
					)
					: 'C',
		awbNumber: awbNumber,
		flightDate: flightDate,
		quoteId: screenName === 'QUOTE' ? quoteName : null,
		validityDate: `${screenName === 'QUOTE' ? `${quoteExpiryTime}(UTC)` : null
			}`,
		companyName: userDetails ? userDetails.companyName : null,
		departureTime: departureTime && departureTime.format('HH:mm'),
		arrivalTime: arrivalTime && arrivalTime.format('HH:mm'),
		departureDate: departureDate && departureDate.format('ddd DD MMM'),
		arrivalDate: arrivalDate && arrivalDate.format('ddd DD MMM'),
		origin: origin,
		destination: destination,
		duration: getFormatedTime(selectedFlight.cumulativeJourneyTime),
		flightSegments: getFlightSegments(selectedFlight),
		dropOffAddress: getDropOffAddress(selectedFlight),
		pickUpAddress: getPickUpAddress(selectedFlight),
		toa: `${moment(selectedFlight.earliestDeliveryTime, 'DD-MMM-YYYY').format(
			'ddd, DD MMM'
		)} ${moment(selectedFlight.earliestDeliveryTime).format('HH:mm')}`,
		lat: `${moment(selectedFlight.lastAcceptanceTime, 'DD-MMM-YYYY').format(
			'ddd, DD MMM'
		)} ${moment(selectedFlight.lastAcceptanceTime).format('HH:mm')}`,
		noOfPieces: getFormattedNumber(searchFormData.totalPieces),
		grossWeight: `${searchFormData.grossWeightInSelectedUnit} 
			${getWeightUnit(searchFormData.weightUnit)}`,
		volume: `${getDecimalFormattedNumber(searchFormData.actualVolume)} 
					${getVolumeUnit(searchFormData.dimensionUnit)}`,
		volumeConverted: `(${searchFormData.totalVolumeInMtrs
			? getDecimalFormattedNumber(searchFormData.totalVolumeInMtrs)
			: 0.01
			} ${getVolumeUnit(DIMENSION_UNIT.METERS)})`,
		chargeableWeight: getChargeableWeight(searchFormData),
		product: `${searchFormData.shippingItemCode.product
			? (searchFormData.shippingItemCode.serviceProduct || searchFormData.shippingItemCode.product)
			: ''
			}`,
		commodity: `${searchFormData.shippingItemCode.description
			? searchFormData.shippingItemCode.description
			: ''
			}`,
		service: `${selectedFlight.service
			? selectedFlight.service &&
				selectedFlight.service === SERVICES.FAST_TRACK
				? DISPLAY_SERVICES[SERVICES.FAST_TRACK]
				: null
			: '--'
			}`,
		shipmentSCC: `${searchFormData.sccCodes
			? searchFormData.sccCodes.map((e) => e.scc).toString()
			: ''
			}`,
		density: `${searchFormData.densityInSelectedUnit}
			${getDensityUnit(searchFormData.weightUnit, searchFormData.dimensionUnit)}`,
		densityConverted: `(${searchFormData.densityInConvertedUnit}
				${getDensityUnit(WEIGHT_UNIT.KILOGRAM, DIMENSION_UNIT.METERS)})`,

		airFreightCostPerKg: selectedFlight.ratingDetails[0].totalFreightRate !== 0
			? `${selectedFlight.ratingDetails[0].currency}
				   ${getDecimalFormattedNumber(selectedFlight.ratingDetails[0].totalFreightRate)}
				   /${getWeightUnit(WEIGHT_UNIT.KILOGRAM)}`
			: null,
		// airFreightCostPerKg: `${selectedFlight.ratingDetails[0].currency} 
		// 	${getDecimalFormattedNumber(
		// 		selectedFlight.ratingDetails[0].totalFreightRate
		// 	)}/${getWeightUnit(WEIGHT_UNIT.KILOGRAM)}`,
		airFreightCost: `${selectedFlight.ratingDetails[0].currency
			} ${getDecimalFormattedNumber(
				selectedFlight.ratingDetails[0].totalFreightCharge
			)}`,
		surcharges: `${selectedFlight.ratingDetails[0].currency} 
			${selectedFlight.ratingDetails[0].totalSurcharge === null
				? '0'
				: getDecimalFormattedNumber(
					selectedFlight.ratingDetails[0].totalSurcharge
				)
			}`,
		otherCharges: getOtherChargeDetails(selectedFlight, searchFormData),
		totalCost: `${selectedFlight.ratingDetails[0].currency
			} ${getDecimalFormattedNumber(selectedFlight.ratingDetails[0].grandTotal)}`,
		showCharges: requestType === 'email' ? showChargesInSummary : showCharges,
		noRates: noRates
	}
	return attributes
}

const getOtherChargeDetails = (selectedFlight, searchFormData) => {
	if (
		selectedFlight.ratingDetails[0] &&
		selectedFlight.ratingDetails[0].otherChargeDetails &&
		selectedFlight.ratingDetails[0].otherChargeDetails.length
	) {
		const otherCharges = []
		selectedFlight.ratingDetails[0].otherChargeDetails.forEach(function (item) {
			const amount = `${selectedFlight.ratingDetails[0].currency
				} ${getDecimalFormattedNumber(getChargeAmount(item))}`

			const otherCharge = {
				amount: amount,
				code: item.code,
				name: item.name ? capitalize(item.name) : null,
				chargePerKg: null,
			}
			otherCharges.push(otherCharge)
		})
		return otherCharges
	} else {
		return null
	}
}

const getChargeAmount = (element) => {
	if (element.chargeAmount) {
		return element.chargeAmount
	} else if (element.amount) {
		return element.amount
	}
}

const getFormatedTime = (value) => {
	let formattedTime = ''
	if (value) {
		let tempArray = value.split(':')
		formattedTime =
			parseInt(tempArray[0], 10) +
			'Hrs ' +
			(tempArray[1] && tempArray[1] !== '00' ? tempArray[1] + ' Min' : '')
	}
	return formattedTime
}
