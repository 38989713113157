import React, { useState, useEffect } from 'react';
import ReportsMenu from './ReportsMenu';
import Calendar from '../../common/Calendar';
import moment from 'moment';
import { generateAwbReports } from '../../services/generateReportsServices';
import envConfigs from '../../configs/envConfigs';
// import { saveAs } from 'file-saver';
import { ALLOWABLE_BOOKING_DATE } from '../../configs/appConfigs'
import { Dropdown } from '../../common/DropDown'
import { useTranslation } from 'react-i18next'
import {
    AWB_REPORT_TYPE
} from '../../constants'

import {
    clearItemFromLocalStorage,
    getFromLocalStorage,
    setToLocalStorage,
} from '../../utils/StorageUtils'

import {
    checkIsBackButtonAction,
    handleTilteableAndStackable,
    validateFormData,
    checkIsBigOrOHG,
} from '../SearchPanel/formUtils'

import { validateAwbDates } from '../SearchPanel/formUtils';
import LoadingIndicator from '../LoadingIndicator'
import {
    FORM_FIELDS,

} from '../../constants'

import MESSAGES from '../../constants/Messages'


const AwbChargesReport = () => {

    const [isLoading, setIsLoading] = useState(false)
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [awbNumber, setAwbNumber] = useState('');
    const [reportType, setReportType] = useState('csv');
    const [maxShippingDate, setMaxShippingDate] = useState(moment().toDate())
    //const [minDate, setMinDate] = useState(moment().subtract(2, 'years').toDate())
    const [error, setError] = useState(null)
    const handleDownload = () => {
        let fromDate = moment(startDate).format('YYYY-MM-DD')
        let toDate = moment(endDate).format('YYYY-MM-DD')
        var agentDetails = getFromLocalStorage('userDetails')


        const handleSetError = (key, text) => {
            error
                ? setError({
                    ...error,
                    [key]: text,
                })
                : setError({
                    [key]: text,
                })
        }

        if (startDate === '' || endDate === '') {

            const errorObj = validateAwbDates({
                startDate,
                endDate
            })
            setError(errorObj)
        }

        else {
            setIsLoading(true)
            const data = {
                "awbNumber": awbNumber,
                "customerName": JSON.parse(agentDetails).companyName,
                "fromDate": fromDate,
                "toDate": toDate,
                "agentCode": JSON.parse(agentDetails).agentCode,
                "reportType": reportType
            }

            generateAwbReports(data).then((res) => {
                setIsLoading(false)
                let url = res.data
                reportType === 'csv' ? window.open(url, "_self") : window.open(url, "_blank")

            })
        }

    }


    const clearError = (key) => {
        const tempError = error ? { ...error } : null
        if (tempError) {
            tempError[key] = null
            setError(tempError)
        }
    }
    const handleSetError = (key, text) => {
        error
            ? setError({
                ...error,
                [key]: text,
            })
            : setError({
                [key]: text,
            })
    }




    return (
        <div className='cargo-portal-container'>
            <ReportsMenu
                activeItem={0}
            />
            <div className='form-row search-panel'>
                <div
                    className={`col col-12 col-lg-3 ${error && error[FORM_FIELDS.INVALID_REPORT_START_DATE] ? 'red-border' : ''
                        }`}
                >

                    <lablel>Start date</lablel>

                    <Calendar
                        className={`form-item__field form-control`}
                        //value={moment(shippingDate, 'DD-MMM-YYYY').toDate()}
                        onChangeHandler={(shippingDate) => {
                            setStartDate(shippingDate)
                            if (shippingDate) {
                                const errorObj = error ? { ...error } : {}
                                errorObj[FORM_FIELDS.INVALID_REPORT_START_DATE] = null
                                errorObj[FORM_FIELDS.INVALID_REPORT_START_DATE] = null
                                setError(errorObj)
                            }

                        }}
                        onFocusHandler={() =>
                            clearError(FORM_FIELDS.INVALID_REPORT_START_DATE)
                        }
                        placeholder='Start date'
                        //minDate={minDate}
                        maxDate={endDate === '' ? moment(maxShippingDate, 'DD-MMM-YYYY').toDate() : moment(endDate, 'DD-MMM-YYYY').toDate()}
                        onErrorHandler={() => {
                            handleSetError(
                                FORM_FIELDS.INVALID_REPORT_START_DATE,
                                MESSAGES.INVALID_REPORT_START_DATE
                            )
                        }}
                    // onBlur={({ target }) => { }}
                    />


                    <span className='search-panel__errormsg'>
                        {error && error[FORM_FIELDS.INVALID_REPORT_START_DATE]
                            ? t(error[FORM_FIELDS.INVALID_REPORT_START_DATE], { ns: 'errors' })
                            : ''}
                    </span>

                </div>
                <div
                    className={` col col-12 col-lg-3 ${error && error[FORM_FIELDS.INVALID_REPORT_END_DATE] ? 'red-border' : ''
                        }`}
                >
                    <lablel>End date</lablel>
                    <Calendar
                        className={`form-item__field form-control`}
                        //value={moment(shippingDate, 'DD-MMM-YYYY').toDate()}
                        onChangeHandler={(shippingDate) => {
                            setEndDate(shippingDate)
                            if (shippingDate) {
                                const errorObj = error ? { ...error } : {}
                                errorObj[FORM_FIELDS.INVALID_REPORT_END_DATE] = null
                                errorObj[FORM_FIELDS.INVALID_REPORT_END_DATE] = null
                                setError(errorObj)
                            }
                        }}
                        onFocusHandler={() =>
                            clearError(FORM_FIELDS.INVALID_REPORT_END_DATE)
                        }
                        placeholder='End date '
                        minDate={startDate}
                        maxDate={maxShippingDate}
                        onErrorHandler={() => {
                            handleSetError(
                                FORM_FIELDS.INVALID_REPORT_END_DATE,
                                MESSAGES.INVALID_REPORT_END_DATE
                            )
                        }}
                    />
                    <span className='search-panel__errormsg'>
                        {error && error[FORM_FIELDS.INVALID_REPORT_END_DATE]
                            ? t(error[FORM_FIELDS.INVALID_REPORT_END_DATE], { ns: 'errors' })
                            : ''}
                    </span>
                </div>
                <div className='col col-12 col-lg-4'>
                    <lablel>AWB number (Optional)</lablel>
                    <input
                        className='form-control form-item__field'
                        type='text'
                        onChange={(e) => setAwbNumber(e.target.value)}
                    />
                </div>
                <div className='col col-12 col-lg-2'>
                    <lablel>Report Type</lablel>
                    <Dropdown
                        value={reportType}
                        options={AWB_REPORT_TYPE}
                        label=''
                        onChange={(e) => {
                            setReportType(e.target.value);
                        }}

                    ></Dropdown>
                </div>
            </div>
            <div className='form-row search-panel'>
                <div className='col col-12 col-lg-3'>
                    <button type='button' className='button mt-4'
                        onClick={handleDownload}
                    >
                        {'Download'}
                    </button>
                </div>
            </div>


            {isLoading ? <LoadingIndicator /> : <></>}
        </div>
    )
}

export default AwbChargesReport;