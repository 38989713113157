
import MESSAGES from '../../constants/Messages'

const REQUIRED_FIELD_ERROR = MESSAGES.FIELD_REQUIRED

export const hasError = (key, error) => {
	if (error !== null && error[key] && error[key] !== '') {
		return true
	} else {
		return false
	}
}

export const removeError = (event, setError) => {
	const id = event.target.id + ''
	setError((prevState) => ({ ...prevState, [id]: '' }))
}

export const validateRequired = (value, setError, key) => {
	if (value &&
		!(typeof value === 'string' || value instanceof String)
		) {
			return true
	} else if (value && value.trim().length) {
		return true
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: REQUIRED_FIELD_ERROR,
		}))
		return false
	}
}