import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'
import getCurrentUser from '../utils/getCurrentUser'

let userDetails = getCurrentUser();


export const processFlightRotations = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.processFlightRotations}`
	//const url = `http://localhost:8310/schedule/process-flight-rotations`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const swapInboundFlights = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.swapInboundFlights}`
	//const url = `http://localhost:8310/schedule/swap-inbound-flights`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const processSchedules = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.processSchedules}`
	//const url = `http://localhost:8310/schedule/process-schedules`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const getFlightRotations = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getFlightRotations}`
	//const url = `http://localhost:8310/schedule/round-trips`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const getSegments = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getSegments}`
	//const url = `http://localhost:8310/schedule/segments`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const updateSegments = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.updateSegments}`
	//const url = `http://localhost:8310/schedule/update-segments`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const exportPdf = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.exportPdf}`
	//const url = `http://localhost:8310/schedule/generate-pdf`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

