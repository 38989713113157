import React, { useState, useEffect } from 'react'
import './uld-overflow.scss'
import LooseLineItem from '../LooseLineItem'
import { getInitLooseItemDetail } from '../../constants'
import { useTranslation } from 'react-i18next'
const ULDOverflow = ({
	looseDetails,
	setLooseDetails,
	totalWeight,
	setTotalWeight,
	setLooseItemsGrossWeight,
	weightUnit,
	setWeightUnit,
	dimensionUnit,
	setDimensionUnit,
	isAllStackable,
	setAllStackable,
	isAllTiltable,
	setAllTiltable,
	handleAddLineItem,
	setTotalPieces,
	error,
	setError,
	setTotalVolumeInMeters,
	setActualLooseTotalVolume,
	setTotalWeightInKg,
	totalWeightInKilo,
	totalVolumeinMtrs,
	setChargeableWeight,
	calculatedTotalWeight,
	setCalculatedTotalWeight,
	showValidation,
	expandAccordian,
	setExpandAccordian,
	hideLooseUpload,
	draftCallback,
	excludeTon,
	captureAwb,
}) => {
	const [t] = useTranslation(['labels'])

	const handleCloseIconclick = (e) => {
		e.preventDefault()
		setExpandAccordian(false)
		setLooseDetails([getInitLooseItemDetail()])
	}
	return (
		<div>
			<div className='overflow'>
				<div className='heading'>
					<span
						className='heading-text'
						onClick={(e) => handleCloseIconclick(e)}
					>
						{t('COMMON.ADD_OVERFLOW')}
					</span>
					<span className='close-icon' onClick={(e) => handleCloseIconclick(e)}>
						X
					</span>
				</div>
				<LooseLineItem
					looseDetails={looseDetails}
					setLooseDetails={setLooseDetails}
					totalWeight={totalWeight}
					setTotalWeight={setTotalWeight}
					setLooseItemsGrossWeight={setLooseItemsGrossWeight}
					weightUnit={weightUnit}
					setWeightUnit={setWeightUnit}
					dimensionUnit={dimensionUnit}
					setDimensionUnit={setDimensionUnit}
					isAllStackable={false}
					setAllStackable={setAllStackable}
					isAllTiltable={false}
					setAllTiltable={setAllTiltable}
					handleAddLineItem={handleAddLineItem}
					setTotalPieces={setTotalPieces}
					setTotalVolumeInMeters={setTotalVolumeInMeters}
					setActualLooseTotalVolume={setActualLooseTotalVolume}
					setChargeableWeight={setChargeableWeight}
					setTotalWeightInKg={setTotalWeightInKg}
					totalWeightInKilo={totalWeightInKilo}
					totalVolumeinMtrs={totalVolumeinMtrs}
					error={error}
					setError={setError}
					calculatedTotalWeight={calculatedTotalWeight}
					setCalculatedTotalWeight={setCalculatedTotalWeight}
					showValidation={showValidation}
					disableWeightUnit={true}
					hideLooseUpload={hideLooseUpload}
					draftCallback={draftCallback}
					excludeTon={excludeTon}
					captureAwb={captureAwb}
				/>
			</div>
		</div>
	)
}

export default ULDOverflow
