import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Chips } from 'primereact/chips'
import { emailPattern, EMAIL_RECIPIENTS_LIMIT } from '../../constants'
import { Alert } from '../../common/Alert'
import LoadingIndicator from '../LoadingIndicator'
import { sendEmail } from '../../services/notificationService'
import { getShareEmailRequest } from './printEmailUtil'
import MESSAGES from '../../constants/Messages'
import { useTranslation } from 'react-i18next'
import { addError, pushPageData } from '../../utils/analytics'
const ShareEmailDialog = ({
	selectedFlight,
	searchFormData,
	saveBookingResponse,
	getFormatedTime,
	awbNumber,
	displayBasic,
	setDisplayBasic,
	showCharges,
	screenName,
	quoteExpiryTime,
	quoteName,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const[emailInvalid,setEmailInvalid]=useState('')
	const [email, setEmail] = useState([])
	const [error, setError] = useState(null)

	const [isLoading, setIsLoading] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [sentSuccess, setSentSuccess] = useState(false)
	const [showChargesInSummary, setShowChargesInSummary] = useState(
		screenName === 'QUOTE' ? true : false
	)
	const emailType = 'shareBooking'

	const noRates =
		(selectedFlight &&
			selectedFlight.ratingDetails &&
			selectedFlight.ratingDetails.length &&
			(selectedFlight.ratingDetails[0].grandTotal === 0 || selectedFlight.ratingDetails[0].totalRate === 0)) ||
			(selectedFlight && (selectedFlight.isForceQueued ||
				selectedFlight.overHangShipment ||
				selectedFlight.bigShipment))
			? true
			: false

	const handleShareEmail = () => {
		if (email && email.length > 0) {
			setIsLoading(true)
			onHide()
			const request = getShareEmailRequest(
				email,
				selectedFlight,
				searchFormData,
				screenName,
				awbNumber,
				saveBookingResponse,
				showChargesInSummary,
				showCharges,
				quoteExpiryTime,
				quoteName,
				noRates,
				emailType,

			)
			sendEmail(request)
				.then((response) => {
					setIsLoading(false)
					if (response.errorDetails) {
						setAlertMessage(
							`${screenName === 'QUOTE'
								? t(MESSAGES.SHARE_QUOTE_FAILED, { ns: 'errors' })
								: t(MESSAGES.SHARE_CONFIRMATION_FAILED, { ns: 'errors' })
							}`
						)
						addError(`${screenName === 'QUOTE'
							? (MESSAGES.SHARE_QUOTE_FAILED, i18n.getResource('en', 'errors', MESSAGES.SHARE_QUOTE_FAILED))
							: (MESSAGES.SHARE_CONFIRMATION_FAILED, i18n.getResource('en', 'errors', MESSAGES.SHARE_CONFIRMATION_FAILED))
							}`)
						setSentSuccess(false)
					} else {
						setAlertMessage(
							`${screenName === 'QUOTE'
								? t(MESSAGES.SHARE_QUOTE_SUCCESS, {
									quoteId: quoteName,
									emailId: email,
									ns: 'errors',
								})
								: t(MESSAGES.SHARE_CONFIRMATION_SUCCESS, {
									emailId: email,
									ns: 'errors',
								})
							}`
						)
						setSentSuccess(true)
					}
					window.scrollTo(0, 0)
				})
				.catch((error) => {
					setIsLoading(false)
					setAlertMessage(
						`${screenName === 'QUOTE'
							? t(MESSAGES.SHARE_QUOTE_FAILED, { ns: 'errors' })
							: t(MESSAGES.SHARE_CONFIRMATION_FAILED, { ns: 'errors' })
						}`
					)
					addError(`${screenName === 'QUOTE'
						? (MESSAGES.SHARE_QUOTE_FAILED, i18n.getResource('en', 'errors', MESSAGES.SHARE_QUOTE_FAILED))
						: (MESSAGES.SHARE_CONFIRMATION_FAILED, i18n.getResource('en', 'errors', MESSAGES.SHARE_CONFIRMATION_FAILED))
						}`)
					setSentSuccess(false)
					window.scrollTo(0, 0)
				})
		} else {
			setError(t(MESSAGES.INCORRECT_MAIL_ENTERED, {
				val: emailInvalid,
				ns: 'errors',
			}))
			addError(MESSAGES.INCORRECT_MAIL_ENTERED, i18n.getResource('en', 'errors', MESSAGES.INCORRECT_MAIL_ENTERED))
		}
	}

	const onHide = () => {
		setDisplayBasic(false)
		setEmail([])
		setError(null)
		setAlertMessage('')
		setSentSuccess(false)
		if (screenName === 'QUOTE') {
			setShowChargesInSummary(true)
		} else {
			setShowChargesInSummary(false)
		}
	}

	const onChange = (e) => {
		const value = e.value.toString().trim()
		let lastVal = ''
		if (value && value.indexOf(',') === -1) {
			lastVal = value
		} else {
			let values = value.split(',')
			lastVal = values[values.length - 1]
		}
		if (!emailPattern.test(lastVal)) {
			setError(
				t(MESSAGES.INCORRECT_MAIL_ENTERED, {
					val: lastVal,
					ns: 'errors',
				})
			)
			setTimeout(() => {
				setError(null)
			}, 5000)
		} else {
			setError(null)
			setEmail(e.value)
		}
	}

	const handleAlertClose = () => {
		setAlertMessage('')
		setSentSuccess(false)
	}

	const onBlur = (e) => {
		if (e.target.value && email) {
			if (!emailPattern.test(e.target.value)) {
				setEmailInvalid(e.target.value)
				setError(
					t(MESSAGES.INCORRECT_MAIL_ENTERED, {
						val: e.target.value,
						ns: 'errors',
					})
				)
			} else {
				setEmailInvalid('')
				setError(null)
				setEmail([...email, ...[e.target.value]])
				e.target.value = ''
			}
		}
	}

	const onFocus = (e) => {
		setError(null)
		e.target.value = ''
	}

	const onRemove = (e) => {
		if (email && email.length === 1 && e.value.toString() === email[0]) {
			setEmail([])
			setError(null)
		}
	}

	const RenderFooter = () => {
		return (
			<div>
				<button onClick={handleShareEmail} className='button mr-0'>
					{t('COMMON.SHARE')}
				</button>
			</div>
		)
	}

	return (
		<div className='cargo-portal-container dialog-container'>
			{alertMessage !== '' && (
				<Alert
					isError={!sentSuccess}
					showAlert={true}
					message={alertMessage}
					onAlertClose={handleAlertClose}
					setTimeout={true}
				/>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
			<div className='portal-dialog p-fluid'>
				<Dialog
					header=''
					visible={displayBasic}
					className='dialogWrapper'
					onHide={onHide}
				>
					<div>
						{screenName === 'QUOTE' ? (
							<>
								<h3 className='mt-1 mb-3'>
									{t('BOOKING_SUMMARY.SHARE_QUOTE')}
								</h3>
								<p>{t(MESSAGES.SHARE_QUOTE_POP_UP_TEXT, { ns: 'errors' })}</p>
							</>
						) : (
							<>
								<h3 className='mt-1 mb-3'>{t('BKGSHARE.BKGSHARE_DETAILS')}</h3>
								<p>{t('BKGSHARE.BKGSHARE_EMAILINFO')}</p>
							</>
						)}
						<label>
							<span className='input-info'>
								{t(MESSAGES.POP_UP_TEXT_BOX_INFO, { ns: 'errors' })}
							</span>
						</label>
						<Chips
							value={email}
							onChange={onChange}
							separator=','
							className='email-ids'
							allowDuplicate={false}
							max={EMAIL_RECIPIENTS_LIMIT}
							onBlur={onBlur}
							onFocus={onFocus}
							onRemove={onRemove}
						/>
						<span className='form-item__error'>{error ? error : ''}</span>

						<div className='form-row mt-3'>
							<div className='col-12 col-md-12'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='showChargesInSummary'
										checked={showChargesInSummary}
										value='showChargesInSummary'
										type='checkbox'
										name='showChargesInSummary'
										className='form-item__field'
										onChange={(e) => setShowChargesInSummary(e.target.checked)}
									/>
									<label
										htmlFor='showChargesInSummary'
										className='form-item__label label-text'
									>
										{t('COMMON.INCLUDE_CHARGES')}
									</label>
								</div>
							</div>
						</div>
						<div className='form-row mt-4 mb-2'>
							<div className='col-12 align-center'>
								<button onClick={handleShareEmail} className='button mr-0'>
									{t('COMMON.SEND')}
								</button>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	)
}

export default ShareEmailDialog
