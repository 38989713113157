import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import BookingConfirmation from '../BookingConfirmation'
import FlightSearchPage from '../FlightSearchPage'
import QuoteDetails from '../QuoteDetails'
import ViewQuote from '../ViewQuote'
import ReportsContainer from '../Reports'

const Booking = () => {
	const history = useHistory()

	return (
		<>
			<div className='container-lg'>
				<Switch>
					<Route
						exact
						path='/booking'
						render={() => {
							history.push('/booking/search')
						}}
					/>
					<Route
						exact
						path='/booking/search'
						render={(props) => <FlightSearchPage {...props} />}
					/>
					<Route path='/booking/flightlist' component={QuoteDetails} />
					<Route exact path='/booking/summary' component={ViewQuote} />
					<Route path='/booking/confirmation' component={BookingConfirmation} />
					<Route
						exact
						path='/booking/summary/:quotationName'
						component={ViewQuote}
					/>
					<Route
						exact
						path='/booking/template/:serialNo/:templateName'
						render={(props) => (
							<FlightSearchPage {...props} disableBeforeClosePrompt={true} />
						)}
					/>
					<Route
						exact
						path='/booking/search/:awbNo'
						render={(props) => (
							<FlightSearchPage {...props} disableBeforeClosePrompt={true} />
						)}
					/>
					<Route
						exact
						path='/booking/modify/:awbNo'
						render={(props) => (
							<FlightSearchPage
								{...props}
								isModifyBooking={true}
								disableBeforeClosePrompt={true}
							/>
						)}
					/>
				</Switch>
			</div>
		</>
	)
}

export default Booking
