import { SAVE_DRAFT } from '../constants'

export const requestSaveDraft = (payload) => ({
    type: SAVE_DRAFT.LOAD,
    payload,
})
export const saveDraftResponse = (response) => ({
    type: SAVE_DRAFT.SUCCESS,
    response,
})
export const saveDraftError = (error) => ({
    type: SAVE_DRAFT.ERROR,
    error,
})

export const clearSaveDraft = () => ({
	type: SAVE_DRAFT.CLEAR,
})
