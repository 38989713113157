import React, { useEffect, useRef, useState } from 'react'
import './search-summary.scss'
import {
	getDensityUnit,
	getWeightUnit,
	DIMENSION_UNIT,
	WEIGHT_UNIT,
	SHIPPING_TYPE,
	getVolumeUnit,
	calculateDensity,
	getDecimalFormattedNumber,
	getFormattedNumber,
	getOneDecimalFormattedNumber,
	getRoundedValue,
} from '../../constants'
import calculateKgWeight from '../../utils/calculateKgWeight'
import { Message } from 'primereact/message'
import { calculateWeightToKg, round } from '../../utils/common'
import { useTranslation } from 'react-i18next'
import MESSAGES from '../../constants/Messages'
const SearchSummary = ({
	totalPieces,
	totalVolumeInMtrs,
	totalWeightInKg,
	weightUnit,
	dimensionUnit,
	chargeableWeight,
	looseDetails,
	shippingType,
	totalWeight,
	looseItemsGrossWeight,
	calculatedTotalWeight,
	actualGrossWeight,
	actualTotalIneItemWeight,
	actualVolume,
	setGrossWeightInSelectedUnit,
	setGrossWeightInConvertedUnit,
	setDensityInSelectedUnit,
	setdensityInConvertedUnit,
	chargeableWeightInSelectedUnit,
	cageList,
	shippingItem,
	AviEnabled,
	cages,
}) => {
	/*for multilingual*/
	const { t, i18n } = useTranslation(['labels'])

	let weightMismatch = false

	const getLooseChargeableWeight = () => {
		let count = 0
		looseDetails.forEach(function (element) {
			if (!element.stackable) {
				count++
			}
		})
		if (count) {
			return false
		} else {
			return true
		}
	}


	const getPetsSum = () => {
		let petList = cages.map((item) => {
			return item.pets.length
		})
		let sum = 0;
		for (const item of petList) {
			sum += item;
		}
		if (sum > 1) {
			return sum + ' Pets';
		}
		else {
			return sum + ' Pet';
		}
	}

	const getCageString = (length) => {
		let cageString = length > 1 ? 'Containers' : 'Container'
		return cageString;
	}

	return (
		<>
			<div className='search-summary mt-3'>
				<div className='form-row col-12 mb-0'>
					<div className={`quantity-summary col-6  ${(shippingItem?.code === 'DOG' || shippingItem?.code === 'CAT') ? 'col-lg-3' : 'col-lg-2'}`} >
						<label>{AviEnabled ? t('COMMON.QTY') : t('COMMON.PCS')} </label>
						<div className='value-field'>{cages && cages[0]?.weight ? `${getPetsSum()} in ${cages.length} ${getCageString(cages.length)}` : getFormattedNumber(totalPieces)}</div>
					</div>

					<div className='col-6 col-lg-2'>
						<label>{t('COMMON.GROSS_WT')}</label>
						{shippingType && shippingType === SHIPPING_TYPE.LOOSE ? (
							// <div
							// 	className={`value-field
							// ${totalWeight &&
							// 			calculatedTotalWeight &&
							// 			totalWeight * 1 > calculatedTotalWeight * 1
							// 			? 'highlight-weight'
							// 			: ''
							// 		}`}
							// >
							<div
								className={`value-field
						       	${actualGrossWeight &&
										actualTotalIneItemWeight &&
										actualGrossWeight * 1 > actualTotalIneItemWeight * 1
										? 'highlight-weight'
										: ''
									}`}
							>
								<span style={{ display: 'inline' }}>
									{actualGrossWeight && actualTotalIneItemWeight &&
										actualGrossWeight * 1 > actualTotalIneItemWeight * 1
										? 
										// (setGrossWeightInSelectedUnit(
										// 	getOneDecimalFormattedNumber(actualGrossWeight)
										// ),
										// 	getOneDecimalFormattedNumber(actualTotalIneItemWeight) +
										// 	' of ' +
										// 	getOneDecimalFormattedNumber(actualGrossWeight))
										// : actualGrossWeight
										// 	? (setGrossWeightInSelectedUnit(
										// 		getOneDecimalFormattedNumber(actualGrossWeight)
										// 	),
										// 		getOneDecimalFormattedNumber(actualGrossWeight))
										// 	: (setGrossWeightInSelectedUnit(0), 0)
										(setGrossWeightInSelectedUnit(
											getOneDecimalFormattedNumber(actualGrossWeight)
										),
											getOneDecimalFormattedNumber(actualTotalIneItemWeight) +
											' of ' +
											getOneDecimalFormattedNumber(actualGrossWeight))
										: actualGrossWeight
											? (setGrossWeightInSelectedUnit(
												actualGrossWeight
											),
												actualGrossWeight)
											: (setGrossWeightInSelectedUnit(0), 0)
											}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getWeightUnit(weightUnit)}
								</span>
								{/* {totalWeight &&
									calculatedTotalWeight &&
									totalWeight * 1 > calculatedTotalWeight * 1
									? (setGrossWeightInConvertedUnit(
										getOneDecimalFormattedNumber(
											calculateWeightToKg(calculatedTotalWeight, weightUnit)
										)
									),
										getOneDecimalFormattedNumber(
											calculateWeightToKg(calculatedTotalWeight, weightUnit)
										) +
										' of ' +
										getOneDecimalFormattedNumber(
											calculateWeightToKg(totalWeight, weightUnit)
										))
									: totalWeightInKg
										? (setGrossWeightInConvertedUnit(
											getOneDecimalFormattedNumber(totalWeightInKg)
										),
											getOneDecimalFormattedNumber(totalWeightInKg))
										: (setGrossWeightInConvertedUnit(0), 0)} */}
								<span style={{ display: 'inline' }}>
									{totalWeight &&
										calculatedTotalWeight &&
										totalWeight * 1 > calculatedTotalWeight * 1
										? setGrossWeightInConvertedUnit(
											round(
												calculateWeightToKg(calculatedTotalWeight, weightUnit), 2
											)
										)
										: totalWeightInKg
											? setGrossWeightInConvertedUnit(
												round(totalWeightInKg, 2)
											)
											: setGrossWeightInConvertedUnit(0)}
								</span>
							</div>
						) : (
							<></>
						)}

						{shippingType && shippingType === SHIPPING_TYPE.ULD ? (
							<div className='value-field'>
								<span style={{ display: 'inline' }}>
									{actualGrossWeight
										? (setGrossWeightInSelectedUnit(
											getOneDecimalFormattedNumber(actualGrossWeight)
										),
											getOneDecimalFormattedNumber(actualGrossWeight))
										: (setGrossWeightInSelectedUnit(0), 0)}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getWeightUnit(weightUnit)}
								</span>
								{/* {totalWeightInKg
                  ? (setGrossWeightInConvertedUnit(
                    getOneDecimalFormattedNumber(totalWeightInKg)
                  ),
                    getOneDecimalFormattedNumber(totalWeightInKg))
                  : (setGrossWeightInSelectedUnit(
                    getOneDecimalFormattedNumber(0)
                  ),
                    0)}
                {getWeightUnit(WEIGHT_UNIT.KILOGRAM)} */}
								{totalWeightInKg
									? setGrossWeightInConvertedUnit(
										round(totalWeightInKg, 2)
									)
									: setGrossWeightInSelectedUnit(0)
								}
							</div>
						) : (
							<></>
						)}
					</div>
					<span className='d-none d-lg-block pr-4'></span>
					<div className='col-6 col-lg-2'>
						<label>{t('COMMON.VOL')} </label>
						{dimensionUnit === DIMENSION_UNIT.CENTIMETERS ? (
							<div className='value-field'>
								<span style={{ display: 'inline' }}>
									{getDecimalFormattedNumber(totalVolumeInMtrs)}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getVolumeUnit(DIMENSION_UNIT.METERS)}
								</span>
							</div>
						) : (
							<div className='value-field'>
								<span style={{ display: 'inline' }}>
									{getDecimalFormattedNumber(actualVolume)}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getVolumeUnit(dimensionUnit)}
								</span>
							</div>
						)}
					</div>
					<span className='d-none d-lg-block pr-4'></span>

					<div className='col-6 col-lg-2'>
						<label>{t('COMMON.DENSITY')} </label>
						{dimensionUnit === DIMENSION_UNIT.CENTIMETERS ? (
							<div className='value-field'>
								<span style={{ display: 'inline' }}>
									{actualGrossWeight && totalVolumeInMtrs
										? (setDensityInSelectedUnit(
											calculateDensity(totalVolumeInMtrs, actualGrossWeight)
										),
											calculateDensity(totalVolumeInMtrs, actualGrossWeight))
										: '0'}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getDensityUnit(weightUnit, DIMENSION_UNIT.METERS)}
								</span>
								{totalWeightInKg && totalVolumeInMtrs
									? setdensityInConvertedUnit(
										calculateDensity(totalVolumeInMtrs, totalWeightInKg)
									)
									: setdensityInConvertedUnit(0)}
							</div>
						) : (
							<div className='value-field'>
								<span style={{ display: 'inline' }}>
									{actualGrossWeight && actualVolume
										? (setDensityInSelectedUnit(
											calculateDensity(actualVolume, actualGrossWeight)
										),
											calculateDensity(actualVolume, actualGrossWeight))
										: (setDensityInSelectedUnit(0.0), 0.0)}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getDensityUnit(weightUnit, dimensionUnit)}
								</span>

								{totalWeightInKg && totalVolumeInMtrs
									? setdensityInConvertedUnit(
										calculateDensity(totalVolumeInMtrs, totalWeightInKg)
									)
									: setdensityInConvertedUnit(0)}
							</div>
						)}
					</div>

					<span className='d-none d-lg-block pr-4'></span>
					{(cageList && cageList.length > 0 && (shippingItem?.code === 'DOG' || shippingItem?.code === 'CAT')) || (shippingType &&
						shippingType === SHIPPING_TYPE.LOOSE &&
						getLooseChargeableWeight()) ? (
						<div className='col-6 col-lg-2'>
							<label>{t('COMMON.CH_WT')} </label>
							<div className='value-field'>
								{/* {chargeableWeight ? getRoundedValue(chargeableWeight) : 0.0}
                  {getWeightUnit(WEIGHT_UNIT.KILOGRAM)} */}
								<span style={{ display: 'inline' }}>
									{chargeableWeightInSelectedUnit && actualGrossWeight
										? getRoundedValue(chargeableWeightInSelectedUnit)
										: 0.0}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getWeightUnit(weightUnit)}
								</span>
							</div>
						</div>
					) : (
						<></>
					)}
					{shippingType && shippingType === SHIPPING_TYPE.ULD ? (
						<div className='col-3'>
							<label>Chargeable weight </label>
							<div className='value-field'>
								{/* {chargeableWeight ? getRoundedValue(chargeableWeight) : 0.0}
                {getWeightUnit(WEIGHT_UNIT.KILOGRAM)} */}
								<span style={{ display: 'inline' }}>
									{chargeableWeightInSelectedUnit && actualGrossWeight
										? getRoundedValue(chargeableWeightInSelectedUnit)
										: 0.0}
								</span>
								<span style={{ display: 'inline-block' }}>
									{getWeightUnit(weightUnit)}
								</span>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
				{(!AviEnabled && shippingType &&
					shippingType === SHIPPING_TYPE.LOOSE &&
					!getLooseChargeableWeight()) && (shippingItem?.code !== 'DOG' && shippingItem?.code !== 'CAT') ? (
					<div className='form-row col-12 mt-3 mb-0 warning-error'>
						<Message
							severity='info'
							text={t(MESSAGES.CHARGEABLE_WT_DIFFER_MSG, { ns: 'errors' })}
						></Message>
					</div>
				) : (
					<></>
				)}
				{shippingType === SHIPPING_TYPE.LOOSE &&
					totalWeight && (shippingItem?.code !== 'DOG' && shippingItem?.code !== 'CAT') &&
					looseDetails.find((detail) => detail.weight) ? (
					<div className='form-row col-12 mt-3 mb-0 warning-error'>
						<Message
							severity='info'
							text={t(MESSAGES.PIECE_LEVEL_WT_DISREGARD, { ns: 'errors' })}
						></Message>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	)
}
export default SearchSummary
