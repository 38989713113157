import React, { useEffect, useState, useRef } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { getAdditionalSccs } from '../../services/searchPanelServices'

import { useTranslation } from 'react-i18next'
import { getUnidInfo, validateunid } from '../../services/dgBookingServices'
// import { validateUnidBlur } from '../SearchPanel/formUtils'
import { validateUnid2 } from '../SearchPanel/formUtils'

import {
	AUTOCOMPLETE_UNID,
	FORM_FIELDS,
	NOTIFICATION_MESSAGE_TYPE,
	UNID_FORM_FIELDS,
} from '../../constants'
import MESSAGES from '../../constants/Messages'

import {
	getFromSessionStorage,
	setToSessionStorage,
} from '../../utils/StorageUtils'
import csnDashboardReducer from '../../redux/reducers/csnDashboardReducer'

const UnidAutocomplete = ({
	autoCompleteType,
	handleAddAdditinalScc,
	setSccWarning,
	setNotificationMessage,
	setNotificationType,
	isCustomized,
	initialValue,
	handleSccChange,
	setUnidName,
	setPackingGroup,
	unid,
	setUnid,
	unidSelectedValidation,
	setUnidSelectedValidation,
	index,
	setMultiunidCheck,
	multiunidCheck,
	removeDuplicates,
	additionalSccs,
	setAdditionalSccs,
	shcValues,
	setshcValues,
	shippingItem,
	selectedOrigin,
	selectedDestination,
	appliedSccs,
	setAppliedSccs,
	unidSccs,
	setUnidSccs,
	unidSccList,
	setUnidSccList,
	// setUnidID,
	setId,
	unidArray,
	handleUnidSccList,
	handleAppliedUnidSccs,
	tabUnid,
	idBlur,
	shippingNameBlur,
	packingGroupBlur,
	error3,
	hasError,
	unidList,

	//setId

	handleCAODelete,
}) => {
	const [unidWarning, setUnidWarning] = useState(null)
	const [sccs, setSccs] = useState([])
	const [sccsD, setSccsD] = useState([])
	const [filteredSccItems, setfilteredSccItems] = useState([])
	const [selectedUnid, setSelectedUnid] = useState(null)
	const [text, setText] = useState(null)
	const [number, setNumber] = useState(null)
	const [filteredUnids, setFilteredUnids] = useState([])
	const isFocused = useRef(false)
	const [error, setError] = useState(null)
	const autoCompleteRef = useRef(null)
	const draftref = useRef(false);
	const myRef = useRef()
	const { t, i18n } = useTranslation(['labels', 'errors'])
	// const [packingGroup1, setPackingGroup1] = useState(item?.packingGroup)

	useEffect(() => {
		let sccMasterData = getFromSessionStorage('master-data-sccs')

		if (sccMasterData) {
			sccMasterData = JSON.parse(sccMasterData)
		}
		if (sccMasterData && sccMasterData.length > 0) {
			setSccsD(sccMasterData)

		} else {
			getAdditionalSccs()
				.then((sccs) => {
					setSccsD(sccs)
					setToSessionStorage('master-data-sccs', JSON.stringify(sccs))
				})
				.catch((err) => {
					setNotificationMessage &&
						setNotificationMessage(
							'Additional Sccs Error :' + err.message ? err.message : ''
						)
					setNotificationType &&
						setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
				})
		}
		if (initialValue) {
			setNumber(initialValue)
		}
	}, [])


	// useEffect(() => {
	// 	console.log("hellp")
	// 	handleAddAdditinalScc(shcValues)
	// }, [shcValues])

	useEffect(() => {
		// unid list call

		// const cageListJson = getFromSessionStorage('cageList')
		setSccs(unidArray)
		if (unidArray?.length > 0) {
			setSccs(unidArray)
		}
		else {

			getUnidInfo()
				.then((sccs) => {
					setSccs(sccs)
				})
				.catch((err) => {
					setNotificationMessage &&
						setNotificationMessage(
							'Additional Sccs Error :' + err.message ? err.message : ''
						)
					setNotificationType &&
						setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
				})
			if (initialValue) {

				setUnid(initialValue)
			}
		}
	}, [])

	const draftCallback = () => {
		draftref.current = true
	}

	const handleSetError = (key, text) => {
		error3
			? setError({
				...error3,
				[key]: text,
			})
			: setError({
				[key]: text,
			})
	}
	const handleSelect = (unid) => {
		autoCompleteType === AUTOCOMPLETE_UNID.UNID
		//setSelectedUnid(unid)
		draftCallback()
	}

	const handleOnBlur = async () => {
		let unidByCode
		autoCompleteType === AUTOCOMPLETE_UNID.UNID
		setTimeout(() => {
			setFilteredUnids([])
		}, 500)
		if (unid && unid.length >= 2) {
			try {
				const reversed = [...sccs].reverse()
				reversed.find((item, index) => {
					if (item.unid.toLowerCase().startsWith(unid.toLowerCase())) {
						unidByCode = item.unid
						shippingNameBlur = item.shippingName
						idBlur = item.id
						packingGroupBlur = item.packingGroup
					}
				})
				setUnid(unidByCode)
				setUnidName(shippingNameBlur)
				setId(idBlur)
				setPackingGroup(packingGroupBlur)
				tabUnid = unidByCode
				draftCallback()
				//return
			} catch (err) {
				console.log(err)
			}
		}
	}

	const setFieldLevelError = (e) => {
		if (e.target.value.length <= 3) {
			if (autoCompleteType === AUTOCOMPLETE_UNID.UNID) {
				const error5={}
				unid=e.target.value
				const errorObj=validateUnid2({
					unid
				})
				let hasError;
				if(errorObj!=null){
					hasError=true
				}
				setError(errorObj)
				addBorder()
			}
		}
	}

	function addBorder() {
		var elements = document.getElementsByClassName("input-wrapper dg-unid-input ");
		for (var i = 0; i < elements.length; i++) {
			const largeText = elements[i].outerHTML
			const startIndex = largeText.indexOf('value=') + 7;
			const endIndex = largeText.indexOf('>', startIndex + 1) - 1;
			const specificText = largeText.slice(startIndex, endIndex);
			if (elements[i].outerHTML.includes("value=\"\"") || specificText.length < 4) {
				elements[i].classList.add('red-border')
			}

		}
	}

	function deleteBorder() {
		var elements = document.getElementsByClassName("input-wrapper dg-unid-input ");
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.remove('red-border')
		}
	}


	const searchShippingItems = () => {

		const shippingItemByCodeStart =
			sccs && sccs?.length && unid && unid?.length
				? sccs.filter((scc) => {
					return scc.unid.toLowerCase().startsWith(unid.toLowerCase())
				})
				: []

		const shippingItemByNameStart =
			sccs && sccs.length && unid && unid.length
				? sccs.filter((scc) => {
					return ` ${scc.unid.toLowerCase()}`
						.startsWith(
							unid.toLowerCase()
						)
				})
				: []

		const shippingItemByContains =
			sccs && sccs.length && unid && unid.length
				? sccs.filter((scc) => {
					return (
						scc.unid.toLowerCase().includes(unid.toLowerCase()) ||
						scc.unid.toLowerCase().includes(unid.toLowerCase())
					)
				})
				: []

		const filteredSccItems = [
			...shippingItemByNameStart,
			...shippingItemByCodeStart,
			...shippingItemByContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.unid === item.unid)
		)

		setfilteredSccItems(filteredSccItems)
	}

	const itemTemplate = (scc) => {

		return (
			<div className='scc-autocomplete'>
				<span className='scc-code'>{` ${scc.unid}`}</span>
				{/* <span className='scc-description'>{` ${scc.sccDescription}`}</span> */}
			</div>
		)
	}

	const clearError = (key) => {
        const tempError = error ? { ...error } : null
        if (tempError) {
            tempError[key] = null
            setError(tempError)
        }
    }

	const handleOnFocus = (e) => {
		if (!unid) {
			myRef.current.onDropdownClick(e, '')
		}
	}


	return (
		<>
			<AutoComplete
				key={index}
				name='unid'
				AutoComplete='false'
				value={unid}
				type="number"
				suggestions={filteredSccItems}
				maxLength={4}
				completeMethod={searchShippingItems}
				autoHighlight

				onChange={(e) => {
					const value = (e.value) ? e.value : '';
					e.value.unid !== '' ? setUnidName(e.value.shippingName) : setUnidName('')
						(e?.value?.packingGroup ? setPackingGroup(e?.value?.packingGroup) : setPackingGroup(''))
					if (handleSccChange) {
						setUnid(typeof (value) === "string" ? value.substring(0, 4) : value)
						//handleSccChange(e)
					}
					else {
						setUnid(typeof (value) === "string" ? value.substring(0, 4) : value)
					}
					if (unidSccs.length > 0) {
						setUnidSelectedValidation({})

						let tempUnidSccList = handleUnidSccList()
						let tempAppliedSccs = handleAppliedUnidSccs(tempUnidSccList)

						setUnidSccList(tempUnidSccList)

						handleCAODelete(tempAppliedSccs)
						setUnidSccs([])
					}
				}
				}

				onFocus={(e) => {
					if (error3 !== null) {
							error3[FORM_FIELDS.UNID] = null
							setError(error3)
						}
					clearError(FORM_FIELDS.UNID)
					deleteBorder()		
				}}
				onSelect={(e) => {
					const unidArr = [];
					const shcCode = [];
					let shcCodeVals = [];

					sccs.filter((item, index) => {
						const itemValue = item


						if (item.unid === e.value.unid) {

							if (item?.packingGroup === '') {

								unidArr.push(
									{
										"label": `${item?.shippingName}`,
										"value": `${item?.shippingName} +${item?.id}`,
										'id': item?.id
									}

								);
							}

							else {

								unidArr.push(
									{
										"label": `${item?.shippingName} (PG ${item?.packingGroup})`,
										"value": `${item?.shippingName} (PG ${item?.packingGroup})  + ${item?.id}`,
										'id': item?.id
									}
								);
							}

							sccsD.filter((items, index) => {

								const splitedArray = itemValue?.shcCodes.split(',');
								splitedArray.map(async (splitedItem, index) => {
									if (items?.scc === splitedItem) {

										const shcItemC = {
											"scc": items?.scc,
											"sccDescription": items?.sccDescription
										}


										let filteredScc = appliedSccs.some(e => e?.scc === splitedItem)

										if (!filteredScc) {
											shcCode.push(shcItemC)
											shcCodeVals.push(items?.scc)
										}

									}


								})

							})

						}
						else return

					})

					if (unidArr.length <= 1) {
						setshcValues(shcCode)

						//setAdditionalSccs([...additionalSccs, ...shcCode])
						let tempUnidSccList = handleUnidSccList()
						tempUnidSccList = [...tempUnidSccList, ...shcCodeVals]

						setUnidSccList(tempUnidSccList)

						let tempAppliedSccs = handleAppliedUnidSccs(tempUnidSccList)

						setAppliedSccs([...tempAppliedSccs, ...shcCode])

						removeDuplicates(shcCode)
					}
					setUnidSccs(shcCodeVals)

					setMultiunidCheck(unidArr)
					// setUnidSelectedValidation('')

					setUnid(isCustomized ? e.value.unid : e.value.unid);
					if (e?.value?.packingGroup) {
						setPackingGroup(e?.value?.packingGroup)
					}
					const splitedClass = shippingItem?.description?.split(" ");

					const data = {
						"id": e?.value?.id,
						"unid": e?.value?.unid,
						"shippingName": e?.value?.shippingName,
						"packingGroup": e?.value?.packingGroup,
						"classDivision": splitedClass[1],
						"origin": selectedOrigin?.cityCode,
						"destination": selectedDestination?.cityCode,
						"originCountry": selectedOrigin?.countryCode,
						"destinationCountry": selectedDestination?.countryCode,
					}

					setId(e.value.id)

					validateunid(data)
						.then((res) => {
							setUnidSelectedValidation(res)

						})
						.catch((err) => {
							setNotificationMessage &&
								setNotificationMessage(
									'Additional Sccs Error :' + err.message ? err.message : ''
								)
							setNotificationType &&
								setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
						})

					//handleSelect(e.value)
					// handleAddAdditinalScc({
					// 	unid: e.value.unid,
					// 	// sccDescription: e.value.sccDescription,
					// })
				}}
				onBlur={(e) => {
					setFieldLevelError(e)
					isFocused.current = false

					if (e.relatedTarget !== null) {
						handleOnBlur()
					}
					if (!tabUnid == '') {
						clearError(FORM_FIELDS.UNID)
						deleteBorder()
						//setUnidWarning(null)

						//if (tabUnid && !tabUnid == '') {
						const unidArr = [];
						const shcCode = [];
						let shcCodeVals = [];

						sccs.filter((item, index) => {
							const itemValue = item


							if (item.unid === tabUnid) {
								setUnidName(item.shippingName)
								setId(item.id)
								if (item?.packingGroup === '') {

									unidArr.push(
										{
											"label": `${item?.shippingName}`,
											"value": `${item?.shippingName} +${item?.id}`,
											'id': item?.id
										}

									);

								}

								else {
									setUnidName(item.shippingName)
									setId(item.id)
									unidArr.push(
										{
											"label": `${item?.shippingName} (PG ${item?.packingGroup})`,
											"value": `${item?.shippingName} (PG ${item?.packingGroup})  + ${item?.id}`,
											'id': item?.id
										}
									);
								}

								sccsD.find((items, index) => {

									const splitedArray = itemValue?.shcCodes.split(',');
									splitedArray.map(async (splitedItem, index) => {
										if (items?.scc === splitedItem) {

											const shcItemC = {
												"scc": items?.scc,
												"sccDescription": items?.sccDescription
											}


											let filteredScc = additionalSccs.some(e => e?.scc === splitedItem)

											if (!filteredScc) {
												shcCode.push(shcItemC)
												shcCodeVals.push(items?.scc)
											}

										}


									})

								})

							}
							else return

						})

						if (unidArr.length <= 1) {
							setshcValues(shcCode)

							//setAdditionalSccs([...additionalSccs, ...shcCode])
							let tempUnidSccList = handleUnidSccList()
							tempUnidSccList = [...tempUnidSccList, ...shcCodeVals]

							setUnidSccList(tempUnidSccList)

							let tempAppliedSccs = handleAppliedUnidSccs(tempUnidSccList)

							setAppliedSccs([...tempAppliedSccs, ...shcCode])

							removeDuplicates(shcCode)
						}
						setUnidSccs(shcCodeVals)
						setMultiunidCheck(unidArr)
						// setUnidSelectedValidation('')

						//setUnid(isCustomized ? e.value.unid : e.value.unid)
						const splitedClass = shippingItem?.description?.split(" ");

						const data = {
							"id": idBlur,
							"unid": tabUnid,
							"shippingName": shippingNameBlur,
							"packingGroup": packingGroupBlur,
							"classDivision": splitedClass[1],
							"origin": selectedOrigin?.cityCode,
							"destination": selectedDestination?.cityCode,
							"originCountry": selectedOrigin?.countryCode,
							"destinationCountry": selectedDestination?.countryCode,
						}

						validateunid(data)
							.then((res) => {
								setUnidSelectedValidation(res)

							})
							.catch((err) => {
								setNotificationMessage &&
									setNotificationMessage(
										'Additional Sccs Error :' + err.message ? err.message : ''
									)
								setNotificationType &&
									setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
							})

					}
				}
				}
				className={'form-control autocomplete search-panel__scc-autocomplete'}
				itemTemplate={itemTemplate}
			/>
			<span className='search-panel__errormsg' key={index}>{ 
                                                error &&
                                                error[FORM_FIELDS.UNID] &&
                                                error[FORM_FIELDS.UNID][0] &&
                                               error[FORM_FIELDS.UNID][0][UNID_FORM_FIELDS.UNID]
                                               ? t(error[FORM_FIELDS.UNID][0][UNID_FORM_FIELDS.UNID], { ns: 'errors' })
                                               : null
                                            
                                                   }
                                         </span> 
		</>
	)
}

export default UnidAutocomplete
