import React from 'react'
import { connect } from 'react-redux'
import { setAccessToken, setRefreshToken } from '../../redux/actions/authAction'
import PageHeading from './PageHeading'

const PageHeadingContainer = ({ 
		principal,
		accessToken,
		setRefreshTokenToState,
		setAccessTokenToState,
		isLoading 
	}) => (
		<PageHeading 
			principal={principal}
			accessToken={accessToken}
			setAccessToken={setAccessTokenToState}
			setRefreshToken={setRefreshTokenToState}
			isLoading={isLoading}	
		/>
	)

const mapStateToProps = ({ auth, loader }) => ({
	principal: auth.principal,
	accessToken: auth.accessToken,
	isLoading: loader.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	setRefreshTokenToState: (refreshToken) => {
		dispatch(setRefreshToken(refreshToken))
	},
	setAccessTokenToState: (accessToken) => {
		dispatch(setAccessToken(accessToken))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(PageHeadingContainer)
