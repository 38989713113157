import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'


export const generateAwbReports = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.generateAwbReport}`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}
export const generateDapReport = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.generateDapReport}`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}
