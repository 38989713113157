import React from 'react';
import './loading-animation.scss';

const LoadingAnimation = () => {
    return (
        <div class="col-3">
            <div class="snippet" data-title=".dot-flashing">
                <div class="stage">
                    <div class="dot-flashing"></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingAnimation;