import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'primereact/tooltip'
import { getScc } from '../../services/searchPanelServices'

const SccItem = ({ scc }) => {
	const [sccObj, setSccObj] = useState(scc)

	useEffect(() => {
		; (async (scc) => {
			let sccObj

			if (scc.sccDescription) {
				sccObj = scc
				setSccObj(sccObj)
			} else {
				const sccObj = await getScc(scc.scc)
				if (sccObj) {
					setSccObj(sccObj)
				} else {
					setSccObj(scc)
				}
			}
		})(scc)
	}, [scc])

	return (
		<>
			{sccObj ? (
				<>
					<div className={`scccode non-rem-scc-${sccObj.scc}`}>
						{sccObj.scc}
					</div>
					<Tooltip target={`.non-rem-scc-${sccObj.scc}`}>
						{sccObj.sccDescription}
					</Tooltip>
				</>
			) : (
				<></>
			)}
		</>
	)
}

const SccListDisplay = ({
	nonRemovableSccs,
	additionalSccs,
	appliedServices,
	handleRemoveAdditionalScc,
	cargoCheck,
	setCargoCheck,
	autoAdd,
	//	services,
}) => {
	const [sccList, setSccList] = useState([])
	 useEffect(() => {
		if (nonRemovableSccs) {
			var setObj = new Set()
			var result = nonRemovableSccs.reduce((acc, item) => {
				if (!setObj.has(item.scc)) {
					setObj.add(item.scc, item)
					acc.push(item)
				}
				return acc
			}, [])
			setSccList(result)
		}
	}, [])
	useEffect(() => {
		if (nonRemovableSccs) {
			var setObj = new Set()
			var result = nonRemovableSccs.reduce((acc, item) => {
				if (!setObj.has(item.scc)) {
					setObj.add(item.scc, item)
					acc.push(item)
				}
				return acc
			}, [])
			setSccList(result)
		}
	}, [nonRemovableSccs])
	return (
		<div className='form-group col-12 scc-wrapper'>
			{sccList && sccList.length ? (
				sccList.map((scc, index) => (
					<span key={`apld-${index}`}>
						<SccItem scc={scc} />
					</span>
				))
			) : (
				<></>
			)}
			{additionalSccs && additionalSccs.length ? (
				additionalSccs?.map((scc, index) => (

					<span key={`adtnl-${index}`}>
						<div className={`scccode adtnl-scc-${index}`} key={index}>
							{scc?.scc}
						</div>
						<Tooltip target={`.adtnl-scc-${index}`}>
							{scc?.sccDescription}
						</Tooltip>
						<FontAwesomeIcon
							icon={faTimes}
							onClick={(e) => handleRemoveAdditionalScc(scc)}
							className='close-button'
						/>
					</span>
				))
			) : (
				<></>
			)}
			{appliedServices && appliedServices.length ? (
				appliedServices.map((scc, index) => {
					return (
						<span key={`adtnl-${index}`}>
							<div className={`scccode service-scc-${index}`} key={index}>
								{scc.scc}
							</div>
							<Tooltip target={`.service-scc-${index}`}>
								{scc.sccDescription}
							</Tooltip>
							{!autoAdd ?
								<FontAwesomeIcon
									icon={faTimes}
									onClick={(e) => handleRemoveAdditionalScc(scc)}
									className='close-button'
								/> :
								<></>
							}
						</span>
					)
				})
			) : (
				<></>
			)}
		</div>
	)
}

export default SccListDisplay
