import envConfigs from '../configs/envConfigs'
import { portalHttpClient, portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const getUserDetails = async (userId) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getUserDetailsUrl}?userCode=${userId}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getMenuResponse = async (language) => {
	const url = (envConfigs.env !== 'local') ? envConfigs['getMenuResponseUrl'+language] : `http://localhost:3001/menuresponse-${language}`
	const response = await portalHttpClient.get(url)
	return response.data
}


export const getMenuResponseFromAPI = async (language) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getNavigationMenuUrl}?lang=${language}`
	const response = await portalHttpClient.get(url)
	return response.data
}

export const getPLACIUserDetails = async (userId) => {
    //const url = `http://localhost:9002/csnservice/dashboard/userDetails/${userId}`
    const url = `${envConfigs.csnUrl}${envConfigs.placiUserDetails}/${userId}`

    const response = await portalAuthHttpClient.get(url)
    return response;
}