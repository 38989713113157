export default {
	FLIGHT_ENQUIRY: 'flight_enquiry',
	USER_PROFILE: 'user_profile',
	USER_CREATION: 'create_user',
	USER_UPDATE: 'edit_user',
	LIST_USER: 'list_users',
	NEW_BOOKING: 'new_booking',
	CHOOSE_FLIGHT: 'choose_flight',
	BOOKING_SUMMARY: 'booking_summary',
	BOOKING_CONFIRMATION: 'booking_confirmation',
	AWB_DETAILS: 'capture_awb',
	HAWB_DETAILS: 'capture_hawb',
	LIST_BOOKING: 'list_bookings',
	VIEW_BOOKING: 'view_bookings',
	MODIFY_BOOKING: 'modify_booking',
	DASHOARD: 'customer_dashboard',
	TEMPLATES: 'booking_templates',
	ADMIN_MASTER: 'admin_master',
	TERMS_CONDITIONS: 'terms_conditions',
	MULTIPLE_BOOKING: 'multiple_booking',
}
