import { SelectButton } from 'primereact/selectbutton'
import React, { useEffect, useState } from 'react'
import {
	PHARMA_ACTIVE_TYPE,
	PHARMA_HYBRID_TYPE,
	PHARMA_SHIPPING_TYPE,
	PHARMA_TEMP_RANGE,
	PHARMA_VACCINE_FIELDS,
	SCC_CODES,
} from '../../constants'

import { useTranslation } from 'react-i18next'
import './additional-form.scss'
import MESSAGES from '../../constants/Messages'

const AdditionalInfoForm = ({
	additionalInfoData,
	setAdditionalInfoData,
	shippingItem,
	appliedSccs,
	setAppliedSccs,
	error,
	setError,
	setDisplayContainerPopup,
	additionalSccs,
	sccCombinationError,
	setSccCombinationError,
	handleAppliedAddScc,
	setAdditionalSccs,
	sccCompatibility,
	setSccCompatibility,
	setHandlingInformation,
}) => {

	const { t, i18n } = useTranslation(['labels','errors'])

	const [tempratureRanges, setTempratureRanges] = useState([
		{ label: '2-8 C', value: PHARMA_TEMP_RANGE.FIRST },
		{ label: '15-25 C', value: PHARMA_TEMP_RANGE.SECOND },
		{ label: '2-25 C', value: PHARMA_TEMP_RANGE.THIRD },
		{ label: t('BOOKING.FRO'), value: PHARMA_TEMP_RANGE.FROZEN },
	])
	const shippingTypes = [
		{ label: t('BOOKING.CONTAINER_HY'), value: PHARMA_SHIPPING_TYPE.HYBRID },
		{ label: t('COMMON.ACTIVE'), value: PHARMA_SHIPPING_TYPE.ACTIVE },
		{ label: t('BOOKING.CONTAINER_PA'), value: PHARMA_SHIPPING_TYPE.PASSIVE },
	]
	const activeTypes = [
		{
			label: t('BOOKING.OWN_CONTAINER_AC'),
			value: PHARMA_ACTIVE_TYPE.OWN_ACTIVE,
		},
		{
			label: t('BOOKING.EY_CONTAINER_AC'),
			value: PHARMA_ACTIVE_TYPE.EY_ACTIVE,
		},
	]

	const hybridTypes = [
		{
			label: t('BOOKING.OWN_CONTAINER_HY'),
			value: PHARMA_HYBRID_TYPE.OWN_HYBRID,
		},
		{
			label: t('BOOKING.EY_CONTAINER_HY'),
			value: PHARMA_HYBRID_TYPE.EY_HYBRID,
		},
	]

	const options = [
		{ label: t('COMMON.YES'), value: 'YES' },
		{ label: t('COMMON.NO'), value: 'NO' },
	]
	const [showTempRange, setShowTempRange] = useState(true)

	useEffect(() => {
		if (additionalInfoData.pharma.tempratureRange) {
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.ICE)) {
				setAdditionalInfoData({
					...additionalInfoData,
					pharma: { ...additionalInfoData.pharma, isDryIceAdded: 'YES' },
				})
			}
		}
		if (
			additionalInfoData.pharma.tempratureRange &&
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.FROZEN
		) {
			handleAppliedAddScc(SCC_CODES.FRO)
		} else if (
			additionalInfoData.pharma.tempratureRange &&
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.FIRST
		) {
			handleAppliedAddScc(SCC_CODES.TMP1)
		} else if (
			additionalInfoData.pharma.tempratureRange &&
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.SECOND
		) {
			handleAppliedAddScc(SCC_CODES.TMP2)
		} else if (
			additionalInfoData.pharma.tempratureRange &&
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.THIRD
		) {
			handleAppliedAddScc(SCC_CODES.TMP3)
		}
	}, [additionalInfoData.pharma.tempratureRange])
	useEffect(() => {
		if (
			additionalInfoData.pharma.isDryIceAdded &&
			additionalInfoData.pharma.isDryIceAdded === 'YES'
		) {
			handleAppliedAddScc(SCC_CODES.ICE)
		} else {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.ICE)
			setAppliedSccs(arr)
		}
	}, [additionalInfoData.pharma.isDryIceAdded])
	useEffect(() => {
		if (
			additionalInfoData.pharma.activeContainerType &&
			additionalInfoData.pharma.activeContainerType ===
				PHARMA_ACTIVE_TYPE.OWN_ACTIVE
		) {
			handleAppliedAddScc(SCC_CODES.ACT)
			setShowTempRange(false)
		} else {
			if (
				additionalInfoData.pharma.activeContainerType ===
				PHARMA_ACTIVE_TYPE.EY_ACTIVE
			) {
				setDisplayContainerPopup(true)
			}
		}
	}, [additionalInfoData.pharma.activeContainerType])
	useEffect(() => {
		if (
			additionalInfoData.pharma.hybridContainerType &&
			additionalInfoData.pharma.hybridContainerType ===
				PHARMA_HYBRID_TYPE.OWN_HYBRID
		) {
			handleAppliedAddScc(SCC_CODES.PIP)
		} else if (
			additionalInfoData.pharma.hybridContainerType !==
			PHARMA_HYBRID_TYPE.OWN_HYBRID
		) {
			if (
				additionalInfoData.pharma.hybridContainerType ===
				PHARMA_HYBRID_TYPE.EY_HYBRID
			) {
				setDisplayContainerPopup(true)
			}
			// setTempratureRange(null)
			// setDryIceAdded(null)
		}
	}, [additionalInfoData.pharma.hybridContainerType])
	useEffect(() => {
		if (
			additionalInfoData.pharma.shippingPharmaType ===
				PHARMA_SHIPPING_TYPE.HYBRID &&
			additionalInfoData.pharma.hybridContainerType ===
				PHARMA_HYBRID_TYPE.OWN_HYBRID
		) {
			handleAppliedAddScc(SCC_CODES.PIP)
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ===
				PHARMA_SHIPPING_TYPE.ACTIVE &&
			additionalInfoData.pharma.activeContainerType ===
				PHARMA_ACTIVE_TYPE.OWN_ACTIVE
		) {
			handleAppliedAddScc(SCC_CODES.ACT)
			setShowTempRange(false)
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.PASSIVE
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.FRO)
			setAppliedSccs(arr)
			if (
				additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.FROZEN
			) {
				setAdditionalInfoData({
					...additionalInfoData,
					pharma: { ...additionalInfoData.pharma, tempratureRange: null },
				})
			}
		}
		if (
			additionalInfoData.pharma.shippingPharmaType &&
			additionalInfoData.pharma.tempratureRange &&
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.FROZEN
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.FRO)
			setAppliedSccs(arr)
		}
	}, [additionalInfoData.pharma.shippingPharmaType])

	useEffect(() => {
		if (sccCompatibility === 'false') {
			if (
				additionalInfoData.pharma.activeContainerType &&
				!appliedSccs.map((item) => item.scc).includes(SCC_CODES.ACT)
			) {
				setAdditionalInfoData({
					...additionalInfoData,
					pharma: { ...additionalInfoData.pharma, activeContainerType: null },
				})
			} else if (
				additionalInfoData.pharma.hybridContainerType &&
				!appliedSccs.map((item) => item.scc).includes(SCC_CODES.PIP)
			) {
				setAdditionalInfoData({
					...additionalInfoData,
					pharma: { ...additionalInfoData.pharma, hybridContainerType: null },
				})
			} else if (
				additionalInfoData.pharma.tempratureRange &&
				appliedSccs.filter(
					(item) =>
						item.scc !== SCC_CODES.FRO ||
						item.scc !== SCC_CODES.TMP1 ||
						item.scc !== SCC_CODES.TMP2 ||
						item.scc !== SCC_CODES.TMP3
				)
			) {
				setAdditionalInfoData({
					...additionalInfoData,
					pharma: { ...additionalInfoData.pharma, tempratureRange: null },
				})
			} else if (additionalInfoData.pharma.isDryIceAdded) {
				setAdditionalInfoData({
					...additionalInfoData,
					pharma: { ...additionalInfoData.pharma, isDryIceAdded: null },
				})
			}
		}
		setSccCompatibility('')
	}, [sccCompatibility])

	useEffect(() => {
		let isFrozenFiltered = false
		isFrozenFiltered = !appliedSccs || !appliedSccs.length || 
						!appliedSccs.find(appliedScc => appliedScc.scc === 'PIP' || appliedScc.scc === 'ACT')
		let tempratureRanges = [
			{ label: '2-8 C', value: PHARMA_TEMP_RANGE.FIRST },
			{ label: '15-25 C', value: PHARMA_TEMP_RANGE.SECOND },
			{ label: '2-25 C', value: PHARMA_TEMP_RANGE.THIRD },
			{ label: 'Frozen', value: PHARMA_TEMP_RANGE.FROZEN },
		]
		if (isFrozenFiltered) {
			tempratureRanges = tempratureRanges.filter(range => range.value !== PHARMA_TEMP_RANGE.FROZEN)
		}
		if (shippingItem && shippingItem.product === 'FreshForward' && additionalInfoData
			&& additionalInfoData?.pharma.shippingPharmaType === PHARMA_SHIPPING_TYPE.PASSIVE) {
			tempratureRanges.push({ label: 'N/A', value: PHARMA_TEMP_RANGE.NA })
		}
		setTempratureRanges(tempratureRanges)
	}, [appliedSccs])

	const checkActivePharmaError = (value) => {
		let validCombination = true
		if (shippingItem.code === 'APHARMA') {
			if (
				value &&
				(value === PHARMA_SHIPPING_TYPE.HYBRID ||
					value === PHARMA_SHIPPING_TYPE.PASSIVE)
			) {
				validCombination = false
			}
		}
		return validCombination
	}
	const showSccError = (sccArray) => {
		let message = ''
		if (sccArray.length) {
			message = t(MESSAGES.CNT_BASED_SCC_REMOVAL, {
				SCC: sccArray.toString(),
				ns: 'errors',
			})
		}
		setSccCombinationError(message)
		setTimeout(function () {
			setSccCombinationError('')
		}, 5000)
	}
	const showDryIceError = () => {
		setSccCombinationError('Dry ice is already added.')
		setTimeout(function () {
			setSccCombinationError('')
		}, 5000)
	}
	const showActivePharmaError = () => {
		setSccCombinationError(MESSAGES.OPTN_NOT_CMPTBL)
		setTimeout(function () {
			setSccCombinationError('')
		}, 3000)
	}
	const handleTypeChange = () => {
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.HYBRID
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.PIP)
			setAppliedSccs(arr)
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.ACTIVE
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.ACT)
			setAppliedSccs(arr)
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.PASSIVE
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.FRO)
			setAppliedSccs(arr)
		}
	}
	const handleActiveContainerChange = () => {
		if (
			additionalInfoData.pharma.activeContainerType ===
			PHARMA_ACTIVE_TYPE.OWN_ACTIVE
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.ACT)
			setAppliedSccs(arr)
		}
	}
	const handleHybridContainerChange = () => {
		if (
			additionalInfoData.pharma.hybridContainerType ===
			PHARMA_HYBRID_TYPE.OWN_HYBRID
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.PIP)
			setAppliedSccs(arr)
		}
	}
	const handleTempChange = () => {
		if (
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.FROZEN
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.FRO)
			setAppliedSccs(arr)
		} else if (
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.FIRST
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.TMP1)
			setAppliedSccs(arr)
		} else if (
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.SECOND
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.TMP2)
			setAppliedSccs(arr)
		} else if (
			additionalInfoData.pharma.tempratureRange === PHARMA_TEMP_RANGE.THIRD
		) {
			let arr = appliedSccs.filter((e) => e.scc !== SCC_CODES.TMP3)
			setAppliedSccs(arr)
		}
	}
	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}

	// const setTempArray = (value) => {
	// 	let tempArray = [...tempratureRanges] // make a separate copy of the array
	// 	let index = tempArray.findIndex((x) => x.value === PHARMA_TEMP_RANGE.FROZEN)
	// 	if (value === PHARMA_SHIPPING_TYPE.PASSIVE) {
	// 		if (index !== -1) {
	// 			tempArray.splice(index, 1)
	// 			setTempratureRanges(tempArray)
	// 		}
	// 	} else {
	// 		if (!index || index < 0) {
	// 			let frozen = { label: 'Frozen', value: PHARMA_TEMP_RANGE.FROZEN }
	// 			tempArray.push(frozen)
	// 			setTempratureRanges(tempArray)
	// 		}
	// 	}
	// }



	const checkShippingTypeCombinationScc = (value) => {
		let validCombination = true
		let invalidSCCArray = []
		if (value === PHARMA_SHIPPING_TYPE.PASSIVE) {
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.PIP)) {
				validCombination = false
				invalidSCCArray.push(SCC_CODES.PIP)
			}
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.ACT)) {
				validCombination = false
				invalidSCCArray.push(SCC_CODES.ACT)
			}
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.FRO)) {
				validCombination = false
				invalidSCCArray.push(SCC_CODES.FRO)
			}
		}
		if (value === PHARMA_SHIPPING_TYPE.ACTIVE) {
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.PIP)) {
				invalidSCCArray.push(SCC_CODES.PIP)
				validCombination = false
			}
		}

		if (value === PHARMA_SHIPPING_TYPE.HYBRID) {
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.ACT)) {
				invalidSCCArray.push(SCC_CODES.ACT)
				validCombination = false
			}
		}
		if (!validCombination) {
			showSccError(invalidSCCArray)
			let additionalSccTempArray = [...additionalSccs]
			invalidSCCArray.forEach(function (element) {
				if (additionalSccTempArray.map((item) => item.scc).includes(element)) {
					additionalSccTempArray = additionalSccTempArray.filter(
						(item) => item.scc !== element
					)
				}
			})
			setAdditionalSccs([...additionalSccTempArray])
		}
	}
	const checkDryIceCombinationScc = (value) => {
		let validCombination = true
		if (value === 'NO') {
			if (additionalSccs.map((item) => item.scc).includes(SCC_CODES.ICE)) {
				validCombination = false
			}
		}
		return validCombination
	}
	const checkTemperatureCombinationScc = (value) => {
		let validCombination = true
		let scc = ''
		let invalidSCCArray = []
		if (value === PHARMA_TEMP_RANGE.FROZEN) {
			scc = SCC_CODES.FRO
		} else if (value === PHARMA_TEMP_RANGE.FIRST) {
			scc = SCC_CODES.TMP1
		} else if (value === PHARMA_TEMP_RANGE.SECOND) {
			scc = SCC_CODES.TMP2
		} else if (value === PHARMA_TEMP_RANGE.THIRD) {
			scc = SCC_CODES.TMP3
		}
		if (
			additionalInfoData.pharma.hybridContainerType ===
				PHARMA_HYBRID_TYPE.OWN_HYBRID ||
			additionalInfoData.pharma.activeContainerType ===
				PHARMA_ACTIVE_TYPE.OWN_ACTIVE
		) {
			let temperatureArray = [
				SCC_CODES.TMP1,
				SCC_CODES.TMP2,
				SCC_CODES.TMP3,
				SCC_CODES.FRO,
			]
			temperatureArray.forEach(function (element) {
				if (element !== scc) {
					if (additionalSccs.map((item) => item.scc).includes(element)) {
						invalidSCCArray.push(element)
						validCombination = false
					}
				}
			})
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ===
			PHARMA_SHIPPING_TYPE.PASSIVE
		) {
			let temperatureArray = [SCC_CODES.TMP1, SCC_CODES.TMP2, SCC_CODES.TMP3]
			temperatureArray.forEach(function (element) {
				if (element !== scc) {
					if (additionalSccs.map((item) => item.scc).includes(element)) {
						invalidSCCArray.push(element)
						validCombination = false
					}
				}
			})
		}
		if (!validCombination) {
			showSccError(invalidSCCArray)
			let additionalSccTempArray = [...additionalSccs]
			invalidSCCArray.forEach(function (element) {
				if (additionalSccTempArray.map((item) => item.scc).includes(element)) {
					additionalSccTempArray = additionalSccTempArray.filter(
						(item) => item.scc !== element
					)
				}
			})
			setAdditionalSccs([...additionalSccTempArray])
		}
	}
	return (
		<>
			{additionalInfoData && additionalInfoData.type === 'pharma' ? (
				<div className='form-row additional-form mb-0'>
					<div class='form-group col-12 mb-0'>
						<div class='item-group-additional row'>
							<div className='form-group col-12 col-md-auto additional-form__question p-l-0'>
								<label>{t(MESSAGES.CHOOSE_CATEGORY, { ns: 'errors' })}</label>
								<div className='form-element'>
									<SelectButton
										className='select-button'
										value={additionalInfoData.pharma.shippingPharmaType}
										options={shippingTypes}
										onChange={(e) => {
											setHandlingInformation(null)
											if (e.value) {
												if (!checkActivePharmaError(e.value)) {
													showActivePharmaError()
													return
												}
												checkShippingTypeCombinationScc(e.value)
												handleTypeChange()
												//setTempArray(e.value)
												setAdditionalInfoData({
													...additionalInfoData,
													pharma: {
														...additionalInfoData.pharma,
														shippingPharmaType: e.value,
													},
												})
												clearError(PHARMA_VACCINE_FIELDS.SHIPPING_TYPE)
											} else {
												handleTypeChange()
												setAdditionalInfoData({
													...additionalInfoData,
													pharma: {
														...additionalInfoData.pharma,
														shippingPharmaType: null,
													},
												})
											}
										}}
									></SelectButton>
									<span className='search-panel__errormsg'>
										{error && error[PHARMA_VACCINE_FIELDS.SHIPPING_TYPE]
											? t(error[PHARMA_VACCINE_FIELDS.SHIPPING_TYPE], {
													ns: 'errors',
											  })
											: ''}
									</span>
								</div>
							</div>
							{additionalInfoData.pharma.shippingPharmaType &&
							additionalInfoData.pharma.shippingPharmaType ===
								PHARMA_SHIPPING_TYPE.ACTIVE ? (
								<div className='form-group col-12 col-md-auto additional-form__question container_type p-l-0'>
									<label>
										{t(MESSAGES.CHOOSE_CONT_TYPE, { ns: 'errors' })}
									</label>
									<div className='form-element'>
										<SelectButton
											className='select-button'
											value={additionalInfoData.pharma.activeContainerType}
											options={activeTypes}
											onChange={(e) => {
												if (e.value) {
													handleActiveContainerChange()
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															activeContainerType: e.value,
														},
													})
													clearError(
														PHARMA_VACCINE_FIELDS.ACTIVE_CONTAINER_TYPE
													)
												} else {
													handleActiveContainerChange()
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															activeContainerType: null,
														},
													})
												}
											}}
										></SelectButton>
										<span className='search-panel__errormsg'>
											{error &&
											error[PHARMA_VACCINE_FIELDS.ACTIVE_CONTAINER_TYPE]
												? t(
														error[PHARMA_VACCINE_FIELDS.ACTIVE_CONTAINER_TYPE],
														{ ns: 'errors' }
												  )
												: ''}
										</span>
									</div>
								</div>
							) : (
								<></>
							)}
							{additionalInfoData.pharma.shippingPharmaType &&
							additionalInfoData.pharma.shippingPharmaType ===
								PHARMA_SHIPPING_TYPE.HYBRID ? (
								<div className='form-group col-12 col-md-auto additional-form__question container_type p-l-0'>
									<label>
										{t(MESSAGES.CHOOSE_CONT_TYPE, { ns: 'errors' })}
									</label>
									<div className='form-element'>
										<SelectButton
											className='select-button'
											value={additionalInfoData.pharma.hybridContainerType}
											options={hybridTypes}
											onChange={(e) => {
												if (e.value) {
													handleHybridContainerChange()
													setHandlingInformation(null)
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															hybridContainerType: e.value,
														},
													})
													clearError(
														PHARMA_VACCINE_FIELDS.HYBRID_CONTAINER_TYPE
													)
												} else {
													handleHybridContainerChange()
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															hybridContainerType: null,
														},
													})
												}
											}}
										></SelectButton>
										<span className='search-panel__errormsg'>
											{error &&
											error[PHARMA_VACCINE_FIELDS.HYBRID_CONTAINER_TYPE]
												? t(
														error[PHARMA_VACCINE_FIELDS.HYBRID_CONTAINER_TYPE],
														{ ns: 'errors' }
												  )
												: ''}
										</span>
									</div>
								</div>
							) : (
								<></>
							)}
							{(additionalInfoData.pharma.shippingPharmaType &&
								additionalInfoData.pharma.shippingPharmaType ===
									PHARMA_SHIPPING_TYPE.PASSIVE) ||
							((additionalInfoData.pharma.hybridContainerType ||
								additionalInfoData.pharma.tempratureRange) &&
								additionalInfoData.pharma.shippingPharmaType ===
									PHARMA_SHIPPING_TYPE.HYBRID) ? (
								// 		||
								// ((additionalInfoData.pharma.activeContainerType ||
								// 	additionalInfoData.pharma.tempratureRange) &&
								// 	additionalInfoData.pharma.shippingPharmaType ==
								// 		PHARMA_SHIPPING_TYPE.ACTIVE)
								<div className='form-group col-12 col-md-auto additional-form__question p-l-0'>
									<label>
										{t(MESSAGES.CHOOSE_TEMP_RANGE, { ns: 'errors' })}
									</label>
									<div className='form-element temp_range'>
										<SelectButton
											className='select-button'
											value={additionalInfoData.pharma.tempratureRange}
											options={tempratureRanges}
											onChange={(e) => {
												if (e.value) {
													checkTemperatureCombinationScc(e.value)
													handleTempChange()
													if (e.value === PHARMA_TEMP_RANGE.NA) {
														setHandlingInformation(PHARMA_TEMP_RANGE.NA)
													} else {
														setHandlingInformation(null)
													}
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															tempratureRange: e.value,
														},
													})

													clearError(PHARMA_VACCINE_FIELDS.TEMP_RANGE)
												} else {
													handleTempChange()
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															tempratureRange: null,
														},
													})
												}
											}}
										></SelectButton>
										<span className='search-panel__errormsg'>
											{error && error[PHARMA_VACCINE_FIELDS.TEMP_RANGE]
												? t(error[PHARMA_VACCINE_FIELDS.TEMP_RANGE], {
														ns: 'errors',
												  })
												: ''}
										</span>
									</div>
								</div>
							) : (
								<></>
							)}
							{(additionalInfoData.pharma.shippingPharmaType ===
								PHARMA_SHIPPING_TYPE.PASSIVE &&
								(additionalInfoData.pharma.tempratureRange ||
									additionalInfoData.pharma.isDryIceAdded )) ||
							(additionalInfoData.pharma.shippingPharmaType ===
								PHARMA_SHIPPING_TYPE.HYBRID &&
								(additionalInfoData.pharma.tempratureRange ||
									additionalInfoData.pharma.isDryIceAdded)) ||
							(additionalInfoData.pharma.shippingPharmaType ===
								PHARMA_SHIPPING_TYPE.ACTIVE &&
								additionalInfoData.pharma.activeContainerType) ? (
								<div className='form-group col-12 col-md-auto additional-form__question p-l-0'>
									<label>{t(MESSAGES.IS_DRY_ICE, { ns: 'errors' })}</label>
									<div className='form-element'>
										<SelectButton
											className='select-button'
											value={additionalInfoData.pharma.isDryIceAdded}
											options={options}
											onChange={(e) => {
												if (e.value) {
													if (!checkDryIceCombinationScc(e.value)) {
														showDryIceError()
													} else {
														setAdditionalInfoData({
															...additionalInfoData,
															pharma: {
																...additionalInfoData.pharma,
																isDryIceAdded: e.value,
															},
														})
														clearError(PHARMA_VACCINE_FIELDS.DRY_ICE)
													}
												} else {
													setAdditionalInfoData({
														...additionalInfoData,
														pharma: {
															...additionalInfoData.pharma,
															isDryIceAdded: null,
														},
													})
												}
											}}
										></SelectButton>
										<span className='search-panel__errormsg'>
											{error && error[PHARMA_VACCINE_FIELDS.DRY_ICE]
												? t(error[PHARMA_VACCINE_FIELDS.DRY_ICE], {
														ns: 'errors',
												  })
												: ''}
										</span>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)
}
export default AdditionalInfoForm
