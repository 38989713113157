import React from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
const ULDInfoDisplay = ({ uldColumns, uldInfoDetails }) => {
	//for multilingual
	const [t] = useTranslation(['labels'])
	return (
		<div className='uldInfo-table-wrapper'>
			<div className='table-container '>
				<table className='table'>
					<thead>
						<tr>
							{uldColumns && uldColumns.length > 0 ? (
								uldColumns.map((column, columnnIndex) => (
									<th
										id='header-font'
										className={`table__head-th uld-head-width-${columnnIndex}`}
									>
										<span>{t(column)}</span>
									</th>
								))
							) : (
								<></>
							)}
						</tr>
					</thead>
					<tbody className='weight-font'>
						{uldInfoDetails &&
							uldInfoDetails.length !== 0 &&
							uldInfoDetails.map((uldInfo, uldInfoIndex) => (
								<tr key={uldInfoIndex}>
									<td className='table__body-td uld-body-width-uldType'>
										{uldInfo.uldType}
									</td>
									<td className='table__body-td uld-body-width-number'>
										{uldInfo.numberOfUlds}
									</td>
									<td className='table__body-td'>
										{uldInfo.numberOfUlds * uldInfo.uldWeight}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default ULDInfoDisplay
