import { LIST_TEMPLATE } from '../constants'

const initState = {
	request: null,
	response: null,
	error: null,
}

export default function (state = initState, action) {
	switch (action.type) {
		case LIST_TEMPLATE.LOAD:
			return {
				...state,
				request: action.templateRequest,
				response: null,
				error: null,
			}
		case LIST_TEMPLATE.SUCCESS:
			return {
				...state,
				response: action.response,
			}
		case LIST_TEMPLATE.ERROR:
			return {
				...state,
				error: action.error,
			}
		default:
			return state
	}
}
