import { WEIGHT_UNIT } from '../constants'
import MESSAGES from '../constants/Messages'
/**
 * @param {* length of uuid} n
 * @returns randon n digit uuid
 */
export const getNumberUUID = (n) => {
	const length = Number(n) || 4
	return Math.floor(
		Math.pow(10, Number(length) - 2) +
			Math.random() * Math.pow(90, Number(length) - 2)
	)
}
/**
 *
 * @param {* numeric weight in input unit} weight
 * @param {* enum WEIGHT_UNIT unit to convert from} weightUnit
 * @returns
 */
export const calculateWeightToKg = (weight, weightUnit) => {
	if (weight) {
		if (weightUnit == WEIGHT_UNIT.TON) {
			return (weight * 907.185).toFixed(1)
		} else if (weightUnit == WEIGHT_UNIT.POUND) {
			return (weight * 0.453592).toFixed(1)
		} else {
			return weight.toString()
		}
	}
	return Number(weight)
}
/**
 *
 * @param {* numeric weight in Kg} weight
 * @param {* enum WEIGHT_UNIT unit to convert to} weightUnit
 * @returns
 */
export const calculateWeightFromKg = (weight, weightUnit) => {
	if (weight) {
		if (weightUnit == WEIGHT_UNIT.TON) {
			return (weight / 907.185).toFixed(1)
		} else if (weightUnit == WEIGHT_UNIT.POUND) {
			return (weight / 0.453592).toFixed(1)
		} else {
			return weight.toString()
		}
	}
	return Number(weight)
}

/**
 *
 * @param {* value to convert first letter to upper and remaining to lowercase}
 * @returns
 */
export const convertToLowerCase = (value) => {
	let nameConverted
	if (value) {
		nameConverted = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
	}
	return nameConverted
}

/**
 *
 * @param {* value to convert every first letter to upper and remaining to lowercase}
 * @returns
 */
export const capitalizeName = (value) => {
	const caps = value.split(' ').map(capitalize).join(' ')
	return caps
}

export const round = (val, places) => {
	return (
		Math.floor(Number(val) * Math.pow(10, Number(places))) /
		Math.pow(10, Number(places))
	)
}

const capitalize = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const sort = (list) => {
	return list.sort((a, b) => (a > b ? 1 : -1))
}

export const allowNumberOnly = (evnt) => {
	let isNumber
	evnt = evnt ? evnt : window.event
	let charCode = evnt.which ? evnt.which : evnt.keyCode
	if (charCode) {
		isNumber =
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			charCode != 190 &&
			charCode != 110 &&
			(charCode > 105 || charCode < 96)
	}
	return isNumber && evnt.preventDefault()
}

export const notZero = (val) => /^[1-9][0-9]*$/.test(val)

export const getLanguageCode = (language) => {
	if (language === 'zh_CN') {
		return 'zh'
	} else {
		return 'en'
	}
}
export const removeCommaFromNumber = (value) => {
	if (value) {
		return value.replace(/,/g, '')
	}
}

export const getErrorMessage = (errorDetails) => {
	const errorCode = errorDetails[0].errorCode
	const description = errorDetails[0].errorDescription
	switch (errorCode) {
		case 'capacity.booking.selectFlight.NoMatchingRecords':
			return MESSAGES.NO_FLIGHTS
		case 'ER044':
			return MESSAGES.REST_NO_CAPACITY
		case 'ER045':
			return MESSAGES.REST_EMBRAGO
		case 'ER046':
			return MESSAGES.REST_OTHER
		case 'ER047':
			return MESSAGES.REST_LOADABILITY
		case 'ER233':
			return MESSAGES.REST_MULTIPLE
		default:
			return description
	}
}
