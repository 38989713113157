import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SchedulerMultiFlightsContainer from './schedulerMultiFlightsContainer'
 import SearchPanel from '../SearchPanel'
 import SeasonalButtonGroup from './SeasonalButtonGroup';
 import {
    getInitSeasonalFlightDetails,
} from '../../constants';



const SeasonalBooking = ({
    isListPageOpen,
    setListPageOpen,
    clearToken,
    isLoading,
    setLoading,
    setInitialList,
    navigateBack,
}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    //addFlights states
    const [seasonalError, setSeasonalError] = useState(null)
    const [flightList, setFlightList] = useState([getInitSeasonalFlightDetails()])
    const [flightSelectedOrigin, setFlightSelectedOrigin] = useState(null)
    const [flightsMaxShippingDate, setFlightsMaxShippingDate] = useState(endDate != '' ? moment(endDate, 'DD-MMM-YYYY').toDate() : moment().add(6, 'months').toDate())
    const [flightsMinDate, setFlightsMinDate] = useState(moment().toDate())

    //scheduler states
    const [schedulerMaxShippingDate, setSchedulerMaxShippingDate] = useState(moment().add(6, 'months').toDate())
    const [schedulerMinDate, setSchedulerMinDate] = useState(moment().toDate())
    const [weekdays, setWeekdays] = useState([])
    const [dateScheduler, setDateScheduler] = useState('daily')

    return (
            <div className='get-quote seasonal-booking'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <SearchPanel 
                            isSeasonalBooking={true}
                            seasonalError={seasonalError}
                            setSeasonalError={setSeasonalError}

                            //flights
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            flightList={flightList}
                            setFlightList={setFlightList}
                            flightSelectedOrigin={flightSelectedOrigin}
                            setFlightSelectedOrigin={setFlightSelectedOrigin}
                            flightsMaxShippingDate={flightsMaxShippingDate}
                            setFlightsMaxShippingDate={setFlightsMaxShippingDate}
                            flightsMinDate={flightsMinDate}
                            setFlightsMinDate={setFlightsMinDate}

                            //scheduler
                            schedulerMaxShippingDate={schedulerMaxShippingDate}
                            setSchedulerMaxShippingDate={setSchedulerMaxShippingDate}
                            schedulerMinDate={schedulerMinDate}
                            setSchedulerMinDate={setSchedulerMinDate}
                            weekdays={weekdays}
                            setWeekdays={setWeekdays}
                            dateScheduler={dateScheduler}
                            setDateScheduler={setDateScheduler}

                            isListPageOpen={isListPageOpen}
                            setListPageOpen={setListPageOpen}
                            clearToken={clearToken}
                            isLoading={isLoading}
                            setLoading={setLoading}
                            setInitialList={setInitialList}
                            navigateBack={navigateBack}
                        >
                        </SearchPanel>
                    </div>
                </div>
            </div>
    )
}

export default SeasonalBooking;