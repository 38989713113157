import React, { useEffect } from 'react'
import {
	PAYMENT_TYPES_SELECT_ITEMS,
	SCI_SELECT_ITEMS,
	ACCOUNTING_IDENTIFIER_SELECT_ITEMS,
} from '../../../constants'
import { Dropdown } from '../../../common/DropDown'
import {
	validateAlphaIfPresent,
	validateAlphaNumericIfPresent,
	hasError,
	removeError,
	validateNumericIfPresent,
	validateFieldLengthIfPresent,
	handleHsCode,
	validateDecimalIfPresent,
} from '../captureAwbUtils'
import plusIcon from '../../../assets/images/plus.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import { useTranslation } from 'react-i18next'
const CustomsDetails = ({ awbDetails, setAwbDetails, error, setError }) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	let {
		awbChargeDeclaration,
		accountingInformationDetails,
		customsInformationDetails,
		currency,
	} = awbDetails

	useEffect(() => {}, [])

	const handleAddCustomsInfo = () => {
		const customsInfo = {
			informationIdentifier: '',
			customsInformationIdentifier: '',
			supplementaryCustomsControlInformation: '',
			countryCode: '',
			isNew: true,
		}
		customsInformationDetails.push(customsInfo)
		setAwbDetails((prevState) => ({
			...prevState,
			customsInformationDetails: customsInformationDetails,
		}))
	}

	const handleDeleteAction = (e, index) => {
		const customsInformationDetailsTemp = customsInformationDetails.filter(
			(itemDetail, curr) => {
				return index !== curr
			}
		)
		setAwbDetails((prevState) => ({
			...prevState,
			customsInformationDetails: customsInformationDetailsTemp,
		}))
	}

	const handleAddAccountingInfo = () => {
		const accountingInfo = {
			accountingInformationIdentifier: '',
			accountingInformation: '',
			isNew: true,
		}
		accountingInformationDetails.push(accountingInfo)
		setAwbDetails((prevState) => ({
			...prevState,
			accountingInformationDetails: accountingInformationDetails,
		}))
	}

	const handleAccountingDeleteAction = (e, index) => {
		const accountingInformation = accountingInformationDetails.filter(
			(itemDetail, curr) => {
				return index !== curr
			}
		)
		setAwbDetails((prevState) => ({
			...prevState,
			accountingInformationDetails: accountingInformation,
		}))
	}

	var trimDecimalValue = function( val, decimals ) {
		if ( parseFloat( val ) ) {
			var numeric_string = String(val);
			var parts = numeric_string.split( '.', 2);
			if ( parts[1] && parts[1].length > decimals ) {
				let slicedString = parts[0]+'.'+parts[1].slice(0,3)
				return slicedString.slice(0,13)
			}
			else {
				if(val % 1 !== 0){
					let numberString = String(val)
					return numberString.slice(0,13)
				}
				else{
					let numberString = String(val)
					return numberString.slice(0,12)
				}
			}
		}
	}

	return (
		<div className='form-row cutom-details'>
			<div className='col-12 col-lg-12'>
				<div className='mt-2'>
					<div className='form-row mb-3'>
						<div className='col-12 col-lg-12'>
							<div className='form-row'>
								<div className='col-6 col-lg-2 form-item'>
									<Dropdown
										id='sci'
										value={awbDetails.sci}
										options={SCI_SELECT_ITEMS}
										label='SCI'
										onChange={(e) => {
											setAwbDetails((prevState) => ({
												...prevState,
												sci: e.target.value,
											}))
											//validateRequired(e.target.value, setError, 'sci')
										}}
										ariaInvalid={hasError('sci', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									></Dropdown>
									<span className='form-item__error'>
										{error && error['sci'] ? error['sci'] : ''}
									</span>
								</div>
								<div className='col-6 col-lg-2 form-item'>
									<Dropdown
										id='customsPaymentType'
										value={
											awbChargeDeclaration.prepaidCollectChargeDeclaration
												.prepaidCollectIndicatorWgtValChgs
										}
										options={PAYMENT_TYPES_SELECT_ITEMS}
										label={t('CAPTURE_AWB.PAY_TYPE')}
										onChange={(e) => {
											awbChargeDeclaration.prepaidCollectChargeDeclaration.prepaidCollectIndicatorWgtValChgs =
												e.target.value
											setAwbDetails((prevState) => ({
												...prevState,
												awbChargeDeclaration: awbChargeDeclaration,
											}))
											/*validateRequired(
												e.target.value,
												setError,
												'customsPaymentType'
											)*/
										}}
										ariaInvalid={hasError('customsPaymentType', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									></Dropdown>
									<span className='form-item__error'>
										{error && error['customsPaymentType']
											? error['customsPaymentType']
											: ''}
									</span>
								</div>
								<div
									className='col-6 col-lg-2 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>{`${t(
										'COMMON.DV_CSTM'
									)}(${currency})`}</label>
									<input
										id='dvCustoms'
										type='number'
										className='form-item__field'
										maxLength={awbChargeDeclaration.declaredValueForCustoms % 1 === 0 ? '12' : '13'}
										placeholder='DV customs'
										value={awbChargeDeclaration.declaredValueForCustoms}
										onChange={(e) => {
											let trimmedValue = trimDecimalValue(e.target.value, 2)
											awbChargeDeclaration.declaredValueForCustoms = trimmedValue
											setAwbDetails((prevState) => ({
												...prevState,
												awbChargeDeclaration: awbChargeDeclaration,
											}))
										}}
										onBlur={(e) => {
											validateDecimalIfPresent(
												e.target.value,
												setError,
												'dvCustoms'
											)
										}}
										aria-invalid={hasError('dvCustoms', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['dvCustoms']
											? t(error['dvCustoms'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className='col-6 col-lg-2 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>{`${t(
										'COMMON.DV_CARRIAGE'
									)}(${currency})`}</label>
									<input
										id='dvCarriage'
										type='number'
										className='form-item__field'
										maxLength={awbChargeDeclaration.declaredValueForCarriage % 1 === 0 ? '12' : '13'}
										placeholder='DV carriage'
										value={awbChargeDeclaration.declaredValueForCarriage}
										onChange={(e) => {
											let trimmedValue = trimDecimalValue(e.target.value, 2)
											awbChargeDeclaration.declaredValueForCarriage = trimmedValue
											setAwbDetails((prevState) => ({
												...prevState,
												awbChargeDeclaration: awbChargeDeclaration,
											}))
										}}
										onBlur={(e) => {
											validateDecimalIfPresent(
												e.target.value,
												setError,
												'dvCarriage'
											)
										}}
										aria-invalid={hasError('dvCarriage', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['dvCarriage']
											? t(error['dvCarriage'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className='col-6 col-lg-2 mt-md-2 mt-lg-0 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('CAPTURE_AWB.HS_CODE')}
									</label>
									<input
										id='hsCode'
										type='text'
										className='form-item__field'
										maxLength='100'
										value={awbDetails.hccs ? awbDetails.hccs.toUpperCase() : ''}
										onChange={(e) => {
											setAwbDetails((prevState) => ({
												...prevState,
												hccs: e.target.value
													? e.target.value.split(' ').join('')
													: '',
											}))
										}}
										onBlur={(e) => {
											handleHsCode(e.target.value, setError)
										}}
										aria-invalid={hasError('hsCode', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['hsCode']
											? t(error['hsCode'], { ns: 'errors' })
											: ''}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className='form-row mb-3'>
						<div
							className='col-12 col-lg-8 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>
								{t('CAPTURE_AWB.HANDLING_INFO')}
							</label>
							<input
								id='handlingInfo'
								type='text'
								className='form-item__field  handling-info'
								maxLength='140'
								value={awbDetails.handlingInfo ? awbDetails.handlingInfo.toUpperCase() :''}
								onChange={(e) => {
									setAwbDetails((prevState) => ({
										...prevState,
										handlingInfo: e.target.value,
									}))
								}}
								onBlur={(e) => {
									validateAlphaNumericIfPresent(
										e.target.value,
										setError,
										'handlingInfo'
									)
								}}
								aria-invalid={hasError('handlingInfo', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['handlingInfo']
									? t(error['handlingInfo'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row'>
						<div className='col-12'>
							<div className='form-row mb-3'>
								<div className='col-12'>
									<h5 className='section-sub-heading'>
										{t('CAPTURE_AWB.ACCOUNTING_INFO')}
									</h5>
								</div>
							</div>
							{accountingInformationDetails &&
							accountingInformationDetails.length ? (
								accountingInformationDetails.map((info, index) => (
									<div className='form-row mb-3'>
										<div className='col-12 col-lg-4 form-item'>
											<Dropdown
												id={`accountInfoId${index}`}
												value={info.accountingInformationIdentifier}
												options={ACCOUNTING_IDENTIFIER_SELECT_ITEMS}
												label={t('CAPTURE_AWB.IDENTIFIER')}
												onChange={(e) => {
													accountingInformationDetails[
														index
													].accountingInformationIdentifier = e.target.value
													setAwbDetails((prevState) => ({
														...prevState,
														accountingInformationDetails:
															accountingInformationDetails,
													}))
													/*validateRequired(
												e.target.value,
												setError,
												'accountInfoId'
											)*/
												}}
												ariaInvalid={hasError(`accountInfoId${index}`, error)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											></Dropdown>
											<span className='form-item__error'>
												{error && error[`accountInfoId${index}`]
													? t(error[`accountInfoId${index}`], { ns: 'errors' })
													: ''}
											</span>
										</div>
										<div
											className='col-12 col-lg-7 form-item form-item--text'
											data-module='molecules/form-item/FormItem'
										>
											<label className='form-item__label'>
												{t('CAPTURE_AWB.INFO')}
											</label>
											<input
												id={`accountingInformation${index}`}
												type='text'
												className='form-item__field accounting-info'
												maxLength='34'
												value={info.accountingInformation ? info.accountingInformation.toUpperCase() : ''}
												onChange={(e) => {
													accountingInformationDetails[
														index
													].accountingInformation = e.target.value
													setAwbDetails((prevState) => ({
														...prevState,
														accountingInformationDetails:
															accountingInformationDetails,
													}))
												}}
												onBlur={(e) => {
													validateAlphaNumericIfPresent(
														e.target.value,
														setError,
														`accountingInformation${index}`
													)
												}}
												aria-invalid={hasError(
													`accountingInformation${index}`,
													error
												)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`accountingInformation${index}`]
													? t(error[`accountingInformation${index}`], {
															ns: 'errors',
													  })
													: ''}
											</span>
										</div>
										{info.isNew && (
											<div className='col-12 col-md-1'>
												<button className='delete-button'>
													<i className='icon'>
														<img
															src={deleteIcon}
															alt='Delete'
															title='Delete'
															onClick={(e) =>
																handleAccountingDeleteAction(e, index)
															}
														/>
													</i>
												</button>
											</div>
										)}
									</div>
								))
							) : (
								<div></div>
							)}
							<div className='form-row'>
								<div className='col-12 mb-4 mt-2'>
									<button
										className='link'
										style={{ background: 'transparent' }}
										onClick={handleAddAccountingInfo}
									>
										<span className='pt-1'>
											{t('CAPTURE_AWB.ADD_ACCNT_INFO')}
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='form-row'>
						<div className='col-12'>
							<div className='form-row mb-3'>
								<div className='col-12'>
									<h5 className='section-sub-heading'>
										{t('CAPTURE_AWB.CUST_INFO')}
									</h5>
								</div>
							</div>
							{customsInformationDetails && customsInformationDetails.length ? (
								customsInformationDetails.map((info, index) => (
									<div className='form-row mb-3'>
										<div
											className='col-6 col-lg-2 form-item form-item--text'
											data-module='molecules/form-item/FormItem'
										>
											<label className='form-item__label'>
												{t('CAPTURE_AWB.ISO_CNTRY_CODE')}
											</label>
											<input
												id={`isoCountryCode${index}`}
												type='text'
												className='form-item__field'
												maxLength='2'
												placeholder=''
												value={info.countryCode ? info.countryCode.toUpperCase() : ''}
												onChange={(e) => {
													const val = e.target.value
													customsInformationDetails[index].countryCode =
														val && val.trim() !== '' ? val.toUpperCase() : val
													setAwbDetails((prevState) => ({
														...prevState,
														customsInformationDetails:
															customsInformationDetails,
													}))
												}}
												onBlur={(e) => {
													validateAlphaIfPresent(
														e.target.value,
														setError,
														`isoCountryCode${index}`
													)
													validateFieldLengthIfPresent(
														e.target.value,
														setError,
														`isoCountryCode${index}`,
														null,
														null,
														2
													)
												}}
												aria-invalid={hasError(`isoCountryCode${index}`, error)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`isoCountryCode${index}`]
													? t(error[`isoCountryCode${index}`], { ns: 'errors' })
													: ''}
											</span>
										</div>
										<div
											className='col-6 col-lg-2 form-item form-item--text'
											data-module='molecules/form-item/FormItem'
										>
											<label className='form-item__label'>
												{t('CAPTURE_AWB.INFO_ID')}
											</label>
											<input
												id={`informationID${index}`}
												type='text'
												className='form-item__field'
												maxLength='3'
												placeholder=''
												value={info.informationId ? info.informationId.toUpperCase() : ''}
												onChange={(e) => {
													customsInformationDetails[index].informationId = e
														.target.value
														? e.target.value.split(' ').join('')
														: ''
													setAwbDetails((prevState) => ({
														...prevState,
														customsInformationDetails:
															customsInformationDetails,
													}))
												}}
												onBlur={(e) => {
													validateAlphaIfPresent(
														e.target.value,
														setError,
														`informationID${index}`
													)
													validateFieldLengthIfPresent(
														e.target.value,
														setError,
														`informationID${index}`,
														null,
														null,
														3
													)
												}}
												aria-invalid={hasError(`informationID${index}`, error)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`informationID${index}`]
													? t(error[`informationID${index}`], { ns: 'errors' })
													: ''}
											</span>
										</div>
										<div
											className='col-12 col-lg-3 form-item form-item--text'
											data-module='molecules/form-item/FormItem'
										>
											<label className='form-item__label'>
												{t('CAPTURE_AWB.CUST_INFO_ID')}
											</label>
											<input
												id={`customsInfoId${index}`}
												type='text'
												className='form-item__field'
												maxLength='2'
												value={info.customsInformationId ? info.customsInformationId.toUpperCase() : ''}
												onChange={(e) => {
													customsInformationDetails[
														index
													].customsInformationId = e.target.value
														? e.target.value.split(' ').join('')
														: ''
													setAwbDetails((prevState) => ({
														...prevState,
														customsInformationDetails:
															customsInformationDetails,
													}))
												}}
												onBlur={(e) => {
													validateAlphaIfPresent(
														e.target.value,
														setError,
														`customsInfoId${index}`
													)
													validateFieldLengthIfPresent(
														e.target.value,
														setError,
														`customsInfoId${index}`,
														2,
														1,
														null
													)
												}}
												aria-invalid={hasError(`customsInfoId${index}`, error)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`customsInfoId${index}`]
													? t(error[`customsInfoId${index}`], { ns: 'errors' })
													: ''}
											</span>
										</div>

										<div
											className='col-12 col-lg-4 form-item form-item--text'
											data-module='molecules/form-item/FormItem'
										>
											<label className='form-item__label'>
												{t('CAPTURE_AWB.SUP_INFO_ID')}
											</label>
											<input
												id={`suppCustomsInfo${index}`}
												type='text'
												className='form-item__field sup-info'
												maxLength='35'
												value={info.supplementaryCustomsInformation ? info.supplementaryCustomsInformation.toUpperCase() : ''}
												onChange={(e) => {
													customsInformationDetails[
														index
													].supplementaryCustomsInformation = e.target.value
													setAwbDetails((prevState) => ({
														...prevState,
														customsInformationDetails:
															customsInformationDetails,
													}))
												}}
												onBlur={(e) => {
													validateAlphaNumericIfPresent(
														e.target.value,
														setError,
														`suppCustomsInfo${index}`
													)
												}}
												aria-invalid={hasError(
													`suppCustomsInfo${index}`,
													error
												)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`suppCustomsInfo${index}`]
													? t(error[`suppCustomsInfo${index}`], {
															ns: 'errors',
													  })
													: ''}
											</span>
										</div>
										{info.isNew && (
											<div className='col-12 col-lg-1'>
												<button className='delete-button'>
													<i className='icon'>
														<img
															src={deleteIcon}
															alt='Delete'
															title='Delete'
															onClick={(e) => handleDeleteAction(e, index)}
														/>
													</i>
												</button>
											</div>
										)}
									</div>
								))
							) : (
								<div></div>
							)}
							<div className='form-row'>
								<div className='col-12 mb-4 mt-2'>
									<button
										className='link'
										style={{ background: 'transparent' }}
										onClick={handleAddCustomsInfo}
									>
										<span className='pt-1'>
											{t('CAPTURE_AWB.ADD_CUST_INFO')}
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CustomsDetails
