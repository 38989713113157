import { OverlayPanel } from 'primereact/overlaypanel'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { addError } from '../../utils/analytics'
const CustomAutoComplete = ({
	templateList,
	quoteList,
	draftList,
	handleSelectedItem,
	selectedItem,
	setSelectedItem,
	populateQuoteDetailsInSummary,
	listQuoteError,
	listTemplateNamesError,
	listDraftsError,
	isSeasonalBooking,
}) => {
	const [text, setText] = useState(null)
	const [activeIndex, setActiveIndex] = useState(0)
	const [templateDetails, setTemplateDetails] = useState([])
	const [draftDetails, setDraftDetails] = useState([])
	const [quoteDetails, setQuoteDetails] = useState([])
	const opRef = useRef(null)
	const inputRef = useRef(null)
	const tabPanelRef = useRef(null)

	const { t, i18n } = useTranslation(['labels', 'errors'])

	useEffect(() => {
		getDropdownList()
	}, [templateList, quoteList, draftList])

	useEffect(() => {
		if (listQuoteError && listQuoteError.description) {
			addError('API_ERROR_LIST_QUOTE', listQuoteError.description)
		}
		if (listTemplateNamesError && listTemplateNamesError.description) {
			addError('API_ERROR_LIST_TEMPLATE', listTemplateNamesError.description)
		}
		if (listDraftsError && listDraftsError.description) {
			addError('API_ERROR_LIST_DRAFT', listDraftsError.description)
		}
	}, [listQuoteError, listTemplateNamesError, listDraftsError])

	useEffect(() => {
		setText(text)
	}, [text])

	const getDropdownList = () => {
		if (templateList) {
			setTemplateDetails(templateList.templateList)
		}
		if (draftList) {
			setDraftDetails(draftList.bookingDraft)
		}
		if (quoteList) {
			setQuoteDetails(quoteList.quotesList)
		}
	}

	const handleSelect = (e) => {
		const value = e.target.innerText ? e.target.innerText : text
		setSelectedItem(value)
		handleSubmit(value)
	}

	const handleSubmit = (value) => {
		if (opRef.current) {
			opRef.current.hide()
		}
		if (activeIndex === 0) {
			handleSelectedItem(value, 'template')
		} else if (activeIndex === 1) {
			handleSelectedItem(value, 'DRAFT')
		} else if (activeIndex === 2) {
			populateQuoteDetailsInSummary(value)
		}
	}

	const filterTemplates = (value) => {
		if (!value || !value.trim().length) {
			setTemplateDetails(templateList.templateList)
			return
		}
		const filteredByItemStart =
			templateList && templateList.templateList.length
				? templateList.templateList.filter((template) => {
					return template.templateName
						.toLowerCase()
						.startsWith(value.toLowerCase())
				})
				: []

		const filteredByItemContains =
			templateList && templateList.templateList.length
				? templateList.templateList.filter((template) => {
					return template.templateName
						.toLowerCase()
						.includes(value.toLowerCase())
				})
				: []

		const filteredTemplates = [
			...filteredByItemStart,
			...filteredByItemContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.templateName === item.templateName)
		)

		setTemplateDetails(filteredTemplates)
	}

	const filterDrafts = (value) => {
		if (!value || !value.trim().length) {
			setDraftDetails(draftList.bookingDraft)
			return
		}
		const filteredByItemStart =
			draftList && draftList.bookingDraft.length
				? draftList.bookingDraft.filter((template) => {
					return template.draftName
						.toLowerCase()
						.startsWith(value.toLowerCase())
				})
				: []

		const filteredByItemContains =
			draftList && draftList.bookingDraft.length
				? draftList.bookingDraft.filter((draft) => {
					return draft.draftName.toLowerCase().includes(value.toLowerCase())
				})
				: []

		const filteredDrafts = [
			...filteredByItemStart,
			...filteredByItemContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.draftName === item.draftName)
		)

		setDraftDetails(filteredDrafts)
	}

	const filterQuotes = (value) => {
		if (!value || !value.trim().length) {
			setQuoteDetails(quoteList.quotesList)
			return
		}
		const filteredByItemStart =
			quoteList && quoteList.quotesList.length
				? quoteList.quotesList.filter((quote) => {
					return quote.quoteName.toLowerCase().startsWith(value.toLowerCase())
				})
				: []

		const filteredByItemContains =
			quoteList && quoteList.quotesList.length
				? quoteList.quotesList.filter((quote) => {
					return quote.quoteName.toLowerCase().includes(value.toLowerCase())
				})
				: []

		const filteredQuotes = [
			...filteredByItemStart,
			...filteredByItemContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.quoteName === item.quoteName)
		)

		setQuoteDetails(filteredQuotes)
	}

	const setFocus = () => {
		inputRef.current.focus()
	}

	const handleClick = (e) => {
		setText(text)
		if (opRef.current) {
			opRef.current.toggle(e)
		}

		getDropdownList()
		//setFocus()
		setActiveIndex(0)
	}

	const handleChange = (e) => {
		setSelectedItem(null)
		setText(e.target.value)
		filterTemplates(e.target.value)
		filterDrafts(e.target.value)
		filterQuotes(e.target.value)
	}

	const handleKeyPress = (evnt) => {
		let charCode = evnt.which ? evnt.which : evnt.keyCode
		if (charCode === 13) handleSelect(evnt)
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {
			e.preventDefault()
			let selectedValue
			if (
				activeIndex === 0 &&
				templateDetails &&
				templateDetails.length === 1
			) {
				selectedValue = templateDetails[0].templateName
			} else if (
				activeIndex === 1 &&
				draftDetails &&
				draftDetails.length === 1
			) {
				selectedValue = draftDetails[0].draftName
			} else if (
				activeIndex === 2 &&
				quoteDetails &&
				quoteDetails.length === 1
			) {
				selectedValue = quoteDetails[0].quoteName
			}
			//setSelectedItem(selectedValue)
			handleSubmit(selectedValue)
		}
	}

	return (
		<div className='custom-autocomplete-wrapper'>
			<div
				className='form-item form-item--text'
				data-module='molecules/form-item/FormItem'
			>
				<span
					className='p-input-icon-right  arrow-wrapper'
					onClick={(e) => handleClick(e)}
				>
					<i className='pi pi-chevron-down' />
					<input
						type='text'
						id='customAutoComplete'
						ref={inputRef}
						autoComplete='off'
						className={`form-control form-item__field input-text-wrapper ${isSeasonalBooking ? 'seasonal-booking-custom_style' : ''}`}
						style={{ fontSize: '20px', width: '100%' }}
						value={selectedItem ? selectedItem : text}
						placeholder={!isSeasonalBooking ? 'Select template/draft/quote' : 'Select template'}
						onChange={(e) => handleChange(e)}
						onKeyPress={(e) => handleKeyPress(e)}
						onKeyDown={(e) => handleKeyDown(e)}
						onBlur={(e) => {
							//setText('')
						}}
					/>
				</span>
			</div>

			<div className='custom-overlay-panel'>
				<OverlayPanel ref={opRef}>
					<div>
						<TabView
							id='hometab'
							ref={tabPanelRef}
							activeIndex={isSeasonalBooking ? 0 : activeIndex}
							onTabChange={(e) => {
								setText
								setFocus()
								setActiveIndex(isSeasonalBooking ? 0 : e.index)
							}}
						>
							<TabPanel header={t('COMMON.TEMPLATE')} id='template'>
								<div>
									<ul className='list-box'>
										{templateDetails && templateDetails.length > 0 ? (
											templateDetails.map((template) => (
												<>
													<li
														value={template.templateName}
														className='list-item'
														onClick={(e) => handleSelect(e)}
													>
														{template.templateName}
													</li>
												</>
											))
										) : (
											<li className='empty-list-wrapper'>
												{t('ER077', { ns: 'errors' })}
											</li>
										)}
									</ul>
								</div>
							</TabPanel>
							{!isSeasonalBooking ?
								<TabPanel header={t('COMMON.DRAFT')} id='draft'>
									<div>
										<ul className='list-box'>
											{draftDetails && draftDetails.length > 0 ? (
												draftDetails.map((draft) => (
													<>
														<li
															value={draft.draftName}
															className='list-item'
															onClick={(e) => handleSelect(e)}
														>
															{draft.draftName}
														</li>
													</>

												))
											) : (
												<li className='empty-list-wrapper'>
													{t('ER081', { ns: 'errors' })}
												</li>
											)}
										</ul>
									</div>
								</TabPanel>
								: <></>}
							{!isSeasonalBooking ?
								<TabPanel ref={tabPanelRef} header={t('COMMON.QUOTE')} id='quote'>
									<div className='custom-panel-sub-wrapper'>
										<ul className='list-box'>
											{quoteDetails && quoteDetails.length > 0 ? (
												quoteDetails.map((quote) => (
													<>
														<li
															value={quote.quoteName}
															className='list-item'
															onClick={(e) => handleSelect(e)}
														>
															{quote.quoteName}
														</li>
													</>
												))
											) : (
												<li className='empty-list-wrapper'>
													{t('ER067', { ns: 'errors' })}
												</li>
											)}
										</ul>
									</div>
								</TabPanel>
								: <></>}
						</TabView>
					</div>
				</OverlayPanel>
			</div>
		</div>
	)
}

export default CustomAutoComplete
