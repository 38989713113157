import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import AwbChargesReport from './awbCharges'
import DapReport from './DapReport'

const ReportsRoot = () => {
	const history = useHistory()

	return (
		<>
			<div className='container-lg'>
				<Switch>
                    <Route
                        exact
                        path='/reports'
                        render={() => {
                            history.push('/reports/awb-charges')
                        }}
                    />
                    {/* <Route
                        exact
                        path='/booking/search'
                        render={(props) => <FlightSearchPage {...props} />}
                    /> */}
					<Route
                        exact
						path='/reports/awb-charges'
                        component={AwbChargesReport}
					/>
                    <Route 
                        path='/reports/dap' 
                        component={DapReport} 
                    />
				</Switch>
			</div>
		</>
	)
}

export default ReportsRoot
