export const FORCE_QUEUE_SCC = {
	scc: 'ERR',
}

export const EYP_SCC = {
	scc: 'EYP',
}

export const OHG_SCC = {
	scc: 'OHG',
}

export const BIG_SCC = {
	scc: 'BIG',
}

export const SMALL_SCC = {
	scc: 'SML',
}

export const EXCEPTION_SCCS = ['OHG', 'BIG', 'HEA']
