import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    getInitSeasonalFlightDetails,
} from '../../../constants';
import trashIcon from '../../../assets/images/trash.svg';
import MESSAGES from '../../../constants/Messages'
import {
    SEASONAL_FIELDS,
    AUTOCOMPLETE_TYPE,
} from '../../../constants';
import {
    validateFieldValue, validateFlightNumber
} from '../formUtils';
import Calendar from '../../../common/Calendar';
import AirportAutocomplete from '../../../components/AirportAutocomplete'
import './index.scss';
import {
    isAlphaNumeric,
    checkFlightFormat,
    checkFlightFormatSeasonal,
    isAlphaNumericSeasonal
} from '../../../utils/Validations'

import { ALLOWABLE_BOOKING_DATE } from '../../../configs/appConfigs';


const AddMultiFlights = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    flightList,
    setFlightList,
    flightSelectedOrigin,
    setFlightSelectedOrigin,
    flightsMaxShippingDate,
    setFlightsMaxShippingDate,
    flightsMinDate,
    setFlightsMinDate,
    seasonalError,
    setSeasonalError,
    selectedDestination,
    dateScheduler,
    startonDay,
    setStartonDay,
    isAllotmentRequired,
    isAllotmentRequiredFlightDate,
    setIsAllotmentRequiredFlightDate,
    setIsAllotmentRequired,
    indexArray

}) => {



    const draftref = useRef(false);
    const { t, i18n } = useTranslation(['labels', 'errors'])


    useEffect(() => {
        const presentDate = new Date();
        const futureDate = new Date(presentDate.getTime() + (ALLOWABLE_BOOKING_DATE * 24 * 60 * 60 * 1000));
        if (endDate) {
            if (endDate < futureDate) {
                setIsAllotmentRequired(false)
                handleError([SEASONAL_FIELDS.ALLOTMENT_ID], null, 0)
                setSeasonalError(seasonalError)
            }
            else if (endDate > futureDate) {
                setIsAllotmentRequired(true)
            }
        }

    }, [startDate, endDate])


    useEffect(() => {
        setIsAllotmentRequiredFlightDate(true)
        const presentDate = new Date();
        const futureDate = new Date(presentDate.getTime() + (ALLOWABLE_BOOKING_DATE * 24 * 60 * 60 * 1000));
        for (let index in flightList) {
            if (flightList[index]?.flightDate) {
                if (index !== "0") {
                    if (flightList[index]?.flightDate < futureDate) {
                        const indexToRemove = indexArray.indexOf(index);
                        if (indexToRemove !== -1) {
                            indexArray.splice(indexToRemove, 1);
                        }
                        setIsAllotmentRequiredFlightDate(false)
                        handleError([SEASONAL_FIELDS.ALLOTMENT_ID], null, index)
                        setSeasonalError(seasonalError)
                    }
                    else if (flightList[index]?.flightDate > futureDate) {
                        indexArray.includes(index) ? '' : indexArray.push(index)

                        setIsAllotmentRequiredFlightDate(true)
                    }
                }

            }
            else if (index !== "0") {
                indexArray.includes(index) ? '' : indexArray.push(index)

                setIsAllotmentRequiredFlightDate(true)
            }
        }
        indexArray?.length > 0 ? setIsAllotmentRequiredFlightDate(true) : setIsAllotmentRequiredFlightDate(false)

    }, [flightList])

    useEffect(() => {
        const flightListData = [...flightList]
        if (dateScheduler === 'weekly') {
            flightListData[0]['flightDate'] = startonDay
            setFlightList([...flightListData])
        }
        else if (dateScheduler !== 'weekly') {
            flightListData[0]['flightDate'] = startDate
            setFlightList([...flightListData])
        }

    }, [startDate, startonDay, dateScheduler])

    const handleItemChange = async (key, item, index) => {

        const flightListData = [...flightList]

        if (flightListData) {
            //const flightListData = JSON.parse(cageListJson)
            flightListData[index][key] = item
            await setFlightList([...flightListData])
        }

        const istrue = flightListData?.find((obj) => obj?.destination?.code === selectedDestination?.code) ? true : false

        if (flightListData && flightListData.length > 1 &&
            istrue) {
            const FlighListNew = flightListData.slice(0, index + 1)
            setFlightList(FlighListNew)

        }
    }

    const handleAddFlights = () => {
        let flightData = [...flightList, getInitSeasonalFlightDetails()]
        if (flightData.length === 4) {
            setFlightBtnDisable(true)
        }
        else {
            setFlightBtnDisable(false)
        }

        setFlightList(flightData)
    }


    const handleRemoveItem = (indexPassed, item) => {
        setFlightBtnDisable(false)
        if (item.id) {
            let idObj = {
                'id': indexPassed
            }
            //setDeletedCageIds([...deletedCageIds, idObj]);
        }

        const itemsTemp = [...flightList].filter((itemDetail, curr) => {
            return item.id ? itemDetail.id !== indexPassed : indexPassed !== curr
        })
        setFlightList([...itemsTemp])
    }

    const handleError = (key, errorMsg, index) => {
        const tempError = seasonalError ? { ...seasonalError } : {}
        let flightErrors =
            seasonalError && seasonalError[SEASONAL_FIELDS.FLIGHT_LIST]
        if (!flightErrors) {
            flightErrors = []
            flightErrors[index] = flightErrors[index] ? flightErrors[index] : {}
            flightErrors[index][key] = errorMsg
        }
        if (flightErrors) {
            flightErrors[index] = flightErrors[index] ? flightErrors[index] : {}
            flightErrors[index][key] = errorMsg
            setSeasonalError({ ...tempError, [SEASONAL_FIELDS.FLIGHT_LIST]: flightErrors })
        }
    }

    const handleSetError = (key, text) => {
        seasonalError
            ? setSeasonalError({
                ...seasonalError,
                [key]: text,
            })
            : setSeasonalError({
                [key]: text,
            })
    }
    const clearError = (key) => {
        const tempError = seasonalError ? { ...seasonalError } : null
        if (tempError) {
            tempError[key] = null
            setSeasonalError(tempError)
        }
    }

    const draftCallback = () => {
        draftref.current = true
    }

    const handleFlightDestination = (key, value, index) => {


        if (value?.code == selectedDestination?.code) {
            setFlightBtnDisable(true);
        }
        else {
            setFlightBtnDisable(false);
        }

        handleItemChange(key, value, index)

    }

    const setMultiComponentDestinationError = (key, value, index) => {
        handleError(key, validateFieldValue(value, t(MESSAGES.INVALID_DESTINATION)), index)
    }

    const setMultiComponentFlightDateError = (key, value, index) => {
        handleError(key, validateFieldValue(value, t(MESSAGES.ENTER_VALID_FLIGHT_DATE)), index)
    }

    const validateFlightNoRegex = (flightNumber, errorMsg) => {
        if (!checkFlightFormatSeasonal(flightNumber)) {
            return errorMsg
        }
        else {
            return null
        }

    }


    const [flightBtnDisable, setFlightBtnDisable] = useState(false)

    return (
        <>
            {
                flightList.map((item, index) => {
                    const flightErrors = seasonalError &&
                        seasonalError[SEASONAL_FIELDS.FLIGHT_LIST] &&
                        seasonalError[SEASONAL_FIELDS.FLIGHT_LIST][index] ? seasonalError[SEASONAL_FIELDS.FLIGHT_LIST][index] : null;

                    return (
                        <>
                            <div className="form-row search-panel">

                                {/**<div className='row'>**/}
                                <div className={`col-lg-2 form-group'  ${flightErrors && flightErrors[SEASONAL_FIELDS.FLIGHT_NUMBER] ? 'red-border' : ''
                                    }`} >
                                    <label>{index > 0 ? `${t('PERMANENT_BOOKING.FLIGHT_NUMBER')}(leg ${index + 1})` : t('PERMANENT_BOOKING.FLIGHT_NUMBER')}</label>
                                    <input
                                        type='text'
                                        placeholder={"Flight number"}
                                        value={item.flightNumber}
                                        className={`form-control`}
                                        onChange={(e) => {
                                            //on Change code
                                            // handleItemChange('flightNumber', e.target.value, index)
                                            isAlphaNumericSeasonal(e.target.value) && (e.target.value !== null) ? handleItemChange('flightNumber', e.target.value, index) : e.target.value === '' ? handleItemChange('flightNumber', '', index) : ''
                                            handleError([SEASONAL_FIELDS.FLIGHT_NUMBER], validateFlightNoRegex(e.target.value, t(MESSAGES.ENTER_FLIGHT_NUMBER)), index)
                                        }}
                                        onBlur={(e) => {
                                            handleError([SEASONAL_FIELDS.FLIGHT_NUMBER], validateFlightNoRegex(e.target.value, t(MESSAGES.ENTER_FLIGHT_NUMBER)), index)
                                        }}
                                        onFocus={(e) => {
                                            handleError([SEASONAL_FIELDS.FLIGHT_NUMBER], null, index)
                                        }}
                                        maxLength={8}

                                    />
                                    <div>
                                        <span className='search-panel__errormsg mt-4'>
                                            {flightErrors ? t(flightErrors[SEASONAL_FIELDS.FLIGHT_NUMBER], { ns: 'errors' }) : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className={`col-lg-2 form-group ${flightErrors && flightErrors[SEASONAL_FIELDS.FLIGHT_DATE] ? 'red-border' : ''}`} >
                                    <label>{t('PERMANENT_BOOKING.FLIGHT_DATE')}</label>
                                    <div class='input-wrapper f-15'>
                                        <Calendar
                                            className={`form-control form-item ${startDate && index === 0 ? '' : ''}`}
                                            value={startDate && index === 0 ? startDate && index === 0 && dateScheduler === "weekly" ? moment(startonDay, 'DD-MMM-YYYY').toDate() : moment(startDate, 'DD-MMM-YYYY').toDate() : moment(item.flightDate, 'DD-MMM-YYYY').toDate()}
                                            onChangeHandler={(shippingDate) => {
                                                handleItemChange('flightDate', moment(shippingDate, 'DD-MMM-YYYY').toDate(), index)
                                            }}
                                            // onFocusHandler={ }
                                            placeholder='Flight date'
                                            minDate={startDate != '' ? moment(startDate, 'DD-MMM-YYYY').toDate() : moment(flightsMinDate, 'DD-MMM-YYYY').toDate()}
                                            maxDate={moment(flightsMaxShippingDate, 'DD-MMM-YYYY').toDate()}
                                            setMultiComponentFlightDateError={setMultiComponentFlightDateError}
                                            componentIndex={index}
                                            disabled={index === 0 ? true : false}
                                        />
                                    </div>
                                    <div>
                                        <span className='search-panel__errormsg mt-4'>
                                            {flightErrors ? t(flightErrors[SEASONAL_FIELDS.FLIGHT_DATE], { ns: 'errors' }) : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className={`col-lg-2 form-group ${flightErrors && flightErrors[SEASONAL_FIELDS.DESTINATION] ? 'red-border' : ''}`} >
                                    <label>{t('COMMON.DEST')}</label>
                                    <div class='input-wrapper f-15'>
                                        <AirportAutocomplete
                                            autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
                                            selectedDestination={item.destination}
                                            flightSelectedOrigin={null}
                                            setSelectedDestination={(value) => {
                                                handleFlightDestination('destination', value, index)
                                            }
                                            }



                                            handleSetError={handleSetError}
                                            clearError={clearError}
                                            draftCallback={draftCallback}
                                            setMultiComponentError={setMultiComponentDestinationError}
                                            componentIndex={index}

                                        />
                                        {/* <span className='search-panel__errormsg'>
										{seasonalError && seasonalError[FORM_FIELDS.DESTINATION]
											? t(seasonalError[FORM_FIELDS.DESTINATION], { ns: 'errors' })
											: ''}
									</span> */}
                                    </div>
                                    <div>
                                        <span className='search-panel__errormsg mt-4'>
                                            {flightErrors ? t(flightErrors[SEASONAL_FIELDS.DESTINATION], { ns: 'errors' }) : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className={`col-lg-2 form-group ${flightErrors && flightErrors[SEASONAL_FIELDS.ALLOTMENT_ID] ? 'red-border' : ''
                                    }`} >
                                    <label>{t('PERMANENT_BOOKING.ALLOTMENT_ID')}</label>
                                    <input
                                        type='text'
                                        placeholder={"Allotment ID"}
                                        value={item.allotmentId}
                                        className={`form-control`}
                                        onChange={(e) => {
                                            //on Change code

                                            isAlphaNumeric(e.target.value) && (e.target.value !== null) ? handleItemChange('allotmentId', e.target.value, index) : e.target.value === '' ? handleItemChange('allotmentId', '', index) : ''

                                        }

                                        }
                                        onBlur={() => {
                                            isAllotmentRequired && index === 0 ? handleError([SEASONAL_FIELDS.ALLOTMENT_ID], validateFieldValue(item.allotmentId, t(MESSAGES.INVALID_ALLOTMENT_ID)), index) : isAllotmentRequiredFlightDate && indexArray.includes(String(index)) ? handleError([SEASONAL_FIELDS.ALLOTMENT_ID], validateFieldValue(item.allotmentId, t(MESSAGES.INVALID_ALLOTMENT_ID)), index) : ''
                                        }}
                                        onFocus={(e) => {
                                            handleError([SEASONAL_FIELDS.ALLOTMENT_ID], null, index)
                                        }}
                                        maxLength={15}



                                    />
                                    <div>
                                        <span className='search-panel__errormsg mt-4'>
                                            {flightErrors ? t(flightErrors[SEASONAL_FIELDS.ALLOTMENT_ID], { ns: 'errors' }) : ''}
                                        </span>
                                    </div>
                                </div>
                                {flightList.length > 1 &&
                                    !flightList.includes(selectedDestination?.code)
                                    // value?.code == selectedDestination?.code
                                    ? (
                                        <div className='col-lg-1 form-group delete-div delete-div-wrapper mt-5'>
                                            <div className='delete-section'>
                                                <img
                                                    src={trashIcon}
                                                    onClick={(e) => handleRemoveItem(index, item)}
                                                    alt='Trash'
                                                    title='Trash'
                                                />
                                                <span>{t('COMMON.DELETE')}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                {/**</div>**/}
                            </div>


                        </>
                    )

                })

            }

            <div className='col-lg-7 mt-3 add-more-flight' >
                <a className={`link-title ${flightBtnDisable ? 'disabled' : ''}`} href='javascript:void(0);' onClick={(e) => handleAddFlights(e)}>
                    {t('PERMANENT_BOOKING.ADD_ANOTHER_FLIGHT')}
                </a>
            </div>
        </>
    )
}

export default AddMultiFlights;