import React, { useState, useEffect } from 'react'
import './index.scss'
import { ProgressBar } from 'primereact/progressbar'
import AllotmentList from './AllotmentList'
import { getAllotments, getAllotmentColorClass } from './allotmentUtils'
import LoadingIndicator from '../../LoadingIndicator'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
import { DASHBOARD_ALLOTMENT_VIEW_ENABLED } from '../../../configs/featureControlConfig'
const AllotmentDetails = () => {
	const { t } = useTranslation(['labels', 'errors'])
	const ERROR = 'Error';
	const [allotmentValType, setAllotmentValType] = useState(
		'allotment-percentage'
	)
	const [isLoading, setIsLoading] = useState(false)

	const [allotmentList, setAllotmentList] = useState(
		/*listAllotmentsResponse ? listAllotmentsResponse.listallotments : */ null
	)
	const [allotment7Days, setAllotment7Days] = useState(0)
	const [allotment14Days, setAllotment14Days] = useState(0)

	//setEndIndex(rowCount - 1)

	useEffect(() => {
		;(async () => {
			try {
				const response = await getAllotments(14, setIsLoading)
				if (
					response &&
					response.listAllotments &&
					response.listAllotments.length
				) {
					setAllotmentList(response.listAllotments)
					if (
						response.totalAllotmentPercentWeight &&
						response.totalAllotmentPercentVolume &&
						response.totalAllotmentPercentVolume >
							response.totalAllotmentPercentWeight
					) {
						setAllotment14Days(response.totalAllotmentPercentVolume)
					} else {
						setAllotment14Days(
							response.totalAllotmentPercentWeight
								? response.totalAllotmentPercentWeight
								: response.totalAllotmentPercentVolume
								? response.totalAllotmentPercentVolume
								: 0
						)
					}
				} else if(!response){
					setAllotmentList(ERROR)
				}
			} catch (error) {
				console.log(error.message)
			}
		})()
	}, [])

	useEffect(() => {
		;(async () => {
			try {
				const response = await getAllotments(7, setIsLoading)
				if (response) {
					if (
						response.totalAllotmentPercentWeight &&
						response.totalAllotmentPercentVolume &&
						response.totalAllotmentPercentVolume >
							response.totalAllotmentPercentWeight
					) {
						setAllotment7Days(response.totalAllotmentPercentWeight)
					} else {
						setAllotment7Days(
							response.totalAllotmentPercentWeight
								? response.totalAllotmentPercentWeight
								: response.totalAllotmentPercentVolume
								? response.totalAllotmentPercentVolume
								: 0
						)
					}
				}
			} catch (error) {
				console.log(error.message)
			}
		})()
	}, [])

	const handleRadio = (e) => {
		setAllotmentValType(e.target.value)
	}
	return (
		<div className='allotment-details'>
			<div className='row'>
				<div className='col-12 col-md-4'>
					<h3 className='section-sub-heading custom-pl-2 mt-0'>
						{t('DASHBOARD.ALLMNT')}
					</h3>
				</div>
			</div>
			{allotmentList && allotmentList != ERROR && allotmentList.length > 0 ? (
				<div className='allotments-section'>
					<div className='row allotment-stats-summary'>
						<div className='col-12 col-lg-4'>
							<ProgressBar
								value={allotment7Days}
								className={getAllotmentColorClass(allotment7Days)}
							></ProgressBar>
							<label className='progressbar-label'>
								{t('DASHBOARD.NEXT_S_DYS')}
							</label>
							<label className='progressbar-label percent'>
								{allotment7Days}%
							</label>
						</div>
						<div className='col-12 col-lg-4 alloment-stats-week'>
							<ProgressBar
								value={allotment14Days}
								className={getAllotmentColorClass(allotment14Days)}
							></ProgressBar>
							<label className='progressbar-label'>
								{t('DASHBOARD.NEXT_F_DYS')}
							</label>
							<label className='progressbar-label percent'>
								{allotment14Days}%
							</label>
						</div>
						<div className='col-12 col-md-4 alloment-stats-type'>
							<div className='radio-btn-group'>
								<div className='form-row'>
									<div className='col-6 col-md-6'>
										<div className='form-item form-item--radio'>
											<input
												id='allotment-actual'
												type='radio'
												value='allotment-actual'
												name='allotment-value'
												className='form-item__field'
												aria-describedby='input-radio-description'
												onChange={handleRadio}
												checked={
													allotmentValType === 'allotment-actual' ? true : false
												}
											/>
											<label
												for='allotment-actual'
												className='form-item__label'
											>
												{t('DASHBOARD.ACTUAL')}
											</label>
										</div>
									</div>
									<div className='col-6 col-md-6'>
										<div className='form-item form-item--radio'>
											<input
												id='allotment-percentage'
												type='radio'
												value='allotment-percentage'
												name='allotment-value'
												className='form-item__field'
												aria-describedby='input-radio-description '
												onChange={handleRadio}
												checked={
													allotmentValType === 'allotment-percentage'
														? true
														: false
												}
											/>
											<label
												for='allotment-percentage'
												className='form-item__label'
											>
												{t('DASHBOARD.PRC')}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						DASHBOARD_ALLOTMENT_VIEW_ENABLED ? (
							<div>
								<AllotmentList
									allotmentValType={allotmentValType}
									setIsLoading={setIsLoading}
									allotmentList={allotmentList}
								/>
							</div>
						) : (<></>)
					}
					
				</div>
			) : (
				<div className='custom-mobile-row'>
					{!isLoading && (
						<div className='col-12'>
							<p className='empty-records ml-0 mr-0 pl-2'>
								{allotmentList != ERROR ?
								t(Messages.NO_ALLOTMENTS, { ns: 'errors' }) :
								t(Messages.ALLOTMENTS_FAILED, { ns: 'errors' })
								}
							</p>
						</div>
					)}
				</div>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
		</div>
	)
}

export default AllotmentDetails
