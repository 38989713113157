import React, { useEffect, useState } from 'react'
// import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from "../LoadingIndicator";

const PermanentBookingFileDropDown = ({
    fileList,
    setFileName,
    bookingFilter,
    setListPageOpen,
    fileName,
    clearToken,
    isRefreshActive,
}) => {

    //const [selectedFileName, setSelectedFileName] = useState(null)
    const [options, setOptions] = useState([])
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const options = fileList?.map(el => ({
            label: el.fileName,
            value: el.fileName,
        }))
        setOptions(options)
    }, [fileList])

    



    const handleChange = (e) => {
        clearInterval(clearToken);
        bookingFilter.confirmed = true
        bookingFilter.queued = true
        bookingFilter.tobeProcessed = true
        bookingFilter.failed = true
        //setSelectedFileName(e.target.value)
        if(isRefreshActive){
            setLoading(true)
            setTimeout(() => {
                setFileName(e.target.value)
                setLoading(false);
            },5100)
        }
        else{
            setFileName(e.target.value)
        }
    }

    return (
        <>
        {
            isLoading ? <LoadingIndicator /> : ''
          }
        <Dropdown
            className='permanent-booking-file-select-dropdown'
            value={fileName}
            options={options}
            placeholder='Select a File'
           // label={'fileName'}
            onChange={(e) => {
                handleChange(e)
                setListPageOpen(true);
            }}
        />
        </>
    )

}

export default PermanentBookingFileDropDown