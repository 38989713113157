import React from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
const ErrorPage = () => {
	//for multilingual
	const { t } = useTranslation(['errors'])
	return (
		<h1 className='error-page container'>
			{t(Messages.NO_ACCESS_PRIVILEGE, { ns: 'errors' })}
		</h1>
	)
}

export default ErrorPage
