import React from 'react'
import BookingDetailsView from './BookingDetailsView'
import { useParams } from 'react-router-dom'

const ViewBooking = () => {
	let { awbNo,ubrNumber } = useParams()

	return awbNo ? (
		<div>
			<BookingDetailsView awbNo={awbNo} ubrNumber={ubrNumber} />
		</div>
	) : (
		<></>
	)
}

export default ViewBooking
