import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import React, { useState, useEffect } from 'react'
import { PHARMA_ACTIVE_TYPE, PHARMA_SHIPPING_TYPE, NOTIFICATION_MESSAGE_TYPE } from '../../../constants'
import MESSAGES from '../../../constants/Messages'
import { sendEmailForAssistance } from '../../../utils/ExceptionHandlingUtil'
import { useTranslation } from 'react-i18next'
const UldValidationPopupdg = ({

    displayDgULDItemPopup,
    setDisplayDgULDItemPopup,
    handleClear,
    selectedDestination,
    selectedOrigin,
    shippingDate,
    shippingItem,
    setIsLoading,
    setNotificationMessage,
    setNotificationType,
    additionalSccs,
    popupNotificationPopup,
    exceptionTypeDg,
    spUnid,
    setSpUnid,
    unidList,
    setShippingType,

}) => {
    const [exceptionType, setExceptionType] = useState(exceptionTypeDg)
    const [itemList, setItemList] = useState()

    const { t, i18n } = useTranslation(['labels', 'errors'])

    const handleCancel = () => {
        setShippingType('LOOSE')
        setDisplayDgULDItemPopup(false)

    }

    useEffect(() => {

        let unidListArray = []

        unidList.map(item =>
            unidListArray.push(item.unid))

        setItemList(unidListArray)

    }, [unidList])



    const handleConfirm = async () => {
        const attributes = [
            {
                key: 'origin',
                value: selectedOrigin ? selectedOrigin.code : null,
            },
            {
                key: 'destination',
                value: selectedDestination ? selectedDestination.code : null,
            },
            {
                key: 'shippingItem',
                value: shippingItem &&
                    shippingItem?.description &&
                    shippingItem?.product
                    ? `${shippingItem.description}-${shippingItem.product}`
                    : null,
            },
            {
                key: 'shippingDate',
                value: shippingDate ? moment(shippingDate).format('DD MMM YYYY') : null,
            },
            {
                key: 'sccs',
                value: getSccs(additionalSccs),
            },
            {
                key: 'unid',
                value: itemList ? itemList : null,
            },
            {
                key: "shippingProduct",
                value: shippingItem &&
                    shippingItem?.product
                    ? `${shippingItem.product}`
                    : null,
            }

        ]
        const replacements = {
            '%DESCRIPTION%': shippingItem ? shippingItem.description : '',
        }
        const response = await sendEmailForAssistance(
            'DG_ULD',
            setIsLoading,
            attributes,
            replacements

        )
        setDisplayDgULDItemPopup(false)
        if (response) {
            setNotificationMessage(t(MESSAGES.ASSISTANCE_MSG, { ns: 'errors' }))
            setNotificationType(NOTIFICATION_MESSAGE_TYPE.INFO)
        } else {
            setNotificationMessage(
                t(MESSAGES.MAIL_ASSISTANCE_FAILS, { ns: 'errors' })
            )
            setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
        }
        window.scrollTo(0, 0)
        handleClear()
    }

    const getSccs = (additionalSccs) => {
        if (additionalSccs && additionalSccs.length) {
            return additionalSccs.map((e) => e.scc).toString()
        } else {
            return null
        }
    }

    const RenderFooter = () => {
        return (
            <div>
                <button className='button' onClick={handleConfirm}>
                    {t('COMMON.OK')}
                </button>
                <button className='button secondary' onClick={handleCancel}>
                    {t('COMMON.CANCEL')}
                </button>
            </div>
        )
    }

    return (
        <div className='cargo-portal-container dialog-container'>
            <div className='portal-dialog p-fluid'>
                <Dialog
                    footer={<RenderFooter />}
                    visible={displayDgULDItemPopup}
                    modal
                    closable={true}
                    header=''
                    onHide={handleCancel}
                >
                    <h3 className='mt-1 mb-4'>
                        {t('BOOKING.ASSISTANCE_HEAD')}
                        {/* {popupNotificationPopup.header} */}
                    </h3>
                    <p className='mt-2'>
                        {t(MESSAGES.ASSISTANCE_MSG_CONTAINER_DG, {
                            item: shippingItem?.description,
                            ns: 'errors',
                        })}
                        {/* {popupNotificationPopup.body} */}
                        {/* {additionalInfoData.pharma.activeContainerType ===
                            PHARMA_ACTIVE_TYPE.EY_ACTIVE
                            ? t(MESSAGES.ASSISTANCE_MSG_CONTAINER, {
                                item: t('BOOKING.EY_CONTAINER_AC'),
                                ns: 'errors',
                            })
                            : t(MESSAGES.ASSISTANCE_MSG_CONTAINER, {
                                item: t('BOOKING.EY_CONTAINER_HY'),
                                ns: 'errors',
                            })}  */}
                    </p>
                </Dialog>
            </div>
        </div>
    )
}

export default UldValidationPopupdg
