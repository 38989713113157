import {
	NUMERIC_REGEX,
	ALPHA_REGEX,
	FLIGHT_NUMBER_REGEX,
	FLIGHT_NUMBER_REGEX_SEASONAL,
	ALPHA_NUMERIC_REGEX,
	POSTAL_CODE_REGEX,
	NAME_REGEX,
	ALPHA_NUMERIC_REGEX_SEASONAL
} from '../constants/regex'

export const padLeadingZeros = (num, size) => {
	if (num) {
		var numberAfterAppend = num + ''
		while (numberAfterAppend.length < size) {
			numberAfterAppend = '0' + numberAfterAppend
		}
		return numberAfterAppend
	}
}

export const isNumericOnly = (value) => value && NUMERIC_REGEX.test(value)

export const isAlphaOnly = (value) => value && ALPHA_REGEX.test(value)

export const isAlphaNumeric = (value) =>
	value && ALPHA_NUMERIC_REGEX.test(value)

export const isAlphaNumericSeasonal = (value) => 
value && ALPHA_NUMERIC_REGEX_SEASONAL.test(value)

export const checkFlightFormat = (value) =>
	value && FLIGHT_NUMBER_REGEX.test(value)

	export const checkFlightFormatSeasonal = (value) =>
	value && FLIGHT_NUMBER_REGEX_SEASONAL.test(value)

export const validateName = (value) => value && NAME_REGEX.test(value)

export const validatePostalCode = (value) =>
	value && POSTAL_CODE_REGEX.test(value)
