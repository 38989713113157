import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { Dialog } from 'primereact/dialog'
// import infoIcon from '../../../assets/images/info_icon.svg'
import { faChevronRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import trashIcon from '../../../assets/images/trash.svg'
import { getPivotWeight, getULDTypeByCode } from '../../../services/searchPanelServices'
import ULDTypeAutocomplete from '../../ULDTypeAutocomplete'
import './item-form.scss'
import { allowNumberOnly } from '../../../utils/common'
import { useTranslation } from 'react-i18next'
import MESSAGES from '../../../constants/Messages'
// import { RadioButton } from 'primereact/radiobutton';

import { deleteAviPetAttachment } from "../../../services/aviBookingService";
import infoicon from '../../../../src/assets/images/infoicon.png';
import pdf from '../../../../src/assets/images/pdf.png';
import excel from '../../../../src/assets/images/excel.png';
import word from '../../../../src/assets/images/word.png';
import walpaper from '../../../../src/assets/images/walpaper.png';
import document from '../../../../src/assets/images/document.png';
import { NUMERIC_REGEX } from '../../../../src/constants/regex';
import './item-form.scss';

import closeIcon from '../../../assets/images/icon-close-alert.png'
import {
	AUTOCOMPLETE_TYPE,
	DIMENSION_UNIT,
	FORM_FIELDS,
	FRESH_FORWARD_PRODUCT,
	getInitLooseItemDetail,
	getInitPharmaObject,
	getInitULDItemDetail,
	PHARMA_PRODUCT,
	PHARMA_SHIPPING_TYPE,
	IS_KNOWN_UNKNOWN,
	PRICE_CODE_TYPE,
	SCC_CODES,
	SHIPPING_TYPE,
	VALIDATE_BOOKINGS_ERROR_CODES,
	WEIGHT_UNIT,
	NOTIFICATION_MESSAGE_TYPE,
	SYSTEM_ADDED_SCCS,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
	getInitPetDetail,
	WEIGHT_UNIT_SELECT_ITEMS,
	DIMENSION_UNIT_SELECT_ITEMS
} from '../../../constants'
import { SHIPPING_ITEM_PLACEHOLDER } from '../../../constants/Placeholders'
import PetDocUpload from "./PetDocUpload";
import ShippingItemValidationPopUp from '../ShippingItemValidationPopUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validateFieldValue } from '../../SearchPanel/formUtils'

import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
	getFromLocalStorage,
	setToLocalStorage,
} from '../../../utils/StorageUtils'

import ConfirmationPopUp from '../../../common/ConfirmationPopUp'


const MultiplePetItem = ({
	getToastSuccess,
	getToastError,
	// item,
	// index,
	removedRowCount,
	petList,
	setPetList,
	//handleItemChange,
	AlertProps,
	shippingItem,
	file,
	setFile,
	dogBreeds,
	setArrayListDoc,
	arrayListDoc,
	petOrder,
	stubNose,
	sccWarning,
	cageOrder,
	cageIndex,
	cageList,
	setDeletedPetIds,
	deletedPetIds,
	error,
	setError,
	breedRejected, 
	setBreedRejected,
}) => {

	const [breed, setBreed] = useState('')
	const [name, setName] = useState('')
	const [ageInYear, setAgeInYear] = useState('')
	const [ageInMonths, setAgeInMonths] = useState('')
	const [medicalCondition, setMedicalCondition] = useState('');
	// const [petOrder, setPetOrder] = useState(item.petOrder);
	const [petCode, setPetCode] = useState('');
	const [petKey, setPetKey] = useState('');
	const [id, setId] = useState(0);
	const [attachments, setAttachments] = useState([]);
	// const [weight, setWeight] = useState(item.weight)
	// const [uldVolume, setUldVolume] = useState(item.uldVolume)
	// const [pivotWeight, setPivotWeight] = useState(item.pivotWeight)
	//const [sccWarning, setSccWarning] = useState(null)
	const [documentRequired, setDocumentRequired] = useState({})
	// const [weightUnit, setWeightUnit] = useState(item.weightUnit);
	// const [measurement, setMeasurement] = useState('C');
	const [pieces, setPieces] = useState(1);
	const [snubNozedBoolean, setSnubNozedBoolean] = useState(false);
	const [otherBreed, setOtherBreed] = useState('')
	//For multilingual translation
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const { header, setHeader } = useState("Document Required")

	const CONTOUR_ITEMS = [
		{ label: t('BOOKING.MAIN_DECK'), value: 'MD' },
		{ label: t('BOOKING.LOWER_DECK'), value: 'LD' },
	]

	const [displayUploadSection, setDisplayUploadSection] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [fileName, setFileName] = useState('');
	const [filteredBreeds, setFilteredBreeds] = useState([...dogBreeds])
	const [text, setText] = useState('')

	// useEffect(() => {
	// 	setAttachments(petList[index].attachments)
	// }, [index])

	// useEffect(() => {
	// 	handleItemChange(0, {
	// 		breed,
	// 		name,
	// 		ageInYear,
	// 		ageInMonths,
	// 		medicalCondition,
	// 		petOrder,
	// 		petCode,
	// 		petKey,
	// 		id,
	// 		stubNose,
	// 		pieces,
	// 		cageOrder,
	// 		attachments,
	// 	})
	// }, [
	// 	breed,
	// 	name,
	// 	ageInYear,
	// 	ageInMonths,
	// 	medicalCondition,
	// 	petOrder,
	// 	petCode,
	// 	petKey,
	// 	id,
	// 	stubNose,
	// 	pieces,
	// 	attachments,
	// ])

	const myRef = React.createRef()


	useEffect(() => {
		// let data = dogBreeds.filter((item) => item.breedCode === breed)
		// if (data.length > 0 && data[0].snubNosed === "Y") {
		// 	setSnubNozedBoolean(true)
		// }
		// else {
		// 	setSnubNozedBoolean(false)
		// }

		// if ((data.length > 0) && (data[0].eyEmbargo === "Y")) {
		// 	setBreedRejected(true)
		// }
		// else {
		// 	setBreedRejected(false)
		// }
	}, [breed])

	const isSnubNozed = (breed) => {
		if(breed.snubNosed === 'Y'){
			return  MESSAGES.SNUB_NOSED;
		}
		else{
			return null;
		}
	}

	const isEyEmbargoRestricted = (breed) => {
		if(breed.eyEmbargo === 'Y'){
			return MESSAGES.PET_EYEMBARGO_ERROR_MSG;
		}
		else{
			return null;
		}
	}

	const handleItemChange = (key, value, index) => {

		const cageListJson = getFromSessionStorage('cageList')
		let tempPetDetails;
		let petDocs = [...petList]

		if (cageListJson) {
			const cageListData = JSON.parse(cageListJson)
			if (cageListData[cageIndex].pets && cageListData[cageIndex].pets.length > 0) {
				tempPetDetails = [...cageListData[cageIndex].pets]
			}
		}
		tempPetDetails[index][key] = value
		setPetList([...tempPetDetails], cageIndex)
	}


	const handleAttachment = (petOrder, res, attachments) => {
		let attachmentArrayList = [...attachments, res]
		handleItemChange('attachments', attachmentArrayList, petOrder)
	}

	const clearError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.CAGE_ITEMS] &&
			tempError[FORM_FIELDS.CAGE_ITEMS][index]
		) {
			tempError[FORM_FIELDS.CAGE_ITEMS][index][key] = null
			setError(tempError)
		}
	}

	const handleError = (key, errorMsg, index) => {
		const tempError = error ? { ...error } : {}
		let cageErrors =
			error && error[FORM_FIELDS.CAGE_ITEMS]
		if (!cageErrors) {
			cageErrors = []
			cageErrors[cageIndex] = cageErrors[cageIndex]
				?  cageErrors[cageIndex]
				: { [FORM_FIELDS.PETS]: [] }
		}
		if (cageErrors) {

			if (!cageErrors[cageIndex]) {
				cageErrors[cageIndex] = cageErrors[cageIndex]
					?  cageErrors[cageIndex]
					: { [FORM_FIELDS.PETS]: [] }
			}

			let errorObjPetsList = error &&
			cageErrors[cageIndex] &&
			cageErrors[cageIndex][FORM_FIELDS.PETS] ? cageErrors[cageIndex][FORM_FIELDS.PETS] : null
			if (!errorObjPetsList) {
				errorObjPetsList = []
				errorObjPetsList[0] = { [FORM_FIELDS.BREED]: [] }
			}

			const errorObjPets = errorObjPetsList && errorObjPetsList[index] ? errorObjPetsList[index] : {};
			
			errorObjPets[key] = errorMsg
			
			errorObjPetsList[index] = errorObjPets
			cageErrors[cageIndex][FORM_FIELDS.PETS] = errorObjPetsList;
			setError({ ...error, [FORM_FIELDS.CAGE_ITEMS]: cageErrors })
		}
	}

	useEffect(() => {
		setFilteredBreeds([...dogBreeds])
	}, [])

	useEffect(() => {
		setFilteredBreeds([...dogBreeds])
	}, [dogBreeds])

	const searchBreeds = (event) => {
		let text = event.query
		if (text.length > 2) {
			let breedsByNameStart;
			breedsByNameStart =
				dogBreeds && dogBreeds.length && text && text.length
					? [...dogBreeds].filter((breed) => {
						return ` ${breed.breedName.toLowerCase()}`.includes(
							` ${text.toLowerCase()}`
						)
					})
					: []

			let dropDownValues = breedsByNameStart.map((item) => item)
			setFilteredBreeds([...dropDownValues])
		} else {
			setFilteredBreeds([...dogBreeds])
		}
	}



	const closeDialog = () => {
		setDisplayInfo(false)
	}


	useEffect(() => {

		listArray?.map((element) => {
			setArrayListDoc([...arrayListDoc, element])
		})

	}, [file])



	const listArray = [...file]
	// useEffect(() => {
	// 	listArray?.map((element) => {
	// 		setArrayListDoc([...arrayListDoc, element])
	// 	})

	// }, [file])

	// useEffect(() => {
	// 	console.log(docUploadIndex,'docUploadIndex')
	// },[docUploadIndex])




	const customClose = (
		<i>
			<img src={closeIcon} alt='close' title='close' onClick={closeDialog} />
		</i>
	)

	const setDropDownFileList = (list) => {
		setFileList(list)
	}

	const handelDocRequired = (event) => {

		setDocumentRequired((prevState) => ({
			...prevState,
			[documentRequired.id]: true,
		}))
	}

	const handleAddLineItem = (e, petIndex, cageIndex) => {
		e.preventDefault();
		const cageListJson = getFromSessionStorage('cageList')
		if (cageListJson) {
			const cageListData = JSON.parse(cageListJson)
			let petData = [...cageListData[cageIndex].pets, getInitPetDetail()]
			petData[petIndex + 1].petOrder = petIndex + 1
			//petData[petIndex + 1].cageOrder = cageIndex 
			const filteredPetData = petData.map((item) => {
				item.cageOrder = cageIndex
				return item
			})
			setPetList(filteredPetData, cageIndex)
		}

	}


	const handleRemoveItem = (indexNum, item) => {
		if (item.id) {
			let idObj = {
				'id': item.id
			}
			setDeletedPetIds([...deletedPetIds, idObj]);
		}
		const itemsTemp = petList.filter((itemDetail, curr) => {
			return item.id ? indexNum !== itemDetail.id : indexNum !== curr
		})
		// const docdelete = arrayListDoc.filter((itemDetail, curr) => {

		// 	return index !== curr
		// })
		//setArrayListDoc([docdelete])

		setPetList([...itemsTemp], cageIndex)
	}

	const handleRemovepet = (element, ind, petIndex) => {
		const data = {
			"attachmentId": element.attachmentId,
			"fileName": element.fileName
		}

		deleteAviPetAttachment(data).then((res) => {

			try {
				if (res === true) {
					// setLoading(false);
					//call toaster
					// getToastSuccess("File Deleted");
					// myToast.current.show({ ...toastDetails, life: 4000 })
					const itemsTemp = petList[petIndex].attachments.filter((itemDetail, curr) => {
						return ind !== curr
					})

					//setAttachments([...itemsTemp])
					handleItemChange('attachments', itemsTemp, petIndex)

				}

			} catch (error) {
				//setLoading(false);
				// setFile(null);
				// getToastError("Failed to upload file");
				//console.log("errr", error);
			}
		})

		//handleSummaryChange()
		//setRemovedRowCount(removedRowCount * 1 + 1)
	}

	useEffect(() => {
		setError([FORM_FIELDS.CAGE_ITEMS], null)
		if (shippingItem && shippingItem.key) {
			setPetCode(shippingItem.code)
			setPetKey(shippingItem.key)
		}

	}, [shippingItem])

	const [displayInfo, setDisplayInfo] = useState(false)

	const handelPopup = () => {
		setDisplayInfo(true)
	}

	const handleSelect = (value) => {
		if (value) {
			setBreed(value.breedName)
		}
	}

	const handlePetValidation = (breed) => {
		const isEmbargoError = isEyEmbargoRestricted(breed)
		const snubNosedError = isSnubNozed(breed)
		return isEmbargoError ? isEmbargoError : snubNosedError ? snubNosedError : null
	}



	return (
		<>
			{
				petList.map((item, index) => {
					const errorObjPets = error &&
						error[FORM_FIELDS.CAGE_ITEMS] &&
						error[FORM_FIELDS.CAGE_ITEMS][cageIndex] &&
						error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.PETS] &&
						error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.PETS][index] ? error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.PETS][index] : null;
					const breedError =
						errorObjPets &&
							errorObjPets[FORM_FIELDS.BREED]
							? errorObjPets[FORM_FIELDS.BREED]
							: null
					const ageInYearError =
						errorObjPets &&
							errorObjPets[FORM_FIELDS.AGE_YEARS] && errorObjPets[FORM_FIELDS.AGE_MONTHS]
							? errorObjPets[FORM_FIELDS.AGE_YEARS]
							: null
					const ageInMonthsError =
						errorObjPets &&
							errorObjPets[FORM_FIELDS.AGE_MONTHS] && errorObjPets[FORM_FIELDS.AGE_YEARS]
							? errorObjPets[FORM_FIELDS.AGE_MONTHS]
							: null
					return(
					<div className='form-row search-panel__data pet-array-data'>
						<div
							className={`form-group col-12`}>
							<div className="row">
								<label className="pet-style">{
									`${t('COMMON.PET_HEADING', {
										index: index + 1,
									})}`
								}</label>

								{petList.length > 1 ? (
									<div className='form-group col-4 col-lg-1 delete-div'>
										<div className='uld-form delete-section'>
											<img
												src={trashIcon}
												onClick={(e) => handleRemoveItem(item.id ? item.id : index, item)}
												alt='Trash'
												title='Trash'
											/>
											<span>{t('COMMON.DELETE')}</span>
										</div>
									</div>
								) : (
									<></>
								)}
							</div>


							{/* {(
						stubNose !== null ?
							(stubNose === true ?
								<>
									<div className='row'>
										<div className='col-md col-12 warning-info'>
											<Message
												severity='warn'
												text={"Sorry! Snub nosed pets are not allowed on Etihad"}
											></Message>
										</div>
									</div>
								</>

								: */}
							<>
								{/* <div className='form-row pl-2 m-b-0 mt-0'> */}
								<div className='form-row'>
									<div
										className={`col-12 col-lg-3 form-group ${breedError? 'red-border' : ''
											}`}

									>

										<label>{t('COMMON.SELECT_BREED_LABEL')}</label>

										{/* <Dropdown
									placeholder='Breed'
									className='breedSelect'
									options={dogBreeds}
									optionLabel={'breedName'}
									optionValue={'breedCode'}
									value={item.breed}
									onChange={(e) => {
										let value= e.target.value
										setOtherBreed(e.target.value)
										handleItemChange('breed',value,index)
									}}
								/> */}
										<AutoComplete
											id='autocomplete-breedlist'
											placeholder='Breed'
											name='breedName'
											className='breedSelect autocomplete-petlist'
											field="breedName"
											dropdown
											completeMethod={searchBreeds}
											ref={myRef}
											minLength={1}
											suggestions={filteredBreeds}
											value={item.breed ? item.breed : text}
											onChange={(e) => {
												setText(e.target.value)
												//setBreed(null)
												handleItemChange('breed', null, index)
											}}
											onSelect={(e) => {
												//let errorObj = item.error ? item.error : {}
												setText('')
												//handleSelect(e.value)
												setBreed(e.value.breedName)
												handleItemChange('breed', e.value.breedName, index)
												handleItemChange('otherBreed', null, index)
												handleItemChange('snubNosed', e.value.snubNosed, index)

												if(item?.error){
													item.error.snubNosed = isSnubNozed(e.value)
												}
												else{
													item.error = {}
													item.error.snubNosed = isSnubNozed(e.value)
												}
												handleItemChange('error',
													item.error
													, index)
												handleError([FORM_FIELDS.BREED], isSnubNozed(e.value), index)
											}}
											onFocus={(e) => {
												let errorObj = item.error ? item.error : {}
												errorObj.breed = null
												handleItemChange('error',
													errorObj
													, index)
												if(!errorObj.snubNosed ) {
													handleError([FORM_FIELDS.BREED], errorObj.breed, index)
												}
											}}
											onBlur={(e) => {
												let errorObj = item.error
												errorObj.breed = validateFieldValue(e.target.value, t(MESSAGES.VALID_BREED))
												handleItemChange('error',
													errorObj
													, index)
												if(!errorObj.snubNosed ) {
													handleError([FORM_FIELDS.BREED], errorObj.breed, index)
												}

											}}
										/>

										{/* {
								breedRejected ?
									<>
										<div className='row'>
											<div className='col-md col-12 warning-info'>
												<Message
													severity='warn'
													text={"Sorry, we do not carry this breed onboard Etihad."}
												></Message>
											</div>
										</div>
									</> :
									snubNozedBoolean ?
										<>
											<div className='row'>
												<div className='col-md col-12 warning-info'>
													<Message
														severity='warn'
														text={" Sorry, we do not carry snub nosed pets onboard Etihad."}
													></Message>
												</div>
											</div>
										</> : ''
							} */}		
											<div>
												<span className='search-panel__errormsg mt-4'>
													{breedError ? t(breedError, { ns: 'errors' }) : ''}
												</span>
											</div>
									</div>
									{item.breed === "Other" ?
										<div
											className={`col-12 col-lg-3 ${error && error[FORM_FIELDS.SHIPPING_ITEM] ? 'red-border' : ''
												}`}
										>
											<label >{t('COMMON.OTHER_LABEL')}</label>

											<input
												type='text'
												placeholder={"Specify the breed"}
												value={item.otherBreed ? item.otherBreed : ''}
												className={`form-item__field-draft breedSelect breedName`}
												onChange={(e) => {
													let value = e.target.value
													setOtherBreed(e.target.value)
													handleItemChange('otherBreed', value, index)
												}
												}
												onFocus={(e) => {
													let errorObj = item.error ? item.error : {}
													errorObj.otherBreed = null
													handleItemChange('error',
														errorObj
														, index)
												}}
												onBlur={(e) => {
													let errorObj = item.error ? item.error : {}
													errorObj.otherBreed = validateFieldValue(e.target.value, t(MESSAGES.VALID_OTHER_BREED))
													handleItemChange('error',
														errorObj
														, index)
												}}

											/>

											{
												item?.error?.otherBreed ?
													<span className='search-panel__errormsg'>{t(item.error.otherBreed, { ns: 'errors' })}</span> :
													''
											}
												<div>
													<span className='search-panel__errormsg mt-4'>
														{breedError ? t(breedError, { ns: 'errors' }) : ''}
													</span>
												</div>
										</div>
										: ''
									}
									<div
										className={`col-12 col-lg-3 form-group ${error && error[FORM_FIELDS.SHIPPING_ITEM] ? 'red-border' : ''
											}`}
									>

										<label >{t('COMMON.NAME_LABEL')}</label>

										<input
											type='text'
											placeholder={"Name"}
											value={item.name}
											className={`form-item__field-draft breedSelect breedName`}
											onChange={(e) => {
												setName(e.target.value)
												let value = e.target.value
												handleItemChange('name', value, index)
											}
											}

										/>

										<span className='search-panel__errormsg'>{sccWarning}</span>

									</div>

									

										{/* <label className={`${ageInYear <= 0 ? 'additional-style yearslabel' : 'additional-style '}`}> {ageInYear <= 0 || ageInYear == null ? "" : "-Years"}</label> */}


											<div className={`input-wrapper age-input  col-6 col-lg-2 ${ageInYearError ? 'red-border' : ''}`} >
												<label className='additional-style '>{t("COMMON.AGE")}</label>

												<span className="yearSpan" >{item.ageInYear ?  t('COMMON.YEARS') : ""}</span>
												<input
													type='text'
													placeholder={t('COMMON.YEARS')}
													value={item.ageInYear <= 0 ? '' : item.ageInYear}
													onKeyDown={(evt) => allowNumberOnly(evt)}
													onChange={(e) => {
														const value = e.target.value.replace(/[^0-9.]/g, '');
														if (value.length <= 2 && value >= 0 && value <= 99) {
															let value = Number(e.target.value)
															setAgeInYear(Number(e.target.value))
															handleItemChange('ageInYear', value, index)
														}
													}
													}
													className='form-control'
													maxLength="7"
													onFocus={(e) => {
														let errorObj = item.error ? item.error : {}
														errorObj.ageInYears = null
														if(Number(item.ageInMonths) > 0){
															errorObj.ageInMonths = null
														}
														handleItemChange('error',
															errorObj
															, index)
														handleError([FORM_FIELDS.AGE_YEARS], errorObj.ageInYears, index)
														handleError([FORM_FIELDS.AGE_MONTHS], errorObj.ageInMonths, index)

													}}
													onBlur={(e) => {
														let errorObj = item.error ? item.error : {}
														errorObj.ageInYears = ((item.ageInMonths === 0) || (item.ageInMonths === '') || (!item.ageInMonths)) ?
														validateFieldValue(e.target.value, MESSAGES.VALID_YEARS) : null 
														if(item.ageInMonths > 0 || item.ageInYear > 0){
															errorObj.ageInMonths = null
														}
														handleItemChange('error',errorObj, index) 
														handleError([FORM_FIELDS.AGE_YEARS], errorObj.ageInYears, index)
														handleError([FORM_FIELDS.AGE_MONTHS], errorObj.ageInMonths, index)
													}}

												/>
												<div>
														<div>
															<span className='search-panel__errormsg mt-4'>
																{ageInYearError ? t(ageInYearError, { ns: 'errors' }) : ''}
															</span>
														</div>
											</div>
											</div>


												<div className={`col-6 col-lg-2 age-input input-wrapper ${ageInMonthsError? 'red-border' : ''}`} >
													<span className="monthsSpan" >{item.ageInMonths ? t('COMMON.MONTHS') : ""}</span>
													<input
														type='text'
														placeholder={t('COMMON.MONTHS')}
														value={item.ageInMonths <= 0 ? "" : item.ageInMonths}
														onKeyDown={(evt) => allowNumberOnly(evt)}
														onChange={(e) => {
															const value = e.target.value.replace(/[^0-9.]/g, '');
															if (value.length <= 2 && value >= 0 && value <= 12) {
																//let value= Number(e.target.value)
																setAgeInMonths(Number(value))
																handleItemChange('ageInMonths', Number(value), index)
															}

														}}
														// onChange={(e) => setAgeInMonths(Number(e.target.value))}
														className="form-control age-months"
														maxLength="2"
														onFocus={(e) => {
															let errorObj = item.error ? item.error : {}
															errorObj.ageInMonths = null
															if(item.ageInYear > 0){
																errorObj.ageInYears = null
															}
															handleItemChange('error',
																errorObj
																, index)
															handleError([FORM_FIELDS.AGE_YEARS], errorObj.ageInYears, index)
															handleError([FORM_FIELDS.AGE_MONTHS], errorObj.ageInMonths, index)
														}}
														onBlur={(e) => {
															let errorObj = item.error ? item.error : {}
															errorObj.ageInMonths = ((item.ageInYear === 0) || (item.ageInYear === '') || (!item.ageInYear)) ?
															validateFieldValue(e.target.value, MESSAGES.VALID_MONTHS) : null
															if(item.ageInMonths > 0 || item.ageInYear > 0){
																errorObj.ageInYears = null
															}
															handleItemChange('error',
																errorObj
																, index)
															handleError([FORM_FIELDS.AGE_YEARS], errorObj.ageInYears, index)
															handleError([FORM_FIELDS.AGE_MONTHS], errorObj.ageInMonths, index)	
														}}

													/>
													
														<div>
															<span className='search-panel__errormsg mt-4'>
																{ageInMonthsError ? t(ageInMonthsError, { ns: 'errors' }) : ''}
															</span>
														</div>

												</div>

								</div>
								<div className='form-row'>
									<div className={`col-sm-12 inputmedical mt-2`}>
										
										<label className="additionalinstruction">{t('COMMON.SPECIAL_INSTRUCTION')}</label>

											<input type="text"
												placeholder={"Medical condition/Pregnancy/etc"}
												className={"form-control inputopt input-wrappe"}
												onChange={(e) => {
													//setMedicalCondition(e.target.value)
													let value = e.target.value
													setMedicalCondition(value)
													handleItemChange('medicalCondition', value, index)
												}
												}
												maxLength="256"
												rows="1"
												value={item.medicalCondition}

												// onFocus={(e) => {
												// 	let errorObj = item.error ? item.error : {}
												// 	errorObj.specialInstructions = null
												// 	handleItemChange('error',
												// 		errorObj
												// 		, index)
												// }}
												// onBlur={(e) => {
												// 	let errorObj = item.error ? item.error : {}
												// 	errorObj.specialInstructions = validateFieldValue(e.target.value, MESSAGES.SPECIAL_INSTRUCTIONS)
												// 	handleItemChange('error',
												// 		errorObj
												// 		, index)
												// }}
											/>
										{/* {
											item?.error?.specialInstructions ?
												<span className='search-panel__errormsg ml-3'>{t(item.error.specialInstructions, { ns: 'errors' })}</span> :
												''
										} */}
									</div>

								</div>
								{/* </div> */}

								{/* </div> */}

								<div className='row'>
									<div className='form-group col-12 col-md-4 mt-4'>

										<label className='mb-0 docstyle'>
											{t('COMMON.DOCUMENTS')}
										</label>

										<div>
											<label className='filePetUpload' >
												{petList[index].attachments.length !== 0 ? t('COMMON.FILES_UPLOADED_TEXT') : t('COMMON.UPLOAD_ALL_DOC_TEXT')}

											</label>
											<i className="circle-padding" >
												<img
													src={infoicon}
													onClick={handelPopup}
													alt='Allowed list'
													title='Allowed list'
													className="circle-padding"
												/>
											</i>
										</div>

										{/* {file?.length > 0 ? */}

										<ul>
											{
												petList[index]?.attachments?.map((item, i) => {
													const filArraypath = item?.fileName?.split('.')
													return (

														<li className="listyle">

															<div className="listupload" key={item.petOrder}>

																<img src={filArraypath && filArraypath[1] === "pdf" ? pdf :filArraypath[1]==="xls" || filArraypath[1] === "xlsx" ? excel : filArraypath[1] === "docx" ? word : filArraypath[1] === "png" || filArraypath[1] === "jpeg" ? walpaper : document} alt={filArraypath[1]} title={filArraypath[1]} className="fileicon" />
																<span> {item.fileName}</span>
															</div>

															<div className='deleteicon' >

																<span onClick={(e) => handleRemovepet(item, i, index)}>{t('COMMON.DELETE')}</span>

															</div>
														</li>

													)

												})
											}





										</ul>


										{/* {
												<ul>
													{file?.foreach((item, index) => {
														<div className='filename-upload uploadfile'>
															<li className=''>{item?.filename}</li>
														</div>

													})
													}
												</ul>
											} */}

										{displayInfo ? (
											<div className='confirmation-dialog-infopet'>
												<Dialog
													// footer={modalFooter}
													visible
													closable={false}
													modal
													icons={customClose}
													header={"Document Required"}
													className='modal-dialog infopet'

												>
													{<>

														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>

															<label className='mb-0' htmlFor={`docReq9`}>
																<li>Health Certificate (by government accredited VET)</li>
															</label>

														</div>


														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


															<label className='mb-0' htmlFor={`docReq2`}>
																<li>Fit to travel certificate (from local vet within 10 days of travel)</li>
															</label>

														</div>
														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>

															<label className='mb-0' htmlFor={`docReq3`}>
																<li>Vaccination certificate (completed in full)</li>
															</label>

														</div>
														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


															<label className='mb-0' htmlFor={`docReq4`}>
																<li>Internal and External parasite treatment</li>
															</label>

														</div>

														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>

															<label className='mb-0' htmlFor={`docReq5`}>
																<li>Rabies vaccine (EU 21 days, other countries 30 days before departure)</li>
															</label>

														</div>

														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


															<label className='mb-0' htmlFor={`docReq6`}>
																<li>RNATT-Rabies titer test (check with local authority if applicable)</li>
															</label>

														</div>


														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


															<label className='mb-0' htmlFor={`docReq7`}>
																<li>Screwworm / tapeworm treatment</li>
															</label>

														</div>

														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


															<label className='mb-0' htmlFor={`docReq8`}>
																<li>Export permit (check with local authority if applicable)</li>
															</label>

														</div>

														<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>


															<label className='mb-0' htmlFor={`docReq9`}>
																<li>Import Permit (check with local authority if applicable)</li>
															</label>

														</div>

													</>}
												</Dialog>
											</div>


										) : (
											<></>
										)}


										<div className='mt-2 mb-3 search-panel__type pl-0 pr-0'>

											<div className='row mb-0'>
												<PetDocUpload setDisplayUploadSection={setDisplayUploadSection}
													setDropDownFileList={setDropDownFileList}
													fileList={fileList}
													// setListPageOpen={setListPageOpen}
													setFileName={setFileName}
													setFileList={setFileList}
													fileName={fileName}
													index={index}
													file={file}
													setFile={setFile}
													fileCount={arrayListDoc[index] ? true : false}
													// arrayListDoc={arrayListDoc?.length > 0 ? arrayListDoc : []}
													arrayListDoc={arrayListDoc}
													setArrayListDoc={setArrayListDoc}
													// handleFileNameSelect={handleFileNameSelect}
													attachments={attachments}
													setAttachments={setAttachments}
													cageIndex={cageIndex}
													petList={petList}
													cageList={cageList}
													handleAttachment={handleAttachment}
												/>
											</div>


										</div>
										<b></b>


									</div>
								</div>
								{
									(petList.length >= 3) || (petList.length - 1 !== index) ?
										<>
											{petList.length - 1 !== index ?
												<div style={{ borderTop: "1px solid grey" }}>
												</div>
												: <></>
											}
										</> :
										<div className='form-row'>
											<div className='form-group col-12 col-lg-10 m-b-0'>
												<Button
													label={t('COMMON.ADD_ANOTHER_PET')}
													// icon='pi pi-plus'
													className='d-block custom-xs button__another adanotherPet '
													type='button'
													onClick={(e) => handleAddLineItem(e, index, cageIndex)}
												/>
											</div>
										</div>
								}
							</>




						</div>
					</div >
				)})
			}
		</>

	)
}

export default MultiplePetItem
