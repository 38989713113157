import { validateNumericIfPresent } from '../../CaptureHawb/CaptureHawbValidations'
import { checkFlightFormat, isAlphaOnly } from '../../../utils/Validations'
import Messages from '../../../constants/Messages'
export const allowNumricOnly = (evnt) => {
	let isNumber
	evnt = evnt ? evnt : window.event
	let charCode = evnt.which ? evnt.which : evnt.keyCode
	if (charCode) {
		isNumber = charCode > 31 && (charCode < 48 || charCode > 57)
	}
	return isNumber && evnt.preventDefault()
}

export const allowAlphaOnly = (evnt) => {
	let isAlpha
	evnt = evnt ? evnt : window.event
	let charCode = evnt.which ? evnt.which : evnt.charCode
	if (charCode) {
		isAlpha =
			(charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)
	}
	return !isAlpha && evnt.preventDefault()
}

export const formatInputTypeNumber = (evnt) => {
	let checkIfNum
	if (evnt.key !== undefined) {
		checkIfNum =
			evnt.key === 'e' ||
			evnt.key === 'E' ||
			evnt.key === '.' ||
			evnt.key === '+' ||
			evnt.key === '-' ||
			evnt.key === ','
	} else if (evnt.keyCode !== undefined) {
		checkIfNum =
			evnt.keyCode === 69 ||
			evnt.keyCode === 190 ||
			evnt.keyCode === 187 ||
			evnt.keyCode === 189 ||
			evnt.keyCode === 190
	}
	return checkIfNum && evnt.preventDefault()
}

export const restrictSpaceSpecial = (e) => {
	let isSpecialCharacter
	let code = e.which ? e.which : e.keyCode
	if (code) {
		isSpecialCharacter =
			(code > 64 && code < 91) ||
			(code > 96 && code < 123) ||
			code === 8 ||
			code === 61 ||
			(code >= 48 && code <= 57)
		return !isSpecialCharacter && e.preventDefault()
	}
}

export const removeError = (event, setError) => {
	const id = event.target.id + ''
	setError((prevState) => ({ ...prevState, [id]: '' }))
}

export const hasError = (key, error) => {
	if (error !== null && error[key] && error[key] !== '') {
		return true
	} else {
		return false
	}
}

export const validateAWB = (value, setError, key) => {

	// setShowAwbValidation(false)
	if (value && value !== null && value.trim() !== '') {
		if (value.length < 8) {
			setError((prevState) => ({
				...prevState,
				[key]: Messages.INVALID_AWB,
			}))
			return false
		}
		if (value.length == 8) {
			if (value.slice(0, -1) % 7 != value[7]) {
				setError((prevState) => ({
					...prevState,
					[key]: Messages.INVALID_AWB,
				}))
				return false
			} else {
				return true
			}
		}
	} else {
		return true
	}
}

export const validateAwbPrefixRequired = (
	awbNumber,
	awbPrefix,
	setError,
	key
) => {
	if (awbNumber && awbNumber !== null && awbNumber.trim() !== '') {
		if (awbPrefix) {
			if (awbPrefix.length < 3) {
				setError((prevState) => ({
					...prevState,
					[key]: Messages.ENTER_VALID_AWB_PREFIX,
				}))
				return false
			}
			return validateNumericIfPresent(awbPrefix, setError, key)
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: Messages.ENTER_AWB_PREFIX,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateFlightNoRegex = (flightNumber, carrierCode, setError) => {
	if (flightNumber && flightNumber !== null && flightNumber.trim() !== '') {
		if (!checkFlightFormat(flightNumber)) {
			setError((prevState) => ({
				...prevState,
				flightNumber: Messages.INVALID_FLIGHT_NUMBER,
			}))
			return false
		}
		else{
			if (
				checkFlightFormat(flightNumber) &&
				(carrierCode === null || !isAlphaOnly(carrierCode))
			) {
				setError((prevState) => ({
					...prevState,
					carrierCode: Messages.ENTER_CARRIER_CODE,
				}))
				return false
			}
			else{
				return true
			}
		}
	} else {
		return true
	}
}

export const validateCarrierCodeRequired = (
	flightNumber,
	carrierCode,
	setError,
	key
) => {
	if (flightNumber && flightNumber !== null && flightNumber.trim() !== '') {
		if (carrierCode) {
			if (carrierCode.length < 2) {
				setError((prevState) => ({
					...prevState,
					[key]: Messages.ENTER_VALID_CARRIER_CODE,
				}))
				return false
			}
			return isAlphaOnly(carrierCode)
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: Messages.ENTER_CARRIER_CODE,
			}))
			return false
		}
	} else {
		return true
	}
}
