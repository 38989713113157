import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
const AgentDetails = ({ awbDetails }) => {
	//for multilingual
	const { t } = useTranslation(['labels'])

	let agentDetails = awbDetails.agentDetails

	useEffect(() => {}, [])

	return (
		<>
			<div className='pt-3 pb-2 section-seperator'>
				<hr />
			</div>
			<div className='form-row cutom-details agent-details'>
				<div className='col-12 col-md-11'>
					<div className='form-row mb-3'>
						<div className='col-12'>
							<h4 className='section-heading'>{t('CAPTURE_AWB.AGT_DTLS')}</h4>
						</div>
					</div>
					<div className='form-row mb-3'>
						<div
							className='col-6 col-lg-3 form-item form-item--text form-item--disabled'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>{t('COMMON.AGT_CODE')}</label>
							<input
								type='text'
								readOnly
								className='form-item__field'
								value={agentDetails.code}
								maxLength='25'
							/>
						</div>
						<div
							className='col-6 col-lg-3 form-item form-item--text form-item--disabled'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>
								{t('CAPTURE_AWB.AGT_NAME')}
							</label>
							<input
								type='text'
								readOnly
								className='form-item__field'
								value={agentDetails.name}
								maxLength='25'
							/>
						</div>
						<div
							className='col-6 col-lg-3 form-item form-item--text form-item--disabled'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>
								{t('CAPTURE_AWB.IATA_CODE')}
							</label>
							<input
								type='text'
								readOnly
								className='form-item__field'
								value={agentDetails.IATACode}
								maxLength='25'
							/>
						</div>
						<div
							className='col-6 col-lg-3 form-item form-item--text form-item--disabled'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>
								{t('CAPTURE_AWB.STAN')}
							</label>
							<input
								type='text'
								readOnly
								className='form-item__field'
								value={agentDetails.stationCode}
								maxLength='25'
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='pt-2 pb-3 section-seperator'>
				<hr />
			</div>
		</>
	)
}

export default AgentDetails
