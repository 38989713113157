import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const getListUserResponse = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getUserListUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}
export const updateUserStatus = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.updatedUserStatusUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}
