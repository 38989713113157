import axios from 'axios'
import { getHeaders, portalAuthHttpClient } from '../utils/HttpRequestUtils'
import { TIMEOUT_TIME } from '../constants'
import envConfigs from '../configs/envConfigs'

export const getSaveCommodityResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveCommodityUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getSaveAirportMasterResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveAirportMasterUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getSaveServiceMasterResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveServiceMasterUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getSaveProductMasterResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveProductMasterUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}
export const getSaveSccMasterResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveSccMasterUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getTermsConditionsResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.getTermsConditions}`
	// const response = await axios
	//     .get(url)
	//     .set(headers)
	//     .timeout(TIMEOUT_TIME)
	//     .send(requestBody)
	// return response.body
	const response = await portalAuthHttpClient.get(url, data)
	return response.data
}

export const getTermsConditionsByLocationResponse = async (queryText) => {
	const url = `${envConfigs.baseUrl}${envConfigs.termsAndConditionByLocation}/${queryText}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getTermsCountries = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCountriesUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const saveTermsConditionsResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveTermsConditions}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const saveUldTypeMaster = async (payload) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveUldTypeMaster}`
	const response = await portalAuthHttpClient.post(url, payload)
	return response.data
}

export const savePivotInfoMaster = async (payload) => {
	const url = `${envConfigs.baseUrl}${envConfigs.savePivotInfoMaster}`
	const response = await portalAuthHttpClient.post(url, payload)
	return response.data
}

export const saveSccIncompatibleMaster = async (payload) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveSccIncompatibilityMaster}`
	const response = await portalAuthHttpClient.post(url, payload)
	return response.data
}

export const saveStationScheduleMaster = async (payload) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveStationScheduleMaster}`
	const response = await portalAuthHttpClient.post(url, payload)
	return response.data
}

export const saveRegionMaster = async (payload) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveRegionMaster}`
	const response = await portalAuthHttpClient.post(url, payload)
	return response.data
}

export const saveCountryMaster = async (payload) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveCountryMaster}`
	const response = await portalAuthHttpClient.post(url, payload)
	return response.data
}


export const getSaveEmbargoResponse = async (payload) => {
	let data = payload
	const url = `${envConfigs.baseUrl}${envConfigs.saveEmbargoMasterUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

