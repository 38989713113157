export default {
	EY_ACTIVE_CONTAINER: {
		body: 'EY_ACTIVE_CONTAINER.ASSISTANCE_MAIL_BODY',
		subject: 'EY_ACTIVE_CONTAINER.ASSISTANCE_MAIL_SUB',
	},
	EY_HYBRID_CONTAINER: {
		body: 'EY_HYBRID_CONTAINER.ASSISTANCE_MAIL_BODY',
		subject: 'EY_HYBRID_CONTAINER.ASSISTANCE_MAIL_SUB',
	},
	SHIPPING_ITEM: {
		body: 'SPCL_PDCT_COMMO.ASSISTANCE_MAIL_BODY',
		subject: 'SPCL_PDCT_COMMO.ASSISTANCE_MAIL_SUB',
	},
	DG_SP: {
		body: 'DG_SPECIAL_PROV_EXCPTN.ASSISTANCE_MAIL_BODY',
		subject: 'DG_SPECIAL_PROV_EXCPTN.ASSISTANCE_MAIL_SUB',
	},
	DG_ULD: {
		body: 'DG_ULD_EXCPTN.ASSISTANCE_MAIL_BODY',
		subject: 'DG_ULD_EXCPTN.ASSISTANCE_MAIL_SUB',
	},
	OVER_BOOKING: {
		body: 'OVER_BOOKING_EXCPTN.MAIL_BODY',
		subject: 'OVER_BOOKING_EXCPTN.MAIL_SUBJECT',
	},
	ZERO_RATE_BOOKING: {
		body: 'NO_RATES_EXCPTN.MAIL_BODY',
		subject: 'NO_RATES_EXCPTN.MAIL_SUBJECT',
	},
	OHG_BOOKING: {
		body: 'OHG_SHPMNT_EXPTN.MAIL_BODY',
		subject: 'OHG_SHPMNT_EXPTN.MAIL_SUBJECT',
	},
	BIG_BOOKING: {
		body: 'BIG_SHPMNT_EXCPTN.MAIL_BODY',
		subject: 'BIG_SHPMNT_EXCPTN.MAIL_SUBJECT',
	},
	SPOT_RATE_BOOKING: {
		body: 'AD_HOC_RATE_EXCPTN.MAIL_BODY',
		subject: 'AD_HOC_RATE_EXCPTN.MAIL_SUBJECT',
	},
	QUEUED_BOOKING: {
		body: 'QUEUED_BOOKING.MAIL_BODY',
		subject: 'QUEUED_BOOKING.MAIL_SUBJECT',
	},
	QUEUED_BOOKING_NST: {
		body: 'QUEUED_BOOKING_NST.MAIL_BODY',
		subject: 'QUEUED_BOOKING_NST.MAIL_SUBJECT',
	},
	OP_WEIGHT_BOOKING: {
		body: 'OP_WEIGHT_BOOKING.MAIL_BODY',
		subject: 'OP_WEIGHT_BOOKING.MAIL_SUBJECT',
	},

	DG_UPDATE_BOOKING: {
		body: 'DG_UPDATE_BOOKING.MAIL_BODY',
		subject: 'DG_UPDATE_BOOKING.MAIL_SUBJECT',
	},
	ALTERNATE_ROUTING_ENQUIRY:{
		body: 'ALTERNATE_ROUTE_ENQUIRY.MAIL_BODY',
		subject: 'ALTERNATE_ROUTE_ENQUIRY.MAIL_SUBJECT',
	}

}
