import React, { useState, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import './filter-column.scss';
import Calendar from '../../../../common/Calendar'
import { useTranslation } from 'react-i18next'
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';
import filterIcon from '../../../../assets/images/filter.svg';
import moment from 'moment';
import filterGear from "../../../../assets/images/Menu-dots.svg";

const FilterColumn = ({
    refs,
    flightNumberSelection,
    departureTimeSelection,
    arrivalTimeSelection,
    remainingTimeSelection,
    flightOriginSelection,
    flightDestinationSelection,
    statusSelection,
    impactedAWBSelection,
    setFlightNumberSelection,
    setDepartureTimeSelection,
    setArrivalTimeSelection,
    setRemainingTimeSelection,
    setFlightOriginSelection,
    setFlightDestinationSelection,
    setStatusSelection,
    setImpactedAWBSelection,
}) => {
    const showFilter = (e) => {
        refs.current.toggle(e)
    }

    const handleFlightNo = () => {
        setFlightNumberSelection(!flightNumberSelection)
    }

    const handleDeparture = () => {
        setDepartureTimeSelection(!departureTimeSelection)
    }

    const handleArrival = () => {
        setArrivalTimeSelection(!arrivalTimeSelection)
    }

    const handleRemaining = () => {
        setRemainingTimeSelection(!remainingTimeSelection)
    }

    const handleOrigin = () => {
        setFlightOriginSelection(!flightOriginSelection)
    }

    const handleDestination = () => {
        setFlightDestinationSelection(!flightDestinationSelection)
    }

    const handleStatus = () => {
        setStatusSelection(!statusSelection)
    }

    const handleImpactedAWB = () => {
        setImpactedAWBSelection(!impactedAWBSelection)
    }



    return (
        <li className='filter__item'>
            <span className='showFilter d-none d-lg-block' onClick={(e) => showFilter(e)}>
                <div>
                    <img className='settings-icon' src={filterGear} />
                </div>
            </span>
            {/* <span className='showFilter d-block d-lg-none' onClick={() => setShowFilterModal(!showFilterModal)}> */}
                {/* <span><img className='uld-form filter-icon' src={filterIcon} /></span> */}
            {/* </span> */}
            {/* <span className='showFilter d-block d-lg-none' onClick={(e) => showFilter(e)}>  
                  <span><img className='uld-form filter-icon' src={filterIcon} /></span>   
             </span>  */}


            <div className='b-none d-lg-block'>
                <OverlayPanel
                    ref={refs}
                    className='custom_overlay_panel_column'
                    appendTo={document.body}
                >
                     
               {/* <div>
                    <div className="popup-header">
                        <button type="button" className='ml-3 l-btn l-btn--primary l-btn--xs'
                         onClick={() => clearFilterStates()}>Clear</button>
                    </div>             
                </div> */}
                    <div className="row form-container pt-3">
                  
                    <div className='col-md-6 filterField_wrap stops-radio-wrapper mt-3'>
                                    <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='confirmed'
                                                type='checkbox'
                                                name='confirmed'
                                                className='checkbox-item__field'
                                                aria-describedby='confirmed'
                                                onChange={handleFlightNo}
                                                checked={flightNumberSelection}
                                            />
                                            <label htmlFor="confirmed" className='checkbox-item__label'>
                                                Flight No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='cancelled'
                                                type='checkbox'
                                                name='cancelled'
                                                className='checkbox-item__field'
                                                aria-describedby='cancelled'
                                                onChange={handleDeparture}
                                                checked={departureTimeSelection}
                                            />
                                            <label htmlFor='cancelled' className='checkbox-item__label'>
                                                Departure Date & Time
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='queued'
                                                type='checkbox'
                                                name='queued'
                                                className='checkbox-item__field'
                                                aria-describedby='queued'
                                                onChange={handleArrival}
                                                checked={arrivalTimeSelection}
                                            />
                                            <label htmlFor='queued' className='checkbox-item__label'>
                                                Arrival Date & Time
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='notNotified'
                                                type='checkbox'
                                                name='notNotified'
                                                className='checkbox-item__field'
                                                aria-describedby='notNotified'
                                                onChange={handleRemaining}
                                                checked={remainingTimeSelection}
                                            />
                                            <label htmlFor='notNotified' className='checkbox-item__label'>
                                                Remaining Time Before STD
                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-6 filterField_wrap stops-radio-wrapper mt-3'>
                                <div class="checkbox-custom col mt-2">
                                        <div class="checkbox-item">
                                            <input
                                                id='approved'
                                                type='checkbox'
                                                name='approved'
                                                className='checkbox-item__field'
                                                aria-describedby='approved'
                                                onChange={handleOrigin}
                                                checked={flightOriginSelection}
                                            />
                                            <label htmlFor='approved' className='checkbox-item__label'>
                                                Flight Origin
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='onHold'
                                                type='checkbox'
                                                name='onHold'
                                                className='checkbox-item__field'
                                                aria-describedby='onHold'
                                                onChange={handleDestination}
                                                checked={flightDestinationSelection}
                                            />
                                            <label htmlFor='onHold' className='checkbox-item__label'>
                                                Flight Destination
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='reqInfo'
                                                type='checkbox'
                                                name='reqInfo'
                                                className='checkbox-item__field'
                                                aria-describedby='reqInfo'
                                                onChange={handleStatus}
                                                checked={statusSelection}
                                            />
                                            <label htmlFor='reqInfo' className='checkbox-item__label'>
                                                Status
                                            </label>
                                        </div>
                                    </div>
                                    <div class="checkbox-custom col mt-4">
                                        <div class="checkbox-item">
                                            <input
                                                id='notified'
                                                type='checkbox'
                                                name='notified'
                                                className='checkbox-item__field'
                                                aria-describedby='notified'
                                                onChange={handleImpactedAWB}
                                                checked={impactedAWBSelection}
                                            />
                                            <label htmlFor='notified' className='checkbox-item__label'>
                                                Impacted AWBs
                                            </label>
                                        </div>
                                    </div>
                            </div>         
                    </div>  
                </OverlayPanel>
            </div>
        </li>
    )
}

export default FilterColumn