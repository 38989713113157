import React, { useEffect, useState, useRef } from 'react';
import Popup from '../../components/Popup/Popup';
import { AutoComplete } from 'primereact/autocomplete'
import { useHistory, Prompt } from 'react-router-dom';
import './prompt-draft.scss';
import DraftPopup from '../../components/Popup/DraftPopup';
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages';
import { addError } from '../../utils/analytics'
//Faheem
const BeforeClosePrompt = ({
    isModifyBooking,
    saveDraftError,
    saveDraftResponse,
    setAlertMessage,
    setSaveDraftError,
    setDraftSuccess,
    acceptFunction,
    notLeave,
    setNotLeave,
    component,
    name,
    setName,
    getTemplates,
    clearSaveDraftResponse,
    handleDraftValueCallback,
    setLocationListened,
    locationListened,
    setForInternalNav,
    disableBeforeClosePrompt,
}) => {


    /*for multilingual*/
	const { t, i18n } = useTranslation(['labels', 'errors'])

    const locale = 'en';
    const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update
    const [triggeredClose, setTriggeredClose] = useState(false)
    const [triggered, setTriggered] = useState(false);

    var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    var date = new Date()
    date.setDate(date.getDate())
    date = date.getDate() + "-" + monthNames[date.getMonth()] + "-" + date.getFullYear()


    const hour = today.getHours();
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
    const history = useHistory();
    var timeout;

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }

    }, [time]);

    function warning() {
        handleDraftValueCallback()
        if (notLeave && triggeredClose === false) {
            if (window.location.pathname === "/booking/search") {
                timeout = setTimeout(function () {
                    setTriggered(true);
                    setName(`Draft ${date} ${time}`)
                    setForInternalNav(false);
                }, 2000);
                return "You are leaving the page";
            }
        } else return
    }

    function noTimeout() {
        clearTimeout(timeout);
        functionClear()
    }

    const functionClear = () => {
        setNotLeave(true);
        setTriggered(false);
        setName('')
        setSaveDraftError(null)
        setTriggeredClose(false)
    }


    window.onbeforeunload = window.location.pathname === "/booking/search" && !disableBeforeClosePrompt && isModifyBooking != true && warning;
    // window.onunload = noTimeout

    // const alertUser = e => {
    //     e.preventDefault()
    //     e.returnValue = ''
    //   }
    // useEffect(() => {
    //     window.addEventListener('load', alertUser)
    //     return () => {
    //       window.removeEventListener('beforeunload', alertUser)
    //     }
    //   }, [])

    const handleExit = () => {
        return (
            <>
                <Popup
                    display={triggered}
                    primaryBtnLbl={t('COMMON.SAVE')}
                    secondaryBtnLbl={t('COMMON.CANCEL')}
                    rejectFn={() => functionClear()}
                    icon='noIcon'
                    header={t('DRAFT.SAVE_DRAFT')}
                    acceptFn={acceptFunction}
                    closeErrorPopup={() => { }}
                    message={
                        <div style={{ width: "90%" }}>
                            <input
                                type='text'
                                placeholder={t('DRAFT.DRAFT_NAME_PLACEHOLDER')}
                                className={`form-item__field-draft`}
                                value={name ? name : ''}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}

                            />
                            <br />
                            <span className='search-panel__errormsg mt-2'>
								{saveDraftError && saveDraftError !== ''
									? t(saveDraftError, { ns: 'errors' })
									: ''}
                            </span>
                        </div>
                    }
                />
            </>
        )
    }

    const modalCloseFunction = () => {
        setTriggered(false)
        setName(``)
    }

    const modalAcceptFunction = () => {
        setTriggered(true)
        setName(`Draft ${date} ${time}`)
    }

    const handleConfirmationModal = () => {
        if (triggeredClose === true) {
            return (
                <>
                    <DraftPopup
                        display={triggeredClose}
                        primaryBtnLbl={t('COMMON.YES')}
                        secondaryBtnLbl={t('COMMON.NO')}
                        rejectFn={() => {
                            modalCloseFunction()
                            window.location.href = locationListened.pathname
                        }}
                        closeFunction={() => setTriggeredClose(false)}
                        icon='noIcon'
                        header={t('DRAFT.DRAFT_CONFIRM_MSG')}
                        acceptFn={modalAcceptFunction}
                        closeErrorPopup={() => { }}
                        message={''}
                    />
                </>
            )
        } else {
            return <></>
        }
    }

    useEffect(() => {
        if (triggeredClose === false) {

            const unblock = history.block((location, action) => {
                if (isModifyBooking != true && location.pathname != '/booking/flightlist'
                    && !location.pathname.includes('/booking/summary')
                    && !disableBeforeClosePrompt
                    && notLeave === true) {
                    // && location.pathname != '/manage-booking/list' 
                    // setTriggered(true)
                    // setName(`Draft ${date} ${time}`)
                    setTriggeredClose(true);
                    setLocationListened(location);
                    setForInternalNav(true);
                    return false
                }
                return true;
            });


            return () => {
                if (notLeave === true) {
                    unblock();
                } else return
            };
        } else return
    }, [time, notLeave]);

    // const unblockHandle = useRef();

    // useEffect(() => {
    //     unblockHandle.current = history.block((location, action) => {
    //         // take some custom action here
    //         // i chose to show my custom modal warning user froim going back
    //         // rather than relying on the browser's alert
    //         if (isModifyBooking != true && location.pathname != '/booking/flightlist' && location.pathname != '/manage-booking/list' && notLeave === true) {
    //             setTriggered(true)
    //             setName(`Draft ${date} ${time}`)
    //             return false;
    //         } else return true;
    //     });
    //     return function () {
    //         unblockHandle.current.current && unblockHandle.current.current()
    //     }
    // }, [time, notLeave])


    useEffect(() => {
        handleDraftAction(saveDraftResponse, true)
    }, [saveDraftResponse]);

    const handleDraftAction = (saveDraftResponse, isSave) => {

        if (saveDraftResponse) {
            let action = 'modified'
            if (isSave === true) {
                action = 'saved'
            }
            if (
                saveDraftResponse.errorDetails &&
                saveDraftResponse.errorDetails.length > 0
            ) {
                setTriggeredClose(false)
                setTriggered(false)
                setName('')
                setSaveDraftError(null)
                setDraftSuccess(false)
				setAlertMessage(
					t(Messages.SAVE_DRAFT_ERROR, {
						action:
							action === 'saved'
								? t('COMMON.SAVED_TXT')
								: t('COMMON.MODIFIED_TXT'),
						ns: 'errors',
					})
				)
                if (action === 'saved') {
					addError(
						'SAVE_DRAFT_ERROR',
						i18n.getResource(
							'en',
							'errors',
							'Draft could not be saved. Please try again later.'
						)
					)
				} else {
					addError(
						'MODIFY_DARFT_FAILED',
						i18n.getResource(
							'en',
							'errors',
							'Draft could not be modified. Please try again later.'
						)
					)
				}
                window.scrollTo(0, 0)
            } else if (
                saveDraftResponse.draftNameDuplicate &&
                saveDraftResponse.draftNameDuplicate === true &&
                isSave === true
            ) {
                setSaveDraftError(Messages.DRAFT_NAME_EXISTS)
            } else {
                setTriggeredClose(false)
                setTriggered(false)
                setName('')
                setSaveDraftError(null)
                if (isSave) {
                    setDraftSuccess(true)                   
					setAlertMessage(
						t('DRAFT.DRAFT_SUCCESS_MSG', {
							action:
								action === 'saved'
									? t('COMMON.SAVED_TXT')
									: t('COMMON.MODIFIED_TXT'),
						})
					)
                    getTemplates()
                    clearSaveDraftResponse()
                    window.scrollTo(0, 0)
                } else {
                    const updateStatus = {
                        message: t('DRAFT.DRAFT_SUCCESS_MSG', {
							action:
								action === 'saved'
									? t('COMMON.SAVED_TXT')
									: t('COMMON.MODIFIED_TXT'),
						}),
                        success: true,
                    }
                    clearSaveDraftResponse()
                }
            }
        }
    }

    return (
        <>
            {component}
            {handleConfirmationModal()}
            {handleExit()}

        </>
    );
};

export default BeforeClosePrompt;