import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import rejectIcon from '../../../assets/images/Error-Close-Filled.png'
import flightIcon from '../../../assets/images/cargo_flight.svg'
import pendingIcon from '../../../assets/images/Pending_icon.svg'
import menuDots from '../../../assets/images/Menu-dots.svg'
import envConfigs from '../../../configs/envConfigs'
import {
	BOOKING_STATUSES,
	MENU_ITEMS_BOOKING,
	NOTIFICATION_MESSAGE_TYPE,
	WEIGHT_UNIT_SELECT_ITEMS,
} from '../../../constants'
import MESSAGES from '../../../constants/Messages'
import PaginatorComponent from '../../Pagination/PaginatorComponent'
import './booking-details.scss'
const BookingDetailsList = ({
	initialSearch,
	setInitialSearch,
	listBookingResponse,
	listBookingError,
	getPaginatedBookingResults,
	setNotificationMessage,
	setNotificationType,
}) => {
	//FOR MULTILINGUAL
	const [t] = useTranslation(['labels', 'errors'])

	const [bookingList, setBookingList] = useState([])
	const [pageIndex, setPageIndex] = useState(1)
	const [currentPage, setCurrentPage] = useState(0)
	const [startIndex, setStartIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(0)
	const [totalRecordCount, setTotalRecordCount] = useState(0)
	const history = useHistory()
	const rowCount = 10
	const recordsPerRequest = 30
	const initialPageNumber = 1
	const [routes, setRoutes] = useState('')
	const [calculatedPageNumber, setCalculatedPageNumber] = useState(1)
    const [showDropdown, setShowDropdown] = useState(false)
	const [key, setKey] = useState(null)
	const { parentUrl } = envConfigs
	let i18nLang = localStorage.getItem('i18nextLng');
	moment.updateLocale( i18nLang.toString() , {
		weekdays : [ t('COMMON.SUN'), t('COMMON.MON'), t('COMMON.TUE'), t('COMMON.WED'), t('COMMON.THU'), t('COMMON.FRI'), t('COMMON.SAT')
		],
		weekdaysShort : [
			t('COMMON.SUN_SHORT'), t('COMMON.MON_SHORT'), t('COMMON.TUE_SHORT'), t('COMMON.WED_SHORT'), t('COMMON.THU_SHORT'), t('COMMON.FRI_SHORT'), t('COMMON.SAT_SHORT')
		]
	});
	useEffect(() => {
		if (listBookingResponse) {
			setBookingList(listBookingResponse.bookingList)
			setTotalRecordCount(listBookingResponse.totalRecordCount)
			let modulusOfPageIndex = pageIndex % (recordsPerRequest / rowCount)

			if (modulusOfPageIndex === 0) {
				modulusOfPageIndex = 3
				setStartIndex((modulusOfPageIndex - 1) * rowCount)
				setEndIndex(modulusOfPageIndex * rowCount)
			} else {
				setStartIndex((modulusOfPageIndex - 1) * rowCount)
				setEndIndex(modulusOfPageIndex * rowCount)
			}
		}
	}, [listBookingResponse, pageIndex])

	useEffect(() => {
		if (initialSearch) {
			setPageIndex(1)
			setCurrentPage(0)
		}
	}, [initialSearch])

	useEffect(() => {
		if (calculatedPageNumber >= initialPageNumber) {
			getPaginatedBookingResults(calculatedPageNumber)
		}
	}, [calculatedPageNumber])

	const onPageIndexChange = (newPageIndex, currentPageToDisplay) => {
		setPageIndex(newPageIndex)
		setCurrentPage(currentPageToDisplay)
		setInitialSearch(false)
		const calculatedPageNumber = Math.ceil(
			(newPageIndex * rowCount) / recordsPerRequest
		)

		setCalculatedPageNumber(calculatedPageNumber)
		// if (calculatedPageNumber >= initialPageNumber) {
		// 	getPaginatedBookingResults(calculatedPageNumber)
		// }
	}

	const handleViewBooking = (bookingDetail) => {
	
		history.push(
			`/manage-booking/${bookingDetail.shipmentIdentifierDetails.shipmentPrefix}
			${bookingDetail.shipmentIdentifierDetails.masterDocumentNumber}/${bookingDetail.ubrNumber}`
		)
	}

	const redirectToTrackOrCopyBooking = (evnt,result,option) => {
		evnt.stopPropagation()
		if (result.bookingStatus === 'X') {
			setNotificationMessage(MESSAGES.ALREDAY_CANCELLED)
			setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
			window.scrollTo(0, 0)
		} else {
			const airwayBill = result.shipmentIdentifierDetails
				? result.shipmentIdentifierDetails.shipmentPrefix +
				  result.shipmentIdentifierDetails.masterDocumentNumber
				: ''
			if (option === MENU_ITEMS_BOOKING.TRACK) {
				window.open(
					`${
						parentUrl ? parentUrl : 'https://www.etihadcargo.com'
					}/en/e-services/shipment-tracking?awb=${airwayBill}`,
					'_blank'
				)
			} else if (option === MENU_ITEMS_BOOKING.COPY) {
				history.push(`/booking/search/${airwayBill}`)
				window.scrollTo(0, 0)
			}
		}
	}

	const getRoutes = (flightSegments) => {
		if (flightSegments.length > 0) {
			return flightSegments.length - 1
		} else return 0
	}

	return (
		<div id='BookingDetailsList' className='mt-3 mt-lg-5 mb-3'>
			<div className='form-row'>
				<div className='col-12'>
					<h3 className='page-sub-head'> {t('COMMON.BOOKING LIST')}</h3>
				</div>
			</div>

			{listBookingResponse && totalRecordCount > 0 && (
				<div className='form-row mb-4 booking-details__total-results-display'>
					<div className='col-12'>
						{totalRecordCount}
						{''} {totalRecordCount > 1 ? t('COMMON.BOOKINGS') : ''}
						{totalRecordCount === 1 && t('COMMON.BKG_HEAD')}
					</div>
				</div>
			)}

			{listBookingResponse && bookingList.length === 0 && (
				<div className='no-data ml-0 mr-0'>
					{' '}
					{t(MESSAGES.NO_BOOKINGS, { ns: 'errors' })}
				</div>
			)}

			{!listBookingResponse && !listBookingError && (
				<div className='no-data m-0'>
					{t(MESSAGES.LOADING, { ns: 'errors' })}
				</div>
			)}

			{listBookingError && (
				<div className='no-data m-0'>
					{t(MESSAGES.PROCESS_REQUEST_FAILED, { ns: 'errors' })}
				</div>
			)}

			{listBookingResponse &&
				bookingList.length >= 1 &&
				bookingList.slice(startIndex, endIndex).map((result, index) => (
					<div
						className=' booking-details__inner-wrapper'
						key={index}
						onClick={() => handleViewBooking(result)}
					>
						<div className='form-row booking-details__item'>
							<div className='col-12'>
								<div className='form-row'>
									<div className='col-12 col-lg-9'>
										<div className='form-row'>
											<div className='col-12 col-lg-2 awb-status-wrapper'>
												{result.shipmentIdentifierDetails ? (
													<div>
														{result.shipmentIdentifierDetails.shipmentPrefix}-
														{
															result.shipmentIdentifierDetails
																.masterDocumentNumber
														}
													</div>
												) : (
													<></>
												)}
												<div className='flight-status-wrapper'>
													<span
														className={
															result.bookingStatus === 'C'
																? 'confirm-tick-mark '
																: result.bookingStatus === 'Q'
																? 'pr-3'
																: 'reject-status'
														}
													>
														{result.bookingStatus === 'Q' && (
															<i className='pr-1 indicator'>
																<img
																	src={pendingIcon}
																	alt='pending'
																	className='image-wrapper'
																></img>
															</i>
														)}
														{result.bookingStatus === 'X' && (
															<i>
																<img
																	src={rejectIcon}
																	alt='Rejected'
																	className='image-wrapper'
																></img>
															</i>
														)}
														{t(BOOKING_STATUSES[result.bookingStatus])}
													</span>
												</div>
											</div>

											<div className='col-12 col-lg-5 booking-details__flight-section pt-4'>
												<div className='booking-details__route'>
													{getRoutes(result.flightSegments) ==1  ? (
														<span className='flight-details__routes pb-3 pb-lg-0'>
															<span>
																{getRoutes(result.flightSegments)}{' '}
																{t('CHOOSE_FLT.STOP')}
															</span>
														</span>
													) : getRoutes(result.flightSegments) > 1 ? (
														<span className='flight-details__routes pb-3 pb-lg-0'>
															<span>
																{getRoutes(result.flightSegments)}{' '}
																{t('CHOOSE_FLT.STOPS')}
															</span>
														</span>
													) : (
														<></>
													)}
													<ul className='flight-details__timing pt-1 pb-lg-0'>
														<li className='booking-details__departure '>
															<div className='booking-details__p-b-6'>
																{moment(result.shippingDate, 'DD-MMM-YYYY').format(
																	'ddd DD MMM'
																)}
															</div>
															<div>{result.origin}</div>
														</li>

														<div className='flight-details__connection'>
															<div className='origin-destination-icon dark'></div>
															<div className='transit-line' />
															<div className='iconsSection'>
																<i>
																	{' '}
																	<img
																		src={flightIcon}
																		alt='Flight'
																		title='Flight'
																	/>
																</i>
															</div>
															<div className='transit-line' />
															<div className='origin-destination-icon dark'></div>
														</div>

														<li className='flight-details__arrival booking-details__arrival '>
															<div className='booking-details__p-b-6'>
																{moment(result.arrivalDate, 'DD-MMM-YYYY').format(
																	'ddd DD MMM'
																)}
															</div>
															<div>{result.destination}</div>
														</li>
													</ul>
												</div>
											</div>

											<div className='col-12 col-lg-4 booking-details__commodity-details'>
												<div className='pb-lg-1 custm_pt'>{result.commodity}</div>
												{result.product && (
													<div className='pb-lg-1 custm_pt'>({result.product})</div>
												)}
												<div>
													<div className='commodity-inner-wrapper d-block d-lg-inline custm_pt'>
														<span>{result.pieces}Pcs -</span>
														{WEIGHT_UNIT_SELECT_ITEMS.map(
															(weightUnit, index) => {
																return weightUnit.value ===
																	result.weightUnit ? (
																	<span key={index}>
																		{result.weight}
																		{weightUnit.label}
																	</span>
																) : (
																	<span key={index}></span>
																)
															}
														)}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-12 col-lg-3 booking-details__right-track d-none d-lg-flex'>									
										<div id ='menu-dots'>
											<div 
												className = 'menu-dot-wrapper' 
												onClick = {(e) => {                                                                
													e.stopPropagation()
													setShowDropdown(!showDropdown)
													setKey(index)  
												}}
											>											
												<i>
													<img
														src={menuDots}
														alt=''
													/>
												</i>
											</div>
											{showDropdown && key === index && 
												<div className='menuDot-dropdown'>
													<ul className='dropdown-panel'>
														<li 
															className='panel-first-item'
														 	onClick={(evnt) => redirectToTrackOrCopyBooking(evnt, result , MENU_ITEMS_BOOKING.TRACK)}>
															{t('COMMON.TRACK')}
														</li>
														<li className='panel-item' 
															onClick={(evnt) => redirectToTrackOrCopyBooking(evnt, result , MENU_ITEMS_BOOKING.COPY)}>
																{t('BKGLIST.COPY')}
														</li>
													</ul>											
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}

			{listBookingResponse && bookingList.length !== 0 && (
				<div className='paginationWrapper'>
					<PaginatorComponent
						initialSearch={initialSearch}
						currentPage={currentPage}
						pageIndex={pageIndex}
						rowCount={rowCount}
						totalRecords={totalRecordCount}
						onPageIndexChange={onPageIndexChange}
					/>
				</div>
			)}
		</div>
	)
}

export default BookingDetailsList
