import { TOAST } from '../constants'

export const getToastSuccess = (message) => ({
	type: TOAST.SUCCESS_TOAST,
	message,
})

export const getToastError = (message) => ({
	type: TOAST.FAILURE_TOAST,
	message,
})

export const getToastInfo = (message) => ({
	type: TOAST.INFO_TOAST,
	message,
})
