import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants'
import tickIcon from '../../assets/images/Check-mark-filled.png'
import warningIcon from '../../assets/images/notification-warning.svg'
import errorIcon from '../../assets/images/Error-Close-Filled.png'
import infoIcon from '../../assets/images/notification-info.svg'

export class Alert extends Component {
	static defaultProps = {
		options: null,
	}

	static propTypes = {
		options: PropTypes.array,
	}

	constructor(props) {
		super(props)
		this.state = {
			isOpen: true,
		}

		this.close = this.close.bind(this)

		if (props.setTimeout) {
			setTimeout(
				() => {
					this.close()
				},
				props.timeOut ? props.timeOut : 5000
			)
		}
	}

	close = () => {
		if (this.props.onAlertClose !== undefined) {
			this.props.onAlertClose()
		}
		this.setState({ isOpen: false })
	}

	render() {
		if (this.state.isOpen) {
			return (
				<div
					className={`form-row alert ${this.props.isError ||
							(this.props.type &&
								this.props.type === NOTIFICATION_MESSAGE_TYPE.ERROR)
							? 'alert-danger'
							: this.props.type &&
								this.props.type === NOTIFICATION_MESSAGE_TYPE.INFO
								? 'alert-info'
								: this.props.type &&
									this.props.type === NOTIFICATION_MESSAGE_TYPE.WARNING
									? 'alert-warning alert-warning-wrapper'
									: 'alert-success alert-success-wrapper '
						}`}
				>
					<div className='col col-12'>
						<div className='alert-inner-wrapper'>
							<i>
								{this.props.isError ||
									(this.props.type &&
										this.props.type === NOTIFICATION_MESSAGE_TYPE.ERROR) ? (
									<img
										src={errorIcon}
										alt='Error'
										className='image-wrapper'
									></img>
								) : this.props.type &&
									this.props.type === NOTIFICATION_MESSAGE_TYPE.INFO ? (
									<img
										src={infoIcon}
										alt='Info'
										className='image-wrapper'
									></img>
								) : this.props.type &&
									this.props.type === NOTIFICATION_MESSAGE_TYPE.WARNING ? (
									<img
										src={warningIcon}
										alt='Warning'
										className='image-wrapper'
									></img>
								) : (
									<img
										src={tickIcon}
										alt='Success'
										className='image-wrapper'
									></img>
								)}
							</i>
							{this.props.message.includes('<br>') ? (
								<p dangerouslySetInnerHTML={{ __html: this.props.message }}></p>
							) : (
								<p>{this.props.message}</p>
							)}
						</div>
					</div>
				</div>
			)
		} else {
			return <span></span>
		}
	}
}
