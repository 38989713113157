export const COUNTRY_CODES = [
	'+971',
	'+1',
	'+44',
	'+61',
	'+91',
	'+49',
	'+966',
	'+353',
	'+33',
	'+93',
	'+355',
	'+213',
	'+1684',
	'+376',
	'+244',
	'+1264',
	'+1268',
	'+54',
	'+374',
	'+297',
	'+43',
	'+994',
	'+1242',
	'+973',
	'+880',
	'+1246',
	'+375',
	'+32',
	'+501',
	'+229',
	'+1441',
	'+975',
	'+591',
	'+387',
	'+267',
	'+55',
	'+246',
	'+673',
	'+359',
	'+226',
	'+257',
	'+855',
	'+237',
	'+238',
	'+359',
	'+226',
	'+257',
	'+855',
	'+237',
	'+238',
	'+1345',
	'+236',
	'+235',
	'+56',
	'+86',
	'+57',
	'+269',
	'+242',
	'+243',
	'+682',
	'+506',
	'+385',
	'+53',
	'+357',
	'+420',
	'+225',
	'+45',
	'+253',
	'+1767',
	'+1849',
	'+1829',
	'+1809',
	'+593',
	'+20',
	'+503',
	'+240',
	'+291',
	'+372',
	'+251',
	'+298',
	'+679',
	'+594',
	'+689',
	'+241',
	'+220',
	'+995',
	'+233',
	'+350',
	'+30',
	'+299',
	'+1473',
	'+1671',
	'+502',
	'+224',
	'+245',
	'+592',
	'+509',
	'+379',
	'+504',
	'+852',
	'+36',
	'+354',
	'+62',
	'+98',
	'+964',
	'+972',
	'+39',
	'+1876',
	'+81',
	'+962',
	'+254',
	'+686',
	'+850',
	'+82',
	'+383',
	'+965',
	'+996',
	'+856',
	'+371',
	'+961',
	'+266',
	'+231',
	'+218',
	'+423',
	'+370',
	'+352',
	'+853',
	'+389',
	'+261',
	'+265',
	'+60',
	'+960',
	'+223',
	'+356',
	'+692',
	'+596',
	'+222',
	'+230',
	'+52',
	'+691',
	'+373',
	'+377',
	'+976',
	'+382',
	'+1664',
	'+258',
	'+95',
	'+264',
	'+674',
	'+977',
	'+31',
	'+687',
	'+505',
	'+227',
	'+234',
	'+683',
	'+672',
	'+1670',
	'+968',
	'+92',
	'+680',
	'+970',
	'+507',
	'+675',
	'+595',
	'+51',
	'+63',
	'+64',
	'+48',
	'+351',
	'+1939',
	'+1787',
	'+974',
	'+40',
	'+7',
	'+250',
	'+262',
	'+290',
	'+1869',
	'+1758',
	'+590',
	'+508',
	'+1784',
	'+685',
	'+378',
	'+239',
	'+221',
	'+381',
	'+248',
	'+232',
	'+65',
	'+1721',
	'+421',
	'+386',
	'+677',
	'+252',
	'+27',
	'+500',
	'+211',
	'+34',
	'+94',
	'+249',
	'+597',
	'+47',
	'+268',
	'+46',
	'+41',
	'+963',
	'+886',
	'+992',
	'+255',
	'+66',
	'+599',
	'+670',
	'+228',
	'+690',
	'+676',
	'+1868',
	'+216',
	'+90',
	'+993',
	'+1649',
	'+688',
	'+256',
	'+380',
	'+598',
	'+998',
	'+678',
	'+58',
	'+84',
	'+1284',
	'+1340',
	'+681',
	'+212',
	'+967',
	'+260',
	'+263',
	'+358',
]