import { SelectButton } from 'primereact/selectbutton'
import React, { useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { ToggleButton } from 'primereact/togglebutton'
import { Dropdown } from '../../../common/DropDown'
//import { pushEventBookingDashboard} from '../../utils/analytics'

const ChartFilter = ({
	period,
	setPeriod,
	chartDataType,
	setChartDataType,
	lastUpdatedDate,
	currency,
	chartData,
	setChangeToLocalCurrency,
	setLocalCur,
	changeToLocalCurrency,
	pushEventBookingDashboard,
	pushEventBookingTime

}) => {
	const { t, i18n } = useTranslation(['labels'])

	//const [buttonTitle, setButtonTitle] = useState(null)
	const [showToggleButton, setShowToggleButton] = useState(false)
	const [currencyOptions, setCurrencyOptions] = useState([])
	const [activeCur, setActiveCur] = useState();
	const PERIOD_SELECT_ITEMS = [
		{ label: t('DASHBOARD.MONTH'), value: 'MONTHLY' },
		{ label: t('DASHBOARD.YEAR'), value: 'YEARLY' },
	]

	const handleRadio = (e) => {
		setChartDataType(e.target.value)
		pushEventBookingDashboard(e.target.value)
		
	}
	//console.log(chartDataType,"chartDataType")

	const handleToggle = (val) => {
		val === 'USD'
			? setChangeToLocalCurrency(false)
			: setChangeToLocalCurrency(true)
		//setButtonTitle(chartData.currencyCode)
	}

	useEffect(() => {
		setActiveCur(currency)
	}, [currency])

	useEffect(() => {
		let CURRENCIES = []
		if (
			chartData &&
			chartData.currencyCode &&
			chartData.currencyCode !== 'USD'
		) {
			setShowToggleButton(true)
			CURRENCIES.push({
				label: chartData.currencyCode,
				value: chartData.currencyCode,
			})
		}
		CURRENCIES.push({ label: 'USD', value: 'USD' })
		setCurrencyOptions(CURRENCIES)
	}, [chartData])

	return (
		<div className='chart-filter-component'>
			<div className='row'>
				<div className='col-12'>
					<div className='d-flex flex-column flex-lg-row align-items-lg-center'>
						<div>
							<SelectButton
								className='select-button shipping-type'
								value={period}
								options={PERIOD_SELECT_ITEMS}
								onChange={(e) => {
									if (e.value) {
										setPeriod(e.value)
									}
									pushEventBookingTime(e.value)
								}}
							></SelectButton>
						</div>
						<div className='custom-pl-4'>
							<div className='h-100 d-flex flex-column flex-lg-row align-items-lg-center'>
								<div className='custom-pl-3 chart-filter-row'>
									<div className='form-item form-item--radio'>
										<input
											id='awbCount'
											type='radio'
											value='awbCount'
											name='chart-type'
											className='form-item__field'
											aria-describedby='awb-count'
											onChange={handleRadio}
											checked={chartDataType === 'awbCount' ? true : false}
										/>
										<label htmlFor='awbCount' className='form-item__label'>
											{t('DASHBOARD.AWB_CNT')}
										</label>
									</div>
								</div>
								<div className='custom-pl-3 chart-filter-row'>
									<div className='form-item form-item--radio'>
										<input
											id='tonnage'
											type='radio'
											value='tonnage'
											name='chart-type'
											className='form-item__field'
											aria-describedby='tonnage'
											onChange={handleRadio}
											checked={chartDataType === 'tonnage' ? true : false}
										/>
										<label htmlFor='tonnage' className='form-item__label'>
											{t('DASHBOARD.TONNAGE')}
										</label>
									</div>
								</div>
								<div className='custom-pl-3 chart-filter-row'>
									<div className='d-flex align-items-center'>
										<div className='cost_selector form-item form-item--radio'>
											<input
												id='charges'
												type='radio'
												value='charges'
												name='chart-type'
												className='form-item__field'
												aria-describedby='charges'
												onChange={handleRadio}
												checked={chartDataType === 'charges' ? true : false}
											/>
											<label htmlFor='charges' className='form-item__label'>
												{t('DASHBOARD.TOTAL_COST_DUE_CARRIER')}
											</label>
										</div>
										{chartDataType === 'charges' && showToggleButton && (
											<div className='d-flex flex-column flex-lg-row align-items-lg-center currency-selector'>
												<Dropdown
													className='cust-dropdown'
													options={currencyOptions}
													value={activeCur}
													onChange={(e) => {
														handleToggle(e.target.value)
														setActiveCur(e.target.value)
														setLocalCur(e.target.value)
													}}
												></Dropdown>
											</div>
											

										)}
									</div>
								</div>
							</div>
						</div>
						<div className='custom-pl-3 updated-time_wrap text-lg-right'>
							<div className='updated-time'>
								{t('DASHBOARD.LUD')} : {lastUpdatedDate}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChartFilter
