import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useRef, useState } from 'react'
import { getChargeHeads } from '../../../../services/dataCaptureService'
import { AUTOCOMPLETE_TYPE_COUNTRY } from '../../../../constants'
import { convertToLowerCase } from '../../../../utils/common'
import Messages from '../../../../constants/Messages'
import { useTranslation } from 'react-i18next'
const ChargeHeadDropDown = ({
	value,
	isMandatory,
	setError,
	chargeHeadId,
	chargeHeadNameId,
	chargeHeads,
	setChargeHeads,
	filteredChargeHeads,
	setFilteredChargeHeads,
	setChargeHeadCode,
}) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	const [text, setText] = useState(null)
	const [selectedChargeHead, setSelectedChargeHead] = useState(null)
	const countryRef = React.createRef()
	const isFocused = useRef(false)

	useEffect(() => {
		if (!text) {
			//getFilteredCountries({ query: text })
		}
	}, [text])

	useEffect(() => {
		setSelectedChargeHead(value)
	}, [value])

	const handleSelect = (chargeHeadVal) => {
		setChargeHeadCode(chargeHeadVal)
	}

	const handleOnBlur = async () => {
		setTimeout(() => {
			setFilteredChargeHeads(chargeHeads)
		}, 500)
		if (text && text.length > 1 && text.length <= 3) {
			const itemByCode =
				chargeHeads && chargeHeads.length
					? chargeHeads.filter((item) => {
							return item.chargeHeadCode.toLowerCase() === text.toLowerCase()
					  })
					: []
			if (itemByCode && itemByCode.length === 1) {
				setChargeHeadCode(itemByCode[0])
			} else {
				setChargeHeadCode(null)
				setError((prevState) => ({
					...prevState,
					[chargeHeadId]: Messages.FIELD_REQUIRED,
				}))
				setError((prevState) => ({
					...prevState,
					[chargeHeadNameId]: Messages.FIELD_REQUIRED,
				}))
			}
		} else if ((!text || text.length === 0) && !selectedChargeHead) {
			if (isMandatory && setError) {
				setError((prevState) => ({
					...prevState,
					[chargeHeadId]: Messages.FIELD_REQUIRED,
				}))
				setError((prevState) => ({
					...prevState,
					[chargeHeadNameId]: Messages.FIELD_REQUIRED,
				}))
			}
		}
		setText(null)
	}

	const searchCountry = async (e) => {
		const chargeHeadSelected = e.query

		if (chargeHeadSelected && chargeHeadSelected.length <= 3) {
			if (isFocused.current) {
				setFilteredChargeHeads(getFilteredChargeHeads(selectedChargeHead))
			}
		}
	}

	const getFilteredChargeHeads = (selectedChargeHead) => {
		const itemsByCodeStart =
			chargeHeads &&
			chargeHeads.length &&
			selectedChargeHead &&
			selectedChargeHead.length
				? chargeHeads.filter((item) => {
						return item.chargeHeadCode
							.toLowerCase()
							.startsWith(text.toLowerCase())
				  })
				: []
		const itemsByContains =
			chargeHeads && chargeHeads.length && text && text.length
				? chargeHeads.filter((item) => {
						return item.chargeHeadCode
							.toLowerCase()
							.includes(text.toLowerCase())
				  })
				: []
		return [...itemsByCodeStart, ...itemsByContains]
	}

	const itemTemplate = (item) => {
		return <div>{`${item.chargeHeadCode}`}</div>
	}

	return (
		<>
			<AutoComplete
				value={
					selectedChargeHead && selectedChargeHead.chargeHeadCode
						? `${selectedChargeHead.chargeHeadCode}`
						: text
				}
				//dropdown
				ref={countryRef}
				suggestions={filteredChargeHeads}
				completeMethod={searchCountry}
				onChange={(e) => {
					setSelectedChargeHead(null)
					setChargeHeadCode(null)
					setText(e.value)
				}}
				onSelect={(e) => {
					handleSelect(e.value)
				}}
				onFocus={(e) => {
					isFocused.current = true
					setError((prevState) => ({
						...prevState,
						[chargeHeadId]: '',
					}))
				}}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onBlur={(e) => {
					isFocused.current = false
					handleOnBlur()
				}}
				className='form-item__field chargehead-autocomplete-inner-wrapper'
				placeholder='Code'
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default ChargeHeadDropDown
