import React from 'react'
import { connect } from 'react-redux'
import { loadListUser } from '../../redux/actions/userListActions'
import UserManagement from './UserManagement'

const UserContainer = ({ listUserResponse, listUserError, getuser }) => {
	return (
		<UserManagement
			listUserResponse={listUserResponse}
			listUserError={listUserError}
			getuser={getuser}
		/>
	)
}

const mapStateToProps = ({ listUser }) => ({
	listUserResponse: listUser.response,
	listUserError: listUser.error,
})

const mapDispatchToProps = (dispatch) => ({
	getuser: (userSearchData) => {
		dispatch(loadListUser(userSearchData))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer)
