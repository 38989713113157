import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const getLisTemplateResponse = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getTemplateListUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const findTemplate = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.findTemplatetUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const updateTemplateResponse = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.updateTemplatetUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const deleteTemplateResponse = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.deleteTemplatetUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}
