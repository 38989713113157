import { LIST_QUOTE} from '../constants'

export const requestListQuotes = (payload) => ({
	type: LIST_QUOTE.LOAD,
	payload,
})

export const listQuoteResponse = (response) => ({
	type: LIST_QUOTE.SUCCESS,
	response,
})

export const listQuoteError = (error) => ({
	type: LIST_QUOTE.ERROR,
	error,
})
