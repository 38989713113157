import { LIST_FLIGHT, POPUP } from '../constants'

const initState = {
	resultSet: {},
	cheapestRate: 0,
}

export default function (state = initState, action = {}) {
	let resultSet = JSON.parse(JSON.stringify(state.resultSet))
	switch (action.type) {
		case LIST_FLIGHT.SET_CALENDAR:
			const calendarRange = action.calendarRange
			resultSet = {}
			if (calendarRange && calendarRange.length) {
				calendarRange.forEach((date) => {
					resultSet[date] = {
						isLoading: true,
						request: null,
						flightList: null,
						error: null,
					}
				})
			}
			return {
				...state,
				resultSet,
			}
		case LIST_FLIGHT.LOAD:
			const searchFormData = action.searchFormData
			resultSet[searchFormData.shippingDate] = {
				...resultSet[searchFormData.shippingDate],
				request: searchFormData,
			}
			return {
				...state,
				resultSet,
			}
			
		case LIST_FLIGHT.SUCCESS:
			const { response } = action
			const {request} = resultSet[response.date]
			if (request.id !== response.id) {
				return {
					...state,
				}
			}
			const { flightList } = response
			const lowestRate =
				flightList &&
				flightList.services &&
				flightList.services['DEFAULT'] &&
				flightList.services['DEFAULT'].lowestRate
					? Number(flightList.services['DEFAULT'].lowestRate.amount)
					: 0
			let cheapestRate =
				!Number(state.cheapestRate) || lowestRate < Number(state.cheapestRate)
					? lowestRate
					: state.cheapestRate

			resultSet[response.date] = {
				...resultSet[response.date],
				isLoading: false,
				flightList: response.flightList,
			}
			return {
				...state,
				resultSet,
				cheapestRate,
			}
		case LIST_FLIGHT.ERROR:
			const error = action.error
			resultSet[error.date] = {
				...resultSet[error.date],
				isLoading: false,
				error: error.error,
			}
			return {
				...state,
				resultSet,
			}
		case LIST_FLIGHT.CLEAR:
			return {
				...state,
				resultSet: {},
				cheapestRate: 0,
			}
		case POPUP.CLOSE:
			return {
				...state,
				error: null,
			}
		default:
			return state
	}
}
