import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalHttpClient } from '../utils/HttpRequestUtils'


export const getGeoLocation = async () => {
    const url = envConfigs.getGeoLocationUrl
    const response = await portalHttpClient.get(url, {
        withCredentials: true,
        headers: {
            Accept: 'application/json'
        }
    })
    return response.data
}