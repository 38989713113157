import React, { useEffect, useState } from 'react'
import { updateUserStatus } from '../../../../services/userService'
import './list-table.scss'
import UserTable from './UserTable'
import { ACCOUNT_DISABLED_FLAG, SUCCESS_STATUS } from '../../../../constants'
import { useTranslation } from 'react-i18next'
import MESSAGES from '../../../../constants/Messages'
import { addError } from '../../../../utils/analytics'

const ListUserTable = ({
	userList,
	setAlertMessage,
	setUpdateSuccess,
	handleUpdateUser,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const columns = [
		'COMMON.NAME',
		'COMMON.USER_ID',
		'COMMON.LAST_LOGIN',
		'COMMON.STATUS',
	]

	const buildUpdateRequest = (userData) => {
		let changedStatus = userData.status
			? ACCOUNT_DISABLED_FLAG.ACTIVE
			: ACCOUNT_DISABLED_FLAG.INACTIVE

		let requestBody = {
			userId: userData.userId,
			accountDisabledFlag: changedStatus,
		}
		return requestBody
	}

	const initiateUpdateUserStatus = (userDataToUpdate) => {
		let requestBody = buildUpdateRequest(userDataToUpdate)
		updateUserStatus(requestBody)
			.then((response) => {
				if (response && response.status === SUCCESS_STATUS) {
					setAlertMessage(`
							${t('UM.ACT_DEACT_MSG', {
								status: userDataToUpdate.status
									? t('UM.STATUS_DE_ACTIVATED')
									: t('UM.STATUS_ACTIVATED'),
								name: `${userDataToUpdate.firstName} ${userDataToUpdate.lastName}`,
							})}`)
					setUpdateSuccess(true)
				} else if (
					response.errorDetails !== undefined &&
					response.errorDetails !== null
				) {
					setAlertMessage(MESSAGES.STATUS_UPDATE_FAILED)
					addError(
						MESSAGES.STATUS_UPDATE_FAILED,
						i18n.getResource('en', 'errors', MESSAGES.STATUS_UPDATE_FAILED)
					)
					setUpdateSuccess(false)
				}
				window.scrollTo(0, 0)
			})
			.catch((error) => {
				setAlertMessage(MESSAGES.STATUS_UPDATE_FAILED)
				addError(
					MESSAGES.STATUS_UPDATE_FAILED,
					i18n.getResource('en', 'errors', MESSAGES.STATUS_UPDATE_FAILED)
				)
				setUpdateSuccess(false)
				console.log(error)
				window.scrollTo(0, 0)
			})
	}

	return (
		<>
			<div className='table-user-list-wrapper'>
				{userList && userList.length ? (
					<UserTable
						userList={userList}
						columns={columns}
						initiateUpdateUserStatus={initiateUpdateUserStatus}
						handleUpdateUser={handleUpdateUser}
						setUpdateSuccess={setUpdateSuccess}
						setAlertMessage={setAlertMessage}
					/>
				) : (
					<div className='no-data m-r-l-0'>
						{
							(t(MESSAGES.NO_USERS_PRESENT, { ns: 'errors' }),
							addError(
								MESSAGES.NO_USERS_PRESENT,
								i18n.getResource('en', 'errors', MESSAGES.NO_USERS_PRESENT)
							))
						}
					</div>
				)}
			</div>
		</>
	)
}

export default ListUserTable
