import { LIST_TEMPLATE_NAMES } from '../constants'

export const requestListTemplateNames = (payload) => ({
	type: LIST_TEMPLATE_NAMES.LOAD,
	payload,
})

export const listTemplateNamesResponse = (response) => ({
	type: LIST_TEMPLATE_NAMES.SUCCESS,
	response,
})

export const listTemplateNamesError = (error) => ({
	type: LIST_TEMPLATE_NAMES.ERROR,
	error,
})
