export const RATE_TYPES={

	CUSTOMER_RATE:  "CHOOSE_FLT.PRC_CLASS_CUST_RT",
	MARKET_RATE: "CHOOSE_FLT.PRC_CLASS_MARKET_RT",
	CUSTOMER_BSA_RATE: "CHOOSE_FLT.PRC_CLASS_CUST_BSA_RT",
	CUSTOMER_CPA_RATE: "CHOOSE_FLT.PRC_CLASS_CUST_CPA_RT",
	SPECIAL_RATE: "CHOOSE_FLT.PRC_CLASS_SPECIAL_RT",
	ADHOC_RATE: "CHOOSE_FLT.PRC_CLASS_ADHOC_RT",
	DYNAMIC_RATE: "CHOOSE_FLT.PRC_CLASS_DYNAMIC_RT",
	MARKET_RATE: "CHOOSE_FLT.PRC_CLASS_MARKET_RT",
	IATA_RATE: "CHOOSE_FLT.PRC_CLASS_IATA_RT",
	PROMO_RATE: "CHOOSE_FLT.PRC_CLASS_PROMO_RT",
	
}

export const PB_RateType={
	CUSTOMER_RATE:  "Customer Rate",
	MARKET_RATE: "Market Rate",
	CUSTOMER_BSA_RATE: "Customer BSA Rate",
	CUSTOMER_CPA_RATE: "Customer CPA Rate",
	SPECIAL_RATE: "Special Rate",
	ADHOC_RATE: "Adhoc Rate",
	DYNAMIC_RATE: "Instant Offer Rate",
	IATA_RATE: "IATA Rate",
	PROMO_RATE: "Promo Rate"
}