import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants'
import MESSAGES from '../../constants/Messages'
import { sendEmailForAssistance } from '../../utils/ExceptionHandlingUtil'
import { useTranslation } from 'react-i18next'
const AviShippingItemValidationPopUp = ({
	displayShippingItemPopup,
	setDisplayShippingItemPopup,
	handleClear,
	selectedDestination,
	selectedOrigin,
	shippingDate,
	shippingItem,
	setIsLoading,
	setNotificationMessage,
	setNotificationType,
	setShippingItem,
	additionalSccs,
}) => {
	const { t } = useTranslation(['labels', 'errors'])

	const handleCancel = () => {
		setDisplayShippingItemPopup(false)
		setShippingItem(null)
	}

	const handleConfirm = async () => {
		const attributes = [
			{
				key: 'origin',
				value: selectedOrigin ? selectedOrigin.code : null,
			},
			{
				key: 'destination',
				value: selectedDestination ? selectedDestination.code : null,
			},
			{
				key: 'shippingItem',
				value: shippingItem &&
					shippingItem?.description &&
					shippingItem?.product
					? `${shippingItem?.description}-${shippingItem?.product}`
					: null,
			},
			{
				key: 'shippingDate',
				value: shippingDate ? moment(shippingDate).format('DD MMM YYYY') : null,
			},
			{
				key: 'sccs',
				value: getSccs(additionalSccs),
			},
		]
		const replacements = {
			'%DESCRIPTION%': shippingItem ? shippingItem?.description : '',
			'%PRODUCT%': shippingItem ? shippingItem?.product : '',
		}

		const response = await sendEmailForAssistance(
			'SHIPPING_ITEM',
			setIsLoading,
			attributes,
			replacements
		)
		setDisplayShippingItemPopup(false)

		if (response) {
			setNotificationMessage(t(MESSAGES.ASSISTANCE_MSG, { ns: 'errors' }))
			setNotificationType(NOTIFICATION_MESSAGE_TYPE.INFO)
		} else {
			setIsLoading(false)
			setNotificationMessage(
				t(MESSAGES.MAIL_ASSISTANCE_FAILS, { ns: 'errors' })
			)
			setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
		}
		window.scrollTo(0, 0)
		handleClear()
	}

	const getSccs = (additionalSccs) => {
		if (additionalSccs && additionalSccs.length) {
			return additionalSccs.map((e) => e.scc).toString()
		} else {
			return null
		}
	}

	const RenderFooter = () => {
		return (
			<div>
				<button icon='' className='button' onClick={handleCancel} >
					{t('COMMON.GO_BACK')}
				</button>
				<button icon='' className='button secondary' onClick={handleConfirm}>
					{t('COMMON.SEND_ENQUIRY')}
				</button>
			</div>
		)
	}

	return (
		<div className='cargo-portal-container dialog-container'>
			<div className='portal-dialog p-fluid'>
				<Dialog
					footer={<RenderFooter />}
					visible={displayShippingItemPopup}
					modal
					closable={true}
					header=''
					onHide={handleCancel}
				>
					<p className='mt-1' style={{'line-height': '24px', 'text-align': 'left'}}>{t('BOOKING.AVI_ASSISTANCE_HEAD')}</p>
					<p className='mt-3' style={{'line-height': '24px', 'text-align': 'left'}}>
						{t(MESSAGES.AVI_ASSISTANCE_MSG_SHIPPING_LABEL1, {
							item: `'${shippingItem?.description}'`,
							ns: 'errors',
						})}
					</p>
					<p className='mt-3' style={{'line-height': '24px', 'text-align': 'left'}}>
						{t(MESSAGES.AVI_ASSISTANCE_MSG_SHIPPING_LABEL2, {
							item: '',
							ns: 'errors',
						})}
					</p>
				</Dialog>
			</div>
		</div>
	)
}

export default AviShippingItemValidationPopUp
