import React, {useState, useEffect} from 'react'
import './index.scss'
import footerLogo from '../../assets/images/footer-text-logo.svg'
import linkedinLogo from '../../assets/images/icon-social-linkedin.svg'
import ICPInformation from './ICPInformation.js'
import { LINKEDIN_URL } from '../../configs/appConfigs'
import sessionKeys from '../../constants/sessionKeys'
import {getGeoLocation} from './../../services/edgeService'
import { useTranslation } from 'react-i18next'
const Footer = () => {
	const { t, i18n } = useTranslation(['labels'])
	const [geoLocation, setGeoLocation] = useState(null)

	useEffect(() => {
		(async () => {
			let geoLocationStr = sessionStorage.getItem(sessionKeys.GEO_LOCATION)
			let geoLocation = geoLocationStr ? JSON.parse(geoLocationStr) : null
			if (!geoLocation) {
				try {
					geoLocation = await getGeoLocation()
					sessionStorage.setItem(sessionKeys.GEO_LOCATION, JSON.stringify(geoLocation))
				} catch (err) {
					console.log('Error getting geo-location', err)
				}
			}
			setGeoLocation(geoLocation)
		})()
		
	}, [])

	return (
		<div className='cargo-portal-container'>
			<div
				className='container-fluid cargo-portal-footer'
				id='cargo-portal-footer'
			>
				<div className='row rbase_layer'>
					<div className='col-md-12'>
						<div className='rlayer1 custom-top-padding-0 custom-bottom-padding-0'>
							<div className='fixed-content-container container-lg fixedRowWidth footer-container'>
								<div className='row'>
									<div className='col-12 footerwithnavigation react-component section'>
										<div id='footerwithnavigation'>
											<footer className='footer'>
												<div className='constrain no-padding'>
													<div className='links__social links flex-cols flex-col-xs-12 flex-col-sm-12 flex-col-md-12 flex-col-lg-12'>
														<ul className='links__list col text-center'>
															<li className='navlink__item'>
																<a
																	href={LINKEDIN_URL}
																	target='_blank'
																	rel='noreferrer'
																	className='link link__no-border'
																>
																	<img
																		src={linkedinLogo}
																		alt='linkedin'
																		className='img-responsive'
																	/>{' '}
																	<span className='sr-only'>
																		opens in new window
																	</span>
																</a>
															</li>
														</ul>
													</div>
													<div>
														<div className='navigation__copyright flex-cols inline'>
															<div className='col col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-5'>
																<div className='language'>
																	<div className='footer-lang-sel-parent'>
																		<div className='footer-lang-sel-btn-grp-main'></div>
																	</div>
																</div>
																<ul className='navigation_links term'>
																	<li className='navigation__item col_left'>
																		<a
																			className='navigation__link '
																			href='https://www.etihadcargo.com/en/terms-and-conditions'
																		>
																				{t('FOOTER.T_C')}
																			
																		</a>
																	</li>
																	<li className='navigation__item col_left'>
																		<a
																			className='navigation__link '
																			href='https://www.etihadcargo.com/en/privacy-policy'
																		>
																			{t('FOOTER.PRIVACY_POLICY')}
																		</a>
																	</li>
																	<li className='navigation__item col_left'>
																		<a className='navigation__link' href='https://www.etihadcargo.com/en/cookie-policy'>
																			{t('FOOTER.COOKIE_POLICY')}
																		</a>
																	</li>
																	
																</ul>
																
																{
																		(geoLocation && geoLocation.countrycode && geoLocation.countrycode === 'cn') ? (
																			
																				<ICPInformation/>
																			
																		) : (<></>)

																	}		
															</div>
															<div className='payoff col col-xs-12 col-sm-12 col-md-6 col-lg-6 no-margin'>
																<div className='atomic-paragraph'>
																	<img
																		src={footerLogo}
																		alt='Footer Logo Alternate Text'
																	/>
																	<p>
																	{t('FOOTER.FOOTER_TXT')}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</footer>
										</div>
										<div className='clearfix'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
