import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Alert } from '../../../common/Alert'
import { Dropdown } from '../../../common/DropDown'
import { UseInput } from '../../../common/UseInput'
import {
	DIMENSION_UNIT_SELECT_ITEMS,
	LANGUAGES,
	MILESTONES,
	USER_TYPES,
	WEIGHT_UNIT_SELECT_ITEMS,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
} from '../../../constants'
import { COUNTRY_CODES } from '../../../constants/countryCodes'
import { createUser } from '../../../services/userProfileService'
import LoadingIndicator from '../../LoadingIndicator'
import { sort } from '../../../utils/common'
import CountryCodeDropdown from '../CountryCodeDropdown/index'
import {
	addAllNotifications,
	getLoggedInUserProfile,
	handleMilestone,
	validateCreateUserEmailAddress,
	validateName,
	validateUserId,
	getPrivileges,
	validateMobileCountryCode,
	validateMobileNumber,
	validatePhoneCountryCode,
	validatePhoneNumber,
	getRoles,
} from '../userManagementUtil'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
import { MULTILANGUAGE_SUPPORT_ENABLED } from '../../../configs/featureControlConfig'
import { addError, pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'
import getPrincipal from '../../../utils/getPrincipal'
import getCurrentUser from '../../../utils/getCurrentUser'
import { getFromLocalStorage } from '../../../utils/StorageUtils'

const CreateUserForm = ({ 
	setAddUser, 
	setAddUserSuccess, 
	addUserSuccess,
	agentCode,
	stationCode,
	defaultAirportCode,
	listUsers,
	isGsaUser,
	customerCode,
	companyName,
	companyCode,
 }) => {
	const { t, i18n } = useTranslation(['labels','errors'])
	const {
		value: userId,
		bind: bindUserId,
		reset: resetUserId,
	} = UseInput('', {noSpaceValidation: true, upperCase: true })
	const {
		value: firstName,
		bind: bindFirstName,
		reset: resetFirstName,
	} = UseInput('',{noSpaceValidation: true})
	const {
		value: lastName,
		bind: bindLastName,
		reset: resetLastName,
	} = UseInput('',{noSpaceValidation: true})
	const {
		value: emailAddress,
		bind: bindEmailAddress,
		reset: resetEmailAddress,
	} = UseInput('')
	const {
		value: confirmEmailAddress,
		bind: bindConfirmEmailAddress,
		reset: resetConfirmEmailAddress,
	} = UseInput('')
	const {
		value: phoneNumber,
		bind: bindPhoneNumber,
		reset: resetPhoneNumber,
	} = UseInput('',{noSpaceValidation: true})
	const {
		value: mobileNumber,
		bind: bindMobileNumber,
		reset: resetMobileNumber,
	} = UseInput('',{noSpaceValidation: true})

	const [userType, setUserType] = useState('')
	const [userTypeKey, setUserTypeKey] = useState('EU')
	const [userRoleGroup, setUserRoleGroup] = useState('PORADMUSR')
	const [milestones, setMilestones] = useState({
		milestone1: true,
		milestone2: true,
		milestone3: true,
		milestone4: true,
		milestone5: true,
		milestone6: true,
	})
	const [notifications, setNotifications] = useState([
		'Accepted',
		'Departed',
		'Arrived',
		'Notified',
		'Delivered',
	])
	const [phContryCode, setPhContryCode] = useState('')
	const [mobContryCode, setMobContryCode] = useState('')
	const [bookingPrevilege, setBookingPrevilege] = useState(userTypeKey === 'KU')
	const [capturePrevilege, setCapturePrevilege] = useState(userTypeKey === 'KU')
	const [dashboardPrevilege, setDashboardPrevilege] = useState(userTypeKey === 'KU')
	const [informaticsPrevilege, setInformaticsPrevilege] = useState(userTypeKey === 'KU')
	const [allotmentPrevilege, setAllotmentPrevilege] = useState(userTypeKey === 'KU')

	const [preferences, setPreferences] = useState({
		weightUnit: 'K',
		dimensionUnit: 'C',
		language: 'en_US',
	})

	const [privilegeToDisable, setPrivilegeTodisable] = useState(false)

	const [isEnterpriseUser, setIsEnterpriseUser] = useState(null)

	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const [alertMessage, setAlertMessage] = useState('')
	const [createSuccess, setCreateSuccess] = useState(false)

	const [sortedCountryCodes, setSotedCountryCodes] = useState([])

	const validateForm = async () => {
		const fNameValid = await validateName(
			firstName,
			'firstName',
			setError,
			error
		)
		const lNameValid = await validateName(lastName, 'lastName', setError, error)
		const userIdValid = await validateUserId(userId, setError, error)
		const emailValid = await validateCreateUserEmailAddress(
			emailAddress,
			// confirmEmailAddress,
			setError,
			error
		)
		const phNoValid = await validatePhoneNumber(
			phoneNumber,
			phContryCode,
			setError,
			error
		)
		const mobNoValid = await validateMobileNumber(
			mobileNumber,
			mobContryCode,
			setError,
			error
		)
		const phnCountryCodeValid = validatePhoneCountryCode(
			phContryCode,
			phoneNumber,
			setError
		)
		const mobCountryCodeValid = validateMobileCountryCode(
			mobContryCode,
			mobileNumber,
			setError
		)
		if (
			fNameValid &&
			lNameValid &&
			userIdValid &&
			emailValid &&
			phNoValid &&
			phnCountryCodeValid &&
			mobCountryCodeValid &&
			mobNoValid
		) {
			console.log('no error')
			sendRequest()
		} else {
			console.log('has error')
		}
	}

	useEffect(() => {
		setSotedCountryCodes(sort(COUNTRY_CODES))
		pushPageData(
			pageNames.USER_CREATION,
			PAGE_PRIMARY_CATEGORY.USER_PROFILE,
			PAGE_SUB_CATEGORY1.USER_MANAGEMENT
		)
		const isEnterpriseUser = getFromLocalStorage('SSOType') === 'E' ? true : false
		setIsEnterpriseUser(isEnterpriseUser)
	}, [])

	const sendRequest = () => {
		if (error != null) {
			setIsLoading(true)
			createUser(getRequest())
				.then((response) => {
					setIsLoading(false)
					if (
						response.errorDetails !== undefined &&
						response.errorDetails !== null &&
						response.errorDetails.length > 0
					) {
						addError(
							Messages.USER_CREATION_FAILED,
							i18n.getResource('en', 'errors', Messages.USER_CREATION_FAILED)
						)
						setAlertMessage(`${Messages.USER_CREATION_FAILED}`)
						setCreateSuccess(false)
					} else {
						setAddUser(false)
						setAddUserSuccess({
							...addUserSuccess,
							success: true,
							message: Messages.USER_CREATION_SUCCESS,
						})
						if(listUsers){
							listUsers();
						}
					}
					window.scrollTo(0, 0)
				})
				.catch((error) => {
					setIsLoading(false)
					addError(
						Messages.USER_CREATION_FAILED,
						i18n.getResource('en', 'errors', Messages.USER_CREATION_FAILED)
					)
					setAlertMessage(Messages.USER_CREATION_FAILED)
					setCreateSuccess(false)
				})
		}
	}

	useEffect(() => {
		if (notifications) {
			if (addAllNotifications(notifications)) {
				setMilestones((prevState) => ({
					...prevState,
					milestone1: true,
					
				}))
					
			} else {
				
				setMilestones((prevState) => ({
					...prevState,
					milestone1: false,
					
				}))
				
			}
		}
	}, [notifications])

	const handleSubmit = (evt) => {
		evt.preventDefault()
		validateForm()
	}

	const onChangeUserType = (event) => {
		setUserType(event.target.value)
		if (event.target.value === 'KU') {
			setUserRoleGroup('PORADMUSR')
			setUserTypeKey('KU')
			setBookingPrevilege(true)
			setCapturePrevilege(true)
			setDashboardPrevilege(true)
			setInformaticsPrevilege(true)
			setAllotmentPrevilege(true)
		} else {
			setUserRoleGroup('PORBKGUSR')
			setUserTypeKey('EU')
			setBookingPrevilege(false)
			setCapturePrevilege(false)
			setDashboardPrevilege(false)
			setInformaticsPrevilege(false)
			setAllotmentPrevilege(false)
		}
	}

	const onChangePhCountryCode = (event) => {
		setPhContryCode(event.target.value)
	}

	const onChangeMobCountryCode = (event) => {
		setMobContryCode(event.target.value)
	}

	const handleChangeMilestone = (event) => {
		if (event.target.id === 'milestone1') {
			MILESTONES.map((milestone) => {
				setMilestones((prevState) => ({
					...prevState,
					[milestone.id]: true,
				}))
			})
		}
		handleMilestone(event, notifications, setNotifications, setMilestones)
	}

	const handleChangePrevilege = (event) => {
		const key = event.target.id
		const isChecked = event.target.checked
		if (key === 'previlege0') {
			if (isChecked) {
				setBookingPrevilege(true)
				setCapturePrevilege(true)
				setDashboardPrevilege(true)
				setInformaticsPrevilege(true)
				setAllotmentPrevilege(true)
			} else {
				setBookingPrevilege(false)
				setCapturePrevilege(false)
				setDashboardPrevilege(false)
				setInformaticsPrevilege(false)
				setAllotmentPrevilege(false)
			}
		} else if (key === 'previlege1') {
			if (isChecked) {
				setBookingPrevilege(true)
			} else {
				return setBookingPrevilege(false)
			}
		} else if (key === 'previlege2') {
			if (isChecked) {
				setCapturePrevilege(true)
			} else {
				return setCapturePrevilege(false)
			}
		} else if (key === 'previlege3') {
			if (isChecked) {
				setDashboardPrevilege(true)
			} else {
				setDashboardPrevilege(false)
				setInformaticsPrevilege(false)
				setAllotmentPrevilege(false)
			}
		} else if (key === 'previlege4') {
			if (isChecked) {
				setInformaticsPrevilege(true)
			} else {
				return setInformaticsPrevilege(false)
			}
		} else {
			if (isChecked) {
				setAllotmentPrevilege(true)
			} else {
				setAllotmentPrevilege(false)
			}
		}
	}

	const getRequest = () => {
		const loggedInUserProfile = getLoggedInUserProfile()

		const privilegeDetails = {
			bookingPrivilege: bookingPrevilege,
			capturePrivilege: capturePrevilege,
			dashBoardPrivilege: dashboardPrevilege,
			informaticsPrivilege: informaticsPrevilege,
			allotmentPrivilege: allotmentPrevilege,
		}
		const roles = getRoles(privilegeDetails)
		const loggedInUserStation = loggedInUserProfile && loggedInUserProfile?.stationCode ? loggedInUserProfile?.stationCode : null
		const loggedInUserAirport = loggedInUserProfile && loggedInUserProfile?.defaultAirportCode ? loggedInUserProfile?.defaultAirportCode : null
		const requestBody = {
			requestId: Math.floor(Math.random() * 89999 + 10000),
			userDetails: {
				userId,
				firstName,
				middleName: null,
				lastName,
				email: emailAddress,
				telephoneNo:
					phContryCode !== '' && phoneNumber !== ''
						? `${phContryCode} ${phoneNumber}`
						: null,
				mobileNo:
					mobContryCode !== '' && mobileNumber !== ''
						? `${mobContryCode} ${mobileNumber}`
						: null,
				userType: userTypeKey,
				//roleGroup: userRoleGroup,
				//agentCode: loggedInUserProfile.agentCode,
				agentCode: agentCode,

				customerCode: isEnterpriseUser || isGsaUser ? (customerCode ? customerCode : null) : (loggedInUserProfile?.customerCode ? loggedInUserProfile.customerCode : null),
				companyName: isEnterpriseUser || isGsaUser ? (companyName ? companyName : null) : (loggedInUserProfile?.companyName ? loggedInUserProfile.companyName : null),
				companyCode: isEnterpriseUser || isGsaUser ? (companyCode ? companyCode : null) : (loggedInUserProfile?.companyCode ? loggedInUserProfile.companyCode : null),
				address: null,
				city: null,
				state: null,
				country: null,
				postalCode: null,
				stationCode: isEnterpriseUser || isGsaUser ? (stationCode ? stationCode : loggedInUserStation) : loggedInUserStation,
				defaultAirportCode: isEnterpriseUser || isGsaUser ? (defaultAirportCode ? defaultAirportCode : loggedInUserAirport) : loggedInUserAirport,
				// language: loggedInUserProfile.language
				// 	? loggedInUserProfile.language
				// 	: 'EN_US',
				notificationDetails: {
					notificationAction: notifications,
					notificationEmail: [emailAddress],
					notificationPhoneNo:
						mobContryCode && mobileNumber
							? [`${mobContryCode} ${mobileNumber}`]
							: null,
				},
				preference: preferences,
				roles: roles.length ? roles : null,
				// privilegeDetails: {
				// 	userId: userId,
				// 	userType: userTypeKey,
				// 	weightUnit: preferences.weightUnit,
				// 	dimensionUnit: preferences.dimensionUnit,
				// 	language: preferences.language,
				// 	bookingPrivilege: bookingPrevilege,
				// 	capturePrivilege: capturePrevilege,
				// 	dashBoardPrivilege: dashboardPrevilege,
				// },
			},
		}
		return requestBody
	}

	const removeError = (event) => {
		const id = event.target.id + ''
		setError((prevState) => ({ ...prevState, [id]: '' }))
	}

	const hasError = (key) => {
		if (error !== null && error[key] && error[key] !== '') {
			return true
		} else {
			return false
		}
	}

	const handleAlertClose = () => {
		setAlertMessage('')
		setCreateSuccess(false)
	}
	const onChangeLanguage = (event) => {
		setPreferences({ ...preferences, language: event.target.value })
	}

	const onChangeWeightUnit = (event) => {
		setPreferences({ ...preferences, weightUnit: event.target.value })
	}

	const onChangeDimensionUnit = (event) => {
		setPreferences({ ...preferences, dimensionUnit: event.target.value })
	}

	const triggerPhoneCodeValidation = () => {
		validatePhoneCountryCode(phContryCode, phoneNumber, setError)
	}

	const triggerMobPhoneCodeValidation = () => {
		validateMobileCountryCode(mobContryCode, mobileNumber, setError)
	}

	return (
		<div className='create-user-panel'>
			{alertMessage !== '' && (
				<Alert
					isError={!createSuccess}
					showAlert={true}
					message={t(alertMessage, { ns: 'errors' })}
					onAlertClose={handleAlertClose}
					setTimeout={true}
				/>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
			<h2 className='page-heading'>{t('COMMON.USER_INFO')}</h2>
			<div className='user-information-panel'>
				<div className='form-row'>
					<div
						className='col-12 col-md-6 col-lg-4 form-item form-item--text user_id'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.USER_ID')}</label>
						<input
							id='userId'
							type='text'
							{...bindUserId}
							className={`form-item__field`}
							maxLength='25'
							aria-invalid={hasError('userId')}
							onFocus={removeError}
							onBlur={(e) => {
								validateUserId(userId, setError)
							}}
						/>
						<span className='form-item__error'>
							{error && error['userId']
								? t(error['userId'], { ns: 'errors' })
								: ''}
						</span>
					</div>
				</div>
				<div className='form-row'>
					<div
						className='col-12 col-md-6 col-lg-4 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.FIRST_NAME')}</label>
						<input
							id='firstName'
							type='text'
							{...bindFirstName}
							className='form-item__field'
							maxLength='20'
							aria-invalid={hasError('firstName')}
							onFocus={removeError}
							onBlur={(e) => {
								validateName(firstName, 'firstName', setError, error)
							}}
						/>
						<span className='form-item__error'>
							{error && error['firstName']
								? t(error['firstName'], { ns: 'errors' })
								: ''}
						</span>
					</div>
					<div
						className='col-12 col-md-6 col-lg-4 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<input
							id='lastName'
							type='text'
							{...bindLastName}
							className='form-item__field'
							maxLength='20'
							aria-invalid={hasError('lastName')}
							onFocus={removeError}
							onBlur={(e) => {
								validateName(lastName, 'lastName', setError, error)
							}}
						/>
						<label className='form-item__label'>{t('COMMON.LAST_NAME')}</label>
						<span className='form-item__error'>
							{error && error['lastName']
								? t(error['lastName'], { ns: 'errors' })
								: ''}
						</span>
					</div>
				</div>
				<div className='form-row custom-mt-5'>
					<div className='col-12 col-md-4 form-item'>
						<Dropdown
							className='cust-dropdown'
							value={userType}
							options={USER_TYPES}
							label={t('COMMON.ACCOUNT_TYPE')}
							onChange={(e) => onChangeUserType(e)}
						></Dropdown>
					</div>
					{
						isEnterpriseUser || isGsaUser ? (
							<div className='col-12 col-md-4 form-item'>
								<label className='form-item__label'>{t('COMMON.AGT_CODE')}</label>
								<input 
									disabled='true'
									value={agentCode}
									id='agentCode'
									type='text'
									className='form-item__field'
									//maxLength='20'	
								/>

							</div>
						) : (<></>)
					}
					
					
				</div>
				<div className='form-row custom-mt-5'>
					<div className='col-12 col-md-12 user-privileges add-account'>
						<label className='form-item__label previlege_heading'>{t('UM.PRIVILEGES')}</label>
						<div className='form-row mt-3'>
							<div className='previlege_label col-12 col-md-auto'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='previlege0'
										checked={
											bookingPrevilege && capturePrevilege && dashboardPrevilege
											&& informaticsPrevilege && allotmentPrevilege
										}
										disabled={userTypeKey === 'KU'}
										value='previlege0'
										type='checkbox'
										name='previlege0'
										className='form-item__field'
										onChange={(e) => handleChangePrevilege(e)}
									/>
									<label htmlFor='previlege0' className='form-item__label'>
										{t('COMMON.ALL')}
									</label>
								</div>
							</div>
							<div className='previlege_label col-12 col-md-auto'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='previlege1'
										checked={bookingPrevilege}
										disabled={userTypeKey === 'KU'}
										value='previlege1'
										type='checkbox'
										name='previlege1'
										className='form-item__field'
										onChange={(e) => handleChangePrevilege(e)}
									/>
									<label htmlFor='previlege1' className='form-item__label'>
										{t('COMMON.BKG')}
									</label>
								</div>
							</div>
							<div className='previlege_label col-12 col-md-auto'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='previlege2'
										checked={capturePrevilege}
										disabled={userTypeKey === 'KU'}
										value='previlege2'
										type='checkbox'
										name='previlege2'
										className='form-item__field'
										onChange={(e) => handleChangePrevilege(e)}
									/>
									<label htmlFor='previlege2' className='form-item__label'>
										{t('COMMON.DATA_CAPTURE')}
									</label>
								</div>
							</div>
							<div className='previlege_label col-12 col-md-auto'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='previlege3'
										checked={dashboardPrevilege}
										value='previlege3'
										disabled={userTypeKey === 'KU'}
										type='checkbox'
										name='previlege3'
										className='form-item__field'
										onChange={(e) => handleChangePrevilege(e)}
									/>
									<label htmlFor='previlege3' className='form-item__label'>
										{t('UM.DASHBOARD_DEFAULT_PRVLG')}
									</label>
								</div>
							</div>

							<div className='previlege_label col-12 col-md-auto'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='previlege4'
										checked={informaticsPrevilege}
										value='previlege4'
										disabled={userTypeKey === 'KU' || !dashboardPrevilege}
										type='checkbox'
										name='previlege4'
										className='form-item__field'
										onChange={(e) => handleChangePrevilege(e)}
									/>
									<label htmlFor='previlege4' className='form-item__label'>
										{t('UM.IFOGRPCS_PRVLG')}
									</label>
								</div>
							</div>

							<div className='previlege_label col-12 col-md-auto'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='previlege5'
										checked={allotmentPrevilege}
										value='previlege5'
										disabled={userTypeKey === 'KU' || !dashboardPrevilege}
										type='checkbox'
										name='previlege5'
										className='form-item__field'
										onChange={(e) => handleChangePrevilege(e)}
									/>
									<label htmlFor='previlege5' className='form-item__label'>
										{t('UM.ALLOTMNT_PRVLG')}
									</label>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div className='form-row custom-mt-5'>
					<div
						className='col-12 col-md-6 col-lg-4 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.EMAIL_ID')}</label>
						<input
							id='emailAddress'
							type='text'
							{...bindEmailAddress}
							className='form-item__field'
							maxLength='100'
							aria-invalid={hasError('emailAddress')}
							onFocus={removeError}
							onBlur={(e) => {
								validateCreateUserEmailAddress(emailAddress, setError, error)
							}}
						/>
						<span className='form-item__error'>
							{error && error['emailAddress']
								? t(error['emailAddress'], { ns: 'errors' })
								: ''}
						</span>
					</div>
				</div>
				{/* <div className='form-row mt-5'>
				<div
					className='col-12 col-md-4 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>Confirm Email Address</label>
					<input
						id='confirmEmailAddress'
						type='text'
						{...bindConfirmEmailAddress}
						className='form-item__field'
						maxLength='100'
						aria-invalid={hasError('confirmEmailAddress')}
						onFocus={removeError}
						onBlur={(e) => {
							validateEmailAddress(
								emailAddress,
								confirmEmailAddress,
								setError,
								error
							)
						}}
					/>
					<span className='form-item__error'>
						{error && error['confirmEmailAddress']
							? error['confirmEmailAddress']
							: ''}
					</span>
				</div>
			</div> */}
				<div className='form-row custom-mt-5'>
					<div
						className='col-12 col-md-6 col-lg-4 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>
							{t('CREATEUSER.PHONE_NO')}
						</label>
						<div>
							<div className='form-row'>
								<div className={`col-4 col-md-4 pl-1
									${error && error['phContryCode'] ? 'red-border': ''}`}>
									{/* <Dropdown
										className='cust-dropdown'
										id='phContryCode'
										value={phContryCode}
										options={COUNTRY_CODES}
										defaultText='-'
										onChange={onChangePhCountryCode}
										onFocus={removeError}
										ariaInvalid={hasError('phContryCode')}
										onBlur={(e) => {
											validatePhoneCountryCode(
												phContryCode,
												phoneNumber,
												setError
											)
										}}
									/> */}
									<CountryCodeDropdown
										id ='phContryCode'
										name='phContryCode'
										countryCodes={sortedCountryCodes}
										userCountryCode={phContryCode}
										setCountryCode={setPhContryCode}
										triggerCodeValidation={triggerPhoneCodeValidation}
										setError={setError}
										removeError={removeError}
									/>
									<span className='form-item__error'>
										{error && error['phContryCode']
											? t(error['phContryCode'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div className='col-8 col-md-8'>
									<input
										id='phoneNumber'
										type='text'
										{...bindPhoneNumber}
										className='form-item__field'
										maxLength='15'
										aria-invalid={hasError('phoneNumber')}
										onFocus={removeError}
										onBlur={(e) => {
											validatePhoneNumber(
												phoneNumber,
												phContryCode,
												setError,
												error
											)
										}}
									/>
									<span className='form-item__error'>
										{error && error['phoneNumber']
											? t(error['phoneNumber'], { ns: 'errors' })
											: ''}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div
						className='col-12 col-md-6 col-lg-4 custom-m-0 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>
							{t('CREATEUSER.MOBILE_NO')}
						</label>
						<div>
							<div className='form-row'>
								<div
									className={`col-4 col-md-4 pl-1
									${error && error['mobContryCode'] ? 'red-border': ''}`}>
									{/* <Dropdown
										className='cust-dropdown'
										id='mobContryCode'
										value={mobContryCode}
										defaultText='-'
										options={COUNTRY_CODES}
										onChange={onChangeMobCountryCode}
										ariaInvalid={hasError('mobContryCode')}
										onFocus={removeError}
										onBlur={(e) => {
											validateMobileCountryCode(
												mobContryCode,
												mobileNumber,
												setError
											)
										}}
									/> */}
									<CountryCodeDropdown
										id ='mobContryCode'
										name='mobContryCode'
										autoCompleteType='MOB_COUNTRY_CODE'
										countryCodes={sortedCountryCodes}
										userCountryCode={mobContryCode}
										setCountryCode={setMobContryCode}
										triggerCodeValidation={triggerMobPhoneCodeValidation}
										setError={setError}
										removeError={removeError}
									/>
									<span className='form-item__error'>
										{error && error['mobContryCode']
											? t(error['mobContryCode'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div className='col-8 col-md-8'>
									<input
										id='mobileNumber'
										type='text'
										{...bindMobileNumber}
										className='form-item__field'
										maxLength='15'
										aria-invalid={hasError('mobileNumber')}
										onFocus={removeError}
										onBlur={(e) => {
											validateMobileNumber(
												mobileNumber,
												mobContryCode,
												setError,
												error
											)
										}}
									/>
									<span className='form-item__error'>
										{error && error['mobileNumber']
											? t(error['mobileNumber'], { ns: 'errors' })
											: ''}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className='form-row'>
					<h3 className='col-12 col-md-12 mt-5 page-sub-head'>
						{t('COMMON.PREF')}
					</h3>
				</div>
				<div className='form-row mt-1'>
					<div className='col-12 col-md-4 form-item form-item pl-2'>
						<Dropdown
							className='cust-dropdown'
							value={preferences.language}
							options={LANGUAGES}
							label={t('COMMON.LANG')}
							onChange={onChangeLanguage}
							disabled={!MULTILANGUAGE_SUPPORT_ENABLED}
						></Dropdown>
					</div>
				</div>
				<div className='form-row custom-mt-5'>
					<div className='col-6 col-md-2 custom-m-0 form-item pl-2'>
						<Dropdown
							className='cust-dropdown'
							value={preferences.weightUnit}
							options={WEIGHT_UNIT_SELECT_ITEMS}
							label={t('COMMON.WT')}
							onChange={onChangeWeightUnit}
						></Dropdown>
					</div>
					<div className='col-6 col-md-2 custom-m-0 form-item'>
						<Dropdown
							className='cust-dropdown'
							value={preferences.dimensionUnit}
							options={DIMENSION_UNIT_SELECT_ITEMS}
							label={t('COMMON.DIM')}
							onChange={onChangeDimensionUnit}
						></Dropdown>
					</div>
				</div>
			</div>
			<div className='form-row'>
				<h3 className='col-12 col-md-12 mt-5 page-sub-head'>
					{t('COMMON.NOTIFICATION')}
				</h3>
			</div>
			<div className='form-row mt-3 notifications-info'>
				<div className='form-row notify_list'>
					<div className='col-12 col-md-auto'>
						<div
							className='form-item form-item--checkbox'
							data-module='molecules/form-item/FormItem'
						>
							<input
								id='milestone1'
								checked={milestones.milestone1}
								value='All'
								type='checkbox'
								name='milestone1'
								className='form-item__field'
								onChange={handleChangeMilestone}
							/>
							<label htmlFor='milestone1' className='form-item__label'>
								{t('COMMON.ALL')}
							</label>
						</div>
					</div>
					<div className='col-12 col-md-auto'>
						<div
							className='form-item form-item--checkbox'
							data-module='molecules/form-item/FormItem'
						>
							<input
								id='milestone2'
								checked={milestones.milestone2}
								value='Accepted'
								type='checkbox'
								name='milestone2'
								className='form-item__field'
								onChange={handleChangeMilestone}
							/>
							<label htmlFor='milestone2' className='form-item__label'>
								{t('COMMON.ACCEPTED')}
							</label>
						</div>
					</div>
					<div className='col-12 col-md-auto'>
						<div
							className='form-item form-item--checkbox'
							data-module='molecules/form-item/FormItem'
						>
							<input
								id='milestone3'
								checked={milestones.milestone3}
								value='Departed'
								type='checkbox'
								name='milestone3'
								className='form-item__field'
								onChange={handleChangeMilestone}
							/>
							<label htmlFor='milestone3' className='form-item__label'>
								{t('COMMON.DEPARTED')}
							</label>
						</div>
					</div>
					<div className='col-12 col-md-auto'>
						<div
							className='form-item form-item--checkbox'
							data-module='molecules/form-item/FormItem'
						>
							<input
								id='milestone4'
								checked={milestones.milestone4}
								value='Arrived'
								type='checkbox'
								name='milestone4'
								className='form-item__field'
								onChange={handleChangeMilestone}
							/>
							<label htmlFor='milestone4' className='form-item__label'>
								{t('COMMON.ARRIVED')}
							</label>
						</div>
					</div>
					<div className='col-12 col-md-auto'>
						<div
							className='form-item form-item--checkbox'
							data-module='molecules/form-item/FormItem'
						>
							<input
								id='milestone5'
								checked={milestones.milestone5}
								value='Notified'
								type='checkbox'
								name='milestone5'
								className='form-item__field'
								onChange={handleChangeMilestone}
							/>
							<label htmlFor='milestone5' className='form-item__label'>
								{t('COMMON.NOTIFIED')}
							</label>
						</div>
					</div>
					<div className='col-12 col-md-auto'>
						<div
							className='form-item form-item--checkbox'
							data-module='molecules/form-item/FormItem'
						>
							<input
								id='milestone6'
								checked={milestones.milestone6}
								value='Delivered'
								type='checkbox'
								name='milestone6'
								className='form-item__field'
								onChange={handleChangeMilestone}
							/>
							<label htmlFor='milestone6' className='form-item__label'>
								{t('COMMON.DELIVERED')}
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className='form-row custom-mt-5'>
				<div className='cta_wrap ml-1 mt-4 col-12'>
					<button className='button ' onClick={handleSubmit}>
						{t('COMMON.SAVE')}
					</button>
					<button
						className='button_cancel button secondary custom-ml-4'
						onClick={() => setAddUser(false)}
					>
						{t('COMMON.CANCEL')}
					</button>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = ({ searchForm, loadingDetails }) => ({
	searchFormData: searchForm.searchFormData,
	loadingDetails: loadingDetails,
})
const mapDispatchToProps = (dispatch, message) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserForm)
