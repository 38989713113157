import React, { useEffect, useState } from 'react'
import { Message } from 'primereact/message'
import trashIcon from '../../../assets/images/trash.svg'
import { getPivotWeight, getULDTypeByCode } from '../../../services/searchPanelServices'
import ULDTypeAutocomplete from '../../ULDTypeAutocomplete'
import './item-form.scss'
import {
    removeDuplicates
} from '../templateUtils'

import { useTranslation } from 'react-i18next'
import MESSAGES from '../../../constants/Messages'
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown'

import infoicon from '../../../../src/assets/images/infoicon.png';
import pdf from '../../../../src/assets/images/pdf.png';
import excel from '../../../../src/assets/images/excel.png';
import word from '../../../../src/assets/images/word.png';
import walpaper from '../../../../src/assets/images/walpaper.png';
import document from '../../../../src/assets/images/document.png';
import { NUMERIC_REGEX } from '../../../../src/constants/regex'
import { validateunid } from '../../../services/dgBookingServices'

import closeIcon from '../../../assets/images/icon-close-alert.png'
import {
    AUTOCOMPLETE_TYPE,
    AUTOCOMPLETE_UNID,
    DIMENSION_UNIT,
    FORM_FIELDS,
    UNID_FORM_FIELDS,
    FRESH_FORWARD_PRODUCT,
    getInitLooseItemDetail,
    getInitPharmaObject,
    getInitULDItemDetail,
    PHARMA_PRODUCT,
    PHARMA_SHIPPING_TYPE,
    IS_KNOWN_UNKNOWN,
    PRICE_CODE_TYPE,
    SCC_CODES,
    SHIPPING_TYPE,
    VALIDATE_BOOKINGS_ERROR_CODES,
    WEIGHT_UNIT,
    NOTIFICATION_MESSAGE_TYPE,
    SYSTEM_ADDED_SCCS,
    PAGE_PRIMARY_CATEGORY,
    PAGE_SUB_CATEGORY1,
    getInitPetDetail,
    WEIGHT_UNIT_SELECT_ITEMS,
    DIMENSION_UNIT_SELECT_ITEMS
} from '../../../constants'
import { SHIPPING_ITEM_PLACEHOLDER } from '../../../constants/Placeholders'
import ShippingItemValidationPopUp from '../ShippingItemValidationPopUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    getFromSessionStorage,
} from '../../../utils/StorageUtils'

import ConfirmationPopUp from '../../../common/ConfirmationPopUp'
import UnidAutocomplete from '../../UnidAutocomplete'

import DgValidationPopup from './dgValidationPopup'

const DgMultiui = ({

    item,
    index,
    handleItemChange,

    error,
    uniderror,
    setArrayListDoc,
    arrayListDoc,
    shippingItem,
    handleAddAdditinalScc,
    clearError,

    setNotificationMessage,
    setNotificationType,
    handleSccChange,

    shippingDate,
    draftCallback,

    // setUnidName,
    // setText,
    // text,
    // unidSelectedValidation,
    // setUnidSelectedValidation,
    // unidName,
    // setSpecialProvison,
    // specialProvison,
    handleClear,
    setIsLoading,

    additionalInfoData,
    setAdditionalInfoData,
    displayDgValidationPopup,
    setDisplayDgValidationPopup,

    popupNotificationPopup,
    // setUnidSelectedValidation={setUnidSelectedValidation}

    // spUnid,

    specailProvialMessage,
    additionalSccs,
    setUnidList,
    unidList,
    dgcheck,
    isModifyBooking,
    searchFormData,
    setCargoCheck,
    setAutoAdd,
    handleRemoveAdditionalScc,
    cargoFlight,
    setCargoFlight,
    isonlyCargo,
    setIsonlyCargo,
    setAdditionalSccs,
    setExceptionTypeDg,

    spUnid,
    setSpUnid,
    selectedDestination,
    selectedOrigin,
    appliedSccs,
    setAppliedSccs,
    unidSccList,
    setUnidSccList,
    unidArray,

   setEmbargoErrorCheck,
   setSpecialProvisonErrorCheck,
   unidSccMasterData,
 

}) => {
    const[onBlurError,setOnBlurError]=useState(null)
    const[error3,setError3]=useState(null)
    const[hasError,setHasError]=useState()
    const[unidWarning, setUnidWarning]= useState(null)
    const [unid, setUnid] = useState(item?.unid)
    const [unidSelectedValidation, setUnidSelectedValidation] = useState(item?.unidSelectedValidation)
    const [unidMultipleValidation, setUnidMultipleValidation] = useState(item && item.unidMultipleValidation ? item.unidMultipleValidation : [])
    const [unidName, setUnidName] = useState(item?.unidName)
    const [specialProvison, setSpecialProvison] = useState()
    // const [cargoCheck, setCargoCheck] = useState(item.cargoCheck)
    const [packingGroup, setPackingGroup] = useState(item?.packingGroup)
    const [dangerousGoods, setdangerousGoods] = useState(true)

    const [multiunidCheck, setMultiunidCheck] = useState(item && item.multiunidCheck
        && item.multiunidCheck.length > 1 ? item.multiunidCheck : [])
    const [dropdown, setDropValue] = useState(item && item.dropdown
        && item.dropdown !== '' ? item.dropdown : '')

    const [sccWarning, setSccWarning] = useState(null)
    const [documentRequired, setDocumentRequired] = useState({})
    const [unidSccs, setUnidSccs] = useState(item && item.unidSccs ? item.unidSccs : [])
    const [cargoType, setCargoType] = useState('')

    const { t, i18n } = useTranslation(['labels', 'errors'])
    const { header, setHeader } = useState("Document Required")

    const CONTOUR_ITEMS = [
        { label: t('BOOKING.MAIN_DECK'), value: 'MD' },
        { label: t('BOOKING.LOWER_DECK'), value: 'LD' },
    ]

    const [displayUploadSection, setDisplayUploadSection] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileName, setFileName] = useState('');
    const [shcValues, setshcValues] = useState([])

    const [validationMsg, setValidationMsg] = useState(false);

    // const [unidID, setUnidID] = useState("")
    const [id, setId] = useState(item?.id)
    const [multiUnidSccs, setMultiUnidSccs] = useState([])

    const closeDialog = () => {
        setDisplayInfo(false)
    }

    useEffect(() => {
        handleItemChange(index, {
            id,
            unid,
            unidSelectedValidation,
            unidName,
            specialProvison,
            dangerousGoods,
            packingGroup,
            unidSccs,
            cargoType,
            multiunidCheck,
            dropdown,
        })
    }, [
        id,
        unid,
        unidSelectedValidation,
        unidName,
        specialProvison,
        dangerousGoods,
        packingGroup,
        cargoType,
        dropdown,
        unidSccs
    ])

    useEffect(()=>{
        setError3(error)
    })

    useEffect(() => {

        if (unidSelectedValidation && unidSelectedValidation.length > 0) {
            /*if(unidSelectedValidation[0]?.embargoRestriction) {
                setCargoType('EMBARGO_RESTRICTION')
            }*/
            if (multiunidCheck?.length <= 1 || (multiunidCheck?.length > 1 && dropdown)) {
                if (!unidSelectedValidation[0]?.passagerAircraftAllowed && !unidSelectedValidation[0]?.cargoAircraftAllowed &&
                    unidSelectedValidation[0]?.hasSpecialProvision) {
                    setCargoType('SPECIAL_PROVISION')
                } else if (unidSelectedValidation[0]?.passagerAircraftAllowed &&
                    unidSelectedValidation[0]?.cargoAircraftAllowed) {
                    setCargoType('CARGO_CHECK')
                    setCargoCheck(true)

                } else if (!unidSelectedValidation[0]?.passagerAircraftAllowed &&
                    unidSelectedValidation[0]?.cargoAircraftAllowed) {
                    setCargoType('CARGO_ONLY')
                    setCargoCheck(true)
                }
            }
        }

    }, [unidSelectedValidation])



    useEffect(() => {
        // setUnidSelectedValidation({})

        if (unidList.length === 1 && unid == '') {
            const scccao = {
                "scc": "CAO",
                "sccDescription": "CARGO AIRCRAFT ONLY",
                'readOnly': true
            }
            setCargoCheck(false)
            setAutoAdd(false)
            setCargoFlight()
            removeDuplicates([scccao])



            // shcValues.forEach(handleRemoveAdditionalScc(item))


            let filteredScc = additionalSccs.some((item) => item.scc === 'CAO')
            if (filteredScc) {
                //handleRemoveAdditionalScc(scccao)
                let tempAppliedSccs = [...appliedSccs]
                if (tempAppliedSccs.includes(scccao)) {
                    tempAppliedSccs = tempAppliedSccs.splice(tempAppliedSccs.indexOf(scccao), 1);
                }
                setAppliedSccs([...tempAppliedSccs], [scccao])
            }

            shcValues?.map(async (e, i) => {

                handleRemoveAdditionalScc(e)
                // setshcValues(shcValues.filter((item) => item.scc !== e.scc))

            })
            setIsonlyCargo(false)


        }
        else if (unidList.length === 1 && unid != '') {
            setCargoCheck(true)
            setIsonlyCargo(false)
            // setCargoFlight()
        }
        if(unid){
            setCargoCheck(true)
        }
        if(unid?.length<4){
            setCargoFlight('')
        }
        setAutoAdd(false)
        setSpecialProvison()
        setUnidSelectedValidation()

    }, [unid])

    useEffect(() => {
		if (unid) {
			error
				? setError3({
					...error,
					[FORM_FIELDS.UNID]:null,
				})
				: setError3({
					[FORM_FIELDS.UNID]:null,
				})
		}

	}, [unid])

    useEffect(() => {
        if (item) {
            if (item.unid && item?.unid !== unid) {
                setId(item?.id)
                setUnid(item?.unid)
                setUnidName(item?.unidName)
                setPackingGroup(item?.packingGroup)
                setSpecialProvison(item?.specialProvison)
                setUnidSccs(item.unidSccs ? item.unidSccs : [])
                setdangerousGoods(item?.dangerousGoods)
                setCargoType(item.cargoType ? item.cargoType : '')
                setMultiunidCheck(item.multiunidCheck ? item.multiunidCheck : [])
                if (item.unidSelectedValidation && item.unidSelectedValidation.length > 0) {
                    setUnidSelectedValidation(item?.unidSelectedValidation)
                } else if (item.unidName) {
                    const data = {
                        "id": item?.id,
                        "unid": item?.unid,
                        "shippingName": item?.unidName,
                        "packingGroup": item?.packingGroup,
                        "classDivision": item?.classDivision,
                        "origin": selectedOrigin?.cityCode,
                        "destination": selectedDestination?.cityCode,
                        "originCountry": selectedOrigin?.countryCode,
                        "destinationCountry": selectedDestination?.countryCode,
                    }
                    validateunid(data)
                        .then((res) => {
                            setUnidSelectedValidation(res)

                        })
                        .catch((err) => {
                            console.error(err);
                        })
                }
                setDropValue(item.dropdown ? item.dropdown : '')
            }
            else if (item.dropdown && item?.unid === unid && (!dropdown || item.dropdown != dropdown || dropdown === '')) {
                setUnidSccs(item.unidSccs ? item.unidSccs : [])
                setDropValue(item.dropdown ? item.dropdown : '')
                setCargoType(item.cargoType ? item.cargoType : '')
                setMultiunidCheck(item.multiunidCheck ? item.multiunidCheck : [])
                setDropValue(item.dropdown ? item.dropdown : [])
            }
            if (item.unid && item?.unid === unid && (item.unidSelectedValidation && item.unidSelectedValidation.length < 1) && item.unidName) {
                const data = {
                    "id": item?.id,
                    "unid": item?.unid,
                    "shippingName": item?.unidName,
                    "packingGroup": item?.packingGroup,
                    "classDivision": item?.classDivision,
                    "origin": selectedOrigin?.cityCode,
                    "destination": selectedDestination?.cityCode,
                    "originCountry": selectedOrigin?.countryCode,
                    "destinationCountry": selectedDestination?.countryCode,
                }
                validateunid(data)
                    .then((res) => {
                        setUnidSelectedValidation(res)

                    })
                    .catch((err) => {
                        console.error(err);
                    })
            }
        }

    }, [item])

  
    const handleSetError = (key, text) => {
		error
			? setError3({
				...error,
				[key]: text,
			})
			: setError3({
				[key]: text,
			})
	}

    const customClose = (
        <i>
            <img src={closeIcon} alt='close' title='close' onClick={closeDialog} />
        </i>
    )

    const setDropDownFileList = (list) => {
        setFileList(list)
    }

    const handelDocRequired = (event) => {

        setDocumentRequired((prevState) => ({
            ...prevState,
            [documentRequired.id]: true,
        }))
    }

    const handleRemoveItem = async (index) => {

        let tempUnidSccList = handleUnidSccList()

        let tempAppliedSccs = handleAppliedUnidSccs(tempUnidSccList)

        setUnidSccList(tempUnidSccList)

        handleCAODelete(tempAppliedSccs, true)
    }

    const handleCAODelete = (tempAppliedSccs, isDelete) => {
        const dgitemToDelete = unidList[index]
        setDropValue('')
        let itemsTemp = []
        let unidListTemp = [...unidList]

        unidListTemp.forEach((itemDetail, curr) => {
            if (index !== curr) {
                itemsTemp.push(itemDetail)
            }
        })
        let hasCargoOnly = false
        let hasCargoCheck = false

        if (dgitemToDelete && (dgitemToDelete.cargoType === 'CARGO_ONLY' || dgitemToDelete.cargoType === 'CARGO_CHECK' || dgitemToDelete.cargoType === 'SPECIAL_PROVISION')) {
            itemsTemp.forEach((dgItem) => {
                if (dgItem.cargoType === 'CARGO_ONLY') {
                    hasCargoOnly = true
                } else if (dgItem.cargoType === 'CARGO_CHECK') {
                    hasCargoCheck = true
                }
            })
        }
        if (dgitemToDelete && dgitemToDelete.cargoType === 'SPECIAL_PROVISION' && (hasCargoCheck)) {
            setCargoCheck(true)
        }

        if (!hasCargoOnly && (!hasCargoCheck || (hasCargoCheck && !cargoFlight))) {
            tempAppliedSccs = tempAppliedSccs.filter((sccItem, curr) => {
                return sccItem.scc !== 'CAO'
            })
            setIsonlyCargo(false)
            if (cargoFlight !== false) {
                setCargoCheck('')
            }
        }

        setTimeout(() => {
            if (isDelete) {
                setUnidList(itemsTemp)
            }
            if (!hasCargoOnly && hasCargoCheck) {
                setCargoCheck(true)
                setIsonlyCargo(false)
            } else if (!hasCargoOnly && !hasCargoCheck) {
                setCargoCheck(false)
                setIsonlyCargo(false)
            }
            setAppliedSccs([...tempAppliedSccs])
        }, 200);

    }

    const handleCAOMultiple = (validationRes, tempAppliedSccs) => {
        const selecedDgitem = unidList[index]
        let unidListTemp = [...unidList]

        let hasCargoOnly = false
        let hasCargoCheck = false

        unidListTemp.forEach((dgItem) => {
            if (dgItem.unid !== selecedDgitem.unid || (dgItem.unid === selecedDgitem.unid && dgItem.dropdown !== selecedDgitem.dropdown)) {
                if (dgItem.cargoType === 'CARGO_ONLY') {
                    hasCargoOnly = true
                } else if (dgItem.cargoType === 'CARGO_CHECK') {
                    hasCargoCheck = true
                }
            }
        })
        if (!hasCargoOnly && (!hasCargoCheck || (hasCargoCheck && (!cargoFlight || cargoFlight === '')))) {
            let filteredScc = appliedSccs.some((item) => item.scc === 'CAO')

            if (!validationRes?.passagerAircraftAllowed &&
                validationRes?.cargoAircraftAllowed) {

                const scccao = {
                    "scc": "CAO",
                    "sccDescription": "CARGO AIRCRAFT ONLY",
                    'readOnly': true
                }

                if (!filteredScc) {
                    tempAppliedSccs.push(scccao)
                }
                setAutoAdd(true)
                setIsonlyCargo(true)
            } else if (validationRes?.passagerAircraftAllowed &&
                validationRes?.cargoAircraftAllowed) {
                setCargoCheck(true)
                if (filteredScc) {
                    tempAppliedSccs = tempAppliedSccs.filter((sccItem, curr) => {
                        return sccItem.scc !== 'CAO'
                    })
                }
                setIsonlyCargo(false)
            } else {
                setIsonlyCargo(false)
                setCargoCheck(false)
                if (filteredScc) {
                    tempAppliedSccs = tempAppliedSccs.filter((sccItem, curr) => {
                        return sccItem.scc !== 'CAO'
                    })
                }
            }

        }

        setTimeout(() => {
            tempAppliedSccs = removeDuplicates(tempAppliedSccs)
            setAppliedSccs([...tempAppliedSccs])
        }, 100);

    }

    const handleUnidSccList = () => {
        let tempUnidSccList = [...unidSccList]
        const tempUnidScc = [...unidSccs]

        tempUnidScc.forEach((sccItem) => {
            if (tempUnidSccList.includes(sccItem)) {          //checking weather array contain the id
                tempUnidSccList.splice(tempUnidSccList.indexOf(sccItem), 1);  //deleting
            }
        })
        return tempUnidSccList;
    }

    const handleAppliedUnidSccs = (tempUnidSccList) => {
        let tempAppliedSccs = [...appliedSccs]
        var numOfItems = appliedSccs.length - 1;
        while (numOfItems >= 0) {
            if (!tempUnidSccList.includes(appliedSccs[numOfItems].scc) && appliedSccs[numOfItems].scc !== 'CAO' && unidSccMasterData.includes(appliedSccs[numOfItems].scc)) {
                tempAppliedSccs.splice(tempAppliedSccs.indexOf(appliedSccs[numOfItems]), 1);  //deleting
            }
            numOfItems -= 1;
        }

        return tempAppliedSccs
    }

    const handelChangeCargos = () => {
        if (multiunidCheck?.length <= 1) {
            const scccao = {
                "scc": "CAO",
                "sccDescription": "CARGO AIRCRAFT ONLY",
                'readOnly': true
            }
            // setCargoCheck(false)
            setAutoAdd(true)
            setIsonlyCargo(true)
            removeDuplicates([scccao])
            //setCargoType('ONLY_CARGO')

            let filteredScc = appliedSccs.some((item) => item.scc === 'CAO')

            if (!filteredScc) {
                //handleAddAdditinalScc(scccao)
                let tempAppliedSccs = [...appliedSccs]
                tempAppliedSccs.push(scccao)
                setAppliedSccs(tempAppliedSccs)
            }
        }

    }
   
    const handleCAOqustn = () => {

        setCargoCheck(true)
        //setCargoType('CARGO_CHECK')

        if (!isonlyCargo) {
            // setCargoCheck(true)
        } else {
            //setCargoCheck(false)
        }

    }



    const [displayInfo, setDisplayInfo] = useState(false)
    // const [cargoOnly, setCargoOnly] = useState([])

    const handelPopup = () => {
        setDisplayInfo(true)
    }


    function removeErrorMsg() {



    }

    const validateMultipleunid = (id, unid, unidName, packagingGroup) => {
        const splitedClass = shippingItem?.description?.split(" ");

        const data = {
            "id": id,
            "unid": unid,
            "shippingName": unidName,
            "packingGroup": packagingGroup,
            "classDivision": splitedClass[1],
            "origin": selectedOrigin?.cityCode,
            "destination": selectedDestination?.cityCode,
            "originCountry": selectedOrigin?.countryCode,
            "destinationCountry": selectedDestination?.countryCode,
        }

        validateunid(data)
            .then((res) => {
                setUnidSelectedValidation(res)
                setEmbargoErrorCheck(true)
                if (res && res.length > 0 && res[0].shcCodes) {
                    setUnidSelectedValidation(res)
                    let shcCode = []
                    let shcCodeVals = [];
                    const splitedArray = res[0].shcCodes.split(',');
                    let sccMasterData = getFromSessionStorage('master-data-scc-map')
                    if (sccMasterData) {
                        sccMasterData = JSON.parse(sccMasterData)
                    }
                    splitedArray.map(async (splitedItem, index) => {
                        const shcItemC = {
                            "scc": splitedItem,
                            "sccDescription": sccMasterData[splitedItem]
                        }



                        shcCode.push(shcItemC)
                        shcCodeVals.push(splitedItem)

                    })
                    //setAdditionalSccs([...additionalSccs, ...shcCode])
                    let tempUnidSccList = [...unidSccList]

                    const tempUnidScc = [...multiUnidSccs]
                    tempUnidScc.forEach((sccItem) => {
                        if (tempUnidSccList.includes(sccItem)) {          //checking weather array contain the id
                            tempUnidSccList.splice(tempUnidSccList.indexOf(sccItem), 1);  //deleting
                        }
                    })
                    
                    tempUnidSccList = [...tempUnidSccList, ...shcCodeVals]

                    setUnidSccList(tempUnidSccList)

                    let tempAppliedSccs = handleAppliedUnidSccs(tempUnidSccList)
                    tempAppliedSccs = [...tempAppliedSccs, ...shcCode]
                    setAppliedSccs(tempAppliedSccs)

                    removeDuplicates(shcCode)
                    setMultiUnidSccs(shcCodeVals)

                    setUnidSccs(shcCodeVals)
                    setUnidName(res[0].shippingName ? res[0].shippingName : unidName)
                    setId(res[0].id ? res[0].id : id)
                    setPackingGroup(res[0].packingGroup ? res[0].packingGroup : packingGroup)

                    setSpecialProvison(res[0].specialProvison ? res[0].specialProvison : specialProvison)
                    handleCAOMultiple(res[0], tempAppliedSccs)
                }

            })
            .catch((err) => {
                setNotificationMessage &&
                    setNotificationMessage(
                        'Additional Sccs Error :' + err.message ? err.message : ''
                    )
                setNotificationType &&
                    setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
            })

        // handleAddAdditinalScc({
        // 	unid: e.value.unid,
        // 	// sccDescription: e.value.sccDescription,
        // })
    }

    return (
        <div
            className={`item-group uld-form pl-3 ${unidList?.length === index ? 'last-item' : ''
                }`}
            key={index}
        >
            <div className='search-panel__hiddenmsg'>
            { 
                  uniderror= error &&
                   error[FORM_FIELDS.UNID] &&
                  error[FORM_FIELDS.UNID][index] &&
                  error[FORM_FIELDS.UNID][index][UNID_FORM_FIELDS.UNID]                         
             }

            </div>

            <div className='form-row ' style={{ marginBottom: "-15px" }}>
                <div
                    className={`col-md-4 form-group col-12 col-lg-12`}
                >
                    <div className='row'>
                        <>
                            <div className={`form-group col-12 col-lg-2 custom-padding-dg dg-unid ${uniderror ? 'red-border' : ''
									}` } key={index}>
                                <label className="specify-id">{t('COMMON.UNID_LABEL')}</label>
                                <div  class='input-wrapper dg-unid-input ' id="errorbox">
                                    <UnidAutocomplete
                                    autoCompleteType={AUTOCOMPLETE_UNID.UNID}
                                        handleAddAdditinalScc={handleAddAdditinalScc}
                                        removeDuplicates={removeDuplicates}
                                        index={index}
                                        clearError={clearError}
                                        setSccWarning={setSccWarning}
                                        setNotificationMessage={setNotificationMessage}
                                        setNotificationType={setNotificationType}
                                        handleSccChange={handleSccChange}
                                        setUnidName={setUnidName}
                                        setPackingGroup={setPackingGroup}
                                        setUnid={setUnid}
                                        unid={unid}
                                        hasError={hasError}
                                        unidSelectedValidation={unidSelectedValidation}
                                        setUnidSelectedValidation={setUnidSelectedValidation}
                                        handleSetError={handleSetError} 
                                        setMultiunidCheck={setMultiunidCheck}
                                        multiunidCheck={multiunidCheck}
                                        additionalSccs={additionalSccs}
                                        setAdditionalSccs={setAdditionalSccs}
                                        setshcValues={setshcValues}
                                        shcValues={shcValues}
                                        shippingItem={shippingItem}
                                        selectedDestination={selectedDestination}
                                        selectedOrigin={selectedOrigin}
                                        appliedSccs={appliedSccs}
                                        setAppliedSccs={setAppliedSccs}
                                        unidSccs={unidSccs}
                                        setUnidSccs={setUnidSccs}
                                        unidSccList={unidSccList}
                                        setUnidSccList={setUnidSccList}
                                        // setUnidID={setUnidID}
                                        setId={setId}
                                        unidArray={unidArray}
                                        setUnidWarning={setUnidWarning}
                                        error={error}
                                        error3={error3}
                                        unidList={unidList}

                                        onBlurError={onBlurError}
                                        handleUnidSccList={handleUnidSccList}
                                        handleAppliedUnidSccs={handleAppliedUnidSccs}
                                        handleCAODelete={handleCAODelete}                         
                                    />                                    
                                     </div>

                                         <span className='search-panel__errormsg' key={index}> { 
                                                (error &&
                                                error[FORM_FIELDS.UNID] &&
                                                error[FORM_FIELDS.UNID][index] &&
                                               error[FORM_FIELDS.UNID][index][UNID_FORM_FIELDS.UNID]) ||onBlurError
                                               ? t(error[FORM_FIELDS.UNID][index][UNID_FORM_FIELDS.UNID], { ns: 'errors' })
                                               : null
                                            
                                                   }
                                         </span> 
                                            
                            </div>
                            

                            {unidName !== undefined && unid != '' ?

                                <>
                                    <div className={`form-group col-12 col-lg-5 custom-padding-dg `}>
                                        <label className="specify-id">{t('COMMON.PROPER_SNAME')}</label>
                                        <div class='input-wrapper dg-unid-input'>
                                            {multiunidCheck.length <= 1 ?

                                                <>
                                                    <div className='flex'>
                                                    
                                                        <div
                                                        
                                                            id={index}
                                                            type='text'
                                                            // value={}
                                                            // value={unidName}
                                                            className={`form-item__field-draft dgunidName breedName div-readonly `}
                                                            readOnly={true}
                                                            style={{ fontWeight: 'bold', paddingTop: "8px" }}
                                                        >

                                                            {packingGroup == '' ? `${unidName}` : `  ${unidName} `}
                                                            <span style={{ fontWeight: 'lighter' }}>
                                                                {packingGroup == '' ? '' : ` (PG ${packingGroup})`}
                                                            </span>
                                                        </div>
                                                        {setEmbargoErrorCheck(true)}
                                                        {setSpecialProvisonErrorCheck(true)}
                                                    </div>
                                                </> :

                                                <>

                                                    <Dropdown
                                                        id={index}
                                                        options={multiunidCheck}
                                                        className='form-item__field-draft dgunidName '
                                                        style={{ fontWeight: 'bold' }}
                                                        placeholder={"Select"}
                                                        value={dropdown}
                                                        onChange={(e) => {

                                                            setDropValue(e.target.value)
                                                            setUnidName(unidName)
                                                            const idValue = e.value.split('+')

                                                            validateMultipleunid(Number(idValue[1]), unid, e.target.value, packingGroup)
                                                        }
                                                        }
                                                    />

                                                </>
                                            }

                                        </div>                                            
                                    </div>
                                </> : <> </>
                            }

                            {unidList.length > 1 ? (
                                <div className='form-group col-4 col-lg-2 delete-div'>
                                    <div className='uld-form delete-section'>
                                        <img
                                            src={trashIcon}
                                            onClick={(e) => handleRemoveItem(index)}
                                            alt='Trash'
                                            title='Trash'
                                            id={index}
                                        />
                                        <span>{t('COMMON.DELETE')}</span>
                                    </div>
                                </div>
                            ) : <></>}

                        </>

                    </div>


                    {

                        unidSelectedValidation && unidSelectedValidation !== '' && unidSelectedValidation[0] !== undefined ?
                            (unidSelectedValidation[0]?.embargoRestriction ?
                                (
                                    <>

                                        <div className='row'>
                                            {setCargoFlight(false)}
                                            {
                                                setCargoCheck(false)
                                            }
                                            {/* {setEmbargoErrorCheck(true)} */}
                                            <div className='col-md col-lg-12 warning-info'>
                                                <Message
                                                    severity='warn'
                                                    text={t('COMMON.UNIDERR1')}
                                                ></Message>
                                            </div>
                                        </div>
                                    </>) :
                                !unidSelectedValidation[0]?.passagerAircraftAllowed && !unidSelectedValidation[0]?.cargoAircraftAllowed ?
                                    unidSelectedValidation[0]?.hasSpecialProvision ?
                                        <>
                                            {setCargoFlight(false)}
                                            {
                                                setCargoCheck(false)
                                            }
                                            {setSpecialProvisonErrorCheck(false)}
                                            <div className='row additional-dg-style 2-div'>
                                                <div className={`col-md form-group col-lg-12`} >
                                                    <label className='dg-label'>{t('COMMON.UNID_SPECIAL')}</label>
                                                </div>

                                            </div>

                                            <div className={`col-md col-lg-12 `}>
                                                <div className='form-item codedg-type-btn code-type-btn-left'>
                                                    <RadioButton
                                                        id='dg-code-btn'
                                                        className='select-button '
                                                        value={"Yes"}
                                                        onChange={(e) => {
                                                            {error[FORM_FIELDS.PROVISON_ERROR]=null}
                                                            {setSpecialProvisonErrorCheck(true)}
                                                            setSpecialProvison(true)
                                                            specailProvialMessage(e.target.value)
                                                            setExceptionTypeDg('DG_SP')
                                                            setSpUnid(unid)

                                                        }}
                                                        checked={specialProvison === true}
                                                    ></RadioButton>
                                                    <label className='dgcheck-label'>
                                                        {t('COMMON.YES')}
                                                    </label>

                                                    <RadioButton
                                                        id='dgn-code-btn'
                                                        className='select-button'
                                                        value={"No"}
                                                        onChange={(e) => {
                                                            {error[FORM_FIELDS.PROVISON_ERROR]=null}
                                                            {setSpecialProvisonErrorCheck(true)}
                                                            // setSpotRateCode(promoCode)
                                                            // setPromoCode(null)
                                                            setSpecialProvison(false)
                                                            // setUnid('')
                                                            // setUnidName()
                                                            // setPackingGroup('')
                                                            specailProvialMessage(e.target.value)
                                                            // setPetOrder(index)
                                                        }}
                                                        checked={specialProvison === false}
                                                    ></RadioButton>
                                                    <label className='dgcheckn-label'> {t('COMMON.NO')}</label>
                                                </div>
                                                <span className={`search-panel__errormsg`}> {error && error[FORM_FIELDS.PROVISON_ERROR]
														? t(error[FORM_FIELDS.PROVISON_ERROR], {ns: 'errors' })
														: null} </span>
                                            </div>
                                        </>

                                        :
                                        (!unidSelectedValidation[0]?.passagerAircraftAllowed && !unidSelectedValidation[0]?.cargoAircraftAllowed && !unidSelectedValidation[0]?.hasSpecialProvision ?
                                            <>
                                                {setCargoFlight(false)}
                                                {
                                                    setCargoCheck(false)
                                                }
                                               
                                                <>
                                                    <div className='row'>
                                                        <div className='col-md col-lg-12 warning-info'>
                                                            <Message
                                                                severity='warn'
                                                                text={t('COMMON.UNIDERR6')}
                                                            ></Message>
                                                            {setEmbargoErrorCheck(false)}
                                                        </div>
                                                    </div>
                                                    

                                                </>
                                               




                                            </>
                                            : <></>)

                                    :
                                    (!unidSelectedValidation[0]?.passagerAircraftAllowed &&
                                        unidSelectedValidation[0]?.cargoAircraftAllowed) ?
                                        handelChangeCargos()

                                        :
                                        // unidSelectedValidation[0]?.cargoShippmentQuestion ?
                                        (unidSelectedValidation[0]?.passagerAircraftAllowed &&
                                            unidSelectedValidation[0]?.cargoAircraftAllowed) ?
                                            handleCAOqustn()
                                            : <></>
                            )
                            : <></>
                            

                    }
                </div>

                {
                    displayDgValidationPopup ?
                        (
                            <DgValidationPopup
                                additionalInfoData={additionalInfoData}
                                setAdditionalInfoData={setAdditionalInfoData}
                                displayDgValidationPopup={displayDgValidationPopup}
                                setDisplayDgValidationPopup={setDisplayDgValidationPopup}
                                handleClear={handleClear}
                                selectedDestination={selectedDestination}
                                selectedOrigin={selectedOrigin}
                                shippingDate={shippingDate}
                                shippingItem={shippingItem}
                                setIsLoading={setIsLoading}
                                setNotificationMessage={setNotificationMessage}
                                setNotificationType={setNotificationType}
                                additionalSccs={additionalSccs}
                                popupNotificationPopup={popupNotificationPopup}
                                // setUnidSelectedValidation={setUnidSelectedValidation}
                                specialProvison={specialProvison}
                                setUnidSelectedValidation={setUnidSelectedValidation}

                                spUnid={spUnid}
                                setUnid={setUnid}
                                setSpUnid={setSpUnid}
                                setUnidName={setUnidName}



                            />
                        ) : (
                            <></>
                        )
                }
            </div>
        </div >
    )
}

export default DgMultiui
