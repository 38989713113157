import React from 'react'
import './ICP.scss'

const ICPInformation =() => {
    return (
     <div className='col col-xs-12 col-sm-12 col-md-6 col-lg-6 ICPfooter'>
         	<a
				className='navigation__link '
				href='https://beian.miit.gov.cn/'>
				Shanghai, ICP prepared No. 14027180
			</a>
															
																
         </div>
    )
}
export default ICPInformation