import React from 'react'
import UpdateUserForm from './UpdateUserForm'
import './index.scss'

const UpdateUser = ({
	userId,
	setUpdateUser,
	setUpdateUserSuccess,
	updateUserSuccess,
	listUsers,
}) => {
	return (
		<div className=''>
			<UpdateUserForm
				userCode={userId}
				setUpdateUser={setUpdateUser}
				setUpdateUserSuccess={setUpdateUserSuccess}
				updateUserSuccess={updateUserSuccess}
				listUsers={listUsers}
			/>
		</div>
	)
}

export default UpdateUser
