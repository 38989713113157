import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import ViewBooking from './ViewBooking'
import ManageBooking from './ManageBooking'
import getCurrentUser from '../../utils/getCurrentUser'

const ManageBookingRoot = () => {
	const history = useHistory()
	const userDetails = getCurrentUser()


	return userDetails && Object.keys(userDetails).length !== 0 ? (
		<>
			<div className='container-lg cargo-portal-container'>
				<div className='manage-booking'>
					<Switch>
						<Route
							exact
							path='/manage-booking'
							render={() => {
								history.push('/manage-booking/list')
							}}
						/>
						<Route
							exact
							path='/manage-booking/list'
							component={ManageBooking}
						/>
						<Route
							exact
							path='/manage-booking/:awbNo/:ubrNumber?'
							component={ViewBooking}
						/>
					</Switch>
				</div>
			</div>
		</>
	) : (
		<></>
	)
}

export default ManageBookingRoot
