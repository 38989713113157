import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import './popup.scss'

const Popup = ({
	display,
	header,
	message,
	errorDataList,
	primaryBtnLbl,
	acceptFn = () => { },
	secondaryBtnLbl,
	rejectFn,
	closeErrorPopup,
	icon,
	openOnAccept,
	onHide = () => { },
}) => {

	const footer = (
		<div className='popup-modal'>
			<Button
				label={primaryBtnLbl ? primaryBtnLbl : 'Ok'}
				className='button-first '
				id="button-first-ok"
				type='button'
				onClick={(e) => {
					acceptFn(e)
					if (!openOnAccept) {
						closeErrorPopup()
					}
				}}
			/>
			{secondaryBtnLbl && rejectFn ? (
				<Button
				 	label={secondaryBtnLbl}
				 	className='button-second'
					id="button-first-cancel"
				 	type='button'
				 	onClick={() => {
				 		rejectFn()
				 		closeErrorPopup()
				 	}}
				/>
			) : null}
		</div>
	)

	return display ? (
		<>
			<div className='portal-popup-modal'>
				<Dialog
					footer={footer}
					visible
					closable={false}
					modal
					header={header}
					onHide={onHide}
					className = 'dialogWrapper'
				>
					<div>
						<p>{message}</p>
						{errorDataList &&
						 errorDataList
						}
					</div>
				</Dialog>
			</div>
		</>
	) : (
		<></>
	)
}

export default Popup
