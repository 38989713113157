import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useReactToPrint } from 'react-to-print';
import PermanentBooking from './PermanentBooking';
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import BookingMenu from "../BookingMenu";
import SeasonalBooking from '../SeasonalBooking/SeasonalBooking';
//import { getSeasonalBookingList } from '../../redux/actions/seasonalBookingActions';
import getCurrentUser from '../../utils/getCurrentUser'
import { v4 as uuidv4 } from 'uuid'
import PermanentBookingList from './PermanentBookingList'
import {
    //getPermanentBookingFileList,
    getSeasonalBookingList,
    getPermanentBookingsForFile
} from '../../services/permanentService'
import LoadingIndicator from "../LoadingIndicator";
import {
    getToastSuccess,
    getToastError,
    getToastInfo
} from '../../redux/actions/toastActions';
import { pushPageData } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../constants'

const PermanentBookingContainer = ({
    getSeasonalBookingListData,
    seasonalBookingList,
    getToastSuccess,
    getToastError,
}) => {
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [selectedMenuItem, setSelectedMenuItem] = useState('massUpload')
    const [seasonalDropDownList, setSeasonalDropDownList] = useState([])
    const [seasonalFileList, setSeasonalFileList] = useState([])
    const [fileName, setFileName] = useState('')
    const [isLoading, setLoading] = useState(false);
    //const [seasonalFileList,setSeasonalFileList] = useState([]);
    const [isEditButtonActive, setEditButtonActive] = useState(false);
    const [isRefreshActive, setRefreshActive] = useState(false);
    const [clearToken, setClearToken] = useState(null);
    const [isListPageOpen, setListPageOpen] = useState(false);
    const [toasterFlag, setToasterFlag] = useState(true);
    const [flag, setFlag] = useState(false)


    const userDetails = getCurrentUser()
    const massUploadSelectItems = [
        { label: t('PERMANENT_BOOKING.MASS_UPLOAD'), value: "massUpload" },
        { label: t('COMMON.USE_TEMPLATE'), value: "useTemplate" },
    ]

    const componentRef = useRef();


    useEffect(() => {
        if (fileName === '') {
            setInitialList();
        }
    }, [])

    useEffect(() => {
        setInitialList();
    }, [selectedMenuItem])

    useEffect(() => {
        if (!(fileName && fileName.length)) return
        setTimeout(() => {
            handleFileNameSelect(fileName)
        }, 500)
        if (clearToken) {
            clearInterval(clearToken)
            //setClearToken(null);
            setEditButtonActive(false)
        }
    }, [fileName])

    const setInitialList = async () => {
        const listRequest = {
            requestId: uuidv4(),
            agentCode: userDetails.agentCode,
        }
        if (selectedMenuItem === 'useTemplate') {
            let seasonalBookingList = await getSeasonalBookingList(listRequest)
            if (seasonalBookingList?.data?.permanentBookingDetails && seasonalBookingList?.data?.permanentBookingDetails.length > 0) {
                setSeasonalDropDownList(seasonalBookingList.data.permanentBookingDetails)
                setFileName(seasonalBookingList.data.permanentBookingDetails[0].fileName)
                handleFileNameSelect(seasonalBookingList.data.permanentBookingDetails[0].fileName)
                setListPageOpen(true)
            }
        }
    }


    //>>>> auto refesh of list in every 5 sec <<<<
    //>>>> auto refesh of list in every 5 sec <<<<
    const setRequest = (fileNameParam, submitGrpId = null) => {
        let token;
        let reprocessid = submitGrpId;
        let toasterFlagLocal = true;
        token = setInterval(async () => {
            setRefreshActive(true);
            let statusList = [];
            let fileSelected = fileName;
            const bookingViewList = await getPermanentBookingsForFile(fileNameParam ? fileNameParam : fileSelected);
            setSeasonalFileList(bookingViewList);
            bookingViewList?.bookingList?.map((item) => {
                statusList.push(item.status);
                if (reprocessid == item.bookingGroupId) {
                    if (item.status == 'Failed' && toasterFlagLocal) {
                        getToastError("Resubmission Failed")
                        toasterFlagLocal = false;
                    }
                    else if (item.status == 'Confirmed' || item.status == 'To be Processed' || item.status == 'Queued') {
                        if (toasterFlagLocal) {
                            getToastSuccess("Resubmission successful")
                            toasterFlagLocal = false;
                        }
                    }
                    // else{
                    //     getToastSuccess("Booking is Success")
                    // }
                }
            })
            if (!statusList.includes("Loading")) {
                clearInterval(token);
                setEditButtonActive(false)
                setRefreshActive(false)
            }
        }, 5000)
        setClearToken(token);
    }
    const getBookingListData = (fileNameParam) => {
        let counter = 0;
        let token = setInterval(async () => {
            const bookingViewList = await getPermanentBookingsForFile(fileNameParam)

            if (bookingViewList.bookingList.length > 0) {
                clearInterval(token);
                setLoading(false);
                setSeasonalFileList(bookingViewList)
                let statusList = [];
                let loadingStatus = bookingViewList?.bookingList.find((item) => item.status === 'Loading')
                if (loadingStatus) {
                    setRequest();
                    setEditButtonActive(true);
                    setRefreshActive(true);
                }
                else {
                    setRefreshActive(false);
                }
            }
            else {
                if (counter >= 2) {
                    setLoading(false);
                    clearInterval(token);
                    setSeasonalFileList(bookingViewList)
                }
                counter++
            }
        }, 3000)

    }

    const handleFileNameSelect = async (fileNameParam) => {
        setLoading(true)
        try {
            const bookingViewList = await getPermanentBookingsForFile(fileNameParam)

            if (bookingViewList.bookingList.length === 0) {
                getBookingListData(fileNameParam);
            }
            else {
                setLoading(false)
                setSeasonalFileList(bookingViewList)
                // let statusList = [];
                let loadingStatus = bookingViewList?.bookingList.find((item) => item.status === 'Loading')
                if (loadingStatus) {
                    setRequest(fileNameParam);
                    setEditButtonActive(true);
                    setRefreshActive(true);
                }
                else {
                    setRefreshActive(false)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const setDropDownFileList = (list) => {
        setFileList(list)
    }


    const handlePrint = useReactToPrint({
        //onBeforeGetContent: () => {setFlag(true)},
        content: () => componentRef.current,
        onAfterPrint: () => { setFlag(false) }

    });

    const handleClose = () => {
        setFlag(true)
        setTimeout(() => {
            handlePrint();
        }, 1000)

    }


    return (
        <div className='seasonal-booking'>
            <div className='container-md container-lg'>
                <div className='permanent-booking-wrapper'>
                    <BookingMenu activeItem={4} />
                    <div className='select-button_container' id="multiple-booking">
                        <SelectButton
                            className='select-button'
                            value={selectedMenuItem}
                            options={massUploadSelectItems}
                            onChange={(e) => {
                                if (e.value) {
                                    clearInterval(clearToken)
                                    setSelectedMenuItem(e.value);
                                    if (e.value == 'useTemplate') {
                                        pushPageData(
                                            pageNames.MULTIPLE_BOOKING,
                                            PAGE_PRIMARY_CATEGORY.E_SERVICES,
                                            PAGE_SUB_CATEGORY1.BOOKING,
                                            BOOKING_PAGE_SUB_CATEGORY2.MULTIPLE_BOOKING,
                                            'Use template'
                                        )
                                    }
                                }
                            }}
                        ></SelectButton>
                    </div>
                    <div>
                        {
                            selectedMenuItem === 'massUpload' ?
                                <PermanentBooking />
                                :
                                !isListPageOpen ?
                                    <SeasonalBooking
                                        isListPageOpen={isListPageOpen}
                                        setListPageOpen={setListPageOpen}
                                        clearToken={clearToken}
                                        isLoading={isLoading}
                                        setLoading={setLoading}
                                        setInitialList={setInitialList}
                                        navigateBack={fileName && fileName !== ''}
                                    />
                                    :
                                    <div className='container-md container-lg cargo-portal-container'>
                                        <div className='permanent-booking-wrapper'>
                                            <div className='col-12 pl-0 pr-0'>
                                                {isLoading ?
                                                    <LoadingIndicator /> : ''
                                                }
                                                <React.Fragment>
                                                    <PermanentBookingList
                                                        flag={flag}
                                                        setDropDownFileList={setSeasonalDropDownList}
                                                        fileList={seasonalDropDownList}
                                                        setListPageOpen={setListPageOpen}
                                                        fileName={fileName}
                                                        setFileName={setFileName}
                                                        bookingList={seasonalFileList}
                                                        setBookingList={setSeasonalFileList}
                                                        handleFileNameSelect={handleFileNameSelect}
                                                        setRequest={setRequest}
                                                        setEditButtonActive={setEditButtonActive}
                                                        isEditButtonActive={isEditButtonActive}
                                                        ref={componentRef}
                                                        clearToken={clearToken}
                                                        isRefreshActive={isRefreshActive}
                                                        toasterFlag={toasterFlag}
                                                        setToasterFlag={setToasterFlag}
                                                    />
                                                    <div className="row button-submit-row">
                                                        <div className='col-2 btn-print btn-reprocess-cancel'>
                                                            <button
                                                                type='submit'
                                                                value='Submit'
                                                                className='button button--secondary'
                                                                onClick={handleClose}
                                                            >
                                                                {t('COMMON.PRINT')}
                                                            </button>
                                                        </div>
                                                        <div className='col-3 btn-reprocess'>
                                                            <button
                                                                type='button'
                                                                className='button button--secondary'
                                                                onClick={(e) => {
                                                                    clearInterval(clearToken)
                                                                    setListPageOpen(false)
                                                                    setSeasonalFileList([]);
                                                                }
                                                                }
                                                            >
                                                                {
                                                                    selectedMenuItem === 'useTemplate' ?
                                                                        t('PERMANENT_BOOKING.NEW_TEMPLATE_BOOKING') :
                                                                        t('PERMANENT_BOOKING.NEW_UPLOAD')
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ seasonalBooking }) => ({
    // seasonalBookingList: seasonalBooking.response
})

const mapDispatchToProps = (dispatch) => ({
    getToastSuccess: (message) => {
        dispatch(getToastSuccess(message))
    },
    getToastError: (message) => {
        dispatch(getToastError(message))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermanentBookingContainer)