import React, { useCallback, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import moment from 'moment';
import './custom-dropzone.scss'
import { v4 as uuidv4 } from 'uuid'
import XLSX from 'xlsx'
import { cells } from "./excellColumns.json"
import getCurrentUser from '../../utils/getCurrentUser'
import {
  registerPermanentbooking,
  getPermanentBookingFileList
} from "../../services/permanentService";
import { Toast } from 'primereact/toast';
import {
  getToastSuccess,
  getToastError
} from '../../redux/actions/toastActions';
import { getShippingItems } from '../../services/searchPanelServices';
import LoadingIndicator from "../LoadingIndicator";
import { t } from 'i18next';


const CustomDropzone = ({
  setDisplayUploadSection,
  toastDetails,
  getToastSuccess,
  getToastError,
  setDropDownFileList,
  setListPageOpen,
  setFileName,
  fileList,
  handleFileNameSelect,
}) => {

  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const myToast = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      acceptedFiles.forEach((file) => {
        const filepathArray = file.path.split('.')
        if (filepathArray[filepathArray.length - 1] !== 'xlsx' &&
          filepathArray[filepathArray.length - 1] !== 'xlx'
        ) {
          setFile(null)
          return
        }
        setFile(file)
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result
        }
        reader.readAsArrayBuffer(file)
        handleUploadFile(file)
      })
    }
  }, [])


  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false })
  // useEffect(() => {
  // 	if (toastDetails && toastDetails.severity) {
  // 		// myToast.current.show({ ...toastDetails, life: 4000 })
  // 	}
  // }, [toastDetails])

  useEffect(() => {
    getPermanentBookingFileList().then((res) => {
      if (res && res.permanentBookingDetails) {
        setDropDownFileList(res.permanentBookingDetails);
      }
    })
  }, [])

  const handleUploadFile = (props) => {
    setDisplayUploadSection(false);
    saveExcelData(props);
  }

  const saveExcelData = (file) => {

    //send excell file to api
    let userDetails = getCurrentUser();

    var formData = new FormData();

    formData.append("requestID", uuidv4());
    formData.append("userId", userDetails.userId);
    formData.append("agentCode", userDetails.agentCode);
    formData.append("fileName", file.name);
    formData.append("saveMassBookingExcel", file);
    formData.append("emailId", userDetails.email);
    formData.append("firstName", userDetails.firstName);

    const checkFileName = () => {
      let isFileExists = false
      fileList.map(item => {
        ///console.log(item.fileName,"item")
        if (item.fileName === file.name) {
          //
          isFileExists = true


          //myToast.current.show({ ...toastDetails, life: 4000 })
         // console.log("not submitted", file.name)
        }
      })
      return isFileExists
    }
  //  console.log(checkFileName(), "checkFileName")
    if (checkFileName() === true) {
      //alert("File Name already Exists");
      getToastError("File already Exists");
    }
    else {


      setLoading(true);

      registerPermanentbooking(formData).then((res) => {


        if (res?.status === 200) {
          setLoading(false);
          //call toaster
          getToastSuccess("File uploaded successfully");
          myToast.current.show({ ...toastDetails, life: 4000 })
          /*getPermanentBookingFileList().then((res) => {
            setDropDownFileList(res.permanentBookingDetails);
          })*/
          setFile(null);
          setListPageOpen(true);
          setFileName(file.name);

          setTimeout(() => {
            getPermanentBookingFileList().then((res) => {
              if (res && res.permanentBookingDetails) {
                setDropDownFileList(res.permanentBookingDetails);
              }
            })
          }, 1000);
          //console.log(file, "path")
          // setTimeout(() => {
          //   handleFileNameSelect(file.name)
          // },500)

        }
      }).catch((error) => {
        getToastError("Failed to upload file");
      })
    }
  }


  return (
    <>

      {
        isLoading ? <LoadingIndicator /> : ''
      }
      <div className='file-name'>
      <div className='box'>
        
      <div className='dropzone__container'>
        <Toast ref={myToast} />
        
        <div className='dropzone__main' {...getRootProps()}>
          <input {...getInputProps()} />
          
          <div className='excel_Upload_image'>
					<img src={require('../../assets/images/UploadExcel.png').default} />
          </div>
					<p>{t('PERMANENT_BOOKING.DROP_CLICK')}</p>
        </div>
      </div>
      </div>

      {
        
        file ?
          (
            <div className='filename-upload'>
              {`${file.path} - ${file.size} kb`}
            </div>
          ) : <></>
          

      }
      </div>

    </>
  )
}

const mapStateToProps = ({ loader, toast }) => ({
  //	principal: auth.principal,
  //	isLoading: loader.isLoading,
  toastDetails: toast.toastDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getToastSuccess: (message) => {
    dispatch(getToastSuccess(message))
  },
  getToastError: (message) => {
    dispatch(getToastError(message))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomDropzone)
