import { SEASONAL_BOOKING } from '../constants'

export const getSeasonalBookingList = (payload) => ({
	type: SEASONAL_BOOKING.LOAD,
    payload: payload
})


export const getSeasonalBookingListSuccess = (response) => ({
    type: SEASONAL_BOOKING.SUCCESS,
    payload: response
})

