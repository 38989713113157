import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import infoIcon from '../../assets/images/info_icon.svg'
import trashIcon from '../../assets/images/trash.svg'
import {
	FORM_FIELDS,
	ULD_FORM_FIELDS,
	WEIGHT_UNIT_SELECT_ITEMS,
	WEIGHT_UNIT_SELECT_ITEMS_WO_TON,
	ULD_TYPES_WITH_CONTOUR,
} from '../../constants'
import { getPivotWeight, getULDTypeByCode } from '../../services/searchPanelServices'
import ULDTypeAutocomplete from '../ULDTypeAutocomplete'
import './item-form.scss'
import { allowNumberOnly } from '../../utils/common'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
const ULDLineItem = ({
	item,
	index,
	selectedOrigin,
	handleItemChange,
	error,
	clearItemError,
	ULDDetails,
	calculateTotalWeight,
	weightUnit,
	handleRemoveItem,
	setWeightUnit,
	removedRowCount,
	captureAWB,
	draftCallback,
	setNotificationMessage,
	setNotificationType,
	excludeTon,
}) => {
	const [uldType, setUldType] = useState(item.uldType)
	const [pieces, setPieces] = useState(item.pieces)
	const [contour, setContour] = useState(item.contour)
	const [weight, setWeight] = useState(item.weight)
	const [uldVolume, setUldVolume] = useState(item.uldVolume)
	const [pivotWeight, setPivotWeight] = useState(item.pivotWeight)

	//For multilingual translation
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const CONTOUR_ITEMS = [
		{ label: t('BOOKING.MAIN_DECK'), value: 'MD' },
		{ label: t('BOOKING.LOWER_DECK'), value: 'LD' },
	]

	useEffect(() => {
		setUldType(item.uldType)
		setPieces(item.pieces)
		setContour(item.contour)
		setWeight(item.weight)
		setUldVolume(item.uldVolume)
		setPivotWeight(item.pivotWeight)
	}, [removedRowCount])

	useEffect(() => {
		; (() => {
			setPivotWeight(null)
			if (uldType && selectedOrigin && selectedOrigin.code) {
				if (ULD_TYPES_WITH_CONTOUR.includes(uldType) && !contour) {
					return
				}
				getPivotWeight(selectedOrigin.code, uldType, contour)
					.then((res) => {
						if (res && res.pivotweight) {
							setPivotWeight(res.pivotweight)
						}
					})
					.catch((err) => {
						console.log(err)
					})

			}
		})()
	}, [uldType, contour, selectedOrigin])


	useEffect(() => {
		handleItemChange(index, {
			uldType,
			contour,
			pieces,
			weight,
			pivotWeight,
			uldVolume,
		})
	}, [uldType, contour, pieces, weight, pivotWeight, uldVolume])
	// Setting contour value after get details 
	useEffect(() => {
		setContour(item.contour)
	}, [uldType])

	useEffect(() => {
		if (uldType && ULD_TYPES_WITH_CONTOUR.includes(uldType)
			&& contour) {
			; (() => {
				getULDTypeByCode(uldType)
					.then((selectedUldType) => {
						if (selectedUldType && selectedUldType.uldtypcod) {
							contour === 'LD'
								? setUldVolume(selectedUldType.maxvolld)
								: setUldVolume(selectedUldType.maxvolmd)
						}
					})
					.catch((err) => {
						console.log(err)
					})

			})()
		}
	}, [contour])

	const uldTypeError =
		error &&
			error[FORM_FIELDS.ULD_ITEMS] &&
			error[FORM_FIELDS.ULD_ITEMS][index] &&
			error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.ULD_TYPE]
			? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.ULD_TYPE]
			: null
	const contourError =
		error &&
			error[FORM_FIELDS.ULD_ITEMS] &&
			error[FORM_FIELDS.ULD_ITEMS][index] &&
			error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.CONTOUR]
			? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.CONTOUR]
			: null
	const piecesError =
		error &&
			error[FORM_FIELDS.ULD_ITEMS] &&
			error[FORM_FIELDS.ULD_ITEMS][index] &&
			error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.PIECES]
			? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.PIECES]
			: null
	const weightError =
		error &&
			error[FORM_FIELDS.ULD_ITEMS] &&
			error[FORM_FIELDS.ULD_ITEMS][index] &&
			error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.WEIGHT]
			? error[FORM_FIELDS.ULD_ITEMS][index][ULD_FORM_FIELDS.WEIGHT]
			: null

	return (
		<div
			className={`item-group search-panel__data uld-form pl-3 ${ULDDetails.length - 1 === index ? 'last-item' : ''
				}`}
			key={index}
		>
			<div className='form-row search-panel__data'>
				<div
					className={`form-group col-6 col-lg-2 ${uldTypeError ? 'red-border' : ''
						}`}
				>
					<label className="additional-style">{t('COMMON.ULD_TYPE')}</label>
					<ULDTypeAutocomplete
						className={'form-control autocomplete'}
						selectedUldType={{ code: uldType }}
						setSelectedUldType={(uldTypeObj) => {
							if (uldTypeObj && uldTypeObj.uldtypcod) {
								setUldType(uldTypeObj.uldtypcod)
								setUldVolume(uldTypeObj.maxvol)

							} else {
								setUldType(null)
								setUldVolume(null)
							}
						}}
						clearError={() => clearItemError(ULD_FORM_FIELDS.ULD_TYPE, index)}
						setNotificationMessage={setNotificationMessage}
						setNotificationType={setNotificationType}
					/>
					<span className='search-panel__errormsg'>
						{uldTypeError ? t(uldTypeError, { ns: 'errors' }) : ''}
					</span>
				</div>
				{ULD_TYPES_WITH_CONTOUR.includes(uldType) ? (
					<div
						className={`form-group col-6 col-lg-2 ${piecesError ? 'red-border' : ''
							}`}
					>
						<label className="additional-style">{t('COMMON.CONTOUR')}</label>
						<Dropdown
							className='form-control'
							options={CONTOUR_ITEMS}
							value={contour}
							onChange={(e) => setContour(e.target.value)}
							onFocus={(e) => clearItemError(ULD_FORM_FIELDS.CONTOUR, index)}
						/>
						<span className='search-panel__errormsg'>
							{contourError ? t(contourError, { ns: 'errors' }) : ''}
						</span>
					</div>
				) : (
					<></>
				)}
				<div
					className={`form-group col-6 col-lg-2 ${piecesError ? 'red-border' : ''
						}`}
				>
					<label className="additional-style">{t('COMMON.NO_OF_UNITS')}</label>
					<input
						type='number'
						className='form-control'
						value={pieces}
						onChange={(e) => {
							if (e.target.value.length <= 9) {
								setPieces(e.target.value)
							}
						}}
						onFocus={(e) => clearItemError(ULD_FORM_FIELDS.PIECES, index)}
					/>
					<span className='search-panel__errormsg'>
						{piecesError ? t(piecesError, { ns: 'errors' }) : ''}
					</span>
				</div>
				{!captureAWB && (
					<div
						className={`form-group col-8 col-lg-2 ${weightError ? 'red-border' : ''
							}`}
					>
						<label className="additional-style">{t('COMMON.WT_PER_UNIT')}</label>
						<div className='uld-form__double_element weight-div'>
							<input
								type='text'
								className='form-control borderRadius-R0'
								value={weight}
								onKeyDown={(evt) => allowNumberOnly(evt)}
								onChange={(e) => {
									const value = e.target.value.replace(/[^0-9.]/g, '');
									if (value.length <= 9 &&
										(
											!value.split('.')[1] ||
											!value.split('.')[1].length ||
											value.split('.')[1].length < 3
										) &&
										(value.split('.').length <= 2)
									) {
										setWeight(value)
									}
								}}
								onBlur={(e) => {
									if (!Number(weight)) {
										setWeight(null)
									} else {
										calculateTotalWeight()
									}
								}}
								onFocus={(e) => clearItemError(ULD_FORM_FIELDS.WEIGHT, index)}
							/>
							<div className='form-group borderRadius-L0 border-left0 dropdown_div m-b-0'>
								<Dropdown
									className='form-control cust-dropdown'
									value={weightUnit}
									options={excludeTon ? WEIGHT_UNIT_SELECT_ITEMS_WO_TON : WEIGHT_UNIT_SELECT_ITEMS}
									onChange={(e) => {
										setWeightUnit(e.target.value)
										if (draftCallback) {
											draftCallback(true)
										}
									}}
									disabled={index ? true : false}
								/>
							</div>
						</div>
						<span className='search-panel__errormsg'>
							{weightError ? t(weightError, { ns: 'errors' }) : ''}
						</span>
					</div>
				)}
				{uldType && pivotWeight ? (
					<div className='form-group col-8 col-lg-3'>
						<div className='uld-form pivot-message'>
							<img className='uld-form info-icon' src={infoIcon} />
							<div className='uld-form info-message' id="pivot-styling">
								{t(Messages.PIVOT_WT_INFO_MSG, {
									weight: pivotWeight,
									ns: 'errors',
								})}
							</div>
						</div>
					</div>
				) : (
					<></>
				)}
				{ULDDetails.length > 1 ? (
					<div className='form-group col-4 col-lg-1 delete-div'>
						<div class='uld-form delete-section'>
							<img
								src={trashIcon}
								onClick={(e) => handleRemoveItem(index)}
								alt='Trash'
								title='Trash'
							/>
							<span>{t('COMMON.DELETE')}</span>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default ULDLineItem
