import React from 'react'
import DashboardContainer from './DashboardContainer'
import './index.scss'

const Dashboard = () => {
	return (
		<div className='cargo-portal-container dashboard'>
			<DashboardContainer />
		</div>
	)
}

export default Dashboard
