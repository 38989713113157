import { LIST_DRAFTS } from '../constants'

export const requestListDrafts = (payload) => ({
    type: LIST_DRAFTS.LOAD,
    payload,
})

export const listDraftsResponse = (response) => ({
    type: LIST_DRAFTS.SUCCESS,
    response,
})

export const listDraftsError = (error) => ({
    type: LIST_DRAFTS.ERROR,
    error,
})
