import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { deleteTemplateResponse } from '../../../services/manageTemplateService'
import ConfirmationPopUp from '../../../common/ConfirmationPopUp'
import '../index.scss'
import ManageListTemplateTable from './ManageListTemplateTable'
import { SUCCESS_STATUS } from '../../../constants'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from '../../LoadingIndicator'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
import { addError} from '../../../utils/analytics'
const TemplateDisplay = ({
	manageTemplateResponse,
	manageTemplateError,
	userDetails,
	setAlertMessage,
	setDeleteSuccess,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const columns = [
		'MNGTMPLT.TEMPLATE_NAME',
		'COMMON.USER_ID',
		'MNGTMPLT.CREATION_TIME',
	]
	const [templateList, setTemplateList] = useState([])
	const [bookingTemplate, setBookingTemplate] = useState(null)
	const [displayPopup, setDisplayPopUp] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()

	useEffect(() => {
		if (manageTemplateResponse) {
			setTemplateList(manageTemplateResponse.templateList)
		}
	}, [manageTemplateResponse])

	const handleEditAction = (evnt, template) => {
		history.push(
			`/booking/template/${template.serialNumber}/${template.templateName}`
		)
	}

	const handleDeleteAction = (evnt, template) => {
		evnt.stopPropagation()
		setBookingTemplate(template)
		setDisplayPopUp(true)
	}

	const sendDeleteRequest = () => {
		setIsLoading(true)
		const deleteRequest = {
			requestId: uuidv4(),
			agentCode:
				userDetails && userDetails.agentCode ? userDetails.agentCode : null,
			// '14360390015',
			templateDetails: bookingTemplate ? bookingTemplate : null,
		}
		deleteTemplateResponse(deleteRequest)
			.then((response) => {
				setIsLoading(false)
				if (response && response.status === SUCCESS_STATUS) {
					setAlertMessage(
						t(Messages.TEMP_DELETE_SUCCESS, {
							templateName: deleteRequest.templateDetails.templateName,
							ns: 'errors',
						})
					)
					setDeleteSuccess(true)
				} else if (
					response &&
					response.errorDetails !== undefined &&
					response.errorDetails.length != 0
				) {
					setAlertMessage(
						`${deleteRequest.templateDetails.templateName} delete error - ${response.errorDetails[0].errorDescription}`
					)
					addError('TEMP_DELETE_FAILED',`${deleteRequest.templateDetails.templateName} delete error - ${response.errorDetails[0].errorDescription}`)
					setDeleteSuccess(false)
					}
				window.scrollTo(0, 0)
			})
			.catch((error) => {
				setIsLoading(false)
				setAlertMessage(
					`${t(Messages.TEMP_DELETE_ERROR, { ns: 'errors' })} - ${error}`
				)
				addError(Messages.PROCESS_REQUEST_FAILED, i18n.getResource('en', 'errors',Messages.PROCESS_REQUEST_FAILED))
				setDeleteSuccess(false)
				console.log(error)
				window.scrollTo(0, 0)
			})
	}

	return (
		<>
			<div className='pt-4'>
				{isLoading ? <LoadingIndicator /> : <></>}
				{templateList && templateList.length > 0 && (
					<ManageListTemplateTable
						templates={templateList}
						columns={columns}
						totalRecordCount={templateList.length}
						handleEditAction={handleEditAction}
						handleDeleteAction={handleDeleteAction}
					/>
				)}

				{manageTemplateResponse && !templateList.length && (
					<div className='no-data'>
						{t(Messages.NO_TEMPLATES, { ns: 'errors' }),
						addError(Messages.PROCESS_REQUEST_FAILED, i18n.getResource('en', 'errors',Messages.PROCESS_REQUEST_FAILED))}
					</div>
					)}

				{manageTemplateError && (
					<div className='no-data'>
						{t(Messages.PROCESS_REQUEST_FAILED, { ns: 'errors' }),
						addError(Messages.PROCESS_REQUEST_FAILED, i18n.getResource('en', 'errors',Messages.PROCESS_REQUEST_FAILED))
						}
					</div>
				)}
				<div className='row mt-4'>
					<div className='col-12'>
						<button
							value='Create new template'
							className='button secondary mr-2'
							onClick={(e) => {
								history.push('/booking/search')
							}
							}
						>
							{t('TEMPLATES.CREATE_TEMPLATE')}
						</button>
					</div>
				</div>
			</div>

			{displayPopup ? (
				<ConfirmationPopUp
					displayPopUp
					setDisplayPopUp={setDisplayPopUp}
					message={t('ER078', {
						templateName: bookingTemplate.templateName,
						ns: 'errors',
						})}
					primaryBtnLbl={t('COMMON.DELETE')}
					secondaryBtnLbl={t('COMMON.CANCEL')}
					acceptFn={sendDeleteRequest}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default TemplateDisplay
