import React, { useCallback, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import './flightdata-dropzone.scss'
import { v4 as uuidv4 } from 'uuid'
import XLSX from 'xlsx'
import getCurrentUser from '../../../utils/getCurrentUser'
import {
  processSchedules
} from '../../../services/scheduleService';
import { Toast } from 'primereact/toast';
import {
  getToastSuccess,
  getToastError
} from '../../../redux/actions/toastActions';
import LoadingIndicator from "../../LoadingIndicator";
import { t } from 'i18next';
import '../../../services/scheduleService'

const FlightDataDropzone = ({
  setDisplayUploadSection,
  setFileData,
  file,
  setFile,
}) => {

  const [isLoading, setLoading] = useState(false);
  const myToast = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true)
    if (acceptedFiles && acceptedFiles.length) {
      acceptedFiles.forEach((file) => {
        const filepathArray = file.path.split('.')
        if (filepathArray[filepathArray.length - 1] !== 'xlsx' &&
          filepathArray[filepathArray.length - 1] !== 'xlx'
        ) {
          setFile(null)
          return
        }
        setFile(file)
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result
        }
        reader.readAsArrayBuffer(file)
        handleUploadFile(file)
      })
    }
  }, [])

  const onFileDialogOpen = () => {
    setLoading(true)
  }

  const onFileDialogCancel = () => {
    setLoading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, onFileDialogOpen, onFileDialogCancel, multiple: false })
  // useEffect(() => {
  // 	if (toastDetails && toastDetails.severity) {
  // 		// myToast.current.show({ ...toastDetails, life: 4000 })
  // 	}
  // }, [toastDetails])

  const handleUploadFile = (props) => {
    setDisplayUploadSection(false);
    setFileData(props)
    setLoading(false)
  }

  
  const handleDelete = () => {
    setFile(null)
    setFileData(null)
  }

  return (
    <>

      {
        isLoading ? <LoadingIndicator /> : ''
      }
      <div className='file-name'>
        <div className='box'>

          <div className='dropzone__container'>
            <Toast ref={myToast} />

            <div className='dropzone__main' {...getRootProps()}>
              <input {...getInputProps()} />

              <div className='excel_Upload_image'>
                <img src={require('../../../assets/images/UploadExcel.png').default} />
              </div>
              <p>{t('PERMANENT_BOOKING.DROP_CLICK')}</p>
            </div>
          </div>
        </div>

        {

          file ?
            (
              <div className='files-list'>
                <h4>Files uploaded</h4>
                <div className='filename-upload'>
                  <span>{`${file.path} - ${file.size} kb`} </span>
                  <span><button className='link btn-delete-file' onClick={handleDelete}>Delete</button></span>
                </div>
              </div>
            ) : <></>


        }
      </div>

    </>
  )
}

const mapStateToProps = ({ loader, toast }) => ({
  //	principal: auth.principal,
  //	isLoading: loader.isLoading,
  toastDetails: toast.toastDetails,
})

const mapDispatchToProps = (dispatch) => ({
  getToastSuccess: (message) => {
    dispatch(getToastSuccess(message))
  },
  getToastError: (message) => {
    dispatch(getToastError(message))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FlightDataDropzone)
