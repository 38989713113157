import { LIST_FLIGHT } from '../constants'
import { v4 as uuidv4 } from 'uuid'

export const loadListFlight = (searchFormData) => ({
	type: LIST_FLIGHT.LOAD,
	searchFormData: {
		id: uuidv4(),
		...searchFormData,
	},
})
export const listFlightSuccess = (response) => ({
	type: LIST_FLIGHT.SUCCESS,
	response,
})
export const listFlightError = (error) => ({
	type: LIST_FLIGHT.ERROR,
	error,
})
export const clearListFlight = () => ({
	type: LIST_FLIGHT.CLEAR,
})

//

export const setCalendar = (calendarRange) => ({
	type: LIST_FLIGHT.SET_CALENDAR,
	calendarRange,
})
