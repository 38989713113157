import { AutoComplete } from 'primereact/autocomplete'
import React, { useRef, useState } from 'react'
import {
	getULDTypeByCode,
	getULDTypes,
} from '../../services/searchPanelServices'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants'
const ULDTypeAutocomplete = ({
	selectedUldType,
	setSelectedUldType,
	clearError,
	setNotificationMessage,
	setNotificationType,
}) => {
	const [text, setText] = useState(null)
	const [filteredUldTypes, setFilteredUldTypes] = useState(null)

	const isFocused = useRef(false)

	const getFilteredUldTypes = (text, uldTypes) => {
		return uldTypes && uldTypes.length
			? uldTypes.filter((uldType) => {
					return uldType.uldtypcod.toLowerCase().includes(text.toLowerCase())
			  })
			: []
	}

	const searchUldTypes = async (e) => {
		const text = e.query
		let uldTypes
		if (text && text.length) {
			try {
				uldTypes = await getULDTypes(text)
				if (isFocused.current) {
					setFilteredUldTypes(getFilteredUldTypes(text, uldTypes))
				} else {
					setFilteredUldTypes([])
				}
			} catch (err) {
				if (err && err.message) {
					setNotificationType &&
						setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
					setNotificationMessage &&
						setNotificationMessage('ULD type error : ' + err.message)
				}
			}
		}
	}

	const handleOnChange = (query) => {
		searchUldTypes({ query })
		if (selectedUldType) {
			setSelectedUldType(null)
		}
		setText(query)
	}

	const itemTemplate = (uldType) => {
		return <div>{uldType.uldtypcod}</div>
	}

	const handleSelect = (value) => {		
		setText(null)
		setSelectedUldType({
			uldtypcod: value.uldtypcod,
			dimcod: value.dimcod,
			maxvol: value.maxvol,
			pvtwgt: value.tarewgt,
		})
	}

	const handleOnBlur = (text) => {
		if (text && text.length) {
			getULDTypeByCode(text)
				.then((selectedUldType) => {
					if (selectedUldType && selectedUldType.uldtypcod) {
						handleSelect(selectedUldType)
					}
				})
				.catch((err) => {
					setNotificationType && setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
					setNotificationMessage && setNotificationMessage(err.message ? err.message : err)
					setText(null)
				})
		}
		searchUldTypes({ query: null })
	}

	return (
		<>
			<AutoComplete
				value={
					selectedUldType && selectedUldType.code ? selectedUldType.code : text
				}
				suggestions={filteredUldTypes}
				completeMethod={searchUldTypes}
				onChange={(e) => {
					handleOnChange(e.value)
				}}
				onSelect={({ value }) => handleSelect(value)}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onFocus={(e) => {
					clearError()
					isFocused.current = true
				}}
				onBlur={(e) => {
					isFocused.current = false
					handleOnBlur(e.target.value)
				}}
				className={'form-control autocomplete'}
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default ULDTypeAutocomplete
