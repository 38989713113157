import React, { useEffect, useState } from 'react'
import minusIcon from '../../assets/images/minus.svg'
import plusIcon from '../../assets/images/plus.svg'
import { VOLUME_UNIT_LABEL, WEIGHT_UNIT, WEIGHT_UNIT_LABEL } from '../../constants'
import ViewPermanentBooking from "./ViewPermanentBooking"
import PermanentBookingForm from "./permanentBookingForm"
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation"
import { RATE_TYPES } from '../../constants/rateTypes'
import { PB_RateType } from '../../constants/rateTypes'
//import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const PermanentBookingItem = ({
    bookingItem,
    flag,
    key,
    fileName,
    bookingFilter,
    setBookingList,
    setAutoRefresh,
    setEditButtonActive,
    isEditButtonActive,
    clearToken,
    sortedArray
}) => {

    const [isExpanded, setExpanded] = useState(false);
    const [isViewMode, setViewMode] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [diableButton, setDisableButton] = useState(true)

    const { t, i18n } = useTranslation(['labels', 'errors'])
    // const [awbNumber, setAwbNumber] = useState('');


    const PERMANENT_BOOKING_STATUS = {
        C: 'Confirmed',
        Q: 'Queued',
        P: 'Pending',
        F: 'Failed'
    }


    let parsedbookingItem = JSON.parse(bookingItem?.requestParameters);

    useEffect(() => {
        if (flag) {
            setViewMode(false);
            setEditMode(false);
        }
    }, [flag])

    const getAwbNumber = (awbNumber) => {
        if (awbNumber) {
            let awbPrefix = awbNumber?.substring(0, 3);
            let awbNumSplit = awbNumber?.substring(3, awbNumber?.length);
            return awbPrefix + '-' + awbNumSplit;
        }
        else {
            return '';
        }

    }

    const changeEditMode = (currentEditMode) => {
        clearInterval(clearToken)
        currentEditMode ? clearInterval(clearToken) : setAutoRefresh();
        setEditMode(!isEditMode);
    }

    //console.log(parsedbookingItem)
    return (
        /*<div className='permanent-booking-list-item__container'> */
        /* <div className='permanent-booking-list-item__flex'> */
        <>
            {(bookingFilter.confirmed && bookingItem.status == "Confirmed") ||
                (bookingFilter.queued && bookingItem.status == "Queued") ||
                (bookingFilter.tobeProcessed && bookingItem.status == "To be Processed") ||
                (bookingFilter.failed && bookingItem.status == "Failed") ||
                (bookingItem.status == "Loading") ? (
                <div id='body' className="row permanent-booking-list-item__list">
                    <th className="">
                        {parsedbookingItem?.shipmentDetails?.shipmentOrigin}&nbsp;-&nbsp;
                        {parsedbookingItem?.shipmentDetails?.shipmentDestination}
                    </th>
                    <th className="">{parsedbookingItem?.shipmentDetails?.productDetails?.productName}</th>
                    <th className="">{parsedbookingItem?.shipmentDetails?.shippingDate}</th>
                    {((parsedbookingItem?.rate === null || parsedbookingItem?.rate === 0))  ?
                        (
                            bookingItem?.awbNumber ? (
                                <>
                                    <th className="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</th>
                                    <th className="">No rates available</th>
                                </>)
                             : (
                                <>
                                    <th className="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th className=""></th>
                                </>)
                        )
                        :
                        (
                            <>
                                {/*  <th className="col">{parsedbookingItem?.priceClass}</th> */}
                                {/*  <th className="col">{t(RATE_TYPES[parsedbookingItem?.priceClass])}</th>  */}
                                <th className="">{(PB_RateType[parsedbookingItem?.priceClass])}</th>
                                <th className="">{parsedbookingItem?.shipmentDetails?.currency}{' '}{parsedbookingItem?.rate}</th>
                            </>
                        )
                    }
                    <th className="">{getAwbNumber(bookingItem?.awbNumber)}
                    </th>
                    <th className="">{bookingItem?.status === "Loading" ?
                        <LoadingAnimation />
                        : bookingItem?.status}</th>
                    <th style={{ cursor: bookingItem?.status === "Loading" ? '' : "pointer" }} className=""

                        onClick={() => {
                            changeEditMode(!isEditMode);
                            if (bookingItem?.status === "Loading") {
                                return
                            }
                            else {
                                setViewMode(!isViewMode)
                                setEditMode(!isEditMode)
                            }

                        }

                        }

                    >

                        {(isViewMode) ? (
                            "X"
                        ) : (
                            <div className="permanent-booking-view-edit">
                                {bookingItem?.status != "Failed" ? t('PERMANENT_BOOKING.VIEW') : ''}
                            </div>
                        )}
                        {(isEditMode) ? (
                            "X"
                        ) : (
                            <div className="permanent-booking-view-edit">
                                {bookingItem?.status === "Failed" ? t('COMMON.EDIT') : ''}
                            </div>
                        )}

                    </th>
                </div>
            ) :
                <></>
            }
            <div>
                {
                    ((isViewMode && bookingItem?.status != "Failed") && ((bookingFilter.confirmed && bookingItem.status == "Confirmed") ||
                        (bookingFilter.queued && bookingItem.status == "Queued") ||
                        (bookingFilter.tobeProcessed && bookingItem.status == "To be Processed")
                    )) ? (
                        <ViewPermanentBooking
                            viewBookingItem={bookingItem}
                        />

                    ) :
                        (isViewMode ? setViewMode(!isViewMode) : (<></>))



                }


            </div>
            <div>
                {
                    isEditMode && parsedbookingItem && bookingItem?.status === "Failed" && bookingFilter.failed ? (
                        <PermanentBookingForm
                            parsedbookingItem={parsedbookingItem}
                            bookingItem={bookingItem}
                            fileName={fileName}
                            setBookingList={setBookingList}
                            setEditMode={setEditMode}
                            // setEditButtonHidden={setEditButtonHidden}
                            setAutoRefresh={setAutoRefresh}
                            setEditButtonActive={setEditButtonActive}
                            changeEditMode={changeEditMode}
                        />
                    ) :
                        (isEditMode ? setEditMode(!isEditMode) : (<></>))
                }
            </div>
        </>
    )
}


export default PermanentBookingItem