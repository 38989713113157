import React, { useEffect, useState } from 'react'
import DoughnutChart from '.'
import './index.scss'
import Messages from '../../../constants/Messages'
import { useTranslation } from 'react-i18next'
const ServiceTypeChart = ({
	chartData,
	chartDataType,
	isLoading,
	changeToLocalCurrency,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [labels, setLabels] = useState([])
	const [data, setData] = useState([])

	const labelSuffix = '%'
	const backgroundColor = ['#7a9a01', '#D6D2C4']
	const borderColor = ['#7a9a01', '#D6D2C4']

	useEffect(() => {
		const serviceData = chartData ? chartData.serviceData : null
		getServiceChartData(serviceData)
	}, [chartData, chartDataType])

	const getServiceChartData = (serviceData) => {
		if (serviceData != null && serviceData.services.length !== 0) {
			let serviceLabels = []
			let dataSet = []
			serviceData.services.map((service) => {
				let dataElementName = service.dataElementName
				if (service.dataElementValue && chartDataType === 'awbCount') {
					serviceLabels.push(
						dataElementName +
							' ' +
							service.dataElementValue.countPercent +
							labelSuffix
					)
					dataSet.push(service.dataElementValue.count)
				} else if (service.dataElementValue && chartDataType === 'tonnage') {
					serviceLabels.push(
						dataElementName +
							' ' +
							service.dataElementValue.tonnagePercent +
							labelSuffix
					)
					dataSet.push(service.dataElementValue.tonnage)
				}  else if (
					service.dataElementValue &&
					chartDataType === 'charge' &&
					changeToLocalCurrency
				) {
					serviceLabels.push(
						dataElementName +
							' ' +
							service.dataElementValue.localChargePercent +
							labelSuffix
					)
					dataSet.push(service.dataElementValue.localChargePercent)
				 }else {
					serviceLabels.push(
						dataElementName +
							' ' +
							service.dataElementValue.chargePercent +
							labelSuffix
					)
					dataSet.push(service.dataElementValue.charge)
				}
			})
			setLabels(serviceLabels)
			setData(dataSet)
		} else if (serviceData != null && serviceData.services.length == 0) {
			setLabels([])
			setData([])
		}
	}

	return (
		<div className='doughnet-chart service-chart'>
			<h3 className='section-sub-heading mt-0 mb-0'>
				{t('DASHBOARD.SERVICE_TYP')}
			</h3>
			{labels && labels.length !== 0 && data && data.length !== 0 && (
				<DoughnutChart
					dataSetLabel='service-type'
					labels={labels}
					backgroundColor={backgroundColor}
					borderColor={borderColor}
					data={data}
				/>
			)}
			{labels &&
				labels.length === 0 &&
				data &&
				data.length === 0 &&
				!isLoading && (
					<div className='empty-records ml-0 pl-0'>
						{t(Messages.NO_DATA_AVAILABLE, { ns: 'errors' })}
					</div>
				)}
		</div>
	)
}

export default ServiceTypeChart
