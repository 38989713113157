import React from 'react'
import { useTranslation } from 'react-i18next'
import { clearItemFromSessionStorage, setToSessionStorage } from '../../utils/StorageUtils'
const RecommendedSccList = ({
	recommendedSccs,
	handleAddAdditinalScc,
	services,
	showWarning,
}) => {
	const { t, i18n } = useTranslation(['labels'])

	return recommendedSccs && recommendedSccs.length ? (
		<div className={`form-group col-12 col-md-3 col-lg-3`}>
			<label id="recomment-font">{t('BOOKING.WE_RECOMMEND')}</label>
			<div class='form-row scc-recommendation'>
				{recommendedSccs && recommendedSccs.length ? (
					recommendedSccs.map((scc, index) => {
						const service =
							services &&
							services.length &&
							services.find((service) => service.scc === scc)
						const upsellOffered = service ? `${service.name} [${scc}]` : scc
						setToSessionStorage('upsellOffered', upsellOffered)
						return (
							<span
								key={`apld-${index}`}
								onClick={() => {
									showWarning()
									handleAddAdditinalScc({
										scc: service.scc,
										sccDescription: service.name,
									})
								}}
							>
								<div className='scccode' key={index}>
									{upsellOffered}
								</div>
							</span>
						)
					})
				) : (
					<></>
				)}
			</div>
		</div>
	) : (
		<></>
	)
}

export default RecommendedSccList
