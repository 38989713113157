import React, { useEffect } from 'react'
import {
	validateAlphaRequired,
	hasError,
	removeError,
} from '../captureAwbUtils'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { useTranslation } from 'react-i18next'
import MESSAGES from '../../../constants/Messages'
const ExecutionDetails = ({ awbDetails, setAwbDetails, error, setError }) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	let awbExecutionDetails = awbDetails.awbExecutionDetails

	useEffect(() => {}, [])

	const handleChangeDate = (e) => {
		awbExecutionDetails.issueDate = e.target.value
		setAwbDetails((prevState) => ({
			...prevState,
			awbExecutionDetails: awbExecutionDetails,
		}))
		if (!e.target.value) {
			setError((prevState) => ({
				...prevState,
				issuedDate: MESSAGES.ENTER_VALID_DATE,
			}))
		}
	}

	return (
		<div className='form-row cutom-details'>
			<div className='col-12 col-lg-12'>
				<div className='mt-md-2 mt-0'>
					<div className='form-row'>
						<div className='col-12'>
							<div className='form-row mb-md-3 mb-0'>
								<div
									className='col-12 col-lg-2 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('CAPTURE_AWB.ISSUED_BY')}
									</label>
									<input
										id='issuedBy'
										type='text'
										className='form-item__field'
										maxLength='35'
										placeholder=''
										value={awbExecutionDetails.issuedBy ? awbExecutionDetails.issuedBy.toUpperCase() : ''}
										onChange={(e) => {
											awbExecutionDetails.issuedBy = e.target.value
											setAwbDetails((prevState) => ({
												...prevState,
												awbExecutionDetails: awbExecutionDetails,
											}))
										}}
										onBlur={(e) => {
											validateAlphaRequired(
												e.target.value,
												setError,
												'issuedBy',
												t('CAPTURE_AWB.ISSUED_BY')
											)
										}}
										aria-invalid={hasError('issuedBy', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['issuedBy']
											? t(error['issuedBy'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className='col-12 col-lg-2 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('CAPTURE_AWB.ISSUED_PLC')}
									</label>
									<input
										id='issuedPlace'
										type='text'
										className='form-item__field'
										maxLength='35'
										value={awbExecutionDetails.issuedPlace ? awbExecutionDetails.issuedPlace.toUpperCase(): ''}
										onChange={(e) => {
											awbExecutionDetails.issuedPlace = e.target.value
											setAwbDetails((prevState) => ({
												...prevState,
												awbExecutionDetails: awbExecutionDetails,
											}))
										}}
										onBlur={(e) => {
											validateAlphaRequired(
												e.target.value,
												setError,
												'issuedPlace',
												t('CAPTURE_AWB.ISSUED_PLC')
											)
										}}
										aria-invalid={hasError('issuedPlace', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['issuedPlace']
											? t(error['issuedPlace'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className={`col-12 col-lg-2 calendar-group form-item ${
										error && error['issuedDate'] ? 'red-border' : ''
									}`}
								>
									<label className='form-item__label'>
										{t('COMMON.ISSUED_DATE')}
									</label>

									<Calendar
										id='issuedDate'
										className='form-item__field'
										dateFormat='dd M yy'
										value={
											awbExecutionDetails.issueDate
												? moment(awbExecutionDetails.issueDate, 'DD-MMM-YYYY').toDate()
												: moment().toDate()
										}
										onChange={handleChangeDate}
										placeholder='Issued date'
										showOtherMonths={false}
										minDate={moment().toDate()}
										onFocus={() => {
											setError((prevState) => ({
												...prevState,
												issuedDate: '',
											}))
										}}
										onBlur={({ target }) => {}}
									/>
									<span className='form-item__error'>
										{error && error['issuedDate']
											? t(error['issuedDate'], { ns: 'errors' })
											: ''}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ExecutionDetails
