import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import sessionKeys from '../../../constants/sessionKeys'
import { refreshTokens } from '../../../services/authService'
import {
	clearItemFromLocalStorage,
	setToLocalStorage,
	getBase64Decoded,
} from '../../../utils/StorageUtils'
import queryString from 'query-string'
import LoadingIndicator from '../../LoadingIndicator'

const Callback = ({
	setRefreshToken,
	setAccessToken,
	clearAuthFromState,
}) => {
	const location = useLocation()

	const params = queryString.parse(location.search)
	const { token: initToken, RelayState, SSORefreshUrl, SSOType } = params

	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		clearAuthFromState()

		if (SSORefreshUrl) {
			setToLocalStorage(sessionKeys.SSO_REFRESH_URL, SSORefreshUrl)
		}
		if (SSOType) {
			setToLocalStorage(sessionKeys.SSO_TYPE, SSOType)
		}
		
		(async () => {
			try {
				if (initToken) {
					setRefreshToken(initToken)
					const tokens = await refreshTokens(initToken)
					if (tokens.accessToken) {
						setAccessToken(tokens.accessToken)
						setRefreshToken(tokens.refreshToken)
						setIsLoading(false)
					}
				}
			} catch (err) {
				console.log(err)
				clearAuthFromState()
				setIsLoading(false)
				return <Redirect to='/logout' />
			}
		})()
	}, [])

	if (isLoading) {
		return  <LoadingIndicator />
	}

	if (RelayState && RelayState.length) {
		window.location.replace(RelayState)
	} else {
		return <Redirect to='/' />
	}

	return null
}

export default Callback
