import { call, put, takeEvery } from 'redux-saga/effects'
import { ERROR_MESSAGE } from '../../constants'
import { getBookingListResponse } from '../../services/manageBookingService'
import { getUserProfile } from '../../services/userProfileService'

import {
	getQuoteResponse,
	getSaveBookingResponse,
	updateBooking,
	validateBooking,
} from '../../services/quoteServices'
import {
	listBookingError,
	listBookingSuccess,
} from '../actions/listBookingActions'

import {
	saveTemplateResponse,
	saveTemplateError,
	requestSaveTemplate,
} from '../actions/saveTemplateActions'

import {
	listFlightError,
	listFlightSuccess,
} from '../actions/listFlightActions'
import {
	saveBookingError,
	saveBookingResponse,
} from '../actions/saveBookingActions'
import {
	userProfileSuccess,
	userProfileError,
} from '../actions/userProfileActions'
import {
	LIST_BOOKING,
	LIST_DRAFTS,
	LIST_FLIGHT,
	LIST_TEMPLATE_NAMES,
	SAVE_BOOKING,
	SAVE_DRAFT,
	SAVE_TEMPLATE,
	USER_PROFILE,
	UPDATE_BOOKING,
	VALIDATE_BOOKING,
	LIST_QUOTE,
	SAVE_COMMODITY,
	SAVE_AIRPORT_MASTER,
	SAVE_SERVICE_MASTER,
	SAVE_PRODUCT_MASTER,
	SAVE_SCC_MASTER,
	SAVE_TERMS_MASTER,
	SAVE_ULD_TYPE_MASTER,
	SAVE_PIVOT_INFO_MASTER,
	SAVE_SCC_INCOMPATIBLE_MASTER,
	SAVE_STATION_SCHEDULE_MASTER,
	SAVE_REGION_MASTER,
	SAVE_COUNTRY_MASTER,
	SAVE_EMBARGO_MASTER,
	AVI_BOOKING,
	SEASONAL_BOOKING,
} from '../constants'
import { getSaveTemplateResponse } from '../../services/saveTemplateService'
import {
	listTemplateNamesError,
	listTemplateNamesResponse,
} from '../actions/listTemplateNamesActions'
import { getListTemplateNamesResponse } from '../../services/listTemplateNamesService'
import {
	updateBookingError,
	updateBookingResponse,
} from '../actions/updateBookingActions'
import {
	validateBookingError,
	validateBookingResponse,
} from '../actions/validateBookingActions'
import { getSaveDraftResponse } from '../../services/saveDraftService'
import { saveDraftError, saveDraftResponse } from '../actions/saveDraftActions'
import { getListDraftResponse } from '../../services/listDraftServices'
import {
	listDraftsError,
	listDraftsResponse,
} from '../actions/listDraftsAction'
import { listQuotes } from '../../services/quoteServices'
import { listQuoteError, listQuoteResponse } from '../actions/listQuotesActions'
import {
	getSaveAirportMasterResponse,
	getSaveCommodityResponse,
	getSaveProductMasterResponse,
	getSaveSccMasterResponse,
	getSaveServiceMasterResponse,
	saveTermsConditionsResponse,
	saveUldTypeMaster,
	savePivotInfoMaster,
	saveSccIncompatibleMaster,
	saveStationScheduleMaster,
	saveRegionMaster,
	saveCountryMaster,
	getSaveEmbargoResponse

} from '../../services/adminMasterServices'

import {
	getAviBookingDetails
} from '../../services/aviBookingService'

import {
	getAviBookingDetailsSuccess,
} from '../actions/aviBookingActions'
import {
	saveAirportMasterError,
	saveAirportMasterResponse,
	saveCommodityError,
	saveCommodityResponse,
	saveProductMasterError,
	saveProductMasterResponse,
	saveSccMasterError,
	saveSccMasterResponse,
	saveServiceMasterError,
	saveServiceMasterResponse,
	savetermsMasterError,
	saveTermsMasterResponse,
	saveUldTypeMasterError,
	saveUldTypeMasterResponse,
	savePivotInfoMasterResponse,
	savePivotInfoMasterError,
	saveSccIncompatibillityMasterError,
	saveSccIncompatibillityMasterResposne,
	saveStationScheduleMasterResponse,
	saveStationScheduleMasterError,
	saveRegionMasterResponse,
	saveRegionMasterError,
	saveCountryMasterResponse,
	saveCountryMasterError,
	saveEmbargoMasterError,
	saveEmbargoMasterResponse
} from '../actions/adminActions'
import {
	getSeasonalBookingListSuccess,
} from '../actions/seasonalBookingActions'
import {
	getSeasonalBookingList
} from '../../services/permanentService'
import { getErrorMessage } from '../../utils/common'
import i18n from '../../i18n'
function* getQuoteSaga({ searchFormData }) {
	let errorObj
	const date = searchFormData.shippingDate
	const t = i18n.t
	try {
		const response = yield call(getQuoteResponse, searchFormData)
		const { id } = searchFormData
		const { flightList, errorDetails } = response
		if (!(errorDetails && errorDetails.length)) {
			yield put(
				listFlightSuccess({
					id,
					date,
					flightList,
				})
			)
		} else {
			errorObj = {
				handledError: true,
				description: getErrorMessage(errorDetails),
				type: 'Error',
			}
			yield put(
				listFlightError({
					date,
					error: errorObj,
				})
			)
		}
	} catch (error) {
		errorObj = {
			handledError: false,
			description: t(ERROR_MESSAGE.UNEXPECTED_ERROR, { ns: 'errors' }),
			type: 'Error',
		}
		yield yield put(
			listFlightError({
				date,
				error: errorObj,
			})
		)
	}
}

function* saveBookingSaga({
	awbNumber,
	spotRatePayload,
	searchFormData,
	selectedFlight,
}) {
	let errorObj
	const t = i18n.t
	try {
		const response = yield call(
			getSaveBookingResponse,
			searchFormData,
			selectedFlight,
			spotRatePayload,
			awbNumber
		)
		if (response && response.status === 'F') {
			errorObj = {
				handledError: true,
				description: response.errorDetails[0].errorDescription,
				type: response.errorDetails[0].errorType || 'Error',
			}
			yield put(saveBookingError(errorObj))
		} else {
			yield put(
				saveBookingResponse({
					...response,
					warning: response.errorDetails[0]
						? response.errorDetails[0].errorDescription
						: null,
				})
			)
		}
	} catch (error) {
		errorObj = {
			handledError: false,
			description: t(ERROR_MESSAGE.UNEXPECTED_ERROR, { ns: 'errors' }),
			type: 'Error',
		}
		yield put(saveBookingError(errorObj))
	}
}

function* updateBookingSaga({
	awbNumber,
	searchFormData,
	selectedFlight,
	spotRatePayload,
}) {
	let errorObj
	try {
		const response = yield call(
			updateBooking,
			searchFormData,
			selectedFlight,
			awbNumber,
			spotRatePayload
		)
		if (response && response.status === 'F') {
			errorObj = {
				handledError: true,
				errorCode: response.errorDetails[0].errorCode,
				description: response.errorDetails[0].errorDescription,
				type: response.errorDetails[0].errorType || 'Error',
			}
			yield put(updateBookingError(errorObj))
		} else {
			yield put(
				updateBookingResponse({
					...response,
					warning:
						response.errorDetails && response.errorDetails[0]
							? response.errorDetails[0].errorDescription
							: null,
				})
			)
		}
	} catch (error) {
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(updateBookingError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

function* validateBookingSaga({ awbNumber, searchFormData, selectedFlight }) {
	let errorObj
	const t = i18n.t
	try {
		const response = yield call(
			validateBooking,
			searchFormData,
			selectedFlight,
			awbNumber
		)
		if (response && response.status === 'F') {
			errorObj = {
				handledError: true,
				errorCode: response.errorDetails[0].errorCode,
				description: response.errorDetails[0].errorDescription,
				type: response.errorDetails[0].errorType || 'Error',
			}
			yield put(validateBookingError(errorObj))
		} else {
			yield put(validateBookingResponse(response))
		}
	} catch (error) {
		console.log('error', error)
		errorObj = {
			handledError: false,
			description: t(ERROR_MESSAGE.UNEXPECTED_ERROR, { ns: 'errors' }),
			type: 'Error',
		}
		yield put(validateBookingError(errorObj))
	}
}

//================= Save Template =======================//
function* saveTemplateSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveTemplateResponse, payload)
		if (
			response &&
			response.responseDetails &&
			response.responseDetails.status === 'F'
		) {
			errorObj = {
				handledError: true,
				description: response.responseDetails.errorDetails[0].errorDescription,
				type: response.responseDetails.errorDetails[0].errorType,
			}
			yield put(saveTemplateError(errorObj))
		} else {
			yield put(saveTemplateResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveTemplateError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//================= List Template Names =======================//
function* listTemplateNamesSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getListTemplateNamesResponse, payload)
		if (
			response &&
			response.responseDetails &&
			response.responseDetails.status === 'F'
		) {
			errorObj = {
				handledError: true,
				description: response.responseDetails.errorDetails[0].errorDescription,
				type: response.responseDetails.errorDetails[0].errorType,
			}
			yield put(listTemplateNamesError(errorObj))
		} else {
			yield put(listTemplateNamesResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(listTemplateNamesError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//================= Save Draft =======================//
function* saveDraftSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveDraftResponse, payload)
		if (
			response &&
			response.responseDetails &&
			response.responseDetails.status === 'F'
		) {
			errorObj = {
				handledError: true,
				description: response.responseDetails.errorDetails[0].errorDescription,
				type: response.responseDetails.errorDetails[0].errorType,
			}
			yield put(saveDraftError(errorObj))
		} else {
			yield put(saveDraftResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveDraftError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//================= List Drafts =======================//
function* listDraftsSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getListDraftResponse, payload)
		if (
			response &&
			response.responseDetails &&
			response.responseDetails.status === 'F'
		) {
			errorObj = {
				handledError: true,
				description: response.responseDetails.errorDetails[0].errorDescription,
				type: response.responseDetails.errorDetails[0].errorType,
			}
			yield put(listDraftsError(errorObj))
		} else {
			yield put(listDraftsResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(listDraftsError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

function* getBookingSaga({ payload }) {
	try {
		const response = yield call(getBookingListResponse, payload)
		yield put(listBookingSuccess(response))
	} catch (error) {
		const errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(listBookingError(errorObj))
	}
}

function* getUserProfileSaga(userCode) {
	try {
		const response = yield call(getUserProfile, userCode)
		yield put(userProfileSuccess(response))
	} catch (error) {
		const errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(userProfileError(errorObj))
	}
}

//=======================list quotes================================//

function* listQuoteSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(listQuotes, payload)
		if (
			response &&
			response.responseDetails &&
			response.responseDetails.status === 'F'
		) {
			errorObj = {
				handledError: true,
				description: response.responseDetails.errorDetails[0].errorDescription,
				type: response.responseDetails.errorDetails[0].errorType,
			}
			yield put(listQuoteError(errorObj))
		} else {
			yield put(listQuoteResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(listQuoteError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//================= Save Commodity =======================//
function* saveCommoditySaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveCommodityResponse, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveCommodityError(errorObj))
		} else {
			yield put(saveCommodityResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveCommodityError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//================= Save Airport Master =======================//
function* saveAirportMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveAirportMasterResponse, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveAirportMasterError(errorObj))
		} else {
			yield put(saveAirportMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveAirportMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//===================== Embargo Master ================//


function* saveEmbargoMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveEmbargoResponse, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveEmbargoMasterError(errorObj))
		} else {
			yield put(saveEmbargoMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveEmbargoMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}


// ===================================================//

//================= Save Service Master =======================//
function* saveServiceMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveServiceMasterResponse, payload)
		if (response && response?.status === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveServiceMasterError(errorObj))
		} else {
			yield put(saveServiceMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveServiceMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//================= Save Product Master =======================//
function* saveProductMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveProductMasterResponse, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveProductMasterError(errorObj))
		} else {
			yield put(saveProductMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveProductMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//

//================= Save Scc Master =======================//
function* saveSccMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(getSaveSccMasterResponse, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveSccMasterError(errorObj))
		} else {
			yield put(saveSccMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveSccMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//================= Save ULD Type Master =======================//
function* saveUldTypeMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(saveUldTypeMaster, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveUldTypeMasterError(errorObj))
		} else {
			yield put(saveUldTypeMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveUldTypeMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//================= Save Pivot Info Master =======================//
function* savePivotInfoMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(savePivotInfoMaster, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(savePivotInfoMasterError(errorObj))
		} else {
			yield put(savePivotInfoMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(savePivotInfoMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

function* saveSccIncompatibleMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(saveSccIncompatibleMaster, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveSccIncompatibillityMasterError(errorObj))
		} else {
			yield put(saveSccIncompatibillityMasterResposne(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveSccIncompatibillityMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

function* saveStationScheduleMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(saveStationScheduleMaster, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveStationScheduleMasterError(errorObj))
		} else {
			yield put(saveStationScheduleMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveStationScheduleMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

function* saveRegionMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(saveRegionMaster, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveRegionMasterError(errorObj))
		} else {
			yield put(saveRegionMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveRegionMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

function* saveCountryMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(saveCountryMaster, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(saveCountryMasterError(errorObj))
		} else {
			yield put(saveCountryMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(saveCountryMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}
//=======================================================//

//================= Save terms Master =======================//
function* saveTermsMasterSaga({ payload }) {
	let errorObj
	try {
		const response = yield call(saveTermsConditionsResponse, payload)
		if (response && response?.message === 'F') {
			errorObj = {
				handledError: true,
				description: response?.errorDetails?.message,
				type: response?.errorDetails?.code,
			}
			yield put(savetermsMasterError(errorObj))
		} else {
			yield put(saveTermsMasterResponse(response))
		}
	} catch (error) {
		console.log(error.message)
		errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(savetermsMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=======================================================//


//=============== Seasonal booking ================


function* getSeasonalBookingListSaga({ payload }) {
	let errorOb;
	try {
		const response = yield call(getSeasonalBookingList, payload)
		if (response) {
			yield put(getSeasonalBookingListSuccess(response))
		}
	} catch (error) {
		console.log(error.message, 'sss')
		// errorObj = {
		// 	handledError: false,
		// 	description: ERROR_MESSAGE.REQUEST_TIMEOUT,
		// 	type: 'Error',
		// }
		// yield put(savetermsMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}

//=============  AVI booking ===============//

function* getAviBookingDetailsSaga() {
	let errorObj
	try {
		const response = yield call(getAviBookingDetails)
		if (response) {
			yield put(getAviBookingDetailsSuccess(response))
		}
	} catch (error) {
		// console.log(error.message)
		// errorObj = {
		// 	handledError: false,
		// 	description: ERROR_MESSAGE.REQUEST_TIMEOUT,
		// 	type: 'Error',
		// }
		// yield put(savetermsMasterError(ERROR_MESSAGE.REQUEST_TIMEOUT))
	}
}



function* watchQuoteResponseSaga() {
	yield takeEvery(LIST_FLIGHT.LOAD, getQuoteSaga)
}

function* watchSaveQuoteResponseSaga() {
	yield takeEvery(SAVE_BOOKING.LOAD, saveBookingSaga)
}

function* watchUpdateBookingResponseSaga() {
	yield takeEvery(UPDATE_BOOKING.LOAD, updateBookingSaga)
}

function* watchValidateBookingResponseSaga() {
	yield takeEvery(VALIDATE_BOOKING.LOAD, validateBookingSaga)
}

function* watchSaveTemplateSaga() {
	yield takeEvery(SAVE_TEMPLATE.LOAD, saveTemplateSaga)
}

function* watchListTemplateNamesSaga() {
	yield takeEvery(LIST_TEMPLATE_NAMES.LOAD, listTemplateNamesSaga)
}

function* watchGetBookingSaga() {
	yield takeEvery(LIST_BOOKING.LOAD, getBookingSaga)
}

function* watchGetUserProfileSaga() {
	yield takeEvery(USER_PROFILE.LOAD, getUserProfileSaga)
}

function* watchSaveDraftSaga() {
	yield takeEvery(SAVE_DRAFT.LOAD, saveDraftSaga)
}

function* watchListDraftsSaga() {
	yield takeEvery(LIST_DRAFTS.LOAD, listDraftsSaga)
}

function* watchListQuotesSaga() {
	yield takeEvery(LIST_QUOTE.LOAD, listQuoteSaga)
}

function* watchSaveCommodity() {
	yield takeEvery(SAVE_COMMODITY.LOAD, saveCommoditySaga)
}

function* watchSaveAirportMaster() {
	yield takeEvery(SAVE_AIRPORT_MASTER.LOAD, saveAirportMasterSaga)
}

function* watchSaveEmbargoMaster() {
	yield takeEvery(SAVE_EMBARGO_MASTER.LOAD, saveEmbargoMasterSaga)
}

function* watchSaveServiceMaster() {
	yield takeEvery(SAVE_SERVICE_MASTER.LOAD, saveServiceMasterSaga)
}

function* watchSaveProductMaster() {
	yield takeEvery(SAVE_PRODUCT_MASTER.LOAD, saveProductMasterSaga)
}

function* watchSaveSccMaster() {
	yield takeEvery(SAVE_SCC_MASTER.LOAD, saveSccMasterSaga)
}
function* watchSaveTermsMaster() {
	yield takeEvery(SAVE_TERMS_MASTER.LOAD, saveTermsMasterSaga)
}
function* watchSaveUldTypeMaster() {
	yield takeEvery(SAVE_ULD_TYPE_MASTER.LOAD, saveUldTypeMasterSaga)
}
function* watchSavePivotInfoMaster() {
	yield takeEvery(SAVE_PIVOT_INFO_MASTER.LOAD, savePivotInfoMasterSaga)
}
function* watchSaveSccIncompatibleMaster() {
	yield takeEvery(
		SAVE_SCC_INCOMPATIBLE_MASTER.LOAD,
		saveSccIncompatibleMasterSaga
	)
}
function* watchSaveStationScheduleMaster() {
	yield takeEvery(
		SAVE_STATION_SCHEDULE_MASTER.LOAD,
		saveStationScheduleMasterSaga
	)
}
function* watchsaveRegionMaster() {
	yield takeEvery(
		SAVE_REGION_MASTER.LOAD,
		saveRegionMasterSaga
	)
}
function* watchsaveCountryMaster() {
	yield takeEvery(
		SAVE_COUNTRY_MASTER.LOAD,
		saveCountryMasterSaga
	)
}
function* watchGetAviBookingDetails() {
	yield takeEvery(AVI_BOOKING.LOAD, getAviBookingDetailsSaga)
}

function* watchSeasonalBookingListSaga() {
	yield takeEvery(SEASONAL_BOOKING.LOAD, getSeasonalBookingListSaga)
}


export default {
	getQuoteSaga,
	saveQuoteSaga: saveBookingSaga,
	updateBookingSaga,
	validateBookingSaga,
	watchQuoteResponseSaga,
	watchSaveQuoteResponseSaga,
	watchGetBookingSaga,
	getBookingSaga,
	watchGetUserProfileSaga,
	getUserProfile,
	watchSaveTemplateSaga,
	watchListTemplateNamesSaga,
	watchUpdateBookingResponseSaga,
	watchValidateBookingResponseSaga,
	watchSaveDraftSaga,
	watchListDraftsSaga,
	watchListQuotesSaga,
	watchSaveCommodity,
	watchSaveAirportMaster,
	watchSaveServiceMaster,
	watchSaveProductMaster,
	watchSaveSccMaster,
	watchSaveTermsMaster,
	watchSaveUldTypeMaster,
	watchSavePivotInfoMaster,
	watchSaveSccIncompatibleMaster,
	watchSaveStationScheduleMaster,
	watchsaveRegionMaster,
	watchsaveCountryMaster,
	watchGetAviBookingDetails,
	watchSaveEmbargoMaster,
	watchSeasonalBookingListSaga,
}
