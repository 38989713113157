export default [
	'FLV',
	'ART',
	'AVI',
	'EQP',
	'EQN',
	'REX',
	'RCX',
	'RGX',
	'RXB',
	'RXC',
	'RXD',
	'RXE',
	'RXG',
	'RXS',
	'RRE',
	'RRW',
	'RRY',
	'ACE',
	'MUW',
	'HUM',
	'MUW',
	'SWP',
	'LTF'
]
