import {
	SAVE_BOOKING,
	SAVE_TEMPLATE,
	LIST_BOOKING,
	FLIGHT_ENQUIRY,
	LIST_USER,
	USER_PROFILE,
	LOADER,
	LIST_TEMPLATE,
	VALIDATE_BOOKING,
	UPDATE_BOOKING,
	SAVE_AIRPORT_MASTER,
	SAVE_SCC_INCOMPATIBLE_MASTER,
	SAVE_PRODUCT_MASTER,
	SAVE_SCC_MASTER,
	SAVE_ULD_TYPE_MASTER,
	SAVE_COMMODITY,
	SAVE_STATION_SCHEDULE_MASTER,
	SAVE_EMBARGO_MASTER,
	SAVE_COUNTRY_MASTER,
	SAVE_REGION_MASTER,
	SAVE_PIVOT_INFO_MASTER,
} from '../constants'

const initState = {
	isLoading: false,
}

export default function (state = initState, action = {}) {
	switch (action.type) {
		case SAVE_BOOKING.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_BOOKING.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_BOOKING.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_TEMPLATE.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_TEMPLATE.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_TEMPLATE.ERROR:
			return {
				...state,
				isLoading: false,
			}
		// case LIST_BOOKING.LOAD:
		// 	return {
		// 		...state,
		// 		isLoading: true,
		// 	}
		case FLIGHT_ENQUIRY.LOAD:
			return {
				...state,
				isLoading: true,
			}
		// case LIST_BOOKING.SUCCESS:
		// 	return {
		// 		...state,
		// 		isLoading: false,
		// 	}
		case FLIGHT_ENQUIRY.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		// case LIST_BOOKING.ERROR:
		// 	return {
		// 		...state,
		// 		isLoading: false,
		// 	}
		case FLIGHT_ENQUIRY.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case LIST_USER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case LIST_USER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case LIST_USER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case USER_PROFILE.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case USER_PROFILE.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case USER_PROFILE.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case LOADER.ENABLE_OR_DISABLE_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			}
		case LIST_TEMPLATE.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case LIST_TEMPLATE.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case LIST_TEMPLATE.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case UPDATE_BOOKING.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case UPDATE_BOOKING.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case UPDATE_BOOKING.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case VALIDATE_BOOKING.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case VALIDATE_BOOKING.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case VALIDATE_BOOKING.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_AIRPORT_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_AIRPORT_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_AIRPORT_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_SCC_INCOMPATIBLE_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_SCC_INCOMPATIBLE_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_SCC_INCOMPATIBLE_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_PRODUCT_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_PRODUCT_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_PRODUCT_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_SCC_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_SCC_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_SCC_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_ULD_TYPE_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_ULD_TYPE_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_ULD_TYPE_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_COMMODITY.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_COMMODITY.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_COMMODITY.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_STATION_SCHEDULE_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_STATION_SCHEDULE_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_STATION_SCHEDULE_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_EMBARGO_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_EMBARGO_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_EMBARGO_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_COUNTRY_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_COUNTRY_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_COUNTRY_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_PIVOT_INFO_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_PIVOT_INFO_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_PIVOT_INFO_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_REGION_MASTER.LOAD:
			return {
				...state,
				isLoading: true,
			}
		case SAVE_REGION_MASTER.SUCCESS:
			return {
				...state,
				isLoading: false,
			}
		case SAVE_REGION_MASTER.ERROR:
			return {
				...state,
				isLoading: false,
			}
		default:
			return state
	}
}
