import { FLIGHT } from '../constants'

const initState = {
	flightSelected: null,
}

export default (state = initState, action) => {
	switch (action.type) {
		case FLIGHT.SET_FLIGHT:
			return {
				...state,
				flightSelected: action.flightSelected,
			}

		default:
			return state
	}
}
