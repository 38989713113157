import React, { useEffect, useState } from 'react';
import AddMultiFlights from './AddMultiFlights/AddMultiFlights';
import DateScheduler from './DateScheduler/DateScheduler';


const SchedulerMultiFlightsContainer = ({
     startDate,
     setStartDate,
     endDate,
     setEndDate,
     flightList,
     setFlightList,
     selectedOrigin,
     setSelectedOrigin,
     flightsMaxShippingDate,
     setFlightsMaxShippingDate,
     flightsMinDate,
     setFlightsMinDate,
     schedulerMaxShippingDate,
     setSchedulerMaxShippingDate,
     schedulerMinDate,
     setSchedulerMinDate,
     weekdays,
     setWeekdays,
     dateScheduler,
     setDateScheduler,
     setSeasonalError,
     seasonalError,
     selectedDestination,
     isAllotmentRequired,
     isAllotmentRequiredFlightDate,
     setIsAllotmentRequiredFlightDate,
	setIsAllotmentRequired,
     indexArray
}) => {

     const [startonDay, setStartonDay] = useState('')

     return (
          <div>
               <DateScheduler
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    schedulerMaxShippingDate={schedulerMaxShippingDate}
                    setSchedulerMaxShippingDate={setSchedulerMaxShippingDate}
                    schedulerMinDate={schedulerMinDate}
                    setSchedulerMinDate={setSchedulerMinDate}
                    weekdays={weekdays}
                    setWeekdays={setWeekdays}
                    dateScheduler={dateScheduler}
                    setDateScheduler={setDateScheduler}
                    seasonalError={seasonalError}
                    setSeasonalError={setSeasonalError}
                    startonDay={startonDay}
                    setStartonDay={setStartonDay}
               />
               <AddMultiFlights
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    flightList={flightList}
                    setFlightList={setFlightList}
                    selectedOrigin={selectedOrigin}
                    setSelectedOrigin={setSelectedOrigin}
                    flightsMaxShippingDate={flightsMaxShippingDate}
                    setFlightsMaxShippingDate={setFlightsMaxShippingDate}
                    flightsMinDate={flightsMinDate}
                    setFlightsMinDate={setFlightsMinDate}
                    seasonalError={seasonalError}
                    setSeasonalError={setSeasonalError}
                    selectedDestination={selectedDestination}
                    dateScheduler={dateScheduler}
                    startonDay={startonDay}
                    setStartonDay={setStartonDay}
                    isAllotmentRequired= {isAllotmentRequired}
				setIsAllotmentRequired= {setIsAllotmentRequired}
                    isAllotmentRequiredFlightDate= {isAllotmentRequiredFlightDate}
                    setIsAllotmentRequiredFlightDate= {setIsAllotmentRequiredFlightDate}
                    indexArray= {indexArray}

               />
          </div>
     )
}

export default SchedulerMultiFlightsContainer;