import { FILTERS } from '../constants'

const initialState = {
	filters: null,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case FILTERS.SET_FILTERS:
			return {
				...state,
				filters: action.filters,
			}
		case FILTERS.CLEAR_FILTERS:
			return {
				...state,
				filters: null,
			}
		default:
			return state
	}
}
