import React from 'react';

const ExportTermsConditionsData = (pdfValue) => {
    const baseUrl = pdfValue?.pdfvalue;

    const exportToPdf = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "abc.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <a
            className='termsPdf-download-button'
            href={baseUrl && baseUrl.length ? `/media/${baseUrl}` : '/'}
            target="_blank">
            {(baseUrl != '' || baseUrl != null) &&
                <img src={require('../../assets/images/DownloadExcel.PNG').default} style={{ height: "30px", width: "55px" }} />
            }
        </a>

    )
}

export default ExportTermsConditionsData;