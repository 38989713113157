import React, { useEffect, useState } from 'react'
import DoughnutChart from '.'
import './index.scss'
import { getDecimalFormattedNumber } from '../../../constants'
import Messages from '../../../constants/Messages'
import { useTranslation } from 'react-i18next'
const ProductChart = ({
	chartData,
	chartDataType,
	isLoading,
	changeToLocalCurrency,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])


	const [labels, setLabels] = useState([])
	const [data, setData] = useState([])

	const labelSuffix = '%'
	const backgroundColor = ['#7f3035', '#F68D2E', '#FFC72C', '#D6D2C4']
	const borderColor = ['#7f3035', '#F68D2E', '#FFC72C', '#D6D2C4']

	useEffect(() => {
		const productData = chartData ? chartData.productData : null
		getChartData(productData)
	}, [chartData,chartDataType])

	const getChartData = (productData) => {
		if (productData != null && productData.products.length !== 0) {
			let productLabels = []
			let dataSet = []
			productData.products.slice(0, 3).map((product) => {
				let dataElementName = product.dataElementName
				if (product.dataElementValue && chartDataType === 'awbCount') {
					productLabels.push(
						dataElementName +
							' ' +
							product.dataElementValue.countPercent +
							labelSuffix
					)
					dataSet.push(product.dataElementValue.countPercent)
				} else if (product.dataElementValue && chartDataType === 'tonnage') {
					productLabels.push(
						dataElementName +
							' ' +
							product.dataElementValue.tonnagePercent +
							labelSuffix
					)
					dataSet.push(product.dataElementValue.tonnagePercent)
				} else if (
					product.dataElementValue &&
					chartDataType === 'charge' &&
					changeToLocalCurrency
				) {
					productLabels.push(
						dataElementName +
							' ' +
							product.dataElementValue.localChargePercent +
							labelSuffix
					)
					dataSet.push(product.dataElementValue.localChargePercent)
				} else {
					productLabels.push(
						dataElementName +
							' ' +
							product.dataElementValue.chargePercent +
							labelSuffix
					)
					dataSet.push(product.dataElementValue.chargePercent)
				}
			})
			if (productLabels.length > 1) {
				const othersPercentage = getOtherProductsPercentage(dataSet)
				if (othersPercentage >= 0.01) {
					dataSet.push(othersPercentage)
					productLabels.push('Others ' + othersPercentage + labelSuffix)
				}
			}
			setLabels(productLabels)
			setData(dataSet)
		} else if (productData != null && productData.products.length == 0) {
			setLabels([])
			setData([])
		}
	}

	const getOtherProductsPercentage = (dataSet) => {
		const sum = dataSet.reduce((a, b) => a + b, 0)
		return getDecimalFormattedNumber(100 - sum)
	}

	return (
		<div className='doughnet-chart product-chart'>
			<h3 className='section-sub-heading mt-0 mb-0'>{t('DASHBOARD.PRD')}</h3>
			{labels && labels.length !== 0 && data && data.length !== 0 && (
				<DoughnutChart
					dataSetLabel='products'
					labels={labels}
					backgroundColor={backgroundColor}
					borderColor={borderColor}
					data={data}
				/>
			)}
			{labels &&
				labels.length === 0 &&
				data &&
				data.length === 0 &&
				!isLoading && (
					<div className='empty-records ml-0 pl-0'>
						{t(Messages.NO_DATA_AVAILABLE, { ns: 'errors' })}
					</div>
				)}
		</div>
	)
}

export default ProductChart
