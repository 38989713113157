import React, { useState, useEffect, useRef } from 'react'
import './filter-option.scss'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Slider } from 'primereact/slider'
import { InputText } from 'primereact/inputtext'
// import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import clockIcon from '../../assets/images/clock.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectButton } from 'primereact/selectbutton'
import { setSearchFormData } from '../../redux/actions/searchFormActions'
import filterIcon from '../../assets/images/filter.svg'
import closeIcon from '../../assets/images/suggestSimiliarClose.png'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
} from '../../utils/StorageUtils'
import { useTranslation } from 'react-i18next'
import { UNKNOWN_SHIPPER_ENABLED } from '../../configs/featureControlConfig'
import {
	updateFlightSearchFilterData,
	updateFlightSearchSortingData
} from '../../utils/analytics'

const FilterOptions = ({
	setFiltersToState,
	filters,
	searchFormData,
	airportList,
	setSearchFormData,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [cheepest, setcheepest] = useState(true)
	const [fastest, setfastest] = useState(false)
	const [leastStops, setleastStops] = useState(false)
	const [stops, setStops] = useState(null)
	const op = useRef(null)
	const sortOverlay = useRef(null)
	const [duration, setDuration] = useState(100)
	const [departureTime, setDepartureTime] = useState([0, 1439])
	const [departureTimeInHours, setDepartureTimeInHours] = useState([
		'0:00 AM',
		'23:59 PM',
	])
	const [arrivalTime, setArrivalTime] = useState([0, 1439])
	const [arraivalTimeInHours, setArraivalTimeInHours] = useState([
		'0:00 AM',
		'23:59 PM',
	])
	const [LATTime, setLATTime] = useState([0, 1439])
	const [LATTimeInHours, setLATTimeTimeInHours] = useState([
		'0:00 AM',
		'23:59 PM',
	])
	const [EDTTime, setEDTTime] = useState([0, 1439])
	const [EDTTimeInHours, setEDTTimeTimeInHours] = useState([
		'0:00 AM',
		'23:59 PM',
	])
	const [isAllotmentsOnly, setAllotmentsOnly] = useState(false)

	const [isUSBound, setIsUSBound] = useState(false)

	const [isUnknownShipment, setIsUnknownShipment] = useState(false)

	const initialStateAircraftClass = {
		narrowBody: true,
		wideBody: true,
		truck: true,
		freighter: true,
	}
	const [aircraftClassification, setAircraftClassification] = useState(
		initialStateAircraftClass
	)

	useEffect(() => {
		const { isUnknownShipment, isUSBound } = searchFormData
		setIsUSBound(isUSBound)
	}, [searchFormData])

	const stopsList = [
		{ label: 'Non stop', value: '0' },
		{ label: '1 Stop', value: '1' },
		{ label: '2+ Stops', value: '2+' },
	]
	const [filterUsedIs,setFilterUsedIs]=useState(false)
	useEffect(() => {
		setFiltersToState({
			...filters,
			cheepest,
			fastest,
			leastStops,
		})
	}, [cheepest, fastest, leastStops])
	useEffect(() => {
		const stopsValue = stops || stops == 0 ? parseInt(stops) : null
		setDuration(0)
		setDepartureTime([0, 1439])
		setDepartureTimeInHours(['0:00 AM', '23:59 PM'])
		setArrivalTime([0, 1439])
		setArraivalTimeInHours(['0:00 AM', '23:59 PM'])
		setLATTime([0, 1439])
		setLATTimeTimeInHours(['0:00 AM', '23:59 PM'])
		setEDTTime([0, 1439])
		setEDTTimeTimeInHours(['0:00 AM', '23:59 PM'])
		setStops(null)
		setAircraftClassification(initialStateAircraftClass)
		setFiltersToState({
			...filters,
			duration,
			departureTime,
			arrivalTime,
			departureTimeInHours,
			arraivalTimeInHours,
			LATTime,
			LATTimeInHours,
			EDTTime,
			EDTTimeInHours,
			stop: stopsValue,
			isUnknownShipment: searchFormData ? searchFormData.isUnknownShipment : null,
			aircraftClassification,
		})
		/* const dummy = {
			duration: 0,
			aircraftClassification: '',
			stops: null,
		}
		setToSessionStorage('selectedfilter', getBase64Encoded(dummy)) */
		return () => {
			const encodedFormData = getFromSessionStorage('searchObject')
			const formData = getBase64Decoded(encodedFormData)
			setSearchFormData(formData)
		}
	}, [])

	const showFilter = (e) => {
		op.current.toggle(e)
		setDepartureTime(filters.departureTime)
		setArrivalTime(filters.arrivalTime)
		setDuration(filters.duration)
		setArraivalTimeInHours(filters.arraivalTimeInHours)
		setDepartureTimeInHours(filters.departureTimeInHours)
		setLATTime(filters.LATTime)
		setLATTimeTimeInHours(filters.LATTimeInHours)
		setEDTTime(filters.EDTTime)
		setEDTTimeTimeInHours(filters.EDTTimeInHours)
		setStops(
			filters.stops ? toString(filters.stops) : filters.stops == 0 ? '0' : null
		)
		setAircraftClassification(filters.aircraftClassification)
	}

	const stopTemplate = (option) => {
		if (!option.value) {
			return option.label
		} else {
			return (
				<div>
					<span>{option.value}</span>
				</div>
			)
		}
	}
	useEffect(() => {
		if (!filters) {
			var sort = 'cheapest'
			setToSessionStorage('sortOption', sort)
		}
	},
		[filters])
	const setFilter = (filter) => {
		if (filter == 'cheepest') {
			setcheepest(true)
			setfastest(false)
			setleastStops(false)
		}
		if (filter == 'leastStops') {
			setcheepest(false)
			setfastest(false)
			setleastStops(true)
		}
		if (filter == 'fastest') {
			setcheepest(false)
			setfastest(true)
			setleastStops(false)
		}
		let sortOption = filter
		if (filter === 'cheepest') {
			sortOption = 'cheapest'
		}
		clearItemFromSessionStorage('sortOption')
		setToSessionStorage('sortOption', sortOption)
		updateFlightSearchSortingData(sortOption)
	}

	const covertDecimalToTime = (component, value) => {
		let startTime, endTime

		value.forEach((element, index) => {
			let hoursAndMinute = element / 60
			let hour = parseInt(hoursAndMinute)
			let minute = hoursAndMinute % 1
			minute = parseInt(minute * 60)
				.toString()
				.padStart(2, 0)
			if (index === 0) {
				startTime =
					hour >= 12 ? hour + ':' + minute + ' PM' : hour + ':' + minute + ' AM'
			} else {
				endTime =
					hour >= 12 ? hour + ':' + minute + ' PM' : hour + ':' + minute + ' AM'
			}
		})
		if (component === 'departure') {
			setDepartureTimeInHours([startTime, endTime])
			setDepartureTime(value)
		} else if (component === 'arraival') {
			setArraivalTimeInHours([startTime, endTime])
			setArrivalTime(value)
		} else if (component == 'LAT') {
			setLATTimeTimeInHours([startTime, endTime])
			setLATTime(value)
		} else {
			setEDTTimeTimeInHours([startTime, endTime])
			setEDTTime(value)
		}
	}

	const onFilter = (e) => {
		const stopsValue =
			stops || stops == 0 ? (stops !== '2+' ? parseInt(stops) : stops) : null
		op.current.hide(e)
		if (showFilterModal) {
			setShowFilterModal(!showFilterModal)
		}
		setFiltersToState({
			...filters,
			duration,
			departureTime,
			arrivalTime,
			departureTimeInHours,
			arraivalTimeInHours,
			LATTime,
			LATTimeInHours,
			EDTTime,
			EDTTimeInHours,
			stops: stopsValue,
			isAllotmentsOnly,
			aircraftClassification,
		})
		 setFilterUsedIs(true)
	}
	if(filterUsedIs==true){
		const selectedfilter = {
		   duration: duration,
		   aircraftClassification: aircraftClassification,
		   stops: stops,
	   }
	   setToSessionStorage('selectedfilter', getBase64Encoded(selectedfilter)) 
	   updateFlightSearchFilterData(selectedfilter)
   }
   else{
	const dummy = {
		duration: 0,
		aircraftClassification: '',
		stops: null,
	}
	setToSessionStorage('selectedfilter', getBase64Encoded(dummy))
   } 
	const onClear = (e) => {
		setDuration(0)
		setDepartureTime([0, 1439])
		setDepartureTimeInHours(['0:00 AM', '23:59 PM'])
		setArrivalTime([0, 1439])
		setArraivalTimeInHours(['0:00 AM', '23:59 PM'])
		setLATTime([0, 1439])
		setLATTimeTimeInHours(['0:00 AM', '23:59 PM'])
		setEDTTime([0, 1439])
		setEDTTimeTimeInHours(['0:00 AM', '23:59 PM'])
		setStops(null)
		setAircraftClassification({
			narrowBody: false,
			wideBody: false,
			truck: false,
			freighter: false,
		})
	}

	const handleUnknownShimentFilter = (isUnknownShipment) => {
		let tempFilters = filters ?
			{
				...filters,
				isUnknownShipment,
			} :
			{
				isUnknownShipment
			}
		setFiltersToState(tempFilters)
		setIsUnknownShipment(isUnknownShipment)
		searchFormData.isUnknownShipment = isUnknownShipment
		const tempFormData = {
			...searchFormData,
			isUnknownShipment,
		}
		setToSessionStorage('searchObject', getBase64Encoded(tempFormData))
	}

	useEffect(() => {
		if (filters) {
			const { isUnknownShipment } = filters
			setIsUnknownShipment(isUnknownShipment)

			const tempFormData = {
				...searchFormData,
				isUnknownShipment,
			}
			setToSessionStorage('searchObject', getBase64Encoded(tempFormData))
		}

	}, [filters])

	const handleAllotmentFilter = (isAllotmentsOnly, e) => {
		const stopsValue =
			stops || stops == 0 ? (stops !== '2+' ? parseInt(stops) : stops) : null
		setFiltersToState({
			...filters,
			duration,
			departureTime,
			arrivalTime,
			departureTimeInHours,
			arraivalTimeInHours,
			LATTime,
			LATTimeInHours,
			EDTTime,
			EDTTimeInHours,
			stops: stopsValue,
			isAllotmentsOnly,
			aircraftClassification,
		})
	}

	const handleRadio = (e) => {
		setStops(e.target.value)
	}

	useEffect(() => {
		if (UNKNOWN_SHIPPER_ENABLED &&
			airportList && airportList.length) {
			const isUSBound = airportList.some(flight => flight.usoutbound)
			if (isUSBound) {
				setIsUSBound(isUSBound)
			}
		}
	}, [airportList])

	return (
		<div>
			<div className='flight-details__allotment-wrapper m-0 d-inline-flex mb-3 d-lg-none'>
				<div className='l-checkbox'>
					<input
						id={`allotment`}
						type='checkbox'
						checked={isAllotmentsOnly}
						onChange={(e) => {
							setAllotmentsOnly(!isAllotmentsOnly)
							handleAllotmentFilter(!isAllotmentsOnly, e)
						}}
					/>
					<label className='mb-3' htmlFor={`allotment`}></label>
				</div>
				<span className='flight-details__allotment-description'>
					{t('CHOOSE_FLT.FLIGHTS_ALLOTMENT')}
				</span>
				<div className='flight-details__allotment-indicator' />
			</div>
			<div className='fd-header'>
				<div className='flights_count'>
					<span className='fd-header__results'>{`${airportList && airportList.length ? airportList.length : 0
						}`}</span>
					&nbsp;
					<span className='fd-header__results'>{t('CHOOSE_FLT.NO_OF_FLTS')}</span>
				</div>
				<div className='filter_wrap'
				>
					<ul>
						<li className='filter__item'>
							<span
								className='showFilter sort-panel'
								onClick={(e) => sortOverlay.current.toggle(e)}
							>
								{cheepest ? <span>{t('COMMON.CHEAPEST')}</span> : <> </>}
								{fastest ? <span>{t('CHOOSE_FLT.FASTEST')}</span> : <> </>}
								{leastStops ? <span>{t('CHOOSE_FLT.LEAST_STOPS')}</span> : <> </>}
							</span>
							<OverlayPanel
								ref={sortOverlay}
								className='sort-overlay-panel'
								appendTo={document.body}
							>
								<ul>
									<li
										className={`${cheepest ? 'filter__active' : ''}`}
										onClick={(e) => {
											setFilter('cheepest')
											sortOverlay.current.hide(e)
										}}
									>
										{t('COMMON.CHEAPEST')}
									</li>
									<li
										className={`${fastest ? 'filter__active' : ''}`}
										onClick={(e) => {
											setFilter('fastest')
											sortOverlay.current.hide(e)
										}}
									>
										{t('CHOOSE_FLT.FASTEST')}
									</li>
									<li
										className={`${leastStops ? 'filter__active' : ''}`}
										onClick={(e) => {
											setFilter('leastStops')
											sortOverlay.current.hide(e)
										}}
									>
										{t('CHOOSE_FLT.LEAST_STOPS')}
									</li>
								</ul>
							</OverlayPanel>
						</li>
						{/* <li className='filter__item'>
					<span
						className='showFilter sort-panel'
						onClick={(e) => sortOverlay.current.toggle(e)}
					>
						{cheepest === true && 'Cheapest'}
						{fastest === true && 'Fastest'}
						{leastStops === true && 'Least stops'}
					</span>
					{cheepest ? <span className='sort-by'>Cheapest</span> : <> </>}
					{fastest ? <span className='sort-by'>Fastest</span> : <> </>}
					{leastStops ? <span className='sort-by'>Least stops</span> : <> </>}
					<OverlayPanel
						ref={sortOverlay}
						className='sort-overlay-panel'
						appendTo={document.body}
					>
						<ul>
							<li
								className={`${cheepest ? 'filter__active' : ''}`}
								onClick={(e) => {
									setFilter('cheepest')
									sortOverlay.current.hide(e)
								}}
							>
								Cheapest
							</li>
							<li
								className={`${fastest ? 'filter__active' : ''}`}
								onClick={(e) => {
									setFilter('fastest')
									sortOverlay.current.hide(e)
								}}
							>
								Fastest
							</li>
							<li
								className={`${leastStops ? 'filter__active' : ''}`}
								onClick={(e) => {
									setFilter('leastStops')
									sortOverlay.current.hide(e)
								}}
							>
								Least stops
							</li>
						</ul>
					</OverlayPanel>
				</li> */}
						<li className='filter__item'>
							<span className='showFilter d-none d-lg-block' onClick={(e) => showFilter(e)}>
								<span>{t('CHOOSE_FLT.SHOW_FILTER')}</span>
							</span>
							<span className='showFilter d-block d-lg-none' onClick={() => setShowFilterModal(!showFilterModal)}>
								<span><img className='uld-form filter-icon' src={filterIcon} /></span>
							</span>
							<div className='b-none d-lg-block'>
								<OverlayPanel
									ref={op}
									className='custom_overlay_panel'
									appendTo={document.body}
								>
									<div>
										<div class='row'>
											<div className='all-filters'>
												<div class='col-6 border-rghtDiv'>
													<span className='filter-title'>{t('CHOOSE_FLT.DEP_TIME')}</span>
													<Slider
														value={departureTime}
														onChange={(e) =>
															covertDecimalToTime('departure', e.value)
														}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='row'>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={departureTimeInHours[0]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
														<div className='seperator'> - </div>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={departureTimeInHours[1]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
													</div>
													<div className='cusom_returntime'>{t('CHOOSE_FLT.ARR_TIME')}</div>
													<Slider
														value={arrivalTime}
														onChange={(e) => covertDecimalToTime('arraival', e.value)}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='row'>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={arraivalTimeInHours[0]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
														<div className='seperator'> - </div>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={arraivalTimeInHours[1]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
													</div>
													<div className='duration-section'>
														<div className='silder-div'>
															<div className='cusom_returntime'>{t('CHOOSE_FLT.DURATION')}</div>

															<Slider
																value={duration}
																onChange={(e) => setDuration(e.value)}
																className='custom-slider'
															/>
														</div>
														<div className='duration-div'>
															<span className='under-text'>{t('CHOOSE_FLT.UNDER')}</span>
															<div className='custom_input_group'>
																<div className='p-inputgroup'>
																	<InputText
																		value={duration}
																		onChange={(e) => setDuration(e.target.value)}
																	/>
																	<span className='p-inputgroup-addon'>{t('COMMON.HRS')}</span>
																</div>
															</div>
														</div>
													</div>
													<span className='cusom_label'>{t('ROUTING_ENQUIRY.AIRCRAFT_CLASS')}</span>
													<div className='cargo-portal-container form-row stops-radio-wrapper mt-3'>
														<div className='col-12 col-md-auto pr-3 mb-2'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='narrowBody'
																	checked={aircraftClassification.narrowBody}
																	value='Narrow Body'
																	type='checkbox'
																	name='narrowBody'
																	className='form-item__field'
																	onChange={(event) => {
																		console.log("e", event)
																		setAircraftClassification({
																			...aircraftClassification,
																			narrowBody: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='narrowBody' className='form-item__label'>
																	{t('COMMON.NB')}
																</label>
															</div>
														</div>
														<div className='col-12 col-md-auto pr-3 mb-2'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='wideBody'
																	checked={aircraftClassification.wideBody}
																	value='Wide Body'
																	type='checkbox'
																	name='wideBody'
																	className='form-item__field'
																	onChange={(event) => {
																		setAircraftClassification({
																			...aircraftClassification,
																			wideBody: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='wideBody' className='form-item__label'>
																	{t('COMMON.WB')}
																</label>
															</div>
														</div>
														<div className='col-12 col-md-auto pr-3 mb-2'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='truck'
																	checked={aircraftClassification.truck}
																	value='Truck'
																	type='checkbox'
																	name='truck'
																	className='form-item__field'
																	onChange={(event) => {
																		setAircraftClassification({
																			...aircraftClassification,
																			truck: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='truck' className='form-item__label'>
																	{t('ROUTING_ENQUIRY.TRUCK')}
																</label>
															</div>
														</div>
														<div className='col-12 col-md-auto pr-3 mb-2'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='freighter'
																	checked={aircraftClassification.freighter}
																	value='Freighter'
																	type='checkbox'
																	name='freighter'
																	className='form-item__field'
																	onChange={(event) => {
																		setAircraftClassification({
																			...aircraftClassification,
																			freighter: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='freighter' className='form-item__label'>
																	{t('ROUTING_ENQUIRY.FREIGHTER_FLIGHT')}
																</label>
															</div>
														</div>
													</div>
													{/* <div className='allotment-filter-section'>
													<span className='cusom_label'>Flights with allotments only</span>
													<div className='l-checkbox'>
														<input
															id={`allotment`}
															type='checkbox'
															checked={isAllotmentsOnly}
															onChange={(e) => { setAllotmentsOnly(!isAllotmentsOnly) }}
														/>
														<label className='mb-0' htmlFor={`allotment`}></label>
													</div>
												</div> */}
												</div>
												<div class='col-6'>
													<span className='filter-title'>{t('CHOOSE_FLT.LAT_FILTER')}</span>
													<span className='clearFilter' onClick={(e) => onClear(e)}>
														{t('COMMON.CLR')}
													</span>
													<Slider
														value={LATTime}
														onChange={(e) => covertDecimalToTime('LAT', e.value)}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='row'>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={LATTimeInHours[0]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
														<div className='seperator'> - </div>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={LATTimeInHours[1]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
													</div>
													<div className='cusom_returntime'>{t('CHOOSE_FLT.TOA_FILTER')}</div>

													<Slider
														value={EDTTime}
														onChange={(e) => covertDecimalToTime('EDT', e.value)}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='row'>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={EDTTimeInHours[0]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
														<div className='seperator'> - </div>
														<div class='col-5'>
															<span className='p-inputgroup time-panel'>
																<InputText value={EDTTimeInHours[1]} />
																{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
															</span>
														</div>
													</div>

													<span className='cusom_label'>{t('CHOOSE_FLT.NO_OF_STOPS')}</span>
													{/* <div className='custom_dropdown'>
													<SelectButton
														className='select-button shipping-type'
														value={stops}
														options={stopsList}
														onChange={(e) => {
															if (e.value) {
																setStops(e.target.value)
															}
														}}
													></SelectButton>
												</div> */}
													<div className='cargo-portal-container form-row stops-radio-wrapper mt-3'>
														<div className='col-4 col-md-auto pr-4'>
															<div className='form-item form-item--radio'>
																<input
																	id='nonStop'
																	type='radio'
																	value='0'
																	name='numberOfStops'
																	className='form-item__field'
																	aria-describedby='nonStop'
																	onChange={handleRadio}
																	checked={stops === '0' ? true : false}
																/>
																<label htmlFor='nonStop' className='form-item__label'>
																	{t('CHOOSE_FLT.NON_STOP')}
																</label>
															</div>
														</div>
														<div className='col-4 col-md-auto pr-4'>
															<div className='form-item form-item--radio'>
																<input
																	id='oneStop'
																	type='radio'
																	value='1'
																	name='numberOfStops'
																	className='form-item__field'
																	aria-describedby='oneStop'
																	onChange={handleRadio}
																	checked={stops === '1' ? true : false}
																/>
																<label htmlFor='oneStop' className='form-item__label'>
																	1 {t('COMMON.STOP')}
																</label>
															</div>
														</div>
														<div className='col-4 col-md-auto pr-4'>
															<div className='form-item form-item--radio'>
																<input
																	id='moreStops'
																	type='radio'
																	value='2+'
																	name='numberOfStops'
																	className='form-item__field'
																	aria-describedby='moreStops'
																	onChange={handleRadio}
																	checked={stops === '2+' ? true : false}
																/>
																<label
																	htmlFor='moreStops'
																	className='form-item__label'
																>
																	2+ {t('CHOOSE_FLT.STOPS')}
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class='row'>
											<div className='col-12 buttons-div'>
												<div>
													<button
														type='button'
														className='l-btn l-btn--primary l-btn--xs'
														onClick={(e) => onFilter(e)}
													>
														{t('COMMON.FILTER')}
													</button>
												</div>
												<div className='mr-3'>
													<button
														type='button'
														className='l-btn l-btn--secondary l-btn--xs'
														onClick={(e) => op.current.hide(e)}
													>
														{t('COMMON.CANCEL')}
													</button>
												</div>
											</div>
										</div>
									</div>
								</OverlayPanel>
							</div>
							{showFilterModal ?
								<div className='showFilter_Mob'>
									<span className='showFilter_Mob-close' onClick={() => setShowFilterModal(!showFilterModal)}>
										<img src={closeIcon} alt="close" />
									</span>
									<div>
										<div className='all-filters'>
											<div class='col-12 border-rghtDiv'>
												<div className='row'>
													<span className='filter-title d-flex'>{t('CHOOSE_FLT.DEP_TIME')}</span>
													<Slider
														value={departureTime}
														onChange={(e) =>
															covertDecimalToTime('departure', e.value)
														}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='filterField_wrap'>
														<div className='row'>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={departureTimeInHours[0]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={departureTimeInHours[1]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
														</div>
													</div>
													<div className='cusom_returntime d-flex'>{t('CHOOSE_FLT.ARR_TIME')}</div>
													<Slider
														value={arrivalTime}
														onChange={(e) => covertDecimalToTime('arraival', e.value)}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='filterField_wrap'>
														<div className='row'>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={arraivalTimeInHours[0]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={arraivalTimeInHours[1]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
														</div>
													</div>
													<div className='duration-section'>
														<div className='silder-div'>
															<div className='cusom_returntime d-flex'>{t('CHOOSE_FLT.DURATION')}</div>

															<Slider
																value={duration}
																onChange={(e) => setDuration(e.value)}
																className='custom-slider'
															/>
														</div>
														<div className='duration-div'>
															<span className='under-text d-flex'>{t('CHOOSE_FLT.UNDER')}</span>
															<div className='custom_input_group'>
																<div className='p-inputgroup'>
																	<InputText
																		value={duration}
																		onChange={(e) => setDuration(e.target.value)}
																	/>
																	<span className='p-inputgroup-addon'>{t('COMMON.HRS')}</span>
																</div>
															</div>
														</div>
													</div>
													{/* <div className='allotment-filter-section'>
												<span className='cusom_label'>Flights with allotments only</span>
												<div className='l-checkbox'>
													<input
														id={`allotment`}
														type='checkbox'
														checked={isAllotmentsOnly}
														onChange={(e) => { setAllotmentsOnly(!isAllotmentsOnly) }}
													/>
													<label className='mb-0' htmlFor={`allotment`}></label>
												</div>
											</div> */}
												</div>
											</div>
											<div class='col-12'>
												<div className='row'>
													<span className='filter-title d-flex'>{t('CHOOSE_FLT.LAT_FILTER')}</span>

													<Slider
														value={LATTime}
														onChange={(e) => covertDecimalToTime('LAT', e.value)}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='filterField_wrap'>
														<div className='row'>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={LATTimeInHours[0]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={LATTimeInHours[1]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
														</div>
													</div>
													<div className='cusom_returntime d-flex'>{t('CHOOSE_FLT.TOA_FILTER')}</div>

													<Slider
														value={EDTTime}
														onChange={(e) => covertDecimalToTime('EDT', e.value)}
														range
														min={0}
														max={1439}
														className='custom-slider'
													/>
													<div class='filterField_wrap'>
														<div className='row'>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={EDTTimeInHours[0]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
															<div class='filterField col-6'>
																<span className='p-inputgroup time-panel'>
																	<InputText value={EDTTimeInHours[1]} />
																	{/* <span className='p-inputgroup-addon'>
																<img src={clockIcon} alt='Clock' title='Clock' />
															</span> */}
																</span>
															</div>
														</div>
													</div>

													<span className='cusom_label'>{t('ROUTING_ENQUIRY.AIRCRAFT_CLASS')}</span>
													<div className='filterField_wrap cargo-portal-container form-row stops-radio-wrapper mt-3'>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='narrowBody'
																	checked={aircraftClassification.narrowBody}
																	value='Narrow Body'
																	type='checkbox'
																	name='narrowBody'
																	className='form-item__field'
																	onChange={(event) => {
																		console.log("e", event)
																		setAircraftClassification({
																			...aircraftClassification,
																			narrowBody: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='narrowBody' className='form-item__label'>
																	{t('COMMON.NB')}
																</label>
															</div>
														</div>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='wideBody'
																	checked={aircraftClassification.wideBody}
																	value='Wide Body'
																	type='checkbox'
																	name='wideBody'
																	className='form-item__field'
																	onChange={(event) => {
																		setAircraftClassification({
																			...aircraftClassification,
																			wideBody: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='wideBody' className='form-item__label'>
																	{t('COMMON.WB')}
																</label>
															</div>
														</div>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='truck'
																	checked={aircraftClassification.truck}
																	value='Truck'
																	type='checkbox'
																	name='truck'
																	className='form-item__field'
																	onChange={(event) => {
																		setAircraftClassification({
																			...aircraftClassification,
																			truck: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='truck' className='form-item__label'>
																	{t('ROUTING_ENQUIRY.TRUCK')}
																</label>
															</div>
														</div>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div
																className='form-item form-item--checkbox'
																data-module='molecules/form-item/FormItem'
															>
																<input
																	id='freighter'
																	checked={aircraftClassification.freighter}
																	value='Freighter'
																	type='checkbox'
																	name='freighter'
																	className='form-item__field'
																	onChange={(event) => {
																		setAircraftClassification({
																			...aircraftClassification,
																			freighter: event.target.checked
																		})
																	}}
																/>
																<label htmlFor='freighter' className='form-item__label'>
																	{t('ROUTING_ENQUIRY.FREIGHTER_FLIGHT')}
																</label>
															</div>
														</div>
													</div>
													<span className='cusom_label'>{t('CHOOSE_FLT.NO_OF_STOPS')}</span>
													{/* <div className='custom_dropdown'>
												<SelectButton
													className='select-button shipping-type'
													value={stops}
													options={stopsList}
													onChange={(e) => {
														if (e.value) {
															setStops(e.target.value)
														}
													}}
												></SelectButton>
											</div> */}
													<div className='filterField_wrap cargo-portal-container form-row stops-radio-wrapper mt-3'>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div className='form-item form-item--radio'>
																<input
																	id='nonStop'
																	type='radio'
																	value='0'
																	name='numberOfStops'
																	className='form-item__field'
																	aria-describedby='nonStop'
																	onChange={handleRadio}
																	checked={stops === '0' ? true : false}
																/>
																<label htmlFor='nonStop' className='form-item__label'>
																	{t('CHOOSE_FLT.NON_STOP')}
																</label>
															</div>
														</div>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div className='form-item form-item--radio'>
																<input
																	id='oneStop'
																	type='radio'
																	value='1'
																	name='numberOfStops'
																	className='form-item__field'
																	aria-describedby='oneStop'
																	onChange={handleRadio}
																	checked={stops === '1' ? true : false}
																/>
																<label htmlFor='oneStop' className='form-item__label'>
																	1 {t('COMMON.STOP')}
																</label>
															</div>
														</div>
														<div className='col-6 col-md-auto mb-4 pr-4'>
															<div className='form-item form-item--radio'>
																<input
																	id='moreStops'
																	type='radio'
																	value='2+'
																	name='numberOfStops'
																	className='form-item__field'
																	aria-describedby='moreStops'
																	onChange={handleRadio}
																	checked={stops === '2+' ? true : false}
																/>
																<label
																	htmlFor='moreStops'
																	className='form-item__label'
																>
																	2+ {t('CHOOSE_FLT.STOPS')}
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='buttons-div'>
												<span className='clearFilter' onClick={(e) => onClear(e)}>
													{t('COMMON.CLR')}
												</span>
												<button
													type='button'
													className='l-btn l-btn--primary l-btn--xs'
													onClick={(e) => onFilter(e)}
												>
													{t('COMMON.FILTER')}
												</button>
												<button
													type='button'
													className='mb-0 l-btn l-btn--secondary l-btn--xs'
													onClick={() => setShowFilterModal(!showFilterModal)}
												>
													{t('COMMON.CANCEL')}
												</button>
											</div>
										</div>
									</div>
								</div>
								: <></>}
						</li>
					</ul>
				</div>
				<div className='flight-details__allotment-wrapper m-0 d-none d-lg-flex'>
					{/* {
					(isUSBound) ? (
						<>
							<div className='l-checkbox'>
								<input
									id={`unknown-shipemnt`}
									type='checkbox'
									checked={isUnknownShipment}
									onChange={(e) => {
										handleUnknownShimentFilter(!isUnknownShipment)
									}}
								/>
								<label className='mb-3' htmlFor={`unknown-shipemnt`}></label>
							</div>
							<span className='flight-details__allotment-description'>
								{t('BOOKING.FILTER_PSNGR_US_OUTBOUND')}
							</span>
						</>
					 ) : (<></>)
				}				 */}

					<div className='l-checkbox'>
						<input
							id={`allotment`}
							type='checkbox'
							checked={isAllotmentsOnly}
							onChange={(e) => {
								setAllotmentsOnly(!isAllotmentsOnly)
								handleAllotmentFilter(!isAllotmentsOnly, e)
							}}
						/>
						<label className='mb-3' htmlFor={`allotment`}></label>
					</div>
					<span className='flight-details__allotment-description'>
						{t('CHOOSE_FLT.FLIGHTS_ALLOTMENT')}
					</span>
					<div className='flight-details__allotment-indicator' />
				</div>
			</div>
		</div>
	)
}

export default FilterOptions