import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useRef, useState } from 'react'
import {
	getCountries,
} from '../../services/dataCaptureService'
import './CustomSelect.scss';
import { convertToLowerCase } from '../../utils/common'
import { getTermsCountries } from '../../services/adminMasterServices';
const CustomeSelect = ({
	selectedCountry,
	setSelectedCountry,

}) => {
	const [text, setText] = useState(null)
	const [filteredCountries, setFilteredCountries] = useState([])
	const [countries, setCountries] = useState(null)
	const [selectedCountryVal, SetSelectedCountry] = useState(null)
	const [allcountries, setAllCountries] = useState([]);
	const countryRef = useRef(null)
	const isFocused = useRef(false)

	useEffect(() => {
		try {
			getTermsCountries()
				.then((response) => {
					if (response) {
						setAllCountries(response)
					} else {
						return null
					}
				})
		} catch (error) {
			console.log(error.message)
			return
		}
	}, [])

	useEffect(() => {
		if (!text) {
			getFilteredCountries({ query: text })
		}
	}, [text])

	useEffect(() => {
		setSelectedCountry(selectedCountryVal)
	}, [selectedCountryVal])

	const handleSelect = (country) => {
		SetSelectedCountry(country)
	}

	const handleOnBlur = async () => {
		setText(null)
	}

	const searchCountry = async (e) => {
		const selectedCountryVal = e.query
		if (!selectedCountryVal || selectedCountryVal.length < 2) {
			setCountries([])
			setFilteredCountries([])
			return
		}

		if (selectedCountryVal && selectedCountryVal.length >= 2) {
			let countries
			try {
				const responseBody = await getCountries(selectedCountryVal)
				countries = responseBody.map((country) => {
					country.countryName = convertToLowerCase(country.countryName)
					return country
				})
			} catch (err) {
				countries = []
			}
			if (isFocused.current) {
				setCountries([...countries])
				setFilteredCountries(getFilteredCountries(countries))
			} else {
				setCountries([])
				setFilteredCountries([])
			}

			return
		}

		if (isFocused.current) {
			setFilteredCountries(getFilteredCountries(countries))
		}
	}
	const getFilteredCountries = (countries) => {
		const countriesByCodeStart =
			countries && countries.length && text && text.length
				? countries.filter((country) => {
					return country.countryCode
						.toLowerCase()
						.startsWith(text.toLowerCase())
				})
				: []
		const countriesByNameStart =
			countries && countries.length && text && text.length
				? countries.filter((country) => {
					return ` ${country.countryName.toLowerCase()}`.startsWith(
						` ${text.toLowerCase()}`
					)
				})
				: []
		const countriesByContains =
			countries && countries.length && text && text.length
				? countries.filter((country) => {
					return (
						country.countryCode.toLowerCase().includes(text.toLowerCase()) ||
						country.countryName.toLowerCase().includes(text.toLowerCase())
					)
				})
				: []
		return [
			...countriesByCodeStart,
			...countriesByNameStart,
			...countriesByContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.countryCode === item.countryCode)
		)
	}

	const itemTemplate = (item) => {
		return (
			<div>
				{`${item.countryCode},`}
				<b>{` ${item.countryName}`}</b>
			</div>
		)
	}

	return (
		<>
			<AutoComplete
				value={
					selectedCountryVal && selectedCountryVal.countryCode
						? `${selectedCountryVal.countryCode}, ${selectedCountryVal.countryName}`
						: text
				}
				ref={countryRef}
				dropdown
				suggestions={filteredCountries}
				completeMethod={searchCountry}
				onChange={(e) => {
					SetSelectedCountry(null)
					setText(e.value)
				}}
				onSelect={(e) => {
					handleSelect(e.value)
				}}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onBlur={(e) => {
					isFocused.current = false
					handleOnBlur()
				}}
				onDropdownClick={() => {
					setText(null)
					const filteredCountries = [...allcountries]
					setFilteredCountries([...filteredCountries])
				}}
				onFocus={(e) => {
					isFocused.current = true
					handleOnBlur()
				}}
				className='form-item__field custom-country-autocomplete-inner-wrapper'
				placeholder='Select country'
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default CustomeSelect
