import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import getCurrentUser from "../../utils/getCurrentUser";
import CustomDropzone from "../CustomDropzone";
import './permanent-booking.scss'
import PermanentBookingList from "./PermanentBookingList";
import { useReactToPrint } from 'react-to-print';
import { connect } from 'react-redux'
import {
    getPermanentBookingFileList,
    getPermanentBookingsForFile
} from '../../services/permanentService'
import {
    getToastSuccess,
    getToastError,
    getToastInfo
} from '../../redux/actions/toastActions';
import LoadingIndicator from "../LoadingIndicator";
import { pushPageData } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../constants'

const PermanentBooking = ({
    getToastSuccess,
    getToastError,
    getToastInfo

}) => {

    const { t, i18n } = useTranslation(['labels', 'errors'])

    const userDetails = getCurrentUser();

    const [displayUploadSection, setDisplayUploadSection] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isListPageOpen, setListPageOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [bookingList, setBookingList] = useState([]);
    const [clearToken, setClearToken] = useState(null);
    const [isEditButtonActive, setEditButtonActive] = useState(false);
    const [isRefreshActive, setRefreshActive] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false)
    const [toasterFlag, setToasterFlag] = useState(true);

    useEffect(() => {
        // handleFileListLoad();
        if (fileName === '') {
            setInitialList();
        }
        // if(fileName){
        //     setTimeout(() => {
        //         handleFileNameSelect(fileName)
        //     },1000)
        // }
        pushPageData(
            pageNames.MULTIPLE_BOOKING,
            PAGE_PRIMARY_CATEGORY.E_SERVICES,
            PAGE_SUB_CATEGORY1.BOOKING,
            BOOKING_PAGE_SUB_CATEGORY2.MULTIPLE_BOOKING,
            'Mass uplaod'
        )
    }, [])

    useEffect(() => {
        if (!(fileName && fileName.length)) return
        setTimeout(() => {
            handleFileNameSelect(fileName)
        }, 500)
        if (clearToken) {
            clearInterval(clearToken)
            //setClearToken(null);
            setEditButtonActive(false)
        }
    }, [fileName])


    const setDropDownFileList = (list) => {
        setFileList(list)
    }

    const setInitialList = async () => {
        const res = await getPermanentBookingFileList()
        setDropDownFileList(res.permanentBookingDetails);
        if (res.permanentBookingDetails?.length > 0) {
            handleFileNameSelect(res.permanentBookingDetails[0].fileName)
            setListPageOpen(true)
            setFileName(res.permanentBookingDetails[0].fileName)
        }
    }

    const setListPage = (e) => {
        e.stopPropagation();
        setListPageOpen(false);
    }

    const setMainPage = (e) => {
        e.stopPropagation();
        setListPageOpen(true);
    }

    const handleFileListLoad = async () => {
        try {
            const res = await getPermanentBookingFileList()
            setDropDownFileList(res.permanentBookingDetails);
        } catch (err) {
            console.log(err)
        }
    }

    const getBookingListData = (fileNameParam) => {
        let counter = 0;
        let token = setInterval(async () => {
            const bookingViewList = await getPermanentBookingsForFile(fileNameParam)
            if (bookingViewList?.bookingList && bookingViewList.bookingList.length > 0) {
                clearInterval(token);
                setLoading(false);
                setBookingList(bookingViewList)
                let statusList = [];
                bookingViewList?.bookingList.map((item) => {
                    statusList.push(item.status);
                })
                if (statusList.includes("Loading")) {
                    setRequest();
                    setEditButtonActive(true);
                    setRefreshActive(true);
                }
                else {
                    setRefreshActive(false);
                }
            }
            else {
                if (counter >= 2) {
                    setLoading(false);
                    clearInterval(token);
                    setBookingList(bookingViewList)
                }
                counter++
            }
        }, 3000)

    }

    const handleFileNameSelect = async (fileNameParam) => {
        setLoading(true)
        try {
            const bookingViewList = await getPermanentBookingsForFile(fileNameParam)
            if (bookingViewList?.bookingList && bookingViewList.bookingList.length === 0) {
                getBookingListData(fileNameParam);
            }
            else {
                setLoading(false)
                setBookingList(bookingViewList)
                let statusList = [];
                bookingViewList?.bookingList.map((item) => {
                    statusList.push(item.status);
                })
                if (statusList.includes("Loading")) {
                    setRequest();
                    setEditButtonActive(true);
                    setRefreshActive(true);
                }
                else {
                    setRefreshActive(false)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }


    //>>>> auto refesh of list in every 5 sec <<<<
    const setRequest = (submitGrpId = null) => {
        let token;
        let reprocessid = submitGrpId;
        let toasterFlagLocal = true;
        token = setInterval(async () => {
            setRefreshActive(true);
            let statusList = [];
            let fileSelected = fileName;
            const bookingViewList = await getPermanentBookingsForFile(fileSelected);
            setBookingList(bookingViewList);
            bookingViewList?.bookingList?.map((item) => {
                statusList.push(item.status);
                if (reprocessid == item.bookingGroupId) {
                    if (item.status == 'Failed' && toasterFlagLocal) {
                        getToastError("Resubmission Failed")
                        toasterFlagLocal = false;
                    }
                    else if (item.status == 'Confirmed' || item.status == 'To be Processed' || item.status == 'Queued') {
                        if (toasterFlagLocal) {
                            getToastSuccess("Resubmission successful")
                            toasterFlagLocal = false;
                        }
                    }
                    // else{
                    //     getToastSuccess("Booking is Success")
                    // }
                }
            })
            if (!statusList.includes("Loading")) {
                clearInterval(token);
                setEditButtonActive(false)
                setRefreshActive(false)
            }
        }, 5000)
        setClearToken(token);
    }


    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        //onBeforeGetContent: () => {setFlag(true)},
        content: () => componentRef.current,
        onAfterPrint: () => { setFlag(false) }

    });

    const handleClose = () => {
        setFlag(true)
        setTimeout(() => {
            handlePrint();
        }, 1000)

    }

    return userDetails ? (
        <div className='container-md container-lg cargo-portal-container'>
            <div className='permanent-booking-wrapper'>
                <div className='col-12 pl-0 pr-0'>
                    {/* <BookingMenu activeItem={4} /> */}
                    {!isListPageOpen ?
                        <React.Fragment>
                            {/* <h2>Mass Upload</h2>
                    <p>&nbsp;</p> */}
                            <div className='row m1'>
                                <div>
                                    <p>
                                        <div className='description'>

                                            <p className='paragraph-1'><b>{t('PERMANENT_BOOKING.TEXT_YOU_ARE')}</b></p>

                                            <p className='paragraph-2'>{t('PERMANENT_BOOKING.TEXT_DWNLOAD')}</p>


                                        </div>

                                        <ul className='list'>
                                            <li>{t('PERMANENT_BOOKING.TEXT_BOOK_REQ')}
                                            </li>
                                            <li>
                                                {t('PERMANENT_BOOKING.TEXT_ALLOT_ID')}
                                            </li>
                                        </ul>





                                    </p>
                                    <div className='row mb-0'>
                                        <div
                                            className='form-group mt-2'
                                        >
                                            <a className='excel_Upload_button ' tabIndex={-1}



                                                href={process.env.PUBLIC_URL + '/Booking_upload_template_v2.xlsx'}
                                                role='button'
                                                target="_blank">
                                                <img src={require('../../assets/images/download.png').default} />
                                                <div className='downld_temp'>{t('PERMANENT_BOOKING.DOWNLOAD_TMP')}</div>
                                            </a>


                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-0'>
                                    <CustomDropzone setDisplayUploadSection={setDisplayUploadSection}
                                        setDropDownFileList={setDropDownFileList}
                                        fileList={fileList}
                                        setListPageOpen={setListPageOpen}
                                        setFileName={setFileName}
                                        setFileList={setFileList}
                                        fileName={fileName}
                                    // handleFileNameSelect={handleFileNameSelect}
                                    />
                                </div>
                            </div>
                            {(fileName) ? <div className='col-2 btn-back '>
                                <button
                                    type='submit'
                                    value='Submit'
                                    className='button button--secondary'
                                    onClick={(e) => {
                                        setMainPage(e)
                                        setInitialList('')
                                    }
                                    }
                                >
                                    {t('COMMON.BACK_BUTTON')}
                                </button>
                            </div> : <></>}
                        </React.Fragment>
                        : <></>}
                    {isLoading ?
                        <LoadingIndicator /> : ''
                    }
                    {isListPageOpen ?
                        <React.Fragment>
                            <PermanentBookingList
                                flag={flag}
                                setDropDownFileList={setDropDownFileList}
                                fileList={fileList}
                                setListPageOpen={setListPageOpen}
                                fileName={fileName}
                                setFileName={setFileName}
                                bookingList={bookingList}
                                setBookingList={setBookingList}
                                // handleFileNameSelect={handleFileNameSelect}
                                setRequest={setRequest}
                                setEditButtonActive={setEditButtonActive}
                                isEditButtonActive={isEditButtonActive}
                                ref={componentRef}
                                clearToken={clearToken}
                                isRefreshActive={isRefreshActive}
                                toasterFlag={toasterFlag}
                                setToasterFlag={setToasterFlag}
                            />
                            <div className="row button-submit-row">
                                <div className='col-2 btn-print btn-reprocess-cancel'>
                                    <button
                                        type='submit'
                                        value='Submit'
                                        className='button button--secondary'
                                        onClick={handleClose}
                                    >
                                        {t('COMMON.PRINT')}
                                    </button>
                                </div>
                                <div className='col-3 btn-reprocess'>
                                    <button
                                        type='button'
                                        className='button button--secondary'
                                        onClick={(e) => {
                                            clearInterval(clearToken)
                                            setListPage(e)
                                            setBookingList([]);
                                        }
                                        }
                                    >
                                        {t('PERMANENT_BOOKING.NEW_UPLOAD')}
                                    </button>
                                </div>
                            </div>

                        </React.Fragment>
                        : <></>}

                </div>
            </div>
        </div>) : <></>
}
const mapStateToProps = ({ loader, toast }) => ({
    //	principal: auth.principal,
    //	isLoading: loader.isLoading,
    toastDetails: toast.toastDetails,
})
const mapDispatchToProps = (dispatch) => ({
    getToastSuccess: (message) => {
        dispatch(getToastSuccess(message))
    },
    getToastError: (message) => {
        dispatch(getToastError(message))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(PermanentBooking)