import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import rootReducer from './reducers'
import rootSaga from './sagas'
//import { refreshTokenListener } from '../utils/HttpRequestUtils'

function configureStore() {
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
	const sagaMiddleware = createSagaMiddleware()
	const middleWares = [reduxImmutableStateInvariant(), sagaMiddleware]
	const store = createStore(
		rootReducer(),
		composeEnhancers(applyMiddleware(...middleWares))
	)
	sagaMiddleware.run(rootSaga)
	return store
}

const store = configureStore()

// store.subscribe(refreshTokenListener)

export default configureStore()
