import { Carousel } from 'primereact/carousel'
import React from 'react'
import './index.scss'
const NewsCard = ({ articles , alerts }) => {
	const responsiveOptions = [
		{
			breakpoint: '1024px',
			numVisible: 3,
			numScroll: 3,
		},
		{
			breakpoint: '992px',
			numVisible: 1,
			numScroll: 1,
		}
	]
    const redirectToReadMore = (evnt, article, alerts) => {
		evnt.stopPropagation()
		article && window.open(article.pageUrl, '_blank')
	}
	
    const articleTemplate = (article) => {
		return (
			<div
				className='card-content'
				onClick={(e) => redirectToReadMore(e, article)}
			>
				{article.imageUrl && (
					<div className='card-content__img' style={{ backgroundImage: `url(${article.imageUrl})`}}>
						<img src={article.imageUrl} />
					</div>
				)}
				<div className- ='card-content__text'>
				{   article.heading  && !(article.heading.includes('Alert'))
&&(
            <div className='card-content__heading mb-3'>
            <div className='section-sub-heading mb-0'>{article.heading}</div>
            </div>

)}

               {   article.heading.includes('Alert') ? (
         <div className='card-content__alertheading mb-3'>
        <div className='section-sub-alertheading mb-0'>{article.heading}</div>
        </div>
):(null)}
			{article.description && (
						<div className='card-content__description mb-3'>
							<div className='news-description'>{article.description}</div>
						</div>
					)}
					<div className='card-content__link'>
						<div
							onClick={(e) => redirectToReadMore(e, article)}
							className='link'
						>
							Read more
							<img
								src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDcgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQ0Njc4IDEwLjk0Nkw2LjMzNTY3IDUuODQ1N0wxLjQ0Njc4IDAuNzQ0MTQxIiBzdHJva2U9IiMwMDVGOTYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K'
								alt=''
								className='icon'
							></img>
						</div>
					</div>
				</div>
			</div>
			)
	}
    return (
		<div className='carousel-wrapper'>
			<div className='row'>
				<div className='col-12 pl-3 pr-3'>
					<Carousel
						value={articles}
						itemTemplate={articleTemplate}
						numVisible={3}
						numScroll={1}
						responsiveOptions={responsiveOptions}
						circular
						autoplayInterval={4000}
						/>
				</div>
			</div>
		</div>
		)
}
export default NewsCard
