import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Alert } from '../../common/Alert'
import downArrow from '../../assets/images/down-arrow.png'
import upArrow from '../../assets/images/up-arrow.png'
import editIcon from '../../assets/images/edit.png'
import {
	BOOKING_STATUSES,
	capitalize,
	DIMENSION_UNIT,
	DISPLAY_SERVICES,
	getDecimalFormattedNumber,
	getDensityUnit,
	getFormattedNumber,
	getRoundedValue,
	getVolumeUnit,
	getWeightUnit,
	NOTIFICATION_MESSAGE_TYPE,
	SERVICES,
	WEIGHT_UNIT,
} from '../../constants'
import { FOUR_DECIMAL_PLACES } from '../../constants/regex'
import { FORCE_QUEUE_SCC } from '../../constants/sccTypes'
import {
	default as SessionKeys,
	default as sessionKeys,
} from '../../constants/sessionKeys'
import {
	clearUpdateBooking,
	updateBooking,
} from '../../redux/actions/updateBookingActions'
import {
	clearValidateBooking,
	validateBooking,
} from '../../redux/actions/validateBookingActions'
import { loadListFlight, clearListFlight } from '../../redux/actions/listFlightActions'
import { getTermsConditionsByLocationResponse } from '../../services/adminMasterServices'
import { allowNumberOnly, calculateWeightFromKg, calculateWeightToKg, round } from '../../utils/common'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
} from '../../utils/StorageUtils'
import ShareEmailDialog from '../BookingConfirmation/ShareEmailDialog'
import BookingMenu from '../BookingMenu'
import LoadingIndicator from '../LoadingIndicator'
import { removeDuplicates } from '../SearchPanel/templateUtils'
import RouteDetails from '../Shared/RouteDetails'
import './view-quote.scss'
import {
	getIsOAL,
	retriveQuoteDetails,
	triggerSaveQuote,
} from './ViewQuoteUtils'
import { Trans, useTranslation } from 'react-i18next'
import { addError, pushPageData } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../constants'
import getCurrentUser from '../../utils/getCurrentUser'
import { sendEmailForAssistance } from '../../utils/ExceptionHandlingUtil'
import { getQuoteResponse } from '../../services/quoteServices'
import { SMALL_SCC } from '../../constants/sccTypes'

const ViewQuote = ({
	updateBookingRequest,
	updateBookingResponse,
	updateBookingError,
	clearUpdateBookingResponse,
	validateBookingRequest,
	validateBookingResponse,
	validateBookingError,
	clearValidateBookingResponse,
	resultSet,
	triggerListFlight,
	triggerclearListFlight,
}) => {
	// let { quotationId } = ''
	let { quotationName } = useParams()
	const [awbNumber, setAWBNumber] = useState('')
	const [showValidation, setshowValidation] = useState(false)
	const [pickAwb, setPickAwb] = useState(true)
	const [showSubCharges, setShowSubCharges] = useState(false)
	const [searchFormData, setSeachFormData] = useState(null)
	const [selectedFlight, setSelectedFlight] = useState(null)
	const [bookingDetails, setBookingDetails] = useState(null)
	const [isCargoExpanded, setCargoExpanded] = useState(false)
	const [awbNo, setAWBNo] = useState('')
	const [locationCode, setLocationCode] = useState('')
	const [fileName, setFileName] = useState('')
	//share quote
	const [displayBasic, setDisplayBasic] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [status, setStatus] = useState(false)
	const [quoteExpiryTime, setQuoteExpiryTime] = useState(null)
	const [quotationDetails, setQuotationDetails] = useState(null)
	const [quoteName, setQuoteName] = useState(null)
	const [validateResponse, setValidateResponse] = useState(null)
	const [showRateDetails, setShowRateDetails] = useState(true)
	//spot rate
	const [pickAllinRate, setPickAllinRate] = useState(false)
	const [spotRate, setSpotRate] = useState('')
	const [chargeableWeight, setChargeableWeight] = useState(null)
	const [error, setError] = useState(null)
	const history = useHistory()
	const [isModifyBooking, setIsModifyBooking] = useState(null)
	const [isDetailsUpdate, setIsDetailsUpdate] = useState(null)
	const selectedBookingDetails = getFromSessionStorage('bookingDetails')

	const [warningMessages, setWarningMessages] = useState(null)
	const [infoMessages, setInfoMessages] = useState(null)
	const [disable, setDisable] = useState(false)
	const [isError, setIsError] = useState(false)

	const dgCheckNormal = getFromSessionStorage('ifDg')
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const userDetails = getCurrentUser()

	// Enable and awb no field

	const [awbValueEnable, setAwbValueEnable] = useState(true)

	// OAL Value

	const [oalValue, setOalValue] = useState('607')


	const dgSccCode = (JSON.parse(getFromSessionStorage('dgsccCode')))
	useEffect(() => {
		if (locationCode != '') {
			locationCodeCallBack(locationCode)
		} else return
	}, [locationCode])

	const [quoteFlightResult, setQuoteFlightResult] = useState(null)

	useEffect(() => {
		if (quoteFlightResult && selectedFlight) {
			const response = quoteFlightResult
			if (response?.flightList?.services?.DEFAULT?.flightItineraryList && response?.flightList?.services?.DEFAULT?.flightItineraryList.length > 0) {
				setSelectedFlight(response?.flightList?.services?.DEFAULT?.flightItineraryList[0])
				let encoded = getBase64Encoded(response?.flightList?.services?.DEFAULT?.flightItineraryList[0])
				setToSessionStorage('flightSelected', encoded)
			} else if (response?.error) {
				if (response?.error?.description) {
					const encodedSearchObjectQuote = getFromSessionStorage(
						SessionKeys.SEARCH_FORM_DATA_BOOKING
					)
					const searchData = getBase64Decoded(encodedSearchObjectQuote)
					searchData.flightNumber = null
					searchData.flightCarrierCode = null
					setToSessionStorage(
						SessionKeys.SEARCH_FORM_DATA_BOOKING,
						getBase64Encoded(searchData)
					)
					triggerclearListFlight()
					history.push({
						pathname: '/booking/flightlist',
						state: {
							validationError: response?.error?.description,
						},
					})
				}
			}
		}
	}, [quoteFlightResult])

	const locationCodeCallBack = async () => {
		try {
			await getTermsConditionsByLocationResponse(locationCode).then(
				(response) => {
					setFileName(response?.termsAndConditions?.fileName)
				}
			)
		} catch (error) {
			console.log(error.message)
			return
		}
	}

	const getFlightSegment = (bookingFlightDetails) => {
		let flightSegmentList = []
		bookingFlightDetails.map((segment) => {
			const flightSegment = {
				aircraftType: segment.aircraftType,
				combinationId: `${segment.segmentOrigin}-${segment.segmentDestination}`,
				arrivalDateTime: segment.segmentArrivalDate,
				departureDateTime: segment.segmentDepartureDate,
				flightCarrierCode: segment.carrierCode,
				flightDate: segment.flightDate,
				flightNumber: segment.flightNumber,
				flightType: segment.flightType,
				route: `${segment.segmentOrigin}-${segment.segmentDestination}`,
				segmentDestination: segment.segmentDestination,
				segmentOrigin: segment.segmentOrigin,
			}
			flightSegmentList.push(flightSegment)
			return flightSegment
		})
		return flightSegmentList
	}

	const getRateDetails = (rateDetails, formData) => {
		let rateDetailsList = []
		rateDetails.map((rate) => {
			const rateDetail = {
				currency: rate.currencyCode,
				totalFreightCharge: rate.freightCharge,
				totalFreightRate: rate.rate,
				otherChargeDetails: rate.otherChargeDetails,
				totalSurcharge: rate.totalOtherCharge,
				totalCharge: rate.freightCharge,
				totalRate: rate.rate,
				totalAdditionalCharges: null,
				grandTotal: rate.totalCharge,
				product:
					selectedFlight &&
						selectedFlight.ratingDetails &&
						selectedFlight.ratingDetails.length
						? selectedFlight.ratingDetails[0].product
						: formData &&
							formData.shippingItemCode &&
							formData.shippingItemCode.product
							? formData.shippingItemCode.serviceProduct ||
							formData.shippingItemCode.product
							: '--',
			}
			rateDetailsList.push(rateDetail)
			return rateDetailsList
		})
		//console.log('rateDetailsList',rateDetailsList)
		return rateDetailsList
	}

	const clearStorage = () => {
		clearItemFromSessionStorage('awbNumber')
		clearItemFromSessionStorage('flightSelected')
		clearItemFromSessionStorage('bookingDetails')
		clearItemFromSessionStorage('searchObject')
		clearItemFromSessionStorage('spotRate')
		clearItemFromSessionStorage('allInRate')
		clearItemFromSessionStorage('additionalFormData')
		//	clearItemFromSessionStorage('upsellOffered')
	}

	const saveQuotationDetails = (flightInfo) => {
		let quotationDetails
		if (flightInfo && flightInfo.ratingDetails) {
			quotationDetails = {
				quotationId: flightInfo.ratingDetails[0].quotationID
					? flightInfo.ratingDetails[0].quotationID
					: null,
				quotationGeneratedDateTime: flightInfo.ratingDetails[0]
					.quotationGeneratedDateTime
					? flightInfo.ratingDetails[0].quotationGeneratedDateTime
					: null,
				quotationType: 'dynamic'
			}
			if (quotationDetails && quotationDetails.quotationId !== null) {
				setToSessionStorage(
					'quotationDetails',
					getBase64Encoded(quotationDetails)
				)
			}
		}
	}

	useEffect(() => {
		const encodedSearchObject = getFromSessionStorage(
			SessionKeys.SEARCH_FORM_DATA_BOOKING
		)
		const encodedFlightSelected = getFromSessionStorage(
			sessionKeys.SELECTED_FLIGHT_BOOKING
		)
		if (
			(!encodedSearchObject || !encodedFlightSelected) &&
			!selectedBookingDetails &&
			!quotationName
		) {
			return history.push('/booking/flightlist')
		}

		if (quotationName) {

			clearStorage()
				; (async () => {
					try {
						let data = await retriveQuoteDetails(
							quotationName,
							setAlertMessage,
							setStatus,
							setIsLoading,
							setSeachFormData,
							setSelectedFlight
						)
						if (data.errorDescription) {
							history.push({
								pathname: '/booking/search',
								state: {
									message: 'Specified quote ID not found',
									type: NOTIFICATION_MESSAGE_TYPE.ERROR,
								},
							})
							addError('FIND_QUOTE_ERROR', `${data.errorDescription}`)
							pushPageData(
								pageNames.BOOKING_SUMMARY,
								PAGE_PRIMARY_CATEGORY.E_SERVICES,
								PAGE_SUB_CATEGORY1.BOOKING,
								BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING)
						} else if (data.length) {
							const encodedSearchObjectQuote = getFromSessionStorage(
								SessionKeys.SEARCH_FORM_DATA_BOOKING
							)
							const encodedFlightSelectedQuote =
								getFromSessionStorage('flightSelected')
							if (!encodedSearchObjectQuote) {
								return history.push('/booking/flightlist')
							}

							const formDataQuote = getBase64Decoded(encodedSearchObjectQuote)
							const flightSelectedQuote = getBase64Decoded(
								encodedFlightSelectedQuote
							)
							setLocationCode(formDataQuote?.origin?.countryCode)
							//setSeachFormData(formDataQuote)
							if (data[0].expiryTime) {
								setQuoteExpiryTime(data[0].expiryTime)
							}

							if (flightSelectedQuote && flightSelectedQuote.isForceQueued) {
								formDataQuote.sccCodes =
									formDataQuote.sccCodes && formDataQuote.sccCodes.length
										? removeDuplicates([
											...formDataQuote.sccCodes,
											FORCE_QUEUE_SCC,
										])
										: [FORCE_QUEUE_SCC]
								setShowRateDetails(false)
								setToSessionStorage(
									SessionKeys.SEARCH_FORM_DATA_BOOKING,
									getBase64Encoded(formDataQuote)
								)
							}
							if (flightSelectedQuote && flightSelectedQuote.departureDateTime) {
								formDataQuote.shippingDate = flightSelectedQuote.departureDateTime ? moment(flightSelectedQuote.departureDateTime).format('DD-MMM-YYYY') : formDataQuote.shippingDate
								flightSelectedQuote.shippingDate = flightSelectedQuote.departureDateTime ? moment(flightSelectedQuote.departureDateTime).format('DD-MMM-YYYY') : formDataQuote.shippingDate
								if (flightSelectedQuote.flightSegmentList && flightSelectedQuote.flightSegmentList.length > 0) {
									formDataQuote.flightNumber = flightSelectedQuote.flightSegmentList[0].flightNumber ? flightSelectedQuote.flightSegmentList[0].flightNumber : null
									formDataQuote.flightCarrierCode = flightSelectedQuote.flightSegmentList[0].flightCarrierCode ? flightSelectedQuote.flightSegmentList[0].flightCarrierCode : null
								}

								setToSessionStorage(
									SessionKeys.SEARCH_FORM_DATA_BOOKING,
									getBase64Encoded(formDataQuote)
								)
							}
							if (
								flightSelectedQuote &&
								(flightSelectedQuote.isForceQueued ||
									flightSelectedQuote.overHangShipment ||
									flightSelectedQuote.bigShipment)
							) {
								setShowRateDetails(false)
							}
							if (
								flightSelectedQuote &&
								flightSelectedQuote.ratingDetails &&
								flightSelectedQuote.ratingDetails.length &&
								!Number(flightSelectedQuote.ratingDetails[0].grandTotal)
							) {
								setShowRateDetails(false)
							}
							setSeachFormData(formDataQuote)
							if (flightSelectedQuote) {
								setSelectedFlight(flightSelectedQuote)
								if (isModifyBooking ||
									!getIsOAL(flightSelectedQuote)
								) {
									//validateBookingRequest(awbNo, formDataQuote, flightSelectedQuote)
									setIsLoading(true)
									//triggerListFlight(formDataQuote)
									const flightResponse = await getQuoteResponse(formDataQuote)
									setQuoteFlightResult(flightResponse)
									setTimeout(() => {
										setIsLoading(false)
									}, 300);
								}
							}
							pushPageData(
								pageNames.BOOKING_SUMMARY,
								PAGE_PRIMARY_CATEGORY.E_SERVICES,
								PAGE_SUB_CATEGORY1.BOOKING,
								BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING)
						}

					} catch (error) {
						console.log(error.message)
						addError('FIND_QUOTE_ERROR', error)
						pushPageData(
							pageNames.BOOKING_SUMMARY,
							PAGE_PRIMARY_CATEGORY.E_SERVICES,
							PAGE_SUB_CATEGORY1.BOOKING,
							BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING)
					}
				})()
		} else {
			const formData = getBase64Decoded(encodedSearchObject)
			let flightSelected
			if (encodedFlightSelected) {
				flightSelected = getBase64Decoded(encodedFlightSelected)
			}
			setLocationCode(formData?.origin?.countryCode)
			const { isUnknownShipment } = formData

			let sccCodes = formData?.sccCodes ? formData.sccCodes : []
			if (isUnknownShipment) {
				if (!sccCodes.find(e => e.scc === 'UKN')) {
					sccCodes = [
						...sccCodes,
						{ scc: 'UKN' }
					]
				}
			} else {
				sccCodes = sccCodes.filter(e => e.scc !== 'UKN')
			}

			formData.sccCodes = sccCodes

			if (isUnknownShipment) {
				setToSessionStorage(
					SessionKeys.SEARCH_FORM_DATA_BOOKING,
					getBase64Encoded(formData)
				)
			}
			if (
				(flightSelected && flightSelected.isForceQueued) ||
				formData.adjustedWeight
			) {
				formData.sccCodes =
					formData.sccCodes && formData.sccCodes.length
						? removeDuplicates([...formData.sccCodes, FORCE_QUEUE_SCC])
						: [FORCE_QUEUE_SCC]
				setShowRateDetails(false)
				setToSessionStorage(
					SessionKeys.SEARCH_FORM_DATA_BOOKING,
					getBase64Encoded(formData)
				)
			}
			if (
				flightSelected &&
				(flightSelected.isForceQueued ||
					flightSelected.overHangShipment ||
					flightSelected.bigShipment)
			) {
				setShowRateDetails(false)
			}

			if (
				flightSelected &&
				flightSelected.ratingDetails &&
				flightSelected.ratingDetails.length &&
				!Number(flightSelected.ratingDetails[0].grandTotal)
			) {
				setShowRateDetails(false)
			}
			if (
				flightSelected &&
				flightSelected.warningMessages &&
				flightSelected.warningMessages.length
			) {
				const newWarningMessages = flightSelected.warningMessages.join(', ')
				setWarningMessages(newWarningMessages)
				if (newWarningMessages === "Minimum Handling / Connection Time Fails") {
					setDisable(true)
					setIsError(true)
					setWarningMessages("Minimum Handling / Connection Time Fails. Please choose another flight.")
				}
				if (newWarningMessages === "Maximum Handling / Connection Time Fails") {
					setDisable(true)
					setIsError(true)
					setWarningMessages("Maximum Handling / Connection Time Fails. Please choose another flight.")
				}
			}
			if (
				flightSelected &&
				flightSelected.infoMessages &&
				flightSelected.infoMessages.length
			) {
				const newInfoMessages = flightSelected.infoMessages.join(', ')
				setInfoMessages(newInfoMessages)
			}

			setSeachFormData(formData)
			if (flightSelected) {
				saveQuotationDetails(flightSelected)
				setSelectedFlight(flightSelected)
				if (isModifyBooking ||
					!getIsOAL(flightSelected)
				) {
					const encodedSearchObject = getFromSessionStorage(
						SessionKeys.SEARCH_FORM_DATA_BOOKING
					)
					//console.log('encodedSearchObject',encodedSearchObject)
					//validateBookingRequest(awbNo, formData, flightSelected)
				}
			}

			if (selectedBookingDetails) {
				const bookingDetails = getBase64Decoded(selectedBookingDetails)
				setBookingDetails(bookingDetails)
				//setToSessionStorage('bookingDetails',bookingDetails)
				//console.log('booking details',bookingDetails)

				const bookingFlightDetails = bookingDetails.bookingFlightDetails
				const rateDetails = bookingDetails.rateDetails
				const shipmentDetails = bookingDetails.shipmentDetails
				if (!flightSelected) {
					const flight = {
						route: `${bookingFlightDetails[0].segmentOrigin}-${bookingFlightDetails[bookingFlightDetails.length - 1]
							.segmentDestination
							}`,
						origin: bookingFlightDetails[0].segmentOrigin,
						destination:
							bookingFlightDetails[bookingFlightDetails.length - 1]
								.segmentDestination,
						arrivalDateTime:
							bookingFlightDetails[bookingFlightDetails.length - 1]
								.segmentArrivalDate,
						departureDateTime: bookingFlightDetails[0].segmentDepartureDate,
						lastAcceptanceTime: null,
						earliestDeliveryTime: null,
						dropOffAddress: shipmentDetails.dropOffAddress
							? shipmentDetails.dropOffAddress
							: {},
						pickUpAddress: shipmentDetails.pickUpAddress
							? shipmentDetails.pickUpAddress
							: {},
						flightSegmentList: getFlightSegment(bookingFlightDetails),
						ratingDetails: getRateDetails(rateDetails, formData),
					}
					setSelectedFlight(flight)
				}
				setIsModifyBooking(formData.isModifyBooking)
				setAWBNo(bookingDetails.awbNo)
				if (
					formData.bigShipment ||
					formData.overHangShipment ||
					formData.isForceQueued
				) {
					setShowRateDetails(false)
				}
			} else {
				setIsModifyBooking(formData.isModifyBooking)
				setAWBNo(formData.awbNo)
			}
			const spotRateSelected = getFromSessionStorage('spotRate')
			if (spotRateSelected != null) {
				setSpotRate(spotRateSelected)
			}
			pushPageData(
				pageNames.BOOKING_SUMMARY,
				PAGE_PRIMARY_CATEGORY.E_SERVICES,
				PAGE_SUB_CATEGORY1.BOOKING,
				BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING)
		}
	}
		, [])

	useEffect(() => {
		if (history.location.state && history.location.state.isModifyBooking) {
			setIsDetailsUpdate(history.location.state.isModifyBooking)
		}
	}, [history])

	useEffect(() => {
		if (validateBookingResponse) {
			const response = validateBookingResponse
			if (response.bookingDetails) {
				setValidateResponse(response.bookingDetails)
				const flight = selectedFlight

				if (response.bookingDetails.rateDetails && flight) {
					const rateDetails = getRateDetails(
						response.bookingDetails.rateDetails,
						searchFormData
					)
					if (rateDetails) {
						flight.ratingDetails = rateDetails
					}
				}

				if (
					response.bookingDetails.shipmentDetails &&
					response.bookingDetails.shipmentDetails.dropOffAddress &&
					flight
				) {
					flight.dropOffAddress =
						response.bookingDetails.shipmentDetails.dropOffAddress
				}

				if (
					response.bookingDetails.shipmentDetails &&
					response.bookingDetails.shipmentDetails.pickUpAddress &&
					flight
				) {
					flight.pickUpAddress =
						response.bookingDetails.shipmentDetails.pickUpAddress
				}

				const bookingCommodityDetails = response.bookingDetails.bookingCommodityDetails
				if (bookingCommodityDetails && bookingCommodityDetails.length) {
					if (
						bookingCommodityDetails[0].chargeableWeight &&
						Number(bookingCommodityDetails[0].chargeableWeight && !searchFormData.adjustedWeight)
					) {
						setSeachFormData((prevState) => ({
							...prevState,
							chargeableWeight: Number(
								bookingCommodityDetails[0].chargeableWeight
							).toFixed(2),
						}))
						setChargeableWeight(
							Number(bookingCommodityDetails[0].chargeableWeight)
						)
					}
				}
				setSelectedFlight(flight)
				let encoded = getBase64Encoded(flight)
				setToSessionStorage('flightSelected', encoded)
				const encodedSearchObject = getFromSessionStorage(
					SessionKeys.SEARCH_FORM_DATA_BOOKING
				)
				//console.log('encodedSearchObject',encodedSearchObject)
			}

			clearValidateBookingResponse()
		}
	}, [validateBookingResponse])

	useEffect(() => {
		if (validateBookingError) {
			const error = validateBookingError
			addError('VALIDATE_BOOKING_ERROR', error)
			if (error.description) {
				clearValidateBookingResponse()
				history.push({
					pathname: '/booking/flightlist',
					state: {
						validationError: error.description,
					},
				})
			}
		}
	}, [validateBookingError])

	useEffect(() => {
		if (updateBookingResponse) {
			const bookingResponseDetails =
				updateBookingResponse.bookingResponseDetails
			let awbNo = `${bookingResponseDetails.shipmentPrefix}-${bookingResponseDetails.masterDocumentNumber}`
			const ubrNumber = `${bookingResponseDetails.ubrNumber}`
			let awbNumberPath = `${bookingResponseDetails.shipmentPrefix}${bookingResponseDetails.masterDocumentNumber}`
			clearUpdateBookingResponse()
			clearStorage()
			history.push({
				pathname: `/manage-booking/${awbNumberPath}/${ubrNumber}`,
				state: {
					modifyBooking: {
						status: true,
						bookingStatus:
							t(BOOKING_STATUSES[bookingResponseDetails.bookingStatus]),
						awbNo: awbNo,
					},
				},
			})

			handleUpdateBooking(awbNo, updateBookingResponse)
		}
	}, [updateBookingResponse])

	useEffect(() => {
		if (pickAwb && awbNumber != '') {
			setshowValidation(true)
		} else if (!pickAwb && awbNumber === '') {
			setshowValidation(true)
		} else {
			setshowValidation(false)
		}
	}, [pickAwb])

	useEffect(() => {
		if (updateBookingError) {
			const errorDesc = updateBookingError.description
			addError('UPATE_BOOKING_ERROR', errorDesc)
			clearUpdateBookingResponse()
			clearStorage()
			history.push({
				pathname: '/manage-booking/list',
				state: {
					modifyBooking: {
						status: false,
						awbNo: awbNo,
						message: errorDesc,
					},
				},
			})
		}
	}, [updateBookingError])

	useEffect(() => {
		if (selectedFlight) {
			if (!selectedFlight.ratingDetails ||
				!selectedFlight.ratingDetails.length ||
				!Number(selectedFlight.ratingDetails[0]?.totalFreightCharge) ||
				!Number(selectedFlight.ratingDetails[0].grandTotal)) {
				setShowRateDetails(false)
			}
			const encodedSearchObjectQuote = getFromSessionStorage(
				SessionKeys.SEARCH_FORM_DATA_BOOKING
			)
			if (encodedSearchObjectQuote) {
				const searchData = getBase64Decoded(encodedSearchObjectQuote)
				let sccCodes = searchData && searchData.sccCodes ? searchData.sccCodes : []

				const hasSML = sccCodes.filter((sccCode) => sccCode.scc === SMALL_SCC.scc).length > 0
				if (selectedFlight.smallShipment) {
					if (!hasSML) {
						sccCodes.push({
							scc: SMALL_SCC.scc
						})
					}
					searchData.sccCodes = sccCodes

					setSeachFormData((prevState) => ({
						...prevState,
						sccCodes: sccCodes,
					}))
					setToSessionStorage(
						SessionKeys.SEARCH_FORM_DATA_BOOKING,
						getBase64Encoded(searchData)
					)
				} else if (hasSML) {
					sccCodes = sccCodes.filter((sccCode) => {
						return sccCode.scc !== SMALL_SCC.scc;
					})
					searchData.sccCodes = sccCodes

					setSeachFormData((prevState) => ({
						...prevState,
						sccCodes: sccCodes,
					}))
					setToSessionStorage(
						SessionKeys.SEARCH_FORM_DATA_BOOKING,
						getBase64Encoded(searchData)
					)
				}
			}

		}
	}, [selectedFlight])

	useEffect(() => {
		if (localStorage.getItem("Error") === "True") {
			setAlertMessage("AWB stock not available. Please contact our local sales team.")
			localStorage.removeItem("Error")
		}
	}, [])

	if (!searchFormData || !selectedFlight) {
		return null
	}

	const routes =
		selectedFlight && selectedFlight.flightSegmentList
			? selectedFlight.flightSegmentList.length - 1
			: 0

	const getFormatedTime = (value) => {
		let formattedTime = ''
		if (value) {
			let tempArray = value.split(':')
			formattedTime =
				parseInt(tempArray[0], 10) +
				'Hrs ' +
				(tempArray[1] && tempArray[1] != '00' ? tempArray[1] + ' Min' : '')
		}
		return formattedTime
	}

	const handleBookNow = () => {

		if (!showValidation && validateSpotRate(spotRate)) {
			let encodedAwbNumber = getBase64Encoded(awbNumber)
			setToSessionStorage('awbNumber', encodedAwbNumber)
			setToSessionStorage('spotRate', spotRate)
			setToSessionStorage('awbPrefix', oalValue)
			setToSessionStorage('allInRate', pickAllinRate === true ? 'Y' : 'N')
			history.push('/booking/confirmation')
		}
	}

	const handleSaveQuote = async () => {
		const data = await triggerSaveQuote(
			searchFormData,
			selectedFlight,
			setIsLoading,
			setAlertMessage,
			setStatus,
			quotationDetails
		)
		if (data && data.quoteDetails) {
			setQuoteName(data.quoteDetails.quoteName)
			setQuoteExpiryTime(data.quoteDetails.expiryTime)
			setDisplayBasic(true)
		} else {
			setDisplayBasic(false)
		}
	}

	const handleAlertClose = () => {
		setAlertMessage('')
		setStatus(false)
		setWarningMessages(null)
		setInfoMessages(null)
	}

	const setUpdateBooking = () => {
		let awbNumber = awbNo.replace('-', '').trim()
		const spotRate = getFromSessionStorage('spotRate')
		const allInRate = getFromSessionStorage('allInRate')
		let spotRatePayload = null
		if (spotRate != null) {
			spotRatePayload = {
				spotRate: spotRate,
				allInRate: allInRate,
				isSpotRateRequired: 'Y',
			}
		}
		updateBookingRequest(
			awbNumber,
			searchFormData,
			selectedFlight,
			spotRatePayload
		)

		//handleUpdateBooking(awbNo)

	}
	const hasAttachmentForException = true

	const handleUpdateBooking = async (awbNo, updateBookingResponse) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)
		const updateCategory = (dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true') ? "dgBookingUpdate" :
			(searchFormData?.shippingItemCode?.description === 'LIVE DOGS' || searchFormData?.shippingItemCode?.description === 'LIVE CATS') ? "aviBookingUpdate" : "booking_update"
		if ((searchFormData?.shippingItemCode?.code === "DOG" ||
			searchFormData?.shippingItemCode?.code === "CAT") ||
			(dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true')) {
			const response = await sendEmailForAssistance(
				updateCategory,
				null,
				attributes,
				replacements,
				selectedFlight,
				searchFormData,
				// saveBookingResponse,
				updateBookingResponse,
				awbNo,
				hasAttachmentForException,
			)
		}
		else {
			return null
		}
	}

	// const getEmailAttributesConfirmation = (awbNo) => {
	// 	const attributes = [
	// 		{
	// 			key: 'awbNo',
	// 			value: awbNo,
	// 		},
	// 		{
	// 			key: 'agentName',
	// 			value: userDetails ? userDetails.companyName : null,
	// 		},
	// 		{
	// 			key: 'scc',
	// 			value: searchFormData && searchFormData.sccCodes
	// 				? searchFormData.sccCodes.map((e) => e.scc).toString()
	// 				: '',
	// 		},
	// 		{
	// 			key: 'shippingItem',
	// 			value:
	// 				searchFormData && searchFormData.shippingItemCode
	// 					? `${searchFormData.shippingItemCode.description}-${searchFormData.shippingItemCode.product}`
	// 					: null,
	// 		},
	// 	]
	// 	return attributes
	// }



	// const sendConfirmationEmailForAssistance = async (
	// 	attributes,
	// 	selectedFlight,
	// 	searchFormData,
	// 	awbNo
	// ) => {
	// 	const request = await getRequest(
	// 		attributes,
	// 		selectedFlight,
	// 		searchFormData,
	// 		awbNo
	// 	)
	// 	if (request) {
	// 		const response = await sendEmail(request)
	// 			.then((response) => {
	// 				setIsLoading && setIsLoading(false)
	// 				if (response.errorDetails) {
	// 					return false
	// 				} else {
	// 					return true
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				setIsLoading && setIsLoading(false)
	// 				return false
	// 			})
	// 		return response
	// 	} else {
	// 		return false
	// 	}
	// }


	// const getRequest = async (
	// 	attributes,
	// 	selectedFlight,
	// 	searchFormData,
	// 	awbNo
	// ) => {
	// 	const stationDtls = await getStationByCode(userDetails.stationCode)
	// 	const sccCodeForSubject = searchFormData && searchFormData.sccCodes
	// 		? searchFormData.sccCodes.map((e) => e.scc).toString()
	// 		: ''
	// 	const agentName = userDetails ? userDetails.companyName : null
	// 	const emailDate = selectedFlight && selectedFlight.departureDateTime
	// 		? moment(selectedFlight.departureDateTime).format('DD MMM YYYY')
	// 		: null
	// 	const originDestination = selectedFlight ? selectedFlight.origin : null
	// 	const destOrigin = selectedFlight ? selectedFlight.destination : null
	// 	const mailAttributes = {
	// 		body: `Booking Update for ${`${originDestination}`}-${`${destOrigin}`} on ${`${emailDate}`} created from portal.`,
	// 		agentCode: userDetails ? userDetails.agentCode : null,
	// 		agentName,
	// 		agentEmail: userDetails ? userDetails.email : null,

	// 	}


	// 	if (stationDtls && stationDtls.emailAddress) {
	// 		const emailAddresses = stationDtls.emailAddress.split(',')
	// 		const request = {
	// 			toEmailAddresses: emailAddresses,
	// 			subject: `Booking Modification - ${originDestination}-${destOrigin} / ${emailDate}/${sccCodeForSubject}`,
	// 			templateName: 'booking_modification_dg',
	// 			attributes: {
	// 				...mailAttributes,
	// 			},
	// 			agentCode: userDetails.agentCode,
	// 			userId: userDetails.userId,
	// 			async: false,
	// 		}
	// 		if (attributes && attributes.length) {
	// 			attributes.forEach(function (attr) {
	// 				request.attributes[attr.key] = attr.value
	// 			})
	// 		}
	// 		return request
	// 	} else {
	// 		return null
	// 	}
	// }


	const getEmailAttriburtes = () => {
		const attributes = [
			{
				key: 'origin',
				value: selectedFlight ? selectedFlight.origin : null,
			},
			{
				key: 'destination',
				value: selectedFlight ? selectedFlight.destination : null,
			},
			{
				key: 'shippingItem',
				value:
					searchFormData && searchFormData.shippingItemCode
						? `${searchFormData.shippingItemCode.description}-${searchFormData.shippingItemCode.product}`
						: null,
			},
			{
				key: 'shippingDate',
				value:
					selectedFlight && selectedFlight.departureDateTime
						? moment(selectedFlight.departureDateTime).format('DD-MMM-YYYY')
						: null,
			},

		]
		return attributes
	}


	const getEmailReplacements = (awbNo) => {
		const replacements = {
			'%FLIGHTNUMBER%':
				selectedFlight &&
					selectedFlight.flightSegmentList &&
					selectedFlight.flightSegmentList.length
					? `${selectedFlight.flightSegmentList[0].flightCarrierCode} ${selectedFlight.flightSegmentList[0].flightCarrierId}`
					: '',
			'%FLIGHTDATE%':
				selectedFlight && selectedFlight.departureDateTime
					? moment(selectedFlight.departureDateTime).format('DD-MMM-YYYY')
					: ' ',
			'%AWB%': awbNo,
			'%ORG%': selectedFlight ? selectedFlight.origin : '',
			'%DES%': selectedFlight ? selectedFlight.destination : '',
		}
		return replacements
	}
	const goBack = () => {
		clearItemFromSessionStorage('bookingDetails')
		clearValidateBookingResponse()
		history.push('/booking/flightlist')
	}

	const handleBack = () => {
		let awbNumber = awbNo.replace('-', '').trim()
		history.push({
			pathname: '/booking/flightlist',
			state: {
				modifyForm: true,
				isBackButtonAction: true,
			},
		})
	}

	const handleChange = (value) => {
		if (value.length <= 8) {
			setAWBNumber(value)
			validateAWB(value)
		}
	}

	const handleChangeOAL = (value) => {
		if (value.length <= 3) {
			setOalValue(value)
		}
		if(value.length === 0 || !value){
			setshowValidation(true)
		}else{
			setshowValidation(false)
			validateAWB(awbNumber)
		}
	}

	const handleChangeSpotRate = (value) => {
		setSpotRate(value)
	}

	const validateAWB = (value) => {
		setshowValidation(false)
		if (value) {
			if (value.length < 8) {
				setshowValidation(true)
			}
			if (value.length == 8) {
				if (value.slice(0, -1) % 7 != value[7]) {
					setshowValidation(true)
				}else if(!awbValueEnable && (oalValue.length === 0 || !oalValue)) {
					setshowValidation(true)
				}
			}
		} else if (!awbValueEnable) {
			setshowValidation(true)
		}
		// else {
		// 	setPickAwb(true)
		// }
	}

	const getChargeAmount = (element) => {
		if (element.chargeAmount) {
			return element.chargeAmount
		} else if (element.amount) {
			return element.amount
		}
	}

	const handlePickAwbChange = (val) => {
		setPickAwb(val)
		setAWBNumber('')
	}

	const validateSpotRate = (value) => {
		if (value && value !== null && value.trim() !== '') {
			let isValid = false
			isValid = FOUR_DECIMAL_PLACES.test(value)
			if (!isValid) {
				setError((prevState) => ({
					...prevState,
					spotRate: 'ER213',
				}))
				return false
			} else {
				return true
			}
		} else {
			return true
		}
	}

	const getPetsSum = (cages) => {
		let petList = cages.map((item) => {
			return item.pets.length
		})
		let sum = 0;
		for (const item of petList) {
			sum += item;
		}
		if (sum > 1) {
			return sum + ' Pets';
		}
		else {
			return sum + ' Pet';
		}
	}

	const getCageString = (length) => {
		let cageString = length > 1 ? 'Containers' : 'Container'
		return cageString;
	}
	return (
		<div className='row'>
			{isLoading ? <LoadingIndicator /> : <></>}
			<div className='col-12'>
				<div className='view-quote-page'>
					<BookingMenu activeItem={isModifyBooking ? 1 : 0} />

					{/* <div className='cargo-portal-container w-100 warning-info-wrapper'>
						{warningMessages !== null && validateResponse && (
							<Alert
								type={NOTIFICATION_MESSAGE_TYPE.WARNING}
								showAlert={true}
								message={warningMessages}
								setTimeout={false}
								isError={isError}
								onAlertClose={handleAlertClose}
								timeOut={10000}
							/>
						)}
					</div> */}

					{/* <div className='cargo-portal-container w-100 warning-info-wrapper pt-0'>
						{infoMessages !== null && validateResponse && (
							<Alert
								type={NOTIFICATION_MESSAGE_TYPE.INFO}
								showAlert={true}
								message={infoMessages}
								setTimeout={true}
								onAlertClose={handleAlertClose}
								timeOut={10000}
							/>
						)}
					</div> */}
					<div className='cargo-portal-container w-100 warning-info-wrapper pt-0'>
						{alertMessage !== '' && status !== null && (
							<Alert
								type={NOTIFICATION_MESSAGE_TYPE.ERROR}
								isError={!status}
								showAlert={true}
								message={alertMessage}
								setTimeout={true}
								onAlertClose={handleAlertClose}
							/>
						)}
					</div>
					<ShareEmailDialog
						selectedFlight={selectedFlight}
						searchFormData={searchFormData}
						getFormatedTime={getFormatedTime}
						displayBasic={displayBasic}
						setDisplayBasic={setDisplayBasic}
						screenName='QUOTE'
						quoteExpiryTime={quoteExpiryTime}
						quoteName={quoteName}
					/>
					<div className='quote-details'>
						<div className='row'>
							<div className='col-12 col-md-6 title'>
								<span className='summary_title'>{t('BOOKING.SUMMARY')}</span>
								{!isModifyBooking && (
									<span className='share_quote d-flex d-md-none'>
										<button
											type='button'
											className='l-btn l-btn--secondary l-btn--xs'
											onClick={() => handleSaveQuote()}
										>
											{t('BOOKING_SUMMARY.SHARE_QUOTE')}
										</button>
									</span>
								)}
							</div>
							{!isModifyBooking && (
								<div class='col-auto col-md-6 text-right d-none d-md-block'>
									<button
										type='button'
										className='l-btn l-btn--secondary l-btn--xs'
										onClick={() => handleSaveQuote()}
									>
										{t('BOOKING_SUMMARY.SHARE_QUOTE')}
									</button>
								</div>
							)}
						</div>
						<div class='flight-summary'>
							<div class='quote-summary'>
								<div class='shipment-summary'>
									{isModifyBooking && (
										<div className='shipment-summary__list awb-number-block'>
											<div className='shipment-summary__item'>
												<span>{`${t('COMMON.AWB_NUMBER')}: ${awbNo}`}</span>
												<div className='separator'></div>
											</div>
										</div>
									)}
									<div class='shipment-summary__item'>
										<span>
											{searchFormData && searchFormData.origin
												? searchFormData.origin.code
												: ''}
											-
											{searchFormData && searchFormData.destination
												? searchFormData.destination.code
												: ''}
										</span>
									</div>
									<div className='shipment-summary__separator'></div>
									<div class='shipment-summary__item border-r-0'>
										<span>
											{selectedFlight && selectedFlight.departureDateTime
												? moment(selectedFlight.departureDateTime, 'DD-MMM-YYYY').format(
													'ddd, DD MMM'
												)
												: ''}
										</span>
									</div>
									<a className='shipment-summary__item__right d-lg-none' onClick={() => goBack()}>
										{!isModifyBooking && (
											<>
												<span className='shipment-summary_modify-mobile'>
													<img src={editIcon} alt='modify-mobile' title='modify-mobile' />
												</span>
											</>
										)}
									</a>
								</div>
							</div>
						</div>
						<div className='col-12 text-right modify'>
							<a className='modify-styling' onClick={() => goBack()}>
								{!isModifyBooking && (
									<span id='modify-position' className='d-none d-lg-inline-block'>{t('COMMON.MODIFY')}</span>
								)}
								{isModifyBooking && (
									<span className='l-link'>{t('COMMON.SEARCHFLIGHT')}</span>
								)}
							</a>
						</div>
						{selectedFlight && (
							<RouteDetails
								selectedFlight={selectedFlight}
								searchFormData={searchFormData}
								viewFlag={true}
								isDetailsUpdate={isDetailsUpdate}
								showRates={true}
							/>
						)}
						<div className='d-none d-lg-block'>
							<div className='col-12 sub-title row'>{t('COMMON.CARGO_DTLS')}</div>
							<div className='cargo-details row'>
								{searchFormData && (
									<div className='col-6 p-l-0'>
										<div className='cargo-item'>
											<label className='col-md-4 p-l-0'> {searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? t('COMMON.QTY') : t('COMMON.PCS')}: </label>
											<div className='value-field'>
												{searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? searchFormData.crates && searchFormData.crates[0]?.weight ? `${getPetsSum(searchFormData.crates)} in ${searchFormData.crates.length} ${getCageString(searchFormData.crates.length)}` : '' : getFormattedNumber(searchFormData.totalPieces)}
											</div>
										</div>

										<div className='cargo-item'>
											<label className='col-md-4 p-l-0'>
												{t('COMMON.GROSS_WT')}
											</label>
											<>
												<div className='value-field p-r-5'>
													{searchFormData.grossWeightInSelectedUnit}
													{getWeightUnit(searchFormData.weightUnit)}
												</div>
												{searchFormData.weightUnit &&
													searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
													<div className='value-field value-field__sub'>
														({searchFormData.grossWeightInConvertedUnit}
														{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
													</div>
												) : (
													<></>
												)}
											</>
										</div>

										<div className='cargo-item'>
											<label className='col-md-4 p-l-0'>{t('COMMON.VOL')}</label>
											{searchFormData.dimensionUnit ===
												DIMENSION_UNIT.CENTIMETERS ? (
												<div className='value-field p-r-5'>
													{getDecimalFormattedNumber(
														searchFormData.totalVolumeInMtrs
													)}
													{getVolumeUnit(DIMENSION_UNIT.METERS)}
												</div>
											) : (
												<>
													<div className='value-field p-r-5'>
														{getDecimalFormattedNumber(
															searchFormData.actualVolume
														)}
														{getVolumeUnit(searchFormData.dimensionUnit)}
													</div>
													<div className='value-field value-field__sub'>
														(
														{getDecimalFormattedNumber(
															searchFormData.totalVolumeInMtrs
														)}
														{getVolumeUnit(DIMENSION_UNIT.METERS)})
													</div>
												</>
											)}{' '}
										</div>
										<div className='cargo-item'>
											<label className='col-md-4 p-l-0'>{t('COMMON.CH_WT')}</label>

											{searchFormData.weightUnit &&
												searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
												<>
													<div className='value-field p-r-5'>
															{searchFormData
																? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
																: ''}
															{getWeightUnit(searchFormData.weightUnit)}
													</div>
													<div className='value-field p-r-5'>
															({searchFormData
																? round(
																	calculateWeightToKg(
																		chargeableWeight || searchFormData.chargeableWeight,
																		searchFormData.weightUnit
																	), 2
																)
																: ''}
															{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
													</div>
												</>
											) : (
												<div className='value-field p-r-5'>
													{searchFormData
														? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
														: ''}
													{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
												</div>
											)}
										</div>
										{searchFormData && searchFormData && searchFormData?.unid && searchFormData?.unid[0]?.unid !== '' &&
											searchFormData.shippingItemCode.product !== "LiveAnimals" && dgCheckNormal === 'true' ?
											<div className='cargo-item' style={{ marginTop: '-8px' }}>
												<div className='row'>
													<label className='col-md-4 p-l-1'>{t('COMMON.UNIDSUMMARY')}</label>
													<div className='value-field p-r-5 restrict-space'>
														{searchFormData && searchFormData.unid
															? searchFormData?.unid?.map((e, index) => searchFormData?.unid?.length - 1 === index ? `${e?.unid}` : `${e?.unid}, `)
															: '--'}
													</div>
												</div>
											</div>
											: <></>
										}
									</div>
								)}

								<div className='col-6'>
									<div className='cargo-item'>
										<label className='col-md-5'>{t('COMMON.PRD')}</label>
										<div className='value-field'>
											{selectedFlight &&
												selectedFlight.ratingDetails &&
												selectedFlight.ratingDetails.length
												? selectedFlight.ratingDetails[0].product
												: ''}
										</div>
									</div>
									<div className='cargo-item'>
										<label className='col-md-5'>{t('COMMON.SERVICE')}</label>
										<div className='value-field'>
											{selectedFlight && selectedFlight.service
												? selectedFlight.service &&
													selectedFlight.service == SERVICES.FAST_TRACK
													? DISPLAY_SERVICES[SERVICES.FAST_TRACK]
													: null
												: '--'}
										</div>
									</div>
									<div className='cargo-item' style={{ marginTop: '-8px' }}>
										<div className='row'>
											<label className='col-md-5' style={{ marginLeft: '14px' }}>{t('COMMON.SCC')}</label>
											<div className='value-field restrict-space' style={{ marginLeft: '-8px' }}>
												{(() => {
													let sccs = searchFormData && searchFormData.sccCodes
													// if (selectedFlight && selectedFlight.service) {
													// sccs = [...sccs, { scc: selectedFlight.service }]
													// }
													return sccs.map((e) => e.scc).join(", ")
												})()}
											</div>
										</div>
									</div>
									<div className='cargo-item'>
										<label className='col-md-5'>
											{t('COMMON.DENSITY')}
										</label>
										<div className='density-col'>
										{searchFormData.dimensionUnit ===
											DIMENSION_UNIT.CENTIMETERS ? (
											<div className='value-field p-r-5'>
												{searchFormData.densityInSelectedUnit}
												{getDensityUnit(
													searchFormData.weightUnit,
													DIMENSION_UNIT.METERS
												)}
											</div>
										) : (
											<div className='value-field p-r-5'>
												{searchFormData.densityInSelectedUnit}
												{getDensityUnit(
													searchFormData.weightUnit,
													searchFormData.dimensionUnit
												)}
											</div>
										)}
										{searchFormData.densityInConvertedUnit &&
											((searchFormData.weightUnit &&
												searchFormData.weightUnit !== WEIGHT_UNIT.KILOGRAM) ||
												searchFormData.dimensionUnit !==
												DIMENSION_UNIT.CENTIMETERS) ? (
											<div className='value-field value-field__sub'>
												({searchFormData.densityInConvertedUnit}
												{getDensityUnit(
													WEIGHT_UNIT.KILOGRAM,
													DIMENSION_UNIT.METERS
												)}
												)
											</div>
										) : (
											<></>
										)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='mobile_partition-wrap border-top-0 d-flex d-lg-none'>
							<div className='mobile_partition-row' onClick={() => setCargoExpanded(!isCargoExpanded)}>
								<span className='mobile_partition-title'>{t('COMMON.CARGO_DTLS')}</span>
								<span className='mobile_partition-icon'>
									<i>
										{isCargoExpanded ? (
											<img src={upArrow} alt='Flight' title='Flight' />
										) : (
											<img src={downArrow} alt='Flight' title='Flight' />
										)}
									</i>
								</span>
							</div>
							{isCargoExpanded ?
								<div className='mobile_partition-expanded'>
									{searchFormData && (
										<>
											<div className='cargo-item'>
												<label> {searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? t('COMMON.QTY') : t('COMMON.PCS')}: </label>

												<div className='value-field'>
													{searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? searchFormData.crates && searchFormData.crates[0]?.weight ? `${getPetsSum(searchFormData.crates)} in ${searchFormData.crates.length} ${getCageString(searchFormData.crates.length)}` : '' : getFormattedNumber(searchFormData.totalPieces)}
												</div>
											</div>

											<div className='cargo-item'>
												<label>
													{t('COMMON.GROSS_WT')}
												</label>
												<>
													<div className='value-field p-r-5'>
														{searchFormData.grossWeightInSelectedUnit}
														{getWeightUnit(searchFormData.weightUnit)}
													</div>
													{searchFormData.weightUnit &&
														searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
														<div className='value-field value-field__sub'>
															({searchFormData.grossWeightInConvertedUnit}
															{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
														</div>
													) : (
														<></>
													)}
												</>
											</div>

											<div className='cargo-item'>
												<label>{t('COMMON.VOL')}</label>
												{searchFormData.dimensionUnit ===
													DIMENSION_UNIT.CENTIMETERS ? (
													<div className='value-field p-r-5'>
														{getDecimalFormattedNumber(
															searchFormData.totalVolumeInMtrs
														)}
														{getVolumeUnit(DIMENSION_UNIT.METERS)}
													</div>
												) : (
													<>
														<div className='value-field p-r-5'>
															{getDecimalFormattedNumber(
																searchFormData.actualVolume
															)}
															{getVolumeUnit(searchFormData.dimensionUnit)}
														</div>
														<div className='value-field value-field__sub'>
															(
															{getDecimalFormattedNumber(
																searchFormData.totalVolumeInMtrs
															)}
															{getVolumeUnit(DIMENSION_UNIT.METERS)})
														</div>
													</>
												)}{' '}
											</div>

											<div className='cargo-item'>
												<label>
													{t('COMMON.DENSITY')}
												</label>
												{searchFormData.dimensionUnit ===
													DIMENSION_UNIT.CENTIMETERS ? (
													<div className='value-field p-r-5'>
														{searchFormData.densityInSelectedUnit}
														{getDensityUnit(
															searchFormData.weightUnit,
															DIMENSION_UNIT.METERS
														)}
													</div>
												) : (
													<div className='value-field p-r-5'>
														{searchFormData.densityInSelectedUnit}
														{getDensityUnit(
															searchFormData.weightUnit,
															searchFormData.dimensionUnit
														)}
													</div>
												)}
												{searchFormData.densityInConvertedUnit &&
													((searchFormData.weightUnit &&
														searchFormData.weightUnit !== WEIGHT_UNIT.KILOGRAM) ||
														searchFormData.dimensionUnit !==
														DIMENSION_UNIT.CENTIMETERS) ? (
													<div className='value-field value-field__sub'>
														({searchFormData.densityInConvertedUnit}
														{getDensityUnit(
															WEIGHT_UNIT.KILOGRAM,
															DIMENSION_UNIT.METERS
														)}
														)
													</div>
												) : (
													<></>
												)}
											</div>
										</>
									)}
									<div className='cargo-item'>
										<label>{t('COMMON.PRD')}</label>
										<div className='value-field'>
											{selectedFlight &&
												selectedFlight.ratingDetails &&
												selectedFlight.ratingDetails.length
												? selectedFlight.ratingDetails[0].product
												: ''}
										</div>
									</div>
									<div className='cargo-item'>
										<label>{t('COMMON.SERVICE')}</label>
										<div className='value-field'>
											{selectedFlight && selectedFlight.service
												? selectedFlight.service &&
													selectedFlight.service == SERVICES.FAST_TRACK
													? DISPLAY_SERVICES[SERVICES.FAST_TRACK]
													: null
												: '--'}
										</div>
									</div>
									<div className='cargo-item'>
										<label>{t('COMMON.SCC')}</label>
										<div className='value-field'>
											{(() => {
												let sccs = searchFormData && searchFormData.sccCodes
												// if (selectedFlight && selectedFlight.service) {
												// 	sccs = [...sccs, { scc: selectedFlight.service }]
												// }
												return sccs.map((e) => e.scc).toString()
											})()}
										</div>
									</div>
									{searchFormData && searchFormData?.unid && searchFormData?.unid[0]?.unid !== '' && dgCheckNormal === 'true' ?
										<div className='cargo-item'>
											<label className='col-md-5'>{t('COMMON.UNIDSUMMARY')}</label>
											<div className='value-field'>
												{searchFormData && searchFormData?.unid
													? searchFormData?.unid?.map((e, index) => searchFormData?.unid?.length - 1 === index ? `${e?.unid}` : `${e.unid}, `)
													: '--'}
											</div>
										</div>
										: <></>
									}
								</div>
								: <></>}
						</div>
						{(
							showRateDetails &&
							((getIsOAL(selectedFlight) && !isModifyBooking) ||
								(selectedFlight) ||
								(bookingDetails && isModifyBooking))
						) ? (
							<div>
								<div className='col-12 sub-title row'>
									{t('COMMON.CHARGES')}
								</div>

								<div className='booking-details'>
									<div className='d-flex justify-content-between row p-l-0'>
										<div>
											<h4>{t('COMMON.CH_WT')}</h4>
										</div>

										<div className='charge-weight '>
											{searchFormData.weightUnit &&
												searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
												<>
													<span className='p-r-5'>
														{searchFormData
															? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
															: 0.0}
														{getWeightUnit(searchFormData.weightUnit)}
													</span>
													<span className='value-field value-field__sub'>
														(
														{searchFormData
															? round(
																calculateWeightToKg(
																	chargeableWeight || searchFormData.chargeableWeight,
																	searchFormData.weightUnit
																), 2
															)
															: 0.0}
														{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
													</span>
												</>
											) : (
												<span>
													{searchFormData
														? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
														: ''}
													{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
												</span>
											)}
										</div>
									</div>

									{selectedFlight && selectedFlight.ratingDetails && (
										<div className='row justify-content-between d-flex p-l-0'>
											<div className='w-60 p-l-0 charges-row'>
												<h4>{t('COMMON.AIR_FREIGHT_COST')}</h4>{' '}
											</div>
											{Number(selectedFlight.ratingDetails[0].totalFreightRate) ?
												<div className='per-rate-field d-none d-lg-inline-block p-r-0'>
													{selectedFlight.ratingDetails[0].currency}{' '}
													{getDecimalFormattedNumber(
														selectedFlight.ratingDetails[0].totalFreightRate
													)}
													{'/'}
													{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
												</div>
												:
												(<div className='per-rate-field d-none d-lg-inline-block p-r-0'></div>)
											}
											<div className='rate-field p-r-0 w-15'>
												<span className='d-block'>
													{selectedFlight.ratingDetails[0].currency}{' '}
													{getDecimalFormattedNumber(
														selectedFlight.ratingDetails[0].totalFreightCharge
													)}
												</span>
												{Number(selectedFlight.ratingDetails[0].totalFreightRate) ?
													<span className='per-rate-field d-block d-lg-none'>
														{selectedFlight.ratingDetails[0].currency}{' '}
														{getDecimalFormattedNumber(
															selectedFlight.ratingDetails[0].totalFreightRate
														)}
														{'/'}
														{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
													</span>
													:
													(<></>)
												}
											</div>
										</div>
									)}

									{(selectedFlight && selectedFlight.ratingDetails &&
										selectedFlight.ratingDetails.length &&
										Number(selectedFlight.ratingDetails[0].grandTotal))
										? (
											<div className='d-flex justify-content-between row subcharges p-l-0'>
												<div className='col-auto col-md-9 p-l-0 charges-row'>
													<h4>
														{t('COMMON.SURCHARGES')}
														{selectedFlight.ratingDetails[0].otherChargeDetails &&
															selectedFlight.ratingDetails[0].otherChargeDetails
																.length > 0 &&
															selectedFlight?.ratingDetails[0]?.totalSurcharge !==
															null && (
																<span
																	class='plus-sign'
																	onClick={() =>
																		setShowSubCharges(!showSubCharges)
																	}
																>
																	<i>
																		<FontAwesomeIcon
																			icon={showSubCharges ? faMinus : faPlus}
																		/>
																	</i>
																</span>
															)}
													</h4>{' '}
												</div>
												<div>
													<span className='rate-field' id='charges-styling'>
														{selectedFlight.ratingDetails[0].currency}{' '}
														{selectedFlight.ratingDetails[0].totalSurcharge ===
															null
															? '0'
															: getDecimalFormattedNumber(
																selectedFlight.ratingDetails[0].totalSurcharge
															)}
													</span>
												</div>
											</div>
										) : (<></>)}

									{selectedFlight &&
										selectedFlight.ratingDetails &&
										selectedFlight.ratingDetails[0].otherChargeDetails &&
										selectedFlight.ratingDetails[0].otherChargeDetails.length &&
										showSubCharges ? (
										selectedFlight.ratingDetails[0].otherChargeDetails.map(
											(element, index) => {
												return (
													<div className='d-flex row justify-content-between sub-charges p-t-0 p-l-0'>
														<div className='w-60 p-l-0 charges-row p-t-0'>
															{element.chargeHeadName && (
																<h4>{capitalize(element.chargeHeadName)} </h4>
															)}
															{element.name && (
																<h4>{capitalize(element.name)} </h4>
															)}
														</div>
														<div className='per-rate-field d-none d-lg-inline-block p-r-0'>
														</div>
														<div
															className='rate-field  p-r-0 w-15'
															id='charges-styling'
														>
															{selectedFlight.ratingDetails[0].currency}{' '}
															{getDecimalFormattedNumber(
																getChargeAmount(element)
															)}
														</div>
													</div>
												)
											}
										)
									) : (
										<></>
									)}

									<div className='d-flex justify-content-between row total'>
										<div className='total-cost'>
											<h4>{t('COMMON.TTL_CST')}</h4>
											<div>
												{selectedFlight && selectedFlight.ratingDetails && (
													<span>
														{selectedFlight.ratingDetails[0].currency}{' '}
														{getDecimalFormattedNumber(
															selectedFlight.ratingDetails[0].grandTotal
														)}
													</span>
												)}
											</div>
										</div>
										<div className='exclude-text col-12 p-l-0 pr-0'>
											{fileName && fileName !== '' ? (
												<React.Fragment>
													<Trans
														i18nKey={t('ER130', { ns: 'errors' })}
														t={t}
														components={[
															<a
																className='l-link'
																href={
																	fileName && fileName.length
																		? `/media/${fileName}`
																		: '/'
																}
																role='button'
																target='_blank'
																rel='noreferrer'
															>
																{' '}
															</a>,
														]}
													/>
													{/* Additional charges may apply. Please read our&nbsp;
													<a
														className='l-link'
														href={
															fileName && fileName.length
																? `/media/${fileName}`
																: '/'
														}
														role='button'
														target='_blank'
														rel='noreferrer'
													>
														terms and conditions
													</a>
													&nbsp;for more information. */}
												</React.Fragment>
											) : (
												<React.Fragment>
													{t('COMMON.ADDITIONAL_CHARGES_MSG')}
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						) : (<></>)}

						{(!showRateDetails &&
							((!isModifyBooking && getIsOAL(selectedFlight)) ||
								(selectedFlight) ||
								(bookingDetails && isModifyBooking))) ? (
							<div className='border-bottom'>
								<div className='col-12 sub-title row '>
									{t('COMMON.CHARGES')}
								</div>
								<div className='col-12 pl-0 pt-3 pb-3 no-rate'>
									{t('COMMON.NO_RATES')}
								</div>
							</div>
						) : (<></>)}

						{!isModifyBooking ? (
							selectedFlight &&
								selectedFlight.priceClass &&
								selectedFlight.priceClass === 'ADHOC_RATE' ? (
								<></>
							) : (
								<div className='awbSpotWrap'>
									<div className='awb-details col-12 col-lg-8'>
										<h4>{t('BOOKING_SUMMARY.REQUEST_SPOT')}</h4>
										<div className='awb-title'>
											{t('COMMON.SPOT_RATE')} (
											{selectedFlight &&
												selectedFlight.ratingDetails &&
												selectedFlight.ratingDetails.length
												? selectedFlight.ratingDetails[0].currency
												: ''}
											)
										</div>
										<div className='form-row details'>
											<div className='form-group col-6 col-md-5 col-lg-3'>
												<input
													id='spotRate'
													name='spotRate'
													type='text'
													className={`form-control ${error && error['spotRate'] ? 'spotRate-error' : ''
														}`}
													value={spotRate}
													onFocus={(e) => setError(null)}
													onKeyDown={(evt) => allowNumberOnly(evt)}
													onChange={(e) => handleChangeSpotRate(e.target.value)}
													onBlur={(e) => validateSpotRate(e.target.value)}
												/>
												<span className='search-panel__errormsg'>
													{error && error['spotRate']
														? t(error['spotRate'], { ns: 'errors' })
														: null}
												</span>
											</div>
											<div className='d-none d-md-block' style={{ width: '35px' }} />
											<div className='col-12 col-lg-5 mb-0 l-checkbox'>
												<input
													id={`allInRate`}
													type='checkbox'
													onChange={(e) => setPickAllinRate(e.target.checked)}
												/>
												<label className='mb-0' htmlFor={`allInRate`}>
													{t('COMMON.ALL_INCLUSIVE')}
												</label>
											</div>
										</div>
										<div
											className='exclude-text col-12 p-l-0 pr-0'
											id='text-font-style'
										>
											<Trans i18nKey={t('COMMON.SPOT_RATE_TXT')} />
											{/* {`* All-inclusive rate includes freight charge and fuel/security/miscellaneous surcharge.`}
											<br />
											{` Other applicable charges (if any) may still apply.`} */}
										</div>
									</div>
								</div>
							)
						) : (
							<></>
						)}

						{!isModifyBooking && (
							<div className='awbSpotWrap border-bottom-0'>
								<div className='awb-details col-12 col-lg-8 pr-0'>
									<h4>{t('COMMON.AWB_DTLS')}</h4>
									<div className='l-checkbox'>
										<input
											id={`pickAWB`}
											type='checkbox'
											checked={pickAwb}
											onChange={(e) => {
												setOalValue('607')
												handlePickAwbChange(e.target.checked)
												if (e.target.checked) {
													setAwbValueEnable(true)
												}
												else {
													setAwbValueEnable(false)
												}
											}}
										/>
										<label
											id='awb-font-style'
											htmlFor={`pickAWB`}
										>
											{t('BOOKING_SUMMARY.AUTOPICK_AWB')}
										</label>
									</div>
									<div className='form-row details'>
										<div className=' form-group  col-3 col-md-2 col-xl-1'>
											<input
												type='number'
												className='form-control'
												value={awbValueEnable ? '607' : oalValue}
												disabled={awbValueEnable ? true : false}
												onChange={(e) => {
													handleChangeOAL(e.target.value)
												}}
												onBlur={(e) => {
													handleChangeOAL(e.target.value)
												}}
											/>
										</div>
										<div className='form-group col-9 col-md-5 col-xl-3'>
											<input
												type='number'
												className='form-control'
												value={awbNumber}
												disabled={awbValueEnable ? true : false}
												onChange={(e) => {	
													handleChange(e.target.value)
												}}
												onBlur={(e) => {
													validateAWB(e.target.value)
													if (e.target.value) {
														//setPickAwb(false)
													}
												}}
											/>
											{showValidation ? (
												<div className='error-msg'>
													{t('ER048', { ns: 'errors' })}
												</div>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
						{!isModifyBooking && (
							<div className='col-12 p-l-0 pr-0'>
								<button
									type='button'
									disabled={disable}
									className='col-12 col-lg-auto l-btn l-btn--primary l-btn--large'
									onClick={() => handleBookNow()}
								>
									{t('BOOKING_SUMMARY.BOOK')}
								</button>
							</div>
						)}
						{isModifyBooking && (
							<div className='col-12 p-l-0 pt-5'>
								<button
									type='button'
									className='l-btn l-btn--secondary l-btn--large mr-3'
									onClick={() => handleBack()}
								>
									{t('COMMON.BACK_BUTTON')}
								</button>
								<button
									type='button'
									className='l-btn l-btn--primary l-btn--large'
									onClick={() => setUpdateBooking()}
								>
									{t('COMMON.CONFIRM')}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = ({ updateBooking, validateBooking, listFlight }) => ({
	updateBookingResponse: updateBooking.response,
	updateBookingError: updateBooking.error,
	validateBookingResponse: validateBooking.response,
	validateBookingError: validateBooking.error,
	resultSet: listFlight.resultSet,
})
const mapDispatchToProps = (dispatch, message) => ({
	updateBookingRequest: (
		awbNumber,
		searchFormData,
		selectedFlight,
		spotRatePayload
	) => {
		dispatch(
			updateBooking(awbNumber, searchFormData, selectedFlight, spotRatePayload)
		)
	},
	clearUpdateBookingResponse: (awbNumber, searchFormData, selectedFlight) => {
		dispatch(clearUpdateBooking())
	},
	validateBookingRequest: (awbNumber, searchFormData, selectedFlight) => {
		dispatch(validateBooking(awbNumber, searchFormData, selectedFlight))
	},
	clearValidateBookingResponse: () => {
		dispatch(clearValidateBooking())
	},
	triggerListFlight: (searchFormData) => {
		dispatch(loadListFlight(searchFormData))
	},
	triggerclearListFlight: () => {
		dispatch(clearListFlight())
	},

})
export default connect(mapStateToProps, mapDispatchToProps)(ViewQuote)