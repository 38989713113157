import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import {version} from './../../package.json';
const options = {
	interpolation: {
		escapeValue: false, // not needed for react!!
	},

	debug: false,
	backend: {
		loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + version,
		// loadPath: 'http://demo8175931.mockable.io/{{lng}}/{{ns}}'
	},
	//   lng: 'en',

	//   resources: {
	//     pt: {
	//       common: pt['pt-BR'],
	//     },
	//     en: {
	//       "COMMON" {
	//           "UPDATE": "Update"
	//       },
	//     },
	//   },

	fallbackLng: 'en',

	ns: ['labels', 'errors'],

	defaultNS: 'labels',

	react: {
		wait: false,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default',
	},
}

i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next).init(options)
//   .changeLanguage('en', (err, t) => {
//     if (err) return console.log('something went wrong loading', err);
//   });

export default i18n
