import React, { useEffect,useRef } from 'react'
import { connect } from 'react-redux'
import {
	clearListFlight,
	setCalendar,
} from '../../redux/actions/listFlightActions'
import { setSearchFormData } from '../../redux/actions/searchFormActions'
import SearchResults from './SearchResults'
import { useHistory } from 'react-router-dom'
import { clearFilters } from '../../redux/actions/filterActions'
import { useTranslation } from 'react-i18next'
import { 
	pushEventFlightSearchError,
	pushEventFlightUnexpectedError
} from '../../utils/analytics';

const SearchResultsContainer = ({
	searchFormData,
	setSearchFormDataToState,
	setCalendarToState,
	resultSet,
	filters,
	clearFiltersFromState,
	clearQuoteDetails,
	showErrorPopup,
}) => {
	const { t, i18n } = useTranslation(['labels','errors'])
	const history = useHistory()
	const prevResult = usePrevious(resultSet);


	useEffect(() => {
		/*if (history.location.state && history.location.state.validationError) {
			let state = { ...history.location.state }
			delete state.validationError
			history.replace({ ...history.location, state })
		}*/
	}, [history])

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
		  ref.current = value;
		}, [value]);
		return ref.current;
	  }

	useEffect(() => {
		if(JSON.stringify(resultSet) !== JSON.stringify({}) && prevResult && JSON.stringify(prevResult) !== JSON.stringify({})){
			if(JSON.stringify(resultSet[searchFormData.shippingDate]?.error) !== JSON.stringify(prevResult[searchFormData.shippingDate]?.error)){
				if(resultSet[searchFormData.shippingDate]?.error?.description === 'ER044' || 
					resultSet[searchFormData.shippingDate]?.error?.description === 'ER039' ||
					resultSet[searchFormData.shippingDate]?.error?.description === 'ER040' ||
					resultSet[searchFormData.shippingDate]?.error?.description === 'ER147'){
					//pushEventInstantConfirmation("")
					pushEventFlightSearchError(resultSet[searchFormData.shippingDate]?.error?.description, i18n.getResource('en', 'errors', 'ER046'), resultSet[searchFormData.shippingDate]?.request)
				}
				else if(resultSet[searchFormData.shippingDate]?.error?.description && resultSet[searchFormData.shippingDate]?.error?.description != ''){
					pushEventFlightUnexpectedError(resultSet[searchFormData.shippingDate]?.error?.description, i18n.getResource('en', 'errors', 'ER232'), resultSet[searchFormData.shippingDate]?.request)
				}
			}
		}
	},[searchFormData,resultSet])


	return (
		<SearchResults
			searchFormData={searchFormData}
			setSearchFormData={setSearchFormDataToState}
			setCalendar={setCalendarToState}
			resultSet={resultSet}
			filters={filters}
			clearFiltersFromState={clearFiltersFromState}
			clearQuoteDetails={clearQuoteDetails}
			showErrorPopup={showErrorPopup}
			validationError={
				history.location.state && history.location.state.validationError
					? history.location.state.validationError
					: null
			}
		/>
	)
}

const mapStateToProps = ({ searchForm, listFlight, filters }) => ({
	searchFormData: searchForm.searchFormData,
	resultSet: listFlight.resultSet,
	filters: filters.filters,
})

const mapDispatchToProps = (dispatch) => ({
	setSearchFormDataToState: (searchFormData) => {
		dispatch(setSearchFormData(searchFormData))
	},
	clearQuoteDetails: (searchFormData) => {
		dispatch(clearListFlight())
	},
	setCalendarToState: (calendar) => {
		dispatch(setCalendar(calendar))
	},
	clearFiltersFromState: () => {
		dispatch(clearFilters())
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchResultsContainer)
