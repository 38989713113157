import {
	calculateDensity,
	getOneDecimalFormattedNumber,
} from '../../../constants'
import { } from '../../SearchPanel/templateUtils'
import moment from 'moment'
import {
	getBase64Decoded,
	getFromSessionStorage,
} from '../../../utils/StorageUtils'

export const getBookingResponseMapper = (bookingDetails) => {

	let unidInfoArray = [];
	bookingDetails?.unid?.filter(item => {

		unidInfoArray.push({
			id: item?.id,
			unid: item?.unid,
			shippingName: item?.unidName,
			packingGroup: item?.packingGroup
		})

	})

	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	const dgCheck = bookingDetails?.sccCodes?.some(e => dgSccCode?.includes(e))
	const dgCheckNormal = getFromSessionStorage('ifDg')

	let responseBody = {
		awb: bookingDetails.shipmentIdentifierDetails
			? bookingDetails.shipmentIdentifierDetails
			: '',
		origin:
			bookingDetails.shipmentDetails &&
				bookingDetails.shipmentDetails.shipmentOrigin
				? bookingDetails.shipmentDetails.shipmentOrigin
				: '',
		destination:
			bookingDetails.shipmentDetails &&
				bookingDetails.shipmentDetails.shipmentDestination
				? bookingDetails.shipmentDetails.shipmentDestination
				: '',
		shippingDate:
			bookingDetails.shipmentDetails &&
				bookingDetails.shipmentDetails.shippingDate
				? getFormattedDate(bookingDetails.shipmentDetails.shippingDate)
				: '',
		arrivalDate:
			bookingDetails.shipmentDetails &&
				bookingDetails.shipmentDetails.arrivalDate
				? getFormattedDate(bookingDetails.shipmentDetails.arrivalDate)
				: '',
		bookingStatus: bookingDetails.bookingStatus
			? bookingDetails.bookingStatus
			: '',
		weightUnit: bookingDetails.weightUnit,
		volumeUnit: bookingDetails.volumeUnit,
		dimensionUnit: bookingDetails.dimensionUnit,
		rate: bookingDetails.rateDetails ? bookingDetails.rateDetails[0].rate : '',
		sccCodes: bookingDetails.sccCodes,
		dropOffAddress: bookingDetails.shipmentDetails
			? bookingDetails.shipmentDetails.dropOffAddress
			: null,
		pickUpAddress: bookingDetails.shipmentDetails
			? bookingDetails.shipmentDetails.pickUpAddress
			: null,
		bookingCargoDetails: {
			quantity:
				bookingDetails.shipmentDetails &&
					bookingDetails.shipmentDetails.totalNumberOfPieces
					? bookingDetails.shipmentDetails.totalNumberOfPieces
					: '-',
			grossWeight: bookingDetails.shipmentDetails
				? bookingDetails.shipmentDetails.totalWeight
				: '-',
			volume:
				bookingDetails.bookingCommodityDetails != null &&
					bookingDetails.bookingCommodityDetails.lenth !== 0
					? bookingDetails.bookingCommodityDetails[0].volume
					: '-',
			density:
				bookingDetails.bookingCommodityDetails != null &&
					bookingDetails.bookingCommodityDetails.lenth !== 0 &&
					bookingDetails.bookingCommodityDetails[0].volume !== 0
					? calculateDensity(
						bookingDetails.bookingCommodityDetails[0].volume,
						bookingDetails.shipmentDetails.totalWeight
					)
					: 0,

			product:
				bookingDetails?.shipmentDetails &&
					bookingDetails?.shipmentDetails?.productDetails
					? bookingDetails?.shipmentDetails?.productDetails?.productDescription
					: '',
			commodity: bookingDetails.bookingCommodityDetails[0].shipmentDescription,
			shipmentScc: bookingDetails.sccCodes ? bookingDetails.sccCodes
				: '-',
			service:
				bookingDetails.sccCodes && getService(bookingDetails.sccCodes)
					? getService(bookingDetails.sccCodes)
					: '--',
		},
		bookingFlightDetails: bookingDetails.bookingFlightDetails,
		bookingRateDetails: bookingDetails ? bookingDetails.rateDetails : null,
		chargeDetails: {
			chargeableWeight:
				bookingDetails.bookingCommodityDetails != null &&
					bookingDetails.bookingCommodityDetails[0].chargeableWeight != null
					? getOneDecimalFormattedNumber(
						bookingDetails.bookingCommodityDetails[0].chargeableWeight
					)
					: '',
			rate:
				bookingDetails.rateDetails != null &&
					bookingDetails.rateDetails[0].rate != null
					? bookingDetails.rateDetails[0].rate
					: 0,
			airFreightCost:
				bookingDetails.rateDetails != null &&
					bookingDetails.rateDetails[0].freightCharge != null
					? bookingDetails.rateDetails[0].freightCharge
					: 0,
			totalSurcharges:
				bookingDetails.rateDetails != null &&
					bookingDetails.rateDetails[0].totalOtherCharge != null
					? bookingDetails.rateDetails[0].totalOtherCharge
					: 0,
			surcharges:
				bookingDetails.rateDetails != null &&
					bookingDetails.rateDetails[0].otherChargeDetails != null
					? bookingDetails.rateDetails[0].otherChargeDetails
					: 0,
			currencyCode:
				bookingDetails.rateDetails != null &&
					bookingDetails.rateDetails[0].currencyCode != null
					? bookingDetails.rateDetails[0].currencyCode
					: '',
			totalCost:
				bookingDetails.rateDetails != null &&
					bookingDetails.rateDetails[0].totalCharge != null
					? bookingDetails.rateDetails[0].totalCharge
					: 0,
			spotRate: bookingDetails.spotRate !== null ? bookingDetails.spotRate : 0,
			allInRate: bookingDetails.allInRate,
			spotRateStatus:
				bookingDetails.spotRate !== null || bookingDetails.spotRate !== 0
					? bookingDetails.spotRateStatus
					: '',
			spotRateCode: bookingDetails.spotRateCode,
		},
		weightDetails: {
			uldInfoDetaills:
				bookingDetails.bookingCommodityDetails &&
					bookingDetails.bookingCommodityDetails[0].uldInfoDetaills
					? bookingDetails.bookingCommodityDetails[0].uldInfoDetaills
					: null,
			looseDetails:
				bookingDetails.bookingCommodityDetails &&
					bookingDetails.bookingCommodityDetails[0].dimensionDetaills
					? bookingDetails.bookingCommodityDetails[0].dimensionDetaills
					: null,
		},
		crates: bookingDetails?.cages ? bookingDetails.cages : [],
		additionalCommodityDetails: (dgCheck) || (dgCheckNormal && dgCheckNormal === 'true') ?
			bookingDetails?.additionalCommodityDetails : null
		,
		specializedCommodityAttachments: bookingDetails?.specializedCommodityAttachments,
		dgbookcheck: bookingDetails?.additionalCommodityDetails?.dangerousGoods ? true : false,
		promoCode: bookingDetails?.promoCode
	}

	return responseBody
}

const getFormattedDate = (dateToChange) => {
	return moment(dateToChange, 'DD-MMM-YYYY').format('ddd DD MMM')
}

const getShipmentSccs = (handlingCodes) => {
	let shipmentSccs = []
	handlingCodes.filter((handlingCode) => shipmentSccs.push(handlingCode.code))
	return shipmentSccs.toString()
}

const getService = (sccCodes) => {
	if (sccCodes.length && sccCodes.includes('XPS')) {
		return 'FastTrack'
	}
}
