import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { SIMILAR_BOOKING_SUGGESTIONS_NUMBER } from '../../configs/appConfigs'
import { getWeightUnit } from '../../constants'
import { getSimilarBookingResponse } from '../../services/manageBookingService'
import { useTranslation } from 'react-i18next'
const SimilarBookingSuggestion = ({
    origin,
    filteredAirports,
    setNotLeave,
    autoCompleteRef,
}) => {

    const { t } = useTranslation(['labels'])

    const [recentBookings, setRecentBookings] = useState([])
    const [filteredRecentBookings, setFilteredRecentBookings] = useState([])
    const [suggestedAirports, setSuggestedAirports] = useState([])

    useEffect(() => {
        setSuggestedAirports([...filteredAirports])
    }, [filteredAirports])

    const fetchRecentBookings = useCallback(async () => {
        try {
            if (origin) {
                const similarBookingResponse = await getSimilarBookingResponse(origin)
                setRecentBookings(similarBookingResponse.bookingList)
            }
        } catch (err) {
            console.log(err)
        }
    },
        [origin])

    useEffect(() => {
        fetchRecentBookings()
    }, [fetchRecentBookings])

    useEffect(() => {
        if (recentBookings && recentBookings.length && suggestedAirports && suggestedAirports.length) {
            const filteredRecentBookings = recentBookings.filter(bookingDetails => (
                suggestedAirports.some(airport => (
                    airport.code === bookingDetails.destination
                )
                )))
            setFilteredRecentBookings(filteredRecentBookings.slice(0, SIMILAR_BOOKING_SUGGESTIONS_NUMBER))
        }
    }, [recentBookings, suggestedAirports])

    const getTemplate = (filteredRecentBookings) => {
        return (
            <div className='similar-booking-overlay'>
                <div className='similar-booking-overlay__title'>
                    {t('COMMON.RECENT_BKG')}
                </div>
                <ul
                    className='similar-booking-overlay__items'
                    role='listbox'>
                    {
                        (filteredRecentBookings.map(bookingDetails => {
                            const { shipmentIdentifierDetails } = bookingDetails
                            const awbNumber = `${shipmentIdentifierDetails.shipmentPrefix}${shipmentIdentifierDetails.masterDocumentNumber}`
                            return (
                                <li
                                    className='similar-booking-overlay__item'
                                    role='option'>
                                    <a
                                        href={`/booking/search/${awbNumber}`}
                                        onClick={() => { setNotLeave(false) }}
                                    >
                                        <div className='similar-booking-overlay__label'>
                                            <span className='label-ond'>
                                                {`${bookingDetails.origin} - ${bookingDetails.destination}`}
                                            </span>
                                            <span className='label-product'>
                                                {bookingDetails.product}
                                            </span>
                                            <span className='label-quantity'>
                                                {`${bookingDetails.pieces} Pcs/${(bookingDetails.weight) ?
                                                    bookingDetails.weight.toString().split('.')[0] : ''} ${bookingDetails.weightUnit ? getWeightUnit(bookingDetails.weightUnit) : ''}`}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            )
                        }))
                    }
                </ul>
            </div>
        )
    }

    return (
        (autoCompleteRef &&
            autoCompleteRef.overlay &&
            origin &&
            filteredAirports && filteredAirports.length &&
            filteredRecentBookings && filteredRecentBookings.length) ? (
            ReactDOM.createPortal(
                (getTemplate(filteredRecentBookings)),
                autoCompleteRef.overlay.element
            )

        ) : (<></>)

    )
}

export default SimilarBookingSuggestion