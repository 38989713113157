import React, { useState } from 'react';
import DgMultiui from './dgmultiui';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import {
    AUTOCOMPLETE_UNID,
    AUTOCOMPLETE_TYPE,
    DIMENSION_UNIT,
    FORM_FIELDS,
    FRESH_FORWARD_PRODUCT,
    getInitLooseItemDetail,
    getInitPharmaObject,
    getInitULDItemDetail,
    PHARMA_PRODUCT,
    PHARMA_SHIPPING_TYPE,
    IS_KNOWN_UNKNOWN,
    PRICE_CODE_TYPE,
    SCC_CODES,
    SHIPPING_TYPE,
    VALIDATE_BOOKINGS_ERROR_CODES,
    WEIGHT_UNIT,
    NOTIFICATION_MESSAGE_TYPE,
    SYSTEM_ADDED_SCCS,
    PAGE_PRIMARY_CATEGORY,
    PAGE_SUB_CATEGORY1,
    getInitUnidDetail
} from '../../../constants'
import { useEffect } from 'react';

import { RadioButton } from 'primereact/radiobutton';
import {
    removeDuplicates,
    getUnidDataList,
} from '../templateUtils'
import {
    setToSessionStorage,
    getFromSessionStorage,
} from '../../../utils/StorageUtils'
import { getAdditionalSccs } from '../../../services/searchPanelServices'

const DgMultiUnidContainer = ({
    handleClear,
    selectedDestination,
    selectedOrigin,
    shippingDate,
    shippingItem,
    setIsLoading,
    isModifyBooking,
    searchFormData,

    draftCallback,

    error,
    clearError,
    setSccWarning,
    setNotificationMessage,
    setNotificationType,
    handleSccChange,
    setUnidName,
    setUnid,
    unid,
    unidSelectedValidation,
    setUnidSelectedValidation,
    unidName,
    setSpecialProvison,
    specialProvison,
    specailProvialMessage,
    additionalSccs,
    unidList,
    setUnidList,
    // dgbookcheck,
    setCargoFlights,
    cargoFlights,
    handleAddAdditinalScc,
    handleRemoveAdditionalScc,
    setShipmentSccs,
    handelAddCAOAuto,
    isCAOChecked,
    setIsCAOChecked,
    setAutoAdd,
    autoAdd,
    setAdditionalSccs,
    setExceptionTypeDg,
    spUnid,
    setSpUnid,
    appliedSccs,
    setAppliedSccs,
    unidSccList,
    setUnidSccList,
    unidArray,
    additionalInfoData,
    setAdditionalInfoData,
    displayDgValidationPopup,
    setDisplayDgValidationPopup,
    popupNotificationPopup,
    setEmbargoErrorCheck,
    setSpecialProvisonErrorCheck
}) => {
    const [unidWarning, setUnidWarning] = useState()
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [alertProps, setAlertProps] = useState({})
    const [cargoOnly, setCargoOnly] = useState([])
    const [cargoFlight, setCargoFlight] = useState(cargoFlights)
    const [cargoCheck, setCargoCheck] = useState(false)
    const [isonlyCargo, setIsonlyCargo] = useState(false)
    const [isCAOSccAdded, setIsCAOSccAdded] = useState(false)
    const [unidSccMasterData, setUnidSccMasterData] = useState([])



    useEffect(() => {
        let sccMasterData = getFromSessionStorage('master-data-sccs')

        if (sccMasterData) {
            sccMasterData = JSON.parse(sccMasterData)
        }
        if (sccMasterData && sccMasterData.length > 0) {
            //setSccsD(sccMasterData)

        } else {
            getAdditionalSccs()
                .then((sccs) => {
                    setToSessionStorage('master-data-sccs', JSON.stringify(sccs))
                    let sccMap = {};
                    sccs.map((sccItem, index) => {
                        sccMap[sccItem.scc] = sccItem.sccDescription
                    })
                    setToSessionStorage('master-data-scc-map', JSON.stringify(sccMap))

                })
                .catch((err) => {

                })
        }
        ; (async () => {
            try {
                const unidDataList = await getUnidDataList();
                let unidSccMasterList = []
                if (unidDataList) {
                    unidDataList.filter(item => {
                        unidSccMasterList.push(item.shcCodes)
                    })
                    setUnidSccMasterData(unidSccMasterList)
                }
            } catch (error) {
                console.log(error.message)
            }
        })()

    }, [])

    useEffect(() => {

        setCargoFlight(cargoFlights)
        if (searchFormData?.unid?.length > 0) {
           // let unidData = [...searchFormData?.unid]
            //setUnidList(unidData)
            if (searchFormData && searchFormData?.unidSccList) {
                //setUnidSccList(searchFormData?.unidSccList)
                let filteredScc = appliedSccs.some((item) => item.scc === 'CAO')
                if (filteredScc) {
                    setCargoFlight(true)
                }
            }
            if (searchFormData && searchFormData?.sccCodes) {
                //setUnidSccList(searchFormData?.unidSccList)
                let filteredScc = searchFormData?.sccCodes?.some((item) => item.scc === 'CAO')
                if (filteredScc) {
                    setCargoFlight(true)
                }
                else{
                    setCargoFlight(false)
                }
            }
        }
    }, [searchFormData])


    useEffect(() => {
        if (additionalSccs && additionalSccs.length > 0) {
            let filteredScc = additionalSccs.some((item) => item.scc === 'CAO')
            if (filteredScc) {
                if (!isCAOSccAdded) {
                    setIsCAOSccAdded(true)
                }
            } else {
                if (isCAOSccAdded) {
                    setIsCAOSccAdded(false)
                }
            }
        } else {
            if (isCAOSccAdded) {
                setIsCAOSccAdded(false)
            }
        }
    }, [additionalSccs])

    useEffect(() => {
        if (unidList?.unid?.length > 0) {
            setCargoCheck(false)
            // setCargoFlight()

            // handleRemoveAdditionalScc(sccCOA)
        }

    })


    const handleAddLineItem = (e) => {
        e.preventDefault()
        setUnidList([...unidList, getInitUnidDetail()])
    }

    const handleItemChange = (index, item) => {
        const tempunidDetails = [...unidList]
        tempunidDetails[index] = item
        setUnidList([...tempunidDetails])
    }

    const handelChangeCargo = (e) => {
        const scccao = {
            "scc": "CAO",
            "sccDescription": "CARGO AIRCRAFT ONLY",
            'readOnly': true
        }

        if (e.value === "Yes") {
            setAutoAdd(true)
            setCargoFlights(true)
            // handleAddAdditinalScc(scccao)
            let tempAppliedSccs = [...appliedSccs]
            let filteredScc = tempAppliedSccs.some((item) => item.scc === 'CAO')

            if (!filteredScc) {
                tempAppliedSccs.push(scccao)
                setAppliedSccs(tempAppliedSccs)
                removeDuplicates([scccao])
            }

        }
        else {
            setAutoAdd(false)
            //handleRemoveAdditionalScc(scccao)
            let tempAppliedSccs = [...appliedSccs]

            tempAppliedSccs.forEach((sccItem) => {
                if (sccItem.scc === 'CAO') {          //checking weather array contain the id
                    tempAppliedSccs.splice(tempAppliedSccs.indexOf(sccItem), 1);  //deleting
                }
            })

            setAppliedSccs([...tempAppliedSccs], [scccao])
            setCargoFlights(false)
        }
    }




    return (
        <div>
            {
                unidList.map((item, index) => (
                    <DgMultiui
                        autoCompleteType={AUTOCOMPLETE_UNID.UNID}
                        index={index}
                        item={item}
                        handleItemChange={handleItemChange}
                        handleClear={handleClear}
                        // draftCallback={draftCallback}
                        setUnidWarning={setUnidWarning}

                        error={error}
                        selectedDestination={selectedDestination}
                        selectedOrigin={selectedOrigin}
                        shippingDate={shippingDate}
                        shippingItem={shippingItem}
                        setIsLoading={setIsLoading}
                        additionalSccs={additionalSccs}
                        searchFormData={searchFormData}
                        handleAddAdditinalScc={handleAddAdditinalScc}
                        clearError={clearError}
                        setSccWarning={setSccWarning}
                        setNotificationMessage={setNotificationMessage}
                        setNotificationType={setNotificationType}
                        handleSccChange={handleSccChange}
                        setUnidName={setUnidName}
                        setUnid={setUnid}
                        unid={unid}
                        unidSelectedValidation={unidSelectedValidation}
                        setUnidSelectedValidation={setUnidSelectedValidation}
                        unidName={unidName}
                        setSpecialProvison={setSpecialProvison}
                        specialProvison={specialProvison}
                        specailProvialMessage={specailProvialMessage}
                        setUnidList={setUnidList}
                        unidList={unidList}
                        // dgbookcheck={dgbookcheck}
                        isModifyBooking={isModifyBooking}
                        cargoCheck={cargoCheck}
                        setCargoCheck={setCargoCheck}
                        cargoFlight={cargoFlight}
                        setCargoFlight={setCargoFlight}
                        handelChangeCargo={handelChangeCargo}
                        handleRemoveAdditionalScc={handleRemoveAdditionalScc}
                        setShipmentSccs={setShipmentSccs}
                        shipmentSccs={setShipmentSccs}
                        handelAddCAOAuto={handelAddCAOAuto}
                        isCAOChecked={isCAOChecked}
                        setIsCAOChecked={setIsCAOChecked}
                        setAutoAdd={setAutoAdd}
                        autoAdd={autoAdd}
                        isonlyCargo={isonlyCargo}
                        setIsonlyCargo={setIsonlyCargo}
                        setAdditionalSccs={setAdditionalSccs}
                        setExceptionTypeDg={setExceptionTypeDg}
                        spUnid={spUnid}
                        setSpUnid={setSpUnid}
                        appliedSccs={appliedSccs}
                        setAppliedSccs={setAppliedSccs}
                        unidSccList={unidSccList}
                        setUnidSccList={setUnidSccList}
                        unidArray={unidArray}


                        additionalInfoData={additionalInfoData}
                        setAdditionalInfoData={setAdditionalInfoData}
                        displayDgValidationPopup={displayDgValidationPopup}
                        setDisplayDgValidationPopup={setDisplayDgValidationPopup}

                        popupNotificationPopup={popupNotificationPopup}
                        unidSccMasterData={unidSccMasterData}
                        // setUnidSelectedValidation={setUnidSelectedValidation}
                        setEmbargoErrorCheck={setEmbargoErrorCheck}
                        setSpecialProvisonErrorCheck={setSpecialProvisonErrorCheck}



                    />
                ))}


            <div className='form-row'>
                <div className='form-group col-12 col-lg-10 m-b-0'>
                    <Button
                        label={t('COMMON.ADD_MORE_UNID')}
                        // icon='pi pi-plus'
                        className='d-block custom-xs button__another addunid '
                        type='button'
                        onClick={(e) =>
                            handleAddLineItem(e)
                        }
                    />
                </div>
            </div>
            {cargoCheck ?
                <>
                    <div className='row additional-dg-style 1-div'>
                        <div className={`col-md form-group col-lg-4`} >
                            <label className={(isonlyCargo === true || isCAOSccAdded === true) ? 'dg-label disabled' : 'dg-label'}>
                                {t('COMMON.CARGO_SHIP_CHECK')}
                            </label>
                        </div>


                        <div className={`col-md col-lg-4 `}>
                            <div className='form-item codedg-type-btn code-type-btn-left'>
                                <RadioButton
                                    id='dg-code-btn'
                                    className='select-button '
                                    value={"Yes"}
                                    onChange={(e) => {
                                        handelChangeCargo(e)
                                        setCargoFlight(true)
                                    }}
                                    checked={cargoFlight === true || isonlyCargo === true || isCAOSccAdded === true}
                                    disabled={isonlyCargo === true || isCAOSccAdded === true}
                                ></RadioButton>
                                <label className='dgcheck-label'>
                                    {t('COMMON.YES')}
                                </label>

                                <RadioButton
                                    id='dgn-code-btn'
                                    className='select-button'
                                    value={"No"}
                                    onChange={(e) => {
                                        handelChangeCargo(e)
                                        setCargoFlight(false)
                                    }}
                                    checked={cargoFlight === false && !isonlyCargo}
                                    disabled={isonlyCargo == true || isCAOSccAdded === true}
                                ></RadioButton>
                                <label className='dgcheckn-label'> {t('COMMON.NO')}</label>

                            </div>
                        </div>
                    </div>
                </>
                : <></>}


        </div>
    )
}

export default DgMultiUnidContainer;