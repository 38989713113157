import { useState } from 'react'

export const UseInput = (initialValue, options) => {
	const [value, setValue] = useState(initialValue)

	return {
		value,
		setValue,
		reset: () => setValue(''),
		bind: {
			value,
			onChange: (event) => {
				let val = event.target.value
				if (options && options.noSpaceValidation || options && options.upperCase) {
					if(options && options.noSpaceValidation && val.match(/\s/g)){
						val = val.replace(/\s/g,'');
					  }
					if(options && options.upperCase){
						val = val && val.trim() !== '' ? val.toUpperCase() : val
					}
					setValue(val);
				} 
				else {
					setValue(val)
				}
			},
		},
	}
}
