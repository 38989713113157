import { LIST_USER } from '../constants'

export const loadListUser = (userSearchData) => ({
	type: LIST_USER.LOAD,
	userSearchData,
})
export const listUserSuccess = (response) => ({
	type: LIST_USER.SUCCESS,
	response,
})
export const listUserError = (error) => ({
	type: LIST_USER.ERROR,
	error,
})
