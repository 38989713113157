import { emailPattern, FORM_FIELDS } from '../../constants'
import {
	isAlphaNumeric,
	isNumericOnly,
	validateName,
	validatePostalCode,
} from '../../utils/Validations'
import {
	validateAlphaIfPresent,
	validateAlphaNumericIfPresent,
	validateSlacPieces,
	validateRequired,
	handleHsCode,
	validateAlphaNumericRequired,
	validateFieldLengthIfPresent,
	validateAlphaRequired,
	validateAddressIfPresent,
} from '../CaptureAWB/captureAwbUtils'
import { validateDestination, validateOrigin } from '../SearchPanel/formUtils'
import {
	validateAlpha,
	validateAlphaNumeric,
	validateDecimalPattern,
	validatePhoneNuberPattern,
} from '../UserManagement/userManagementUtil'
import MESSAGES from '../../constants/Messages'
import i18n from '../../i18n'

const t = i18n.t

export const removeError = (event, setError) => {
	const id = event.target.id + ''
	setError((prevState) => ({ ...prevState, [id]: '' }))
}

export const hasError = (key, error) => {
	if (error !== null && error[key] && error[key] !== '') {
		return true
	} else {
		return false
	}
}

export const validateAWB = (value, key, setError) => {
	if (value && value !== null && value.trim() !== '') {
		if (isNumericOnly(value)) {
			if (value.length < 8) {
				setError((prevState) => ({
					...prevState,
					[key]: MESSAGES.VALID_AWB_NUMBER,
				}))
				return false
			} else if (value.length == 8) {
				if (value.slice(0, -1) % 7 != value[7]) {
					setError((prevState) => ({
						...prevState,
						[key]: MESSAGES.VALID_AWB_NUMBER,
					}))
					return false
				} else {
					setError((prevState) => ({ ...prevState, [key]: '' }))
					return true
				}
			} else {
				return true
			}
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.VALIDATE_PARTICIPANT_NAME,
			}))
			return false
		}
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: MESSAGES.VALID_AWB_NUMBER,
		}))
		return false
	}
}

export const validateHAWB = (hawbNumber, key, setError) => {
	if (hawbNumber && hawbNumber !== null && hawbNumber.trim() !== '') {
		if (isAlphaNumeric(hawbNumber)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.HAWB_ALPHANUMERIC_MSG,
			}))
			return false
		}
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: MESSAGES.ENTER_VALID_HAWB_NUMBER,
		}))
		return false
	}
}

export const validateNameRequired = (value, setError, key, fieldName) => {
	if (value && value !== null && value.trim() !== '') {
		return true
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: MESSAGES.ENTER_PARTICIPANT_NAME,
		}))
		return false
	}
}

export const validateNumericIfPresent = (value, setError, key) => {
	if (value && value !== null && value !== '') {
		if (isNumericOnly(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_NUMERIC,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateDecimalIfPresent = (value, setError, key, fieldName) => {
	if (value && value !== '' && value !== null) {
		if (validateDecimalPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: `Please enter valid ${fieldName}`,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateDecimalRequired = (value, setError, key, fieldName) => {
	if (value && value !== '' && value !== null) {
		if (validateDecimalPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: `Please enter valid ${fieldName}`,
			}))
			return false
		}
	} else {
		let ERR_KEY = (fieldName === 'Piece') ? 'ER185' : 'ER186'
		setError((prevState) => ({
			...prevState,
			[key]: ERR_KEY,
		}))
		return false
	}
}

export const validateNumericRequired = (value, setError, key, fieldName) => {
	if (value && value !== null && value !== '') {
		if (isNumericOnly(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_NUMERIC,
			}))
			return false
		}
	} else {
		let ERR_KEY = (fieldName === 'Piece') ? 'ER185' : 'ER186'
		setError((prevState) => ({
			...prevState,
			[key]: ERR_KEY,
		}))
		return false
	}
}

export const validatePostalCodeIfPresent = (value, setError, key) => {
	if (value && value !== null && value.trim() !== '') {
		if (validatePostalCode(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_ZIP_CODE,
			}))
			return false
		}
	} else {
		return true
	}
}
export const validatePhoneNumberIfPresent = (value, setError, key) => {
	let maxLength = 35
	if (value && value !== null && value.trim() !== '') {
		if (validatePhoneNuberPattern(value)) {
			if (value.length <= maxLength) {
				return true
			} else {
				setError((prevState) => ({
					...prevState,
					[key]: 'Maximum length is 35',
				}))
				return false
			}
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_PHN_NO,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateEmailIfPresent = (email, setError, key) => {
	let maxLength = 70
	if (email && email !== null && email.trim() !== '') {
		if (emailPattern.test(email)) {
			if (email.length <= maxLength) {
				return true
			} else {
				setError((prevState) => ({
					...prevState,
					[key]: 'Maximum length is 70',
				}))
				return false
			}
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_MAIL,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateHawbForm = (
	awbDetails,
	hawbNumber,
	shipmentDetails,
	shipperDetails,
	consigneeDetails,
	awbChargeDeclaration,
	customsInformationDetails,
	otherCustomsDetails,
	setError,
) => {
	let status = true
	let statuses = []
	let err1 = validateOrigin(shipmentDetails.origin)
	if (err1) {
		status = false
		statuses.push(status)
		setError((prevState) => ({
			...prevState,
			[FORM_FIELDS.ORIGIN]: err1,
		}))
	}
	let err = validateDestination(
		shipmentDetails.destination,
		shipmentDetails.origin,
	)
	if (err) {
		status = false
		statuses.push(status)
		setError((prevState) => ({
			...prevState,
			[FORM_FIELDS.DESTINATION]: err,
		}))
	}

	let isValidHawb = validateHAWB(hawbNumber, 'hawbNumber', setError)
	statuses.push(isValidHawb)
	statuses = [
		...statuses,
		...ValidateHawbDetails(shipmentDetails, setError),
		...validateParticipants(shipperDetails, consigneeDetails, setError),
		...validateCustomsDetails(awbChargeDeclaration,customsInformationDetails,otherCustomsDetails, setError),
	]
	return statuses
}

/**
 * Validate HAWB details fields
 *
 * @param {*} shipmentDetails
 * @param {*} setError
 */

const ValidateHawbDetails = (shipmentDetails, setError) => {
	let statuses = []

	let isValid = validateRequired(
		shipmentDetails.shipmentDescription,
		setError,
		'shipmentDescription',
		'Shipment description'
	)
	statuses.push(isValid)

	isValid = validateDecimalRequired(
		shipmentDetails.weight,
		setError,
		'weight',
		'Weight'
	)
	statuses.push(isValid)

	isValid = validateDecimalIfPresent(
		shipmentDetails.volume,
		setError,
		'volume',
		'Volume'
	)
	statuses.push(isValid)

	isValid = validateNumericRequired(
		shipmentDetails.pieces,
		setError,
		'pieces',
		'Piece'
	)
	statuses.push(isValid)

	isValid = validateSlacPieces(
		shipmentDetails.slacPieces,
		setError,
		shipmentDetails.pieces
	)
	statuses.push(isValid)

	isValid = validateDecimalIfPresent(
		shipmentDetails.declaredValueForCustoms,
		setError,
		'declaredValueForCustoms'
	)
	statuses.push(isValid)

	return statuses
}

/**
 * Validate Participants fields
 *
 * @param {*} shipperDetails
 * @param {*} consigneeDetails
 * @param {*} setError
 */
export const validateParticipants = (shipperDetails, consigneeDetails, setError) => {
	let statuses = []

	let isValid = validateNameRequired(
		shipperDetails.shipperName,
		setError,
		'shipperName',
		'Name'
	)
	statuses.push(isValid)

	isValid = validateRequired(
		shipperDetails.shipperAddress1,
		setError,
		'shipperAddress',
		t('COMMON.ADDRS')
	)
	statuses.push(isValid)

	isValid = validatePostalCode(
		shipperDetails.shipperPostalCode,
		setError,
		'shipperZipCode'
	)
	statuses.push(isValid)

	// isValid = validateAlphaNumericIfPresent(
	// 	shipperDetails.shipperAccountNumber,
	// 	setError,
	// 	'shipperAccountNumber'
	// )
	// statuses.push(isValid)

	isValid = validateRequired(
		shipperDetails.shipperCity,
		setError,
		'shipperCity',
		t('COMMON.CITY')
	)
	statuses.push(isValid)

	isValid = validateAlphaIfPresent(
		shipperDetails.shipperState,
		setError,
		'shipperState'
	)
	statuses.push(isValid)

	isValid = validateRequired(
		shipperDetails.shipperCountry,
		setError,
		'shipperCountry'
	)
	statuses.push(isValid)

	isValid = validatePhoneNumberIfPresent(
		shipperDetails.shipperPhone,
		setError,
		'shipperTelephone'
	)
	statuses.push(isValid)

	isValid = validateEmailIfPresent(
		shipperDetails.shipperMail,
		setError,
		'shipperMail'
	)
	statuses.push(isValid)

	isValid = validateNameRequired(
		consigneeDetails.consigneeName,
		setError,
		'consigneeName',
		'Name'
	)
	statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.consigneeAddress1,
		setError,
		'consigneeAddress',
		t('COMMON.ADDRS')
	)
	statuses.push(isValid)

	isValid = validatePostalCodeIfPresent(
		consigneeDetails.consigneePostalCode,
		setError,
		'consigneeZipCode'
	)
	statuses.push(isValid)

	// isValid = validateAlphaNumericIfPresent(
	// 	consigneeDetails.consigneeAccountNumber,
	// 	setError,
	// 	'consigneeAccountNumber'
	// )
	// statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.consigneeCity,
		setError,
		'consigneeCity',
		t('COMMON.CITY')
	)
	statuses.push(isValid)

	isValid = validateAlphaIfPresent(
		consigneeDetails.consigneeState,
		setError,
		'consigneeState'
	)
	statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.consigneeCountry,
		setError,
		'consigneeCountry'
	)
	statuses.push(isValid)

	isValid = validatePhoneNumberIfPresent(
		consigneeDetails.consigneePhone,
		setError,
		'consigneeTelephone'
	)
	statuses.push(isValid)

	isValid = validateEmailIfPresent(
		consigneeDetails.consigneeMail,
		setError,
		'consigneeEmail'
	)
	statuses.push(isValid)

	return statuses
}

const isNotBlank = (value) => {
	if (value && value.trim() !== '') {
		return true
	} else {
		return false
	}
}
const validateCustomsDetails = (awbChargeDeclaration,customsInformationDetails,otherCustomsDetails, setError) => {
	//const awbChargeDeclaration = awbChargeDeclaration
	//const customsInformationDetails = customsInformationDetails
	let statuses = []
	
	let isValid = validateDecimalIfPresent(
		awbChargeDeclaration?.declaredValueForCarriage,
		setError,
		'dvCarriage'
	)
	
	statuses.push(isValid)

	isValid = validateRequired(otherCustomsDetails?.hccs, setError, 'hsCode', 'HS code')

	statuses.push(isValid)

	const hsCodeValid = handleHsCode(otherCustomsDetails?.hccs, setError, 'hsCode')
	if (hsCodeValid) {
		statuses = [...statuses, ...hsCodeValid]
	}
    
	if (customsInformationDetails && customsInformationDetails.length) {
		customsInformationDetails.forEach(function (info, index) {
			if (
				isNotBlank(info.countryCode) ||
				isNotBlank(info.customsInformationId) ||
				isNotBlank(info.informationId) ||
				isNotBlank(info.supplementaryCustomsInformation)
			) {
				isValid = validateAlphaRequired(
					info.countryCode,
					setError,
					`isoCountryCode${index}`
				)
				statuses.push(isValid)

				isValid = validateFieldLengthIfPresent(
					info.countryCode,
					setError,
					`isoCountryCode${index}`,
					null,
					null,
					2
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					info.informationId,
					setError,
					`informationID${index}`
				)
				statuses.push(isValid)

				isValid = validateFieldLengthIfPresent(
					info.informationId,
					setError,
					`informationID${index}`,
					null,
					null,
					3
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					info.customsInformationId,
					setError,
					`customsInfoId${index}`
				)
				statuses.push(isValid)

				isValid = validateFieldLengthIfPresent(
					info.customsInformationId,
					setError,
					`customsInfoId${index}`,
					2,
					1,
					null
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					info.supplementaryCustomsInformation,
					setError,
					`suppCustomsInfo${index}`
				)
				statuses.push(isValid)
			}
		})
	}

	return statuses
}
