import React, { useEffect, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
const RevenueIndicator = ({
	chartData,
	chartDataType,
	isLoading,
	changeToLocalCurrency,
	currency,
}) => {
	const { t } = useTranslation(['labels', 'errors'])

	useEffect(() => { }, [])

	const getChartValue = (data) => {
		if (chartDataType === 'awbCount') {
			return data?.dataElementValue?.count
		} else if (chartDataType === 'tonnage') {
			return data.dataElementValue && data.dataElementValue.tonnage
				? `${data.dataElementValue.tonnage} Kg`
				: '0'
		} else if (chartDataType === 'charges' && !changeToLocalCurrency) {
			return currency === 'USD' ?
			data.dataElementValue && data.dataElementValue.charge
				? `${currency} ${data.dataElementValue.charge.toFixed(2)}`
				: '0' :
				data.dataElementValue && data.dataElementValue.charge
				? `${currency} ${data.dataElementValue.charge}`
				: '0'

		} else if (chartDataType === 'charges' && changeToLocalCurrency) {
			return currency === 'USD' ?
			
			data.dataElementValue && data.dataElementValue.localCharge
				? `${currency} ${data.dataElementValue.localCharge.toFixed(2)}`
				: '0' :
				data.dataElementValue && data.dataElementValue.localCharge
				? `${currency} ${data.dataElementValue.localCharge}`
				: '0'
		}
	}

	const getChartPercentage = (data) => {
		if (chartDataType === 'awbCount') {
			return data?.dataElementValue?.countPercent
		} else if (chartDataType === 'tonnage') {
			return data?.dataElementValue?.tonnagePercent
		} else if (chartDataType === 'charges' && !changeToLocalCurrency) {
			return data?.dataElementValue?.chargePercent
		} else if (chartDataType === 'charges' && changeToLocalCurrency) {
			return data?.dataElementValue?.localChargePercent
		}
	}

	const getSortedArray = (data) => {
		if (chartDataType === 'awbCount') {
			return data?.sort(function (a, b) {
				return (b.dataElementValue?.count) - (a.dataElementValue?.count);
			});
		} else if (chartDataType === 'tonnage') {
			return data?.sort(function (a, b) {
				return parseFloat(b.dataElementValue?.tonnage) - parseFloat(a.dataElementValue?.tonnage);
			});
		} else if (chartDataType === 'charges') {
			return data?.sort(function (a, b) {
				return parseFloat(b.dataElementValue?.charge) - parseFloat(a.dataElementValue?.charge);
			});
		} else return data
	}

	return (
		<div className='top-destinations-chart'>
			<div className='row'>
				<div className='col-12 col-md-12'>
					<h3 className='section-sub-heading pl-4 ml-1'>
						{t('DASHBOARD.TOP_DEST')}
					</h3>
				</div>
			</div>
			<div className='revenue-indicator-panel'>
				{getSortedArray(chartData?.destinationData?.destinations)?.slice(0, 5)?.map((data, index) => (
					<div className='indicator-element-wrap' id={`dest-data-${index}`}>
						<div className='indicator-element-wrap__details'>
							<p>{data?.dataElementName}</p> <p>{getChartValue(data)}</p>
						</div>
						<div className='indicator-element-wrap__element-background'>
							<div
								className='element'
								style={{ width: getChartPercentage(data) }}
							/>
						</div>
					</div>
				))}
				{(!chartData ||
					(chartData &&
						chartData.destinationData &&
						chartData.destinationData.destinations.length === 0)) &&
					!isLoading && (
						<div className='empty-records'>
							{t(Messages.NO_DATA_AVAILABLE, { ns: 'errors' })}
						</div>
					)}
			</div>
		</div>
	)
}

export default RevenueIndicator
