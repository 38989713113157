import { VALIDATE_BOOKING } from '../constants'

export const validateBooking = (awbNumber, searchFormData, selectedFlight) => ({
	type: VALIDATE_BOOKING.LOAD,
	awbNumber,
	searchFormData,
	selectedFlight,
})

export const validateBookingResponse = (response) => ({
	type: VALIDATE_BOOKING.SUCCESS,
	response,
})

export const validateBookingError = (error) => ({
	type: VALIDATE_BOOKING.ERROR,
	error,
})

export const clearValidateBooking = () => ({
	type: VALIDATE_BOOKING.CLEAR,
})
