import React, { useRef } from 'react'
import { Chart, Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './index.scss'
const DoughnutChart = ({
	dataSetLabel,
	labels,
	backgroundColor,
	borderColor,
	data,
	showFlightData,
	flightPercentage,
	flights,
	flightCount,
}) => {
	const ref = useRef(null)

	const getOrCreateLegendList = (chart, id) => {
		const legendContainer = document.getElementById(id)
		let listContainer = legendContainer.querySelector('ul')

		if (!listContainer) {
			listContainer = document.createElement('ul')
			legendContainer.appendChild(listContainer)
		}
		return listContainer
	}

	const htmlLegendPlugin = {
		id: 'htmlLegend',
		afterUpdate(chart, args, options) {
			const ul = getOrCreateLegendList(chart, options.containerID)

			// Remove old legend items
			while (ul.firstChild) {
				ul.firstChild.remove()
			}

			// Reuse the built-in legendItems generator
			const items = chart.options.plugins.legend.labels.generateLabels(chart)

			items.forEach((item) => {
				const li = document.createElement('li')
				li.onclick = null

				// Circle
				const boxSpan = document.createElement('span')
				boxSpan.style.background = item.fillStyle
				boxSpan.style.borderColor = item.strokeStyle
				boxSpan.style.borderWidth = item.lineWidth + 'px'

				const textContainer = document.createElement('p')
				textContainer.style.color = item.fontColor

				const text = document.createTextNode(item.text)
				textContainer.appendChild(text)
				li.appendChild(boxSpan)
				li.appendChild(textContainer)
				ul.appendChild(li)
			})
		},
	}

	if (!showFlightData) {
		Chart.register({ htmlLegendPlugin })
	}
	const chartData = {
		labels: labels,
		usePointStyle: 'true',
		datasets: [
			{
				label: dataSetLabel,
				data: data,
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				cutout: showFlightData ? '70%' : '78%',
				radius: '70%',
			},
		],
	}

	const lightOptions = {
		responsive: true,
		//maintainAspectRatio: false,

		plugins: {
			tooltip: { enabled: showFlightData ? true : false },
			htmlLegend: {
				containerID: `legend-container-${dataSetLabel}`,
			},
			legend: {
				display: false,
				label: {
					usePointStyle: 'true',
				},
			},
			datalabels: {
				color: '#fff',
				display: function(context) {
					return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
				}
			}
		},
	}

	return (
		<div className='form-row chart-main-wrapper'>
			{!showFlightData &&
			<div
				className={`col-12 col-md-12 col-lg-7 legend-wrapper ${showFlightData ? 'csn-chart' : ''}`}
				id={`legend-container-${dataSetLabel}`}
			></div>
			}
			<div className={`${showFlightData ? 'col-12 col-md-12' : 'col-7 col-md-5'} chart-container pr-0`}>
				{showFlightData ?
				<div className="donut-val">
					{/*<p>Cleared flights: {flights}</p>*/}
					<p>Total flights: {flightCount}</p>
				</div> : ''
				}
				<Doughnut
					ref={ref}
					id={`doughnutChart-${dataSetLabel}`}
					type='doughnut'
					data={chartData}
					options={lightOptions}
					plugins={showFlightData ? [ChartDataLabels] : []}
				>
				</Doughnut>
			</div>
		</div>
	)
}

export default DoughnutChart
