import React, { useState } from 'react'
import './../../../Shared/RouteDetails/route-details.scss'
import siteLogo from '../../../../assets/images/logo.png'
import flightIcon from '../../../../assets/images/cargo_flight.svg'
import moment from 'moment'
import { getWeightUnit, getDecimalFormattedNumber } from '../../../../constants'
import './booking-details.scss'
import plusIcon from '../../../../assets/images/plus.svg'
import minusIcon from '../../../../assets/images/minus.svg'
import downArrow from '../../../../assets/images/down-arrow.png'
import upArrow from '../../../../assets/images/up-arrow.png'
import { useTranslation } from 'react-i18next'
const RouteDetails = ({ viewFlag, bookingDetails }) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels','errors'])

	const routes = bookingDetails.bookingFlightDetails
		? bookingDetails.bookingFlightDetails.length - 1
		: 0
	const departureTime = moment(
		bookingDetails.bookingFlightDetails[0].segmentDepartureDate
	)
	const departureDate = moment(
		bookingDetails.bookingFlightDetails[0].segmentDepartureDate, 'DD-MMM-YYYY'
	)
	const arrivalTime = moment(
		bookingDetails.bookingFlightDetails[
			bookingDetails.bookingFlightDetails.length - 1
		].segmentArrivalDate
	)
	const arrivalDate = moment(
		bookingDetails.bookingFlightDetails[
			bookingDetails.bookingFlightDetails.length - 1
		].segmentArrivalDate,'DD-MMM-YYYY'
	)
	const origin = bookingDetails.origin
	const destination = bookingDetails.destination

	const [expandFlightSection, setExpandFlightSection] = useState(false)

	return (
		<div className='route-details-booking'>
			<div className='d-flex form-row quote-details__filghtDetails flightDetails-mainWrapper'
				 onClick={() => setExpandFlightSection(!expandFlightSection)}
			>
				<div className='col-12 flight-details__summary'>
					<div className='flight-details__summary-route'>
						<div className='logo d-none d-lg-flex mr-3 mr-lg-0'>
							<img src={siteLogo} alt='Etihad' title='Etihad' />
						</div>
						<div className='flight-details__route'>
							{/* {routes ? (
								<span className='flight-details__routes p-l-stops'>
									{routes} {t('COMMON.STOP')}
								</span>
							) : (
									<></>
								)} */}
							{routes ? (
									<span className='flight-details__routes p-l-stops'>
									<span className='flight-details__routes-arrival d-inline-block d-lg-none'>
										{departureTime.format('HH:mm')}
									</span>
									<span className='flight-details__routes-count'>
										{routes} {t('COMMON.STOP')}
									</span>
									<span className='flight-details__routes-depart d-inline-block d-lg-none'>
										{arrivalTime.format('HH:mm')}
									</span>
								</span>
							) : (
								<>
								<span className='flight-details__routes'>
										<span className='flight-details__routes-arrival d-inline-block d-lg-none'>
											{departureTime.format('HH:mm')}
										</span>
										<span className='flight-details__routes-count'></span>
										<span className='flight-details__routes-depart d-inline-block d-lg-none'>
											{arrivalTime.format('HH:mm')}
										</span>
								</span>
								</>
							)}
							<ul className='flight-details__timing'>
								<li className='flight-details__departure'>
									<strong className='d-none d-lg-inline-block'>
										{departureTime.format('HH:mm')}
									</strong>
									<span>{departureDate.format('ddd DD MMM')}</span>
									<span>{origin}</span>
								</li>
								<div className='flight-details__connection'>
									<div className='origin-destination-icon'></div>
									<div className='transit-line' />
									<div className='iconsSection'>
										<i>
											<img src={flightIcon} alt='Flight' title='Flight' />
										</i>
									</div>
									<div className='transit-line' />
									<div className='origin-destination-icon'></div>
								</div>

								<li className='flight-details__arrival'>
									<strong className='d-none d-lg-inline-block'>
										{arrivalTime.format('HH:mm')}
									</strong>
									<span>{arrivalDate.format('ddd DD MMM')}</span>
									<span>{destination}</span>
								</li>
							</ul>
						</div>
					</div>

					<div className='flight-details__right'>
						{bookingDetails.bookingRateDetails &&
						<div className='flight-details__right__sub__pricing'>
							<div className='flight-details__right__sub__amount'>
								  {/* <label>
									{bookingDetails.chargeDetails.currencyCode}{' '}
									{getDecimalFormattedNumber(
										bookingDetails.chargeDetails.airFreightCost
									)}
								</label> */}  

								  {bookingDetails.chargeDetails.airFreightCost !== 0 && bookingDetails.chargeDetails.airFreightCost != null?
										<label>
											{bookingDetails.chargeDetails.currencyCode}{' '}
											{getDecimalFormattedNumber(
												bookingDetails.chargeDetails.airFreightCost
											)}
										</label>
										: <></>
									}  
							</div>							
								<div className='flight-details__right__sub__rate'>
								{bookingDetails.chargeDetails.rate ?
									<label>
										{bookingDetails.chargeDetails.currencyCode}{' '}
										{getDecimalFormattedNumber(bookingDetails.chargeDetails.rate)}
										{'/'}
										{getWeightUnit(bookingDetails.weightUnit)}
									</label> 
									: <></>}
								</div>
						</div>}


						{!viewFlag ? (
							<div className='mr-lg-3'>
								{!expandFlightSection && !viewFlag &&(
									<i>
										<img
											src={plusIcon}
											alt='Flight'
											className='d-none d-lg-flex image-wrapper'
											onClick={() => setExpandFlightSection(true)}></img>
									</i>
								)}
								{!expandFlightSection && !viewFlag && (
									<i>
										<img
											src={downArrow}
											alt='Flight'
											title='Flight'
											className='d-flex d-lg-none'
											onClick={() => setExpandFlightSection(true)}></img>
									</i>
								)}
								{expandFlightSection && !viewFlag && (
									<i>
										<img
											alt='Flight'
											src={minusIcon}
											className='d-none d-lg-flex image-wrapper'
											onClick={() => setExpandFlightSection(false)}
										></img>
									</i>
								)}
								{expandFlightSection && !viewFlag && (
									<i>
										<img
											src={upArrow}
											alt='Flight'
											className='d-flex d-lg-none'
											onClick={() => setExpandFlightSection(false)}
										></img>
									</i>
								)}
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
				{expandFlightSection || viewFlag ? <div className='col-12 flight-details__expandedDetails'>
				<div className ='form-row w-100'>
					<div className='col-12 col-lg-6 flight-details__segment-info'>
						{bookingDetails.bookingFlightDetails.length ? (
							bookingDetails.bookingFlightDetails.map((segment, index) => {
								return (
									<>
										<div className='flight-details__routeDetails pb-4 pb-lg-2'>
											<div className='flight-details__flightNo'>
												<div>
													{segment.carrierCode}
													{segment.flightNumber}
												</div>
												<div>{segment.aircraftType}</div>
											</div>
											<div className='flight-details__route'>
												<ul className='flight-details__timing'>
													<li className='flight-details__departure'>
														<strong>
															{moment(segment.segmentDepartureDate).format(
																'HH:mm'
															)}
														</strong>
														<span>
															{moment(segment.segmentDepartureDate, 'DD-MMM-YYYY').format(
																'ddd DD MMM'
															)}
														</span>
														<span>{segment.segmentOrigin}</span>
													</li>
													<div className='flight-details__connection'>
														<div className='origin-destination-icon'></div>
														<div className='transit-line' />
														<div className='iconsSection'>
															<i>
																<img
																	src={flightIcon}
																	alt='Flight'
																	title='Flight'
																/>
															</i>
														</div>
														<div className='transit-line' />
														<div className='origin-destination-icon'></div>
													</div>

													<li className='flight-details__arrival'>
														<strong>
															{moment(segment.segmentArrivalDate).format('HH:mm')}
														</strong>
														<span>
															{moment(segment.segmentArrivalDate, 'DD-MMM-YYYY').format(
																'ddd DD MMM'
															)}
														</span>
														<span>{segment.segmentDestination}</span>
													</li>
												</ul>
											</div>
										</div>
									</>
								)
							})
						) : (
								<></>
							)}
					</div>
					
						<div className='col-12 col-lg-6 flight-details__more-info'>
							<div className='form-row address-container'>
							<div className='col-12 col-lg-6 address-info pr-lg-3'>
									<h4>{t('COMMON.DROP_OFF')}</h4>
									<div className='details'>
										{
											bookingDetails.dropOffAddress ? 
											(<>
												{bookingDetails.dropOffAddress.name
													? bookingDetails.dropOffAddress.name
													: ''}
												{', '}
												{bookingDetails.dropOffAddress.streetAndHouseNumber
													? bookingDetails.dropOffAddress.streetAndHouseNumber
													: ''}
												{', '}
												{bookingDetails.dropOffAddress.country
													? bookingDetails.dropOffAddress.country
													: ''}
												{', '}
												{bookingDetails.dropOffAddress.city
													? bookingDetails.dropOffAddress.city
													: ''}
												{', '}
												{bookingDetails.dropOffAddress.postCode
													? bookingDetails.dropOffAddress.postCode
												: ''}
											</>) : 
											(<>
												-
											</>)
										}
									</div>
								</div>
								<div className='col-12 col-lg-6 pl-lg-4 address-info address-info-pickup'>
									<h4>{t('COMMON.PICK_UP')}</h4>
									<div className='details'>
										{
											bookingDetails.pickUpAddress ? 
												(<>
													{bookingDetails.pickUpAddress.name
														? bookingDetails.pickUpAddress.name
														: ''}
													{', '}
													{bookingDetails.pickUpAddress.streetAndHouseNumber
														? bookingDetails.pickUpAddress.streetAndHouseNumber
														: ''}
													{', '}
													{bookingDetails.pickUpAddress.country
														? bookingDetails.pickUpAddress.country
														: ''}
													{', '}
													{bookingDetails.pickUpAddress.city
														? bookingDetails.pickUpAddress.city
														: ''}
													{', '}
													{bookingDetails.pickUpAddress.postCode
														? bookingDetails.pickUpAddress.postCode
														: ''}
												</>) :
												(<>
													-
												</>)
										}
									</div>
								</div>
							</div>
							<div className='form-row lat-container d-none d-lg-flex'>
								{bookingDetails.lastAcceptanceTime && (
									<div className='col-12 col-lg-6 pr-lg-3 lat-info'>
										<h4>
											LAT (<span>{t('CHOOSE_FLT.LAT_FILTER')}</span>)
									</h4>
										<div className='details'>
											{moment(bookingDetails.lastAcceptanceTime,'DD-MMM-YYYY').format(
												'ddd, DD MMM'
											)}{' '}
											<span className='time-field'>
												{' '}
												{moment(bookingDetails.lastAcceptanceTime).format(
													'HH:mm'
												)}{' '}
											</span>
										</div>
									</div>
								)}
								{bookingDetails.earliestDeliveryTime && (
									<div className='col-12 col-lg-6 pl-lg-4 toa-info'>
										<h4>
											TOA (<span>{t('CHOOSE_FLT.TOA_FILTER')}</span>)
									</h4>
										<div className='details'>
											{moment(bookingDetails.earliestDeliveryTime,'DD-MMM-YYYY').format(
												'ddd, DD MMM'
											)}{' '}
											<span className='time-field'>
												{' '}
												{moment(bookingDetails.earliestDeliveryTime).format(
													'HH:mm'
												)}{' '}
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
				</div>
				</div> : <> </> }
			</div>
		</div>
	)
}
export default RouteDetails
