import { v4 as uuidv4 } from 'uuid'
import { calculateDensity } from '../../constants'
import SessionKeys from '../../constants/sessionKeys'
import { findQuote, saveQuote } from '../../services/quoteServices'
import getCurrentUser from '../../utils/getCurrentUser'
import { getBase64Encoded, setToSessionStorage } from '../../utils/StorageUtils'
import { addError } from '../../utils/analytics'
export const triggerSaveQuote = async (
	formData,
	selectedFlight,
	setIsLoading,
	setAlertMessage,
	setStatus,
	quotationDetails
) => {
	setIsLoading(true)
	const data = await saveQuote(formData, selectedFlight, quotationDetails)
		.then((quoteResponse) => {
			setIsLoading(false)
			if (quoteResponse) {
				return quoteResponse
			} else {
				return null
			}
		})
		.catch((error) => {
			setIsLoading(false)
			addError('QUOTE_SHARE_ERROR', error)
			console.log(error)
			return null
		})
	if (data && data.quoteDetails) {
		return data
	} else if (data && data.errorDetails) {
		setAlertMessage(`${data.errorDetails[0].errorDescription}`)
		addError('QUOTE_SHARE_ERROR', `${data.errorDetails[0].errorDescription}`)
		setStatus(false)
		return data
	} else {
		setAlertMessage('Quote could not be share now. Please try again later')
		addError('QUOTE_SHARE_ERROR', `Quote could not be share now. Please try again later`)
		setStatus(false)
		return false
	}
}

export const removeError = (event, setError) => {
	const id = event.target.id + ''
	setError((prevState) => ({ ...prevState, [id]: '' }))
}

export const hasError = (key, error) => {
	if (error !== null && error[key] && error[key] !== '') {
		return true
	} else {
		return false
	}
}

export const retriveQuoteDetails = async (
	quotationName,
	setAlertMessage,
	setStatus,
	setIsLoading,
	setSeachFormData,
	setSelectedFlight
) => {
	const userDetails = getCurrentUser()

	const findQuoteRequest = {
		requestId: uuidv4(),
		quoteDetails: {
			quoteName: quotationName,
			userId: userDetails.userId,
			agentCode: userDetails.agentCode,
		},
	}

	setIsLoading(true)
	const responseQuote = await findQuote(findQuoteRequest)
		.then((response) => {
			setIsLoading(false)
			if (
				response &&
				response.quoteDetails &&
				response.quoteDetails.length > 0
			) {
				return response.quoteDetails
			} else if (response && response.errorDetails) {
				return response.errorDetails
			} else {
				return null
			}
		})
		.catch((error) => {
			console.log(error)
			addError('QUOTE_RESPONSE_ERROR', error)
			setIsLoading(false)
			return null
		})

	if (responseQuote && !responseQuote.errorDescription) {
		const quoteParameters = JSON.parse(responseQuote[0].quoteParameters)

		setToSessionStorage(
			SessionKeys.SEARCH_FORM_DATA_BOOKING,
			getBase64Encoded(quoteParameters)
		)

		setToSessionStorage(
			SessionKeys.SELECTED_FLIGHT_BOOKING,
			getBase64Encoded(quoteParameters.flightDetails)
		)
		if (responseQuote) {
			const quotationDetails = {
				quotationId: responseQuote[0].quoteId,
				quotationGeneratedDateTime: responseQuote[0].quotationGeneratedDateTime,
				quoteName: responseQuote[0].quoteId,
				quotationType: 'saved'
			}
			setToSessionStorage(
				'quotationDetails',
				getBase64Encoded(quotationDetails)
			)
			setToSessionStorage(
				'quotationId',
				quotationDetails ? quotationDetails.quotationId : null
			)
			if (responseQuote[0].quoteName) {
				setToSessionStorage(
					'quoteName',
					responseQuote[0].quoteName
				)
				setToSessionStorage('templateSelected', '')
				setToSessionStorage('draftSelected', '')
			}
		}
		return responseQuote
	} else {

		setAlertMessage(`${responseQuote.errorDescription}`)
		addError('FIND_QUOTE_ERROR', `${responseQuote.errorDescription}`)
		setStatus(false)
		return responseQuote
	}
}

export const getIsOAL = (flightSelected) => {
	let isOAL = false
	isOAL = (flightSelected?.flightSegmentList &&
		flightSelected?.flightSegmentList?.length &&
		flightSelected.flightSegmentList.some(seg => seg.flightCarrierCode !== 'EY'))
	return isOAL
}

