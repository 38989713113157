import { decode as base64_decode, encode as base64_encode } from 'base-64'

export const setToSessionStorage = (key, value) => {
	sessionStorage.setItem(key, value)
}
export const getFromSessionStorage = (key) => {
	if (sessionStorage.getItem(key)) return sessionStorage.getItem(key)
	else return null
}

export const clearItemFromSessionStorage = (key) => {
	if (sessionStorage.getItem(key)) return sessionStorage.removeItem(key)
}

export const clearSessionStorage = () => {
	sessionStorage.clear()
}

//local storage
export const setToLocalStorage = (key, value) => {
	localStorage.setItem(key, value)
}
export const getFromLocalStorage = (key) => {
	if (localStorage.getItem(key)) return localStorage.getItem(key)
	else return null
}

export const clearItemFromLocalStorage = (key) => {
	if (localStorage.getItem(key)) return localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
	localStorage.clear()
}

export const getBase64Encoded = (value) => {
	return base64_encode(JSON.stringify(value))
}
export const getBase64Decoded = (value) => {
	if(value){
		return JSON.parse(base64_decode(value))
	}else{
		return null;
	}
	
}
