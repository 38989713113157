import React, { useEffect, useState } from 'react'
import './index.scss'
import '../../manage-booking.scss'
import {
	getWeightUnit,
	getDecimalFormattedNumber,
	WEIGHT_UNIT,
} from '../../../../constants'
import plusIcon from '../../../../assets/images/plus.svg'
import minusIcon from '../../../../assets/images/minus.svg'
import tickIcon from '../../../../assets/images/Check-mark-filled.png'
import pendingApprovalIcon from '../../../../assets/images/notification-warning.svg'
import rejectIcon from '../../../../assets/images/Error-Close-Filled.png'
import { convertToLowerCase } from '../../../../utils/common'
import { getTermsConditionsByLocationResponse } from '../../../../services/adminMasterServices'
import { getAirportsByCode } from '../../../../services/searchPanelServices'
import { Trans, useTranslation } from 'react-i18next'
const ChargeDetails = ({ bookingDetails }) => {
	const { t } = useTranslation(['labels', 'errors'])
	const [rateDetails, setRateDetails] = useState([])
	const [expandSurcharges, setExpandSurcharges] = useState(false)
	const [locationCode, setLocationCode] = useState('')
	const [fileName, setFileName] = useState('')

	useEffect(() => {
		if (bookingDetails && bookingDetails?.origin) {
			try {
				getAirportsByCode(bookingDetails?.origin).then((response) => {
					setLocationCode(response?.countryCode)
				})
			} catch (error) {
				console.log(error.message)
				return
			}
		}

		if (bookingDetails && bookingDetails.chargeDetails) {
			setRateDetails(bookingDetails.chargeDetails)
		}
	}, [bookingDetails])

	// console.log('bookingDetails',bookingDetails)
	// console.log('rateDetails',rateDetails)

	const handleSurchargeExpansion = (value) => {
		setExpandSurcharges(value)
	}

	useEffect(() => {
		if (locationCode != '') {
			locationCodeCallBack(locationCode)
		} else return
	}, [locationCode])

	const locationCodeCallBack = async () => {
		try {
			await getTermsConditionsByLocationResponse(locationCode).then(
				(response) => {
					setFileName(response?.termsAndConditions?.fileName)
				}
			)
		} catch (error) {
			console.log(error.message)
			return
		}
	}

	return (
		<div className='charge-detail-wrapper mt-1 mt-lg-4 mb-lg-3 mb-4'>
			<div className='form-row charge-detail-wrapper__title pb-lg-3 pb-4'>
				<div className='col-12 col-lg-12'>
					<h3 className='page-sub-head mt-0 mb-0 '>{t('COMMON.CHARGES')}</h3>
				</div>
			</div>
			{bookingDetails && !bookingDetails.bookingRateDetails ? (
				<div className='form-row'>
					<div className='col-12 pl-0 pt-3 pb-3 no-rate'>
						{t('COMMON.NO_RATES')}
					</div>
				</div>
			) : (
				<></>
			)}
			{bookingDetails.bookingRateDetails ? (
				<div>
					{rateDetails.airFreightCost !== 0 && rateDetails.airFreightCost !== null ? (
						<div className='charge-details-inner-wrapper'>
							<div className='form-row d-flex justify-content-between'>
								<label className='form-item__label'>{t('COMMON.CH_WT')}</label>

								<span className='value-field'>
									{' '}
									{rateDetails.chargeableWeight}
									{getWeightUnit(bookingDetails.weightUnit)}
								</span>
							</div>
							<div className='form-row d-flex justify-content-between freight-wrapper'>
								<div className='w-60'>
									<label className='form-item__label'>
										{t('COMMON.AIR_FREIGHT_COST')}
									</label>
								</div>

								<div className='value-field freight-per-rate-field charge-font d-none d-lg-inline-block'>
									{Number(rateDetails.rate) ? (
										<>
											{rateDetails.currencyCode}{' '}
											{getDecimalFormattedNumber(rateDetails.rate)}
											{'/'}
											{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
										</>
									) : (
										<></>
									)}
								</div>
								<div className='value-field rate-field w-15'>
									<span className='d-block'>
										{rateDetails.currencyCode}{' '}
										{getDecimalFormattedNumber(rateDetails.airFreightCost)}
									</span>
									{Number(rateDetails.rate) ? (
										<span className='value-field d-block d-lg-none cost_per_rate_mobile'>
											{rateDetails.currencyCode}{' '}
											{getDecimalFormattedNumber(rateDetails.rate)}
											{'/'}
											{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
										</span>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className='form-row d-flex justify-content-between pl-0'>
								<div className='col-12 pl-0'>
									<div className='d-flex justfy-content-between'>
										<div className='col-8 pl-0'>
											<label className='form-item__label'>
												{t('COMMON.SURCHARGES')}
												{rateDetails.surcharges &&
													rateDetails.surcharges.length !== 0 ? (
													!expandSurcharges ? (
														<i>
															<img
																src={plusIcon}
																className='image-wrapper'
																onClick={() => handleSurchargeExpansion(true)}
															></img>
														</i>
													) : (
														<i>
															<img
																src={minusIcon}
																className='image-wrapper'
																onClick={() => handleSurchargeExpansion(false)}
															></img>
														</i>
													)
												) : (
													<></>
												)}
											</label>
										</div>

										<div className='col-4 value-field rate-field'>
											{rateDetails.currencyCode}{' '}
											{getDecimalFormattedNumber(rateDetails.totalSurcharges)}
										</div>
									</div>

									{rateDetails.surcharges &&
										expandSurcharges &&
										rateDetails.surcharges.map((surcharge) => (
											<div className='d-flex justfy-content-between'>
												<div className='col-8 pl-0'>
													<label className='form-item__label'>
														{convertToLowerCase(surcharge.name)}
													</label>
												</div>
												<div className='col-4 surcharge-value-field rate-field'>
													{rateDetails.currencyCode}{' '}
													{getDecimalFormattedNumber(surcharge.amount)}
												</div>
											</div>
										))}
								</div>
							</div>

							<div className='form-row d-flex justify-content-between'>
								{/* <div className='col-12 pl-0'> */}
								<div className='col-6 pl-0 pr-0'>
									{' '}
									<label className='form-item__label booking-total-cost'>
										{t('COMMON.TTL_CST')}
									</label>
								</div>

								<div
									className='col-6 text-right
								total-cost-value-field pl-0 pr-0'
								>
									{rateDetails.currencyCode}{' '}
									{getDecimalFormattedNumber(rateDetails.totalCost)}
								</div>
								{/* </div> */}
								<div
									className='terms-conditions-wrapper col-12 p-l-0'
									id='terms-font'
								>
									{fileName && fileName !== '' ? (
										<React.Fragment>
											<Trans
												i18nKey={t('ER130', { ns: 'errors' })}
												t={t}
												components={[
													<a
														className='link terms-conditions-wrapper__link '
														href={
															fileName && fileName.length
																? `/media/${fileName}`
																: '/'
														}
														role='button'
														target='_blank'
														rel='noreferrer'
													>
														{' '}
													</a>,
												]}
											/>
											{/* Additional charges may apply. Please read our&nbsp;
										<a
											className='link terms-conditions-wrapper__link '
											href={
												fileName && fileName.length ? `/media/${fileName}` : '/'
											}
											role='button'
											target='_blank'
											rel='noreferrer'
										>
											terms and conditions
										</a>
										&nbsp;for more information. */}
										</React.Fragment>
									) : (
										<React.Fragment>
											{t('COMMON.ADDITIONAL_CHARGES_MSG')}
										</React.Fragment>
									)}
								</div>
							</div>
						</div>
					) : (
						<div className='form-row'>
							<div className='col-12 pl-0 pt-3 pb-3 no-rate'>
								{t('COMMON.NO_RATES')}
							</div>
						</div>
					)}
				</div>
			) : (
				//
				<></>
			)}

			{rateDetails.spotRate > 0 && (
				<div className='mt-4 spot-rate-wrapper'>
					<div className='form-row'>
						<div className='col-6'>
							<label className='form-item__label spot-rate-wrapper__title'>
								{t('COMMON.SPOT_RATE')}{' '}
							</label>
							<span>
								{rateDetails.allInRate && rateDetails.allInRate === 'Y'
									? `(${t('COMMON.ALL_INCLUSIVE')})`
									: ''}
							</span>
						</div>
						<div className='col-6 text-right spot-rate-wrapper__rate'>
							{rateDetails.currencyCode}{' '}
							{getDecimalFormattedNumber(rateDetails.spotRate)}
						</div>
					</div>
					<div className='form-row mt-lg-2'>
						{rateDetails.allInRate && rateDetails.allInRate === 'Y' ? (
							<div className='terms-conditions-wrapper col-8'>
								<Trans i18nKey={t('COMMON.SPOT_RATE_TXT')} />
								{/* {`* All-inclusive rate includes freight charge and fuel/security/miscellaneous surcharge.`}<br/>
											{` Other applicable charges (if any) may still apply.`} */}
							</div>
						) : (
							<div className='col-8'></div>
						)}
						{rateDetails.spotRateStatus && (
							<div className='col-4 text-right spot_rate_status_mobile'>
								<i>
									{rateDetails.spotRateStatus === 'Applied' ? (
										<>
											<img
												src={tickIcon}
												alt='Applied'
												className='image-wrapper'
											></img>
											<span style={{ fontStyle: 'normal' }}>
												{t('VIEWBKG.ST_APPLIED')}
											</span>
										</>
									) : rateDetails.spotRateStatus === 'Rejected' ? (
										<>
											<img
												src={rejectIcon}
												alt='Rejected'
												className='image-wrapper'
											></img>
											<span style={{ fontStyle: 'normal' }}>
												{t('VIEWBKG.ST_REJECTED')}
											</span>
										</>
									) : (
										<>
											<img
												src={pendingApprovalIcon}
												alt='Pending'
												className='image-wrapper'
											></img>
											<span style={{ fontStyle: 'normal' }}>
												{t('VIEWBKG.ST_PENDNG_APPR')}
											</span>
										</>
									)}
								</i>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default ChargeDetails
