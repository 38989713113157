import React, { useRef } from 'react'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'

const generateNewShippingDate = (
	dateOfShipping,
	minDate,
	maxDate,
	onChangeHandler,
	onErrorHandler
) => {
	let currentDate = new Date()
	let regxForDate = /^[\+]{1}[0-9]+$/

	if (dateOfShipping === '.') {
		onChangeHandler(currentDate)
	} else if (regxForDate.test(dateOfShipping)) {
		let numberOfDaysToAdd = dateOfShipping.split('+')
		if (numberOfDaysToAdd.length >= 1) {
			let newShippingDate = new Date(currentDate)
			newShippingDate.setDate(
				newShippingDate.getDate() + parseInt(numberOfDaysToAdd[1])
			)
			if (
				moment(newShippingDate).subtract(1, 'days').isBefore(moment(maxDate))
			) {
				onChangeHandler(newShippingDate)
			} else {
				onChangeHandler('')
			}
		}
	} else {
		if (
			dateOfShipping.length &&
			moment(dateOfShipping).isSameOrAfter(
				moment(minDate).format('DD MMM YYYY')
			) &&
			moment(dateOfShipping).isSameOrBefore(
				moment(maxDate).format('DD MMM YYYY')
			)
		) {
			onChangeHandler(dateOfShipping)
		} else {
			if (onErrorHandler) onErrorHandler()
		}
	}
}

const AppCalendar = ({
	value,
	onChangeHandler,
	onFocusHandler,
	placeholder,
	minDate,
	maxDate,
	disabled,
	onErrorHandler,
	className,
	setMultiComponentFlightDateError,
	componentIndex,
}) => {
	const calendarRef = useRef(null)

	return (
		<Calendar
			className={className ? className : 'form-control'}
			ref={calendarRef}
			dateFormat='dd M yy'
			value={value}
			onChange={(e) => {
				onChangeHandler(e.value)
				if(setMultiComponentFlightDateError && componentIndex !== undefined){
					setMultiComponentFlightDateError('FLIGHT_DATE',e.value, componentIndex);
				}
			}}
			onFocus={(e) => {

				onFocusHandler ? onFocusHandler() : ''
			}}
			placeholder={placeholder}
			minDate={minDate ? minDate: ''}
			maxDate={maxDate}
			showOtherMonths={false}
			disabled={disabled}
			onBlur={(e) => {
				e.preventDefault()
				if (
					e.target.value &&
					e.target.value.length &&
					e.relatedTarget !== null
				) {
					calendarRef.current.state.overlayVisible = false
					generateNewShippingDate(
						e.target.value,
						minDate,
						maxDate,
						onChangeHandler,
						onErrorHandler
					)
				}
				if (!e.target.value) {
					if (onErrorHandler) onErrorHandler()
				}
				if(setMultiComponentFlightDateError && componentIndex !== undefined){
					setMultiComponentFlightDateError('FLIGHT_DATE',e.target.value, componentIndex);
				}
			}}
		/>
	)
}

export default AppCalendar
