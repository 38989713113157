/* eslint-disable import/prefer-default-export */
export const API_LOADING_STATUS = {
	IDLE: 'idle',
	PENDING: 'pending',
	SUCCESS: 'success',
	ERROR: 'error',
}

export const SEARCH_FORM = {
	SET_FORM_DATA: 'SET_SEARCH_FORM_DATA',
	SET_PHARMA_FORM_DATA: 'SET_SEARCH_PHARMA_FORM_DATA',
}

export const LIST_FLIGHT = {
	SET_CALENDAR: 'SET_CALENDAR',
	LOAD: 'LIST_FLIGHT_LOAD',
	SUCCESS: 'LIST_FLIGHT_SUCCESS',
	ERROR: 'LIST_FLIGHT_ERROR',
	CLEAR: 'LIST_FLIGHT_CLEAR',
}

export const FLIGHT_ENQUIRY = {
	LOAD: 'FLIGHT_ENQUIRY_LOAD',
	SUCCESS: 'FLIGHT_ENQUIRY_SUCCESS',
	ERROR: 'FLIGHT_ENQUIRY_ERROR',
	CLEAR: 'FLIGHT_ENQUIRY_CLEAR',
}

export const SAVE_BOOKING = {
	LOAD: 'SAVE_BOOKING_LOAD',
	SUCCESS: 'SAVE_BOOKING_SUCCESS',
	ERROR: 'SAVE_BOOKING_ERROR',
}

export const UPDATE_BOOKING = {
	LOAD: 'UPDATE_BOOKING_LOAD',
	SUCCESS: 'UPDATE_BOOKING_SUCCESS',
	ERROR: 'UPDATE_BOOKING_ERROR',
	CLEAR: 'UPDATE_BOOKING_CLEAR',
}

export const VALIDATE_BOOKING = {
	LOAD: 'VALIDATE_BOOKING_LOAD',
	SUCCESS: 'VALIDATE_BOOKING_SUCCESS',
	ERROR: 'VALIDATE_BOOKING_ERROR',
	CLEAR: 'VALIDATE_BOOKING_CLEAR',
}

export const FILTERS = {
	SET_FILTERS: 'SET_FILTERS',
	CLEAR_FILTERS: 'CLEAR_FILTERS',
}

export const FLIGHT = {
	SET_FLIGHT: 'SET_FLIGHT',
}

export const TOAST = {
	SUCCESS_TOAST: 'SUCCESS_TOAST',
	FAILURE_TOAST: 'FAILURE_TOAST',
	INFO_TOAST: 'INFO_TOAST',
}

export const LOADER = {
	ENABLE_OR_DISABLE_LOADING: 'ENABLE_OR_DISABLE_LOADING',
}

export const POPUP = {
	SHOW: 'SHOW',
	CLOSE: 'CLOSE',
}

export const LIST_BOOKING = {
	LOAD: 'LIST_BOOKING_LOAD',
	SUCCESS: 'LIST_BOOKING_SUCCESS',
	ERROR: 'LIST_BOOKING_ERROR',
	CLEAR: 'LIST_BOOKING_CLEAR',
}

export const LIST_USER = {
	LOAD: 'LIST_USER_LOAD',
	SUCCESS: 'LIST_USER_SUCCESS',
	ERROR: 'LIST_USER_ERROR',
}

export const USER_PROFILE = {
	LOAD: 'USER_PROFILE_LOAD',
	SUCCESS: 'USER_PROFILE_SUCCESS',
	ERROR: 'USER_PROFILE_ERROR',
	CLEAR: 'USER_PROFILE_CLEAR',
}

export const SAVE_TEMPLATE = {
	LOAD: 'SAVE_TEMPLATE_LOAD',
	SUCCESS: 'SAVE_TEMPLATE_SUCCESS',
	ERROR: 'SAVE_TEMPLATE_ERROR',
	CLEAR: 'SAVE_TEMPLATE_CLEAR',
}

export const LIST_TEMPLATE_NAMES = {
	LOAD: 'LIST_TEMPLATE_NAMES_LOAD',
	SUCCESS: 'LIST_TEMPLATE_NAMES_SUCCESS',
	ERROR: 'LIST_TEMPLATE_NAMES_ERROR',
}
export const LIST_TEMPLATE = {
	LOAD: 'LIST_TEMPLATE_LOAD',
	SUCCESS: 'LIST_TEMPLATE_SUCCESS',
	ERROR: 'LIST_TEMPLATE_ERROR',
}

export const AUTH = {
	SET_PRINCIPAL: 'SET_PRINCIPAL',
	SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_IS_REFRESHING: 'SET_IS_AUTH_REFRESHING',
	CLEAR: 'CLEAR_AUTH',
}

export const SAVE_DRAFT = {
	LOAD: 'SAVE_DRAFT_LOAD',
	SUCCESS: 'SAVE_DRAFT_SUCCESS',
	ERROR: 'SAVE_DRAFT_ERROR',
	CLEAR: 'SAVE_DRAFT_CLEAR',
}

export const LIST_DRAFTS = {
	LOAD: 'LIST_DRAFTS_LOAD',
	SUCCESS: 'LIST_DRAFTS_SUCCESS',
	ERROR: 'LIST_DRAFTS_ERROR',
}

export const LIST_QUOTE = {
	LOAD: 'LIST_QUOTE_LOAD',
	SUCCESS: 'LIST_QUOTE_SUCCESS',
	ERROR: 'LIST_QUOTE_ERROR',
}

export const SAVE_COMMODITY = {
	LOAD: 'SAVE_COMMODITY_LOAD',
	SUCCESS: 'SAVE_COMMODITY_SUCCESS',
	ERROR: 'SAVE_COMMODITY_ERROR',
	CLEAR: 'SAVE_COMMODITY_CLEAR',
}

export const SAVE_AIRPORT_MASTER = {
	LOAD: 'SAVE_AIRPORT_MASTER_LOAD',
	SUCCESS: 'SAVE_AIRPORT_MASTER_SUCCESS',
	ERROR: 'SAVE_AIRPORT_MASTER_ERROR',
	CLEAR: 'SAVE_AIRPORT_MASTER_CLEAR',
}

export const SAVE_SERVICE_MASTER = {
	LOAD: 'SAVE_SERVICE_MASTER_LOAD',
	SUCCESS: 'SAVE_SERVICE_MASTER_SUCCESS',
	ERROR: 'SAVE_SERVICE_MASTER_ERROR',
	CLEAR: 'SAVE_SERVICE_MASTER_CLEAR',
}

export const SAVE_PRODUCT_MASTER = {
	LOAD: 'SAVE_PRODUCT_MASTER_LOAD',
	SUCCESS: 'SAVE_PRODUCT_MASTER_SUCCESS',
	ERROR: 'SAVE_PRODUCT_MASTER_ERROR',
	CLEAR: 'SAVE_PRODUCT_MASTER_CLEAR',
}

export const SAVE_SCC_MASTER = {
	LOAD: 'SAVE_SCC_MASTER_LOAD',
	SUCCESS: 'SAVE_SCC_MASTER_SUCCESS',
	ERROR: 'SAVE_SCC_MASTER_ERROR',
	CLEAR: 'SAVE_SCC_MASTER_CLEAR',
}

export const SAVE_TERMS_MASTER = {
	LOAD: 'SAVE_TERMS_MASTER_LOAD',
	SUCCESS: 'SAVE_TERMS_MASTER_SUCCESS',
	ERROR: 'SAVE_TERMS_MASTER_ERROR',
	CLEAR: 'SAVE_TERMS_MASTER_CLEAR',
}

export const SAVE_ULD_TYPE_MASTER = {
	LOAD: 'SAVE_ULD_TYPE_MASTER_LOAD',
	SUCCESS: 'SAVE_ULD_TYPE_MASTER_SUCCESS',
	ERROR: 'SAVE_ULD_TYPE_MASTER_ERROR',
}

export const SAVE_PIVOT_INFO_MASTER = {
	LOAD: 'SAVE_PIVOT_INFO_MASTER_LOAD',
	SUCCESS: 'SAVE_PIVOT_INFO_MASTER_SUCCESS',
	ERROR: 'SAVE_PIVOT_INFO_MASTER_ERROR',
}

export const SAVE_SCC_INCOMPATIBLE_MASTER = {
	LOAD: 'SAVE_SCC_INCOMPATIBLE_MASTER_LOAD',
	SUCCESS: 'SAVE_SCC_INCOMPATIBLE_MASTER_SUCCESS',
	ERROR: 'SAVE_SCC_INCOMPATIBLE_MASTER_ERROR',
}

export const SAVE_STATION_SCHEDULE_MASTER = {
	LOAD: 'SAVE_STATION_SCHEDULE_MASTER_LOAD',
	SUCCESS: 'SAVE_STATION_SCHEDULE_MASTER_SUCCESS',
	ERROR: 'SAVE_STATION_SCHEDULE_MASTER_ERROR',
}

export const SAVE_REGION_MASTER = {
	LOAD: 'SAVE_REGION_MASTER_LOAD',
	SUCCESS: 'SAVE_REGION_MASTER_SUCCESS',
	ERROR: 'SAVE_REGION_MASTER_ERROR',
}

export const SAVE_COUNTRY_MASTER = {
	LOAD: 'SAVE_COUNTRY_MASTER_LOAD',
	SUCCESS: 'SAVE_COUNTRY_MASTER_SUCCESS',
	ERROR: 'SAVE_COUNTRY_MASTER_ERROR',
}

export const SAVE_EMBARGO_MASTER = {
	LOAD: 'SAVE_EMBARGO_MASTER_LOAD',
	SUCCESS: 'SAVE_EMBARGO_MASTER_SUCCESS',
	ERROR: 'SAVE_EMBARGO_MASTER_ERROR',
	CLEAR: 'SAVE_EMBARGO_MASTER_CLEAR',
}

export const CSN_FLIGHT_SUMMARY_FILTER = {
	APPLY: 'APPLY_FLIGHT_SUMMARY_FILTER',
	CLEAR: 'CLEAR_FLIGHT_SUMMARY_FILTER'
}

export const CSN_FLIGHT_FFM_SUMMARY_FILTER = {
	APPLY: 'APPLY_FLIGHT_FFM_SUMMARY_FILTER',
	CLEAR: 'CLEAR_FLIGHT_FFM_SUMMARY_FILTER'
}

export const AVI_BOOKING = {
	LOAD: 'AVI_BOOKING_FEATURE_DETAILS',
	SUCCESS: 'AVI_BOOKING_DETAILS_SUCCESS'
}

export const DG_BOOKING = {
	LOAD: 'DG_BOOKING_FEATURE_DETAILS',
	SUCCESS: 'DG_BOOKING_DETAILS_SUCCESS'
}

export const SEASONAL_BOOKING = {
	LOAD: 'SEASONAL_BOOKING_LIST_LOAD',
	SUCCESS: 'SEASONAL_BOOKING_LIST_SUCCESS'
}

