import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
const CountryCodeDropdown = ({
	id,
    name,
    userCountryCode,
    setCountryCode,
    countryCodes,
	triggerCodeValidation,
	setError,
}) => {
	const [text, setText] = useState(null)
	const [filteredItems, setfilteredItems] = useState([])

	const myRef = useRef()
	
    useEffect(() => {
        if(countryCodes){
            setfilteredItems([...countryCodes])
        }		
	}, [])

	const searchCountryCodes = () => {
		const countryCodeByStart =
        countryCodes && countryCodes.length && text && text.length
				? countryCodes.filter((code) => {
					let newText = text
					newText = text.startsWith('+') ? text.substring(0) : `+${text}`
				    return code.startsWith(newText)
				  })
				: []

		const countryCodeContains =
        countryCodes && countryCodes.length && text && text.length
				? countryCodes.filter((code) => {
						return (
							code.includes(text)
						)
				  })
				: []

		const filteredItems = [		
			...countryCodeByStart,
			...countryCodeContains,
          
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i === item)
		)
		setfilteredItems(filteredItems)
	}

	const itemTemplate = (code) => {
		return (
			<div className=''>
				<span className=''>{` ${code}`}</span>
			</div>
		)
	}

	const handleOnFocus = (e) => {
		if (!text) {
			myRef.current.onDropdownClick(e, '')
		}
	}

	return (
		<div className ='country-code-wrapper'>
			<AutoComplete
				id ={id}
				name ={name}		
                AutoComplete='false'
                value={userCountryCode ? userCountryCode : text}			
				dropdown
				ref={myRef}
                suggestions={filteredItems}
				completeMethod={searchCountryCodes}
				onChange={(e) => {
					setText(e.value)
					setCountryCode(null)
				}}
				onSelect={(e) => {
					setText(null)
					setCountryCode(e.value)
				}}
                onDropdownClick={() => {
					setText(null)
					const filteredItems =
						countryCodes && countryCodes.length
							?  [...countryCodes]
							: []
					setfilteredItems(filteredItems)
				}}
				onBlur={(e) => triggerCodeValidation()}
                className='form-item__field search-wrapper-country'
				onFocus={(evnt) => {
					setError((prevState) => ({ ...prevState, [id]: '' }))
				}}
				itemTemplate={itemTemplate}
			/>
		</div>  
	)
}

export default CountryCodeDropdown
