import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import sessionKeys from '../constants/sessionKeys'
import { portalHttpClient, portalAuthHttpClient } from '../utils/HttpRequestUtils'
import { getFromLocalStorage } from '../utils/StorageUtils'
import store from '../redux/store'
import { setIsRefreshing } from '../redux/actions/authAction'

export const getOktaSession = async (url) => {
	const response = portalHttpClient.get(url, {
		withCredentials: true,
		headers: {
			Accept: 'application/json'
		}
	})
	return response && response.statusCode ? response.statusCode : null
}

export const validateSession = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.validateSessionUrl}`
	try {
		const response = await portalAuthHttpClient.get(url)
		return response
	} catch(err) {
		console.log(err)
	}
}

export const refreshTokens = async (token) => {
	//store.dispatch(setIsRefreshing(true))
	const refreshToken = token || getFromLocalStorage(sessionKeys.REFRESH_TOKEN)
	if (!refreshToken) {
		return null
	}
	const url = `${envConfigs.baseUrl}${envConfigs.refreshTokenUrl}`
	try {
		const response = await portalHttpClient.get(url, {
			headers: {
				'Refresh-Token': refreshToken,
			}
		})
		return response.data
	} catch(err) {
		throw err
	} 
	// finally {
	// 	store.dispatch(setIsRefreshing(false))
	// }
}

export const sessionLogout = async () => {
	const refreshToken = getFromLocalStorage(sessionKeys.REFRESH_TOKEN)
	const url = `${envConfigs.baseUrl}${envConfigs.getSessionLogoutUrl}`
	try {
		const response = await portalHttpClient.delete(url, {
			headers: {
				'Refresh-Token': refreshToken,
			}
		})
		return response
	} catch(err) {
		console.log(err)
		return null
	} 
}

export const callLogOutService = async (url) => {
	await portalHttpClient.get(url)
}
