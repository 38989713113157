import React, { useState } from 'react'
import getCurrentUser from '../../utils/getCurrentUser'
import { useTranslation } from 'react-i18next'
import UploadFlightData from './UploadFlightData'
import './index.scss'

const ScheduleVisualization = ({ showErrorPopup }) => {
	//for multilingual
	const { t } = useTranslation(['labels'])

	const userDetails = getCurrentUser()


	return (
		<>
			<div className='container-lg cargo-portal-container schedule-visualization'>
				<div class="schedule-visualization-heading mt-4">
					<div>
						<h2 class="page-heading mt-0">Schedule visualization</h2>
					</div>
				</div>
				<UploadFlightData/>
			</div>
		</>
	)
}

export default ScheduleVisualization
