import { AVI_BOOKING } from '../constants'

export const getAviFeatureDetails = () => ({
	type: AVI_BOOKING.LOAD,
})

export const getAviBookingDetailsSuccess = (response) => ({
	type: AVI_BOOKING.SUCCESS,
    response
})

