import React, { useEffect, useState } from 'react'
import { Dropdown } from '../../../common/DropDown'
import {
	AUTOCOMPLETE_TYPE,
	FORM_FIELDS,
	getVolumeUnit,
	getWeightUnit,
	VOLUME_UNIT_SELECT_ITEMS,
	WEIGHT_UNIT_SELECT_ITEMS_WO_TON,
	PAYMENT_TYPES_SELECT_ITEMS_HAWB,
} from '../../../constants'
import AirportAutocomplete from '../../AirportAutocomplete'
import {
	validateRequired,
	validateSlacPieces,
} from '../../CaptureAWB/captureAwbUtils'
import {
	hasError,
	removeError,
	validateDecimalIfPresent,
	validateDecimalRequired,
	validateNumericIfPresent,
	validateNumericRequired,
	validateShipmentDescription,
} from '../CaptureHawbValidations'
import HawbSccAutoComplete from './HawbSccAutoComplete'
import './index.scss'
import { useTranslation } from 'react-i18next'

const HawbShipmentDetails = ({
	shipmentDetails,
	setShipmentDetails,
	error,
	setError,
	currencyList,
	setAlertMessage,
	setSuccess,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [selectedOrigin, setSelectedOrigin] = useState(null)
	const [selectedDestination, setSelectedDestination] = useState(null)


	useEffect(() => {
		if (shipmentDetails) {
			setSelectedOrigin(shipmentDetails.origin)
			setSelectedDestination(shipmentDetails.destination)
		}
	}, [])

	useEffect(() => {
		setShipmentDetails({
			...shipmentDetails,
			origin: selectedOrigin,
		})
	}, [selectedOrigin])

	useEffect(() => {
		setShipmentDetails({
			...shipmentDetails,
			destination: selectedDestination,
		})
	}, [selectedDestination])

	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}

	const handleAddAdditinalScc = async (scc) => {
		setShipmentDetails({
			...shipmentDetails,
			scc: scc,
		})
	}

	const handleSetError = (key, text) => {
		error
			? setError({
				...error,
				[key]: text,
			})
			: setError({
				[key]: text,
			})
	}

	const draftCallback = () => { }

	var trimDecimalValue = function (val, decimals) {
		if (parseFloat(val)) {
			var numeric_string = String(val);
			var parts = numeric_string.split('.', 2);
			if (parts[1] && parts[1].length > decimals) {
				let slicedString = parts[0] + '.' + parts[1].slice(0, 3)
				return slicedString.slice(0, 13)
			}
			else {
				if (val % 1 !== 0) {
					let numberString = String(val)
					return numberString.slice(0, 13)
				}
				else {
					let numberString = String(val)
					return numberString.slice(0, 12)
				}
			}
		}
	}


	return (
		<div className='hawb-shipment-detail-wrapper mt-5 mb-3'>
			<div className='form-row'>
				<div className='col-12'>
					<h4 className='page-sub-title'>{t('CAPTURE_HAWB.HAWB_DTLS')}</h4>
				</div>
			</div>

			<div className='form-row mt-3 mb-2 mb-lg-3'>
				<div
					className={`col-12 col-lg-4 form-item form-item--text
                     ${error && error[FORM_FIELDS.ORIGIN] ? 'red-border' : ''}`}
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>{t('COMMON.SHP_FROM')}</label>
					<AirportAutocomplete
						autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
						selectedOrigin={shipmentDetails.origin}
						selectedDestination={shipmentDetails.destination}
						setSelectedOrigin={setSelectedOrigin}
						handleSetError={handleSetError}
						clearError={clearError}
						screen='MANAGE_BOOKING'
						draftCallback={draftCallback}
					/>
					<span className='form-item__error'>
						{error && error[FORM_FIELDS.ORIGIN]
							? t(error[FORM_FIELDS.ORIGIN], { ns: 'errors' })
							: ''}
					</span>
				</div>

				<div
					className={`col-12 col-lg-4 form-item form-item--text
                     ${error && error[FORM_FIELDS.DESTINATION]
							? 'red-border'
							: ''
						}`}
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>{t('COMMON.SHP_TO')}</label>

					<AirportAutocomplete
						autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
						selectedOrigin={shipmentDetails.origin}
						selectedDestination={shipmentDetails.destination}
						setSelectedDestination={setSelectedDestination}
						handleSetError={handleSetError}
						clearError={clearError}
						screen='MANAGE_BOOKING'
						draftCallback={draftCallback}
					/>
					<span className='form-item__error'>
						{error && error[FORM_FIELDS.DESTINATION]
							? t(error[FORM_FIELDS.DESTINATION], { ns: 'errors' })
							: ''}
					</span>
				</div>
			</div>

			<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
				<div
					className='col-12 col-lg-4 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>{t('COMMON.SHIP_DES')}</label>
					<input
						id='shipmentDescription'
						type='text'
						maxLength='50'
						name='shipmentDescription'
						className='form-item__field'
						placeholder='Shipment description'
						aria-describedby='shipmentDescription'
						value={shipmentDetails.shipmentDescription ? shipmentDetails.shipmentDescription.toUpperCase() :''}
						onChange={(e) => {
							setShipmentDetails({
								...shipmentDetails,
								shipmentDescription: e.target.value,
							})
						}}
						onBlur={(e) => {
							validateRequired(e.target.value, setError, 'shipmentDescription', t('COMMON.SHIP_DES'))
						}}
						aria-invalid={hasError('shipmentDescription', error)}
						onFocus={(e) => {
							removeError(e, setError)
						}}
					/>
					<span className='form-item__error'>
						{error && error['shipmentDescription']
							? t(error['shipmentDescription'], { ns: 'errors' })
							: ''}
					</span>
				</div>

				<div
					className={`form-group additional_scc col-12 col-lg-4 form-item pl-lg-3`}
				>
					<label className='form-item__label'>{t('COMMON.SCC_LABEL')}</label>
					<div className='input-wrapper'>
						<HawbSccAutoComplete
							shipmentDetails={shipmentDetails}
							handleAddAdditinalScc={handleAddAdditinalScc}
							clearError={clearError}
							setAlertMessage={setAlertMessage}
							setSuccess={setSuccess}
						/>
					</div>
				</div>
			</div>

			<div className='form-row mt-lg-3 mb-2 mb-lg-3'>
				<div
					className='col-6 col-lg-4 col-xl-2 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'> {shipmentDetails?.shippingItemCode?.code === "DOG" || shipmentDetails?.shippingItemCode?.code === "CAT" ? t('COMMON.CRTS') : t('COMMON.PCS')}: </label>

					<input
						id='pieces'
						name='pieces'
						type='number'
						className='form-item__field'
						placeholder='Pieces'
						maxLength='6'
						value={shipmentDetails.pieces}
						onChange={(e) => {
							setShipmentDetails({
								...shipmentDetails,
								pieces: e.target.value,
							})
						}}
						onBlur={(e) => {
							validateNumericRequired(
								e.target.value,
								setError,
								'pieces',
								'Piece'
							)
						}}
						aria-invalid={hasError('pieces', error)}
						onFocus={(e) => {
							removeError(e, setError)
						}}
					/>
					<span className='form-item__error'>
						{error && error['pieces']
							? t(error['pieces'], { ns: 'errors' })
							: ''}
					</span>
				</div>

				<div
					className='col-6 col-lg-4 col-xl-2 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label slacLabel-mobile'>{t('COMMON.SLAC_PCS')}</label>
					<input
						id='slacPieces'
						name='slacPieces'
						type='text'
						className='form-item__field'
						placeholder='SLAC pieces(optional)'
						maxLength='6'
						value={shipmentDetails.slacPieces}
						onChange={(e) => {
							setShipmentDetails({
								...shipmentDetails,
								slacPieces: e.target.value,
							})
						}}
						onBlur={(e) => {
							validateSlacPieces(
								e.target.value,
								setError,
								shipmentDetails.pieces
							)
						}}
						aria-invalid={hasError('slacPieces', error)}
						onFocus={(e) => {
							removeError(e, setError)
						}}
					/>
					<span className='form-item__error'>
						{error && error['slacPieces']
							? t(error['slacPieces'], { ns: 'errors' })
							: ''}
					</span>
				</div>
			</div>

			<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
				<div
					className='col-12 col-lg-4 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>{t('COMMON.WT')}</label>

					<div className='form-row'>
						<div className='col-6'>
							<input
								id='weight'
								name='weight'
								type='text'
								className='form-item__field'
								placeholder='Weight'
								value={shipmentDetails.weight}
								onChange={(e) => {
									if (
										e.target.value.length <= 9 &&
										(!e.target.value.split('.')[1] ||
											!e.target.value.split('.')[1].length ||
											e.target.value.split('.')[1].length < 3) &&
										e.target.value.split('.').length <= 2
									) {
										const value = e.target.value.replace(/[^0-9.]/g, '')
										setShipmentDetails({
											...shipmentDetails,
											weight: value,
										})
									}
								}}
								onBlur={(e) => {
									validateDecimalRequired(
										e.target.value,
										setError,
										'weight',
										'Weight'
									)
								}}
								aria-invalid={hasError('weight', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['weight']
									? t(error['weight'], { ns: 'errors' })
									: ''}
							</span>
						</div>
						{/* <div className='col-2 d-inline d-lg-none'></div> */}
						<div className='col-6 col-lg-4 pr-0 ml-lg-2 form-item--select '>
							<Dropdown
								id='weightUnit'
								name='weightUnit'
								className='cust-dropdown'
								value={getWeightUnit(shipmentDetails.weightUnit)}
								options={WEIGHT_UNIT_SELECT_ITEMS_WO_TON}
								onChange={(e) => {
									setShipmentDetails({
										...shipmentDetails,
										weightUnit: e.target.value,
									})
								}}
							/>
						</div>
					</div>
				</div>

				<div
					className='col-12 col-lg-4 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>{t('COMMON.VOL')}</label>

					<div className='form-row'>
						<div className='col-6'>
							<input
								id='volume'
								name='volume'
								type='text'
								className='form-item__field'
								placeholder='Volume'
								value={shipmentDetails.volume}
								onChange={(e) => {
									if (
										e.target.value.length <= 9 &&
										(!e.target.value.split('.')[1] ||
											!e.target.value.split('.')[1].length ||
											e.target.value.split('.')[1].length < 3) &&
										e.target.value.split('.').length <= 2
									) {
										const value = e.target.value.replace(/[^0-9.]/g, '')
										setShipmentDetails({
											...shipmentDetails,
											volume: value,
										})
									}
								}}
								onBlur={(e) => {
									validateDecimalIfPresent(
										e.target.value,
										setError,
										'volume',
										'Volume'
									)
								}}
								aria-invalid={hasError('volume', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['volume']
									? t(error['volume'], { ns: 'errors' })
									: ''}
							</span>
						</div>
						<div className='col-6 col-lg-4 pr-0 ml-lg-2'>
							<Dropdown
								id='volumeUnit'
								name='volumeUnit'
								className='cust-dropdown'
								value={getVolumeUnit(shipmentDetails.volumeUnit)}
								options={VOLUME_UNIT_SELECT_ITEMS}
								onChange={(e) => {
									setShipmentDetails({
										...shipmentDetails,
										volumeUnit: e.target.value,
									})
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
				<div className='col-6 col-md-4 col-lg-2 form-item'>
					<Dropdown
						id='customsPaymentType'
						value={shipmentDetails.paymentType}
						options={PAYMENT_TYPES_SELECT_ITEMS_HAWB}
						label={t('CAPTURE_AWB.PAY_TYPE')}
						onChange={(e) => {
							setShipmentDetails({
								...shipmentDetails,
								paymentType: e.target.value,
							})
						}}
						ariaInvalid={hasError('customsPaymentType', error)}
						onFocus={(e) => {
							removeError(e, setError)
						}}
					></Dropdown>
					<span className='form-item__error'>
						{error && error['customsPaymentType']
							? error['customsPaymentType']
							: ''}
					</span>
				</div>
				<div
					className='col-12 col-md-8 col-lg-4 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>{t('COMMON.DV_CSTM')}</label>
					<div className='form-row'>
						<div className='col-6'>
							<input
								id='declaredValueForCustoms'
								name='declaredValueForCustoms'
								type='number'
								className='form-item__field'
								placeholder=''
								value={shipmentDetails.declaredValueForCustoms}
								onChange={(e) => {
									let trimmedValue = trimDecimalValue(e.target.value, 3)
									setShipmentDetails({
										...shipmentDetails,
										declaredValueForCustoms: trimmedValue,
									})
								}}
								onBlur={(e) => {
									validateDecimalIfPresent(
										e.target.value,
										setError,
										'declaredValueForCustoms'
									)
								}}
								aria-invalid={hasError('declaredValueForCustoms', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['declaredValueForCustoms']
									? t(error['declaredValueForCustoms'], { ns: 'errors' })
									: ''}
							</span>
						</div>
						<div className='col-6 col-lg-4 pr-0 ml-lg-2'>
							<Dropdown
								id='currencyCode'
								name='currencyCode'
								className='cust-dropdown'
								value={shipmentDetails.currencyCode}
								options={currencyList}
								onChange={(e) => {
									setShipmentDetails({
										...shipmentDetails,
										currencyCode: e.target.value,
									})
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='form-row mb-3'>
				<div
					className='col-12 col-lg-8 form-item form-item--text'
					data-module='molecules/form-item/FormItem'
				>
					<label className='form-item__label'>
						{t('CAPTURE_AWB.HANDLING_INFO')}
					</label>
					<input
						id='handlingInfo'
						type='text'
						className='form-item__field  handling-info'
						maxLength='140'
						value={shipmentDetails.handlingInfo ? shipmentDetails.handlingInfo.toUpperCase() :'' }
						onChange={(e) => {
							setShipmentDetails({
								...shipmentDetails,
								handlingInfo: e.target.value,
							})
						}}
						/**onBlur={(e) => {
							validateAlphaNumericIfPresent(
								e.target.value,
								setError,
								'handlingInfo'
							)
						}}**/
						aria-invalid={hasError('handlingInfo', error)}
						onFocus={(e) => {
							removeError(e, setError)
						}}
					/>
					<span className='form-item__error'>
						{error && error['handlingInfo']
							? t(error['handlingInfo'], { ns: 'errors' })
							: ''}
					</span>
				</div>
			</div>





		</div>
	)
}

export default HawbShipmentDetails
