import React from 'react'
import { connect } from 'react-redux'
import SccListDisplay from './SccListDisplay'

const SccListDisplayContainer = ({
	nonRemovableSccs,
	additionalSccs,
	appliedServices,
	handleRemoveAdditionalScc,
	envConfig,
	autoAdd,
}) => {
	return (
		<>
			<SccListDisplay
				nonRemovableSccs={nonRemovableSccs}
				additionalSccs={additionalSccs}
				appliedServices={appliedServices}
				handleRemoveAdditionalScc={handleRemoveAdditionalScc}
				envConfig={envConfig}
				autoAdd={autoAdd}
			/>
		</>
	)
}

const mapStateToProps = ({ configs }) => ({
	envConfig: configs,
})

export default connect(mapStateToProps)(SccListDisplayContainer)
