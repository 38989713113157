import axios from 'axios'
import { getHeaders, portalAuthHttpClient } from '../utils/HttpRequestUtils'
import { TIMEOUT_TIME } from '../constants'
import envConfigs from '../configs/envConfigs'

export const getAviBookingDetails = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAviBookingDetails}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getAviBookingBreeds = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAviBookingBreeds}/${data}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const postAviPetAttachment = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.postAviPetAttachment}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const deleteAviPetAttachment = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.deleteAviPetAttachment}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}