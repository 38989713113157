import React from 'react'
import Admin from './Admin'
import { connect } from 'react-redux'
import {
	clearSaveAirportMaster,
	clearSaveCommodity,
	clearSaveProductMaster,
	clearSaveSccMaster,
	clearSaveServiceMaster,
	clearSaveEmbargoResponse,
	requestSaveAirportMaster,
	requestSaveCommodity,
	requestSaveProductMaster,
	requestSaveSccMaster,
	requestSaveServiceMaster,
	requestSaveTermsMaster,
	clearSaveTermsMaster,
	requestSaveUldTypeMaster,
	requestSavePivotInfoMaster,
	requestSaveSccIncompatibillityMaster,
	requestSaveStationScheduleMaster,
	requestSaveRegionMaster,
	requestSaveCountryMaster,
	clearSaveEmbargoeMasterc,
	requestSaveEmbargoMaster
} from '../../redux/actions/adminActions'

const AdminContainer = ({
	getSaveCommodityResponse,
	saveCommodityResponse,
	saveCommodityError,
	clearSaveCommodityResponse,
	getSaveAirportMasterResponse,
	saveAirportMasterResponse,
	saveAirportMasterError,
	clearSaveAirportMasterResponse,
	getSaveServiceMasterResponse,
	saveServiceMasterResponse,
	saveServiceMasterError,
	clearSaveServiceMasterResponse,
	getSaveProductMasterResponse,
	saveProductMasterResponse,
	saveProductMasterError,
	clearSaveProductMasterResponse,
	getSaveSccMasterResponse,
	saveSccMasterResponse,
	saveSccMasterError,
	clearSaveSccMasterResponse,
	getSaveTermsMasterResponse,
	saveTermsMasterResponse,
	saveTermsMasterError,
	clearSaveTermsMasterResponse,
	getSaveUldTypeMasterResponse,
	getSavePivotInfoMasterResponse,
	savePivotWeightInfoMasterResponse,
	savePivotWeightInfoMasterError,
	saveUldTypeMasterError,
	saveUldTypeMasterResponse,
	getSaveSccIncompatibilityMasterResponse,
	saveSccIncompatibilityMasterResponse,
	saveSccIncompatibilityMasterError,
	getSaveStationScheduleMasterResponse,
	saveStationScheduleMasterResponse,
	saveStationScheduleMasterError,
	saveRegionMasterResponse,
	saveRegionMasterError,
	getSaveRegionMasterResponse,
	saveCountryMasterResponse,
	saveCountryMasterError,
	getSaveCountryMasterResponse,
	getSaveEmbargoResponse,
	saveEmbargoResponse,
	saveEmbargoError,
	clearSaveEmbargoResponse,
}) => {
	return (
		<Admin
			getSaveCommodityResponse={getSaveCommodityResponse}
			saveCommodityResponse={saveCommodityResponse}
			saveCommodityError={saveCommodityError}
			clearSaveCommodityResponse={clearSaveCommodityResponse}
			getSaveAirportMasterResponse={getSaveAirportMasterResponse}
			saveAirportMasterResponse={saveAirportMasterResponse}
			saveAirportMasterError={saveAirportMasterError}
			clearSaveAirportMasterResponse={clearSaveAirportMasterResponse}
			getSaveServiceMasterResponse={getSaveServiceMasterResponse}
			saveServiceMasterResponse={saveServiceMasterResponse}
			saveServiceMasterError={saveServiceMasterError}
			clearSaveServiceMasterResponse={clearSaveServiceMasterResponse}
			getSaveProductMasterResponse={getSaveProductMasterResponse}
			saveProductMasterResponse={saveProductMasterResponse}
			saveProductMasterError={saveProductMasterError}
			clearSaveProductMasterResponse={clearSaveProductMasterResponse}
			getSaveSccMasterResponse={getSaveSccMasterResponse}
			saveSccMasterResponse={saveSccMasterResponse}
			saveSccMasterError={saveSccMasterError}
			clearSaveSccMasterResponse={clearSaveSccMasterResponse}
			getSaveTermsMasterResponse={getSaveTermsMasterResponse}
			saveTermsMasterResponse={saveTermsMasterResponse}
			saveTermsMasterError={saveTermsMasterError}
			clearSaveTermsMasterResponse={clearSaveTermsMasterResponse}
			getSaveUldTypeMasterResponse={getSaveUldTypeMasterResponse}
			getSavePivotInfoMasterResponse={getSavePivotInfoMasterResponse}
			saveUldTypeMasterResponse={saveUldTypeMasterResponse}
			saveUldTypeMasterError={saveUldTypeMasterError}
			savePivotWeightInfoMasterError={savePivotWeightInfoMasterError}
			savePivotWeightInfoMasterResponse={savePivotWeightInfoMasterResponse}
			getSaveSccIncompatibilityMasterResponse={
				getSaveSccIncompatibilityMasterResponse
			}
			saveSccIncompatibilityMasterResponse={
				saveSccIncompatibilityMasterResponse
			}
			saveSccIncompatibilityMasterError={saveSccIncompatibilityMasterError}
			getSaveStationScheduleMasterResponse={
				getSaveStationScheduleMasterResponse
			}
			saveStationScheduleMasterResponse={saveStationScheduleMasterResponse}
			saveStationScheduleMasterError={saveStationScheduleMasterError}
			saveRegionMasterResponse={saveRegionMasterResponse}
			saveRegionMasterError={saveRegionMasterError}
			getSaveRegionMasterResponse={getSaveRegionMasterResponse}
			saveCountryMasterResponse={saveCountryMasterResponse}
			saveCountryMasterError={saveCountryMasterError}
			getSaveCountryMasterResponse={getSaveCountryMasterResponse}

			getSaveEmbargoResponse={getSaveEmbargoResponse}
			saveEmbargoResponse={saveEmbargoResponse}
			saveEmbargoError={saveEmbargoError}
			clearSaveEmbargoResponse={clearSaveEmbargoResponse}

		/>
	)
}
const mapStateToProps = ({
	saveCommodity,
	saveAirportMaster,
	saveServiceMaster,
	saveProductMaster,
	saveSccMaster,
	saveTermsMaster,
	saveUldTypeMaster,
	savePivotWeightInfoMaster,
	saveSccIncompatibilityMaster,
	saveStationScheduleMaster,
	saveRegionMaster,
	saveCountryMaster,
	saveEmbargoMaster,
}) => ({
	saveCommodityResponse: saveCommodity.response,
	saveCommodityError: saveCommodity.error,
	saveAirportMasterResponse: saveAirportMaster.response,
	saveAirportMasterError: saveAirportMaster.error,
	saveServiceMasterResponse: saveServiceMaster.response,
	saveServiceMasterError: saveServiceMaster.error,
	saveProductMasterResponse: saveProductMaster.response,
	saveProductMasterError: saveProductMaster.error,
	saveSccMasterResponse: saveSccMaster.response,
	saveSccMasterError: saveSccMaster.error,
	saveTermsMasterResponse: saveTermsMaster.response,
	saveTermsMasterError: saveTermsMaster.error,
	saveUldTypeMasterResponse: saveUldTypeMaster.response,
	saveUldTypeMasterError: saveUldTypeMaster.error,
	savePivotWeightInfoMasterResponse: savePivotWeightInfoMaster.response,
	savePivotWeightInfoMasterError: savePivotWeightInfoMaster.error,
	saveSccIncompatibilityMasterResponse: saveSccIncompatibilityMaster.response,
	saveSccIncompatibilityMasterError: saveSccIncompatibilityMaster.error,
	saveStationScheduleMasterResponse: saveStationScheduleMaster.response,
	saveStationScheduleMasterError: saveStationScheduleMaster.error,
	saveRegionMasterResponse: saveRegionMaster.response,
	saveRegionMasterError: saveRegionMaster.error,
	saveCountryMasterResponse: saveCountryMaster.response,
	saveCountryMasterError: saveCountryMaster.error,
	saveEmbargoResponse: saveEmbargoMaster?.response,
	saveEmbargoError: saveEmbargoMaster?.error,
})

const mapDispatchToProps = (dispatch, message) => ({
	getSaveCommodityResponse: (payload) => {
		dispatch(requestSaveCommodity(payload))
	},
	clearSaveCommodityResponse: () => {
		dispatch(clearSaveCommodity())
	},
	getSaveAirportMasterResponse: (payload) => {
		dispatch(requestSaveAirportMaster(payload))
	},
	clearSaveAirportMasterResponse: () => {
		dispatch(clearSaveAirportMaster())
	},
	getSaveServiceMasterResponse: (payload) => {
		dispatch(requestSaveServiceMaster(payload))
	},
	clearSaveServiceMasterResponse: () => {
		dispatch(clearSaveServiceMaster())
	},
	getSaveProductMasterResponse: (payload) => {
		dispatch(requestSaveProductMaster(payload))
	},
	clearSaveProductMasterResponse: () => {
		dispatch(clearSaveProductMaster())
	},
	getSaveSccMasterResponse: (payload) => {
		dispatch(requestSaveSccMaster(payload))
	},
	clearSaveSccMasterResponse: () => {
		dispatch(clearSaveSccMaster())
	},
	getSaveTermsMasterResponse: (payload) => {
		dispatch(requestSaveTermsMaster(payload))
	},
	clearSaveTermsMasterResponse: () => {
		dispatch(clearSaveTermsMaster())
	},
	getSaveUldTypeMasterResponse: (payload) => {
		dispatch(requestSaveUldTypeMaster(payload))
	},
	getSavePivotInfoMasterResponse: (payload) => {
		dispatch(requestSavePivotInfoMaster(payload))
	},
	getSaveSccIncompatibilityMasterResponse: (payload) => {
		dispatch(requestSaveSccIncompatibillityMaster(payload))
	},
	getSaveStationScheduleMasterResponse: (payload) => {
		dispatch(requestSaveStationScheduleMaster(payload))
	},
	getSaveRegionMasterResponse: (payload) => {
		dispatch(requestSaveRegionMaster(payload))
	},
	getSaveCountryMasterResponse: (payload) => {
		dispatch(requestSaveCountryMaster(payload))
	},

	getSaveEmbargoResponse: (payload) => {
		dispatch(requestSaveEmbargoMaster(payload))
	},
	clearSaveEmbargoResponse: () => {
		dispatch(clearSaveAirportMaster())
	},

})

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)
