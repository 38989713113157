import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useState } from 'react'
import './shipping-item-autocomplete.scss'
import './product-autocomplete.scss'
const ProductAutocomplete = ({
	products,
	selectedProduct,
	setSelectedProduct,
	placeholder,
}) => {
	const [text, setText] = useState(null)
	const [filteredItems, setFilteredItems] = useState([])

	const myRef = React.createRef()

	useEffect(() => {
		setFilteredItems([products])
		//setSelectedProduct('All')
	}, [])

	const searchProducts = ({ query }) => {
		if (!query || !query.trim().length) {
			setFilteredItems([products])
			return
		}
		const filteredByCommodityStart =
			products && products.length
				? products.filter((product) => {
						return product.name.toLowerCase().startsWith(query.toLowerCase())
				  })
				: []
		const filteredByCommodityContains =
			products && products.length
				? products.filter((product) => {
						return product.name.toLowerCase().includes(query.toLowerCase())
				  })
				: []

		const filteredItems = [
			...filteredByCommodityStart,
			...filteredByCommodityContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.description === item.description)
		)

		setFilteredItems(filteredItems)
	}

	// const handleOnFocus = (e) => {
	//     clearError(FORM_FIELDS.SHIPPING_ITEM)
	//     if (!text && !selectedProduct && myRef.current) {
	//         myRef.current.onDropdownClick(e, '')
	//     }
	// }

	const itemTemplate = (item) => {
		return (
			<div className='autocomplete_item'>
				<div className='autocomplete_item_main'>{item.name}</div>
			</div>
		)
	}

	return (
		<>
			<AutoComplete
				value={
					selectedProduct && selectedProduct.name ? selectedProduct.name : text
				}
				dropdown
				minLength={1}
				ref={myRef}
				suggestions={filteredItems}
				completeMethod={searchProducts}
				onChange={(e) => {
					setSelectedProduct(null)
					setText(e.value)
				}}
				onSelect={(e) => {
					setSelectedProduct(e.value)
				}}
				// onFocus={(e) => {
				// 	handleOnFocus(e)
				// }}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onBlur={(e) => {
					if (filteredItems && filteredItems.length === 1) {
						setSelectedProduct(filteredItems[0])
						setText(null)
						return
					}
					setText(null)
				}}
				onDropdownClick={() => {
					setText(null)
					setFilteredItems([...products])
				}}
				className='form-item__field autocomplete-inner-wrapper'
				placeholder={placeholder}
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default ProductAutocomplete
