import React, { useState, useEffect, Suspense } from 'react'
import './price-calendar.scss'
import moment from 'moment'
import leftArrow from '../../assets/images/prev-icon.svg'
import rightArrow from '../../assets/images/next-icon.svg'
import dgRestrictedSccs from '../../configs/dgRestrictedSccs';

const DateElement = React.lazy(() => import('./DateElement'))

const PriceCalendar = ({
	calendarDates,
	selectedDate,
	setSelectedDate,
	searchFormData,
	increaseSelectionStartDate,
	calendarStartDate,
	calendarEndDate,
	resultSet,
}) => {
	const [isNextBtn, setIsNextBtn] = useState(true)
	const [isPrevBtn, setIsPrevBtn] = useState(true)

	useEffect(() => {
		let isPrevBtn
		let isNextBtn
		if (calendarDates && calendarDates.length && calendarEndDate) {
			isPrevBtn = moment(calendarDates[0], 'DD-MMM-YYYY').isAfter(calendarStartDate)
			setIsPrevBtn(isPrevBtn)
			isNextBtn = !moment(calendarDates[calendarDates.length - 1], 'DD-MMM-YYYY').add(1, 'days').isAfter(calendarEndDate)
			setIsNextBtn(isNextBtn)
		}
		if (searchFormData.sccCodes) {
			let result = searchFormData.sccCodes.reduce((acc, item) => {
				acc.push(item.scc)
				return acc
			}, [])

			if (result.includes('AVI') || ((searchFormData.shippingItemCode?.product === "Specialized Commodities") && !(dgRestrictedSccs?.includes(searchFormData.shippingItemCode?.scc))) || searchFormData.dgbookcheck) {
				if (searchFormData.aviBookingDetaills && searchFormData.aviBookingDetaills.length > 0) {
					let aviBookingMinDate = moment().add(searchFormData.aviBookingDetaills[0].minBookingDays + 1, 'days').toDate()
					let dgBookingMinDate = moment().add(searchFormData.aviBookingDetaills[1].minBookingDays + 1, 'days').toDate()
					if (moment(calendarDates[0], 'DD-MMM-YYYY').isBefore(moment(aviBookingMinDate)) || moment(calendarDates[0], 'DD-MMM-YYYY').isBefore(moment(dgBookingMinDate)) && searchFormData.shippingItemCode !== null) {
						setIsPrevBtn(false);
					}
				}
			}
		}
	}, [calendarDates, calendarStartDate, calendarEndDate])

	useEffect(() => {
		if (resultSet) {
			Object.keys(resultSet).forEach(date => {
				const response = resultSet[date]
				if (!isNextBtn && !isPrevBtn) {
					return
				}
				if (response && !response.isLoading && response.error && response.error.description
					&& (response.error.description.toUpperCase().indexOf("SPOT RATE IS NOT APPLICABLE") !== -1)) {
					if (moment(date, 'DD-MMM-YYYY').isAfter(moment(searchFormData.shippingDate, 'DD-MMM-YYYY'))) {
						setIsNextBtn(false)
					} else {
						setIsPrevBtn(false)
					}
				}
			})
		}
	}, [resultSet])

	return (
		<div className='price-calendar col-12'>
			<div className='price-calendar__nav-btn'>
				<button
					className='prev-btn'
					onClick={() => {
						increaseSelectionStartDate(-1)
					}}
					disabled={!isPrevBtn}
				>
					<img src={leftArrow} alt='leftArrow' title='Previous' />
				</button>
			</div>
			<div className='price-calendar__details'>
				{calendarDates && calendarDates.length ? (
					calendarDates.map((date, index) => {
						return (
							<Suspense fallback={<></>}>
								<DateElement
									key={index}
									customKey={index}
									isPrevBtn={isPrevBtn}
									isNextBtn={isNextBtn}
									calendarLength={calendarDates.length}
									date={date}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									increaseSelectionStartDate={increaseSelectionStartDate}
								/>
							</Suspense>
						)
					})
				) : (
					<></>
				)}
			</div>
			<div className='price-calendar__nav-btn'>
				<button
					className='next-btn'
					onClick={() => {
						increaseSelectionStartDate(1)
					}}
					disabled={!isNextBtn}
				>
					<img src={rightArrow} alt='rightArrow' title='Next' />
				</button>
			</div>
		</div>
	)
}

export default PriceCalendar
