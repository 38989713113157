import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import serchIcon from '../../assets/images/header-search-icon.svg'
import menuIcon from '../../assets/images/header-hamburger-icon.svg'
import logo from '../../assets/images/logo-etihadcargo.png'
import envConfigs from '../../configs/envConfigs'
import { getOktaSession } from '../../services/authService'
import {
	getMenuResponse,
	getMenuResponseFromAPI,
} from '../../services/onLoadServices'
import './header.scss'
import { USER_PRIVLILEGES } from '../../constants'
import { useTranslation } from 'react-i18next'
import MobileMenuModal from './MobileMenuModal'
import { pushEventDashboardHeaders } from '../../utils/analytics'
import { getFromLocalStorage } from '../../utils/StorageUtils'

const PortalHeader = ({ userDetails, ssoRefreshUrl, ssoType }) => {
	const [menuResponse, setMenuResponse] = useState(null)

	const [oktaUrl, setOktaUrl] = useState(null)

	const [displayLoginOverlay, setDisplayLoginOverlay] = useState(false)

	const loginOverlayRef = useRef(null)

	const [isEyAdmin, setIsEyAdmin] = useState(false)
	const [isEnterprise, setIsEnterprise] = useState(false)
	const [isGSA, setIsGSA] = useState(false)
	const [viewCSN, setViewCSN] = useState(false)

	const [showMobileMenu, setShowMobileMenu] = useState(false)
	const [customWidth, setcustomWidth] = useState(window.innerWidth)
	const {
		bookingPortalAppUrl,
		checkSessionInterval,
		cosumerOktaSsoUrl,
		enterpriseOktaSsoUrl,
	} = envConfigs
	const [hideMenu, setHideMenu] = useState(false);

	const { t, i18n } = useTranslation(['labels'])
	useEffect(() => {
		; (async () => {
			getNavigationMenu()
			i18n.on('languageChanged', function (lng) {
				getNavigationMenu()
			})
		})()
	}, [])

	const updateDimensions = () => {
		setcustomWidth(window.innerWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		return () => window.removeEventListener('resize', updateDimensions)
	}, [])

	useEffect(() => {
		setShowMobileMenu(false)
	}, [customWidth])

	useEffect(() => {
		if (userDetails && userDetails.roles && userDetails.roles.length) {
			if (userDetails.roles.includes(USER_PRIVLILEGES.EY_ADMIN_PRIVILEGE)) {
				setIsEyAdmin(true)
			} else {
				setIsEyAdmin(false)
			}
		}
	}, [userDetails])

	useEffect(() => {
		if (userDetails && userDetails.roles && userDetails.roles.length) {
			if (userDetails.roles.includes(USER_PRIVLILEGES.CSN_DASHBOARD_PRIVILEGE)) {
				setViewCSN(true)
			} else {
				setViewCSN(false)
			}
		}
	}, [userDetails])

	useEffect(() => {
		if (userDetails?.type === "enterprise") {
			setIsEnterprise(true)
		}
		else {
			setIsEnterprise(false)
		}
	}, [userDetails])

	useEffect(() => {
		if (userDetails && userDetails.roles && userDetails.roles.length) {
			if (userDetails.roles.includes(USER_PRIVLILEGES.GSA_USER_PRIVILEGE)) {
				setIsGSA(true)
			} else {
				setIsGSA(false)
			}
		}
	}, [userDetails])

	useEffect(() => {
		; (async () => {
			if (ssoRefreshUrl && ssoRefreshUrl !== '') {
				if (checkSessionInterval && checkSessionInterval !== 0) {
					let interval = checkSessionInterval * 60 * 1000
					setInterval(function () {
						keepOktaSessionAlive(ssoRefreshUrl)
					}, interval)
				}
			}
		})()
	}, [ssoRefreshUrl])

	useEffect(() => {
		if (ssoType) {
			if (ssoType === 'E') {
				setOktaUrl(enterpriseOktaSsoUrl)
			} else if (ssoType === 'C') {
				setOktaUrl(cosumerOktaSsoUrl)
			}
		}
	}, [ssoType])

	const getNavigationMenu = async () => {
		let menuResponse = await getMenuResponseFromAPI(i18n.language)
			.then((response) => {
				if (response && response.navigationmenu) {
					setMenuResponse(response)
					return response
				} else {
					return null
				}
			})
			.catch((error) => {
				console.log(error)
				return null
			})
		if (!menuResponse) {
			menuResponse = await getMenuResponse(i18n.language)
				.then((response) => {
					if (response && response.navigationmenu) {
						setMenuResponse(response)
						return response
					} else {
						return null
					}
				})
				.catch((error) => {
					console.log(error)
					return null
				})
		}
	}
	//console.log('menu response',menuResponse)
	const keepOktaSessionAlive = async (url) => {
		if (url && url !== '') {
			console.log('Calling okta session url')
			try {
				const status = await getOktaSession(url)
			} catch (err) {
				console.log(err)
			}
		}
	}

	/**
	 * Below useEffect is to handle click outside loginOverlay
	 */
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				loginOverlayRef.current &&
				!loginOverlayRef.current.contains(event.target)
			) {
				setDisplayLoginOverlay(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [loginOverlayRef])

	const getAvatarLetter = (name) => {
		if (name && name.trim !== '') {
			return name.charAt(0)
		}
	}

	const [hoveredMenu, setHoveredMenu] = useState(null)

	const handleMouseEnter = (title) => {
		setHoveredMenu(title)
	}

	const handleMouseEnterOther = (e) => {
		setHoveredMenu(null)
	}

	const handleMouseLeave = (e) => {
		setHoveredMenu(null)
	}

	const handleClose = () => {
		setDisplayLoginOverlay(false)
	}

	useEffect(() => {
		let data = JSON.parse(getFromLocalStorage('subject'))
		if (data && data.roles.includes("CSN_USER") && data.roles.length === 1) {
			setHideMenu(true);
		}
	}, [])
	const getSubmenuEServices = ({ categories, navigationsubmenu }) => {
		return (
			<div className='header-navigation-l2-component-container d-none d-xl-flex'>
				<div className='header-navigation-l2-component-subhead'>
					{categories && categories.length ? (
						categories.map((category) => (
							<div
								className='header-navigation-l2-component-item-con-wrapper'
								onMouseLeave={(e) => {
									handleMouseLeave(e)
								}}
							>
								<div className='icon-wrapper'>
									{category.categoryicon ? (
										<img
											src={category.categoryicon}
											alt={category.categorytitle}
											className=''
										/>
									) : (
										<></>
									)}
								</div>
								<div className='category-wrapper'>
									<h2
										className='category-title-header'
										id='category-title-header~eservices'
									>
										{category.categorytitle}
									</h2>
									<ul
										role='menu'
										aria-label={category.categorytitle}
										className='category-list-item'
									>
										{navigationsubmenu && navigationsubmenu.length ? (
											navigationsubmenu.map((submenu) => {
												return submenu.categorytype === category.categorykey ? (
													<li
														role='none'
														className='header-navigation-l2-component-item hover-out-gold'
													>
														{(submenu.externalPortalLink &&
															submenu.externalPortalLink !== '') ||
															(submenu.redirecturl &&
																submenu.redirecturl.startsWith(
																	bookingPortalAppUrl
																)) ? (
															<a
																href={
																	submenu.externalPortalLink &&
																		submenu.externalPortalLink !== ''
																		? submenu.externalPortalLink.replace(
																			bookingPortalAppUrl,
																			''
																		)
																		: submenu.redirecturl.replace(
																			bookingPortalAppUrl,
																			''
																		)
																}
																target={submenu.newwindow}
																className='header-nav-l2-img-container'
																role='menuitem'
																aria-describedby='category-title-header~eservices'
															>
																{submenu.title}
															</a>
														) : (
															<a
																href={
																	oktaUrl
																		? `${oktaUrl}?RelayState=${encodeURIComponent(
																			submenu.redirecturl
																		)}`
																		: submenu.redirecturl
																}
																target={submenu.newwindow}
																className='header-nav-l2-img-container'
																role='menuitem'
																aria-describedby='category-title-header~eservices'
															>
																{submenu.title}
															</a>
														)}
													</li>
												) : (
													<></>
												)
											})
										) : (
											<></>
										)}
									</ul>
								</div>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>
		)
	}

	if (!menuResponse) return <></>
	return (
		<>
			<div className='cargo-portal-container portal-header'>
				<header>
					<div className='header-comp'>
						<div className='header-primary'>
							<div className='header-img-class-2 header-white'></div>
							<div className='header-navbar-container'>
								<div className='header-navbar-left'>
									<div className='header-navbar-left-items'>
										<div className='navbar-header'>
											<span className='header-navbar-brand navbar-brand'>
												<a
													href='https://www.etihadcargo.com/en/'
													target='_self'
													className='header-logo'
												></a>
												<div className='header-text-logo'>
													<a
														href='https://www.etihadcargo.com/en/'
														target='_self'
													>
														<img
															src={logo}
															alt='Etihad Choose Well'
															className='header-text-logo-mobile'
														/>
														<img
															src={logo}
															alt='Etihad Choose Well'
															className='header-text-logo-web'
														/>
														<img
															src={logo}
															alt=''
															className='header-text-logo-web-scroll'
														/>
													</a>
												</div>
											</span>
										</div>
									</div>
								</div>
								<div className='header-navbar-right'>
									<div className='header-navbar-right-flex d-none d-xl-flex'>
										<nav
											className='header-navbar-main-container'
											aria-label='Primary'
										>
											<ul role='menubar' className='header-rightele'>

												{
													hideMenu ? <></> :
														menuResponse.navigationmenu &&
															menuResponse.navigationmenu.navigation &&
															menuResponse.navigationmenu.navigation.length ? (
															menuResponse.navigationmenu.navigation.map((item) => (
																<li
																	aria-haspopup='true'
																	aria-expanded='false'
																	role='menuitem'
																	className='navigation-web-main-head hover-out-gold'
																	onMouseEnter={() => {
																		handleMouseEnter(item.title)
																	}}
																	onMouseLeave={handleMouseLeave}
																>
																	{item.redirecturl &&
																		item.redirecturl.startsWith(
																			bookingPortalAppUrl
																		) ? (
																		<Link
																			to={item.redirecturl.replace(
																				bookingPortalAppUrl,
																				''
																			)}

																			aria-expanded='true'
																			aria-haspopup='true'
																			className='navigation-web-main-head-ele'
																			onClick={() => {
																				console.log('inside link tag', item.title)
																				pushEventDashboardHeaders(item.title)
																			}}
																		>
																			{item.title}
																		</Link>
																	) : (
																		<a
																			/* href={
																				oktaUrl
																					? `${oktaUrl}?RelayState=${encodeURIComponent(
																							item.redirecturl
																					  )}`
																					: item.redirecturl
																			} */
																			href="javascript: void(0);"
																			//href="#"
																			target={item.newwindow}
																			aria-expanded='true'
																			aria-haspopup='true'
																			className='navigation-web-main-head-ele'
																			onClick={() => {
																				pushEventDashboardHeaders(item.title)
																				window.location.href =
																					oktaUrl
																						? `${oktaUrl}?RelayState=${encodeURIComponent(
																							item.redirecturl
																						)}`
																						: item.redirecturl
																				//	console.log('inside anchor tag',item.title)
																			}}
																		>
																			{item.title}
																		</a>
																	)}
																	{item.title === hoveredMenu &&
																		item.categories &&
																		item.categories.length ? (
																		getSubmenuEServices(item)
																	) : (
																		<></>
																	)}
																</li>
															))
														) : (
															<></>
														)}

											</ul>
										</nav>
									</div>
								</div>
								<ul
									className='header-navitem-right-icons'
									onMouseEnter={handleMouseEnterOther}
								>

									{!hideMenu ? (
										<li
											role='presentation'
											className='header-navitem-right-2 hover-out-gold'
										>
											<a href='https://www.etihadcargo.com/en/search'>
												<img src={serchIcon} alt='Search Icon' className='' />
											</a>
										</li>
									) : (null)}

									<li
										role='presentation'
										className={`header-navitem-right-2 ${displayLoginOverlay ? 'loggedIn-widget-open' : ''
											}`}
									>
										{userDetails ? (
											<a
												className='login-icon-button'
												onClick={(e) => {
													setDisplayLoginOverlay(!displayLoginOverlay)
												}}
											>
												<div className='login-section login-icon-section'>
													<span className='sr-only header-little-man-icon'>
														logged in state expanded
													</span>
													<span className='firstname-initial' tabindex='-1'>
														{getAvatarLetter(userDetails.firstName)}
													</span>
												</div>
												{userDetails && (
													<div className='login-section login-text-section d-none d-xl-flex'>
														<span className='login-status-text firstname-text'>
															{`${userDetails.firstName ? userDetails.firstName : ''} ${userDetails.lastName ? userDetails.lastName : ''}`}
														</span>
													</div>
												)}
												{displayLoginOverlay ? (
													<div
														className='user-profile-menu'
														ref={loginOverlayRef}
													>
														{menuResponse.loggedIn && (
															<div className='user-profile-menu__inner-wrapper row'>
																<div
																	id='welcome-wrapper'
																	className='col-12 mb-3'
																>
																	<div className='row'>
																		<i
																			className='col-12 text-right'
																			onClick={handleClose}
																		>
																			<img
																				src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjUgOC41TDEgMUw4LjUgOC41TDEgMTZMOC41IDguNVpNOC41IDguNUwxNiAxTDguNSA4LjVMMTYgMTZMOC41IDguNVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik04LjUgOC41TDEgMUw4LjUgOC41Wk04LjUgOC41TDEgMTZMOC41IDguNVpNOC41IDguNUwxNiAxTDguNSA4LjVaTTguNSA4LjVMMTYgMTZMOC41IDguNVoiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K'
																				alt=''
																				className=''
																			/>
																		</i>

																		{menuResponse.loggedIn.welcomeBackText && (
																			<h3 className='col-12 mt-0'>
																				{menuResponse.loggedIn.welcomeBackText}
																			</h3>
																		)}

																		<div className='col-12 user-name'>
																			{`${userDetails.firstName ? userDetails.firstName : ''} ${userDetails.lastName ? userDetails.lastName : ''}`}
																		</div>
																	</div>
																</div>
																{/*  */}
																<div className='button-group'>
																	<div className='col-12 '>

																		{menuResponse.loggedIn.profileBtnText
																			// && data?roles.includes("CSN_USER")
																			//   && data.length ===1 
																			&& !hideMenu ? (
																			<div className='row mb-3'>
																				<a
																					href='/user-management'
																					target='_self'
																					className='col-12 button secondary'
																				>
																					{menuResponse.loggedIn.profileBtnText}
																				</a>
																			</div>
																		) : (null)

																		}
																		{!isEnterprise && (
																			<div className='row mb-3'>
																				{menuResponse.loggedIn.changePassTextLink && !hideMenu ? (
																					<a
																						href={
																							menuResponse.loggedIn
																								.changePassTextLink
																						}
																						target='_blank'
																						className='col-12 button secondary'
																						rel='noreferrer'
																					>
																						{t('HEADER.CHANGE_PSWD')}
																					</a>) : (null)
																				}
																			</div>
																		)}

																		<div className='row mb-3'>

																			{menuResponse.loggedIn.cargoWebsiteLink && !hideMenu ? (
																				<a
																					href={
																						menuResponse.loggedIn.cargoWebsiteLink
																					}
																					target='_self'
																					className='col-12 button secondary'
																				>
																					{t('HEADER.SWITCH_CLASSIC')}
																				</a>) : (null)
																			}


																		</div>

																		{viewCSN && (
																			<div className='row mb-3'>
																				<a
																					href='/csn-dashboard'
																					target='_self'
																					className='col-12 button secondary'
																				>
																					{t('HEADER.VIEW_CSN')}
																				</a>
																			</div>
																		)}

																		{isEyAdmin && (
																			<div className='row mb-3'>
																				<a
																					href='/admin-master'
																					target='_self'
																					className='col-12 button secondary'
																				>
																					{`Admin`}
																				</a>
																			</div>
																		)}

																		<div className='row mb-3'>

																			{!hideMenu ? (
																				<a
																					href={(isEnterprise || isGSA) ? process.env.REACT_APP_ENV === 'stage' ||  process.env.REACT_APP_ENV === 'dev' ? 'https://test.etihadcargo.com/en/secured/dashboard' : 'https://etihadcargo.com/en/secured/dashboard' : '/dashboard'}
																					target='_self'
																					className='col-12 button primary-btn'
																				>
																					{t('HEADER.VIEW_DASH')}
																				</a>
																			) : (null)}

																		</div>
																	</div>
																</div>

																<div className='log-out-btn col-6'>
																	<a href='/logout' className='link'>
																		{menuResponse.loggedIn.logoutText}
																		<img
																			src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDcgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQ0Njc4IDEwLjk0Nkw2LjMzNTY3IDUuODQ1N0wxLjQ0Njc4IDAuNzQ0MTQxIiBzdHJva2U9IiMwMDVGOTYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K'
																			alt=''
																			className='icon'
																		></img>
																	</a>
																</div>
															</div>
														)}
													</div>
												) : (
													<></>
												)}
											</a>
										) : (
											<a className='d-flex' href='/login' target='_self'>
												<div className='icon-wrapper'>
													{menuResponse.loggedIn ? (
														<img
															src={menuResponse.loggedIn.loggedInIcon}
															alt=''
															className=''
														/>
													) : (
														<></>
													)}
												</div>
												<div className='login-section login-text-section'>
													<span className='login-status-text '>Log in</span>
												</div>
											</a>
										)}
									</li>
									<li
										role='presentation'
										className='header-navitem-right-3 d-flex d-xl-none'
									>
										<a
											role='button'
											onClick={(e) => {
												setShowMobileMenu(true)
											}}
										>
											<img src={menuIcon} alt='Navigation Menu' />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{showMobileMenu && menuResponse &&
						<MobileMenuModal
							setShowMobileMenu={setShowMobileMenu}
							menuResponse={menuResponse}
							oktaUrl={oktaUrl}
						/>
					}
				</header>
			</div>
		</>
	)
}

export default PortalHeader
