import { SAVE_BOOKING } from '../constants'

export const requestSaveBooking = (
	awbNumber,
	spotRatePayload,
	searchFormData,
	selectedFlight
) => ({
	type: SAVE_BOOKING.LOAD,
	awbNumber,
	spotRatePayload,
	searchFormData,
	selectedFlight,
})

export const saveBookingResponse = (response) => ({
	type: SAVE_BOOKING.SUCCESS,
	response,
})

export const saveBookingError = (error) => ({
	type: SAVE_BOOKING.ERROR,
	error,
})
