import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const getUserProfile = async ({ userCode }) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getUserDetailsUrl}?userCode=${userCode}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}
export const getUserIdByAgentCode = async (reqObj) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getUserIdByAgentCode}`
	const response = await portalAuthHttpClient.post(url,reqObj)
	return response.data
}

export const createUser = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.createUserUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const updateUser = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.updateUserUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getRegionByAirportCode = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getRegionByAirportCode}/${code}`
	//const url = `http://13.126.80.20/api/api/masterdata/getregionbyairportcode/${code}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}
