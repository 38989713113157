import moment from 'moment'
import React, { useEffect, useState } from 'react'
import flightIcon from '../../../../assets/images/flight.svg'
import siteLogo from '../../../../assets/images/logo.png'
import './index.scss'
import { getWeightUnit } from '../../../../constants'
import { getShippingItems } from '../../../../services/searchPanelServices'
import { populateBookingDetails } from '../../../SearchPanel/templateUtils'
import {
	setToSessionStorage,
	getBase64Encoded,
	clearItemFromSessionStorage,
} from '../../../../utils/StorageUtils'
import { validateBooking } from './../../../../services/quoteServices'
import { useHistory } from 'react-router-dom'
import RouteDetails from '../PrintBooking/RouteDetails'
import MESSAGES from '../../../../constants/Messages'
import { useTranslation } from 'react-i18next'
import editIcon from '../../../../assets/images/edit.png'
const FlightDetails = ({
	bookingDetails,
	bookingResponse,
	setIsLoading,
	awbNo,
	setIsPastBooking,
	setPastBookingMessage,
}) => {

	//for multilingual
	const [t] = useTranslation(['labels', 'errors'])

	const [routes, setRoutes] = useState('')
	const [flyingHrs, setFlyingHrs] = useState('')
	const [departureDateTime, setDepartureDateTime] = useState(null)
	const [arrivalDateTime, setArrivalDateTime] = useState(null)
	const [rateDetails, setRateDetails] = useState([])

	const history = useHistory()

	const getFormattedTtime = (dateToChange) => {
		return moment(dateToChange).format('HH:mm')
	}

	useEffect(() => {
		if (bookingDetails && bookingDetails.bookingFlightDetails)
			getFlightTime(bookingDetails)
		if (bookingDetails && bookingDetails.chargeDetails) {
			setRateDetails(bookingDetails.chargeDetails)
		}
		if (
			bookingDetails.bookingFlightDetails &&
			bookingDetails.bookingFlightDetails.length > 0
		) {
			setRoutes(bookingDetails.bookingFlightDetails.length - 1)
		}
	}, [bookingDetails])

	const getFlightTime = (bookingDetails) => {
		const length = bookingDetails.bookingFlightDetails.length

		setDepartureDateTime(
			moment(bookingDetails.bookingFlightDetails[0].segmentDepartureDate)
		)
		setArrivalDateTime(
			moment(bookingDetails.bookingFlightDetails[length - 1].segmentArrivalDate)
		)
	}

	const handleModifyFlight = async () => {
		const status = checkPastBooking(bookingDetails.shippingDate)
		if (status) {
			setIsPastBooking(status)
			setPastBookingMessage(MESSAGES.ERR_MODIFY_PAST_BOOKING)
			window.scrollTo(0, 0)
		} else {
			clearItemFromSessionStorage('awbNumber')
			clearItemFromSessionStorage('flightSelected')
			clearItemFromSessionStorage('bookingDetails')
			clearItemFromSessionStorage('searchObject')

			//getBookingData()
			// To disable modify booking for spot rate attached bookings.
			if (bookingDetails?.chargeDetails?.spotRate && bookingDetails?.chargeDetails?.spotRateCode) {
				setIsPastBooking(true)
				setPastBookingMessage(MESSAGES.ERR_MODIFY_BOOKING)
				window.scrollTo(0, 0)
			} else {
				getBookingData()
			}
		}
	}

	const validateBookingDetails = async (bookingData, awbNumber) => {
		const validateBookingResponse = await validateBooking(
			bookingData,
			null,
			awbNumber
		)
			.then((response) => {
				if (response && response.bookingDetails) {
					return response.bookingDetails
				} else if (
					response &&
					response.errorDetails &&
					response.errorDetails.length > 0
				) {
					return response.errorDetails[0]
				} else {
					return null
				}
			})
			.catch((error) => {
				console.log(error)
				setIsLoading(false)
				return null
			})
		return validateBookingResponse
	}

	const getBookingData = async () => {
		setIsLoading(true)
		let shippingItemsList = await getShippingItems()
		if (shippingItemsList) {
			shippingItemsList = shippingItemsList.sort((a, b) => {
				if (a.description < b.description) {
					return -1
				}
				if (a.description > b.description) {
					return 1
				}
				return 0
			})
		}

		if (shippingItemsList && bookingResponse) {
			const data = await populateBookingDetails(
				bookingResponse,
				shippingItemsList,
				true,
				setIsLoading
			)
			if (data) {
				data.isModifyBooking = true
				data.awbNo = awbNo
				data.densityInSelectedUnit = bookingDetails.bookingCargoDetails.density

				setToSessionStorage('searchObject', getBase64Encoded(data))
				if (data.additionalInfo) {
					setToSessionStorage(
						'additionalFormData',
						getBase64Encoded(data.additionalInfo)
					)
				}
				history.push(`/booking/flightlist`)

				/*const validateBookingResponse = await validateBookingDetails(
					data,
					awbNo.trim()
				)
				if (
					validateBookingResponse &&
					!validateBookingResponse.errorDescription
				) {
					setToSessionStorage('searchObject', getBase64Encoded(data))
					if (data.additionalInfo) {
						setToSessionStorage(
							'additionalFormData',
							getBase64Encoded(data.additionalInfo)
						)
					}
					history.push(`/booking/flightlist`)
				} else {
					history.push({
						pathname: '/manage-booking/list',
						state: {
							modifyBooking: {
								status: false,
								awbNo: awbNo,
								message:
									validateBookingResponse &&
									validateBookingResponse.errorDescription
										? validateBookingResponse.errorDescription
										: '',
							},
						},
					})
				}*/
			}
		}
		setIsLoading(false)
	}

	const checkPastBooking = (shippingDate) => {
		const today = moment(new Date(),'ddd DD MMM')
		return shippingDate && moment(shippingDate ,'ddd DD MMM').isSameOrBefore(today)
	}

	return (
		<div className='flight-detail-wrapper mt-4 mb-3'>
			<div className='form-row custm_mb_view_booking'>
				<div className='col-8 pb-lg-2 pb-4'>
					<h3 className='page-sub-head'>{t('VIEWBKG.FLIGHT_DTS')}</h3>
				</div>
				{bookingDetails.bookingStatus &&
					bookingDetails.bookingStatus !== 'X' && (
						<div className='col-4 link-align-center'>
						<span
							className='link d-none d-lg-inline'
							onClick={handleModifyFlight}
						>
								{t('COMMON.MODIFY')}
						</span>
						<span
							className='primary-details-wrapper_modify-mobile d-lg-none d-flex'
							onClick={handleModifyFlight}
						>
								<img src={editIcon} alt='modify-mobile' title='modify-mobile' />
							</span>
						</div>
					)}
			</div>

			<div className='flight-detail-inner-wrapper '>
				<RouteDetails bookingDetails={bookingDetails} viewFlag={false} />
			</div>

			<div></div>
		</div>
	)
}
export default FlightDetails
