import React from 'react'
import './loose-line-item.scss'
import { withTranslation } from 'react-i18next'
class ExportToExcel extends React.Component {

	render() {
		const { t } = this.props
		return (
			<a
				className='excel_Download_button'
				href={this.props.pageName && this.props.pageName === 'CAPTURE' ? (process.env.PUBLIC_URL + '/TemplateToUpload-data-capture.xlsx') : (process.env.PUBLIC_URL + '/TemplateToUpload.xlsx') }
				role='button'
				target="_blank"
				style={{ textDecoration: 'underline' }}
			>
				{t('BOOKING.DOWNLOAD_TMP')}
			</a>
		)
	}
}

export default withTranslation()(ExportToExcel)