import React from 'react'
import { connect } from 'react-redux'
import BookingConfirmation from './BookingConfirmation'
import { setSearchFormData } from '../../redux/actions/searchFormActions'
import { requestSaveBooking } from '../../redux/actions/saveBookingActions'
import { showPopup } from '../../redux/actions/popupActions'
import { setFlight } from '../../redux/actions/flightActions'

const BookingConfirmationContainer = ({
	setSearchFormDataToState,
	setSelectedFlightToState,
	getSaveBookingResponse,
	validateBookingResponse,
	saveBookingResponse,
	saveBookingError,
	showErrorPopup,
}) => {
	return (
		<BookingConfirmation
			setSearchFormDataToState={setSearchFormDataToState}
			setSelectedFlightToState={setSelectedFlightToState}
			getSaveBookingResponse={getSaveBookingResponse}
			validateBookingResponse={validateBookingResponse}
			saveBookingResponse={saveBookingResponse}
			saveBookingError={saveBookingError}
			showErrorPopup={showErrorPopup}
		/>
	)
}

const mapStateToProps = ({ saveBooking, flight, validateBooking }) => ({
	saveBookingResponse: saveBooking.response,
	saveBookingError: saveBooking.error,
	validateBookingResponse: validateBooking.response,
})
const mapDispatchToProps = (dispatch) => ({
	setSearchFormDataToState: (searchFormData) => {
		dispatch(setSearchFormData(searchFormData))
	},
	setSelectedFlightToState: (flightSelected) => {
		dispatch(setFlight(flightSelected))
	},
	getSaveBookingResponse: (awbNumber, spotRatePayload, searchFormData, selectedFlight) => {
		dispatch(requestSaveBooking(awbNumber, spotRatePayload, searchFormData, selectedFlight))
	},
	showErrorPopup: (
		header,
		message,
		primaryBtnLbl,
		acceptFn,
		secondaryBtnLbl,
		rejectFn
	) => {
		dispatch(
			showPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn
			)
		)
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BookingConfirmationContainer)
