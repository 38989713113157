import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { allowNumberOnly } from '../../../utils/common';
import trashIcon from '../../../assets/images/trash.svg';
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
	getFromLocalStorage,
	setToLocalStorage,
} from '../../../utils/StorageUtils';
import {
	validateFieldValue,
} from '../../SearchPanel/formUtils';
import MultiplePetItem from './multiplePets';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import {
	AUTOCOMPLETE_TYPE,
	DIMENSION_UNIT,
	FORM_FIELDS,
	FRESH_FORWARD_PRODUCT,
	getInitLooseItemDetail,
	getInitPharmaObject,
	getInitULDItemDetail,
	PHARMA_PRODUCT,
	PHARMA_SHIPPING_TYPE,
	IS_KNOWN_UNKNOWN,
	PRICE_CODE_TYPE,
	SCC_CODES,
	SHIPPING_TYPE,
	VALIDATE_BOOKINGS_ERROR_CODES,
	WEIGHT_UNIT,
	NOTIFICATION_MESSAGE_TYPE,
	SYSTEM_ADDED_SCCS,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
	getInitPetDetail,
	WEIGHT_UNIT_SELECT_ITEMS,
	DIMENSION_UNIT_SELECT_ITEMS,
} from '../../../constants'
import MESSAGES from '../../../constants/Messages'

const Cages = ({
	shippingItem,
	// petList,
	// setPetList,
	error,
	setError,
	file,
	setFile,
	dogBreeds,
	searchFormData,
	setArrayListDoc,
	arrayListDoc,
	petOrder,
	setCageList,
	cageList,
	//cageIndex,
	//handleCageItemChange,
	//item,
	setDeletedCageIds,
	deletedCageIds,
	setDeletedPetIds,
	deletedPetIds,
	weightUnit,
	dimensionUnit,
	setDimensionUnit,
	setWeightUnit,
	breedRejected,
	setBreedRejected,
}) => {

	const { t, i18n } = useTranslation(['labels', 'errors'])
	const [alertProps, setAlertProps] = useState({})
	const [stubNose, setStubNose] = useState('')
	const [weight, setWeight] = useState()
	const [length, setLength] = useState()
	const [width, setWidth] = useState()
	const [height, setHeight] = useState()
	//const [weightUnit, setWeightUnit] = useState('K');
	const [measurement, setMeasurement] = useState('C');
	const [pieces, setPieces] = useState(1);
	const [id, setId] = useState(0);
	const [cageOrder, setCageOrder] = useState();
	const [sccWarning, setSccWarning] = useState(null);
	// const [pets, setPetList] = useState(

	// 		[getInitPetDetail()]
	// )



	// useEffect(() => {
	// 	console.log("use-effect : handleCageItemChange");
	// 	handleCageItemChange(cageIndex, {
	// 		weight,
	// 		length,
	// 		width,
	// 		height,
	// 		weightUnit,
	// 		measurement,
	// 		pieces,
	// 		pets,
	// 		id,
	// 		cageOrder,
	// 	})
	// }, [
	// 	weight,
	// 	length,
	// 	width,
	// 	height,
	// 	weightUnit,
	// 	measurement,
	// 	pieces,
	// 	pets,
	// 	id,
	// ])

	const handleErrorFields = (key, errorCode) => {
		const tempError = error ? { ...error } : null
		if (tempError && tempError[key]) {
			let errorArr = tempError[key] ? [...tempError[key]] : []
			let filteredArr = errorArr.map((item) => item ? item : null)
			if (!filteredArr.includes(errorCode)) {
				tempError[key] = null
				setError(tempError)
			}
		}
	}

	useEffect(() => {
		//handleErrorFields(FORM_FIELDS.CRATE_TOTAL_WEIGHT, 'ER245')
		//handleErrorFields(FORM_FIELDS.CRATE_LENGTH, 'ER246')
		//handleErrorFields(FORM_FIELDS.CRATE_WIDTH, 'ER247')
		//handleErrorFields(FORM_FIELDS.CRATE_HEIGHT, 'ER248')
	}, [error])

	const clearError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.CAGE_ITEMS] &&
			tempError[FORM_FIELDS.CAGE_ITEMS][index]
		) {
			tempError[FORM_FIELDS.CAGE_ITEMS][index][key] = null
			setError(tempError)
		}
	}

	const handleError = (key, errorMsg, index) => {
		const tempError = error ? { ...error } : {}
		let cageErrors =
			error && error[FORM_FIELDS.CAGE_ITEMS]
				? error[FORM_FIELDS.CAGE_ITEMS]
				: []
		if (cageErrors) {
			cageErrors[index] = cageErrors[index]
				? { ...cageErrors[index], [key]: errorMsg }
				: { [key]: errorMsg }
			setError({ ...error, [FORM_FIELDS.CAGE_ITEMS]: cageErrors })
		}
	}



	const handleCageItemChange = (key, item, index) => {
		const cageListJson = getFromSessionStorage('cageList')

		if (cageListJson) {
			const cageListData = JSON.parse(cageListJson)
			cageListData[index][key] = item
			setCageList([...cageListData])
		}
	}

	const setPetList = (petsListNew, index) => {
		handleCageItemChange('pets', petsListNew, index)
	}



	const handleRemoveItem = (indexPassed, item) => {
		if (item.id) {
			let idObj = {
				'id': indexPassed
			}
			setDeletedCageIds([...deletedCageIds, idObj]);
		}

		const itemsTemp = [...cageList].filter((itemDetail, curr) => {
			return item.id ? itemDetail.id !== indexPassed : indexPassed !== curr
		})
		setCageList([...itemsTemp])
	}


	return (
		<>
			{
				cageList.map((item, cageIndex) => {
					const lengthError =
						error &&
							error[FORM_FIELDS.CAGE_ITEMS] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_LENGTH]
							? error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_LENGTH]
							: null
					const widthError =
						error &&
							error[FORM_FIELDS.CAGE_ITEMS] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_WIDTH]
							? error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_WIDTH]
							: null
					const heightError =
						error &&
							error[FORM_FIELDS.CAGE_ITEMS] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_HEIGHT]
							? error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_HEIGHT]
							: null
					const weightError =
						error &&
							error[FORM_FIELDS.CAGE_ITEMS] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex] &&
							error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_TOTAL_WEIGHT]
							? error[FORM_FIELDS.CAGE_ITEMS][cageIndex][FORM_FIELDS.CRATE_TOTAL_WEIGHT]
							: null
					return (
						<div
							className={'item-group search-panel__data uld-form pl-3 mt-4'}>
							<label className="cage-style">{
						 		`${t('COMMON.CONTAINER_COUNT', {
									index: cageIndex + 1,
								})}`
								}
							</label>

							{cageList.length > 1 ? (
								<div className='form-group col-4 col-lg-1 delete-div'>
									<div className='uld-form delete-section'>
										<img
											src={trashIcon}
											onClick={(e) => handleRemoveItem(item.id ? item.id : cageIndex, item)}
											alt='Trash'
											title='Trash'
										/>
										<span>{t('COMMON.DELETE')}</span>
									</div>
								</div>
							) : (
								<></>
							)}
							{/* <div className='form-row pl-2 m-b-0 mt-0'> */}
							<div className='form-row'>
								<div
									className={`col-12 col-lg-4 form-group`}
								>

									<label>{t('COMMON.TOTAL_WT')}</label>
									<div className='form-row'>
										<div
											className={`col-8 col-md-10 col-lg-8 ${weightError ? 'red-border' : ''
												}`}
										>
											<input
												type='text'
												value={item.weight <= 0 ? "" : item.weight}
												onKeyDown={(evt) => allowNumberOnly(evt)}
												className='form-control'
												//value={}
												//value={totalWeight}
												//onKeyDown={(evt) => allowNumberOnly(evt)}

												onChange={(e) => {

													if (
														e.target.value.length <= 9 &&
														//e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
														(!e.target.value.split('.')[1] ||
															!e.target.value.split('.')[1].length ||
															e.target.value.split('.')[1].length < 3) &&
														e.target.value.split('.').length <= 2
													)

														setWeight(Number(e.target.value))
													handleCageItemChange('weight', Number(e.target.value), cageIndex)
												}}
												onFocus={(e) => {
													clearError(FORM_FIELDS.CRATE_TOTAL_WEIGHT, cageIndex)
												}}
												onBlur={(e) => {
													//error[FORM_FIELDS.CRATE_TOTAL_WEIGHT] = validateFieldValue(e.target.value)
													let errorMsg = validateFieldValue(e.target.value, t(MESSAGES.VALID_TOTAL_WEIGHT))
													if (errorMsg) {
														handleError([FORM_FIELDS.CRATE_TOTAL_WEIGHT], errorMsg, cageIndex)
													}
												}}
											/>
										</div>
										<div className='col-4 col-md-2 col-lg-4 form-group dropdown_div m-b-0'>
											<Dropdown
												id="weight"
												disabled={cageIndex > 0 ? true : false}
												className='form-control cust-dropdown'
												value={weightUnit}
												options={WEIGHT_UNIT_SELECT_ITEMS}
												onChange={async (e) => {
													// setDisableButton && setDisableButton(false)
													setToLocalStorage("weight", e.target.value)
													setWeightUnit(e.target.value);
													handleCageItemChange('weightUnit', e.target.value, cageIndex)
													// draftCallback(true)
												}}
											/>
										</div>
									</div>
									<div>
										<span className='search-panel__errormsg'>
											{weightError ? t(weightError, { ns: 'errors' }) : ''}
										</span>
									</div>	
									<div className='form-row'>
										<div className='col-12 form-group'>
											<label className="cageweight">{
												`${t('COMMON.CONTAINER_WEIGHT_LABEL', {
													index: cageIndex + 1,
												})}`
											}</label>
										</div>
									</div>						
									{/* <div className='form-group borderRadius-L0 border-left0 dropdown_div m-b-0  '> */}
								</div>
								<div
									className={`col-12 col-lg-6 form-group`}
								>
									<div className="form-row">
										<div
											className={`col-12 col-lg-3 form-group ${lengthError ? 'red-border' : ''
												}`}
										>

											<label>{t('COMMON.LN')}</label>

											<div className='input-wrapper'>
												<input
													type='text'
													// placeholder={"Name"}
													className={`form-control form-item__field-draft name`}
													value={item.length === 0 ? '' : item.length}
													onKeyDown={(evt) => allowNumberOnly(evt)}
													onChange={(e) => {
														const value = e.target.value.replace(/[^0-9.]/g, '');
														if (value.length <= 9 && value >= 0) {
															setLength(Number(value))
															handleCageItemChange('length', Number(value), cageIndex)
														}
													}}
													maxLength="8"
													onFocus={(e) => {
														clearError(FORM_FIELDS.CRATE_LENGTH, cageIndex)
													}}
													// onFocus={(e) => clearError(FORM_FIELDS.TOTAL_WEIGHT)}
													onBlur={(e) => {
														//error[FORM_FIELDS.CRATE_TOTAL_WEIGHT] = validateFieldValue(e.target.value)
														let errorMsg = validateFieldValue(e.target.value, t(MESSAGES.VALID_LENGTH))
														if (errorMsg) {
															handleError([FORM_FIELDS.CRATE_LENGTH], errorMsg, cageIndex)
														}
													}}
												/>
											</div>
											<div>
												<span className='search-panel__errormsg'>
													{lengthError ? t(lengthError, { ns: 'errors' }) : ''}
												</span>
											</div>
										</div>
										<div
											className={`col-12 col-lg-3 form-group ${widthError ? 'red-border' : ''
												}`}
										>
											{/* <div style={{ marginLeft: "-15px" }}> */}
											<label>{t('COMMON.WD')}</label>

											<div className='input-wrapper'>
												<input
													type='text'
													// placeholder={"Name"}
													className={`form-control form-item__field-draft name`}
													value={item.width === 0 ? '' : item.width}
													onKeyDown={(evt) => allowNumberOnly(evt)}
													onChange={(e) => {
														const value = e.target.value.replace(/[^0-9.]/g, '');
														if (value.length <= 8) {
															setWidth(Number(value))
															handleCageItemChange('width', Number(value), cageIndex)
														}
													}
													}
													onFocus={(e) => {
														clearError(FORM_FIELDS.CRATE_WIDTH, cageIndex)
													}}
													// onFocus={(e) => clearError(FORM_FIELDS.TOTAL_WEIGHT)}
													onBlur={(e) => {
														//error[FORM_FIELDS.CRATE_TOTAL_WEIGHT] = validateFieldValue(e.target.value)
														let errorMsg = validateFieldValue(e.target.value, t(MESSAGES.VALID_WIDTH))
														if (errorMsg) {
															handleError([FORM_FIELDS.CRATE_WIDTH], errorMsg, cageIndex)
														}
													}}

												/>
											</div>
											<div>
												<span className='search-panel__errormsg'>{sccWarning}</span>
											</div>
											<div>
												<span className='search-panel__errormsg'>
													{widthError ? t(widthError, { ns: 'errors' }) : ''}
												</span>
											</div>
										</div>
										{/* </div> */}
										<div
											className={`col-8 col-md-10 col-lg-3 form-group ${heightError ? 'red-border' : ''
												}`}
										>
											{/* <div className={`form-group col-6 col-lg-2  lengthp`}> */}
											<label>{t('COMMON.HT')}</label>

											<div className='input-wrapper'>
												<input
													type='text'
													// placeholder={"Name"}
													className={`form-control form-item__field-draft name`}
													value={item.height === 0 ? '' : item.height}
													onKeyDown={(evt) => allowNumberOnly(evt)}
													onChange={(e) => {
														const value = e.target.value.replace(/[^0-9.]/g, '');
														if (value.length <= 8) {
															setHeight(Number(value))
															handleCageItemChange('height', Number(value), cageIndex)
														}
													}
													}
													onFocus={(e) => {
														clearError(FORM_FIELDS.CRATE_HEIGHT, cageIndex)
													}}
													// onFocus={(e) => clearError(FORM_FIELDS.TOTAL_WEIGHT)}
													onBlur={(e) => {
														//error[FORM_FIELDS.CRATE_TOTAL_WEIGHT] = validateFieldValue(e.target.value)
														let errorMsg = validateFieldValue(e.target.value, t(MESSAGES.VALID_HEIGHT))
														if (errorMsg) {
															handleError([FORM_FIELDS.CRATE_HEIGHT], errorMsg, cageIndex)
														}
													}}

												/>
											</div>
											<div>
												<span className='search-panel__errormsg'>{sccWarning}</span>
											</div>
											<div>
												<span className='search-panel__errormsg'>
													{heightError ? t(heightError, { ns: 'errors' }) : ''}
												</span>
											</div>
										</div>

										<div className='col-4 col-md-2 col-lg-3'>
											

											<Dropdown
												id="height"
												disabled={cageIndex > 0 ? true : false}
												value={dimensionUnit}
												options={DIMENSION_UNIT_SELECT_ITEMS}
												className={'form-control dimension-length-cage'}
												onChange={async (e) => {
													setToLocalStorage('height', e.target.value)
													setMeasurement(e.target.value)
													setDimensionUnit(e.target.value)
													handleCageItemChange('measurement', e.target.value, cageIndex)

													// setDisableButton && setDisableButton(false)

													// handleItemChange('dimensionUnit', e.target.value, key)
													// draftCallback(true)
												}}


											/>

										</div>

									</div>
									<div className='form-row'>
										<div className='col-12 form-group'>
										<label className="cagedimension">{t('COMMON.CONTAINER_DIMENSION')}</label>
										</div>
									</div>
								</div>

							</div>
							{
								//pets.map((item, index) => (
								<MultiplePetItem
									setPetList={setPetList}
									petList={item.pets}
									// index={index}
									// item={item}
									// handleItemChange={handleItemChange}
									AlertProps={alertProps}
									shippingItem={shippingItem}
									file={file}
									setFile={setFile}
									dogBreeds={dogBreeds}
									//searchFormData={searchFormData.petList[index]}
									setArrayListDoc={setArrayListDoc}
									arrayListDoc={arrayListDoc}
									petOrder={petOrder}
									stubNose={stubNose}
									sccWarning={sccWarning}
									cageOrder={cageOrder}
									cageIndex={cageIndex}
									cageList={cageList}
									setDeletedPetIds={setDeletedPetIds}
									deletedPetIds={deletedPetIds}
									error={error}
									setError={setError}
									clearError={clearError}
									handleError={handleError}
									breedRejected={breedRejected}
									setBreedRejected={setBreedRejected}
								/>
								//))
							}
						</div>
					)
				})}
		</>
	)
}

export default Cages;