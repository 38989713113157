import moment from 'moment'
import React, { useState, useEffect } from 'react'
import './index.scss'
import Messages from '../../../constants/Messages'
import { useTranslation } from 'react-i18next'
const SummaryChart = ({
	period,
	chartData,
	chartDataType,
	isLoading,
	changeToLocalCurrency,
	currency,
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [barChartData, setBarChartData] = useState(null)
	const [currentHeight, setCurrentHeight] = useState(0)
	const [previousHeight, setPreviousHeight] = useState(0)
	const [chartTitle, setChartTitle] = useState(t('DASHBOARD.AWC'))

	const setChartValues = () => {
		if (chartData && chartData.quantityData && chartDataType) {
			if (chartDataType === 'awbCount' && chartData.quantityData.count) {
				getChartDataValues(chartData.quantityData.count)
			} else if (
				chartDataType === 'tonnage' &&
				chartData.quantityData.tonnage
			) {
				getChartDataValues(chartData.quantityData.tonnage)
			} else if (
				chartDataType === 'charges' &&
				changeToLocalCurrency &&
				chartData.quantityData.localCharge
			) {
				getChartDataValues(chartData.quantityData.localCharge)
			} else if (
				chartDataType === 'charges' &&
				chartData.quantityData.charge &&
				!changeToLocalCurrency
			) {
				getChartDataValues(chartData.quantityData.charge)
			}
		}
	}

	const getChartDataValues = (data) => {
		setBarChartData(data)
		let currHeight
		let prevHeight
		if (data.growthPercentage > 0) {
			prevHeight = 100 - data.growthPercentage
			currHeight = 100
			if (prevHeight < 1) {
				prevHeight = 1
			}
		} else if (data.growthPercentage < 0) {
			currHeight = 100 + data.growthPercentage
			prevHeight = 100
			if (currHeight < 1) {
				currHeight = 1
			}
		} else {
			currHeight = 100
			prevHeight = 100
		}
		setPreviousHeight(prevHeight)
		setCurrentHeight(currHeight)
	}

	const getDataValue = (data) => {
		if (chartDataType === 'awbCount') {
			return data ? data : 0
		} else if (chartDataType === 'tonnage') {
			return data ? `${data} Kg` : '0'
		} else if (chartDataType === 'charges') {
			return  currency === 'USD' ? 
			data ? `${currency} ${data.toFixed(2)}` : '0'
			: data? `${currency} ${data}` : '0'
		}
	}

	useEffect(() => {
		if (chartData) {
			setChartValues()
		}
	}, [chartData, changeToLocalCurrency])

	useEffect(() => {
		if (chartDataType === 'awbCount') {
			setChartTitle(t('DASHBOARD.AWC'))
		} else if (chartDataType === 'tonnage') {
			setChartTitle(t('DASHBOARD.TONNAGE'))
		} else if (chartDataType === 'charges') {
			setChartTitle(t('COMMON.CHARGES'))
		}
		setChartValues()
	}, [chartDataType])

	const getBarValuePosition = (current, height) => {
		let position = current && height ? 100 - height : 35
		if (position < 35) {
			position = '35px'
		} else {
			position = `calc(${position}% - 0px)`
		}
		return position
	}

	const getBarValueTopPosition = (barValue, height) => {
		let position = barValue && height ? 100 - height : 0
		if (position > 65 || barValue == 0) {
			return null
		} else {
			return position + '%'
		}
	}

	const getBarValueBottomPosition = (barValue, height) => {
		let position = barValue && height ? 100 - height : 0
		if (position > 65 || barValue == 0) {
			return '15px'
		} else {
			return null
		}
	}
	

	return (
		<div className='chart-component'>
			<h3 className='section-sub-heading mt-0 pl-2 ml-1'>
				{chartTitle && chartTitle !== '' ? chartTitle : t('DASHBOARD.AWC')}
			</h3>
			{barChartData ? (
				<div className='chart-wrapper-bar'>
					<div className='chart-row'>
						<div className='vertical-bar first'>
							<div
								className='bar-value'
								style={{
									top: getBarValueTopPosition(
										barChartData.previous,
										previousHeight
									),
									bottom: getBarValueBottomPosition(
										barChartData.previous,
										previousHeight
									),
									right: '45px',
									textAlign: 'right',
								}}
							>
								{getDataValue(barChartData.previous)}
							</div>
							<div
								className='bar'
								style={{
									height: `${
										barChartData.previous && previousHeight ? previousHeight : 1
									}%`,
								}}
							></div>
						</div>
						<div className='vertical-bar last'>
							<div
								className='bar'
								style={{
									height: `${
										barChartData.current && currentHeight ? currentHeight : 1
									}%`,
								}}
							></div>
							<div
								className='bar-value'
								style={{
									top: getBarValueTopPosition(
										barChartData.current,
										currentHeight
									),
									bottom: getBarValueBottomPosition(
										barChartData.current,
										currentHeight
									),
									left: '45px',
									textAlign: 'left',
								}}
							>
								{getDataValue(barChartData.current)} <br />
								<span>
									{barChartData.growthPercentage >= 0 ? '+' : ''}
									{barChartData.growthPercentage}%
								</span>
							</div>
						</div>
					</div>
					<div className='chart-legends'>
						<div className='legend first'>
							<span className=''>
								{period === 'YEARLY'
									? `${moment().year() - 1} YTD`
									: `${moment().format('MMM')} ${moment().year() - 1} MTD`}
							</span>
						</div>
						<div className='legend last'>
							<span className=''>
								{period === 'YEARLY'
									? `${moment().year()} YTD`
									: `${moment().format('MMM')} ${moment().year()} MTD`}
							</span>
						</div>
					</div>
				</div>
			) : (
				!isLoading && (
					<div className='empty-records pl-0 ml-3'>
						{t(Messages.NO_DATA_AVAILABLE, { ns: 'errors' })}
					</div>
				)
			)}
		</div>
	)
}

export default SummaryChart
