import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import footerLogo from '../../assets/images/footer-text-logo.svg'
import siteLogo from '../../assets/images/logo.png'
import printIcon from '../../assets/images/print.svg'
import shareIcon from '../../assets/images/share.svg'
import green_tick from '../../assets/images/green_tick.svg'
import downArrow from '../../assets/images/down-arrow.png'
import upArrow from '../../assets/images/up-arrow.png'
import { sendEmail } from '../../services/notificationService'
import { getStationByCode } from '../../services/searchPanelServices'
import { getConfirmationEnabledFlag } from '../../services/searchPanelServices'
import anotherBooking from '../../assets/images/another_booking.svg'
import {
	BOOKING_STATUSES,
	capitalize, DIMENSION_UNIT,
	DISPLAY_SERVICES,
	getDecimalFormattedNumber, getDensityUnit,
	getFormattedNumber,
	getRoundedValue, getVolumeUnit, getWeightUnit,
	NOTIFICATION_MESSAGE_TYPE,
	SERVICES, WEIGHT_UNIT
} from '../../constants'
import { deleteQuote } from '../../services/quoteServices'
import { deleteDrafts } from '../../services/templateService'
import getCurrentUser from '../../utils/getCurrentUser'
import {
	clearItemFromSessionStorage, getBase64Decoded, getFromSessionStorage, setToSessionStorage, getBase64Encoded
} from '../../utils/StorageUtils'
import RouteDetails from '../Shared/RouteDetails'
import './booking-confirmation.scss'
import ShareEmailDialog from './ShareEmailDialog'
import PrintBookingDialog from '../../common/PrintBookingDialog'
import { handlePrint } from './printEmailUtil'
import LoadingIndicator from '../LoadingIndicator'
import { Message } from 'primereact/message'
import BookingMenu from '../BookingMenu'
import { sendEmailForAssistance } from '../../utils/ExceptionHandlingUtil'
import { calculateWeightFromKg, calculateWeightToKg, round } from '../../utils/common'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../common/Alert'
import { addError, pushPageData } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 }
	from '../../constants'
import dgRestrictedSccs from '../../configs/dgRestrictedSccs';

const BookingConfirmation = ({
	setSearchFormDataToState,
	setSelectedFlightToState,
	getSaveBookingResponse,
	validateBookingResponse,
	saveBookingResponse,
	saveBookingError,
	showErrorPopup,
}) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])
	const componentRef = useRef()

	const [searchFormData, setSearchFormData] = useState(null)
	const [selectedFlight, setSelectedFlight] = useState(null)
	const [confirmationFlag, setConfirmationFlag] = useState('')
	

	const [awbNumber, setAwbNumber] = useState(null)
	const [awbPrefix, setAwbPrefix] = useState(null)
	//spot rate
	const [pickAllinRate, setPickAllinRate] = useState(true);
	const [spotRate, setSpotRate] = useState('');

	const [showSubCharges, setShowSubCharges] = useState(false)

	const [isCargoExpanded, setCargoExpanded] = useState(false)

	const [showCharges, setShowCharges] = useState(false)

	const [awb, setAwb] = useState(null)

	const [displayBasic, setDisplayBasic] = useState(false)

	const [displayPrintConfirm, setDisplayPrintConfirm] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const [chargeableWeight, setChargeableWeight] = useState(null)

	const userDetails = getCurrentUser()

	const history = useHistory()

	const [queuedReasons, setQueuedReasons] = useState(null)
	const [queuedReasonsAnalytics, setQueuedReasonsAnalytics] = useState(null)
	const hasAttachmentForException = true
	const dgCheckNormal = getFromSessionStorage('ifDg')
	const emailType = "saveBooking"

	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	const cleanUp = () => {
		clearItemFromSessionStorage('searchObject')
		clearItemFromSessionStorage('additionalFormData')
		clearItemFromSessionStorage('flightSelected')
		clearItemFromSessionStorage('awbNumber')
		clearItemFromSessionStorage('spotRate')
		clearItemFromSessionStorage('allInRate')
		clearItemFromSessionStorage('draftSelected')
		clearItemFromSessionStorage('flightParameterObj')
		clearItemFromSessionStorage('quoteId')
		clearItemFromSessionStorage('sortOption')
		setSearchFormDataToState(null)
		clearItemFromSessionStorage('awbPrefix')
		//setSelectedFlightToState(null)
	}

	useEffect(() => {
		const encodedSearchObject = getFromSessionStorage('searchObject')
		const encodedSelectedFlight = getFromSessionStorage('flightSelected')
		const encodedAwbNumber = getFromSessionStorage('awbNumber')
		const spotRate = getFromSessionStorage('spotRate')
		const awbPrefix = getFromSessionStorage('awbPrefix')
		const allInRate = getFromSessionStorage('allInRate')
		if (!encodedSearchObject || !encodedSelectedFlight || !encodedAwbNumber)
			return history.push('/booking/summary')
		const searchFormData = getBase64Decoded(encodedSearchObject)
		const selectedFlight = getBase64Decoded(encodedSelectedFlight)
		const awbNumber = getBase64Decoded(encodedAwbNumber)

		//(awbPrefix);
		setSelectedFlight(selectedFlight)
		setSearchFormData(searchFormData)
		setAwbNumber(awbNumber)
		setAwbPrefix(awbPrefix)
		setSpotRate(spotRate)
		setPickAllinRate(allInRate)
		const spotRatePayload = {
			spotRate: spotRate,
			allInRate: allInRate,
			isSpotRateRequired: 'Y',
			awbPrefix:awbPrefix
		}
		getSaveBookingResponse(
			awbNumber,
			spotRatePayload,
			searchFormData,
			selectedFlight
		)
		
		//return cleanUp()	
	}, [])

	useEffect(() => {
		if (validateBookingResponse) {
			const response = validateBookingResponse
			if (response.bookingDetails) {
				const bookingCommodityDetails = response.bookingDetails.bookingCommodityDetails
				if (bookingCommodityDetails && bookingCommodityDetails.length) {
					if (
						bookingCommodityDetails[0].chargeableWeight &&
						Number(bookingCommodityDetails[0].chargeableWeight)
					) {
						setSearchFormData((prevState) => ({
							...prevState,
							chargeableWeight: Number(
								bookingCommodityDetails[0].chargeableWeight
							).toFixed(2),
						}))

						setChargeableWeight(
							Number(bookingCommodityDetails[0].chargeableWeight),
						)
					}
				}
			}
		}
	}, [validateBookingResponse])

	useEffect(() => {
		if (selectedFlight && selectedFlight.origin) {
			try {
				getConfirmationEnabledFlag(selectedFlight?.origin).then((response) => {
					setConfirmationFlag(response?.confirmationEnabledFlag)
				})
			} catch (error) {
				console.log(error.message)
			}
		}
	}, [selectedFlight])

	useEffect(() => {
		const draftSelected = getFromSessionStorage('draftSelected')
		const selectedQuoteId = getFromSessionStorage('quotationId')
		let hasSentExceptionMail = false
		if (saveBookingResponse && saveBookingResponse.bookingResponseDetails) {
			if (draftSelected && draftSelected !== '') {
				try {
					let draftDeleteObj = {
						requestId: uuidv4(),
						draftName: draftSelected,
						userId: userDetails.userId,
					}
					deleteDrafts(draftDeleteObj)
				} catch (error) {
					console.log(error.message)
					return
				}
			}
			if (selectedQuoteId && selectedQuoteId !== '') {
				try {
					const quoteFromSession = getFromSessionStorage('quotationDetails')
					let quotationDetails =
						quoteFromSession !== null
							? getBase64Decoded(quoteFromSession)
							: null

					let quoteDeleteRequest = {
						requestId: uuidv4(),
						quoteId: quotationDetails ? quotationDetails.quotationId : null,
						agentCode: userDetails.agentCode,
					}
					deleteQuote(quoteDeleteRequest)
				} catch (error) {
					console.log(error.message)
					return
				}
			}
			// window.location.reload(true)
			// getFromSessionStorage('draftSelected') != 'null' && deleteDrafts(draftDeleteObj);
			// getFromSessionStorage('quotationDetails') != 'null' && deletQuote(quoteDeleteRequest)

			const awbNo = `${saveBookingResponse.bookingResponseDetails.shipmentPrefix} ${saveBookingResponse.bookingResponseDetails.masterDocumentNumber}`
			setAwb(awbNo)
			const queuedReasons = []
			const queuedReasonsData = []
			setTimeout(() => {

				// if (spotRate && Number(spotRate) && saveBookingResponse.bookingResponseDetails.bookingStatus && 
				// saveBookingResponse.bookingResponseDetails.bookingStatus === 'Q') {
				// 	handleSpotRateBooking(awbNo)
				// 	hasSentExceptionMail = true
				// 	queuedReasons.push(t('COMMON.SPOT_RATE_EXCEP'))
				// }
				if (saveBookingResponse.bookingResponseDetails.bookingStatus &&
					saveBookingResponse.bookingResponseDetails.bookingStatus === 'C' && confirmationFlag == "Y") {
					handleBookingConfirmationMail(awbNo)
				}

				if (selectedFlight && saveBookingResponse.bookingResponseDetails.bookingStatus &&
					saveBookingResponse.bookingResponseDetails.bookingStatus === 'Q') {
					try {
						if (selectedFlight.bigShipment) {
							handleBigBookingNotification(awbNo)
							hasSentExceptionMail = true
							queuedReasons.push(t('COMMON.BIG_EXCEP'))
							queuedReasonsData.push('Queued for rate approval for oversized shipment.')
						}
						if (selectedFlight.overHangShipment) {
							handleOHGBookingNotification(awbNo)
							hasSentExceptionMail = true
							if (queuedReasons.length == 0) {
								queuedReasons.push(t('COMMON.OHG_EXCEP'))
								queuedReasonsData.push('Queued for rate approval for overhang shipment.')
							}

						}
						if (spotRate && Number(spotRate)) {
							handleSpotRateBooking(awbNo)
							hasSentExceptionMail = true
							if (queuedReasons.length == 0) {
								queuedReasons.push(t('COMMON.SPOT_RATE_EXCEP'))
								queuedReasonsData.push('Queued for spot rate validation.')
							}
						}

						if (
							selectedFlight.ratingDetails &&
							selectedFlight.ratingDetails.length &&
							selectedFlight.ratingDetails[0].grandTotal === 0
						) {
							handleZeroRateBooking(awbNo)
							hasSentExceptionMail = true
							if (queuedReasons.length == 0) {
								let msg = (searchFormData?.shippingItemCode?.product)
								let isNstIncluded = searchFormData?.sccCodes.some((item) => item.scc === 'NST')
								if (dgSccCode?.includes(searchFormData?.shippingItemCode?.scc) || (dgCheckNormal && dgCheckNormal === 'true')) {
									queuedReasons.push(t('COMMON.DG_QUEUED'))
									queuedReasonsData.push('Your booking got created successfully and you shall receive a confirmation once the required DG validation is completed.')
								}
								else if (msg === "LiveAnimals") {
									queuedReasons.push(t('COMMON.AVI_QUEUED'))
									queuedReasonsData.push('Your booking got created successfully and you shall receive a confirmation once the required validation is completed.')
								} else if (isNstIncluded) {
									queuedReasons.push(t('COMMON.OTHER_EXCEP'))
									queuedReasonsData.push('Queued for further validation')
								} else {
									queuedReasons.push(t('COMMON.NO_RATES_EXCEP'))
									queuedReasonsData.push('Queued for rate approval.')
								}
							}
						}

						if (searchFormData.adjustedWeight) {
							handleOperationalWeightBooking(awbNo)
							hasSentExceptionMail = true
							if (queuedReasons.length == 0) {
								let msg = (searchFormData?.shippingItemCode?.product)
								if (dgSccCode?.includes(searchFormData?.shippingItemCode?.scc) || (dgCheckNormal && dgCheckNormal === 'true')) {
									queuedReasons.push(t('COMMON.DG_QUEUED'))
									queuedReasonsData.push('Your booking got created successfully and you shall receive a confirmation once the required DG validation is completed.')
								}
								else if (msg === "LiveAnimals") {
									queuedReasons.push(t('COMMON.AVI_QUEUED'))
									queuedReasonsData.push('Your booking got created successfully and you shall receive a confirmation once the required validation is completed.')
								}
								else {
									queuedReasons.push(t('COMMON.NO_RATES_EXCEP'))
									queuedReasonsData.push('Queued for rate approval.')
								}
							}
						}

						if (
							searchFormData?.sccCodes &&
							(searchFormData.sccCodes.some(e => e.scc === 'MUW') || searchFormData.sccCodes.some(e => e.scc === 'SWP'))
							//(searchFormData.sccCodes.includes('MUW')|| searchFormData.sccCodes.includes('SWP'))
						) {
							if (queuedReasons.length == 0) {
								queuedReasons.push(t('COMMON.MUW_SWP_SCC_EXCEP'))
								queuedReasonsData.push('Queued for further validation due to shipment restriction')
							}
						}

						if (selectedFlight.overBookingAllowed) {
							handleOverBookingNotification(awbNo)
							hasSentExceptionMail = true
							if (queuedReasons.length == 0) {
								queuedReasons.push(t('COMMON.OTHER_EXCEP'))
								queuedReasonsData.push('Queued for further validation')
							}
						}

						if (!hasSentExceptionMail) {
							let isNscIncluded = searchFormData?.sccCodes.some((item) => item.scc === 'NST')
							if (isNscIncluded) {
								handleNstQueuedBooking(awbNo)
							}
							else {
								handleQueuedBooking(awbNo)
							}
							if (queuedReasons.length == 0) {
								let msg = (searchFormData?.shippingItemCode?.product)

								if (dgSccCode?.includes(searchFormData?.shippingItemCode?.scc) || (dgCheckNormal && dgCheckNormal === 'true')) {

									queuedReasons.push(t('COMMON.DG_QUEUED'))
									queuedReasonsData.push('Your booking got created successfully and you shall receive a confirmation once the required DG validation is completed.')
								}
								else if (msg === "LiveAnimals") {
									queuedReasons.push(t('COMMON.AVI_QUEUED'))
									queuedReasonsData.push('Your booking got created successfully and you shall receive a confirmation once the required validation is completed.')
								}
								else {
									queuedReasons.push(t('COMMON.OTHER_EXCEP'))
									queuedReasonsData.push('Queued for further validation')
								}
							}
						}

						const queuedReasonsJoined = queuedReasons.join('')
						const queuedReasonsDataJoined = queuedReasonsData.join('')
						setQueuedReasons(queuedReasonsJoined)
						setQueuedReasonsAnalytics(queuedReasonsDataJoined)

					} catch (err) {
						console.log(err)
					}
				}

				pushPageData(
					pageNames.BOOKING_CONFIRMATION,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.BOOKING,
					BOOKING_PAGE_SUB_CATEGORY2.NEW_BOOKING,
					null,
					saveBookingResponse.bookingResponseDetails,
					queuedReasonsData.join(''), pickAllinRate, getFromSessionStorage('spotRate')
					//Object.assign({},{pickAllinRate, spotRate: getFromSessionStorage('spotRate')})
				)
			}, 1000)
			setTimeout(() => { cleanUp() }, 10000)

		}
	}, [saveBookingResponse])

	useEffect(() => {
		const spotRatePayload = {
			spotRate: spotRate,
			allInRate: pickAllinRate,
			isSpotRateRequired: 'Y',
			awbPrefix: awbPrefix
		}
		if (saveBookingError) {
			const header = saveBookingError.type
			const message = saveBookingError.description
			addError('SAVE_BOOKING_ERROR', message)
			let primaryBtnLbl = t('BOOKING.RETRY')
			const acceptFn = () => {
				getSaveBookingResponse(awbNumber, spotRatePayload, searchFormData, selectedFlight)
			}
			const secondaryBtnLbl = t('BOOKING.GO_BACK')
			const rejectFn = () => {
				history.goBack()
			}
			if (saveBookingError.handledError) {
				let primaryBtnLbl = t('COMMON.OK')
				history.goBack()
				if (message === "Stock does not exist for the agent.Do you want to continue?") {
					localStorage.setItem("Error", "True")
				}
				else {
					showErrorPopup(header, message, primaryBtnLbl)
				}
				return
			}
			showErrorPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn
			)
		}
	}, [saveBookingError])

	const handleOverBookingNotification = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)

		const response = await sendEmailForAssistance(
			'OVER_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}

	const handleOHGBookingNotification = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)

		const response = await sendEmailForAssistance(
			'OHG_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}

	const handleBigBookingNotification = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)

		const response = await sendEmailForAssistance(
			'BIG_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}

	const handleSpotRateBooking = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)
		const response = await sendEmailForAssistance(
			'SPOT_RATE_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}

	const handleZeroRateBooking = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)
		let isNscIncluded = searchFormData?.sccCodes.some((item) => item.scc === 'NST')
		const response = await sendEmailForAssistance(
			isNscIncluded ? 'QUEUED_BOOKING_NST' : 'ZERO_RATE_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}

	const handleOperationalWeightBooking = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)
		const response = await sendEmailForAssistance(
			'OP_WEIGHT_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}

	const handleQueuedBooking = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)
		const response = await sendEmailForAssistance(
			'QUEUED_BOOKING',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}
	const handleNstQueuedBooking = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const replacements = getEmailReplacements(awbNo)
		const response = await sendEmailForAssistance(
			'QUEUED_BOOKING_NST',
			null,
			attributes,
			replacements,
			selectedFlight,
			searchFormData,
			saveBookingResponse,
			awbNo,
			hasAttachmentForException,
			emailType,
		)
	}
	const handleBookingConfirmationMail = async (awbNo) => {
		const attributes = getEmailAttributesConfirmation(awbNo)
		const response = await sendConfirmationEmailForAssistance(
			attributes,
			selectedFlight,
			searchFormData,
			awbNo
		)

	}
	const getEmailAttributesConfirmation = (awbNo) => {
		const attributes = [
			{
				key: 'awbNo',
				value: awbNo,
			},
			{
				key: 'agentName',
				value: userDetails ? userDetails.companyName : null,
			},
			{
				key: 'scc',
				value: searchFormData && searchFormData.sccCodes
					? searchFormData.sccCodes.map((e) => e.scc).toString()
					: '',
			},
			{
				key: 'shippingDate',
				value:
					selectedFlight && selectedFlight.departureDateTime
						? moment(selectedFlight.departureDateTime).format('DD-MMM-YYYY')
						: null,
			},
			{
				key: 'shippingItem',
				value:
					searchFormData && searchFormData.shippingItemCode
						? `${searchFormData.shippingItemCode.description}-${searchFormData.shippingItemCode.product}`
						: null,
			},
		]
		return attributes
	}

	const sendConfirmationEmailForAssistance = async (
		attributes,
		selectedFlight,
		searchFormData,
		awbNo
	) => {
		const request = await getRequest(
			attributes,
			selectedFlight,
			searchFormData,
			awbNo
		)
		if (request) {
			const response = await sendEmail(request)
				.then((response) => {
					setIsLoading && setIsLoading(false)
					if (response.errorDetails) {
						return false
					} else {
						return true
					}
				})
				.catch((error) => {
					setIsLoading && setIsLoading(false)
					return false
				})
			return response
		} else {
			return false
		}
	}
	const getRequest = async (
		attributes,
		selectedFlight,
		searchFormData,
		awbNo
	) => {
		let defaultAirport = userDetails && userDetails.defaultAirportCode ?  userDetails.defaultAirportCode : userDetails?.stationCode
		const stationDtls = await getStationByCode(defaultAirport)
		const sccCodeForSubject = searchFormData && searchFormData.sccCodes
			? searchFormData.sccCodes.map((e) => e.scc).toString()
			: ''
		const agentName = userDetails ? userDetails.companyName : null
		const emailDate = selectedFlight && selectedFlight.departureDateTime
			? moment(selectedFlight.departureDateTime).format('DD MMM YYYY')
			: null
		const originDestination = selectedFlight ? selectedFlight.origin : null
		const destOrigin = selectedFlight ? selectedFlight.destination : null
		const mailAttributes = {
			body: `New booking for ${`${originDestination}`}-${`${destOrigin}`} on ${`${emailDate}`} created from portal.`,
			agentCode: userDetails ? userDetails.agentCode : null,
			agentName,
			agentEmail: userDetails ? userDetails.email : null,

		}


		if (stationDtls && stationDtls.emailAddress) {
			const emailAddresses = stationDtls.emailAddress.split(',')
			const request = {
				toEmailAddresses: emailAddresses,
				subject: `New Portal Booking - ${originDestination}-${destOrigin} / ${emailDate}/${sccCodeForSubject}`,
				templateName: 'confirmed_booking',
				attributes: {
					...mailAttributes,
				},
				agentCode: userDetails.agentCode,
				userId: userDetails.userId,
				async: false,
			}
			if (attributes && attributes.length) {
				attributes.forEach(function (attr) {
					request.attributes[attr.key] = attr.value
				})
			}
			return request
		} else {
			return null
		}
	}
	const getEmailAttriburtes = () => {
		const attributes = [
			{
				key: 'origin',
				value: selectedFlight ? selectedFlight.origin : null,
			},
			{
				key: 'destination',
				value: selectedFlight ? selectedFlight.destination : null,
			},

			{
				key: 'shippingItem',
				value:
					searchFormData && searchFormData.shippingItemCode
						? `${searchFormData.shippingItemCode.description}-${searchFormData.shippingItemCode.product}`
						: null,
			},
			{
				key: 'shippingDate',
				value:
					selectedFlight && selectedFlight.departureDateTime
						? moment(selectedFlight.departureDateTime).format('DD-MMM-YYYY')
						: null,
			},
		]
		return attributes
	}

	const getEmailReplacements = (awbNo) => {
		const replacements = {
			'%FLIGHTNUMBER%':
				selectedFlight &&
					selectedFlight.flightSegmentList &&
					selectedFlight.flightSegmentList.length
					? `${selectedFlight.flightSegmentList[0].flightCarrierCode} ${selectedFlight.flightSegmentList[0].flightCarrierId}`
					: '',
			'%FLIGHTDATE%':
				selectedFlight && selectedFlight.departureDateTime
					? moment(selectedFlight.departureDateTime).format('DD-MMM-YYYY')
					: ' ',
			'%AWB%': awbNo,
			'%ORG%': selectedFlight ? selectedFlight.origin : '',
			'%DES%': selectedFlight ? selectedFlight.destination : '',
		}
		return replacements
	}

	if (!searchFormData || !selectedFlight) {
		return null
	}
	const routes =
		selectedFlight && selectedFlight.flightSegmentList
			? selectedFlight.flightSegmentList.length - 1
			: 0
	const departureTime = selectedFlight
		? moment(selectedFlight.departureDateTime)
		: null
	const arrivalTime = selectedFlight
		? moment(selectedFlight.arrivalDateTime)
		: null
	const origin = selectedFlight ? selectedFlight.origin : null
	const destination = selectedFlight ? selectedFlight.destination : null

	const getFormatedTime = (value) => {
		let formattedTime = ''
		if (value) {
			let tempArray = value.split(':')
			formattedTime =
				parseInt(tempArray[0], 10) +
				'Hrs ' +
				(tempArray[1] && tempArray[1] != '00' ? tempArray[1] + ' Min' : '')
		}
		return formattedTime
	}

	const goToSearch = () => {
		history.push('/booking/search')
		clearItemFromSessionStorage('searchObject')
		//clearItemFromSessionStorage('upsellOffered')
	}

	const onClickShare = () => {
		setDisplayBasic(true)
	}

	const printDetails = () => {
		handlePrint(
			selectedFlight,
			searchFormData,
			awb,
			saveBookingResponse,
			showCharges,
			setIsLoading
		)
	}

	const handleAlertClose = () => {
		setQueuedReasons(null)
	}

	const getPetsSum = (cages) => {
		let petList = cages.map((item) => {
			return item.pets.length
		})
		let sum = 0;
		for (const item of petList) {
			sum += item;
		}
		if (sum > 1) {
			return sum + ' Pets';
		}
		else {
			return sum + ' Pet';
		}
	}

	const getCageString = (length) => {
		let cageString = length > 1 ? 'Containers' : 'Container'
		return cageString;
	}

	return (
		<div className='row'>
			<div className='col-12'>
				<div className='booking-confirmation-page' ref={componentRef}>
					{searchFormData && !searchFormData.isModifyBooking && <BookingMenu activeItem={0} />}
					{isLoading ? <LoadingIndicator /> : <></>}
					<ShareEmailDialog
						selectedFlight={selectedFlight}
						searchFormData={searchFormData}
						saveBookingResponse={saveBookingResponse}
						getFormatedTime={getFormatedTime}
						awbNumber={awb}
						displayBasic={displayBasic}
						setDisplayBasic={setDisplayBasic}
						showCharges={showCharges}
					/>
					{saveBookingResponse &&
						saveBookingResponse.bookingResponseDetails &&
						searchFormData ? (
						<div className='quote-details'>
							<div className='d-none d-lg-block col-12 text-right another-booking no-print'>
								<a className='l-link' onClick={(e) => goToSearch(e)}>
									<span>{t('BOOKING.ANOTHER_BOOKING_TXT')}</span>
								</a>
							</div>
							<div className='logo-company'>
								<img
									className='logo-company-image'
									src={siteLogo}
									alt='Etihad'
									title='Etihad'
								/>
								<div className='company-name'>{userDetails.companyName}</div>
							</div>
							<div className='title p-r-0 row d-none d-lg-block'>
								<div className='col-4'>
									{t('COMMON.BKG_HEAD')}
									{`:`}
									<span
										className={
											saveBookingResponse.bookingResponseDetails.bookingStatus ==
												'C'
												? 'status-confirmed'
												: 'status-queued'
										}
									>
										{' '}
										{t(
											BOOKING_STATUSES[
											saveBookingResponse.bookingResponseDetails.bookingStatus
											]
										)}
									</span>
								</div>
							</div>
							<div className={
								saveBookingResponse.bookingResponseDetails.bookingStatus ==
									'C'
									? 'status-confirmed title mobile d-flex d-lg-none'
									: 'status-queued title mobile d-flex d-lg-none'
							}>
								{saveBookingResponse.bookingResponseDetails.bookingStatus ==
									'C' ?
									<>
										<img
											className='confirmation_status'
											src={green_tick}
											alt='Etihad'
											title='Etihad'
										/>
									</> :
									<>
										<i className='indicator'>
											<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="hourglass-half" class="svg-inline--fa fa-hourglass-half fa-w-12 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-75.078 384H99.08c17.059-46.797 52.096-80 92.92-80 40.821 0 75.862 33.196 92.922 80zm.019-256H99.078C91.988 108.548 88 86.748 88 64h208c0 22.805-3.987 44.587-11.059 64z"></path></svg>
										</i>
									</>}
								{t('COMMON.BKG_HEAD')}
								{' '}
								{t(
									BOOKING_STATUSES[
									saveBookingResponse.bookingResponseDetails.bookingStatus
									]
								)}
							</div>
							{/* {
								saveBookingResponse.warning ? (
									<Message
										severity='warn'
										text={saveBookingResponse.warning}
									></Message>
								) : <></>
							} */}
							{queuedReasons && (
								<div className='cargo-portal-container'>
									<Alert
										type={NOTIFICATION_MESSAGE_TYPE.WARNING}
										showAlert={true}
										message={queuedReasons}
										setTimeout={false}
										onAlertClose={handleAlertClose}
									/>
								</div>
							)}
							<div className='flight-summary row'>
								<div className='col-12 custom-pl-0'>
									<div className='shipment-summary'>
										<div className='shipment-summary__list'>
											<div className='shipment-summary__item'>
												<span>
													{`${t('COMMON.AWB_NUMBER')} : ${saveBookingResponse.bookingResponseDetails.shipmentPrefix}-${saveBookingResponse.bookingResponseDetails.masterDocumentNumber}`}
												</span>
											</div>
											<div className='d-flex flex-column flex-lg-row no-print'>
												<div className='shipment-summary__button'>

													<button
														type='button'
														className='l-btn l-btn--secondary l-btn--large'
														onClick={onClickShare}
													>
														{/* <i className='shipment-summary__icons'>
														<img src={shareIcon} alt='Share' title='Share' />
													</i> */}
														{t('COMMON.SHARE')}
													</button>
												</div>
												<div className='shipment-summary__button'>
													<button
														type='button'
														className='l-btn l-btn--secondary l-btn--large'
														onClick={(e) => {
															setDisplayPrintConfirm(!displayPrintConfirm)
															setShowCharges(false)
														}}
													>
														{/* <i className='shipment-summary__icons'>
														<img src={printIcon} alt='Print' title='Print' />
													</i> */}
														{t('COMMON.PRINT')}
													</button>
												</div>
												{displayPrintConfirm && (
													<PrintBookingDialog
														showCharges={showCharges}
														setShowCharges={setShowCharges}
														displayPrintConfirm={displayPrintConfirm}
														setDisplayPrintConfirm={setDisplayPrintConfirm}
														printDetails={printDetails}
														selectedFlight={selectedFlight}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='confirm_title'>{t('BOOKING.SUMMARY')}</div>
							<RouteDetails selectedFlight={selectedFlight} showRates={false}/>

							<div className='col-12 sub-title row d-none d-lg-block'>
								{t('COMMON.CARGO_DTLS')}
							</div>
							<div className='mobile_partition-wrap border-top-0 d-flex d-lg-none'>
								<div className='mobile_partition-row' onClick={() => setCargoExpanded(!isCargoExpanded)}>
									<span className='mobile_partition-title'>{t('COMMON.CARGO_DTLS')}</span>
									<span className='mobile_partition-icon'>
										<i>
											{isCargoExpanded ? (
												<img src={upArrow} alt='Flight' title='Flight' />
											) : (
												<img src={downArrow} alt='Flight' title='Flight' />
											)}
										</i>
									</span>
								</div>
								{isCargoExpanded ?
									<div className='mobile_partition-expanded'>
										<div className='cargo-details__col'>
											<div className='cargo-details__item'>
												<label> {searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? t('COMMON.QTY') : t('COMMON.PCS')}: </label>
												<div className='value-field'>
													<div className='value-field'>
														{searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? searchFormData.crates && searchFormData.crates[0]?.weight ? `${getPetsSum(searchFormData.crates)} in ${searchFormData.crates.length} ${getCageString(searchFormData.crates.length)}` : '' : getFormattedNumber(searchFormData.totalPieces)}
													</div>
												</div>
											</div>

											<div className='cargo-details__item'>
												<label>{t('COMMON.GROSS_WT')}</label>
												<>
													<div className='value-field p-r-5'>
														{searchFormData.grossWeightInSelectedUnit}
														{getWeightUnit(searchFormData.weightUnit)}
													</div>
													{searchFormData.weightUnit &&
														searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
														<div className='value-field value-field__sub'>
															({searchFormData.grossWeightInConvertedUnit}
															{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
														</div>
													) : (
														<></>
													)}
												</>
											</div>

											{/* <div className='cargo-details__item'>
												<label>Volume</label>
												<div className='value-field p-r-5'>
													{getDecimalFormattedNumber(searchFormData.actualVolume)}
													{getVolumeUnit(searchFormData.dimensionUnit)}
												</div>
												<div className='value-field value-field__sub'>
													(
												{searchFormData.totalVolumeInMtrs
														? getDecimalFormattedNumber(
															searchFormData.totalVolumeInMtrs
														)
														: 0.01}
													{getVolumeUnit(DIMENSION_UNIT.METERS)})
											</div>
											</div> */}

											<div className='cargo-details__item'>
												<label>{t('COMMON.VOL')}</label>
												{searchFormData.dimensionUnit === DIMENSION_UNIT.CENTIMETERS
													? (<div className='value-field p-r-5'>
														{searchFormData.totalVolumeInMtrs
															? getDecimalFormattedNumber(
																searchFormData.totalVolumeInMtrs
															)
															: 0.01}
														{getVolumeUnit(DIMENSION_UNIT.METERS)}

													</div>)
													: (<div className='value-field p-r-5'>
														{getDecimalFormattedNumber(searchFormData.actualVolume)}
														{getVolumeUnit(searchFormData.dimensionUnit)}
													</div>)
												}

												{searchFormData.dimensionUnit === DIMENSION_UNIT.INCHES &&
													<div className='value-field value-field__sub'>
														({searchFormData.totalVolumeInMtrs
															? getDecimalFormattedNumber(
																searchFormData.totalVolumeInMtrs
															)
															: 0.01}
														{getVolumeUnit(DIMENSION_UNIT.METERS)})
													</div>
												}
											</div>

											<div className='cargo-details__item'>
												<label>{t('COMMON.CH_WT')}</label>

												{searchFormData.weightUnit &&
													searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
													<>
														<div className='value-field p-r-5'>
															{searchFormData
																? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
																: ''}
															{getWeightUnit(searchFormData.weightUnit)}
														</div>
														<div className='value-field value-field__sub'>
															(
															{searchFormData
																? round(
																	calculateWeightToKg(
																		chargeableWeight || searchFormData.chargeableWeight,
																		searchFormData.weightUnit
																	), 2
																)
																: ''}
															{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
														</div>
													</>
												) : (
													<div className='value-field'>
														{searchFormData
															? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
															: ''}
														{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
													</div>
												)}
											</div>

											{searchFormData && searchFormData?.unid && searchFormData?.unid[0]?.unid !== '' && dgCheckNormal === 'true' ?
												<div className='cargo-details__item'>
													<label>{t('COMMON.UNIDSUMMARY')} </label>
													<div className='value-field'>
														{searchFormData && searchFormData?.unid
															? searchFormData?.unid?.map((e, index) => searchFormData?.unid?.length - 1 === index ? `${e?.unid}` : `${e?.unid}, `)
															: '--'}
													</div>
												</div>
												: <></>
											}
										</div>
										<div className='cargo-details__col'>
											<div className='cargo-details__item'>
												<label>{t('COMMON.PRD')}</label>
												<div className='value-field'>
													{(selectedFlight.ratingDetails && selectedFlight.ratingDetails.length) ?
														selectedFlight.ratingDetails[0].product : ''}
												</div>
											</div>
											<div className='cargo-details__item'>
												<label>{t('COMMON.SERVICE')}</label>
												<div className='value-field'>
													{selectedFlight.service
														? selectedFlight.service &&
															selectedFlight.service == SERVICES.FAST_TRACK
															? DISPLAY_SERVICES[SERVICES.FAST_TRACK]
															: null
														: '--'}
												</div>
											</div>
											<div className='cargo-details__item'>
												<label>{t('COMMON.SCC')} </label>
												<div className='value-field'>
													{
														(() => {
															let sccs = searchFormData && searchFormData.sccCodes
															// if (selectedFlight && selectedFlight.service) {
															// 	sccs = [...sccs, { scc: selectedFlight.service }]
															// }
															return sccs.map((e) => e.scc).toString()
														})()
													}
												</div>
											</div>

											<div className='cargo-details__item density-print'>
												<label>{t('COMMON.DENSITY')}</label>
												{searchFormData.dimensionUnit === DIMENSION_UNIT.CENTIMETERS
													? <div className='value-field p-r-7'>
														{searchFormData.densityInSelectedUnit}
														{getDensityUnit(
															searchFormData.weightUnit,
															DIMENSION_UNIT.METERS
														)}
													</div>
													: <div className='value-field p-r-7'>
														{searchFormData.densityInSelectedUnit}
														{getDensityUnit(
															searchFormData.weightUnit,
															searchFormData.dimensionUnit
														)}
													</div>
												}
												{searchFormData.densityInConvertedUnit &&
													((searchFormData.weightUnit &&
														searchFormData.weightUnit !== WEIGHT_UNIT.KILOGRAM) ||
														searchFormData.dimensionUnit !== DIMENSION_UNIT.CENTIMETERS) ?
													<div className='value-field value-field__sub'>
														({searchFormData.densityInConvertedUnit}
														{getDensityUnit(
															WEIGHT_UNIT.KILOGRAM,
															DIMENSION_UNIT.METERS
														)}
														)
													</div>
													: (
														<></>
													)}
											</div>






										</div>

									</div>
									: <></>}
							</div>
							<div className='d-none d-lg-flex cargo-details cargo-details-print'>
								<div className='cargo-details__col'>
									<div className='cargo-details__item'>
										<label> {searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? t('COMMON.QTY') : t('COMMON.PCS')}: </label>
										<div className='value-field'>
											{searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? searchFormData.crates && searchFormData.crates[0]?.weight ? `${getPetsSum(searchFormData.crates)} in ${searchFormData.crates.length} ${getCageString(searchFormData.crates.length)}` : '' : getFormattedNumber(searchFormData.totalPieces)}
										</div>
									</div>

									<div className='cargo-details__item'>
										<label>{t('COMMON.GROSS_WT')}</label>
										<>
											<div className='value-field p-r-5'>
												{searchFormData.grossWeightInSelectedUnit}
												{getWeightUnit(searchFormData.weightUnit)}
											</div>
											{searchFormData.weightUnit &&
												searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
												<div className='value-field value-field__sub'>
													({searchFormData.grossWeightInConvertedUnit}
													{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
												</div>
											) : (
												<></>
											)}
										</>
									</div>

									{/* <div className='cargo-details__item'>
											<label>Volume</label>
											<div className='value-field p-r-5'>
												{getDecimalFormattedNumber(searchFormData.actualVolume)}
												{getVolumeUnit(searchFormData.dimensionUnit)}
											</div>
											<div className='value-field value-field__sub'>
												(
											{searchFormData.totalVolumeInMtrs
													? getDecimalFormattedNumber(
														searchFormData.totalVolumeInMtrs
													)
													: 0.01}
												{getVolumeUnit(DIMENSION_UNIT.METERS)})
										</div>
										</div> */}

									<div className='cargo-details__item'>
										<label>{t('COMMON.VOL')}</label>
										{searchFormData.dimensionUnit === DIMENSION_UNIT.CENTIMETERS
											? (<div className='value-field p-r-5'>
												{searchFormData.totalVolumeInMtrs
													? getDecimalFormattedNumber(
														searchFormData.totalVolumeInMtrs
													)
													: 0.01}
												{getVolumeUnit(DIMENSION_UNIT.METERS)}

											</div>)
											: (<div className='value-field p-r-5'>
												{getDecimalFormattedNumber(searchFormData.actualVolume)}
												{getVolumeUnit(searchFormData.dimensionUnit)}
											</div>)
										}

										{searchFormData.dimensionUnit === DIMENSION_UNIT.INCHES &&
											<div className='value-field value-field__sub'>
												({searchFormData.totalVolumeInMtrs
													? getDecimalFormattedNumber(
														searchFormData.totalVolumeInMtrs
													)
													: 0.01}
												{getVolumeUnit(DIMENSION_UNIT.METERS)})
											</div>
										}
									</div>

									<div className='cargo-details__item'>
										<label>{t('COMMON.CH_WT')}</label>

										{searchFormData.weightUnit &&
											searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
											<>
												<div className='value-field p-r-5'>
													{searchFormData
														? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
														: ''}
													{getWeightUnit(searchFormData.weightUnit)}
												</div>
												<div className='value-field value-field__sub'>
													(
													{searchFormData
														? round(
															calculateWeightToKg(
																chargeableWeight || searchFormData.chargeableWeight,
																searchFormData.weightUnit
															), 2
														)
														: ''}
													{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
												</div>
											</>
										) : (
											<div className='value-field'>
												{searchFormData
													?
													<>
														{searchFormData?.adjustedWeight ?
															getRoundedValue(searchFormData.adjustedWeight) < getRoundedValue(searchFormData.chargeableWeight) ?
																getRoundedValue(chargeableWeight || searchFormData.chargeableWeight) :
																getRoundedValue(searchFormData.adjustedWeight)
															:
															getRoundedValue(searchFormData.chargeableWeight)
														}
													</>
													: ''}
												{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
											</div>
										)}
									</div>

									{searchFormData && searchFormData?.unid && searchFormData?.unid[0]?.unid !== '' && dgCheckNormal === 'true' ?
										<div className='cargo-details__item'>
											<label>{t('COMMON.UNIDSUMMARY')} </label>

											<div className='value-field'>
												{searchFormData && searchFormData?.unid
													? searchFormData?.unid?.map((e, index) => searchFormData?.unid?.length - 1 === index ? `${e.unid}` : `${e.unid}, `)
													: '--'}
											</div>
										</div>
										: <></>
									}
								</div>
								<div className='cargo-details__col'>
									<div className='cargo-details__item'>
										<label>{t('COMMON.PRD')}</label>
										<div className='value-field'>
											{(selectedFlight.ratingDetails && selectedFlight.ratingDetails.length) ?
												selectedFlight.ratingDetails[0].product : ''}
										</div>
									</div>
									<div className='cargo-details__item'>
										<label>{t('COMMON.SERVICE')}</label>
										<div className='value-field'>
											{selectedFlight.service
												? selectedFlight.service &&
													selectedFlight.service == SERVICES.FAST_TRACK
													? DISPLAY_SERVICES[SERVICES.FAST_TRACK]
													: null
												: '--'}
										</div>
									</div>
									<div className='cargo-details__item'>
										<label>{t('COMMON.SCC')} </label>
										<div className='value-field'>
											{
												(() => {
													let sccs = searchFormData && searchFormData.sccCodes
													// if (selectedFlight && selectedFlight.service) {
													// 	sccs = [...sccs, { scc: selectedFlight.service }]
													// }
													return sccs.map((e) => e.scc).toString()
												})()
											}
										</div>
									</div>

									<div className='cargo-details__item density-print'>
										<label>{t('COMMON.DENSITY')}</label>
										<div className='density-col'>
										{searchFormData.dimensionUnit === DIMENSION_UNIT.CENTIMETERS
											? <div className='value-field p-r-7'>
												{searchFormData.densityInSelectedUnit}
												{getDensityUnit(
													searchFormData.weightUnit,
													DIMENSION_UNIT.METERS
												)}
											</div>
											: <div className='value-field p-r-7'>
												{searchFormData.densityInSelectedUnit}
												{getDensityUnit(
													searchFormData.weightUnit,
													searchFormData.dimensionUnit
												)}
											</div>
										}
										{searchFormData.densityInConvertedUnit &&
											((searchFormData.weightUnit &&
												searchFormData.weightUnit !== WEIGHT_UNIT.KILOGRAM) ||
												searchFormData.dimensionUnit !== DIMENSION_UNIT.CENTIMETERS) ?
											<div className='value-field value-field__sub'>
												({searchFormData.densityInConvertedUnit}
												{getDensityUnit(
													WEIGHT_UNIT.KILOGRAM,
													DIMENSION_UNIT.METERS
												)}
												)
											</div>
											: (
												<></>
											)}
											</div>
									</div>




								</div>
							</div>
							<button onClick={(e) => goToSearch(e)} className='d-flex d-lg-none another_booking_mobile'>
								<span>Create another booking</span>
								<img src={anotherBooking} alt="another_booking" />
							</button>
							<div className='page-number-1'>
								{showCharges ? `Page 1 of 2` : 'Page 1 of 1'}
							</div>
							{showCharges && <div className='charge-heading'>{t('COMMON.CHARGES')}</div>}
							{showCharges && (
								<div className='booking-details-print'>
									<div className='d-flex justify-content-between row p-l-0'>
										{showCharges && (
											<div>
												<h4>{t('COMMON.CH_WT')}</h4>
											</div>
										)}

										<div className='charge-weight '>
											{searchFormData.weightUnit &&
												searchFormData.weightUnit != WEIGHT_UNIT.KILOGRAM ? (
												<>
													<span className='p-r-5'>
														{searchFormData
															? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
															: 0.0}
														{getWeightUnit(searchFormData.weightUnit)}
													</span>
													<span className='value-field value-field__sub'>
														(
														{searchFormData
															? round(
																calculateWeightToKg(
																	chargeableWeight || searchFormData.chargeableWeight,
																	searchFormData.weightUnit
																), 2
															)
															: 0.0}
														{getWeightUnit(WEIGHT_UNIT.KILOGRAM)})
													</span>
												</>
											) : (
												<span>
													{searchFormData
														? getRoundedValue(chargeableWeight || searchFormData.chargeableWeight)
														: ''}
													{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
												</span>
											)}
										</div>
									</div>

									<div className='row  d-flex p-l-0'>
										<div className='w-60 p-l-0 charges-row'>
											<h4>{t('COMMON.AIR_FREIGHT_COST')}</h4>{' '}
										</div>
										<div className='per-rate-field  p-r-0'>
											{selectedFlight.ratingDetails[0].currency}{' '}
											{getDecimalFormattedNumber(
												selectedFlight.ratingDetails[0].totalFreightRate
											)}
											{'/'}
											{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
										</div>
										<div className='rate-field p-r-0 w-15'>
											{selectedFlight.ratingDetails[0].currency}{' '}
											{getDecimalFormattedNumber(
												selectedFlight.ratingDetails[0].totalFreightCharge
											)}
										</div>
									</div>

									<div className='d-flex justify-content-between row subcharges p-l-0'>
										<div className='col-9 p-l-0 charges-row'>
											<h4>
												{t('COMMON.SURCHARGES')}
												{/* <span
													class='plus-sign'
													onClick={() => setShowSubCharges(!showSubCharges)}
												>
													<i>
														<FontAwesomeIcon
															icon={showSubCharges ? faMinus : faPlus}
														/>
													</i>
												</span> */}
											</h4>{' '}
										</div>
										<div>
											<span className='rate-field'>
												{selectedFlight.ratingDetails[0].currency}{' '}
												{selectedFlight.ratingDetails[0].totalSurcharge === null
													? '0'
													: getDecimalFormattedNumber(
														selectedFlight.ratingDetails[0].totalSurcharge
													)}
											</span>
										</div>
									</div>
									{selectedFlight.ratingDetails[0].otherChargeDetails.length &&
										showSubCharges ? (
										selectedFlight.ratingDetails[0].otherChargeDetails.map(
											(element, index) => {
												return (
													<div className='d-flex row sub-charges p-t-0 p-l-0'>
														<div className='w-60 p-l-0 charges-row p-t-0'>
															<h4>{capitalize(element.chargeHeadName)} </h4>{' '}
														</div>
														<div className='per-rate-field  p-r-0'>
														</div>
														<div className='rate-field  p-r-0 w-15'>
															{selectedFlight.ratingDetails[0].currency}{' '}
															{getDecimalFormattedNumber(element.chargeAmount)}
														</div>
													</div>
												)
											}
										)
									) : (
										<></>
									)}

									<div className='d-flex justify-content-between row total'>
										<div className='total-cost'>
											<h4>{t('COMMON.TTL_CST')}</h4>
											<div>
												<span>
													{selectedFlight.ratingDetails[0].currency}{' '}
													{getDecimalFormattedNumber(
														selectedFlight.ratingDetails[0].grandTotal
													)}
												</span>
											</div>
										</div>
										<div className='exclude-text col-12 p-l-0'>
											{`Additional charges may apply. Please read our `}
											<a className='l-link' onClick={(e) => e.preventDefault()}>
												terms and conditions
											</a>
											{` for more information.`}
										</div>
									</div>
								</div>
							)}
							<div className='floater-logo'>
								<img
									src={footerLogo}
									alt='AbhudabiTotheWorld'
									title='AbhudabiTotheWorld'
								/>
							</div>
							<p className='footer-text'>
								Copyright 2021 Etihad Airways. All rights reserved
							</p>
							{showCharges && (
								<div className='page-number-2'>
									{showCharges ? `Page 2 of 2` : ``}
								</div>
							)}
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	)
}
export default BookingConfirmation