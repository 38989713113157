import React, { useEffect, useState, useRef } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { getAdditionalSccs } from '../../services/searchPanelServices'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants'

const SccAutoComplete = ({
	handleAddAdditinalScc,
	setSccWarning,
	setNotificationMessage,
	setNotificationType,
	isCustomized,
	initialValue,
	handleSccChange,
}) => {
	const [text, setText] = useState(null)
	const [sccs, setSccs] = useState([])
	const [filteredSccItems, setfilteredSccItems] = useState([])

	const myRef = useRef()

	useEffect(() => {
		getAdditionalSccs()
			.then((sccs) => {
				setSccs(sccs)
			})
			.catch((err) => {
				setNotificationMessage &&
					setNotificationMessage(
						'Additional Sccs Error :' + err.message ? err.message : ''
					)
				setNotificationType &&
					setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
			})
		if (initialValue) {
			setText(initialValue)
		}
	}, [])

	const searchShippingItems = () => {

		const shippingItemByCodeStart =
			sccs && sccs.length && text && text.length
				? sccs.filter((scc) => {
					return scc.scc.toLowerCase().startsWith(text.toLowerCase())
				})
				: []

		const shippingItemByNameStart =
			sccs && sccs.length && text && text.length
				? sccs.filter((scc) => {
					return ` ${scc.sccDescription.toLowerCase()}`.startsWith(
						text.toLowerCase()
					)
				})
				: []

		const shippingItemByContains =
			sccs && sccs.length && text && text.length
				? sccs.filter((scc) => {
					return (
						scc.scc.toLowerCase().includes(text.toLowerCase()) ||
						scc.sccDescription.toLowerCase().includes(text.toLowerCase())
					)
				})
				: []

		const filteredSccItems = [
			...shippingItemByNameStart,
			...shippingItemByCodeStart,
			...shippingItemByContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.scc === item.scc)
		)

		setfilteredSccItems(filteredSccItems)
	}

	const itemTemplate = (scc) => {
		return (
			<div className='scc-autocomplete'>
				<span className='scc-code'>{` ${scc.scc}`}</span>
				<span className='scc-description'>{` ${scc.sccDescription}`}</span>
			</div>
		)
	}

	const handleOnFocus = (e) => {
		if (!text) {
			myRef.current.onDropdownClick(e, '')
		}
	}

	return (
		<>
			<AutoComplete
				id='additionalScc'
				name='additionalScc'
				AutoComplete='false'
				value={text}
				suggestions={filteredSccItems}
				//dropdown
				ref={myRef}
				completeMethod={searchShippingItems}
				onChange={(e) => {
					if (handleSccChange) {
						setText(e.value)
						handleSccChange(e)
					}
					else {
						setText(e.value)
					}
				}
				}
				// onFocus={(e) => {
				// 	handleOnFocus(e)
				// }}
				onSelect={(e) => {
					setText(isCustomized ? e.value.scc : null)
					handleAddAdditinalScc({
						scc: e.value.scc,
						sccDescription: e.value.sccDescription,
					})
				}}
				onBlur={(e) => {
					setSccWarning(null)
					// setText('')
				}}
				className={'form-control autocomplete search-panel__scc-autocomplete'}
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default SccAutoComplete
