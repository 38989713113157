import { AVI_BOOKING } from '../constants'

const initState = {
	response: null,
}

export default function (state = initState, action) {
	switch (action.type) {
		case AVI_BOOKING.LOAD:
            return {
				...state,
				response: null,
			}
		case AVI_BOOKING.SUCCESS:
			return{
				...state,
				response: action.response,
			}
			
		default:
			return state
	}
}
