import axios from 'axios'
import { portalHttpClient, portalAuthHttpClient } from '../utils/HttpRequestUtils'
import envConfigs from '../configs/envConfigs'

export const getAirportsByCode = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAirportByCodeUrl}/${code}`
	const response = await portalHttpClient.get(url)
	return response.data
}

export const getConfirmationEnabledFlag = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getConfirmationEnabledFlagUrl}/${code}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getDisclaimerEnabledFlag = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getDisclaimerEnabledFlagUrl}/${code}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getAirports = async (query) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAirportsUrl}/${query}`
	const response = await portalHttpClient.get(url)
	return response.data
}

export const getAirportsMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAirportsMasterUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getShippingItems = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getShippingItemsUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getCommodityMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCommodityMasterUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getEmbargoMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getEmbargoMasterUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}


export const getServices = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getServicesUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getServicesMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getServicesMasterUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getScc = async (sccCode) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getSccUrl}${sccCode ? `/${sccCode}` : ''
		}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getSccMaster = async (sccCode) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getSccMasterUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getAdditionalSccs = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAdditionalSccsUrl}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getULDTypeByCode = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getULDTypeByCodeUrl}/${code}`
	//const url = `http://localhost:8080/api/masterdata/uldtypebycode/${code}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getULDTypes = async (query) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getULDTypesUrl}/${query}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getOrigins = async (query) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getOriginsUrl}?queryTxt=${query}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getPivotWeight = async (origin, uldtype, contour) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getPivotWeightUrl
		}?origin=${origin}&uldtype=${uldtype}&contour=${contour ? contour : ''}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getDestinations = async (query, originCode) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getDestinationsUrl}/${originCode}?queryTxt=${query}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const checkSccCompatibility = async (sccList) => {
	let newSccList

	if (sccList && sccList.selectedItems) {
		const newSelectedItems = sccList.selectedItems
			.filter((val, id, array) => array.indexOf(val) == id);

		newSccList = {
			selectedItems: newSelectedItems,
			newItem: sccList.newItem && sccList.newItem
		}
	}

	const data = newSccList
	const url = `${envConfigs.baseUrl}${envConfigs.checkSccCompatibilityurl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getStationByCode = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getStationDetailsUrl}/${code}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getUldTypeMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getUldTypeMaster}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getPivotInfoMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getPivotInfoMaster}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getSccIncompatibleMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getSccIncompatibilityMaster}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getStationScheduleMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getStationScheduleMaster}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getRegionMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getRegionMaster}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getCountryMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCountryMaster}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

