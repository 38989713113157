import { call, put, takeEvery } from 'redux-saga/effects'
import { ERROR_MESSAGE } from '../../constants'
import { getLisTemplateResponse } from '../../services/manageTemplateService'
import {
	listTemplateError,
	listTemplateSuccess,
} from '../actions/manageTemplateActions'
import { LIST_TEMPLATE } from '../constants'

function* getTemplates({ templateRequest }) {
	try {
		const response = yield call(getLisTemplateResponse, templateRequest)
		if (
			response &&
			response.errorDetails !== undefined &&
			response.errorDetails !== null
		) {
			const errorObj = {
				handledError: false,
				description: response.errorDetails[0].errorDescription,
				type: 'Error',
			}
			yield put(listTemplateError(errorObj))
		}
		if (response && response.errorDetails == null) {
			yield put(listTemplateSuccess(response))
		}
	} catch (error) {
		const errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(listTemplateError(errorObj))
	}
}

function* watchGetTemplates() {
	yield takeEvery(LIST_TEMPLATE.LOAD, getTemplates)
}

export default {
	getTemplates,
	watchGetTemplates,
}
