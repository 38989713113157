import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const getTemplate = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.findTemplatetUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getAdditionaFormInfo = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAdditionaFormInfoUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getRecentBooking = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getBookingDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const updateTemplate = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.updateTemplateUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getDraftsDetails = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getDraftsDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const deleteDrafts = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.deleteDraftsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const listDrafts = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.listDraftUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getAWB = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAWBDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}