import { LIST_USER } from '../constants'

const initState = {
	request: null,
	response: null,
	error: null,
}

export default function (state = initState, action) {
	switch (action.type) {
		case LIST_USER.LOAD:
			return {
				...state,
				request: action.userSearchData,
				response: null,
				error: null,
			}
		case LIST_USER.SUCCESS:
			return {
				...state,
				response: action.response,
			}
		case LIST_USER.ERROR:
			return {
				...state,
				error: action.error,
			}
		default:
			return state
	}
}
