import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import XLSX from 'xlsx'
import { Alert } from '../../common/Alert'
import { Dropdown } from '../../common/DropDown'
import { ADMIN_MASTER_TYPES } from '../../constants'
import { saveSccIncompatibillityMasterError } from '../../redux/actions/adminActions'
import { getProductsMaster } from '../../services/manageBookingService'
import {
	getAirportsMaster,
	getCommodityMaster,
	getEmbargoMaster,
	getPivotInfoMaster,
	getSccMaster,
	getServicesMaster,
	getUldTypeMaster,
	getSccIncompatibleMaster,
	getStationScheduleMaster,
	getRegionMaster,
	getCountryMaster,
} from '../../services/searchPanelServices'
import LoadingIndicator from '../LoadingIndicator'
import './Admin.scss'
import { ExportAdmindata } from './ExportAdmindata'
import { SheetJSFT } from './Types'
import getCurrentUser from '../../utils/getCurrentUser'
// import { getSaveEmbargoResponse } from '../../services/adminMasterServices';

//Faheem
const ViewMasterDetails = ({
	getSaveCommodityResponse,
	saveCommodityResponse,
	saveCommodityError,
	clearSaveCommodityResponse,
	getSaveAirportMasterResponse,
	saveAirportMasterResponse,
	saveAirportMasterError,
	clearSaveAirportMasterResponse,
	getSaveServiceMasterResponse,
	saveServiceMasterResponse,
	saveServiceMasterError,
	clearSaveServiceMasterResponse,
	getSaveProductMasterResponse,
	saveProductMasterResponse,
	saveProductMasterError,
	clearSaveProductMasterResponse,
	getSaveSccMasterResponse,
	saveSccMasterResponse,
	saveSccMasterError,
	clearSaveSccMasterResponse,
	getSaveUldTypeMasterResponse,
	getSavePivotInfoMasterResponse,
	saveUldTypeMasterResponse,
	saveUldTypeMasterError,
	savePivotWeightInfoMasterError,
	savePivotWeightInfoMasterResponse,
	getSaveSccIncompatibilityMasterResponse,
	saveSccIncompatibilityMasterResponse,
	saveSccIncompatibilityMasterError,
	getSaveStationScheduleMasterResponse,
	saveStationScheduleMasterResponse,
	saveStationScheduleMasterError,
	getSaveRegionMasterResponse,
	saveRegionMasterResponse,
	saveRegionMasterError,
	getSaveCountryMasterResponse,
	saveCountryMasterResponse,
	saveCountryMasterError,

	getSaveEmbargoResponse,
	saveEmbargoResponse,
	saveEmbargoError,
	clearSaveEmbargoResponse,

}) => {
	const [fileName, setFileName] = useState(undefined)
	const [masterType, setMasterType] = useState('select')
	const [mappedResponse, setMappedResponse] = useState([])
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [csvData, setCsvData] = useState({
		productCsv: [],
		airportCsv: [],
		commodityCsv: [],
		serviceCsv: [],
		sccCsv: [],
		uldTypeCsv: [],
		pivotInfoCsv: [],
		sccIncompatibilityCsv: [],
		stationScheduleCsv: [],
		regionCsv: [],
		countryCsv: [],
		embargoCsv: [],
	})
	const EMPTY_SHEET_MESSAGE = 'Empty sheets cannot be uploaded'
	const UPLOAD_SUCCESS = 'Successfully uploaded'
	const [message, setMessage] = useState(null)
	//===================== mapping and changing keys here =========================//
	const productKeyMap = {
		Name: 'name',
		Description: 'description',
		Status: 'status',
		'System product name': 'systemProductName',
	}

	const serviceKeyMap = {
		CODE: 'code',
		ACTIVE: 'active',
		NAME: 'name',
		PRODUCT: 'product',
	}

	const sccKeyMap = {
		SCC: 'scc',
		ACTIVE: 'active',
		ADDITIONAL_SCC: 'additionalScc',
		SCC_DESCRIPTION: 'sccDescription',
	}

	const commodityKeyMap = {
		Code: 'code',
		Description: 'description',
		SCC: 'scc',
		Product: 'product',
		'Service selection': 'serviceSelection',
	}

	const airportKeyMap = {
		Name: 'name',
		Code: 'code',
		'City code': 'cityCode',
		'Country code': 'countryCode',
		Active: 'active',
		Latitude: 'latitude',
		Longitude: 'longitude',
		'Geo fencing radius': 'geoFencingRadius',
		'Geo fencing radius unit': 'geoFencingRadiusUnit',
	}

	const mapToResponse = (data, type) =>
		data.map((row) => {
			let mapped = {}
			Object.keys(row).forEach((key) => {
				return (mapped[type[key]] = row[key])
			})
			return mapped
		})
	//=============================================================================//

	useEffect(() => {
		; (async () => {
			setIsLoading(true)
			try {
				setCsvData({
					productCsv: await getProductsMaster(),
					airportCsv: await getAirportsMaster(),
					commodityCsv: await getCommodityMaster(),
					serviceCsv: await getServicesMaster(),
					sccCsv: await getSccMaster(),
					uldTypeCsv: await getUldTypeMaster(),
					pivotInfoCsv: await getPivotInfoMaster(),
					sccIncompatibilityCsv: await getSccIncompatibleMaster(),
					stationScheduleCsv: await getStationScheduleMaster(),
					regionCsv: await getRegionMaster(),
					countryCsv: await getCountryMaster(),
					embargoCsv: await getEmbargoMaster(),
				})
			} catch (error) {
				console.log(error.message)
			} finally {
				setIsLoading(false)
			}
		})()
	}, [])

	useEffect(() => {
		if (saveCommodityResponse && saveCommodityError === null) {
			setError(null)
			saveCommodityResponse?.message === 'S' && setMessage(UPLOAD_SUCCESS)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							commodityCsv: await getCommodityMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveCommodityError?.description)
		}
	}, [saveCommodityResponse, saveCommodityError])

	// Embargo Master

	useEffect(() => {
		if (saveEmbargoResponse && saveEmbargoError === null) {
			setError(null)
			saveEmbargoResponse?.message === 'S' && setMessage(UPLOAD_SUCCESS)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							embargoCsv: await getEmbargoMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveEmbargoError?.description)
		}
	}, [saveEmbargoResponse, saveEmbargoError])

	useEffect(() => {
		if (saveAirportMasterResponse && saveAirportMasterError === null) {
			setError(null)
			saveAirportMasterResponse?.message === 'S' && setMessage(UPLOAD_SUCCESS)
				; (async () => {
					try {
						setCsvData({
							...csvData,
							airportCsv: await getAirportsMaster(),
						})
					} catch (error) {
						console.log(error.message)
					}
				})()
		} else {
			setError(saveAirportMasterError?.description)
		}
	}, [saveAirportMasterResponse, saveAirportMasterError])

	useEffect(() => {
		if (saveServiceMasterResponse && saveServiceMasterError === null) {
			setError(null)
			saveServiceMasterResponse?.status === 'S' && setMessage(UPLOAD_SUCCESS)
				; (async () => {
					try {
						setCsvData({
							...csvData,
							serviceCsv: await getServicesMaster(),
						})
					} catch (error) {
						console.log(error.message)
					}
				})()
		} else {
			setError(saveServiceMasterError?.description)
		}
	}, [saveServiceMasterResponse, saveServiceMasterError])

	useEffect(() => {
		if (saveProductMasterResponse && saveProductMasterError === null) {
			setError(null)
			saveProductMasterResponse?.message === 'S' && setMessage(UPLOAD_SUCCESS)
				; (async () => {
					try {
						setCsvData({
							...csvData,
							productCsv: await getProductsMaster(),
						})
					} catch (error) {
						console.log(error.message)
					}
				})()
		} else {
			setError(saveProductMasterError?.description)
		}
	}, [saveProductMasterResponse, saveProductMasterError])

	useEffect(() => {
		if (saveSccMasterResponse && saveSccMasterError === null) {
			setError(null)
			saveSccMasterResponse?.status === 'S' ? setMessage(UPLOAD_SUCCESS) : setError(saveSccMasterResponse?.errorDetails?.message)
				; (async () => {
					try {
						setCsvData({
							...csvData,
							sccCsv: await getSccMaster(),
						})
					} catch (error) {
						console.log(error.message)
					}
				})()
		} else {
			setError(saveSccMasterError?.description)
		}
	}, [saveSccMasterResponse, saveSccMasterError])

	useEffect(() => {
		if (saveUldTypeMasterResponse && saveUldTypeMasterError === null) {
			setError(null)
			saveUldTypeMasterResponse?.message === 'S' && setMessage(UPLOAD_SUCCESS)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							uldTypeCsv: await getUldTypeMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveUldTypeMasterError?.description)
		}
	}, [saveUldTypeMasterResponse, saveUldTypeMasterError])

	useEffect(() => {
		if (
			savePivotWeightInfoMasterResponse &&
			savePivotWeightInfoMasterError === null
		) {
			savePivotWeightInfoMasterResponse?.message === 'S' &&
				setMessage(UPLOAD_SUCCESS)
			setError(null)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							pivotInfoCsv: await getPivotInfoMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(savePivotWeightInfoMasterError?.description)
		}
	}, [savePivotWeightInfoMasterResponse, savePivotWeightInfoMasterError])

	useEffect(() => {
		if (
			saveSccIncompatibilityMasterResponse &&
			saveSccIncompatibilityMasterError === null
		) {
			saveSccIncompatibilityMasterResponse?.message === 'S' &&
				setMessage(UPLOAD_SUCCESS)
			setError(null)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							sccIncompatibilityCsv: await getSccIncompatibleMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveSccIncompatibilityMasterError?.description)
		}
	}, [saveSccIncompatibilityMasterResponse, saveSccIncompatibilityMasterError])

	useEffect(() => {
		if (
			saveStationScheduleMasterResponse &&
			saveStationScheduleMasterError === null
		) {
			setError(null)
			saveStationScheduleMasterResponse?.message === 'S' &&
				setMessage(UPLOAD_SUCCESS)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							stationScheduleCsv: await getStationScheduleMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveStationScheduleMasterError?.description)
		}
	}, [saveStationScheduleMasterResponse, saveStationScheduleMasterError])

	useEffect(() => {
		if (
			saveRegionMasterResponse &&
			saveRegionMasterError === null
		) {
			setError(null)
			saveRegionMasterResponse?.message === 'S' &&
				setMessage(UPLOAD_SUCCESS)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							regionCsv: await getRegionMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveRegionMasterError?.description)
		}
	}, [saveRegionMasterResponse, saveRegionMasterError])

	useEffect(() => {
		if (
			saveCountryMasterResponse &&
			saveCountryMasterError === null
		) {
			setError(null)
			saveCountryMasterResponse?.message === 'S' &&
				setMessage(UPLOAD_SUCCESS)
				; (async () => {
					setIsLoading(true)
					try {
						setCsvData({
							...csvData,
							countryCsv: await getCountryMaster(),
						})
					} catch (error) {
						console.log(error.message)
					} finally {
						setIsLoading(false)
					}
				})()
		} else {
			setError(saveCountryMasterError?.description)
		}
	}, [saveCountryMasterResponse, saveCountryMasterError])

	const handleChange = (files) => {
		var f = files[0]
		var reader = new FileReader()
		reader.onload = (e) => {
			var data = e.target.result
			let readedData = XLSX.read(data, { type: 'binary' })
			const wsname = readedData.SheetNames[0]
			const ws = readedData.Sheets[wsname]

			/* Convert array to json*/
			const parsedData = XLSX.utils.sheet_to_json(ws)
			setError(null)
			setMappedResponse(parsedData)
			setFileName(f?.name)
		}
		reader.readAsBinaryString(f)
	}

	const handleUpload = (type) => {
		setIsLoading(true)
		const userDetails = getCurrentUser()
		if (type === 'select') {
		} else if (type === 'commodity') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				// 'commodity': mapToResponse(mappedResponse, commodityKeyMap)
				commodity: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveCommodityResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'airport') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				airport: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveAirportMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'service') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				service: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveServiceMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'product') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				product: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveProductMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'scc') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				scc: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveSccMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'uldType') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				uldType: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveUldTypeMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'pivotInfo') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				pivotWeightInfo: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSavePivotInfoMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'sccIncompatible') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				sccIncompatibility: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveSccIncompatibilityMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'stationSchedule') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				stationSchedule: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveStationScheduleMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		} else if (type === 'region') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				region: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveRegionMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		}
		else if (type === 'country') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				country: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveCountryMasterResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		}

		else if (type === 'embargoMaster') {
			const payload = {
				requestId: uuidv4(),
				fileName: fileName,
				embargoRestrictionDetails: mappedResponse,
				userId: userDetails?.userId,
			}
			mappedResponse?.length > 0
				? getSaveEmbargoResponse(payload)
				: setError(EMPTY_SHEET_MESSAGE)
		}

		else {
			setIsLoading(false)
			return
		}
		setFileName(undefined)
		setMasterType('select')
		setMappedResponse([])
		setIsLoading(false)
	}

	const handleAlertClose = () => {
		setError('')
	}

	const handleAlertCloseSuccess = () => {
		setMessage(null)
	}

	return (
		<>
			{error != null && (
				<div className='cargo-portal-container'>
					<Alert
						isError={error != null}
						showAlert={true}
						message={error != null && error}
						onAlertClose={handleAlertClose}
						setTimeout={true}
					/>
				</div>
			)}
			{message != null && (
				<div className='master-alert-wrap'>
					<Alert
						isError={false}
						showAlert={true}
						message={message}
						onAlertClose={handleAlertCloseSuccess}
						setTimeout={true}
					/>
				</div>
			)}
			<div className='admin-page__upload-panel'>
				<div className='form-group col-md-4 col-lg-3 pr-0 pr-md-3'>
					<div className='form-group'>
						<label htmlFor='masterType' className='form-item__label'>
							Select your master
						</label>
						<div className='form-item'>
							<Dropdown
								id='masterType'
								className='cust-dropdown'
								value={masterType}
								options={ADMIN_MASTER_TYPES}
								placeholder='Select'
								onChange={(e) => {
									setMasterType(e.target.value)
								}}
							></Dropdown>
						</div>
					</div>
				</div>
				<div className='choose-file-master-panel'>
					<div className='selected-file-panel'>
						<label for='fileButton' className='button-choose'>
							<div className='choose-file-button-panel'>Choose file</div>
						</label>
						<div className='selected-showcase'>
							{fileName != undefined ? fileName : ''}
						</div>
					</div>
					<input
						type='file'
						id='fileButton'
						accept={SheetJSFT}
						onChange={(e) => {
							handleChange(e.target.files)
							e.target.value = ''
						}}
						style={{ display: 'none' }}
					/>
					<span className='search-panel__errormsg mt-2'>{''}</span>
				</div>
				<button
					className='l-btn--primary'
					onClick={() => handleUpload(masterType)}
				>
					Upload
				</button>
			</div>
			{!isLoading ? (
				<div className='admin-page__download-panel mt-4'>
					<label className='heading'>Master list</label>
					<div className='list-container'>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Product master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.productCsv?.fileName != null
										? csvData?.productCsv?.fileName
										: 'Product_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.productCsv?.products?.length > 0
												? csvData?.productCsv?.products
												: []
										}
										fileName={`${csvData && csvData.productCsv && csvData.productCsv.fileName
											? csvData.productCsv.fileName
											: 'Product_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Service master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.serviceCsv?.fileName != null
										? csvData?.serviceCsv?.fileName
										: 'Service_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.serviceCsv?.services?.length > 0
												? csvData?.serviceCsv?.services
												: []
										}
										fileName={`${csvData && csvData.serviceCsv && csvData.serviceCsv.fileName
											? csvData.serviceCsv.fileName
											: 'Service_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>SCC master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.sccCsv?.fileName != null
										? csvData?.sccCsv?.fileName
										: 'Scc_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.sccCsv?.scc?.length > 0 ? csvData?.sccCsv?.scc : []
										}
										fileName={`${csvData && csvData.sccCsv && csvData.sccCsv.fileName
											? csvData.sccCsv.fileName
											: 'Scc_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Commodity master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.commodityCsv?.fileName != null
										? csvData?.commodityCsv?.fileName
										: 'Commodity_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.commodityCsv?.commodities?.length > 0
												? csvData?.commodityCsv?.commodities
												: []
										}
										fileName={`${csvData &&
											csvData.commodityCsv &&
											csvData.commodityCsv.fileName
											? csvData.commodityCsv.fileName
											: 'Commodity_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Airport master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.airportCsv?.fileName != null
										? csvData?.airportCsv?.fileName
										: 'Airport_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.airportCsv?.airports?.length > 0
												? csvData?.airportCsv?.airports
												: []
										}
										fileName={`${csvData && csvData.airportCsv && csvData.airportCsv.fileName
											? csvData.airportCsv.fileName
											: 'Airport_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>ULD type master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.uldTypeCsv?.fileName != null
										? csvData?.uldTypeCsv?.fileName
										: 'ULD_Type_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.uldTypeCsv?.uldType?.length > 0
												? csvData?.uldTypeCsv?.uldType
												: []
										}
										fileName={`${csvData && csvData.uldTypeCsv && csvData.uldTypeCsv.fileName
											? csvData.uldTypeCsv.fileName
											: 'ULD_Type_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Pivot info master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.pivotInfoCsv?.fileName != null
										? csvData?.pivotInfoCsv?.fileName
										: 'Pivot_Info_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.pivotInfoCsv?.pivotWeightInfo?.length > 0
												? csvData?.pivotInfoCsv?.pivotWeightInfo
												: []
										}
										fileName={`${csvData &&
											csvData.pivotInfoCsv &&
											csvData.pivotInfoCsv.fileName
											? csvData.pivotInfoCsv.fileName
											: 'Pivot_Info_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>SCC incompatibility master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.sccIncompatibilityCsv?.fileName != null
										? csvData?.sccIncompatibilityCsv?.fileName
										: 'Scc_Incompatibility_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.sccIncompatibilityCsv?.sccIncompatibility?.length > 0
												? csvData?.sccIncompatibilityCsv?.sccIncompatibility
												: []
										}
										fileName={`${csvData &&
											csvData.sccIncompatibilityCsv &&
											csvData.sccIncompatibilityCsv.fileName
											? csvData.sccIncompatibilityCsv.fileName
											: 'Scc_Incompatibility_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Station schedule master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.stationScheduleCsv?.fileName != null
										? csvData?.stationScheduleCsv?.fileName
										: 'Station_Schedule_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.stationScheduleCsv?.stationSchedule?.length > 0
												? csvData?.stationScheduleCsv?.stationSchedule
												: []
										}
										fileName={`${csvData &&
											csvData.stationScheduleCsv &&
											csvData.stationScheduleCsv.fileName
											? csvData.stationScheduleCsv.fileName
											: 'Station_Schedule_Master.xlsx'
											}`}
									/>
								</p>
							</div>



						</div>


						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Embargo restricted master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.embargoCsv?.fileName != null
										? csvData?.embargoCsv?.fileName
										: 'Embargo_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.embargoCsv?.embargoRestrictionDetails?.length > 0
												? csvData?.embargoCsv?.embargoRestrictionDetails
												: []
										}
										fileName={`${csvData && csvData.embargoCsv && csvData.embargoCsv.fileName
											? csvData.embargoCsv.fileName
											: 'Embargo_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>

						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Region master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.regionCsv?.fileName != null
										? csvData?.regionCsv?.fileName
										: 'Region_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.regionCsv?.region?.length > 0 ? csvData?.regionCsv?.region : []
										}
										fileName={`${csvData && csvData.regionCsv && csvData.regionCsv.fileName
											? csvData.regionCsv.fileName
											: 'Region_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
						<div className='master-list-items'>
							<div className='master-list-items__first-element'>
								<p>Country master</p>
							</div>
							<div className='master-list-items__first-element'>
								<p>
									{csvData?.countryCsv?.fileName != null
										? csvData?.countryCsv?.fileName
										: 'Country_Master.xlsx'}
								</p>
							</div>
							<div className='master-list-items__second-element'>
								<p>
									<ExportAdmindata
										csvData={
											csvData?.countryCsv?.country?.length > 0 ? csvData?.countryCsv?.country : []
										}
										fileName={`${csvData && csvData.countryCsv && csvData.countryCsv.fileName
											? csvData.countryCsv.fileName
											: 'Country_Master.xlsx'
											}`}
									/>
								</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoadingIndicator />
			)}
		</>
	)
}

export default ViewMasterDetails
