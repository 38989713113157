import { FLIGHT_ENQUIRY } from '../constants'

export const loadFlightEnquiry = (formData) => ({
	type: FLIGHT_ENQUIRY.LOAD,
	formData,
})
export const flightEnquirySuccess = (response) => ({
	type: FLIGHT_ENQUIRY.SUCCESS,
	response,
})
export const flightEnquiryError = (error) => ({
	type: FLIGHT_ENQUIRY.ERROR,
	error,
})
export const clearflightEnquiry = () => ({
	type: FLIGHT_ENQUIRY.CLEAR,
})
