import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


const ViewPermanentBooking = ({
    viewBookingItem
}) => {
    const { t, i18n } = useTranslation(['labels', 'errors']);

    let bookingItem = JSON.parse(viewBookingItem.requestParameters);
    let awbPrefix = viewBookingItem?.awbNumber?.substring(0, 3);
    let awbNumber = viewBookingItem?.awbNumber?.substring(3, viewBookingItem?.awbNumber.length);


    return (
        <form>
            <div>
                <div className='mt-4 pt-2'>
                        <div className="row pb-main-sub align-items-start">
                            <div
                                className=" col-md-2"
                            >
                                <label className='col f-15'>{t('COMMON.ORG')}</label>
                                <span className='col f-16'>{bookingItem?.shipmentDetails?.shipmentOrigin}</span>
                            </div>
                            <div
                                className=" col-md-2"
                            >
                                <label className='col f-15'>{t('COMMON.DEST')}</label>
                                <span className='col f-16'>{bookingItem?.shipmentDetails?.shipmentDestination}</span>
                            </div>
                            <div
                                className=" col-md-4"
                            >
                                <label className='col f-15'>{t('COMMON.SHP_ITEM')}</label>
                                <span className='col f-16'>{bookingItem?.bookingCommodityDetails[0].commodityCode} - {bookingItem?.shipmentDetails?.productDetails?.productName} - {bookingItem?.sccCodes[0]}</span>
                            </div>
                        </div>
                        <div className='pb-main-sub row align-items-start'>
                            <div
                                className=" col-md-4"
                            >
                                <label className='col f-15'>{t('DASHBOARD.SHIPMT_DATE')}</label>
                                <span className='col f-16'>{bookingItem?.shipmentDetails?.shippingDate}</span>
                            </div>   
                            <div
                                className="col-md-3"                           >   
                                <label className='col f-15'>{t('PERMANENT_BOOKING.AWB')}</label>
                                <span className='col f-16'>{awbPrefix}-{awbNumber}</span>          
                            </div>
                            <div
                                className="status col-md-3"
                            >
                                <label className='col f-15'>{t('COMMON.STATUS')}</label>
                                <span className='col f-16'>{viewBookingItem?.status}</span>
                            </div>
                        </div>
                    </div>
                
                <div className="mb-3 pb-sub-head-custom">
                    <h2>{t('COMMON.DTLS')}</h2>
                </div>
                <div class="pb-main-sub row align-items-start">
                    <div class="col-md-2">
                        <label className='col f-15'>SHC-1</label>
                        <span className='col f-16'>{bookingItem?.sccCodes[1]}</span>
                    </div>

                    <div class="col-md-2">
                        <label className=' col f-15'>SHC-2</label>
                        <span className='col f-16'>{bookingItem?.sccCodes[2]}</span>
                    </div>

                    <div class="col-md-2">
                        <label className='col f-15'>SHC-3</label>
                        <span className='col f-16'>{bookingItem?.sccCodes[3]}</span>
                    </div>

                    <div class="col-md-4">
                        <label className='col f-15'>{t('BOOKING.PROMO_CODE')}</label>
                        <span className='col f-16'>{bookingItem?.promoCode}</span>
                    </div>
                </div>
                <div className="mb-3 pb-sub-head">
                    {bookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills ? 
                        <h2>{t('PERMANENT_BOOKING.ULD_DETAILS')}</h2> : <></>
                    }
                    {bookingItem?.bookingCommodityDetails[0]?.dimensionDetaills ? 
                        <h2>{t('PERMANENT_BOOKING.DIMENSION_DETAILS')}</h2> : <></>
                    }
                </div>
                {bookingItem?.bookingCommodityDetails[0]?.dimensionDetaills ? 
                <div class="pb-main-sub row align-items-start mb-3">
                    <div class="col-md-4">
                        <label className='col f-15'>{t('COMMON.TOTAL_WT')}</label>
                        <span className='col f-15'>{bookingItem?.bookingCommodityDetails[0]?.weight}</span>
                    </div>
                </div> : <></>
                    }
                    {
                        bookingItem?.bookingCommodityDetails[0]?.uldInfoDetaills?.map((uldInfoDetail, index) => {
                            return (

                <div class="pb-main-sub row align-items-start mb-3">
                    
                    <div class="col-md-2">
                        <label className='col f-15'>{t('COMMON.ULD_TYPE')}</label>
                        <span className='col f-15'>{uldInfoDetail?.uldType}</span>
                    </div>

                    <div class="col-md-2">
                        <label className=' col f-15'>{t('COMMON.CONTOUR')}</label>
                        <span className='col f-15'>{uldInfoDetail?.contour}</span>
                    </div>

                    <div class="col-md-2">
                        <label className='col f-15'>{t('COMMON.NO_OF_UNITS')}</label>
                        <span className='col f-15'>{uldInfoDetail?.numberOfUlds}</span>
                    </div>

                    <div class="col-md-4">
                        <label className='col f-15'>{t('COMMON.WT_PER_UNIT')}</label>
                        <span className='col f-15'>{uldInfoDetail?.uldWeight}</span>
                    </div>
                </div>
                
                            )
                        })
                    }
{/* Loose details START */}
                    {
                        bookingItem?.bookingCommodityDetails[0]?.dimensionDetaills?.map((dimDetail, index) => {
                            return (

                <div class="pb-main-sub row align-items-start mb-3">
                    
                    <div class="col-md-2">
                        <label className='col f-15'>{t('PERMANENT_BOOKING.NO_PIECES')}</label>
                        <span className='col f-15'>{dimDetail?.numberOfPieces}</span>
                    </div>

                    <div class="col-md-2">
                        <label className=' col f-15'>{t('COMMON.LN')}</label>
                        <span className='col f-15'>{dimDetail?.length}</span>
                    </div>

                    <div class="col-md-2">
                        <label className='col f-15'>{t('COMMON.WD')}</label>
                        <span className='col f-15'>{dimDetail?.width}</span>
                    </div>
                    <div class="col-md-2">
                        <label className='col f-15'>{t('COMMON.HT')}</label>
                        <span className='col f-15'>{dimDetail?.height}</span>
                    </div>

                </div>
                
                            )
                        })
                    }
            
{/* Loose details END */}  
        
            
                
                <div className="mb-3 pb-sub-head">
                    <h2>{t('PERMANENT_BOOKING.FLIGHT_INFORMATION')}</h2>
                </div>
                <div className=" row pb-main-sub">
                    {
                        bookingItem?.bookingFlightDetails.map((flightDetails, index) => {
                            return (
                                <div className="col-md-12 mt-3">
                                    <div className="mb-2">
                                        <p className='align-items-start'>{t('PERMANENT_BOOKING.LEG')} {index + 1}</p>
                                    </div>
                                    <div class="row align-items-start mb-3">
                                        <div class="col-md-2">

                                            <label className='col f-15'>{t('CHOOSE_FLT.FROM')}</label>
                                            <span className='col f-15'>{flightDetails?.segmentOrigin}</span>
                                        </div>

                                        <div class="col-md-2">
                                            <label className=' col f-15'>{t('PERMANENT_BOOKING.TO')}</label>
                                            <span className='col f-15'>{flightDetails?.segmentDestination}</span>
                                        </div>

                                        <div class="col-md-2">
                                            <label className='col f-15'>{t('COMMON.FLT_NO')}</label>
                                            <span className='col f-15'>{flightDetails?.carrierCode}{flightDetails?.flightNumber}</span>
                                        </div>

                                        <div class="col-md-2">
                                            <label className='col f-15'>{t('PERMANENT_BOOKING.DATE')}</label>
                                            <span className='col f-15'>{flightDetails?.flightDate}</span>
                                        </div>
                                        <div class="col-md-2">
                                            <label className='col f-15'>{t('DASHBOARD.ALLMNT')}</label>
                                            <span className='col f-15'>{flightDetails?.allotmentId ? flightDetails?.allotmentId : 'FS'}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </form>)
}



export default ViewPermanentBooking;