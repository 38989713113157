import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useRef, useState } from 'react'
import {
	getCountryByCode,
	getCountries,
	getCountryByName,
} from '../../services/dataCaptureService'
import { AUTOCOMPLETE_TYPE_COUNTRY } from '../../constants'
import './index.scss'
import {capitalizeName } from '../../utils/common'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
const CountryAutocomplete = ({
	autoCompleteType,
	selectedShipperCountry,
	setSelectedShipperCountry,
	selectedCosigneeCountry,
	setSelectedCosigneeCountry,
	isMandatory,
	setError,
	fieldName,
}) => {
	const { t } = useTranslation(['labels','errors'])

	const [text, setText] = useState(null)
	const [filteredCountries, setFilteredCountries] = useState([])
	const [countries, setCountries] = useState(null)
	const [selectedCountry, SetSelectedCountry] = useState(null)
	const countryRef = useRef(null)
	const isFocused = useRef(fieldName ? fieldName : false)

	useEffect(() => {
		if (!text) {
			getFilteredCountries({ query: text })
		}
	}, [text])

	useEffect(() => {
		if (autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER) {
			SetSelectedCountry(selectedShipperCountry)
		} else {
			SetSelectedCountry(selectedCosigneeCountry)
		}
	}, [selectedShipperCountry, selectedCosigneeCountry])

	const handleSelect = (country) => {
		autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER
			? setSelectedShipperCountry(country)
			: setSelectedCosigneeCountry(country)
	}

	const handleOnBlur = async (e) => {
		let countryByCode
		let countryByName

		setTimeout(() => {
			setFilteredCountries([])
			if (countryRef.current) {
				countryRef.current.hideOverlay()
			}
		}, 500)

		if (e.relatedTarget !== null) {
			if (text && text.length === 2) {
				try {
					countryByCode = await getCountryByCode(text)				
					if (countryByCode && countryByCode.countryCode) {
						countryByCode.countryName = capitalizeName(countryByCode.countryName)
						autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER
							? setSelectedShipperCountry(countryByCode)
							: setSelectedCosigneeCountry(countryByCode)
						setText(null)
						return
					}
				} catch (err) {
					console.log(err)
				}
			}
		} else {
			if (
				text &&
				text.length &&
				typeof text === 'string' &&
				!filteredCountries.length
			) {
				try {
					countryByName = await getCountryByName(text)				
					if (countryByName && countryByName.countryCode) {
						countryByName.countryName = capitalizeName(countryByName.countryName)
						autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER
							? setSelectedShipperCountry(countryByName)
							: setSelectedCosigneeCountry(countryByName)
						setText(null)
						return
					}
				} catch (err) {
					console.log(err)
				}
			}
		}

		setText(null)
		if (isMandatory && setError) {
			if (autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER) {
				if (!selectedShipperCountry && !countryByCode && !countryByName) {
					setError((prevState) => ({
						...prevState,
						shipperCountry: Messages.FIELD_REQUIRED,
					}))
				}
			} else if (autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.CONSIGNEE) {
				if (!selectedCosigneeCountry && !countryByCode && !countryByName) {
					if(fieldName) {
						setError((prevState) => ({
							...prevState,
							[fieldName]: Messages.FIELD_REQUIRED,
						}))
					} else {
						setError((prevState) => ({
							...prevState,
							consigneeCountry: Messages.FIELD_REQUIRED,
						}))
					}
					
				}
			}
		}
	}

	const searchCountry = async (e) => {
		const selectedCountry = e.query
		if (!selectedCountry || selectedCountry.length < 1) {
			setCountries([])
			setFilteredCountries([])
			return
		}

		if (selectedCountry && selectedCountry.length >= 1) {
			let countries
			try {
				const responseBody = await getCountries(selectedCountry)
				countries = responseBody.map((country) => {
					country.countryName = capitalizeName(country.countryName)
					return country
				})
			} catch (err) {
				countries = []
				console.log(err.message)
			}
			if (isFocused.current) {
				setCountries([...countries])
				setFilteredCountries(getFilteredCountries(countries))
			} else {
				setCountries([])
				setFilteredCountries([])
			}
			return
		}

		if (isFocused.current) {
			setFilteredCountries(getFilteredCountries(countries))
		}
	}

	const getFilteredCountries = (countries) => {
		const countriesByCodeStart =
			countries && countries.length && text && text.length
				? countries.filter((country) => {
						return country.countryCode
							.toLowerCase()
							.startsWith(text.toLowerCase())
				  })
				: []
		const countriesByNameStart =
			countries && countries.length && text && text.length
				? countries.filter((country) => {
						return ` ${country.countryName.toLowerCase()}`.startsWith(
							` ${text.toLowerCase()}`
						)
				  })
				: []
		const countriesByContains =
			countries && countries.length && text && text.length
				? countries.filter((country) => {
						return (
							country.countryCode.toLowerCase().includes(text.toLowerCase()) ||
							country.countryName.toLowerCase().includes(text.toLowerCase())
						)
				  })
				: []
		return [
			...countriesByCodeStart,
			...countriesByNameStart,
			...countriesByContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.countryCode === item.countryCode)
		)
	}

	const itemTemplate = (item) => {
		return (
			<div>
				{`${item.countryName},`}
				<b>{` ${item.countryCode}`}</b>
			</div>
		)
	}

	return (
		<>
			<AutoComplete
				value={
					selectedCountry && selectedCountry.countryCode
						? `${selectedCountry.countryName}, ${selectedCountry.countryCode}`
						: text
				}
				ref={countryRef}
				suggestions={filteredCountries}
				completeMethod={searchCountry}
				onChange={(e) => {
					autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER
						? setSelectedShipperCountry(null)
						: setSelectedCosigneeCountry(null)
					setText(e.value)
				}}
				onSelect={(e) => {
					handleSelect(e.value)
				}}
				onFocus={(e) => {
					if (autoCompleteType === AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER) {
						isFocused.current = true
						if (isMandatory && setError) {
							setError((prevState) => ({
								...prevState,
								shipperCountry: '',
							}))
						}
					} else {
						isFocused.current = true
						if (isMandatory && setError) {
							if(fieldName) {
								setError((prevState) => ({
									...prevState,
									[fieldName]: '',
								}))
							} else {
								setError((prevState) => ({
									...prevState,
									consigneeCountry: '',
								}))
							}
							
						}
						if(!isMandatory && setError && fieldName) {
								setError((prevState) => ({
									...prevState,
									[fieldName]: '',
								}))
						}
					}
				}}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onBlur={(e) => {
					isFocused.current = false
					handleOnBlur(e)
				}}
				className='form-item__field country-autocomplete-inner-wrapper'
				placeholder='Country'
				itemTemplate={itemTemplate}
			/>
		</>
	)
}

export default CountryAutocomplete
