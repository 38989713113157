import React from 'react'
import './loading-indicator.scss'

const LoadingIndicator = () => {
	return (
		// <div className='loading-wrapper'>
		// 	<div className='loading__spinner'>
		// 		<div className='loading__inner'>
		// 			<div></div>
		// 		</div>
		// 	</div>
		// </div>
		<div className='spinnerdiv ui-g-12 text-center'>
			<div className='loading__inner'>
				<div></div>
			</div>
		</div>
	)
}

export default LoadingIndicator
