import { POPUP, SAVE_SERVICE_MASTER } from '../constants'

const initState = {
    request: null,
    response: null,
    error: null,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SAVE_SERVICE_MASTER.LOAD:
            return {
                ...state,
                request: action.request,
                response: null,
                error: null,
            }
        case SAVE_SERVICE_MASTER.SUCCESS:
            return {
                ...state,
                response: action.response,
            }
        case SAVE_SERVICE_MASTER.ERROR:
            return {
                ...state,
                error: action.error,
            }
        case POPUP.CLOSE:
            return {
                ...state,
                error: null,
            }
        case SAVE_SERVICE_MASTER.CLEAR:
            return {
                ...state,
                response: null,
                error: null,
            }
        default:
            return state
    }
}