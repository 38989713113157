import React, { useEffect, useState } from 'react'
import { getNewsFeed } from '../../../services/dashboardServices'
import './index.scss'
import NewsCard from './NewsCard'
import { addError } from '../../../utils/analytics'
import { useTranslation } from 'react-i18next'
const NewsFeed = () => {
	const [articles, setArticles] = useState(null)
	const [alerts, setAlerts] = useState(null)
    const { t, i18n } = useTranslation(['labels','errors'])

	useEffect(() => {
    ;(async () => {
        try {
           const response = await getNewsFeed()
          let alertsArray = [];
          let articlesArray = [];
          if(response && response.alerts && response.alerts.length > 0){
          if(response.alerts.length >=6){
			alertsArray = response.alerts.splice(0,6);
}
else{
alertsArray = response.alerts;
}
}
if (response && response.articles && response.articles.length > 0) {
articlesArray = response.articles;

}
setArticles([...alertsArray].slice(0,6))
setArticles([...alertsArray.concat(...articlesArray)].slice(0,6))
} catch (error) {
addError('NEWS_FEED_ERROR',error)
}
})()
}, [])

	return (
		<div className='news-details-main-wrapper' id='news-details-main'>
			<div className='row'>
				<div className='col-12'>
				<h3 className='section-heading m-0'>{t('EXTRAS.NEWS_UPDATES')}</h3>

				</div>
			</div>
		    {articles && articles.length && <NewsCard articles={articles} />}
		</div>
	)
}
export default NewsFeed