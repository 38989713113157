import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import editIcon from '../../assets/images/edit.png'
import calendarIcon from '../../assets/images/Calendar.svg'
import { ALLOWABLE_BOOKING_DATE } from '../../configs/appConfigs'
import { getFormattedNumber, getWeightUnit } from '../../constants'
import { getBase64Encoded, setToSessionStorage } from '../../utils/StorageUtils'
import './shipment-summary.scss'

const ShipmentSummary = ({ searchFormData, setSeachFormData }) => {
	const { origin, destination, shippingDate } = searchFormData
	const history = useHistory()

	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const goToHome = (e) => {
		if (searchFormData.isModifyBooking && searchFormData.awbNo) {
			history.push({
				pathname: `/booking/modify/${searchFormData.awbNo}`,
				state: {
					isBackButtonAction: true,
				},
			})
		} else {
			history.push('/booking/search')
		}
	}

	const [selectedShippingDate, setShippingDate] = useState(
		moment(shippingDate).toDate()
	)

	useEffect(() => {
		if (searchFormData) {
			setShippingDate(moment(shippingDate, 'DD-MMM-YYYY').toDate())
		}

	}, [searchFormData])

	const handleDateChange = (date) => {
		setShippingDate(date)
		const updatedSearchFormData = {
			...searchFormData,
			orginalShipingDate: moment(date).format('DD-MMM-YYYY'),
			shippingDate: moment(date).format('DD-MMM-YYYY'),
		}
		setToSessionStorage('searchObject', getBase64Encoded(updatedSearchFormData))
		setSeachFormData(updatedSearchFormData)
	}

	const getPetsSum = (cages) => {
		let petList = cages.map((item) => {
			return item.pets.length
		})
		let sum = 0;
		for (const item of petList) {
			sum += item;
		}
		if (sum > 1) {
			return sum + ' Pets';
		}
		else {
			return sum + ' Pet';
		}
	}

	const getCageString = (length) => {
		let cageString = length > 1 ? 'Containers' : 'Container'
		return cageString;
	}

	return (
		<div className='shipment-summary'>
			<div className='d-none d-lg-flex align-items-center flex-fill'>
				<div className='shipment-summary__item'>
					{/*<i>
							<FontAwesomeIcon icon={faMapMarkerAlt} />
						</i>*/}
					{origin && destination && (
						<span>{`${origin.code}-${destination.code}`}</span>
					)}
					{!origin && !destination && <span>{`${origin}-${destination}`}</span>}
				</div>
				<div className='shipment-summary__separator'></div>

				<div className='shipment-summary__item border-r-0 calender-icon mr-2'>
					{/* <i className='departure-icon'>
							<img src={departureIcon} alt='departure' title='departure' />
					</i> */}
					<span>
						{shippingDate
							? moment(selectedShippingDate, 'DD-MMM-YYYY').format('ddd Do MMM')
							: ''}
					</span>

					<div className='shipment-summary__item shipment-summary__filter-calendar'>
						<Calendar
							dateFormat='dd/M/yy'
							value={selectedShippingDate}
							minDate={moment().toDate()}
							maxDate={searchFormData && searchFormData.arrivalDate ? (moment(searchFormData.arrivalDate, 'DD-MMM-YYYY').toDate()) :
								(moment()
									.add(ALLOWABLE_BOOKING_DATE - 1, 'days')
									.toDate())
							}
							showOtherMonths={false}
							onChange={(e) => {
								handleDateChange(e.value)
							}}
							placeholder='Shipping Date'
							showIcon='true'
						/>
						<i className='edit-icon'>
							<img src={calendarIcon} alt='calendar' title='calendar' />
						</i>
					</div>
				</div>
				<div className='shipment-summary__separator' style={{ paddingRight: "10px" }}></div>
				<div className='shipment-summary__item shipment-summary__item__commodity'>
					{searchFormData.shippingItemCode?.code}
					{' - '}
					{searchFormData.shippingItemCode?.product}
				</div>
				<div className='shipment-summary__separator'></div>
				<div className='shipment-summary__item'>
					<span> {searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? t('COMMON.QTY') : t('COMMON.PCS')}: </span>
					{searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? searchFormData.crates && searchFormData.crates[0]?.weight ? `${getPetsSum(searchFormData.crates)} in ${searchFormData.crates.length} ${getCageString(searchFormData.crates.length)}` : '' : getFormattedNumber(searchFormData.totalPieces)}
				</div>
				<div className='shipment-summary__separator'></div>
				<div className='shipment-summary__item border-r-0'>
					<span>{t('COMMON.GROSS_WT')}: </span>
					{searchFormData.grossWeightInSelectedUnit}
					{getWeightUnit(searchFormData.weightUnit)}
				</div>

				<div className='shipment-summary__item__right'>
					<a className='l-link d-none d-md-block d-lg-block' onClick={goToHome}>
						<span>{t('COMMON.MODIFY')}</span>
					</a>
					<span className='shipment-summary_modify-mobile d-md-none d-lg-none'>
						<img src={editIcon} alt='modify-mobile' title='modify-mobile' />
					</span>
				</div>
			</div>
			<div className='d-flex flex-column justify-content-center d-lg-none lh-1'>
				<div>
					<div className='shipment-summary__item'>
						{/*<i>
								<FontAwesomeIcon icon={faMapMarkerAlt} />
							</i>*/}
						{origin && destination && (
							<span>{`${origin.code}-${destination.code}`}</span>
						)}
						{!origin && !destination && <span>{`${origin}-${destination}`}</span>}
					</div>
					<div className='shipment-summary__item border-r-0 calender-icon mr-2'>
						{/* <i className='departure-icon'>
								<img src={departureIcon} alt='departure' title='departure' />
						</i> */}
						<span>
							{shippingDate
								? moment(selectedShippingDate, 'DD-MMM-YYYY').format('ddd Do MMM')
								: ''}
						</span>

						<div className='shipment-summary__item shipment-summary__filter-calendar'>
							<Calendar
								dateFormat='dd/M/yy'
								value={selectedShippingDate}
								minDate={moment().toDate()}
								maxDate={searchFormData && searchFormData.arrivalDate ? (moment(searchFormData.arrivalDate, 'DD-MMM-YYYY').toDate()) :
									(moment()
										.add(ALLOWABLE_BOOKING_DATE - 1, 'days')
										.toDate())
								}
								showOtherMonths={false}
								onChange={(e) => {
									handleDateChange(e.value)
								}}
								placeholder='Shipping Date'
								showIcon='true'
							/>
							<i className='edit-icon'>
								<img src={calendarIcon} alt='calendar' title='calendar' />
							</i>
						</div>
					</div>
				</div>
				<div>
					<div className='shipment-summary__item'>
						<span> {searchFormData.shippingItemCode.code === "DOG" || searchFormData.shippingItemCode.code === "CAT" ? t('COMMON.CRTS') : t('COMMON.PCS')}: </span>
						{getFormattedNumber(searchFormData.totalPieces)}
					</div>
					<div className='shipment-summary__item border-r-0'>
						<span>{t('COMMON.GROSS_WT')}: </span>
						{searchFormData.grossWeightInSelectedUnit}
						{getWeightUnit(searchFormData.weightUnit)}
					</div>
				</div>
				<div className='shipment-summary__item__right'>
					<a className='l-link' onClick={goToHome}>
						<span className='d-none d-lg-block'>{t('COMMON.MODIFY')}</span>
						<span className='shipment-summary_modify-mobile d-lg-none'>
							<img src={editIcon} alt='modify-mobile' title='modify-mobile' />
						</span>
					</a>
				</div>
			</div>
		</div>
	)
}

export default ShipmentSummary
