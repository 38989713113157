import React from 'react'
import { connect } from 'react-redux'
import { setFilters } from '../../redux/actions/filterActions'
import { setSearchFormData } from '../../redux/actions/searchFormActions'
import FilterOptions from './FilterOptions'

const FilterOptionsContainer = ({ 
	filters,
	searchFormData,
	airportList,
	setSearchFormDataToState,
	setFiltersToState
}) => {
	return (
		<FilterOptions
			// airportList={airportList}
			setFiltersToState={setFiltersToState}
			filters={filters}
			airportList = {airportList}
			// listFlightResponse={listFlightResponse}
			// selectedDate={selectedDate}
			searchFormData={searchFormData}
			setSearchFormData={setSearchFormDataToState}
		/>
	)
}

const mapStateToProps = ({ 
	searchForm,
	filters 
}) => ({
	searchFormData: searchForm.searchFormData,
	filters: filters.filters,
})

const mapDispatchToProps = (dispatch) => ({
	setSearchFormDataToState: (searchFormData) => {
		dispatch(setSearchFormData(searchFormData))
	},
	setFiltersToState: (filters) => {
		dispatch(setFilters(filters))
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FilterOptionsContainer)
