import React from 'react'
import { connect } from 'react-redux'
import { setRefreshToken, setAccessToken, clearAuth } from '../../../redux/actions/authAction'
import Callback from './Callback'

const CallbackContainer = ({ 
		principal,
		accessToken,
		setRefreshTokenToState,
		setAccessTokenToState,
		clearAuthFromState,
	}) => (
		<Callback 
			principal={principal}
			accessToken={accessToken}
			setAccessToken={setAccessTokenToState}
			setRefreshToken={setRefreshTokenToState}
			clearAuthFromState={clearAuthFromState}
		/>
	)

const mapStateToProps = ({ auth, loader }) => ({
	principal: auth.principal,
	accessToken: auth.accessToken,
	isLoading: loader.isLoading
})

const mapDispatchToProps = (dispatch) => ({
	setRefreshTokenToState: (refreshToken) => {
		dispatch(setRefreshToken(refreshToken))
	},
	setAccessTokenToState: (accessToken) => {
		dispatch(setAccessToken(accessToken))
	},
	clearAuthFromState: () => {
		dispatch(clearAuth())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(CallbackContainer)
