import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ConfirmationPopUp from '../../../common/ConfirmationPopUp'
import { BOOKING_STATUSES, NOTIFICATION_MESSAGE_TYPE } from '../../../constants'
import { cancelBooking } from '../../../services/manageBookingService'
import { getRecentBooking } from '../../../services/templateService'
import LoadingIndicator from '../../LoadingIndicator'
import CargoDetails from './CargoDetails'
import ChargeDetails from './ChargeDetails'
import FlightDetails from './FlightDetails'
import './index.scss'
import { getBookingResponseMapper } from './ResponseMapper'
import WeightDetails from './WeightDetails'
import { useHistory } from 'react-router-dom'
import ShareEmailDialog from './ShareEmailDialog'
import { getAirportsByCode } from '../../../services/searchPanelServices'
import PrintBookingDialog from '../../../common/PrintBookingDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import pendingIcon from '../../../assets/images/Pending_icon.svg'
import { handlePrint } from './printEmailUtil'
import MESSAGES from '../../../constants/Messages'
import BookingMenu from '../../BookingMenu'
import rejectIcon from '../../../assets/images/Error-Close-Filled.png'
import moment from 'moment'
import { Alert } from '../../../common/Alert'
import { Trans, useTranslation } from 'react-i18next'
import editIcon from '../../../assets/images/edit.png'
import { addError, pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, BOOKING_PAGE_SUB_CATEGORY2 } from '../../../constants'
import { getTermsConditionsByLocationResponse } from '../../../services/adminMasterServices'
import getCurrentUser from '../../../utils/getCurrentUser'
import { sendEmail } from '../../../services/notificationService'
import { getStationByCode } from '../../../services/searchPanelServices'
import { getDisclaimerEnabledFlag } from '../../../services/searchPanelServices'

import {
	getBase64Decoded,
	getFromSessionStorage,
} from '../../../utils/StorageUtils'

const BookingDetailsView = ({ awbNo, ubrNumber }) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const [bookingDetails, setBookingDetails] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [displayPopup, setDisplayPopUp] = useState(false)
	const [disclaimerPopup, setDisclaimerPopUp] = useState(false)
	const [bookingResponse, setBookingResponse] = useState(null)
	const [fileName, setFileName] = useState('')
	const [locationCode, setLocationCode] = useState('')
	const [disclaimerFlag, setDisclaimerFlag] = useState('')
	const [disclaimerThreshold, setDisclaimerThreshold] = useState('')


	const [showCharges, setShowCharges] = useState(false)
	const [displayBasic, setDisplayBasic] = useState(false)
	const [displayPrintConfirm, setDisplayPrintConfirm] = useState(false)

	const [isPastBooking, setIsPastBooking] = useState(false)
	const [pastBookingMessage, setPastBookingMessage] = useState(false)
	const [queuedReasons, setQueuedReasons] = useState(null)
	const history = useHistory()

	const dgCheckNormal = getFromSessionStorage('ifDg')

	const dgSccCode = (JSON.parse(getFromSessionStorage('dgsccCode')))
	const [alertMessage, setAlertMessage] = useState('')
	const [status, setStatus] = useState(null)

	const getQueueReasons = (remarks, response) => {
		const reasons = []
		// if (remarks.includes('NO_CAPACITY_EXCEP')) {
		// 	reasons.push(t('COMMON.NO_CAPACITY_EXCEP'))
		// }
		if (remarks.includes('BIG_EXCEP')) {
			reasons.push(t('COMMON.BIG_EXCEP'))
		}
		else if (remarks.includes('OHG_EXCEP')) {
			reasons.push(t('COMMON.OHG_EXCEP'))
		}
		else if (remarks.includes('SPOT_RATE_EXCEP')) {
			reasons.push(t('COMMON.SPOT_RATE_EXCEP'))
		}
		else if (remarks.includes('NO_RATES_EXCEP')) {
			reasons.push(t('COMMON.NO_RATES_EXCEP'))
		}
		else if (remarks.includes('MUW_SWP_SCC_EXCEP')) {
			reasons.push(t('COMMON.MUW_SWP_SCC_EXCEP'))
		}
		else if (remarks.includes('OTHER_EXCEP')) {

			if ((dgSccCode?.includes(response?.sccCodes)) || (dgCheckNormal && dgCheckNormal === 'true')) {
				// if ((msg === "SPECIALIZEDCOMMODITIES")) {
				reasons.push(t('COMMON.DG_QUEUED'))
				// }
			}

			else {
				let msg = response?.shipmentDetails?.productDetails?.productName;
				if ((msg === "AVI")) {
					reasons.push(t('COMMON.AVI_QUEUED'))
				}
				else {
					reasons.push(t('COMMON.OTHER_EXCEP'))
				}
			}

		}

		return reasons
	}

	useEffect(() => {
		if (history.location.state && history.location.state.modifyBooking) {
			const modifyBooking = history.location.state.modifyBooking
			setStatus(modifyBooking.status)
			if (modifyBooking.status && modifyBooking.status === true) {
				setAlertMessage(
					t(MESSAGES.MODIFY_BOOKING_SUCCESS, {
						AWB: modifyBooking.awbNo,
						status: modifyBooking.bookingStatus,
						ns: 'errors',
					})
				)
			} else {
				setAlertMessage(
					t('ER219', {
						awbNo: modifyBooking.awbNo,
						message: modifyBooking.message ? modifyBooking.message : '',
						ns: 'errors',
					})
				)
			}
			window.scrollTo(0, 0)

			let state = { ...history.location.state }
			delete state.modifyBooking
			history.replace({ ...history.location, state })
		}
	}, [history])

	useEffect(() => {
		if (bookingDetails && bookingDetails?.origin) {
			try {

				getAirportsByCode(bookingDetails?.origin).then((response) => {
					setLocationCode(response?.countryCode)
				})
			} catch (error) {
				console.log(error.message)
				return
			}
		}
	}, [bookingDetails])

	useEffect(() => {
		if (bookingDetails && bookingDetails?.origin) {
			try {

				getDisclaimerEnabledFlag(bookingDetails?.origin).then((response) => {
					setDisclaimerFlag(response?.disclaimerFeatureEnabled)
					setDisclaimerThreshold(response?.disclaimerSetThresholdInTonne)
				})
			} catch (error) {
				console.log(error.message)
				return
			}
		}
	}, [bookingDetails])
	useEffect(() => {
		if (locationCode != '') {
			locationCodeCallBack(locationCode)
		} else return
	}, [locationCode])
	const locationCodeCallBack = async () => {
		try {
			await getTermsConditionsByLocationResponse(locationCode).then(
				(response) => {
					setFileName(response?.termsAndConditions?.fileName)
				}
			)
		} catch (error) {
			console.log(error.message)
			return
		}
	}

	useEffect(() => {
		if (awbNo) {
			const userDetails = getCurrentUser()
			const request = {
				requestId: uuidv4(),
				shipmentIdentifier: getAwb(),
				ubrNumber: ubrNumber,
				agentCode: userDetails ? userDetails.agentCode : null,
			}

				; (async () => {
					setIsLoading(true)
					await getRecentBooking(request)
						.then((response) => {
							setIsLoading(false)
							if (
								response &&
								response.bookingDetails !== null &&
								response.bookingDetails !== undefined
							) {
								const responseBody = getBookingResponseMapper(
									response.bookingDetails
								)

								setBookingDetails(responseBody)

								setBookingResponse(response)
								if (
									response.bookingDetails &&
									response.bookingDetails.remarks &&
									response.bookingDetails.remarks.length
								) {
									const queuedReasons = getQueueReasons(
										response.bookingDetails.remarks,
										response.bookingDetails
									)
									//response.bookingDetails.remarks.join('')
									setQueuedReasons(queuedReasons)

								}
								pushPageData(
									pageNames.VIEW_BOOKING,
									PAGE_PRIMARY_CATEGORY.E_SERVICES,
									PAGE_SUB_CATEGORY1.BOOKING,
									BOOKING_PAGE_SUB_CATEGORY2.MY_BOOKINGS
								)
							} else {
								history.push({
									pathname: '/manage-booking/list',
									state: {
										message: MESSAGES.PROCESS_REQUEST_FAILED,
										success: false,
									},
								})
								setBookingDetails(response.bookingDetails)
								addError(MESSAGES.PROCESS_REQUEST_FAILED, i18n.getResource('en', 'errors', MESSAGES.PROCESS_REQUEST_FAILED))
								pushPageData(
									pageNames.VIEW_BOOKING,
									PAGE_PRIMARY_CATEGORY.E_SERVICES,
									PAGE_SUB_CATEGORY1.BOOKING,
									BOOKING_PAGE_SUB_CATEGORY2.MY_BOOKINGS
								)
							}
							window.scrollTo(0, 0)
						})
						.catch((error) => {
							history.push({
								pathname: '/manage-booking/list',
								state: {
									// message: `${awbNo} details could not retrieve now `,
									message: MESSAGES.PROCESS_REQUEST_FAILED,
									success: false,
								},

							})
							setIsLoading(false)
							addError(MESSAGES.PROCESS_REQUEST_FAILED, i18n.getResource('en', 'errors', MESSAGES.PROCESS_REQUEST_FAILED))
							pushPageData(
								pageNames.VIEW_BOOKING,
								PAGE_PRIMARY_CATEGORY.E_SERVICES,
								PAGE_SUB_CATEGORY1.BOOKING,
								BOOKING_PAGE_SUB_CATEGORY2.MY_BOOKINGS
							)
							console.log(error)
							window.scrollTo(0, 0)
						})
				})()
		}
	}, [])

	const getAwb = () => {
		let shipmentIdentifier = {
			shipmentPrefix: awbNo.trim().substring(0, 3).trim(),
			masterDocumentNumber: awbNo.trim().substring(3).trim(),
		}
		return shipmentIdentifier
	}

	const cancelBookingDetails = async (cancelRequest) => {
		setIsLoading(true)
		await cancelBooking(cancelRequest)
			.then((response) => {
				setIsLoading(false)
				if (response && response.status === 'S') {
					history.push({
						pathname: '/manage-booking/list',
						state: {
							message: t('ER132', {
								AWB: awbNo,
								ns: 'errors',
							}),
							success: true,
						},
					})

					if (disclaimerPopup) {
						handleBookingCancellation(awbNo)
					}

				} else if (response && response.errorDetails !== null) {
					history.push({
						pathname: '/manage-booking/list',
						state: {
							message: `${response.errorDetails[0].errorDescription}.`,
							success: false,
						},
					})
					addError('CANCEL_REQUEST', ` ${response.errorDetails[0].errorDescription}`)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				addError('BOOKING_CANCELLED', error)
				console.log(error)
			})
	}

	const handleBookingCancellation = async (awbNo) => {
		const attributes = getEmailAttriburtes()
		const response = await sendEmailForAssistance(
			attributes,
			bookingDetails,
			awbNo
		)
	}
	const getEmailAttriburtes = () => {
		const attributes = [
			{
				key: 'awbNo',
				value: awbNo,
			},
			{
				key: 'orgDst',
				value: bookingDetails ? `${bookingDetails.origin} - ${bookingDetails.destination}`
					: null,
			},
			{
				key: 'shippingDate',
				value:
					bookingDetails && bookingDetails.bookingFlightDetails && bookingDetails.bookingFlightDetails.length
						&& bookingDetails.bookingFlightDetails[0].segmentDepartureDate
						? moment(bookingDetails.bookingFlightDetails[0].segmentDepartureDate).format('DD MMM YYYY')
						: null,
			},
			{
				key: 'shippingItem',
				value:
					bookingDetails && bookingDetails.bookingCargoDetails
						? `${bookingDetails.bookingCargoDetails.commodity}-${bookingDetails.bookingCargoDetails.product}`
						: null,
			},
		]
		return attributes
	}

	const sendEmailForAssistance = async (
		attributes,
		bookingDetails,
		awbNo
	) => {
		const request = await getRequest(
			attributes,
			bookingDetails,
			awbNo
		)
		if (request) {
			const response = await sendEmail(request)
				.then((response) => {
					setIsLoading && setIsLoading(false)
					if (response.errorDetails) {
						return false
					} else {
						return true
					}
				})
				.catch((error) => {
					setIsLoading && setIsLoading(false)
					return false
				})
			return response
		} else {
			return false
		}
	}

	const getRequest = async (
		attributes,
		bookingDetails,
		awbNo
	) => {

		const userDetails = getCurrentUser()
		const stationDtls = await getStationByCode(userDetails.stationCode)
		const agentName = userDetails ? userDetails.companyName : null
		const emailDate = bookingDetails && bookingDetails.bookingFlightDetails && bookingDetails.bookingFlightDetails.length
			&& bookingDetails.bookingFlightDetails[0].segmentDepartureDate
			? moment(bookingDetails.bookingFlightDetails[0].segmentDepartureDate).format('DD MMM YYYY')
			: null
		const mailAttributes = {
			body: `${agentName} has cancelled the following booking, whose chargeable weight is above set threshold for cancellation fee. Please do the needful.`,
			agentCode: userDetails ? userDetails.agentCode : null,
			agentName,
			agentEmail: userDetails ? userDetails.email : null,

		}


		if (stationDtls && stationDtls.emailAddress) {
			const emailAddresses = stationDtls.emailAddress.split(',')
			const request = {
				toEmailAddresses: emailAddresses,
				subject: `Booking Cancellation -  Above ${disclaimerThreshold} Tonne - ${`${bookingDetails.awb.shipmentPrefix} ${bookingDetails.awb.masterDocumentNumber}`} / ${emailDate}`,
				templateName: 'booking_cancellation',
				attributes: {
					...mailAttributes,
				},
				agentCode: userDetails.agentCode,
				userId: userDetails.userId,
				async: false,
			}
			if (attributes && attributes.length) {
				attributes.forEach(function (attr) {
					request.attributes[attr.key] = attr.value
				})
			}
			return request
		} else {
			return null
		}
	}

	const handleModify = () => {
		const status = checkPastBooking(bookingDetails.shippingDate)
		if (status) {
			setIsPastBooking(status)
			setPastBookingMessage(MESSAGES.ERR_MODIFY_PAST_BOOKING)
			window.scrollTo(0, 0)
		} else {
			//history.push(`/booking/modify/${awbNo}`)

			// To disable modify booking for spot rate attached bookings.
			if (bookingDetails?.chargeDetails?.spotRate && bookingDetails?.chargeDetails?.spotRateCode) {
				setIsPastBooking(true)
				setPastBookingMessage(MESSAGES.ERR_MODIFY_BOOKING)
				window.scrollTo(0, 0)
			} else {
				history.push(`/booking/modify/${awbNo}`)
			}
		}
	}

	const handleDisplayPopUp = () => {
		const status = checkPastBooking(bookingDetails.shippingDate)
		if (status) {
			setIsPastBooking(status)
			setPastBookingMessage(MESSAGES.ERR_CANCEL_PAST_BOOKING)
			window.scrollTo(0, 0)
		} else if (bookingDetails.bookingStatus == "C" && bookingDetails.chargeDetails.chargeableWeight
			&& disclaimerFlag == "Y"
			&& Number(bookingDetails.chargeDetails.chargeableWeight.replace(',', '')) >= (Number(disclaimerThreshold) * 907.185)) {
			setDisclaimerPopUp(true)
		} else {
			setDisplayPopUp(true)
		}
	}
	const handleBookingCancel = () => {
		const cancelRequest = {
			requestID: uuidv4(),
			bookingDetailsFilter: {
				shipmentIdentifierDetails: getAwb(),
			},
		}
		cancelBookingDetails(cancelRequest)
		//handleBookingCancellation(awbNo)
	}

	const handleBack = () => {
		history.push({
			pathname: '/manage-booking/list',
			state: {
				retainFormData: true,
			},
		})
		window.scrollTo(0, 0)
	}

	const onClickShare = () => {
		setDisplayBasic(true)
	}

	const printDetails = () => {
		handlePrint(bookingDetails, showCharges, setIsLoading)
	}

	const checkPastBooking = (shippingDate) => {
		const today = moment(new Date(), 'ddd DD MMM')
		return (
			shippingDate && moment(shippingDate, 'ddd DD MMM').isSameOrBefore(today)
		)
	}

	const handleAlertClose = () => {
		setIsPastBooking(false)
		setPastBookingMessage('')
	}

	const handleAlertClose1 = () => {
		setStatus(null)
		setAlertMessage('')
	}

	return (
		<div id='bookingDetails' className='booking-details'>
			{isLoading ? <LoadingIndicator /> : <></>}
			<BookingMenu activeItem={1} />
			<ShareEmailDialog
				bookingDetails={bookingDetails}
				displayBasic={displayBasic}
				setDisplayBasic={setDisplayBasic}
			/>
			{alertMessage !== '' && status !== null && (
				<Alert
					isError={!status}
					showAlert={true}
					message={t(alertMessage, { ns: 'errors' })}
					setTimeout={true}
					onAlertClose={handleAlertClose1}
				/>
			)}
			{isPastBooking && (
				<Alert
					type={NOTIFICATION_MESSAGE_TYPE.INFO}
					showAlert={true}
					message={t(pastBookingMessage, { ns: 'errors' })}
					setTimeout={true}
					onAlertClose={handleAlertClose}
				/>
			)}
			{queuedReasons && queuedReasons.length > 0 && (
				<Alert
					type={NOTIFICATION_MESSAGE_TYPE.WARNING}
					showAlert={true}
					message={queuedReasons}
					setTimeout={false}
					onAlertClose={handleAlertClose}
				/>
			)}

			<div className='form-row'>
				<div className='col-12 col-lg-12'>
					<h3 className=' page-heading pl-0 pt-0'>
						{t('VIEWBKG.BOOKING_DETAILS')}
					</h3>
				</div>
			</div>
			{bookingDetails !== null ? (
				bookingDetails.length === 0 ? (
					<div className='no-data ml-0 mr-0'>
						{t(MESSAGES.LOADING, { ns: 'errors' })}
					</div>
				) : (
					<div>
						<div
							className='form-row primary-details-wrapper'
							id='primary-details-wrapper'
						>
							<div className='col-10 awb-wrapper'>
								{bookingDetails.awb && (
									<span className='d-block d-lg-inline'>
										{t('COMMON.AWB_NUMBER')} :{' '}
										{bookingDetails.awb.shipmentPrefix}-
										{bookingDetails.awb.masterDocumentNumber}
									</span>
								)}

								<span className='pl-3 pr-3 d-none d-lg-inline'>|</span>
								<span>
									{bookingDetails.origin} - {bookingDetails.destination}
								</span>
								<span className='pl-1 pr-1 pl-lg-3 pr-lg-3'>|</span>
								<span>{bookingDetails.shippingDate} </span>
								<span className='pl-lg-3 pr-lg-3'></span>
								<span
									className={`status-wrapper d-block d-lg-inline ${bookingDetails.bookingStatus === 'C'
										? 'status-confirmed confirm-tick-mark'
										: bookingDetails.bookingStatus === 'Q'
											? 'status-queued'
											: ''
										}`}
								>
									{bookingDetails.bookingStatus === 'Q' && (
										<i className='pr-2 indicator'>
											<img
												src={pendingIcon}
												alt='pending'
												className='image-wrapper pl-0 pb-1'
											></img>

										</i>
									)}

									{bookingDetails.bookingStatus === 'X' && (
										<i>
											<img
												src={rejectIcon}
												alt='Rejected'
												className='image-wrapper pl-0 pb-1'
											></img>
										</i>
									)}
									<span>{t(BOOKING_STATUSES[bookingDetails.bookingStatus])}</span>
								</span>
							</div>
							{bookingDetails.bookingStatus &&
								bookingDetails.bookingStatus !== 'X' && (
									<div className='col-2 text-right pt-3 pt-lg-0'>
										<span
											className='link d-none d-lg-inline'
											onClick={handleModify}
										>
											{t('COMMON.MODIFY')}
										</span>
										<span
											className='primary-details-wrapper_modify-mobile d-lg-none d-inline'
											onClick={handleModify}
										>
											<img
												src={editIcon}
												alt='modify-mobile'
												title='modify-mobile'
											/>
										</span>
									</div>
								)}
						</div>
						<CargoDetails bookingDetails={bookingDetails} />
						<WeightDetails bookingDetails={bookingDetails} />
						<FlightDetails
							bookingDetails={bookingDetails}
							bookingResponse={bookingResponse}
							setIsLoading={setIsLoading}
							awbNo={awbNo}
							setIsPastBooking={setIsPastBooking}
							setPastBookingMessage={setPastBookingMessage}
						/>
						<ChargeDetails bookingDetails={bookingDetails} />

						<div className='form-row mt-3 mt-lg-5'>
							{bookingDetails.bookingStatus &&
								bookingDetails.bookingStatus !== 'X' && (
									<div className='col-12 col-lg-4 mt-2  d-inline d-lg-none text-right'>
										<div className='link' onClick={handleDisplayPopUp}>
											{t('BKGCAN.CANCEL_BOOKING')}
										</div>
									</div>
								)}
							<div className='col-12 col-lg-8 mt-5'>
								<div className='form-row '>
									<div className='col-12  col-lg-2 mr-lg-4 mt-2 mt-lg-0 cta_wrap flex-column flex-lg-row'>
										<button
											type='button'
											className='button button--secondary'
											onClick={handleBack}
										>
											{t('COMMON.BACK_BUTTON')}
										</button>
									</div>
									<div className='col-12 col-lg-2 mr-lg-4 mt-4 mt-lg-0 cta_wrap flex-column flex-lg-row'>
										<button
											type='button'
											className='button button--secondary'
											onClick={onClickShare}
										>
											{t('COMMON.SHARE')}
										</button>
									</div>

									<div className='col-12  col-lg-2 mr-lg-4 mt-4 mt-lg-0 cta_wrap ml-lg-2 flex-column flex-lg-row'>
										{displayPrintConfirm && (
											<PrintBookingDialog
												showCharges={showCharges}
												setShowCharges={setShowCharges}
												displayPrintConfirm={displayPrintConfirm}
												setDisplayPrintConfirm={setDisplayPrintConfirm}
												printDetails={printDetails}
											/>
										)}

										<button
											type='button'
											className='button button--secondary'
											onClick={(e) => {
												setDisplayPrintConfirm(!displayPrintConfirm)
												setShowCharges(false)
											}}
										>
											{t('COMMON.PRINT')}
										</button>
									</div>
								</div>
							</div>
							{bookingDetails.bookingStatus &&
								bookingDetails.bookingStatus !== 'X' && (
									<div className='col-12 col-lg-4 mt-5  text-right d-none d-lg-inline'>
										<div className='link' onClick={handleDisplayPopUp}>
											{t('BKGCAN.CANCEL_BOOKING')}
										</div>
									</div>
								)}
						</div>
					</div>
				)
			) : (
				<div className='no-data ml-0 mr-0'>
					{' '}
					Sorry, the booking detail is not available.
				</div>
			)}
			{displayPopup ? (
				<ConfirmationPopUp
					displayPopUp
					setDisplayPopUp={setDisplayPopUp}
					message={t(MESSAGES.POP_UP_CANCEL_TEXT, { ns: 'errors' })}
					primaryBtnLbl={t('COMMON.YES')}
					secondaryBtnLbl={t('COMMON.NO')}
					acceptFn={handleBookingCancel}
				/>
			) : (
				<></>
			)}
			{disclaimerPopup && fileName && fileName !== '' ? (
				<ConfirmationPopUp
					displayPopUp
					setDisplayPopUp={setDisclaimerPopUp}
					message=

					{fileName && fileName !== '' ? (
						<React.Fragment>
							<Trans
								i18nKey={t('ER238', { ns: 'errors' })}
								t={t}
								components={[
									<a
										className='link terms-conditions-wrapper__link '
										href={
											fileName && fileName.length
												? `/media/${fileName}`
												: '/'
										}
										role='button'
										target='_blank'
										rel='noreferrer'
									>
										{' '}
									</a>,
								]}
							/>
						</React.Fragment>
					)
						:
						(
							<React.Fragment>
								{t('MESSAGES.DISCLAIMER_TEXT')}
							</React.Fragment>
						)}

					primaryBtnLbl={t('COMMON.YES')}
					secondaryBtnLbl={t('COMMON.NO')}
					acceptFn={handleBookingCancel}
				/>
			) : (
				<></>
			)}
		</div>
	)
}

export default BookingDetailsView
