import React, { useState } from 'react';
import MultiplePetItem from './multiplePets';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import {
    AUTOCOMPLETE_TYPE,
    DIMENSION_UNIT,
    FORM_FIELDS,
    FRESH_FORWARD_PRODUCT,
    getInitLooseItemDetail,
    getInitPharmaObject,
    getInitULDItemDetail,
    PHARMA_PRODUCT,
    PHARMA_SHIPPING_TYPE,
    IS_KNOWN_UNKNOWN,
    PRICE_CODE_TYPE,
    SCC_CODES,
    SHIPPING_TYPE,
    VALIDATE_BOOKINGS_ERROR_CODES,
    WEIGHT_UNIT,
    NOTIFICATION_MESSAGE_TYPE,
    SYSTEM_ADDED_SCCS,
    PAGE_PRIMARY_CATEGORY,
    PAGE_SUB_CATEGORY1,
    getInitPetDetail,
    WEIGHT_UNIT_SELECT_ITEMS,
    DIMENSION_UNIT_SELECT_ITEMS,
    getInitCageDetails,
} from '../../../constants'
import { useEffect } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { Message } from 'primereact/message';
import Cages from './cages';

const MultiplePetsContainer = ({
    shippingItem,
    // petList,
    // setPetList,
    file,
    setFile,
    dogBreeds,
    searchFormData,
    setArrayListDoc,
    arrayListDoc,
    setCageList,
    cageList,
    setDeletedCageIds,
	deletedCageIds,
    setDeletedPetIds,
	deletedPetIds,
    error,
	setError,
    weightUnit,
    dimensionUnit,
    setDimensionUnit,
    setWeightUnit,
    stubNose, 
    setStubNose,
    breedRejected, 
    setBreedRejected,
}) => {

    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [petOrder, setPetOrder] = useState(0);

    const handleAddCageItem = () => {
        let cageData = [...cageList, getInitCageDetails(weightUnit, dimensionUnit)]
        cageData[cageData.length - 1].cageOrder = cageData.length - 1
        setCageList(cageData)
    }

    return (
        <div>
            <div className='row mt-3'>
						<div className={`col-md form-group col-lg-2 selectboxp`}>
							<label className="snubnosedCheck">{t('COMMON.IS_SNUB')}</label>
						</div>
						<div className={`col-md col-lg-4 checkbox-container`}>
							<div className='form-item codep-type-btn code-type-btn-left '>
								<RadioButton
									id='snuby-code-btn'
									className='select-button'
									value={"Yes"}
									onChange={(e) => {
										setStubNose(true)
									}}
									checked={stubNose === true}
								></RadioButton>
								<label className='snubnose-label'>
									{/* {t('BOOKING.PROMO_CODE')} */}
									{t('COMMON.YES')}
								</label>
								<RadioButton
									id='snubn-code-btn'
									className='select-button'
									value={"No"}
									onChange={(e) => {
										// setSpotRateCode(promoCode)
										// setPromoCode(null)
										setStubNose(false)
										setPetOrder(0)
									}}
									checked={stubNose === false}
								></RadioButton>
								<label className='snub-label'> {t('COMMON.NO')}</label>
							</div>
						</div>
					</div>
            {
                stubNose === true ?
                <>
                    <div className='row'>
                        <div className='col-md col-12 warning-info'>
                            <Message
                                severity='warn'
                                text={t('COMMON.SNUB_NOSED_NOT_ALLOWED')}
                            ></Message>
                        </div>
                    </div>
                </> :


// Cages startig --->
                <div>
                    {/* {
                    cageList.map((item, index) => ( */}
                    <Cages
                        shippingItem={shippingItem}
                        // petList={petList}
                        // setPetList={setPetList}
                        file={file}
                        setFile={setFile}
                        dogBreeds={dogBreeds}
                        searchFormData={searchFormData}
                        setArrayListDoc={setArrayListDoc}
                        arrayListDoc={arrayListDoc}
                        petOrder={petOrder}
                        setCageList={setCageList}
                        cageList={cageList}
                        //cageIndex={index}
                       // handleCageItemChange={handleCageItemChange}
                        //item={item}
                        setDeletedCageIds={setDeletedCageIds}
                        deletedCageIds={deletedCageIds}
                        setDeletedPetIds={setDeletedPetIds}
						deletedPetIds={deletedPetIds}
                        error={error}
						setError={setError}
                        weightUnit={weightUnit}
						dimensionUnit={dimensionUnit}
                        setDimensionUnit={setDimensionUnit}
						setWeightUnit={setWeightUnit}
                        breedRejected={breedRejected}
                        setBreedRejected={setBreedRejected}
                    />
                    {/* ))
                    } */}
                        <div className='form-row'>
                            <div className='form-group col-12 col-lg-10 m-b-0'>
                                <Button
                                    label={t('COMMON.ADD_ANOTHER_CAGE')}
                                    // icon='pi pi-plus'
                                    className='d-block custom-xs button__another adanotherPet '
                                    type='button'
                                    onClick={(e) => handleAddCageItem(e)}
                                />
                            </div>
                        </div>
                 </div>
            }
        </div>
    )
}

export default MultiplePetsContainer;