import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'

export const sendEmail = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.sendEmailUrl}`
	//const url = `http://localhost:8080/api/notification/send-email`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const getPdfForPrint = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.createPrintUrl}`
	//const url = `http://localhost:8080/notification/create-print`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}
