import React from 'react'
import Popup from './Popup'
import { closePopup } from '../../redux/actions/popupActions'
import { connect } from 'react-redux'

const PopupContainer = ({
	display,
	header,
	message,
	primaryBtnLbl,
	acceptFn,
	secondaryBtnLbl,
	rejectFn,
	closeErrorPopup,
}) => (
	<Popup
		display={display}
		header={header}
		message={message}
		primaryBtnLbl={primaryBtnLbl}
		acceptFn={acceptFn}
		secondaryBtnLbl={secondaryBtnLbl}
		rejectFn={rejectFn}
		closeErrorPopup={closeErrorPopup}
	/>
)

const mapStateToProps = ({ popup }) => ({
	display: popup.display,
	header: popup.header,
	message: popup.message,
	primaryBtnLbl: popup.primaryBtnLbl,
	acceptFn: popup.acceptFn,
	secondaryBtnLbl: popup.secondaryBtnLbl,
	rejectFn: popup.rejectFn,
})

const mapDispatchToProps = (dispatch) => ({
	closeErrorPopup: () => {
		dispatch(closePopup())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer)
