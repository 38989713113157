import moment from 'moment'
import getPrincipal from '../../../utils/getPrincipal'
import {
	getWeightUnit,
	WEIGHT_UNIT,
	getVolumeUnit,
	getDensityUnit,
	BOOKING_STATUSES,
	capitalize,
	getDecimalFormattedNumber,
} from '../../../constants'
import { getPdfForPrint } from '../../../services/notificationService'
import { openBase64NewTab } from '../../CaptureAWB/printAwbUtils'
import getCurrentUser from '../../../utils/getCurrentUser'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { addError } from '../../../utils/analytics'
import {
	getBase64Decoded,
	getFromSessionStorage,
} from '../../../utils/StorageUtils'
export const getShareEmailRequest = (email, bookingDetails, showCharges, emailType) => {
	//for multilingual
	const t = i18n.t

	const userDetails = getCurrentUser()
	const awbNumber = `${bookingDetails.awb.shipmentPrefix} ${bookingDetails.awb.masterDocumentNumber}`
	const origin = bookingDetails.origin
	const destination = bookingDetails.destination
	const noRates = bookingDetails.bookingRateDetails ? false : true
	const language = i18n.language
	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	const dgCheck = bookingDetails?.sccCodes?.some(e => dgSccCode?.includes(e))
	const dgCheckNormal = getFromSessionStorage('ifDg')

	return {
		toEmailAddresses: email,
		subject: `${t('BKGSHARE.BKGSHARE_SUBJECT', {
			AWB: awbNumber,
			ORG: origin,
			DES: destination,
		})}`,
		templateName:
			language === 'zh' ? 'booking_confirmation' : (bookingDetails.bookingCargoDetails.commodity === "LIVE DOGS" || bookingDetails.bookingCargoDetails.commodity === "LIVE CATS" ? 'booking_confirmation_avi' : (dgCheck || (dgCheckNormal && dgCheckNormal === 'true')) ? "booking_confirmation_share_dg" : "booking_confirmation"),
		attributes: getPdfAttributes(
			bookingDetails,
			awbNumber,
			userDetails,
			showCharges,
			noRates,
		),
		hasAttachments: true,
		attachmentName: t('BKGSHARE.BKGSHARE_ATTACH_NAME'),
		agentCode: userDetails ? userDetails.agentCode : null,
		userId: userDetails ? userDetails.userId : null,
		async: false,
		cages: bookingDetails?.crates[0]?.weight ? bookingDetails.crates : null,
		additionalCommodityDetails: ((dgCheck) || (dgCheckNormal && dgCheckNormal === 'true')) ?
			bookingDetails?.additionalCommodityDetails : null,
		emailType: emailType,
	}
}

export const getPrintRequest = (bookingDetails, showCharges) => {

	const userDetails = getCurrentUser()
	const noRates = bookingDetails.bookingRateDetails ? false : true
	const awbNumber = `${bookingDetails.awb.shipmentPrefix} ${bookingDetails.awb.masterDocumentNumber}`
	const language = i18n.language
	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	const dgCheck = bookingDetails?.sccCodes?.some(e => dgSccCode?.includes(e))
	const dgCheckNormal = getFromSessionStorage('ifDg')

	return {
		templateName:
			language === 'zh' ? 'booking_confirmation_zh' : bookingDetails.bookingCargoDetails.commodity === "LIVE DOGS" || bookingDetails.bookingCargoDetails.commodity === "LIVE CATS" ? 'booking_confirmation_avi' : (dgCheck || (dgCheckNormal && dgCheckNormal === 'true')) ? "booking_confirmation_share_dg" : "booking_confirmation",
		attributes: getPdfAttributes(
			bookingDetails,
			awbNumber,
			userDetails,
			showCharges,
			noRates
		),
		agentCode: userDetails ? userDetails.agentCode : null,
		userId: userDetails ? userDetails.userId : null,
		cages: bookingDetails?.crates[0]?.weight ? bookingDetails.crates : null,
		additionalCommodityDetails: (dgCheck) || (dgCheckNormal && dgCheckNormal === 'true') ?
			bookingDetails?.additionalCommodityDetails : null,
	}
}

export const handlePrint = async (
	bookingDetails,
	showCharges,
	setIsLoading
) => {
	const t = i18n.t
	setIsLoading(true)
	const pdfEncoded = await getPdfForPrint(
		getPrintRequest(bookingDetails, showCharges)
	)
		.then((response) => {
			setIsLoading(false)
			if (response && response.pdfToPrint) {
				return response.pdfToPrint
			} else {
				return null
			}
		})
		.catch((error) => {
			setIsLoading(false)
			addError('CANNOT_PROCESS_TO_PRINT', error),

				window.scrollTo(0, 0)
		})
	if (pdfEncoded) {
		const awbNumber = bookingDetails.awb
			? `${bookingDetails.awb.shipmentPrefix} ${bookingDetails.awb.masterDocumentNumber}`
			: ''
		openBase64NewTab(
			pdfEncoded,
			`${t('PRINT.PDF_NAME_VWBKG', { awb: awbNumber })}.pdf`
		)
	}
}

const getPdfAttributes = (
	bookingDetails,
	awbNumber,
	userDetails,
	showCharges,
	noRates,
) => {
	const t = i18n.t
	const language = i18n.language
	const departureTime = bookingDetails.bookingFlightDetails
		? moment(bookingDetails.bookingFlightDetails[0].segmentDepartureDate)
		: null
	const arrivalTime = bookingDetails.bookingFlightDetails
		? moment(
			bookingDetails.bookingFlightDetails[
				bookingDetails.bookingFlightDetails.length - 1
			].segmentArrivalDate
		)
		: null
	const departureDate = bookingDetails.bookingFlightDetails
		? moment(bookingDetails.bookingFlightDetails[0].segmentDepartureDate, 'DD-MMM-YYYY')
		: null
	const arrivalDate = bookingDetails.bookingFlightDetails
		? moment(
			bookingDetails.bookingFlightDetails[
				bookingDetails.bookingFlightDetails.length - 1
			].segmentArrivalDate,
			'DD-MMM-YYYY'
		)
		: null
	const origin = bookingDetails.origin
	const destination = bookingDetails.destination
	const flightDate =
		bookingDetails && bookingDetails.bookingFlightDetails
			? moment(bookingDetails.bookingFlightDetails[0].flightDate, 'DD-MMM-YYYY').format(
				'DD-MMM-YY'
			)
			: null
	const attributes = {
		bookingStatus: t(BOOKING_STATUSES[bookingDetails.bookingStatus]),
		awbNumber: awbNumber,
		companyName: userDetails ? userDetails.companyName : null,
		departureTime: departureTime.format('HH:mm'),
		arrivalTime: arrivalTime.format('HH:mm'),
		departureDate: departureDate.format('ddd DD MMM'),
		arrivalDate: arrivalDate.format('ddd DD MMM'),
		origin: origin,
		destination: destination,
		flightDate: flightDate,
		duration: '',
		flightNumber: '',
		flightType: '',
		flightSegments: getFlightSegments(bookingDetails),
		dropOffAddress: getDropOffAddress(bookingDetails),
		pickUpAddress: getPickUpAddress(bookingDetails),
		toa: null,
		lat: null,
		noOfPieces: bookingDetails.bookingCargoDetails.quantity,
		grossWeight: `${bookingDetails.bookingCargoDetails.grossWeight
			} ${getWeightUnit(bookingDetails.weightUnit)}`,
		volume: `${bookingDetails.bookingCargoDetails.volume}${getVolumeUnit(
			bookingDetails.volumeUnit
		)}`,
		volumeConverted: '',
		chargeableWeight: getChargeableWeight(bookingDetails),
		product: bookingDetails.bookingCargoDetails.product,
		service: bookingDetails.bookingCargoDetails.service,
		shipmentSCC: `${bookingDetails.sccCodes ? bookingDetails.sccCodes.join() : ''
			}`,
		density: `${bookingDetails.bookingCargoDetails.density}${getDensityUnit(
			bookingDetails.weightUnit,
			bookingDetails.volumeUnit
		)}`,
		densityConverted: '',
		airFreightCostPerKg: bookingDetails.chargeDetails.rate
			? `${bookingDetails.chargeDetails.currencyCode} ${bookingDetails.chargeDetails.rate}/${getWeightUnit(WEIGHT_UNIT.KILOGRAM)}`
			: null,
		airFreightCost: `${bookingDetails.chargeDetails.currencyCode
			} ${getDecimalFormattedNumber(
				bookingDetails.chargeDetails.airFreightCost
			)}`,
		surcharges: `${bookingDetails.chargeDetails.currencyCode
			} ${getDecimalFormattedNumber(
				bookingDetails.chargeDetails.totalSurcharges
			)}`,
		otherCharges: getOtherChargeDetails(bookingDetails.chargeDetails),
		totalCost: `${bookingDetails.chargeDetails.currencyCode
			} ${getDecimalFormattedNumber(bookingDetails.chargeDetails.totalCost)}`,
		showCharges: showCharges,
		noRates: noRates,
		commodity: `${bookingDetails.bookingCargoDetails.commodity
			? bookingDetails.bookingCargoDetails.commodity
			: ''
			}`,
	}
	return attributes
}

const getOtherChargeDetails = (chargeDetails) => {
	if (
		chargeDetails &&
		chargeDetails.surcharges &&
		chargeDetails.surcharges.length
	) {
		const otherCharges = []
		chargeDetails.surcharges.forEach(function (item) {
			const amount = `${chargeDetails.currencyCode} ${getDecimalFormattedNumber(
				item.amount
			)}`

			const otherCharge = {
				amount: amount,
				code: item.code,
				name: item.name ? capitalize(item.name) : null,
				chargePerKg: null,
			}
			otherCharges.push(otherCharge)
		})
		return otherCharges
	} else {
		return null
	}
}

const getDropOffAddress = (bookingDetails) => {
	if (bookingDetails.dropOffAddress) {
		const dropOffAddress = bookingDetails.dropOffAddress
		return `${dropOffAddress.name ? dropOffAddress.name + ',' : ''} ${dropOffAddress.streetAndHouseNumber
			? dropOffAddress.streetAndHouseNumber + ','
			: ''
			} ${dropOffAddress.country ? dropOffAddress.country + ',' : ''} ${dropOffAddress.city ? dropOffAddress.city + ',' : ''
			} ${dropOffAddress.postCode ? dropOffAddress.postCode : ''}`
	} else {
		return null
	}
}

const getPickUpAddress = (bookingDetails) => {
	if (bookingDetails.pickUpAddress) {
		const pickUpAddress = bookingDetails.pickUpAddress
		return `${pickUpAddress.name ? pickUpAddress.name + ',' : ''} ${pickUpAddress.streetAndHouseNumber
			? pickUpAddress.streetAndHouseNumber + ','
			: ''
			} ${pickUpAddress.country ? pickUpAddress.country + ',' : ''} ${pickUpAddress.city ? pickUpAddress.city + ',' : ''
			} ${pickUpAddress.postCode ? pickUpAddress.postCode : ''}`
	} else {
		return null
	}
}

const getChargeableWeight = (bookingDetails) => {
	return `${bookingDetails.chargeDetails.chargeableWeight}${getWeightUnit(WEIGHT_UNIT.KILOGRAM) + 's'
		}`
}

const getFlightSegments = (bookingDetails) => {
	var result = bookingDetails.bookingFlightDetails.map((segment) => ({
		flightNumber: `${segment.carrierCode}${segment.flightNumber}`,
		departureTime: moment(segment.segmentDepartureDate).format('HH:mm'),
		departureDate: moment(segment.segmentDepartureDate, 'DD-MMM-YYYY').format('ddd DD MMM'),
		arrivalTime: moment(segment.segmentArrivalDate).format('HH:mm'),
		arrivalDate: moment(segment.segmentArrivalDate, 'DD-MMM-YYYY').format('ddd DD MMM'),
		origin: segment.segmentOrigin,
		destination: segment.segmentDestination,
		//flightType: selectedFlight.flightSegmentList[0].flightType,
		aircraftType: segment.aircraftType ? segment.aircraftType : '',
		duration: segment.flyingHours ? segment.flyingHours : '',
	}))
	return result
}
