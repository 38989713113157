import React, { useState, useEffect } from 'react';

import Calendar from '../../../common/Calendar';
import moment from 'moment';


import { Dropdown } from '../../../common/DropDown'
import { useTranslation } from 'react-i18next'
import {
    REPEAT_TYPE, SEASONAL_FIELDS
} from '../../../constants'

import {
    clearItemFromLocalStorage,
    getFromLocalStorage,
    setToLocalStorage,
} from '../../../utils/StorageUtils'

import {
    checkIsBackButtonAction,
    handleTilteableAndStackable,
    validateFormData,
    checkIsBigOrOHG,
} from '../../SearchPanel/formUtils'

import { validateAwbDates } from '../../SearchPanel/formUtils';
import LoadingIndicator from '../../LoadingIndicator'
import {
    FORM_FIELDS,

} from '../../../constants'

import MESSAGES from '../../../constants/Messages'
import './index.scss'
import { formValues } from 'redux-form';


const active = {
    backgroundColor: '#262364',
    cursor: 'pointer',
    color: 'rgb(255, 255, 255)',
}
const inactive = {}

const disabledDay = {
    opacity: '0.5',
    cursor: 'default',
    pointerEvents: 'none',
}

const weekArray = [];
const dayArray = [];
const SelectableDiv = ({ weekdays,
    setWeekdays,
    value,
    day,
    startDate,
    endDate,
    weekCount,
    startonDay,
    setStartonDay,
    setSelectedDates,
    selectedDates,
    seasonalError,
    setSeasonalError,
    clearError,
    handleSetError,
    dateScheduler,
    disabled,
    lessSeven
}) => {
    const [selected, setSelected] = useState(false);

    const isWeekdaySlected = async (selectedDay) => {

        const isDateIncluded = dayArray?.includes(selectedDay.toString())
        if (!isDateIncluded) {
            dayArray.push(selectedDay.toString())
            setSelectedDates(dayArray)

        }
        else {
            const index = dayArray.indexOf(selectedDay.toString());
            const x = dayArray.splice(index, 1);
            if (dayArray?.length === 0) {
                await setSelectedDates()
            }
            else {
                await setSelectedDates(dayArray)
            }
        }

    }



    const GetNextWeekStart = async () => {
        if (weekdays && weekdays?.length >= 0) {
            const dayINeed = weekCount;
            if (startDate) {
                const today = moment(startDate).isoWeekday();
                if (today <= dayINeed) {
                    const selectedDay = moment(startDate, 'DD-MMM-YYYY').isoWeekday(dayINeed).toDate()
                    isWeekdaySlected(selectedDay)
                    if (startonDay && moment(startonDay).isSameOrAfter(moment(selectedDay), 'minute') || startonDay === '') {
                        if (dayArray.length === 0) {

                            await setStartonDay('')
                        }
                        else {
                            if (selectedDates && selectedDates?.length > 0) {
                                let moments = selectedDates.map(d => moment(d)),
                                    mind = moment.min(moments)
                                await setStartonDay(moment(mind, 'DD-MMM-YYYY').toDate())
                            }

                            else {
                                setStartonDay(selectedDay)
                            }
                        }

                    }

                    return moment().isoWeekday(dayINeed);
                } else {
                    // otherwise, give me *next week's* instance of that same day
                    const selectedDay = moment(startDate, 'DD-MMM-YYYY').add(1, 'weeks').isoWeekday(dayINeed).toDate()
                    isWeekdaySlected(selectedDay)
                    if (startonDay && moment(startonDay).isSameOrAfter(moment(selectedDay), 'minute') || startonDay === '') {

                        if (selectedDates && selectedDates.length >= 1) {

                            let moments = selectedDates.map(d => moment(d)),
                                mind = moment.min(moments)
                            await setStartonDay(moment(mind, 'DD-MMM-YYYY'))
                        }

                        else {
                            if (dayArray.length === 0) {

                                await setStartonDay('')
                            }
                            else {
                                setStartonDay(selectedDay)
                            }
                        }
                    }

                    return moment().add(1, 'weeks').isoWeekday(dayINeed);
                }
            }

        }

    }

    const handleClick = async () => {

        setSelected(!selected);
        const isDayIncluded = weekdays?.includes(value)

        if (!isDayIncluded) {
            weekArray.push(value);
            await setWeekdays(weekArray)
            GetNextWeekStart()
        }
        else {
            const index = weekArray.indexOf(value);
            const x = weekArray.splice(index, 1);

            await setWeekdays(weekArray)

            GetNextWeekStart()

        }
        weekdays?.length > 0 || !selected ? clearError(SEASONAL_FIELDS.ON_DAY) : handleSetError(SEASONAL_FIELDS.ON_DAY, "Please select an option")

        return

    };
    return (
        <div className="weekdays-scheduler" style={disabled ? disabledDay : lessSeven ? selected ? active : inactive : disabledDay} onClick={!disabled ? handleClick : ''}>
            {day}
        </div>
    );
}

const DateScheduler = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    schedulerMaxShippingDate,
    setSchedulerMaxShippingDate,
    schedulerMinDate,
    setSchedulerMinDate,
    weekdays,
    setWeekdays,
    dateScheduler,
    setDateScheduler,
    seasonalError,
    setSeasonalError,
    startonDay,
    setStartonDay,

}) => {

    const [isLoading, setIsLoading] = useState(false)
    const { t, i18n } = useTranslation(['labels', 'errors'])
    const [inDate, setInDate] = useState(true)

    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedDaysNew, setSelectedDaysNew] = useState([])


    useEffect(() => {
        if (dateScheduler != 'daily') {
            setSelectedDaysNew(null)
            const selectedDays = []
            //  if there is start date and end date
            if (startDate && endDate) {
                setInDate(true)
                //  week days get enabled 
                const diffInDays = moment(endDate).diff(moment(startDate), 'days')
                // to disable unwanted days

                if (diffInDays < 7) {

                    let currentDate = moment(startDate);
                    const daysOfWeek = ['Sun', 'M', 'Tue', 'W', 'Thu', 'F', 'Sat'];

                    while (currentDate.startOf('day') <= moment(endDate).startOf('day')) {
                        const dayOfWeek = daysOfWeek[currentDate.day()];
                        if (!selectedDays.includes(dayOfWeek)) {
                            selectedDays.push(dayOfWeek);
                        }
                        currentDate.add(1, 'days');
                    }
                    const remainingDays = daysOfWeek.filter(day => !selectedDays.includes(day));
                    setSelectedDaysNew(remainingDays)
                }
                else {
                    setSelectedDaysNew(null)
                }
            }
            else {
                setInDate(false)
            }
        }

    }, [startDate, endDate]);




    const handleInDate = (dateScheduler) => {
        if (dateScheduler != 'daily') {
            setSelectedDaysNew(null)
            const selectedDays = []
            if (startDate && endDate) {
                setInDate(true)
                const diffInDays = moment(endDate).diff(moment(startDate), 'days')

                if (diffInDays < 7) {

                    let currentDate = moment(startDate);
                    const daysOfWeek = ['Sun', 'M', 'Tue', 'W', 'Thu', 'F', 'Sat'];

                    while (currentDate.startOf('day') <= moment(endDate).startOf('day')) {
                        const dayOfWeek = daysOfWeek[currentDate.day()];
                        if (!selectedDays.includes(dayOfWeek)) {
                            selectedDays.push(dayOfWeek);
                            setSelectedDaysNew(selectedDays)
                        }
                        currentDate.add(1, 'days');
                    }
                    const remainingDays = daysOfWeek.filter(day => !selectedDays.includes(day));
                    setSelectedDaysNew(remainingDays)

                }
                else {
                    setSelectedDaysNew(null)
                }
            }
            else {
                setInDate(false)
            }
        }
    }

    const handleDownload = () => {
        let fromDate = moment(startDate).format('YYYY-MM-DD')
        let toDate = moment(endDate).format('YYYY-MM-DD')
        var agentDetails = getFromLocalStorage('userDetails')


        const handleSetError = (key, text) => {
            seasonalError
                ? setSeasonalError({
                    ...seasonalError,
                    [key]: text,
                })
                : setSeasonalError({
                    [key]: text,
                })
        }

        if (startDate === '' || endDate === '') {

            const errorObj = validateAwbDates({
                startDate,
                endDate
            })
            setSeasonalError(errorObj)
        }

        else {
            setIsLoading(true)
            const data = {
                "awbNumber": awbNumber,
                "customerName": JSON.parse(agentDetails).companyName,
                "fromDate": fromDate,
                "toDate": toDate,
                "agentCode": JSON.parse(agentDetails).agentCode,
                "reportType": reportType
            }

            generateAwbReports(data).then((res) => {
                setIsLoading(false)
                let url = res.data
                reportType === 'csv' ? window.open(url, "_self") : window.open(url, "_blank")

            })
        }

    }


    const clearError = (key) => {
        const tempError = seasonalError ? { ...seasonalError } : null
        if (tempError) {
            tempError[key] = null
            setSeasonalError(tempError)
        }
    }
    const handleSetError = (key, text) => {
        seasonalError
            ? setSeasonalError({
                ...seasonalError,
                [key]: text,
            })
            : setSeasonalError({
                [key]: text,
            })
    }



    return (
        <>
            <div className='form-row search-panel'>
                <div
                    className={`form-group col-lg-2
                    ${seasonalError && seasonalError[FORM_FIELDS.INVALID_REPORT_START_DATE] ? 'red-border' : ''
                        }`}

                >

                    <label>{t('PERMANENT_BOOKING.START_DATE')}</label>
                    <div class='input-wrapper f-15'>
                        <Calendar
                            className={`form-control form-item`}
                            value={moment(startDate, 'DD-MMM-YYYY').toDate()}
                            onChangeHandler={(shippingDate) => {
                                setStartDate(shippingDate)
                                if (shippingDate) {
                                    const errorObj = seasonalError ? { ...seasonalError } : {}
                                    errorObj[FORM_FIELDS.INVALID_REPORT_START_DATE] = null
                                    errorObj[FORM_FIELDS.INVALID_REPORT_START_DATE] = null
                                    setSeasonalError(errorObj)
                                }

                            }}
                            // onFocusHandler={ }
                            placeholder='Start date'
                            minDate={schedulerMinDate}
                            maxDate={endDate === '' ? moment(schedulerMaxShippingDate, 'DD-MMM-YYYY').toDate() : moment(endDate, 'DD-MMM-YYYY').toDate()}
                            onErrorHandler={() => {
                                handleSetError(
                                    FORM_FIELDS.INVALID_REPORT_START_DATE,
                                    MESSAGES.INVALID_REPORT_START_DATE
                                )
                            }}
                            onFocusHandler={() =>
                                clearError(FORM_FIELDS.INVALID_REPORT_START_DATE)
                            }
                        // onBlur={({ target }) => { }}
                        />
                    </div>

                    <span className='search-panel__errormsg'>
                        {seasonalError && seasonalError[FORM_FIELDS.INVALID_REPORT_START_DATE]
                            ? t(seasonalError[FORM_FIELDS.INVALID_REPORT_START_DATE], { ns: 'errors' })
                            : ''}
                    </span>

                </div>
                <div
                    className={` form-group col-lg-2 ${seasonalError && seasonalError[FORM_FIELDS.INVALID_REPORT_END_DATE] ? 'red-border' : ''
                        }`}
                >
                    <label>{t('PERMANENT_BOOKING.END_DATE')}</label>
                    <div class='input-wrapper f-15'>
                        <Calendar
                            className={`form-control form-item`}
                            value={moment(endDate, 'DD-MMM-YYYY').toDate()}
                            onChangeHandler={(shippingDate) => {
                                setEndDate(shippingDate)
                                if (shippingDate) {
                                    const errorObj = seasonalError ? { ...seasonalError } : {}
                                    errorObj[FORM_FIELDS.INVALID_REPORT_END_DATE] = null
                                    errorObj[FORM_FIELDS.INVALID_REPORT_END_DATE] = null
                                    setSeasonalError(errorObj)
                                }
                            }}
                            onFocusHandler={() =>
                                clearError(FORM_FIELDS.INVALID_REPORT_END_DATE)
                            }
                            placeholder='End date '
                            minDate={startDate === '' ? (moment(schedulerMinDate, 'DD-MMM-YYYY').toDate()) : (moment(startDate, 'DD-MMM-YYYY').toDate())}
                            maxDate={(moment(schedulerMaxShippingDate, 'DD-MMM-YYYY').toDate())}
                            onErrorHandler={() => {
                                handleSetError(
                                    FORM_FIELDS.INVALID_REPORT_END_DATE,
                                    MESSAGES.INVALID_REPORT_END_DATE
                                )
                            }}
                        />
                    </div>
                    <span className='search-panel__errormsg'>
                        {seasonalError && seasonalError[FORM_FIELDS.INVALID_REPORT_END_DATE]
                            ? t(seasonalError[FORM_FIELDS.INVALID_REPORT_END_DATE], { ns: 'errors' })
                            : ''}
                    </span>
                </div>

                <div className='form-group col-lg-2'>
                    <label className="form-item__label">{t('PERMANENT_BOOKING.REPEAT')}</label>
                    <div class='input-wrapper f-15'>
                        <Dropdown
                            className={`form-control form-item form-control`}
                            value={dateScheduler}
                            options={REPEAT_TYPE}
                            label=''
                            onChange={(e) => {
                                clearError(SEASONAL_FIELDS.ON_DAY)
                                setDateScheduler(e.target.value);
                                handleInDate(e.target.value)
                                setStartonDay('')
                                { weekArray.splice(0, weekArray?.length) }
                            }}

                        ></Dropdown>
                    </div>
                </div>
                {dateScheduler != 'daily' ?
                    <>
                        <div className='col col-12 col-lg-6'>
                            <label className='label-onday'>{t('PERMANENT_BOOKING.ON_DAY')}</label>
                            <div className="flex-container ">
                                <SelectableDiv
                                    day={'S'}
                                    value={'Sunday'}
                                    weekdays={weekdays}
                                    setWeekdays={setWeekdays}
                                    startDate={startDate}
                                    endDate={endDate}
                                    startonDay={startonDay}
                                    setStartonDay={setStartonDay}
                                    weekCount={7}
                                    setSelectedDates={setSelectedDates}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError}
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('Sun') ? false : true}

                                />
                                <SelectableDiv day={'M'}
                                    value={'Monday'}
                                    weekdays={weekdays}
                                    setWeekdays={setWeekdays}
                                    weekCount={1}
                                    startDate={startDate}
                                    endDate={endDate}
                                    startonDay={startonDay}
                                    setSelectedDates={setSelectedDates}
                                    setStartonDay={setStartonDay}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError}
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('M') ? false : true}
                                />
                                <SelectableDiv
                                    day={'T'}
                                    value={'Tuesday'}
                                    weekdays={weekdays}
                                    setWeekdays={setWeekdays}
                                    weekCount={2}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setSelectedDates={setSelectedDates}
                                    startonDay={startonDay}
                                    setStartonDay={setStartonDay}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError}
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('Tue') ? false : true}
                                />
                                <SelectableDiv
                                    day={'W'}
                                    value={'Wednesday'}
                                    weekdays={weekdays}
                                    setSelectedDates={setSelectedDates}
                                    setWeekdays={setWeekdays}
                                    weekCount={3}
                                    startDate={startDate}
                                    endDate={endDate}
                                    startonDay={startonDay}
                                    setStartonDay={setStartonDay}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError} y
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('W') ? false : true}
                                />
                                <SelectableDiv day={'T'}
                                    value={'Thursday'} weekdays={weekdays}
                                    setWeekdays={setWeekdays}
                                    weekCount={4}
                                    setSelectedDates={setSelectedDates}
                                    startDate={startDate}
                                    endDate={endDate}
                                    startonDay={startonDay}
                                    setStartonDay={setStartonDay}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError}
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('Thu') ? false : true}
                                />
                                <SelectableDiv
                                    day={'F'}
                                    value={'Friday'}
                                    setSelectedDates={setSelectedDates}
                                    weekdays={weekdays}
                                    setWeekdays={setWeekdays}
                                    weekCount={5}
                                    startDate={startDate}
                                    endDate={endDate}
                                    startonDay={startonDay}
                                    setStartonDay={setStartonDay}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError}
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('F') ? false : true}
                                />
                                <SelectableDiv day={'S'}
                                    value={'Saturday'}
                                    startDate={startDate}
                                    endDate={endDate}
                                    weekdays={weekdays}
                                    setWeekdays={setWeekdays}
                                    weekCount={6}
                                    setSelectedDates={setSelectedDates}
                                    startonDay={startonDay}
                                    setStartonDay={setStartonDay}
                                    selectedDates={selectedDates}
                                    seasonalError={seasonalError}
                                    setSeasonalError={setSeasonalError}
                                    clearError={clearError}
                                    handleSetError={handleSetError}
                                    dateScheduler={dateScheduler}
                                    disabled={inDate ? false : true}
                                    lessSeven={selectedDaysNew?.includes('Sat') ? false : true}
                                />

                            </div>
                            <span className='search-panel__errormsg'>
                                {seasonalError && seasonalError[SEASONAL_FIELDS.ON_DAY]
                                    ? t('PERMANENT_BOOKING.SELECTED_OPTION')
                                    : ''}
                            </span>
                        </div>


                    </> : <></>
                }

            </div>

        </>
    )
}

export default DateScheduler;