import { POPUP, SAVE_BOOKING } from '../constants'

const initState = {
	request: null,
	response: null,
	warning: null,
	error: null,
}


export default (state = initState, action) => {
	switch (action.type) {
		case SAVE_BOOKING.LOAD:
			return {
				...state,
				request: action.request,
				response: null,
				warning: null,
				error: null,
			}
		case SAVE_BOOKING.SUCCESS:
			return {
				...state,
				response: action.response,
				warning: action.response.warning,
			}
		case SAVE_BOOKING.ERROR:
			return {
				...state,
				error: action.error,
			}
		case POPUP.CLOSE:
			return {
				...state,
				error: null,
			}
		default:
			return state
	}
}
