
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import PaginatorComponent from '../../Pagination/PaginatorComponent'
import LoadingIndicator from '../../LoadingIndicator'
import { getFlightDetails, getFlighFFMDetails } from '../../../services/csnDashboardService'
import FilterPopup from './filterPopup/filterPopup';
import DoughnutChart from '../../Dashboard/DoughnutChart';
import getCurrentUser from '../../../utils/getCurrentUser';
import getPrincipal from '../../../utils/getPrincipal'
import getPLACIUser from '../../../utils/getPLACIUser'
import './ffm-summary.scss';
import { flightFFMSummaryApplyFilter , flightSummaryApplyFilter} from '../../../redux/actions/csnDashboardActions'
import { getPLACIUserDetails } from '../../../services/onLoadServices'
import { setToLocalStorage } from '../../../utils/StorageUtils'
import sessionKeys from '../../../constants/sessionKeys'

const FFMSummary = ({
    setInitialAWB,
    handleTabChange,
    setSelectedFlightData,
    setLastUpdated,
    setNextUpdated,
    setFlights,
    flights,
    setTotalFlights,
    totalFlights,
    setProducts,
    products,
    setTotalRecordCount,
    totalRecordCount,
    setFlightFromDate,
    flightFromDate,
    setFlightToDate,
    flightToDate,
    setShowLoader,
    showLoader,
    flightFFMSummaryApplyFilter,
    flightSummaryFilterFFM,
    consolidatedFlightDetails,
    setConsolidatedFlightDetails,
    setAwbProducts,
    setFlightsRefreshed,
    flightsRefreshed,
    setFiltered,
    setFlightFilter,
    flightFilter,
    setAwbFilter,
    setFilterValues,
    filterValues,
    tabClicked,
    setTabClicked,
    currentPage,
    setCurrentPage,
    pageIndex,
    setPageIndex,
    pageReset,
    setPageReset,
    setFlightFilterSummary,
    flightSummaryApplyFilter,
    setFilterValuesSummary,
    getInitialFilter,
    setFfmFlightFilter,
    ffmFlightFilter,
}) => {
    // const [products, setProducts] = useState([]);
    const rowCount = 10;
    const [initialSearch, setInitialSearch] = useState(null)
    const [calculatedPageNumber, setCalculatedPageNumber] = useState(1)
    const [filterSummary, setFilterSummary] = useState({});


    const [count1, setCount1] = useState(1)
    const [count2, setCount2] = useState(1)
    const [count3, setCount3] = useState(1)
    const [count4, setCount4] = useState(1)
    const [count5, setCount5] = useState(1)
    const [count6, setCount6] = useState(1)
    const [count7, setCount7] = useState(1)
    const [count8, setCount8] = useState(1)
    const [sortByHeader, setSortByHeader] = useState('');
    const [sortOrder, setSortOrder] = useState('ASC')

    const op = useRef(null);
    const refs = useRef(null);
    let userDetails = getCurrentUser();
    let principal = getPrincipal()
    let placiUser = getPLACIUser()

    const getPlaciUserInfo = async () => {
        try {
            const placiUserDetails = getPLACIUser()
            if (!placiUserDetails) {
                setShowLoader(true)
                const userDetailsResponse = await getPLACIUserDetails(principal.sub)
                setShowLoader(false)
                if (userDetailsResponse && userDetailsResponse.data) {
                    setToLocalStorage(
                        sessionKeys.PLACI_USER_DETAILS,
                        JSON.stringify(userDetailsResponse.data)
                    )
                    return userDetailsResponse.data
                }
            } else {
                return placiUserDetails
            }

        } catch (err) {
            setShowLoader(false)
            console.log(err)
        }
    }

    const getProducts = async (reqObj) => {
        try {
            setShowLoader(true);
            if (principal && principal.type === 'consumer') {
                if (!placiUser) {
                    placiUser = await getPlaciUserInfo()
                }
                if (placiUser && placiUser.stationCode && !reqObj.stationCode) {
                    reqObj.stationCode = placiUser.stationCode
                }
            }
            setProducts([])
            const res = await getFlighFFMDetails(reqObj);
            if (res && res.data) {
                setFlights(res.data?.consolidatedFlightDetails?.flightsCleared);
                setConsolidatedFlightDetails(res.data?.consolidatedFlightDetails)
                setTotalFlights(res.data.consolidatedFlightDetails.totalFlights);
                setProducts(res.data.flightFFMDetails);
                setTotalRecordCount(res.data.pagination.totalRecordCount)
                setFlightFromDate(res.data.flightFromDate);
                setFlightToDate(res.data.flightToDate);
                setLastUpdated(res.data.lastRefreshedTime)
                setNextUpdated(res.data.nextRefreshTime)
                setAwbProducts([])
                setFlightsRefreshed(true)
                setShowLoader(false)
                if (reqObj.airlinePrefix && reqObj.airlinePrefix !== '' && reqObj.awbNumber && reqObj.awbNumber !== '') {
                    let awbFilterData = {
                        airlinePrefix: reqObj.airlinePrefix,
                        awbNumber: reqObj.awbNumber,
                    }
                    setAwbFilter(awbFilterData)
                    if ((!tabClicked || tabClicked) && tabClicked !== 'FLIGHT' && tabClicked !== 'FFM' && res?.data?.flightDetails && res.data.flightDetails.length > 0) {
                        handleTableClick(res.data.flightDetails[0])
                    }
                } else {
                    setAwbFilter(null)
                }
            } else {
                setShowLoader(false);
            }
            setTabClicked(null)
        } catch (err) {
            console.log(err)
            setShowLoader(false);
        }
    }
    useEffect(() => {
        if (ffmFlightFilter) {
            setFlightFilterSummary(null)
            flightSummaryApplyFilter(null)
            setFilterValuesSummary(getInitialFilter())
        }

        setShowLoader(true);
        let reqObj = flightSummaryFilterFFM ? setRequestObj(flightSummaryFilterFFM, pageIndex) : setRequestObj(null, pageIndex)
        getProducts(reqObj);
        setInitialSearch(false)
    }, [])

    useEffect(() => {
        if (flightSummaryFilterFFM) {
            setFilterSummary(flightSummaryFilterFFM);
        }
    }, [flightSummaryFilterFFM])

    const handleFilterAction = (filterValues) => {
        setPageReset(true)

        setFilterSummary(filterValues);
        setFlightFilter(filterValues)
        let reqObj = setRequestObj(filterValues, 1);
        getProducts(reqObj);
        //setShowLoader(true);
        flightFFMSummaryApplyFilter(filterValues);
    }

    const formatDate = (date) => {
        if (date) {
            let datearray = date.split("-");
            let newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            let dateObject = new Date(newdate);
            return moment(dateObject).format('DD/MMM/YYYY');
        }
    }

    useEffect(() => {
        if (initialSearch) {
            setPageIndex(1)
            setCurrentPage(0)
        }
    }, [initialSearch])

    const handleColumnSort = (header, counter) => {
        let count = 0;
        if (header == "NUMBER") {
            count = count1
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if (header == "ORIGIN") {
            count = count2
            setSortByHeader(header)
            setSortOrder(counter)

        }
        else if (header == "DESTINATION") {
            count = count3
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if (header == "DEPARTURETIME") {
            count = count4
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if (header == "ARRIVALTIME") {
            count = count5
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if (header == "STATUS") {
            count = count6
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if (header == "IMPACTEDAWBS") {
            count = count7
            setSortByHeader(header)
            setSortOrder(counter)
        }
        else if (header == 'REMAININGTIMEBEFORESTD') {
            count = count8
            setSortByHeader(header)
            setSortOrder(counter)
        }



        let sortValues = {
            "sortBy": header,
            "sortOrder": counter % 2 == 0 ? "ASC" : counter === 1 ? "ASC" : "DESC"
        }
        setPageReset(true)
        let reqObj = setRequestObj(filterSummary, 1, counter === 1 ? {} : sortValues);
        getProducts(reqObj);
        //setShowLoader(true);
    }

    const setRequestObj = (filterData, pageNumber, sortColumn) => {
        let reqObj = {
            "requestId": uuidv4(),
            "agentCode": userDetails ? userDetails.agentCode : principal ? principal.sub : null,
            "userId": userDetails ? userDetails.userId : principal ? principal.sub : null,
            userType: principal ? principal.type : null,
            stationCode: placiUser ? placiUser.stationCode : '',
            "pagination": {
                "pageNumber": pageNumber ? pageNumber : calculatedPageNumber,
                "pageSize": 10,
            },

        }
        if (filterData && filterData?.depDetails?.showDepartedFlights) {
            filterData.depDetails.utc = false
        }
        let reqData = {
            ...reqObj,
            ...filterData,
            ...sortColumn
        }

        if (filterData || sortColumn) {
            return reqData;
        }
        else {
            return reqObj;
        }
    }

    const onPageIndexChange = (newPageIndex, currentPageToDisplay) => {
        setPageIndex(newPageIndex)
        setCurrentPage(currentPageToDisplay)
        setInitialSearch(false)
        setCalculatedPageNumber(newPageIndex)
        let sortValues = {
            "sortBy": sortByHeader,
            "sortOrder": sortOrder % 2 == 0 ? "ASC" : sortOrder === 1 ? "ASC" : "DESC"
        }
        let reqObj = setRequestObj(filterSummary, newPageIndex, sortOrder === 1 ? {} : sortValues)
        getProducts(reqObj);
        setShowLoader(true);
        setPageReset(false);
    }

    const handleTableClick = (row) => {
        setInitialAWB(true);
        document.documentElement.scrollTop = 0;

        let reqObj = {
            depDetails: {
                fromDate: row?.departureDetails?.departureDate ? row?.departureDetails?.departureDate: '',
                toDate: row?.departureDetails?.departureDate ? row?.departureDetails?.departureDate: '',
                utc: false,
                utcInterval: '',
                fromTime: '0:00 AM',
                toTime: '23:59 PM',
                departureTimeInHours: [
                    '0:00 AM',
                    '23:59 PM',
                ],
                departureTime: [0, 1439],
            },
            arrDetails: {
                fromDate: '',
                toDate: '',
                arrivalTimeInHours: [
                    '0:00 AM',
                    '23:59 PM',
                ],
                arrivalTime: [0, 1439],
            },
            customsStatus: {
                finalized: false,
                onHold: false,
                inProgress: false
            },
            status: [],
            carrierCode: row.carrierCode,
            flightNumber: row.flightNo,
            airlinePrefix: '607',
            awbNumber: '',
        }

        //setSelectedFlightData(reqObj);
        setFlightFilterSummary(reqObj)
        flightSummaryApplyFilter(reqObj)
        setFilterValuesSummary(reqObj)
        setFfmFlightFilter(true)
        handleTabChange(0);
    }

    const getFlightData = () => {
        let arr = []
        if (consolidatedFlightDetails) {
            let flightCount = flights;
            arr.push(flightCount, consolidatedFlightDetails?.flightsOnHold)
            //arr.push(2, 0 , 1)
        }
        return arr;
    }

    const getFlightNo = (row) => {

        return <span className='flight-no-chart'
            style={{ cursor: "pointer", textDecoration: "underline", textDecorationColor: "rgb(0, 95, 150)" }}
            onClick={() => handleTableClick(row)}>
            {
                row.carrierCode + '-' + row.flightNo
            }
        </span>
    }

    const formatTime = (time) => {
        let dateArray = time.split(':');
        let hours = dateArray[0];
        let minutes = dateArray[1];

        const strTime = `${hours}:${minutes}`;
        return strTime;
    }

    const stockBodyTemplate = (rowData) => {
        let statusClassName = ''
        let statusValue = ''
        switch (rowData.ffmStatus) {
            case 'RECEIVED':
                statusClassName = 'status-finalized'
                statusValue = 'Received'
                break;
            case 'NOT_RECEIVED':
                statusClassName = 'status-onhold'
                statusValue = 'Not received'
                break;
            default: ''
        }
        return (
            <div className={`sign-post-container`}>
                <div className={`sign-post flight-status ${statusClassName}`} >
                    {statusValue}
                </div>
            </div>


        );
    }

    const getFlightNoHeader = () => {
        return (
            <div>
                <span>Flight No</span>
                <i className={(count1 > 1) ? (count1 % 2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count1 === 1 ? setCount1(2) : count1 === 2 ? setCount1(3) : setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("NUMBER", count1 === 1 ? 2 : count1 === 2 ? 3 : 1)
                }}></i>

            </div>
        )
    }

    const getOriginHeader = () => {
        return (
            <div>
                <span>Origin</span>
                <i className={(count2 > 1) ? (count2 % 2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count2 === 1 ? setCount2(2) : count2 === 2 ? setCount2(3) : setCount2(1)
                    setCount1(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("ORIGIN", count2 === 1 ? 2 : count2 === 2 ? 3 : 1)
                }}></i>
            </div>
        )
    }

    const getDestinationHeader = () => {
        return (
            <div>
                <span>Destination</span>
                <i className={(count3 > 1) ? (count3 % 2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count3 === 1 ? setCount3(2) : count3 === 2 ? setCount3(3) : setCount3(1)
                    setCount1(1)
                    setCount2(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("DESTINATION", count3 === 1 ? 2 : count3 === 2 ? 3 : 1)
                }}></i>
            </div>
        )
    }

    const getDepartureHeader = () => {
        return (
            <div>
            <span>Departure date</span>
            {/*<i className={(count4 > 1) ? (count4 % 2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                e.preventDefault();
                count4 === 1 ? setCount4(2) : count4 === 2 ? setCount4(3) : setCount4(1)
                setCount1(1)
                setCount2(1)
                setCount3(1)
                setCount5(1)
                setCount6(1)
                setCount7(1)
                setCount8(1)
                handleColumnSort("DEPARTURETIME", count4 === 1 ? 2 : count4 === 2 ? 3 : 1)
            }}></i>*/}
        </div>
        )
    }

    const getArrivalHeader = () => {
        return (
            <div>
                <span>Arrival date</span>
                <i className={(count5 > 1) ? (count5 % 2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count5 === 1 ? setCount5(2) : count5 === 2 ? setCount5(3) : setCount5(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount6(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("ARRIVALTIME", count5 === 1 ? 2 : count5 === 2 ? 3 : 1)
                }}></i>
            </div>
        )
    }

    const getStatusHeader = () => {
        return (
            <div>
                <span>FFM status</span>
                <i className={(count6 > 1) ? (count6 % 2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count6 === 1 ? setCount6(2) : count6 === 2 ? setCount6(3) : setCount6(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount7(1)
                    setCount8(1)
                    handleColumnSort("STATUS", count6 === 1 ? 2 : count6 === 2 ? 3 : 1)
                }}></i>
            </div>
        )
    }

    const secondsToHms = (d) => {

        d = Number(d);

        if (d > 0) {
            var h = Math.floor(d / (1000 * 60 * 60));
            var m = Math.floor((d / (1000 * 60)) % 60);
            //var s = Math.floor(d % 3600 % 60);
            if (h < 0 && m < 0) {
                var hDisplay = '';
                var mDisplay = '';
            } else {
                var hDisplay = h > 0 ? h > 9 ? h + '' : '0' + h + '' : '00';
                var mDisplay = m > 0 ? m > 9 ? m : '0' + m : '00';
            }

            // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return `${hDisplay} hrs ${mDisplay} mins`
        } else {
            return 'Departed'
        }
    }

    const getRemainingTime = (rowData) => {
        let dateUTC = rowData.departureDetails.departureDateUTC;
        let timeUTC = rowData.departureDetails.departureTimeUTC;
        let newDate = dateUTC + ' ' + timeUTC+'Z';
        let currDate = new Date().toUTCString();
        let currentDate = new Date(currDate)
        let departureDate = new Date(newDate);
        let currentTimeStamp = currentDate.getTime();
        let departureTimeStamp = departureDate.getTime();

        return(
            <div>
                {secondsToHms(currentTimeStamp - departureTimeStamp)}
            </div>
        )
    }

    const getRemainingTimeBeforeStd = () => {
        return (
            <div>
                <span>Time since STD</span>
                <i className={(count8>1)? (count8%2 === 0 ? 'sort-icon pi pi-sort-amount-up-alt' : 'sort-icon pi pi-sort-amount-down') : 'sort-icon pi pi-sort-alt'} onClick={(e) => {
                    e.preventDefault();
                    count8 === 1 ? setCount8(2) : count8 === 2? setCount8(3) : setCount8(1)
                    setCount1(1)
                    setCount2(1)
                    setCount3(1)
                    setCount4(1)
                    setCount5(1)
                    setCount6(1)
                    setCount7(1)
                    handleColumnSort("REMAININGTIMEBEFORESTD",count8 === 1 ? 2 : count8 === 2? 3 : 1)}}></i>
            </div>
        )
    }

    return (
        <div className='ffm-dashboard'>
            <div className="csn-table card">
                <div className="row">
                    <div className="col col-12 col-md-6 flight-date-subhead">
                        <h3>UAE PLACI - FFM Status Dashboard</h3>
                    </div>
                    <div className="col col-12 col-md-6 doughnut-summary">
                        <div className='row'>
                            <div className='col col-4'>
                            </div>
                            <div className='col col-6'>
                                <div className='placi-chart'>
                                    <DoughnutChart
                                        dataSetLabel='Flights'
                                        labels={['Received', 'Not received']}
                                        backgroundColor={['#559E49', '#FF5A4B']}
                                        borderColor={'#ebe8e1'}
                                        data={getFlightData()}
                                        showFlightData={true}
                                        flightPercentage={totalFlights !== 0 ? parseInt((flights / totalFlights) * 100) : 0}
                                        flights={flights}
                                        flightCount={totalFlights}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <FilterPopup
                    op={op}
                    setFilterSummary={setFilterSummary}
                    handleFilterAction={handleFilterAction}
                    flightFromDate={flightFromDate}
                    flightToDate={flightToDate}
                    flightSummaryApplyFilter={flightFFMSummaryApplyFilter}
                    flightSummaryFilter={flightSummaryFilterFFM}
                    setFlightsRefreshed={setFlightsRefreshed}
                    flightsRefreshed={flightsRefreshed}
                    setFiltered={setFiltered}
                    setFlightFilter={setFlightFilter}
                    flightFilter={flightFilter}
                    setFilterValues={setFilterValues}
                    filterValues={filterValues}
                />

                <div className='table-position'>
                    <DataTable value={products} responsiveLayout="scroll" id="userList" className="ffm-status-table mt-3">
                        <Column field="flightNo" header={getFlightNoHeader()} body={(e) => getFlightNo(e)} className='flight-no'></Column>


                        <Column field="departureDetails.departureDate" header={getDepartureHeader()} className='dep-time' body={(e) => {
                            return (
                                <>
                                    <div>
                                        {formatDate(e.departureDetails?.departureDate)} {formatTime(e.departureDetails?.departureTime)}
                                    </div>
                                </>
                            )
                        }}></Column>
                        <Column field="arrivalDetails.arrivalDate" header={getArrivalHeader()} className='arrv-time' body={(e) => {
                            return (
                                <>
                                    <div>
                                        {formatDate(e.arrivalDetails?.arrivalDate)} {formatTime(e.arrivalDetails?.arrivalTime)}
                                    </div>
                                </>
                            )
                        }}></Column>


                        <Column field="origin" header={getOriginHeader()} className='origin'></Column>

                        <Column field="destination" header={getDestinationHeader()} className='destination'></Column>

                        {<Column field="New field" className='timeafterSTD' header={getRemainingTimeBeforeStd()} body={(e) => getRemainingTime(e)}></Column>}


                        <Column field="status" header={getStatusHeader()} className='ffm-status' body={(e) => stockBodyTemplate(e)}></Column>

                    </DataTable>
                </div>
            </div>
            {products && products.length > 0 ? (
                <div className='paginationWrapper'>
                    <PaginatorComponent
                        initialSearch={initialSearch}
                        currentPage={currentPage}
                        pageIndex={pageIndex}
                        rowCount={rowCount}
                        totalRecords={totalRecordCount}
                        onPageIndexChange={onPageIndexChange}
                        pageReset={pageReset}
                    />
                </div>) :
                (!showLoader && <p className='data-table-empty'>There is no data that matches those criteria</p>)
            }
            {showLoader ?
                <LoadingIndicator /> : <></>
            }
        </div>
    );
}

const mapStateToProps = ({
    csnDashboard
}) => ({
    flightSummaryFilterFFM: csnDashboard.flightSummaryFilterFFM,
    flightSummaryFilter: csnDashboard.flightSummaryFilter
})

const mapDispatchToProps = (dispatch) => ({
    flightFFMSummaryApplyFilter: (filter) => {
        dispatch(flightFFMSummaryApplyFilter(filter))
    },
    flightSummaryApplyFilter: (filter) => {
		dispatch(flightSummaryApplyFilter(filter))
	},
})


export default connect(mapStateToProps, mapDispatchToProps)(FFMSummary)
