import React from 'react'
import { connect } from 'react-redux'
import { clearAuth } from '../../redux/actions/authAction'
import { setSearchFormData } from '../../redux/actions/searchFormActions'
import Root from './Root'

const RootContainer = ({
	isLoading,
	toastDetails,
	displayPopup,
	setSearchFormDataToState,
	clearAuthFromState,
	principal,
}) => {
	return (
		<Root
			isLoading={isLoading}
			toastDetails={toastDetails}
			displayPopup={displayPopup}
			setSearchFormDataToState={setSearchFormDataToState}
			clearAuthFromState={clearAuthFromState}
			principal={principal}
		/>
	)
}

const mapStateToProps = ({ loader, toast, auth }) => ({
	principal: auth.principal,
	isLoading: loader.isLoading,
	toastDetails: toast.toastDetails,
})

const mapDispatchToProps = (dispatch) => ({
	setSearchFormDataToState: (searchFormData) => {
		dispatch(setSearchFormData(searchFormData))
	},
	clearAuthFromState: () => {
		dispatch(clearAuth())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
