import { FLIGHT_ENQUIRY_FORM_FIELDS } from '../../constants'
import { checkFlightFormat, padLeadingZeros } from '../../utils/Validations'
import MESSGAES from '../../constants/Messages'
export const validateOrigin = (origin) =>
	origin && origin.code ? null : MESSGAES.VALID_ORIGIN
export const validateDestination = (destination, origin) => {
	if (destination && destination.code) {
		if (origin && origin.code) {
			if (origin.code == destination.code) {
				return MESSGAES.SAME_ORG_DEST_ERROR
			} else {
				return null
			}
		} else {
			return null
		}
	} else {
		return MESSGAES.INVALID_DESTINATION
	}
}
export const validateShippingDate = (flightDate) =>
	flightDate ? null : MESSGAES.INVALID_SHIPPING_DATE


const validateFlightNoRegex = (flightNumber, showFlightNoValidation) => {
	if (!checkFlightFormat(flightNumber)) {
		showFlightNoValidation(true)
		return MESSGAES.INVALID_FLIGHT_NUMBER
	}
}

const validateFlightNo = (flightNo, showFlightNoValidation) => {
	showFlightNoValidation(false)
	let newFlightNumber
	if (flightNo) {
		if (flightNo.length < 4) {
			newFlightNumber = padLeadingZeros(flightNo, 4)
			return validateFlightNoRegex(newFlightNumber, showFlightNoValidation)
		}
		if (flightNo.length === 4 || flightNo.length === 5) {
			return validateFlightNoRegex(flightNo, showFlightNoValidation)
		}
	}
}

export const validateFormData = (
	selectedOrigin,
	selectedDestination,
	flightDate,
	flightNo,
	showFlightNoValidation
) => {
	const error = {}
	error[FLIGHT_ENQUIRY_FORM_FIELDS.ORIGIN] = validateOrigin(selectedOrigin)
	error[FLIGHT_ENQUIRY_FORM_FIELDS.DESTINATION] = validateDestination(
		selectedDestination,
		selectedOrigin
	)
	error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE] =
		validateShippingDate(flightDate)
		error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER] = validateFlightNo(flightNo, showFlightNoValidation)

	const isValid = !Object.keys(error).some((key) => (error[key] ? true : false))
	return isValid ? null : error
}
