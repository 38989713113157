import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadListTemplate } from '../../redux/actions/manageTemplateActions'
import ManageTemplate from './ManageTemplateActions/ManageTemplate'
const ManageBookingContainer = ({
	manageTemplateResponse,
	manageTemplateError,
	getTemplateDetails,
}) => {
	const history = useHistory()

	useEffect(() => {
		if (history.location.state && history.location.state.updateStatus) {
			let state = { ...history.location.state }
			delete state.updateStatus
			history.replace({ ...history.location, state })
		}
	}, [history])

	return (
		<ManageTemplate
			manageTemplateResponse={manageTemplateResponse}
			manageTemplateError={manageTemplateError}
			getTemplateDetails={getTemplateDetails}
			updateStatus={
				history.location.state && history.location.state.updateStatus
					? history.location.state.updateStatus
					: null
			}
		/>
	)
}

const mapStateToProps = ({ manageTemplate }) => ({
	manageTemplateResponse: manageTemplate.response,
	manageTemplateError: manageTemplate.error,
})

const mapDispatchToProps = (dispatch) => ({
	getTemplateDetails: (templateData) => {
		dispatch(loadListTemplate(templateData))
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ManageBookingContainer)
