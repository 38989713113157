import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import fastTrack from '../../assets/images/fasttrack.png'
import {
	getWeightUnit,
	WEIGHT_UNIT,
	getDecimalFormattedNumber,
} from '../../constants'
import './fasttrack.scss'
import FlightDetails from '../FlightDetails'
import { Trans, useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
const FastTrack = ({
	setEnquiredPresent,
	fastTrackList,
	fasttrackLowestRate,
	fasterToDefaultByMin,
}) => {
	const [isFastTrackExpanded, setFastTrackExpanded] = useState(false)
	const [diffDays, setDiffDays] = useState(0)
	const [diffHrs, setDiffHrs] = useState(0)
	const [diffMins, setDiffMins] = useState(0)

	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	useEffect(() => {
		const diffDays = fasterToDefaultByMin
			? Math.floor(fasterToDefaultByMin / 60 / 24)
			: 0
		const diffHrs = fasterToDefaultByMin
			? Math.floor((fasterToDefaultByMin / 60) % 24)
			: 0
		const diffMins = fasterToDefaultByMin
			? Math.floor(fasterToDefaultByMin % 60)
			: 0
		setDiffDays(diffDays)
		setDiffHrs(diffHrs)
		setDiffMins(diffMins)
	}, [fastTrackList, fasterToDefaultByMin])
	return (
		<div className='col-12'>
			<div
				className={`flight-details fasttrack ${
					isFastTrackExpanded ? 'border-expand' : ''
				}`}
			>
				<div
					className='flight-details__item parent-line'
					onClick={() => setFastTrackExpanded(!isFastTrackExpanded)}
				>
					<div className='flight-details__left'>
						<div className='flight-details__price__service'>
							<img src={fastTrack} alt='fastTrack' title='fastTrack' />
						</div>

						<div class='flight-details__price__sub'>
							{fastTrackList[0].ratingDetails[0].grandTotal !== 0 ? (
								<div class='flight-details__price__sub__pricing'>
									<div class='flight-details__price__sub__amount'>
										<label>
											{fasttrackLowestRate.currency}{' '}
											{getDecimalFormattedNumber(
												fastTrackList[0].ratingDetails[0].grandTotal
											)}
										</label>
									</div>
									<div class='flight-details__price__sub__rate'>
										<label>
											{fasttrackLowestRate.currency}{' '}
											{getDecimalFormattedNumber(
												fastTrackList[0].ratingDetails[0].totalRate
											)}
											{'/'}
											{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
										</label>
									</div>
								</div>
							) : (
								<div className='flight-details__no-rate'>
									{t('COMMON.NO_RATES')}
								</div>
							)}
						</div>
					</div>
					<div className='flight-details__right'>
						{fasterToDefaultByMin > 0 ? (
							<div className='flight-details__right__message'>
								<Trans
									i18nKey={t('ER042', {
										diffDays: diffDays ? diffDays + t('COMMON.DAYS') : '',
										diffMins: diffMins
											? (diffDays || diffHrs ? t('COMMON.AND') : '') +
											  diffMins +
											  t('COMMON.MINUTES')
											: '',
										diffHrs: diffHrs
											? (diffDays && !diffMins ? t('COMMON.AND') : '') +
											  diffHrs +
											  t('COMMON.HOURS')
											: '',
										ns: 'errors',
									})}
									t={t}
								/>
								{/* Get your cargo delivered <br />
								{diffDays
									? diffDays + ' day(s) '
									: ''}{' '}
								{diffHrs
									? (diffDays && !diffMins ? 'and ' : '') + diffHrs + ' hour(s)'
									: ''}{' '}
								{diffMins
										? (diffDays || diffHrs ? 'and ' : '') +
										diffMins +
										' minute(s)'
									: ''}{' '}
									earlier, using FastTrack. */}
							</div>
						) : (
							<div className='flight-details__right__message'>
								{' '}
								<Trans>
									{t(Messages.FASTTRACK_BANNER_MSG, { ns: 'errors' })}
								</Trans>
							</div>
						)}
						{!isFastTrackExpanded ? (
							<div class='flight-details__arrowRight'>
								<i>
									<FontAwesomeIcon icon={faChevronRight} />
								</i>
							</div>
						) : (
							<div class='flight-details__close'>
								<i>
									<FontAwesomeIcon icon={faTimes} />
								</i>
							</div>
						)}
					</div>
				</div>
				{isFastTrackExpanded ? (
					<div className='flight-details__list'>
						{fastTrackList && fastTrackList.length ? (
							fastTrackList.map((destinationAirport, index) => (
								<FlightDetails
									setEnquiredPresent={setEnquiredPresent}
									destinationAirport={destinationAirport}
									key={index}
								/>
							))
						) : (
							<></>
						)}
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default FastTrack
