import React from 'react'
import CaptureHawbDetails from './CaptureHawbDetails'

const CaptureHawb = ({ shipmentPrefix, masterDocumentNumber }) => {
	return (
		<div className='form-row'>
			<div className='col-12'>
				<CaptureHawbDetails
					shipmentPrefix={shipmentPrefix}
					masterDocumentNumber={masterDocumentNumber}
				/>
			</div>
		</div>
	)
}

export default CaptureHawb
