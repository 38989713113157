import { TabPanel, TabView } from 'primereact/tabview'
import React, { useState } from 'react'
import getCurrentUser from '../../utils/getCurrentUser'
import CaptureHawb from '../CaptureHawb'
import CaptureAWB from './CaptureAWB'
import Declaration from './Declaration'
import { useTranslation } from 'react-i18next'
const CaptureAWBRoot = ({ showErrorPopup }) => {
	//for multilingual
	const { t } = useTranslation(['labels'])

	const userDetails = getCurrentUser()
	const [showTC, setShowTC] = useState(
		userDetails
			? userDetails.dataCaptureTnCFlag === false
			: true
	)
	const [activeIndex, setActiveIndex] = useState(0)
	const [shipmentPrefix, setShipmentPrefix] = useState(null)
	const [masterDocumentNumber, setMasterDocumentNumber] = useState(null)

	const handleTabChange = (index) => {
		setActiveIndex(index)
		if (index === 0) {
			setShipmentPrefix(null)
			setMasterDocumentNumber(null)
		}
	}

	return userDetails && Object.keys(userDetails).length !== 0 ? (
		<>
			<div className='container-lg cargo-portal-container portal-tabs tabs-data-capture'>
				{!showTC ? (
					<div className='data-capture'>
						<div className='form-row'>
							<div className='col-12'>
								<h3 className='page-heading mt-0'>
									{t('COMMON.DATA_CAPTURE')}
								</h3>
							</div>
						</div>
						<div className='form-row capture-faqs-row'>
							<div className='capture-faqs'>
								<a href='/PLACI-QuickHelp.pdf' className='link' target='_blank' >PLACI - Quick Help</a>
							</div>
						</div>
						<TabView
							activeIndex={activeIndex}
							onTabChange={(e) => handleTabChange(e.index)}
						>
							<TabPanel
								header={t('CAPTURE_AWB.AWB_SCREEN')}
								headerClassName='awb-capture no-icon'
							>
								<CaptureAWB
									setShipmentPrefix={setShipmentPrefix}
									setMasterDocumentNumber={setMasterDocumentNumber}
									handleTabChange={handleTabChange}
								/>
							</TabPanel>
							<TabPanel
								header={t('COMMON.HOUSE_AWB')}
								headerClassName='hawb-capture no-icon'
							>
								<CaptureHawb
									shipmentPrefix={shipmentPrefix}
									masterDocumentNumber={masterDocumentNumber}
								/>
							</TabPanel>
						</TabView>
					</div>
				) : (
					<Declaration setShowTC={setShowTC} />
				)}
			</div>
		</>
	) : (
		<></>
	)
}

export default CaptureAWBRoot
