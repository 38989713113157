import { UPDATE_BOOKING } from '../constants'

export const updateBooking = (
	awbNumber,
	searchFormData,
	selectedFlight,
	spotRatePayload
) => ({
	type: UPDATE_BOOKING.LOAD,
	searchFormData,
	selectedFlight,
	awbNumber,
	spotRatePayload,
})

export const updateBookingResponse = (response) => ({
	type: UPDATE_BOOKING.SUCCESS,
	response,
})

export const updateBookingError = (error) => ({
	type: UPDATE_BOOKING.ERROR,
	error,
})

export const clearUpdateBooking = () => ({
	type: UPDATE_BOOKING.CLEAR,
})
