import React from 'react'
import {SplitButton} from 'primereact/splitbutton';
import './split-button.scss'

export default ({label, primary, onClick, options, disabled}) => {
    return (
        <SplitButton 
            className={`ml-3 split-btn`}
            label={label} 
            onClick={onClick} 
            model={options}
            disabled={disabled ? true : false}></SplitButton>
    )

}