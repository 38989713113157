import React, { useState } from 'react'
import './index.scss'
import RecentBookingList from './RecentBookingList'

const RecentBookingDetails = ({ recentBookings, isLoading }) => {
	return (
		<div className='recentBooking-details'>
			<RecentBookingList
				recentBookings={recentBookings}
				isLoading={isLoading}
			/>
		</div>
	)
}

export default RecentBookingDetails
