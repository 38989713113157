export const NUMERIC_REGEX = /^\d+$/
export const ALPHA_REGEX = /^[a-zA-Z]+$/
export const FLIGHT_NUMBER_REGEX = /^[0-9]{3,4}([a-zA-Z]{1}|[a-zA-Z]{0})$/
export const ALPHA_NUMERIC_REGEX =  /^[a-zA-Z0-9]+$/
export const ALPHA_NUMERIC_REGEX_SEASONAL = /^([a-zA-Z0-9 -])+$/
export const NAME_REGEX = /^[a-zA-Z\\s .'-]+$/
export const POSTAL_CODE_REGEX = /[a-zA-Z0-9\-. ]{1,35}/
export const FOUR_DECIMAL_PLACES = /^\d+(\.\d{1,4})?$/
export const AWB_PATTERN = /[0-9]{8}/
export const FLIGHT_NUMBER_REGEX_SEASONAL = /^(?:[a-zA-Z]{2}[0-9]{1,4}[a-zA-Z]|[0-9]{1,4}|[a-zA-Z]{2}\s[0-9]{1,4}|[a-zA-Z]{2}\s[0-9]{1,4}[a-zA-Z]|[a-zA-Z]{2}[0-9]{1,4}|[0-9]{1,4}[a-zA-Z]|[a-zA-Z]{2}-[0-9]{1,4}[a-zA-Z]|[a-zA-Z]{2}-[0-9]{1,4})$/
