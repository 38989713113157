import React, { useState, useEffect, useRef } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import {
	AUTOCOMPLETE_TYPE,
	FORM_FIELDS,
	NOTIFICATION_MESSAGE_TYPE,
} from '../../constants'
import {
	getAirports,
	getAirportsByCode,
} from '../../services/searchPanelServices'
import './airport-autocomplete.scss'
import { convertToLowerCase, capitalizeName } from '../../utils/common'
import SimilarBookingSuggestion from './SimilarBookingSuggestion'
import MESSAGES from '../../constants/Messages'
import { useTranslation } from 'react-i18next';


const AirportAutocomplete = ({
	autoCompleteType,
	selectedOrigin,
	selectedDestination,
	setSelectedOrigin,
	setSelectedDestination,
	clearError,
	handleSetError,
	screen,
	displaySimilarBooking,
	setNotLeave,
	draftCallback,
	setNotificationMessage,
	setNotificationType,
	setAlertMessage,
	setStatus,
	setMultiComponentError,
	componentIndex,
}) => {
	const [text, setText] = useState(null)
	const [airports, setAirports] = useState([])
	const [selectedAirport, setSelectedAirport] = useState(null)
	const [filteredAirports, setFilteredAirports] = useState([])
	const isFocused = useRef(false)
	//const { t, i18n } = useTranslation(['labels', 'errors'])
	const autoCompleteRef = useRef(null)
	const { t } = useTranslation(['labels'])

	const handleSelect = (airport) => {
		autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN
			? setSelectedOrigin(airport)
			: setSelectedDestination(airport)
		draftCallback()
	}

	useEffect(() => {
		if (selectedOrigin || selectedDestination) {
			if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
				setSelectedAirport(selectedOrigin)
			} else {
				setSelectedAirport(selectedDestination)
			}
		}
	}, [])

	useEffect(() => {
		if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
			setSelectedAirport(selectedOrigin)
		} else {
			setSelectedAirport(selectedDestination)
		}
	}, [selectedOrigin, selectedDestination])

	useEffect(() => {
		if (!text) {
			getFilteredAirports({ query: text })
		}
	}, [text])

	useEffect(() => {
		if ((selectedAirport || !(text && text.length)) && autoCompleteRef) {
			autoCompleteRef.current.hideOverlay()
		}
	}, [selectedAirport, text])

	useEffect(() => {
		if (
			selectedOrigin &&
			selectedDestination &&
			selectedOrigin.code === selectedDestination.code
		) {
			const errorMsg = MESSAGES.SAME_ORG_DEST_ERROR
			if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
				handleSetError(FORM_FIELDS.ORIGIN, errorMsg)
			} else {
				handleSetError(FORM_FIELDS.DESTINATION, errorMsg)
			}
		}
		if (
			selectedOrigin &&
			selectedDestination &&
			selectedOrigin.code !== selectedDestination.code
		) {
			if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
				handleSetError(FORM_FIELDS.DESTINATION, null)
			} else {
				handleSetError(FORM_FIELDS.ORIGIN, null)
			}
		}
		if (
			AUTOCOMPLETE_TYPE.ORIGIN &&
			!selectedOrigin &&
			selectedDestination &&
			FORM_FIELDS.DESTINATION
		) {
			handleSetError(FORM_FIELDS.DESTINATION, null)
		}
		if (
			AUTOCOMPLETE_TYPE.DESTINATION &&
			!selectedDestination &&
			selectedOrigin &&
			FORM_FIELDS.ORIGIN
		) {
			handleSetError(FORM_FIELDS.ORIGIN, null)
		}
	}, [selectedAirport])

	const getFilteredAirports = (airports) => {
		const airportsByCodeStart =
			airports && airports.length && text && text.length
				? airports.filter((airport) => {
					return airport.code.toLowerCase().startsWith(text.toLowerCase())
				})
				: []
		const airportsByNameStart =
			airports && airports.length && text && text.length
				? airports.filter((airport) => {
					return ` ${airport.name.toLowerCase()}`.includes(
						` ${text.toLowerCase()}`
					)
				})
				: []
		const airportsByContains =
			airports && airports.length && text && text.length
				? airports.filter((airport) => {
					return (
						airport.code.toLowerCase().includes(text.toLowerCase()) ||
						airport.name.toLowerCase().includes(text.toLowerCase())
					)
				})
				: []
		return [
			...airportsByCodeStart,
			...airportsByNameStart,
			...airportsByContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.code === item.code)
		)
	}

	const itemTemplate = (item) => {
		//return custom element
		return (
			<div>
				{`${item.name},`}
				<b>{` ${item.code}`}</b>
			</div>
		)
	}

	const searchAirport = async (e) => {
		const selectedAirport = e.query
		if (!selectedAirport || selectedAirport.length === 2) {
			setAirports([])
			setFilteredAirports([])
			return
		}

		if (selectedAirport && selectedAirport.length > 2) {
			let airports
			try {
				const responseBody = await getAirports(selectedAirport)
				airports = responseBody.map((airport) => {
					airport.name = capitalizeName(airport.name)
					return airport
				})
			} catch (err) {
				airports = []
				console.log(err)
				if (err && err.message) {
					setAlertMessage &&
						setAlertMessage('Airport could not retrive now :' + err.message)
					setNotificationMessage &&
						setNotificationMessage(
							'Airport could not retrive now :' + err.message
						)
					setNotificationType &&
						setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
					setStatus && setStatus(false)
				}
			}
			if (isFocused.current) {
				setAirports([...airports])
				setFilteredAirports(getFilteredAirports(airports))
			} else {
				setAirports([])
				setFilteredAirports([])
			}

			return
		}
		if (isFocused.current) {
			setFilteredAirports(getFilteredAirports(airports))
		}
	}

	const handleOnBlur = async () => {
		let airportByCode
		setTimeout(() => {
			setFilteredAirports([])
		}, 500)
		if (text && text.length === 3) {
			try {
				airportByCode = await getAirportsByCode(text)
				if (airportByCode && airportByCode.code) {
					autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN
						? setSelectedOrigin(airportByCode)
						: setSelectedDestination(airportByCode)
					setText(null)
					draftCallback()
					return
				}
			} catch (err) {
				console.log(err)
			}
		}
		setText(null)
		if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
			if (!selectedOrigin && !airportByCode) {
				handleSetError(FORM_FIELDS.ORIGIN, MESSAGES.INVALID_ORIGIN)

			}
		} else {
			if (!selectedDestination && !airportByCode) {
				handleSetError(FORM_FIELDS.DESTINATION, MESSAGES.INVALID_DESTINATION)

			}
		}
	}

	const setFieldLevelError = (e) => {
		if (e.target.value === "") {
			if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
				handleSetError(FORM_FIELDS.ORIGIN, MESSAGES.INVALID_ORIGIN)
			} else {
				handleSetError(FORM_FIELDS.DESTINATION, MESSAGES.INVALID_DESTINATION)
			}
		}
	}

	return (
		<>
			<AutoComplete
				id={autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN ?
					FORM_FIELDS.ORIGIN : FORM_FIELDS.DESTINATION}
				name={autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN ?
					FORM_FIELDS.ORIGIN : FORM_FIELDS.DESTINATION}
				ref={autoCompleteRef}
				value={
					selectedAirport && selectedAirport.code
						? `${selectedAirport.name}, ${selectedAirport.code}`
						: text
				}
				suggestions={filteredAirports}
				AutoComplete='false'
				completeMethod={searchAirport}
				onChange={(e) => {
					autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN
						? setSelectedOrigin(null)
						: setSelectedDestination(null)
					setText(e.value)
				}}
				onSelect={(e) => {
					handleSelect(e.value)
				}}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onFocus={(e) => {
					if (autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN) {
						isFocused.current = true
						clearError(FORM_FIELDS.ORIGIN)
					} else {
						isFocused.current = true
						clearError(FORM_FIELDS.DESTINATION)
					}
				}}
				onBlur={(e) => {
					setFieldLevelError(e)
					isFocused.current = false
					if (e.relatedTarget !== null) {
						handleOnBlur()
					}
					if(setMultiComponentError && componentIndex !== undefined){
						setMultiComponentError([FORM_FIELDS.DESTINATION],e.target.value,componentIndex)
					}
				}}
				className={`airport-autocomplete ${screen === 'MANAGE_BOOKING'
						? 'form-item__field autocomplete-inner-wrapper'
						: 'form-control autocomplete'
					}`}
				// className={'form-control autocomplete'}
				placeholder={
					autoCompleteType === AUTOCOMPLETE_TYPE.ORIGIN
						? 'Origin'
						: 'Destination'
				}
				itemTemplate={itemTemplate}
			/>
			{/* Below component is for Suggest Similar Booking component */}
			{autoCompleteRef &&
				autoCompleteRef.current &&
				displaySimilarBooking &&
				autoCompleteType === AUTOCOMPLETE_TYPE.DESTINATION ? (
				<SimilarBookingSuggestion
					origin={selectedOrigin}
					filteredAirports={[...filteredAirports]}
					setNotLeave={setNotLeave}
					autoCompleteRef={autoCompleteRef.current}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default AirportAutocomplete
