/* eslint-disable import/prefer-default-export */
export const SHOW_HEADER = true
export const ALLOWABLE_BOOKING_DATE = 14
export const DISPLAY_DATE_RANGE = 5
export const SIMILAR_BOOKING_SUGGESTIONS_NUMBER = 5
export const SIMILAR_BOOKING_SUGGESTIONS_PERIOD_DAYS = 14
export const LINKEDIN_URL = 'https://www.linkedin.com/showcase/etihad-cargo/'
export const ICP_INFO = {
    text: 'Shanghai, ICP prepared No. 14027180',
    link: 'https://beian.miit.gov.cn/',
}

