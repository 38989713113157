import { SEASONAL_BOOKING } from '../constants'

const initState = {
	request: null,
	response: null,
}

export default (state = initState, action) => {
	switch (action.type) {
		case SEASONAL_BOOKING.LOAD:
			return {
				...state,
				request: action.payload,
			}
        case SEASONAL_BOOKING.SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
		default:
			return state
	}
}
