import { takeEvery, call, put, select } from 'redux-saga/effects'
import { FLIGHT_ENQUIRY } from '../constants'
import {
	flightEnquirySuccess,
	flightEnquiryError,
} from '../actions/flightEnquiryActions'
import { ERROR_MESSAGE } from '../../constants'
import { getFlightEnquiryResponse } from '../../services/flightEnquiryServices'
function* getFlightEnquirySaga({ formData }) {
	try {
		const response = yield call(getFlightEnquiryResponse, formData)
		yield put(flightEnquirySuccess(response))
	} catch (error) {
		console.log(error.message)
		const errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(flightEnquiryError(errorObj))
	}
}

function* watchFlightEnquirySaga() {
	yield takeEvery(FLIGHT_ENQUIRY.LOAD, getFlightEnquirySaga)
}

export default {
	getFlightEnquirySaga,
	watchFlightEnquirySaga,
}
