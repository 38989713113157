import { USER_PROFILE } from '../constants'

export const loadUserProfile = (userCode) => ({
	type: USER_PROFILE.LOAD,
	userCode,
})

export const userProfileSuccess = (response) => ({
	type: USER_PROFILE.SUCCESS,
	response,
})

export const userProfileError = (error) => ({
	type: USER_PROFILE.ERROR,
	error,
})
