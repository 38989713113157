import { TOAST } from '../constants'

const initialState = {
	toastDetails: null,
}

export default function (state = initialState, action = {}) {
	switch (action.type) {
		case TOAST.SUCCESS_TOAST:
			return {
				...state,
				toastDetails: {
					severity: 'success',
					summary: 'Success',
					detail: action.message,
				},
			}
		case TOAST.FAILURE_TOAST:
			return {
				...state,
				toastDetails: {
					severity: 'error',
					summary: 'Error',
					detail: action.message,
				},
			}
		case TOAST.INFO_TOAST:
			return {
				...state,
				toastDetails: {
					severity: 'info',
					summary: 'Info',
					detail: action.message,
				},
			}
		default:
			return state
	}
}
