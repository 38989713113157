import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useEffect } from 'react'
import trashIcon from '../../../../assets/images/trash.svg'
import { Message } from 'primereact/message'
import {
	DETAILS_APPLY_FOR,
	DIMENSION_UNIT,
	DIMENSION_UNIT_SELECT_ITEMS,
	FORM_FIELDS,
	LOOSE_FORM_FIELDS,
	WEIGHT_UNIT_SELECT_ITEMS_WO_TON,
	getVolumeUnit,
	getDecimalFormattedNumber,
} from '../../../../constants'
import calculateKgWeight from '../../../../utils/calculateKgWeight'
import { validateLineItemWeights, validateLooseDetails } from '../../../SearchPanel/formUtils'
import './item-form.scss'
import MESSAGES from '../../../../constants/Messages'
import {
	calculateWeightToKg,
	round,
	allowNumberOnly,
	notZero,
} from '../../../../utils/common'
import { useTranslation } from 'react-i18next'
import ExcelReader from '../../../LooseLineItem/LooseUpload'

const LooseItemForm = ({
	isAllStackable,
	setAllStackable,
	isAllTiltable,
	setAllTiltable,
	handleAddLineItem,
	error,
	setError,
	setCalculatedTotalWeight,
	setActualTotalIneItemWeight,
	awbDetails,
	setAwbDetails,
	totalWeightInKilo,
	handleWeightChange,
}) => {
	//FOR MULTILINGUAL
	const { t, i18n } = useTranslation(['labels', 'errors'])

	let looseDetails = awbDetails.looseDetails
	const setLooseDetails = (looseDetailsUp) => {
		setAwbDetails((prevState) => ({
			...prevState,
			looseDetails: looseDetailsUp,
		}))
	}

	useEffect(() => {
		let chargeableWeight = 0
		let volumetricWeight = 0
		if (awbDetails.totalVolumeInMtrs) {
			//volumetricWeight = round(awbDetails.totalVolumeinMtrs, 2) * 166.6667
			/*volumetricWeight =
				Number(awbDetails.totalVolumeInMtrs).toFixed(2) * 166.6667*/
		}
		if (totalWeightInKilo || volumetricWeight) {
			chargeableWeight = Math.max(totalWeightInKilo * 1, volumetricWeight * 1)
		}
		//setChargeableWeight(chargeableWeight)
		if (awbDetails.actualChargeableWeight < chargeableWeight) {
			setAwbDetails((prevState) => ({
			...prevState,
				chargeableWeight: chargeableWeight,
			}))
		}
	}, [awbDetails.totalVolumeinMtrs, totalWeightInKilo, looseDetails])

	/*commented because iCargo is not returning stackable flag*/
	/*useEffect(() => {
		if (isAllStackable) {
			setLooseDetails(
				looseDetails.map((item) => ({ ...item, stackable: true }))
			)
		} else {
			setLooseDetails(
				looseDetails.map((item) => ({ ...item, stackable: false }))
			)
		}
	}, [isAllStackable])*/

	useEffect(() => {
		handleSummaryChange(looseDetails)
		handleWeightInput(looseDetails)
	}, [
		looseDetails,
		awbDetails.weightUnit,
		awbDetails.dimensionUnit,
	])

	useEffect(() => {
		handleSummaryChange(looseDetails)
	}, [
		awbDetails.totalWeight,
	])
	useEffect(() => {
		if (awbDetails.totalWeight) {
			//setActualGrossWeight(totalWeight)
			setAwbDetails((prevState) => ({
				...prevState,
				actualGrossWeight: awbDetails.totalWeight,
			}))
			const totalWeightinKg = calculateWeightToKg(
				awbDetails.totalWeight,
				awbDetails.weightUnit
			)
			//setTotalWeightInKg(totalWeightinKg)
			setAwbDetails((prevState) => ({
				...prevState,
				totalWeightInKg: totalWeightinKg,
			}))
		} else {
			//setActualGrossWeight(calculatedTotalWeight)
			setAwbDetails((prevState) => ({
				...prevState,
				actualGrossWeight: awbDetails.calculatedTotalWeight,
			}))

			const calculatedTotalWeightInKg = calculateWeightToKg(
				awbDetails.calculatedTotalWeight,
				awbDetails.weightUnit
			)
			//setLooseItemsGrossWeight(calculatedTotalWeightInKg)
			// /setTotalWeightInKg(calculatedTotalWeightInKg)
			setAwbDetails((prevState) => ({
				...prevState,
				totalWeightInKg: calculatedTotalWeightInKg,
			}))
		}
	}, [
		awbDetails.totalWeight,
		awbDetails.calculatedTotalWeight,
		awbDetails.weightUnit,
	])

	const handleItemChange = (key, value, index) => {
		const templooseDetails = JSON.parse(JSON.stringify(looseDetails))
		if (key === 'stackable' || key === 'turnable') {
			templooseDetails[index][key] = !value
		} else {
			if (
				key === 'length' ||
				key === 'width' ||
				key === 'height' ||
				key === 'pieces'
			) {
				if (value.length <= 5) {
					templooseDetails[index][key] = value
				}
			} else if (key === 'weight') {
				if (value.length <= 9) {
					templooseDetails[index][key] = value
				}
			} else {
				templooseDetails[index][key] = value
			}
		}
		setLooseDetails([...templooseDetails])
		if (key === 'weightType') {
			handleWeightInput(templooseDetails)
		}
	}

	const handleWeightInput = (LooseDetails) => {
		const calculatedTotalWeight = [...LooseDetails, {}].reduce((curr, next) => {
			return {
				weight:
					(curr.weightType === DETAILS_APPLY_FOR.PER_ITEM
						? (curr.pieces ? curr.pieces * 1 : 0) *
						(curr.weight ? curr.weight * 1 : 0)
						: curr.weight
							? curr.weight * 1
							: 0) +
					(next.weightType === DETAILS_APPLY_FOR.PER_ITEM
						? (next.pieces ? next.pieces * 1 : 0) *
						(next.weight ? next.weight * 1 : 0)
						: next.weight
							? next.weight * 1
							: 0),
				pieces: 1,
			}
		}).weight
		setCalculatedTotalWeight(calculatedTotalWeight)
		setActualTotalIneItemWeight(calculatedTotalWeight)

		if (!awbDetails.totalWeight || awbDetails.totalWeight === 0 || awbDetails.totalWeight === '0') {

			setAwbDetails((prevState) => ({
				...prevState,
				totalWeight: calculatedTotalWeight,
			}))
		}
	}

	const handleLineItemWeightRemoval = () => {
		let looseDetailsErrors =
			error && error[FORM_FIELDS.LOOSE_ITEMS]
				? error[FORM_FIELDS.LOOSE_ITEMS]
				: []
		looseDetailsErrors = validateLineItemWeights(
			looseDetails,
			looseDetailsErrors
		)
		setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
	}

	const handleSummaryChange = (LooseDetails) => {
		let quantity = 0
		let totalVolumeinMeters = 0
		let actualVolumeInSelectedUnit = 0
		LooseDetails.forEach(function (element) {
			if (element.pieces) {
				quantity += element.pieces * 1
			}
			if (element.pieces && element.length && element.width && element.height) {
				actualVolumeInSelectedUnit +=
					parseFloat(element.width * element.length * element.height) *
					parseInt(element.pieces)
			}
			if (element.pieces && element.length && element.width && element.height) {
				if (awbDetails.dimensionUnit === DIMENSION_UNIT.INCHES) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.0254 *
							(element.length * 0.0254) *
							(element.height * 0.0254)
						) * parseInt(element.pieces)
				}
				if (awbDetails.dimensionUnit === DIMENSION_UNIT.CENTIMETERS) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.01 *
							(element.length * 0.01) *
							(element.height * 0.01)
						) * parseInt(element.pieces)
				}
			}
		})
		if (totalVolumeinMeters && totalVolumeinMeters < 0.01) {
			totalVolumeinMeters = 0.01
		}
		if (actualVolumeInSelectedUnit && actualVolumeInSelectedUnit < 0.01) {
			actualVolumeInSelectedUnit = 0.01
		}
		//setActualVolume(actualVolumeInSelectedUnit)
		//setTotalVolumeInMeters(totalVolumeinMeters)
		//setTotalPieces(quantity)
		setAwbDetails((prevState) => ({
			...prevState,
			actualVolume: actualVolumeInSelectedUnit,
		}))

		let volumeCBM = getDecimalFormattedNumber(totalVolumeinMeters)
		setAwbDetails((prevState) => ({
			...prevState,
			displayVolume: volumeCBM,
		}))

		setAwbDetails((prevState) => ({
			...prevState,
			totalVolumeInMtrs: totalVolumeinMeters,
		}))
		setAwbDetails((prevState) => ({
			...prevState,
			totalPieces: quantity,
		}))
	}
	const handleRemoveItem = (index) => {
		const looseDetailsTemp = looseDetails.filter((itemDetail, curr) => {
			return index !== curr
		})
		setLooseDetails(looseDetailsTemp)
		handleWeightInput(looseDetailsTemp)
	}

	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}
	const clearItemError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.LOOSE_ITEMS] &&
			tempError[FORM_FIELDS.LOOSE_ITEMS][index]
		) {
			tempError[FORM_FIELDS.LOOSE_ITEMS][index][key] = null
			setError(tempError)
		}
	}

	const handleClickAdd = (e) => {
		handleAddLineItem(e)
	}
	const isNum = (val) => /^\d+$/.test(val)

	const validateLineItemOnBlur = (type, key) => {
		let looseDetailsErrors =
			error && error[FORM_FIELDS.LOOSE_ITEMS]
				? error[FORM_FIELDS.LOOSE_ITEMS]
				: []
		if (type === 'pieces') {
			const errorMsg =
				looseDetails[key].pieces &&
					isNum(looseDetails[key].pieces) &&
					notZero(looseDetails[key].pieces)
					? null
					: MESSAGES.INVALID_PIECES
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.PIECES]: errorMsg }
					: { [LOOSE_FORM_FIELDS.PIECES]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
		if (type === 'width') {
			const errorMsg =
				looseDetails[key].width && isNum(looseDetails[key].width)
					? null
					: MESSAGES.INVALID_WIDTH
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.WIDTH]: errorMsg }
					: { [LOOSE_FORM_FIELDS.WIDTH]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
		if (type === 'height') {
			const errorMsg =
				looseDetails[key].height && isNum(looseDetails[key].height)
					? null
					: MESSAGES.INVALID_HEIGHT
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.HEIGHT]: errorMsg }
					: { [LOOSE_FORM_FIELDS.HEIGHT]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
		if (type === 'length') {
			const errorMsg =
				looseDetails[key].length && isNum(looseDetails[key].length)
					? null
					: MESSAGES.INVALID_LENGTH
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.LENGTH]: errorMsg }
					: { [LOOSE_FORM_FIELDS.LENGTH]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
	}

	const checkIsEmpty = () => {
		let isLooseEmpty = true
		looseDetails?.filter((item) => {
			if (
				item.pieces !== '' ||
				item.length !== '' ||
				item.height !== '' ||
				item.weight != '' ||
				item.width != '' ||
				item.weightType !== 'PER_ITEM' 
			) {
				isLooseEmpty = false
			}
		})
		return isLooseEmpty;
	}

	const onReadExcel = (val, weightUnit, dimensionUnit) => {

		//============== for bulk dimension & weight unit ===============//
		if (dimensionUnit === 'cm') {
			setAwbDetails((prevState) => ({
				...prevState,
				dimensionUnit: 'C',
				//volumeUnit: 'C',
			}))
		} else if (dimensionUnit === 'inch') {
			setAwbDetails((prevState) => ({
				...prevState,
				dimensionUnit: 'I',
				//volumeUnit: 'I',
			}))
		} else return

		if (weightUnit === 'kg') {
			setAwbDetails((prevState) => ({
				...prevState,
				weightUnit: 'K',
			}))
		} else if (weightUnit === 'lb') {
			setAwbDetails((prevState) => ({
				...prevState,
				weightUnit: 'L',
			}))
		} else if (weightUnit === 't') {
			setAwbDetails((prevState) => ({
				...prevState,
				weightUnit: 'T',
			}))
		} else return
		//=================================================//
		let tempLooseDetails = []
		if (checkIsEmpty()) {
			tempLooseDetails = val
			setLooseDetails(val)
		} else {
			tempLooseDetails = tempLooseDetails.concat(val)
			setLooseDetails(tempLooseDetails)
		}
		let looseDetailsErrors = validateLooseDetails(tempLooseDetails)
		const hasError = looseDetailsErrors.some((item) => (item ? true : false))
		error[FORM_FIELDS.LOOSE_ITEMS] = hasError ? looseDetailsErrors : null
	}

	return (
		<>
			<div className='loose-form capture-awb-loose-form'>
				<div className='form-row m-b-0'>
					<div className={`form-group col-9 col-lg-3`}>
						<label className='form-item__label'>{t('COMMON.TOTAL_WT')}</label>
						<div
							className={`search-panel__double_element weight-div${error && error[FORM_FIELDS.TOTAL_WEIGHT] ? 'red-border' : ''
								}`}
						>
							<input
								type='text'
								className='form-control borderRadius-R0 form-item__field  loose-weight'
								value={awbDetails.totalWeight}
								onKeyDown={(evt) => allowNumberOnly(evt)}
								onChange={(e) => {
									if (e.target.value.length <= 9 &&
										(
											!e.target.value.split('.')[1] ||
											!e.target.value.split('.')[1].length ||
											e.target.value.split('.')[1].length < 3
										) &&
										(e.target.value.split('.').length <= 2)
									) {
										const value = e.target.value.replace(/[^0-9.]/g, '');
										setAwbDetails((prevState) => ({
											...prevState,
											totalWeight: value,
										}))
									}
								}}
								// 	if (e.target.value.length <= 9) {
								// 		//setTotalWeight(e.target.value)
								// 		setAwbDetails((prevState) => ({
								// 			...prevState,
								// 			totalWeight: e.target.value,
								// 		}))
								// 	}
								// }}
								onFocus={(e) => clearError(FORM_FIELDS.TOTAL_WEIGHT)}
								onBlur={(e) => handleSummaryChange(looseDetails)}
							/>
							<div className='form-group borderRadius-L0 border-left0 dropdown_div m-b-0'>
								<Dropdown
									className='form-control cust-dropdown'
									value={awbDetails.weightUnit}
									options={WEIGHT_UNIT_SELECT_ITEMS_WO_TON}
									onChange={(e) => {
										setAwbDetails((prevState) => ({
											...prevState,
											weightUnit: e.target.value,
										}))
									}}
								/>
							</div>
						</div>
						<span className='search-panel__errormsg'>
							{error && error[FORM_FIELDS.TOTAL_WEIGHT]
								? t(error[FORM_FIELDS.TOTAL_WEIGHT], { ns: 'errors' })
								: ''}
						</span>
					</div>
					{/*<div className='form-group col-12 col-md-4'>
						<label className='form-item__label'>All pieces are</label>
						<div className='form-control search-panel__type grey-div'>
							<div className='l-checkbox'>
								<input
									id={`stackable`}
									type='checkbox'
									checked={isAllStackable}
									onChange={(e) => setAllStackable(e.target.checked)}
								/>
								<label className='mb-0 form-item__label' htmlFor={`stackable`}>
									Stackable
								</label>
							</div>
							<div className='l-checkbox'>
								<input
									id={`turnable`}
									type='checkbox'
									checked={isAllTiltable}
									onChange={(e) => setAllTiltable(e.target.checked)}
								/>
								<label className='form-item__label mb-0' htmlFor={`turnable`}>
									Tiltable
								</label>
							</div>
						</div>
					</div>*/}
				</div>
				{looseDetails &&
					looseDetails.length &&
					looseDetails.map((item, key) => {
						const piecesError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.PIECES]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.PIECES]
								: null
						const lengthError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.LENGTH]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.LENGTH]
								: null
						const widthError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WIDTH]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WIDTH]
								: null
						const heightError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.HEIGHT]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.HEIGHT]
								: null
						const weightError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WEIGHT]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WEIGHT]
								: null
						return (
							<div
								className={`item-group search-panel__data ${looseDetails.length - 1 === key ? 'last-item' : ''
									}`}
								key={key}
							>
								<div className='form-row m-b-20'>
									<div
										className={`form-group pieces-div m-r-10 ${piecesError ? 'red-border' : ''
											}`}
									>
										<label className='form-item__label'>
											{t('COMMON.PCS')}
										</label>
										<input
											type='number'
											className='form-control form-item__field'
											value={item.pieces}
											id={`quantity-${key}`}
											onChange={(e) =>
												handleItemChange('pieces', e.target.value, key)
											}
											onFocus={(e) =>
												clearItemError(LOOSE_FORM_FIELDS.PIECES, key)
											}
											onBlur={(e) => {
												validateLineItemOnBlur('pieces', key)
												handleWeightInput(looseDetails)
												handleWeightChange();
											}}
										/>
										<span className='search-panel__errormsg'>
											{piecesError ? t(piecesError, { ns: 'errors' }) : ''}
										</span>
									</div>
									<div
										className={`form-group col-6 col-lg-1 ${lengthError ? 'red-border' : ''
											}`}
									>
										<label className='form-item__label'>{t('COMMON.LN')}</label>
										<input
											type='number'
											className='form-control form-item__field'
											value={item.length}
											id={`length-${key}`}
											onChange={(e) =>
												handleItemChange('length', e.target.value, key)
											}
											onFocus={(e) =>
												clearItemError(LOOSE_FORM_FIELDS.LENGTH, key)
											}
											onBlur={(e) => {
												validateLineItemOnBlur('length', key)
												handleSummaryChange(looseDetails)
												handleWeightChange();
											}}
										/>
										<span className='search-panel__errormsg'>
											{lengthError ? t(lengthError, { ns: 'errors' }) : ''}
										</span>
									</div>

									<div
										className={`form-group col-6 col-lg-1 ${widthError ? 'red-border' : ''
											}`}
									>
										<label className='form-item__label'>{t('COMMON.WD')}</label>
										<input
											type='number'
											className='form-control form-item__field'
											id={`width-${key}`}
											value={item.width}
											onChange={(e) =>
												handleItemChange('width', e.target.value, key)
											}
											onFocus={(e) =>
												clearItemError(LOOSE_FORM_FIELDS.WIDTH, key)
											}
											onBlur={(e) => {
												validateLineItemOnBlur('width', key)
												handleSummaryChange(looseDetails)
												handleWeightChange();
											}}
										/>
										<span className='search-panel__errormsg'>
											{widthError ? t(widthError, { ns: 'errors' }) : ''}
										</span>
									</div>
									<div
										className={`form-group col-6 col-lg-1 ${heightError ? 'red-border' : ''
											}`}
									>
										<label className='form-item__label'>{t('COMMON.HT')}</label>
										<input
											type='number'
											className='form-control form-item__field'
											id={`height-${key}`}
											value={item.height}
											onChange={(e) =>
												handleItemChange('height', e.target.value, key)
											}
											onFocus={(e) =>
												clearItemError(LOOSE_FORM_FIELDS.HEIGHT, key)
											}
											onBlur={(e) => {
												validateLineItemOnBlur('height', key)
												handleSummaryChange(looseDetails)
												handleWeightChange();
											}}
										/>
										<span className='search-panel__errormsg'>
											{heightError ? t(heightError, { ns: 'errors' }) : ''}
										</span>
									</div>
									<div className='form-group col-6 col-lg-1 mr-0 m-t-1'>
										<label>&nbsp;</label>
										<Dropdown
											value={awbDetails.dimensionUnit}
											options={DIMENSION_UNIT_SELECT_ITEMS}
											//onChange={(e) => setDimensionUnit(e.target.value)}
											onChange={(e) => {
												setAwbDetails((prevState) => ({
													...prevState,
													dimensionUnit: e.target.value,
													//volumeUnit: e.target.value,
												}))
											}}
											className={'form-control'}
											disabled={key ? true : false}
											dataKey={item.dimensionUnit}
										/>
									</div>
									<div
										className={`form-group col-6 col-lg-2 ml-md-2 ml-0${weightError ? 'red-border' : ''
											}`}
									>
										<label className='form-item__label'>
											{t('COMMON.WT')}{' '}
										</label>
										<div className='search-panel__double_element'>

											<input
												type='text'
												className='form-control borderRadius-R0 form-item__field'
												value={item.weight}
												onKeyDown={(evt) => allowNumberOnly(evt)}
												onChange={(e) => {
													if (e.target.value.length <= 9 && (
														!e.target.value.split('.')[1] ||
														!e.target.value.split('.')[1].length ||
														e.target.value.split('.')[1].length < 3
													) &&
														(e.target.value.split('.').length <= 2)
													) {
														const value = e.target.value.replace(/[^0-9.]/g, '')
														handleItemChange('weight', value, key)
													}
												}
												}
												onFocus={(e) =>
													clearItemError(LOOSE_FORM_FIELDS.WEIGHT, key)
												}
												onBlur={(e) => {
													handleWeightInput(looseDetails)
													if (
														(!e.target.value || !e.target.value.length) &&
														!looseDetails.some((detail) => detail.weight)
													) {
														handleLineItemWeightRemoval()
													}
												}}
											/>
											<div className='m-r-10 borderRadius-L0 border-left0 dropdown_div'>
												<Dropdown
													className='form-control cust-dropdown'
													value={awbDetails.weightUnit}
													options={WEIGHT_UNIT_SELECT_ITEMS_WO_TON}
													onChange={(e) => {
														setAwbDetails((prevState) => ({
															...prevState,
															weightUnit: e.target.value,
														}))
													}}
													disabled={key ? true : false}
												/>
											</div>
										</div>
										<span className='search-panel__errormsg'>
											{weightError ? t(weightError, { ns: 'errors' }) : ''}
										</span>
									</div>

									<div className='form-group col-12 col-lg-1 no-wrap-type'>
										<label className='form-item__label'>
											{t('COMMON.WT_TYPE')}
										</label>
										<div className='d-flex d-lg-block form-control border-0 p-l-0 p-t-0'>
											<div className='flex-fill l-radio'>
												<input
													type='radio'
													id={`perItem-${key}`}
													name='radio'
													value={DETAILS_APPLY_FOR.PER_ITEM}
													checked={
														item.weightType === DETAILS_APPLY_FOR.PER_ITEM
													}
													onChange={(e) => {
														handleItemChange('weightType', e.target.value, key)
													}}
												/>
												<label
													id='loose-per-item-weight'
													className={`mb-0 form-item__label font-label-20 ${item.weightType === DETAILS_APPLY_FOR.PER_ITEM
														? 'checked'
														: ''
														}`}
													htmlFor={`perItem-${key}`}
												>
													{t('COMMON.PER_ITEM')}
												</label>
											</div>
											<div className='flex-fill l-radio'>
												<input
													type='radio'
													id={`total-${key}`}
													name='radio'
													value={DETAILS_APPLY_FOR.TOTAL}
													checked={item.weightType === DETAILS_APPLY_FOR.TOTAL}
													onChange={(e) => {
														handleItemChange('weightType', e.target.value, key)
														handleWeightInput(looseDetails)
														handleSummaryChange(looseDetails)
													}}
												/>
												<label
													className={`mb-0 form-item__label font-label-20 ${item.weightType === DETAILS_APPLY_FOR.TOTAL
														? 'checked'
														: ''
														}`}
													htmlFor={`total-${key}`}
												>
													{t('COMMON.TOTAL')}
												</label>
											</div>
										</div>
									</div>
									<div className='form-group col-12 col-lg-2'>
										<label className='m-b-0 form-item__label'>
											{t('COMMON.PCKG_TYPE')}
										</label>
										<div className='form-control border-0 p-l-0'>
											{/* <div className='l-checkbox'>
												<input
													id={`stackable-${key}`}
													type='checkbox'
													disabled={isAllStackable}
													checked={!item.stackable}
													onChange={(e) =>
														handleItemChange('stackable', e.target.checked, key)
													}
												/>
												<label
													className='mb-0 form-item__label'
													htmlFor={`stackable-${key}`}
												>
													Non-stackable
												</label>
												</div> */}
											<div className='l-checkbox'>
												<input
													id={`turnable-${key}`}
													type='checkbox'
													disabled={isAllTiltable}
													checked={!item.turnable}
													onChange={(e) =>
														handleItemChange('turnable', e.target.checked, key)
													}
												/>
												<label
													className='mb-0 form-item__label font-label-20'
													htmlFor={`turnable-${key}`}
												>
													{t('COMMON.NON_TILTABLE')}
												</label>
											</div>
										</div>
									</div>
									{looseDetails.length > 1 ? (
										<div class='delete-section'>
											<img
												onClick={(e) => handleRemoveItem(key)}
												src={trashIcon}
												alt='Trash'
												title='Trash'
											/>
											<span>{t('COMMON.DELETE')}</span>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						)
					})}
				<div className='form-row'>
					<div
						className='col-12 col-lg-3 m-b-0'
					>
						<Button
							label={t('COMMON.ADD_ANOTHER')}
							icon='pi pi-plus'
							className='button__another'
							type='button'
							onClick={(e) => handleClickAdd(e)}
						/>
					</div>
					<div className='col-lg-2 col-12 mt-2'>
						<ExcelReader
							onRead={onReadExcel}
							pageName='CAPTURE'
							 />
					</div>
				</div>
				{awbDetails.totalWeight &&
					awbDetails.looseDetails.find((detail) => detail.weight) ? (
					<div className='form-row col-12 mt-3 mb-0 warning-error'>
						<Message
							severity='info'
							text={t(MESSAGES.PIECE_LEVEL_WT_DISREGARD, { ns: 'errors' })}
						></Message>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	)
}

export default LooseItemForm
