import React, { useEffect, useState } from 'react'
import {
	PAYMENT_TYPES_SELECT_ITEMS,
	CHARGE_ENTITLEMENT_PARTY_CODES,
	RATE_CLASS_CODE_SELECT_ITEMS,
	getOneDecimalFormattedNumber,
} from '../../../constants'
import { Dropdown } from '../../../common/DropDown'
import {
	validateAlphaRequired,
	hasError,
	removeError,
	validateDecimalRequired,
	validateRequired,
} from '../captureAwbUtils'
import plusIcon from '../../../assets/images/plus.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import ChargeHeadDropDown from './SubSections/ChargeHeadDropDown'
import { getChargeHeads } from '../../../services/dataCaptureService'
import { useTranslation } from 'react-i18next'
const RatesDetails = ({
	awbDetails,
	setAwbDetails,
	error,
	setError,
	ammendRate,
	setAmmendRate,
	initialLoading,
	setInitialLoading,
}) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	let { awbRateLineDetails, currency, chargeableWeightInSelectedUnit } =
		awbDetails
	const [chargeHeads, setChargeHeads] = useState(null)
	const [filteredChargeHeads, setFilteredChargeHeads] = useState([])
	const [netChargeCalculated, setNetChargeCalculated] = useState(null)

	useEffect(() => {
		;(async () => {
			try {
				let chargeHeads = await getChargeHeads()
				if (chargeHeads && chargeHeads.length) {
					setChargeHeads(chargeHeads)
					setFilteredChargeHeads(chargeHeads)
				}
			} catch (err) {
				console.log(err)
			}
		})()
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setInitialLoading(false)
		}, 2000)

		if (
			!initialLoading &&
			awbRateLineDetails &&
			chargeableWeightInSelectedUnit
		) {
			let calculatedNetCharge = chargeableWeightInSelectedUnit * awbRateLineDetails.rate
			setError((prevState) => ({ ...prevState, 'netCharge': '' }))
			awbRateLineDetails.netCharge = parseFloat(calculatedNetCharge).toFixed(2)
			awbRateLineDetails.freightCharge =  parseFloat(calculatedNetCharge).toFixed(2)
			setAwbDetails((prevState) => ({
				...prevState,
				awbRateLineDetails: awbRateLineDetails,
			}))
		}
	}, [chargeableWeightInSelectedUnit, awbRateLineDetails.rate])

	const handleRateAmmend = () => {
		setAmmendRate(!ammendRate)
	}

	const handleAddSurcharge = () => {
		const surcharge = {
			otherChargeCode: '',
			prepaidCollectIndicator: '',
			chargeEntitlementCodeParty: '',
			chargeAmount: null,
			chargeHeadName: '',
			isNew: true,
		}
		let otherCharges = awbRateLineDetails.otherCharges
		if (!awbRateLineDetails.otherCharges) {
			otherCharges = []
		}
		otherCharges.push(surcharge)
		awbRateLineDetails.otherCharges = otherCharges
		setAwbDetails((prevState) => ({
			...prevState,
			awbRateLineDetails: awbRateLineDetails,
		}))
	}

	const handleDeleteAction = (e, index) => {
		let otherCharges = awbRateLineDetails.otherCharges
		const otherChargesTemp = otherCharges.filter((itemDetail, curr) => {
			return index !== curr
		})
		awbRateLineDetails.otherCharges = otherChargesTemp
		setAwbDetails((prevState) => ({
			...prevState,
			awbRateLineDetails: awbRateLineDetails,
		}))
	}

	return (
		<div className='form-row rate-details'>
			<div className='col-12 col-lg-12'>
				<div className='form-row'>
					<div className='col-12'>
						<h4 className='section-heading'>{t('CAPTURE_AWB.RATES')}</h4>
					</div>
				</div>
				{!ammendRate && (
					<div className='rate-info'>
						<div className='form-row rate-detail'>
							<div className='col-6 col-lg-2'>
								<label className='form-item__label rate-label'>
								{t('CAPTURE_AWB.FRE_CHARGE')}:
								</label>
							</div>
							<div className='col-6 col-lg-2'>
								<span className='rate-value'>
									{awbDetails.currency} {awbRateLineDetails.freightCharge}
								</span>
							</div>
						</div>
						<div className='form-row rate-detail'>
							<div className='col-6 col-lg-2'>
								<label className='form-item__label rate-label'>
									{t('CAPTURE_AWB.OTHER_CHARGE')}:
								</label>
							</div>
							<div className='col-6 col-lg-2'>
								<span className='rate-value'>
									{awbDetails.currency} {awbRateLineDetails.surcharges}
								</span>
							</div>
						</div>
						<div className='form-row rate-detail'>
							<div className='col-6 col-lg-2'>
								<label className='form-item__label rate-label'>
									{t('CAPTURE_AWB.IATA_RATE')}:
								</label>
							</div>
							<div className='col-6 col-lg-2'>
								<span className='rate-value'>
									{awbDetails.currency} {awbRateLineDetails.rate}
								</span>
							</div>
						</div>
						<div className='form-row rate-detail total'>
							<div className='col-6 col-lg-2'>
								<label className='form-item__label rate-label'>
									{t('CAPTURE_AWB.TOT_CHARGE')}
								</label>
							</div>
							<div className='col-6 col-lg-2'>
								<span className='rate-value'>
									{awbDetails.currency} {awbRateLineDetails.totalCharge}
								</span>
							</div>
						</div>
					</div>
				)}
				{ammendRate && (
					<div className='rate-info-edit mt-2'>
						<div className='form-row mb-3 charges'>
							<div className='col-12 col-lg-8'>
								<div className='form-row'>
									<div
										className='col-6 col-lg-3 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>{`${t(
											'CAPTURE_AWB.RATES'
										)}(${currency})`}</label>
										<input
											id='rate'
											type='text'
											className='form-item__field'
											maxLength='20'
											value={awbRateLineDetails.rate}
											onChange={(e) => {
												awbRateLineDetails.rate = e.target.value
												setAwbDetails((prevState) => ({
													...prevState,
													awbRateLineDetails: awbRateLineDetails,
												}))
											}}
											onBlur={(e) => {
												validateDecimalRequired(
													e.target.value,
													setError,
													'rate',
													t('CAPTURE_AWB.RATES')
												)
											}}
											aria-invalid={hasError('rate', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['rate']
												? t(error['rate'], { ns: 'errors' })
												: ''}
										</span>
									</div>
									<div className='col-6 col-lg-3 form-item'>
										<Dropdown
											id='rateClass'
											value={awbRateLineDetails.rateClassCode}
											options={RATE_CLASS_CODE_SELECT_ITEMS}
											label={t('CAPTURE_AWB.RATE_CLASS')}
											onChange={(e) => {
												awbRateLineDetails.rateClassCode = e.target.value
												setAwbDetails((prevState) => ({
													...prevState,
													awbRateLineDetails: awbRateLineDetails,
												}))
											}}
											onBlur={(e) => {
												validateRequired(e.target.value, setError, `rateClass`, t('CAPTURE_AWB.RATE_CLASS'))
											}}
											ariaInvalid={hasError('rateClass', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										></Dropdown>
										<span className='form-item__error'>
											{error && error['rateClass']
												? t(error['rateClass'], { ns: 'errors' })
												: ''}
										</span>
									</div>
									<div
										className='col-6 col-lg-3 mt-md-2 mt-lg-0 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>{`${t(
											'CAPTURE_AWB.NET_CHARGE'
										)}(${currency})`}</label>
										<input
											id='netCharge'
											type='text'
											className='form-item__field'
											maxLength='20'
											value={awbRateLineDetails.netCharge}
											onChange={(e) => {
												awbRateLineDetails.netCharge = e.target.value
												setAwbDetails((prevState) => ({
													...prevState,
													awbRateLineDetails: awbRateLineDetails,
												}))
											}}
											onBlur={(e) => {
												validateDecimalRequired(
													e.target.value,
													setError,
													'netCharge',
													t('CAPTURE_AWB.NET_CHARGE')
												)
											}}
											aria-invalid={hasError('netCharge', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['netCharge']
												? t(error['netCharge'], { ns: 'errors' })
												: ''}
										</span>
									</div>
									<div className='col-6 col-lg-3 mt-md-2 mt-lg-0 form-item form-item--disabled'>
										<Dropdown
											id='paymentType'
											value={awbRateLineDetails.paymentType}
											options={PAYMENT_TYPES_SELECT_ITEMS}
											label={t('CAPTURE_AWB.PAY_TYPE')}
											disabled={true}
											onChange={(e) => {
												awbRateLineDetails.paymentType = e.target.value
												setAwbDetails((prevState) => ({
													...prevState,
													awbRateLineDetails: awbRateLineDetails,
												}))
												validateRequired(
													e.target.value,
													setError,
													'paymentType'
												)
											}}
											onBlur={(e) => {
												validateRequired(
													e.target.value,
													setError,
													'paymentType'
												)
											}}
											ariaInvalid={hasError('paymentType', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										></Dropdown>
										<span className='form-item__error'>
											{error && error['paymentType']
												? t(error['paymentType'], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className='form-row mb-3 other-charges'>
							<div className='col-12 col-lg-12'>
								<div className='form-row'>
									<div className='col-12 col-lg-8'>
										<h4 className='section-sub-heading pl-md-2 pl-0'>
											{t('CAPTURE_AWB.OTHER_CHARGE')}
										</h4>
									</div>
								</div>
								{awbRateLineDetails.otherCharges &&
								awbRateLineDetails.otherCharges.length ? (
									awbRateLineDetails.otherCharges.map((surcharge, index) => (
										<div className='form-row mb-3'>
											<div
												className={`col-6 col-lg-2 form-item form-item--text ${hasError(`subchargeCode${index}`, error) ? 'form-item--error' : ''}`}
												data-module='molecules/form-item/FormItem'
											>
												<label className='form-item__label'>
													{t('COMMON.CODE')}
												</label>
												<ChargeHeadDropDown
													setError={setError}
													chargeHeadId={`subchargeCode${index}`}
													chargeHeadNameId={`chargeHead${index}`}
													chargeHeads={chargeHeads}
													value={{
														chargeHeadCode: surcharge.otherChargeCode,
														chargeHeadDescription: surcharge.chargeHeadName,
													}}
													setChargeHeads={setChargeHeads}
													filteredChargeHeads={filteredChargeHeads}
													setFilteredChargeHeads={setFilteredChargeHeads}
													setChargeHeadCode={(val) => {
														let otherCharges = awbRateLineDetails.otherCharges
														otherCharges[index].otherChargeCode = val
															? val.chargeHeadCode
															: null
														otherCharges[index].chargeHeadName = val
															? val.chargeHeadDescription
															: ''
														awbRateLineDetails.otherCharges = otherCharges
														setAwbDetails((prevState) => ({
															...prevState,
															awbRateLineDetails: awbRateLineDetails,
														}))
														if (
															otherCharges[index].chargeHeadName &&
															otherCharges[index].chargeHeadName !== ''
														) {
															setError((prevState) => ({
																...prevState,
																[`chargeHead${index}`]: '',
															}))
														}
													}}
													isMandatory={true}
												/>
												{/*<input
													id={`subchargeCode${index}`}
													type='text'
													className='form-item__field'
													maxLength='10'
													value={surcharge.otherChargeCode}
													onChange={(e) => {
														let otherCharges = awbRateLineDetails.otherCharges
														otherCharges[index].otherChargeCode = e.target.value
														awbRateLineDetails.otherCharges = otherCharges
														setAwbDetails((prevState) => ({
															...prevState,
															awbRateLineDetails: awbRateLineDetails,
														}))
													}}
													onBlur={(e) => {
														validateAlphaRequired(
															e.target.value,
															setError,
															`subchargeCode${index}`
														)
													}}
													aria-invalid={hasError(
														`subchargeCode${index}`,
														error
													)}
													onFocus={(e) => {
														removeError(e, setError)
													}}
												/>*/}
												<span className='form-item__error'>
													{error && error[`subchargeCode${index}`]
														? t(error[`subchargeCode${index}`], {
																ns: 'errors',
														  })
														: ''}
												</span>
											</div>
											<div
												className='col-6 col-lg-4 form-item form-item--text form-item--disabled'
												data-module='molecules/form-item/FormItem'
											>
												<label className='form-item__label'>
													{t('COMMON.CHARGE_HEAD')}
												</label>
												<input
													id={`chargeHead${index}`}
													type='text'
													className='form-item__field'
													maxLength='20'
													value={surcharge.chargeHeadName}
													disabled={true}
													onChange={(e) => {
														let otherCharges = awbRateLineDetails.otherCharges
														otherCharges[index].chargeHeadName = e.target.value
														awbRateLineDetails.otherCharges = otherCharges
														setAwbDetails((prevState) => ({
															...prevState,
															awbRateLineDetails: awbRateLineDetails,
														}))
													}}
													onBlur={(e) => {
														validateRequired(
															e.target.value,
															setError,
															`chargeHead${index}`,
															t('COMMON.CHARGE_HEAD')
														)
													}}
													aria-invalid={hasError(`chargeHead${index}`, error)}
													onFocus={(e) => {
														removeError(e, setError)
													}}
												/>
												<span className='form-item__error'>
													{error && error[`chargeHead${index}`]
														? t(error[`chargeHead${index}`], { ns: 'errors' })
														: ''}
												</span>
											</div>
											<div
												className='col-6 col-lg-2 form-item form-item--text'
												data-module='molecules/form-item/FormItem'
											>
												<label className='form-item__label'>{`${t(
													'CAPTURE_AWB.CHARGE'
												)}(${currency})`}</label>
												<input
													id={`subCharge${index}`}
													type='text'
													className='form-item__field'
													maxLength='20'
													value={surcharge.chargeAmount}
													onChange={(e) => {
														let otherCharges = awbRateLineDetails.otherCharges
														otherCharges[index].chargeAmount = e.target.value
														awbRateLineDetails.otherCharges = otherCharges
														setAwbDetails((prevState) => ({
															...prevState,
															awbRateLineDetails: awbRateLineDetails,
														}))
													}}
													onBlur={(e) => {
														validateDecimalRequired(
															e.target.value,
															setError,
															`subCharge${index}`,
															t('CAPTURE_AWB.CHARGE')
														)
													}}
													aria-invalid={hasError(`subCharge${index}`, error)}
													onFocus={(e) => {
														removeError(e, setError)
													}}
												/>
												<span className='form-item__error'>
													{error && error[`subCharge${index}`]
														? t(error[`subCharge${index}`], { ns: 'errors' })
														: ''}
												</span>
											</div>
											<div className='col-6 col-lg-2 form-item'>
												<Dropdown
													id={`subChargePaymentType${index}`}
													value={
														awbRateLineDetails.otherCharges[0]
															.prepaidCollectIndicator
													}
													options={PAYMENT_TYPES_SELECT_ITEMS}
													label={t('CAPTURE_AWB.PAY_TYPE')}
													isUpdate={true}
													disabled={index !== 0}
													onChange={(e) => {
														let otherCharges = awbRateLineDetails.otherCharges
														otherCharges[0].prepaidCollectIndicator =
															e.target.value
														awbRateLineDetails.otherCharges = otherCharges
														setAwbDetails((prevState) => ({
															...prevState,
															awbRateLineDetails: awbRateLineDetails,
														}))
														validateRequired(
															e.target.value,
															setError,
															`subChargePaymentType`,
															t('CAPTURE_AWB.PAY_TYPE')
														)
													}}
													onBlur={(e) => {
														validateRequired(
															e.target.value,
															setError,
															`subChargePaymentType`,
															t('CAPTURE_AWB.PAY_TYPE')
														)
													}}
													ariaInvalid={
														index === 0
															? hasError(`subChargePaymentType`, error)
															: false
													}
													onFocus={(e) => {
														setError((prevState) => ({
															...prevState,
															subChargePaymentType: '',
														}))
													}}
												></Dropdown>
												{index === 0 && (
													<span className='form-item__error'>
														{error && error[`subChargePaymentType`]
															? t(error[`subChargePaymentType`], {
																	ns: 'errors',
															  })
															: ''}
													</span>
												)}
											</div>
											<div className='col-6 col-lg-2 form-item'>
												<Dropdown
													id={`dueParty${index}`}
													value={surcharge.chargeEntitlementCodeParty}
													options={CHARGE_ENTITLEMENT_PARTY_CODES}
													label={t('CAPTURE_AWB.DUE_PARTY')}
													onChange={(e) => {
														let otherCharges = awbRateLineDetails.otherCharges
														otherCharges[index].chargeEntitlementCodeParty =
															e.target.value
														awbRateLineDetails.otherCharges = otherCharges
														setAwbDetails((prevState) => ({
															...prevState,
															awbRateLineDetails: awbRateLineDetails,
														}))
														validateRequired(
															e.target.value,
															setError,
															`dueParty${index}`,
															t('CAPTURE_AWB.DUE_PARTY')
														)
													}}
													onBlur={(e) => {
														validateRequired(
															e.target.value,
															setError,
															`dueParty${index}`,
															t('CAPTURE_AWB.DUE_PARTY')
														)
													}}
													ariaInvalid={hasError(`dueParty${index}`, error)}
													onFocus={(e) => {
														removeError(e, setError)
													}}
												></Dropdown>
												<span className='form-item__error'>
													{error && error[`dueParty${index}`]
														? t(error[`dueParty${index}`], { ns: 'errors' })
														: ''}
												</span>
											</div>
											{awbRateLineDetails.otherCharges.length > 1 && (
												<div className='col-12 col-lg-1'>
													<button className='delete-button'>
														<i className='icon'>
															<img
																src={deleteIcon}
																alt='Delete'
																title='Delete'
																onClick={(e) => handleDeleteAction(e, index)}
															/>
														</i>
													</button>
												</div>
											)}
										</div>
									))
								) : (
									<div className='form-row'></div>
								)}
							</div>
							<div className='form-row'>
								<div className='col-12 mb-4 mt-2'>
									<button
										className='link'
										style={{ background: 'transparent' }}
										onClick={handleAddSurcharge}
									>
										<img
											src={plusIcon}
											alt='Add surcharge'
											title='Add surcharge'
											className='pr-3'
										/>
										<span className='pt-1'>{t('COMMON.ADD_CHARGE_DTLS')}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
				{!ammendRate && (
					<div className='form-row mb-3 mt-3'>
						<div className='col-12 col-md-8'>
							<button
								className='link'
								style={{ background: 'transparent' }}
								onClick={handleRateAmmend}
							>
								{ammendRate ? t('CAPTURE_AWB.AMMEND') : t('COMMON.DTLS')}
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default RatesDetails
