import React from 'react'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
const PrintBookingDialog = ({
	showCharges,
	setShowCharges,
	displayPrintConfirm,
	setDisplayPrintConfirm,
	printDetails,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const onHide = () => {
		setDisplayPrintConfirm(false)
		setShowCharges(false)
	}

	const handlePrint = () => {
		printDetails()
		onHide()
	}

	const RenderFooter = () => {
		return (
			<div>
				<button type='button' className='button primary' onClick={handlePrint}>
					{t('COMMON.PRINT')}
				</button>
			</div>
		)
	}

	return (
		<div className='cargo-portal-container dialog-container'>
			<div className='portal-dialog p-fluid'>
				<Dialog
					header=''
					visible={displayPrintConfirm}
					className = 'dialogWrapper'
					onHide={onHide}
				>
					<div>
						<h3 className='mt-1 mb-3'>{t('COMMON.PRINT_DETAILS')}</h3>
						<p>{t('COMMON.PRINT_TXT')}</p>
						
						<div className='form-row mt-3'>
							<div className='col-12 col-md-12'>
								<div
									className='form-item form-item--checkbox'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='showCharges'
										checked={showCharges}
										value='All'
										type='checkbox'
										name='showCharges'
										className='form-item__field'
										onChange={(e) => setShowCharges(e.target.checked)}
									/>
									<label
										htmlFor='showCharges'
										className='form-item__label label-text'
									>
										{t('COMMON.INCLUDE_CHARGES')}
									</label>
								</div>
							</div>
						</div> 
						<div className='form-row mt-4 mb-2'>
							<div className='col-12 align-center'>
								<button type='button' className='button primary' onClick={handlePrint}>
									{t('COMMON.PRINT')}
								</button>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	)
}

export default PrintBookingDialog
