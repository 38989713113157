import React, { useState, useEffect } from 'react'
import moment from 'moment'
import rightArrow from '../../../assets/images/icon-right-arrow.png'
import leftArrow from '../../../assets/images/icon-left-arrow.png'
import PaginatorComponent from '../../Pagination/PaginatorComponent'
import { getAllotmentColorClass } from './allotmentUtils'
import { useTranslation } from 'react-i18next'
import Slider from "react-slick";
const AllotmentList = ({ allotmentValType, allotmentList, setIsLoading }) => {
	const { t } = useTranslation(['labels', 'errors'])
	const [translateX, setTranslateX] = useState(0)
	const [index, setIndex] = useState(0)

	const [pageIndex, setPageIndex] = useState(1)
	const [currentPage, setCurrentPage] = useState(0)
	const [startIndex, setStartIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(0)
	const [totalRecordCount, setTotalRecordCount] = useState(0)

	const [customWidth, setcustomWidth]   = useState(window.innerWidth);
	const [disableButton, setDisableButton] = useState(false);

	const rowCount = 5

	const today = moment()

	const updateDimensions = () => {
		setcustomWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	useEffect(() => {
		setTranslateX(0)
		setIndex(0)
	}, [customWidth])
	
	useEffect(() => {
		onPageIndexChange(pageIndex)
	})
	useEffect(() => {
		if(customWidth > 768) {
			if(index === 7) {
				setDisableButton(true)
			} else {
				setDisableButton(false)
			}
		} else {
			if(index === 11) {
				setDisableButton(true)
			} else {
				setDisableButton(false)
			}
		}
	}, [index])

	const handleNext = () => {
		if (index === 0) {
			if(customWidth <= 768) {
				setTranslateX(translateX - 33.33)
			} else {
				setTranslateX(translateX - 13)
			}
		} else {
			if(customWidth <= 768) {
				setTranslateX(translateX - 33.33)
			} else {
				setTranslateX(translateX - 13)
			}
		}
		setIndex(index + 1)
	}

	const handlePrev = () => {
		if (index === 1) {
			if(customWidth <= 768) {
				setTranslateX(translateX + 33.33)
			} else {
				setTranslateX(translateX + 13)
			}
		} else {
			if(customWidth <= 768) {
				setTranslateX(translateX + 33.33)
			} else {
				setTranslateX(translateX + 13)
			}
		}
		setIndex(index - 1)
	}

	const calendarStyles = {
		transform: `translateX(${translateX}%)`,
	}

	const getCalendarDate = (index) => {
		var date = moment(today).add(index, 'days')
		return date.format('DD MMM')
	}

	const setDates = () => {
		let cards = []

		for (let i = 0; i < 14; i++) {
			cards.push(getCalendarDate(i))
		}
		return cards
	}

	const getAllotmentsForDates = (index, item, allotmentId, altIndex) => {
		let cards = []

		for (let i = index; i < 14; i++) {
			if (
				item &&
				item.flightDepartureDate &&
				moment(item.flightDepartureDate).format('DD MMM') === getCalendarDate(i)
			) {
				cards.push(
					<div className='table__body-td calendar-col'>
						{allotmentValType && allotmentValType === 'allotment-actual' ? (
							item.allotmentPercentVolume &&
							item.allotmentPercentVolume > item.allotmentPercentWeight ? (
								<span
									className={`${getAllotmentColorClass(
										item.allotmentPercentVolume
									)} ${getActiveColClassFn(i)}`}
								>
									{item.bookedVolume} CBM
								</span>
							) : (
								<span
									className={`${getAllotmentColorClass(
										item.allotmentPercentWeight
									)} ${getActiveColClassFn(i)}`}
								>
									{item.bookedWeight} Kg
								</span>
							)
						) : item.allotmentPercentVolume &&
						  item.allotmentPercentVolume > item.allotmentPercentWeight ? (
							<span
								className={`${getAllotmentColorClass(
									item.allotmentPercentVolume
								)} ${getActiveColClassFn(i)}`}
							>
								{item.allotmentPercentVolume}%
							</span>
						) : (
							<span
								className={`${getAllotmentColorClass(
									item.allotmentPercentWeight
								)} ${getActiveColClassFn(i)}`}
							>
								{item.allotmentPercentWeight}%
							</span>
						)}
					</div>
				)
				break
			} else if (
				(index !== 0 &&
					allotmentList[altIndex]?.allotments[index - 1] &&
					allotmentList[altIndex]?.allotments[index - 1].flightDepartureDate &&
					moment(
						allotmentList[altIndex].allotments[index - 1].flightDepartureDate
					).format('DD MMM') !== getCalendarDate(i) &&
					moment(
						allotmentList[altIndex].allotments[index - 1].flightDepartureDate
					)
						.add(1, 'days')
						.format('DD MMM') !==
						moment(item.flightDepartureDate).format('DD MMM')) ||
				index === 0
			) {
				cards.push(
					<div className='table__body-td calendar-col'>
						<span className={`${getActiveColClassFn(i)}`}></span>
					</div>
				)
			}
		}
		return cards
	}

	const [calendarDates, setCalendarDates] = useState(setDates())

	const onPageIndexChange = (newPageIndex, currentPage) => {
		setPageIndex(newPageIndex)
		setCurrentPage(currentPage)
		setStartIndex((newPageIndex - 1) * rowCount)
		if(customWidth <=992) {
			setEndIndex(allotmentList.length)
		} else {
			setEndIndex((newPageIndex - 1) * rowCount + rowCount)
		}
	}

	const getActiveColClass = (itemIndex) => {
		return itemIndex >= index && itemIndex < index + 7 ? 'active' : 'inactive'
	}

	const getActiveColClassFn = (itemIndex) => {
		return itemIndex > index && itemIndex < index + 7 ? 'active' : 'inactive'
	}

	function SampleNextArrow(props) {
		const { className, style, onClick } = props;
		return (
		  <img
			src={rightArrow}
			className={className}
			alt='Previous'
			onClick={onClick}
		/>
		);
	  }
	  
	  function SamplePrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<img src={leftArrow} className={className} alt='Next' onClick={onClick} />
		);
	  }
	  
	const settings = {
		className: "center",
		infinite: false,
		centerPadding: "60px",
		slidesToShow: 3,
		swipeToSlide: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		afterChange: function(index) {
		  console.log(
			`Slider Changed to: ${index + 1}, background: #222; color: #bada55`
		  );
		}
	  };
	return (
		<div className='allotments'>
			<div className='table-container'>
				<div className='row'>
					<div className='col-12 col-lg-6'>
						<div className='allotment-table'>
							<div className='table-header row allotment-row d-none d-lg-flex'>
								<div className='table__body-th col-md-4'>
									{t('DASHBOARD.ALLMNT_ID')}
								</div>
								<div className='table__body-th col-md-3'>
									{t('DASHBOARD.ORG_DEST_ALLOTMENT')}
								</div>
								<div className='table__body-th col-md-3'>
									{t('DASHBOARD.FLT')}
								</div>
								<div className='table__body-th col-md-2'>
									{t('DASHBOARD.UTLS')}
								</div>
							</div>
							{allotmentList && allotmentList.length ? (
								allotmentList
									.slice(startIndex, endIndex)
									.map((allotment, index) => (
										<div className='allotment-row_mobile'>
											<div
												className='table-row row allotment-row'
												id={`allotment-${index}`}
											>
												<div className='table__body-td col-lg-4'>
													<span className='d-inline-block d-lg-none allotment-header_mobile'>{t('DASHBOARD.ALLMNT_ID')}&nbsp;</span>
													{allotment.allotmentId}
												</div>
												<div className='table__body-td col-lg-3'>
												<span className='d-inline-block d-lg-none allotment-header_mobile'>{t('DASHBOARD.ORG_DEST_ALLOTMENT')}&nbsp;</span>
													{allotment.origin} - {allotment.destination}
												</div>
												<div className='table__body-td col-lg-3'>
												<span className='d-inline-block d-lg-none allotment-header_mobile'>{t('DASHBOARD.FLT')}&nbsp;</span>
													{allotment.flightCarrierCode} {allotment.flightNumber}
												</div>
												<div className='table__body-td col-lg-2'>
												<span className='d-inline-block d-lg-none allotment-header_mobile'>{t('DASHBOARD.UTLS')}&nbsp;</span>
													{allotment.totalVolumePercent &&
													allotment.totalVolumePercent >
														allotment.totalWeightPercent ? (
														<span
															className={getAllotmentColorClass(
																allotment.totalVolumePercent
															)}
														>
															{allotment.totalVolumePercent}%
														</span>
													) : (
														<span
															className={getAllotmentColorClass(
																allotment.totalWeightPercent
															)}
														>
															{allotment.totalWeightPercent}%
														</span>
													)}
												</div>
											</div>
											
											<div className='pt-2 pl-4 pr-4 d-block d-lg-none'>
												<Slider {...settings}>
													
												{calendarDates && calendarDates.length ? (
																			calendarDates.map((calendarDate, index) => (
												<>
												<div className='table__body-th calendar-col'>
													{calendarDate}
												</div>	
												{allotment.allotments &&
																allotment.allotments.length ? (
																	allotment.allotments.map((item, itemIndex) =>
																		item.flightDepartureDate &&
																		moment(item.flightDepartureDate).format(
																			'DD MMM'
																		) === getCalendarDate(itemIndex) ? (
																	<div>

																	{index == itemIndex ? (
																		<div className='calendar-col'>
																			{allotmentValType &&
																			allotmentValType === 'allotment-actual' ? (
																				item.allotmentPercentVolume &&
																				item.allotmentPercentVolume >
																					item.allotmentPercentWeight ? (
																					<span
																						className={`${getAllotmentColorClass(
																							item.allotmentPercentVolume
																						)} ${getActiveColClass(itemIndex)}`}
																					>
																						{item.bookedVolume} CBM
																					</span>
																				) : (
																					<span
																						className={`${getAllotmentColorClass(
																							item.allotmentPercentWeight
																						)} ${getActiveColClass(itemIndex)}`}
																					>
																						{item.bookedWeight} Kg
																					</span>
																				)
																			) : item.allotmentPercentVolume &&
																				item.allotmentPercentVolume >
																					item.allotmentPercentWeight ? (
																				<span
																					className={`${getAllotmentColorClass(
																						item.allotmentPercentVolume
																					)} ${getActiveColClass(itemIndex)}`}
																				>
																					{item.allotmentPercentVolume}%
																				</span>
																			) : (
																				<span
																					className={`${getAllotmentColorClass(
																						item.allotmentPercentWeight
																					)} ${getActiveColClass(itemIndex)}`}
																				>
																					{item.allotmentPercentWeight}%
																				</span>
																			)}
																		</div>
																	) : (<></>)}

																	</div>
																		) : (
																			<React.Fragment>
																				{getAllotmentsForDates(
																					itemIndex,
																					item,
																					allotment.allotmentId,
																					index
																				).map((altmnt, index) => altmnt)}
																			</React.Fragment>
																		)
																	)
																) : 
													(
														<></>
													)}
													
													</>
													))
													) : (
														<></>
													)}
												</Slider>
											</div>
										</div>
									))
							) : (
								<></>
							)}
						</div>
					</div>
					
					<div className='d-none d-lg-block col-12 col-lg-6 custom-pl-r-0 custom-pr-r-0'>
						<div className='calendar-wrapper'>
							<div className='calendar-data'>
								<button
									className='previous-btn'
									onClick={handlePrev}
									disabled={index === 0}
								>
									<img
										src={leftArrow}
										className='icon-wrapper'
										alt='Previous'
									/>
								</button>
								<button
									className='next-btn'
									onClick={handleNext}
									disabled={disableButton}
								>
									<img src={rightArrow} className='icon-wrapper' alt='Next' />
								</button>
								<div className='allotment-calendar' style={calendarStyles}>
									<div className='table-header calendar-row'>
										{calendarDates && calendarDates.length ? (
											calendarDates.map((calendarDate, index) => (
												<div className='table__body-th calendar-col'>
													{calendarDate}
												</div>
											))
										) : (
											<></>
										)}
									</div>
									{allotmentList && allotmentList.length ? (
										allotmentList
											.slice(startIndex, endIndex)
											.map((allotment, altIndex) => (
												<div className='calendar-body table-row calendar-row'>
													{allotment.allotments &&
													allotment.allotments.length ? (
														allotment.allotments.map((item, itemIndex) =>
															item.flightDepartureDate &&
															moment(item.flightDepartureDate).format(
																'DD MMM'
															) === getCalendarDate(itemIndex) ? (
																<div className='table__body-td calendar-col'>
																	{allotmentValType &&
																	allotmentValType === 'allotment-actual' ? (
																		item.allotmentPercentVolume &&
																		item.allotmentPercentVolume >
																			item.allotmentPercentWeight ? (
																			<span
																				className={`${getAllotmentColorClass(
																					item.allotmentPercentVolume
																				)} ${getActiveColClass(itemIndex)}`}
																			>
																				{item.bookedVolume} CBM
																			</span>
																		) : (
																			<span
																				className={`${getAllotmentColorClass(
																					item.allotmentPercentWeight
																				)} ${getActiveColClass(itemIndex)}`}
																			>
																				{item.bookedWeight} Kg
																			</span>
																		)
																	) : item.allotmentPercentVolume &&
																	  item.allotmentPercentVolume >
																			item.allotmentPercentWeight ? (
																		<span
																			className={`${getAllotmentColorClass(
																				item.allotmentPercentVolume
																			)} ${getActiveColClass(itemIndex)}`}
																		>
																			{item.allotmentPercentVolume}%
																		</span>
																	) : (
																		<span
																			className={`${getAllotmentColorClass(
																				item.allotmentPercentWeight
																			)} ${getActiveColClass(itemIndex)}`}
																		>
																			{item.allotmentPercentWeight}%
																		</span>
																	)}
																</div>
															) : (
																<React.Fragment>
																	{getAllotmentsForDates(
																		itemIndex,
																		item,
																		allotment.allotmentId,
																		altIndex
																	).map((altmnt, index) => altmnt)}
																</React.Fragment>
															)
														)
													) : (
														<></>
													)}
												</div>
											))
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{allotmentList && allotmentList.length !== 0 && (
					<div className='d-none d-lg-block paginationWrapper'>
						<PaginatorComponent
							currentPage={currentPage}
							pageIndex={pageIndex}
							rowCount={rowCount}
							totalRecords={allotmentList.length}
							onPageIndexChange={onPageIndexChange}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default AllotmentList
