import React, { useState } from 'react'
import './flight-rotations.scss'

import { t } from 'i18next';
import '../../../services/scheduleService'
import AirportAutocomplete from '../../AirportAutocomplete'
import moment from 'moment'
import { AUTOCOMPLETE_TYPE } from '../../../constants'
const FlightRotations = ({
  flightRotations,
  showMore,
  handleBack,
  handleSelection,
  handleSwap,
  setSelectedCount,
  selectedCount,
  handleProcessSchedules,
  weekData,
  handleFlightRotationsSearch,
  handleFlightsLoading,
}) => {

  const [selectedOrigin, setSelectedOrigin] = useState(null)
  const [selectedDestination, setSelectedDestination] = useState(null)

  const handleSetError = (key, text) => {

  }

  const clearError = (key) => {

  }

  const draftCallback = () => { }



  const handleSubmit = () => {
    setSelectedDestination(null)
    setSelectedOrigin(null)
    handleProcessSchedules()
  }


  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='row mb-5'>

            <div
              className={`col-12 col-lg-3 mb-md-3 mb-4 form-item form-item--text`}
              data-module='molecules/form-item/FormItem'
            >
              <label className='form-item__label'>{t('COMMON.DEST')}</label>
              <AirportAutocomplete
                autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
                selectedOrigin={selectedOrigin}
                selectedDestination={selectedDestination}
                setSelectedDestination={setSelectedDestination}
                handleSetError={handleSetError}
                clearError={clearError}
                draftCallback={draftCallback}
                screen='MANAGE_BOOKING'
              />

            </div>
            <div className='col-3 mt-1'>
              <button className='button mt-4' onClick={(e) => handleFlightRotationsSearch(selectedDestination)}>Search</button>
            </div>
            <div className='col-6'>
              <div className='selected-week float-right mt-5'>
                Selected week:  {weekData &&
                  <span>{weekData.fromDate} to {weekData.toDate}</span>
                }
              </div>
            </div>
          </div>
          <div className='row main-table-heading'>
            <div className='col-6'>
              <h2>Outbound</h2>
            </div>
            <div className='col-6'>
              <h2>Inbound</h2>
            </div>
          </div>
          <div className='row flights-rotation flight-header sticky-top'>
            <div className='col-1 start-col'>
              Flight date
            </div>
            <div className='col-1'>
              Flight No.
            </div>
            <div className='col-1'>
              Legs
            </div>
            <div className='col-1 small-col'>
              Departure time
            </div>
            <div className='col-1 small-col'>
              Arrival time
            </div>
            <div className='col-1 end-col'>
              Aircraft
            </div>
            {/*<<div className='col-1 end-col'>
              Service type
  </div>*/}
            <div className='col-1 start-col'>
              Flight date
            </div>
            <div className='col-1'>
              Flight No.
            </div>
            <div className='col-1'>
              Legs
            </div>
            <div className='col-1 small-col'>
              Departure time
            </div>
            <div className='col-1 small-col'>
              Arrival time
            </div>
            <div className='col-1 end-col'>
              Aircraft
            </div>
            {/*<div className='col-1 end-col'>
              Service type
  </div>*/}
          </div>
          {
            flightRotations && flightRotations.map((flight, index) => (
              <div className='row flights-rotation'>
                <div className='col-12' id={`outbound-${flight.id}`}>
                  <div className='row flight-row'>
                    <div className='col-1 start-col'>
                      {moment(flight.flightDate).format('DD-MMM-YYYY')}
                    </div>
                    <div className='col-1'>
                      EY {flight.flightNumber}
                    </div>
                    <div className='col-1'>
                      {flight.legs}
                    </div>
                    <div className='col-1 small-col'>
                      {flight.depTime}
                    </div>
                    <div className='col-1 small-col'>
                      {flight.arrivalTime} {flight.arrivalDate ? ' +1' : ''}
                    </div>
                    <div className='col-1 end-col'>
                      {flight.aircraftType}
                    </div>
                    {/*<div className='col-1 end-col'>
                      {flight.servicetype}
            </div>*/}
                    <div className='col-6'>
                      <div className='row'>

                        {flight && flight.flightInbounds && flight.flightInbounds.map((flightInbound, outBoundindex) => (
                          <>
                            <div className='col-2 start-col col-flight-date'>
                              <div className='row'>

                                <div
                                  className='form-item form-item--checkbox col-2'
                                  data-module='molecules/form-item/FormItem'
                                >
                                  {outBoundindex === 0 &&
                                    <>
                                      <input
                                        id={`select-inbound-${index}-${outBoundindex}`}
                                        checked={flight.checked}
                                        value={flightInbound.id}
                                        disabled={!flight.checked && selectedCount && selectedCount === 2}
                                        type='checkbox'
                                        name={`select-inbound-${index}-${outBoundindex}`}
                                        className='form-item__field'
                                        onChange={(e) => {
                                          handleSelection(index, e)
                                          if (e.target.checked) {
                                            setSelectedCount(selectedCount + 1)
                                          } else {
                                            setSelectedCount(selectedCount - 1)
                                          }
                                        }}
                                      />
                                      <label htmlFor={`select-inbound-${index}-${outBoundindex}`} className='form-item__label'>
                                      </label>
                                      <span className='form-item__error'></span>
                                    </>
                                  }
                                </div>
                                <div className='col-10'>
                                  {moment(flightInbound.flightDate).format('DD-MMM-YYYY')}
                                </div>
                              </div>
                            </div>
                            <div className='col-2'>
                             EY {flightInbound.flightNumber}
                            </div>
                            <div className='col-2'>
                              {flightInbound.legs}
                            </div>
                            <div className='col-2 small-col'>
                              {flightInbound.depTime}
                            </div>
                            <div className='col-2 small-col'>
                              {flightInbound.arrivalTime} {flightInbound.arrivalDate ? '  +1' : ''}
                            </div>
                            <div className='col-2 end-col'>
                              {flightInbound.aircraftType}
                            </div>
                            {/*<div className='col-1 end-col'>
                        {flightInbound.servicetype}
                  </div>*/}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {
            flightRotations && flightRotations.length === 0 &&
            <div className='row text-center pt-4'>
              <div className='col-12'>No records found</div>
            </div>
          }
        </div>
      </div>
      {showMore &&
        <div className='row mt-5'>
          <div className='col col-2 mx-auto'>
            <button className='button' onClick={handleFlightsLoading}>
              Show more
            </button>
          </div>
        </div>
      }
      <div className='row mt-5'>
        <div className='col col-2'>
          <button className='button' onClick={handleSubmit}>
            {t('COMMON.CONT')}
          </button>
        </div>
        <div className='col col-2'>
          <button className='button secondary' onClick={handleBack}>
            {t('COMMON.BACK_BUTTON')}
          </button>
        </div>
      </div>
      <div className='float-menu'>
        <div className='col-1 button-row'>
          <div className='row'>
            <button className='scroll-btn col-12' title='Scroll to top' onClick={(e) => window.scrollTo(0, 0)}></button>
          </div>
          <div className='row'>
            <button className='swap-btn col-12' disabled={selectedCount !== 2} onClick={handleSwap} title='Swap'></button>
          </div>
          <div className='row'>
                <button className='scroll-btn down col-12' title='Scroll to bottom' onClick={(e) => window.scrollTo(0, document.body.scrollHeight)}></button>
          </div>
        </div>
      </div>
    </>
  )
}



export default FlightRotations
