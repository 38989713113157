import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { TIMEOUT_TIME } from '../constants'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'
import {
	getBookingListRequestBody,
	getSimilarBookingsRequestBody,
} from '../utils/RequestBodyUtils'

export const getBookingListResponse = async (bookingListRequest) => {
	const data = getBookingListRequestBody(bookingListRequest)
	const url = `${envConfigs.baseUrl}${envConfigs.getBookingListUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const getSimilarBookingResponse = async (origin) => {
	const data = getSimilarBookingsRequestBody(origin)
	const url = `${envConfigs.baseUrl}${envConfigs.getBookingListUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const getProducts = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getProductsUrl}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getProductsMaster = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getProductsMasterUrl}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getProductBySystemName = async (productName) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getProductBySystemNameUrl}/${productName}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}


export const cancelBooking = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.cancelBookingUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}
