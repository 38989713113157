import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
const SearchPanelMessage = ({selectedTemplate,listTemplateNamesResponse }) => {
	const { t, i18n } = useTranslation(['labels','errors']);
	return (
		<>
		{ listTemplateNamesResponse?.templateList?.length == 0 && selectedTemplate =='' ?
		<div>
			<p style={{ fontSize:"17px"}}>{t('COMMON.NO_TEMPLATE_MESSAGE')}
				<p><a href="/booking/search" style={{color:"#005F96"}}>{t('COMMON.NEW_BKG')}</a></p>
			</p>	
		</div>:''}
		</ >
	);
	
}

export default SearchPanelMessage
