import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {	
	WEIGHT_UNIT,
	SERVICES,
	SHIPPING_TYPE,
	DIMENSION_UNIT,
	getInitLooseItemDetail,
	getInitULDItemDetail,
} from '../../../constants'
import siteLogo from '../../../assets/images/logo.png'
import flightIcon from '../../../assets/images/cargo_flight.svg'
import flightIconFilled from '../../../assets/images/Freighter_Grey.svg'
import downArrow from '../../../assets/images/down-arrow.png'
import upArrow from '../../../assets/images/up-arrow.png'
import truckIcon from '../../../assets/images/cargo_truck.svg'
import plusIcon from '../../../assets/images/plus.svg'
import minusIcon from '../../../assets/images/minus.svg'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
	setToSessionStorage,
	getBase64Encoded,
} from '../../../utils/StorageUtils'
import { getBookingFlightDetails, removeDuplicates } from '../../SearchPanel/templateUtils'
import '../../FlightDetailsList/flight-details-list.scss';
import fastTrack from '../../../assets/images/fasttrack.png';

const FlightItem = ({ destinationAirport, selectedOrigin, selectedDestination, flightDate }) => {
	const [isExpanded, setExpanded] = useState(false)
	const { t } = useTranslation(['labels']);

	useEffect(() => {
		setExpanded(false)
	}, [destinationAirport])
	let i18nLang = localStorage.getItem('i18nextLng');
	moment.updateLocale( i18nLang.toString() , {
		weekdays : [ t('COMMON.SUN'), t('COMMON.MON'), t('COMMON.TUE'), t('COMMON.WED'), t('COMMON.THU'), t('COMMON.FRI'), t('COMMON.SAT')
		],
		weekdaysShort : [
			t('COMMON.SUN_SHORT'), t('COMMON.MON_SHORT'), t('COMMON.TUE_SHORT'), t('COMMON.WED_SHORT'), t('COMMON.THU_SHORT'), t('COMMON.FRI_SHORT'), t('COMMON.SAT_SHORT')
		]
	});
	const departureTime = moment(destinationAirport.departureDateTime)
	const departureDate = moment(destinationAirport.departureDateTime,'DD-MMM-YYYY')
	const arrivalTime = moment(destinationAirport.arrivalDateTime)
	const arrivalDate = moment(destinationAirport.arrivalDateTime, 'DD-MMM-YYYY')
	const origin = destinationAirport.origin
	const destination = destinationAirport.destination
	let routes = destinationAirport.flightSegmentList
		? destinationAirport.flightSegmentList.length - 1
		: 0

	let dateFromFlightResponse = destinationAirport?.flightSegmentList?.map((segment) => {
		return segment?.flightDate
	});

	const history = useHistory()

	const getFormatedTime = (value) => {
		let formattedTime = ''
		if (value) {
			let tempArray = value.split(':')
			formattedTime =
				parseInt(tempArray[0], 10) +
				'Hrs ' +
				(tempArray[1] && tempArray[1] != '00' ? tempArray[1] + ' Min' : '')
		}
		return formattedTime
	}

	//Pharma Life && VAC specific state values...
	const [additionalInfoData, setAdditionalInfoData] = useState({
		type: null,
		pharma: {},
	})

	const handleEnquiryBooking = async (e, flightNumber, segmentArray) => {
		e.preventDefault();
		const bookedFlightDetails = await getBookingFlightDetails(
			null,
			flightDate
		)

		let formData = {
			origin: selectedOrigin,
			destination: selectedDestination,
			orginalShipingDate: moment(flightDate,'DD-MMM-YYYY').format('DD-MMM-YYYY'),
			shippingDate:  moment(flightDate,'DD-MMM-YYYY').format('DD-MMM-YYYY'),
			arrivalDate: null,
			shippingItemCode: null,
			shippingType: SHIPPING_TYPE.LOOSE,
			weightUnit: WEIGHT_UNIT.KILOGRAM,
			dimensionUnit: DIMENSION_UNIT.CENTIMETERS,
			shipmentSccs: [],
			appliedSccs: [],
			additionalSccs: [],
			appliedServices: [],
			sccCodes: removeDuplicates([]),
			totalWeight: null,
			totalVolumeInMtrs: 0,
			totalWeightInKg: 0,
			actualGrossWeight: 0,
			shippingType: SHIPPING_TYPE.LOOSE,
			calculatedTotalWeight: 0,
			actualTotalIneItemWeight: 0,
			actualVolume: 0,
			actualChargeableWeight: 0,
			totalPieces: 0,
			looseDetails: [getInitLooseItemDetail()],
			isAllStackable: true,
			isAllTiltable: true,
			ULDDetails: [getInitULDItemDetail()],
			chargeableWeight: 0,
			mixLooseDetails: [getInitLooseItemDetail()],
			expandAccordian: false,
			priceCode: null,
			grossWeightInSelectedUnit: 0,
			grossWeightInConvertedUnit: 0,
			densityInSelectedUnit: 0,
			densityInConvertedUnit: 0,
			chargeableWeightInSelectedUnit: 0,
			additionalInfo: additionalInfoData,
			currency: '',
			bookingFlightDetails: bookedFlightDetails,
			isModifyBooking: false,
			awbNo: '',
		}
		let flightNumberValCombination = segmentArray?.map(segment => {
			return segment?.flightNumber
		})
		let flightDateValCombination = segmentArray?.map(segment => {
			return segment?.flightDate
		})
		let flightParameterObj = {
			flightNumberVal: flightNumber && flightNumberValCombination,
			flightDateVal: flightDate && flightDateValCombination
		}
		setToSessionStorage('flightEnquiryObj', getBase64Encoded(formData))
		setToSessionStorage('flightParameterObj', getBase64Encoded(flightParameterObj))
		history.push('/booking/search');
	}

	return (
		<>
			{dateFromFlightResponse[0]?.includes(moment(flightDate,'DD-MMM-YYYY')?.format('DD-MMM-YYYY')) &&
				<div className='flight-details__wrap'>
					<div
						className={`flight-details__item ${isExpanded ? 'borderB0 border-expand' : ''
							}`}
						onClick={() => setExpanded(!isExpanded)}
					>
						<div className='flight-details__left'>
							<div className='logo d-none d-lg-block'>
								<img src={siteLogo} alt='Etihad' title='Etihad' />
							</div>

							<div className='flight-details__route'>
								{routes ? (
								// 	<span className='flight-details__routes'>
								// 		<span>{routes} Stop</span>
								// 	</span>
								// ) : (
								// 	<></>
								<span className='flight-details__routes'>
									<span className='flight-details__routes-dep d-inline-block d-lg-none'>{departureTime.format('HH:mm')}</span>
									<span>{routes} {t('COMMON.STOP')}</span>
									<span className='flight-details__routes-arr d-inline-block d-lg-none'>{arrivalTime.format('HH:mm')}</span>
								</span>
							) : (
								<span className='flight-details__routes'>
									<span className='flight-details__routes-dep d-inline-block d-lg-none'>{departureTime.format('HH:mm')}</span>
									<span></span>
									<span className='flight-details__routes-arr d-inline-block d-lg-none'>{arrivalTime.format('HH:mm')}</span>
								</span>
								)}
								<ul className='flight-details__timing'>
									<li className='flight-details__departure'>
										<strong className='d-none d-lg-inline-block'>{departureTime.format('HH:mm')}</strong>
										<span>{departureDate.format('ddd DD MMM')}</span>
										<span>{origin}</span>
									</li>

									<div className='flight-details__connection'>
										<div className='origin-destination-icon'></div>
										<div className='transit-line' />
										<div className='iconsSection'>
											<i>
												{destinationAirport.flightSegmentList[0].flightType == 'C' &&
												<span className ='freighter-icon-list-span'>
													<img
														src={flightIconFilled}
														alt='Flight'
														title='Flight'
													/>

												</span>
												}
												{destinationAirport.flightSegmentList[0].flightType == 'T' &&
													<img
														src={truckIcon}
														alt='Flight'
														title='Flight'
													/>}
												{destinationAirport.flightSegmentList[0].flightType == 'ET' &&
													<img
														src={truckIcon}
														alt='Flight'
														title='Flight'
													/>}
													{destinationAirport.flightSegmentList[0].flightType == 'IT' &&
													<img
														src={truckIcon}
														alt='Flight'
														title='Flight'
													/>}
												{destinationAirport.flightSegmentList[0].flightType == 'CO' &&
													<img
														src={flightIcon}
														alt='Flight'
														title='Flight'
													/>}
											</i>
										</div>
										<div className='transit-line' />
										<div className='origin-destination-icon'></div>
									</div>

									<li className='flight-details__arrival'>
										<strong className='d-none d-lg-inline-block'>{arrivalTime.format('HH:mm')}</strong>
										<span>{arrivalDate.format('ddd DD MMM')}</span>
										<span>{destination}</span>
									</li>
								</ul>
								<span className='flight-details__elapse' id="flight-label">
									{getFormatedTime(destinationAirport.cumulativeJourneyTime)}
								</span>
							</div>
						</div>
						<div className='flight-details__right'>
							<div className='flight-details__right__service'>
								{destinationAirport.service &&
									destinationAirport.service === SERVICES.FAST_TRACK ? (
									<img src={fastTrack} alt='fastTrack' title='fastTrack' />
								) : (
									<div></div>
								)}
							</div>
							<div className='flight-details__right__sub'>
								<div className='flight-details__right__sub__pricing'>
								</div>
								<button type='button' className='l-btn l-btn--primary d-none d-lg-block' 
								onClick={(e) => handleEnquiryBooking(e, destinationAirport?.flightSegmentList[0]?.flightNumber, destinationAirport?.flightSegmentList)}>
									{t('COMMON.BOOK')}
								</button>
								<div class='flight-details__arrowUp d-none d-lg-block'>
									<i>
										{isExpanded ? (
											<img src={minusIcon} alt='Flight' title='Flight' />
										) : (
											<img src={plusIcon} alt='Flight' title='Flight' />
										)}
									</i>
								</div>
							<div class='flight-details__arrowUp d-block d-lg-none'>
							<i>
								{isExpanded ? (
									<img src={upArrow} alt='Flight' title='Flight' />
								) : (
									<img src={downArrow} alt='Flight' title='Flight' />
								)}
							</i>
						</div>
							</div>
							{isExpanded ? (<></>) : (<button
								type='button'
								className='l-btn l-btn--primary d-block d-lg-none flight-details_choose'
								onClick={(e) => handleEnquiryBooking(e, destinationAirport?.flightSegmentList[0]?.flightNumber, destinationAirport?.flightSegmentList)}
							>
								Book
							</button>)}
							
							
						</div>
					</div>
					{isExpanded ? (
						<div
							className={`flight-details__expanded col-md-12 p-l-0 p-r-0 ${isExpanded ? 'border-expand borderT0' : ''
								}`}
						>
							<div class='flight-details__expandedDetails'>
								<div className='flight-details__segment-info'>
									{destinationAirport.flightSegmentList.length ? (
										destinationAirport.flightSegmentList.map((segment, index) => {
											return (
												<>
													<div className='flight-details__routeDetails'>
														<div className='flight-details__flightNo'>
															<div>
																{segment.flightCarrierCode}
																{segment.flightNumber}
															</div>
															<div>{segment.aircraftType}{segment.aircraftClassification && <> | {segment.aircraftClassification}</>}</div>
														</div>
														<div className='flight-details__route'>
															<div className='flight-details__timing'>
																<div className='flight-details__departure'>
																	<strong>
																		{moment(segment.departureDateTime).format(
																			'HH:mm'
																		)}
																	</strong>
																	<span>
																		{moment(segment.departureDateTime, 'DD-MMM-YYYY').format(
																			'ddd DD MMM'
																		)}
																	</span>
																	<span>{segment.segmentOrigin}</span>
																	{/* <span class="flight-details__elapse">LAT : </span>*/}
																</div>
																<div className='flight-details__connection'>
																	<div className='origin-destination-icon'></div>
																	<div className='transit-line' />
																	<div className='iconsSection'>
																		<i>
																			{segment.flightType == 'C' &&
																			<span className ='freighterSpan'>
																				<img
																					src={flightIconFilled}
																					alt='Flight'
																					title='Flight'
																				/>
																			</span>
																		}
																			{segment.flightType == 'T' &&
																				<img
																					src={truckIcon}
																					alt='Flight'
																					title='Flight'
																				/>}
																			{segment.flightType == 'ET' &&
																				<img
																					src={truckIcon}
																					alt='Flight'
																					title='Flight'
																				/>}
																				{segment.flightType == 'IT' &&
																				<img
																					src={truckIcon}
																					alt='Flight'
																					title='Flight'
																				/>}
																			{segment.flightType == 'CO' &&
																				<img
																					src={flightIcon}
																					alt='Flight'
																					title='Flight'
																				/>}
																		</i>
																	</div>
																	<div className='transit-line' />
																	<div className='origin-destination-icon'></div>
																</div>

																<div className='flight-details__arrival'>
																	<strong>
																		{moment(segment.arrivalDateTime).format(
																			'HH:mm'
																		)}
																	</strong>
																	<span>
																		{moment(segment.arrivalDateTime, 'DD-MMM-YYYY').format(
																			'ddd DD MMM'
																		)}
																	</span>
																	<span>{segment.segmentDestination}</span>
																</div>
															</div>
															<span className='flight-details__elapse'>
																{getFormatedTime(segment.flyingHours)}
															</span>
														</div>
														

													</div>
													{segment.segmentDestination != destination && (
														<div className='flight-details__layover'>
															<div className='segment-transit-line' />
															<span>
																{segment.layoverTime} Hrs in{' '}
																{segment.segmentDestination}
															</span>
															<div className='segment-transit-line' />
														</div>
													)}
												</>
											)
										})
									) : (
										<></>
									)}
								</div>
								<div className='mt-4 d-block d-lg-none segmentInfo-Separator'>
														{isExpanded ? (<button
																type='button'
																className='mt-4 l-btn l-btn--primary d-block d-lg-none flight-details_choose'
																onClick={(e) => handleEnquiryBooking(e, destinationAirport?.flightSegmentList[0]?.flightNumber, destinationAirport?.flightSegmentList)}
															>
																Book
														</button>) : (<></>)}
													</div>
								<div className='flight-details__others d-flex col-md-6  col-12'>
									{/* {destinationAirport.flightSegmentList.length ? (
										destinationAirport.flightSegmentList.map((segment, index) => {
											return (
												index == 0 && (
													<>
														<div className='time-details'>
															<div className='other-value'>
																<div className='chargeable-weight'>
																	LAT <span>(Last acceptance time) </span>
																</div>
																<div className='other-value'>
																	{moment(
																		destinationAirport.lastAcceptanceTime
																	).format('ddd, DD MMM')}{' '}
																	<span className='time-field'>
																		{moment(
																			destinationAirport.lastAcceptanceTime
																		).format('HH:mm')}
																	</span>
																</div>
															</div>
															<div className='other-value'>
																<div className='chargeable-weight'>
																	TOA <span>(Time of availability) </span>
																</div>
																<div className='other-value'>
																	{moment(
																		destinationAirport.earliestDeliveryTime
																	).format('ddd, DD MMM')}{' '}
																	<span className='time-field'>
																		{moment(
																			destinationAirport.earliestDeliveryTime
																		).format('HH:mm')}
																	</span>
																</div>
															</div>
														</div>
														<div className='weight-details'>
															<div className='chargeable-weight'>
																Chargeable weight
															</div>
															<div className='other-value'>
																{getRoundedValue(searchFormData.chargeableWeight)}
																{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
																{'s'}
															</div>
														</div>
													</>
												)
											)
										})
									) : (
										<></>
									)} */}
								</div>
								
								{/*<div className='flight-details__right'>
						<div className='flight-details__info'>
							{destinationAirport.product &&
							destinationAirport.product === PRODUCTS.TEMP_CHECK ? (
								<img src={tempCheck} alt='tempCheck' title='tempCheck' />
							) : (
								<></>
							)}
							{destinationAirport.product &&
							destinationAirport.product === PRODUCTS.FAST_TRACK ? (
								<img src={fastTrack} alt='fastTrack' title='fastTrack' />
							) : (
								<></>
							)}
						</div>
					</div> */}
							</div>
						</div>
					) : (
						<></>
					)}
					
					
				</div>}
		</>
	)
}

export default FlightItem