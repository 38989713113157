import React from 'react'
import { connect } from 'react-redux'
import envConfigs from '../../configs/envConfigs'
import Login from './Login'
import { Redirect } from 'react-router-dom'
const LoginContainer = ({ principal }) => {
	return envConfigs.appLoginUrl && envConfigs.appLoginUrl.length ? (
		principal ? (
			<Redirect to='/' />
		) : (
			window.location.replace(envConfigs.appLoginUrl)
		)
	) : (
		<Login principal={principal} />
	)
}

const mapStateToProps = ({ auth }) => ({
	principal: auth.principal,
})

export default connect(mapStateToProps)(LoginContainer)
