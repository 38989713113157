import { all } from 'redux-saga/effects'
import Sagas from './Sagas'
import FlightEnquirySagas from './FlightEnquirySagas'
import UserSagas from './UserSagas'
import ManageTemplateSaga from './ManageTemplateSaga'

export default function* rootSaga() {
	yield all([
		Sagas.watchQuoteResponseSaga(),
		Sagas.watchSaveQuoteResponseSaga(),
		Sagas.watchGetBookingSaga(),
		FlightEnquirySagas.watchFlightEnquirySaga(),
		UserSagas.watchGetUsers(),
		Sagas.watchGetUserProfileSaga(),
		Sagas.watchSaveTemplateSaga(),
		Sagas.watchListTemplateNamesSaga(),
		Sagas.watchSaveDraftSaga(),
		Sagas.watchListDraftsSaga(),
		Sagas.watchListQuotesSaga(),
		ManageTemplateSaga.watchGetTemplates(),
		Sagas.watchUpdateBookingResponseSaga(),
		Sagas.watchValidateBookingResponseSaga(),
		Sagas.watchSaveCommodity(),
		Sagas.watchSaveAirportMaster(),
		Sagas.watchSaveServiceMaster(),
		Sagas.watchSaveProductMaster(),
		Sagas.watchSaveSccMaster(),
		Sagas.watchSaveTermsMaster(),
		Sagas.watchSavePivotInfoMaster(),
		Sagas.watchSaveUldTypeMaster(),
		Sagas.watchSaveSccIncompatibleMaster(),
		Sagas.watchSaveStationScheduleMaster(),
		Sagas.watchsaveRegionMaster(),
		Sagas.watchsaveCountryMaster(),
		Sagas.watchGetAviBookingDetails(),
		Sagas.watchSaveEmbargoMaster(),
		Sagas.watchSeasonalBookingListSaga(),
	])
}
