import { call, put, takeEvery } from 'redux-saga/effects'
import { ERROR_MESSAGE } from '../../constants'
import { getListUserResponse } from '../../services/userService'
import { listUserSuccess, listUserError } from '../actions/userListActions'
import { LIST_USER } from '../constants'

function* getUsers({ userSearchData }) {
	try {
		const response = yield call(getListUserResponse, userSearchData)
		yield put(listUserSuccess(response))
	} catch (error) {
		const errorObj = {
			handledError: false,
			description: ERROR_MESSAGE.REQUEST_TIMEOUT,
			type: 'Error',
		}
		yield put(listUserError(errorObj))
	}
}

function* watchGetUsers() {
	yield takeEvery(LIST_USER.LOAD, getUsers)
}

export default {
	getUsers,
	watchGetUsers,
}
