import { LIST_TEMPLATE } from '../constants'

export const loadListTemplate = (templateRequest) => ({
	type: LIST_TEMPLATE.LOAD,
	templateRequest,
})
export const listTemplateSuccess = (response) => ({
	type: LIST_TEMPLATE.SUCCESS,
	response,
})
export const listTemplateError = (error) => ({
	type: LIST_TEMPLATE.ERROR,
	error,
})
