import React, { useState, useEffect } from 'react'
import ListBooking from './ListBooking'
import { useHistory } from 'react-router-dom'
import './manage-booking.scss'
import { Alert } from '../../common/Alert'
import BookingMenu from '../BookingMenu'
import MESSAGES from '../././../constants/Messages'
import { useTranslation } from 'react-i18next'
const ManageBooking = () => {
	const [alertMessage, setAlertMessage] = useState('')
	const [status, setStatus] = useState(null)
	const [retainFormData, setRetainFormData] = useState(null)
	const history = useHistory()

	const { t, i18n } = useTranslation(['labels', 'errors'])

	useEffect(() => {
		if (history.location.state && history.location.state.modifyBooking) {
			const modifyBooking = history.location.state.modifyBooking
			setStatus(modifyBooking.status)
			if (modifyBooking.status && modifyBooking.status === true) {
				setAlertMessage(
					t(MESSAGES.MODIFY_BOOKING_SUCCESS, {
						AWB: modifyBooking.awbNo,
						status: modifyBooking.bookingStatus,
						ns: 'errors',
					})
				)
			} else {
				setAlertMessage(
					t('ER219', {
						awbNo: modifyBooking.awbNo,
						message: modifyBooking.message ? modifyBooking.message : '',
						ns: 'errors',
					})
				)
			}
			window.scrollTo(0, 0)

			let state = { ...history.location.state }
			delete state.modifyBooking
			history.replace({ ...history.location, state })
		}
	}, [history])

	const handleAlertClose = () => {
		setStatus(null)
		setAlertMessage('')
	}

	useEffect(() => {
		if (
			history.location.state &&
			history.location.state.message &&
			history.location.state.success
		) {
			const message = history.location.state.message
			const status = history.location.state.success

			setAlertMessage(message)
			setStatus(status)

			let state = { ...history.location.state }
			delete state.message
			delete state.success
			history.replace({ ...history.location, state })
			window.scrollTo(0, 0)
		}
	}, [history])

	useEffect(() => {
		if (history.location.state && history.location.state.retainFormData) {
			const retainFormData = history.location.state.retainFormData
			setRetainFormData(retainFormData)
			let state = { ...history.location.state }
			delete state.retainFormData

			history.replace({ ...history.location, state })
			window.scrollTo(0, 0)
		} else {
			setRetainFormData(false)
		}
	}, [history])

	return (
		<div className='row'>
			<div className='col-lg-12'>
				<div className='manage-booking__list'>
					<BookingMenu activeItem={1} />
					{alertMessage !== '' && status !== null && (
						<Alert
							isError={!status}
							showAlert={true}
							message={t(alertMessage, { ns: 'errors' })}
							setTimeout={true}
							onAlertClose={handleAlertClose}
						/>
					)}
					<ListBooking retainFormData={retainFormData} />
				</div>
			</div>
		</div>
	)
}

export default ManageBooking
