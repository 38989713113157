import React, { useState } from 'react'
import './index.scss'
import trackingIcon from '../../../assets/images/icon-route-map.svg'
import envConfigs from '../../../configs/envConfigs'
import { useTranslation } from 'react-i18next'
import Messages from '../../../constants/Messages'
const ShipmentTracking = ({
	pushEventDashboardBookingandTracking
}
) => {
	const { t } = useTranslation(['labels', 'errors'])

	const [airwayBill, setAirwayBill] = useState('')
	const [error, setError] = useState(null)

	const { parentUrl } = envConfigs

	const handleTrack = () => {
		const status = validateAwb()
		pushEventDashboardBookingandTracking("Track")
		if (status) {
			window.open(
				`${
					parentUrl ? parentUrl : 'https://www.etihadcargo.com'
				}/en/e-services/shipment-tracking?awb=${airwayBill}`,
				'_blank'
			)
			}
	}
	const handleOnFocus = () => {
		if (error) {
			setError(null)
		}
	}

	const validateAwb = () => {
		if (airwayBill.trim() === '') {
			setError(Messages.EMPTY_AWB_NUMBER)
			return false
		} else if (
			airwayBill.trim().length !== 8 &&
			airwayBill.trim().length !== 11
		) {
			setError(t('ER234', { ns: 'errors' }))
			return false
		} else {
			return true
		}
	}

	return (
		<div className='shipment-tracking-panel'>
			<h3 className='section-sub-heading mt-0 '>{t('DASHBOARD.SHIP_TRACK')}</h3>
			<img src={trackingIcon} alt='New booking' className='mb-4 '
				style={{ height: "72px", width: "72px" }} />
			<div
				className='form-item form-item--text'
				data-module='molecules/form-item/FormItem'
			>
				<input
					id='awbNumber'
					type='text'
					className='form-item__field'
					maxLength='11'
					aria-invalid={error !== null}
					onFocus={handleOnFocus}
					onBlur={(e) => {
						validateAwb()
					}}
					onChange={(e) => {
						setAirwayBill(e.target.value)
						
					}}
					placeholder='Airway bill number'
				/>
				<span className='form-item__error'>
					{error && error !== '' ? t(error, { ns: 'errors' }) : ''}
				</span>
			</div>
			<button type='button' className='button' onClick={handleTrack}>
				{t('COMMON.TRACK')}
			</button>
		</div>
	)
}

export default ShipmentTracking
