import { SEARCH_FORM } from '../constants'

const initState = {
	searchFormData: null,
}

export default (state = initState, action) => {
	switch (action.type) {
		case SEARCH_FORM.SET_FORM_DATA:
			return {
				...state,
				searchFormData: action.searchFormData,
			}
		default:
			return state
	}
}
