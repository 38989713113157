import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import MESSAGES from '../../../constants/Messages'
import LoadingIndicator from '../../LoadingIndicator'
const BookingDetailListMobile = lazy(() =>
	import('./BookingDetailListMobile.js')
)
const BookingListMain = ({
	getBookingDetails,
	bookingSearchFormData,
	listBookingResponse,
	listBookingError,
	setNotificationMessage,
	setNotificationType,
	initialSearch,
	setInitialSearch,
	isLoading,
	setIsLoading,
}) => {
	//FOR MULTILINGUAL
	const [t] = useTranslation(['labels', 'errors'])
	const [bookingList, setBookingList] = useState([])
	const [totalRecordCount, setTotalRecordCount] = useState(0)

	const [hasMore, setHasMore] = useState(true)
	const [page, setPage] = useState(1)

	useEffect(() => {
		if (listBookingResponse) {
			if (initialSearch) {
				setBookingList(listBookingResponse.bookingList)
				setHasMore(true)
			} else {
				setBookingList(() => [
					...bookingList,
					...listBookingResponse.bookingList,
				])
			}
			if (listBookingResponse.totalRecordCount !== 0) {
				setIsLoading(false)
				setTotalRecordCount(listBookingResponse.totalRecordCount)
			}
		}
	}, [listBookingResponse])

	const loadMoreItems = () => {
		if (bookingList.length >= totalRecordCount) {
			setHasMore(false)
			return
		}
		const nextPage = page + 1
		getBookingDetails(bookingSearchFormData, nextPage, null)
		setPage(nextPage)
		setInitialSearch(false)
	}

	return (
		<div id='BookingDetailsList' className='mt-3 mt-lg-5 mb-3'>
			<div className='form-row'>
				<div className='col-12'>
					<h3 className='page-sub-head'> {t('COMMON.BOOKING LIST')}</h3>
				</div>
			</div>

			{listBookingResponse && totalRecordCount > 0 && (
				<div className='form-row mb-4 booking-details__total-results-display'>
					<div className='col-12'>
						{totalRecordCount}
						{''} {totalRecordCount > 1 ? t('COMMON.BOOKINGS') : ''}
						{totalRecordCount === 1 && t('COMMON.BKG_HEAD')}
					</div>
				</div>
			)}

			{listBookingResponse && bookingList.length === 0 && (
				<div className='no-data ml-0 mr-0'>
					{' '}
					{t(MESSAGES.NO_BOOKINGS, { ns: 'errors' })}
				</div>
			)}

			{!listBookingResponse &&
				!listBookingError &&
				(initialSearch || initialSearch == null) && (
					<div className='no-data m-0'>
						{t(MESSAGES.LOADING, { ns: 'errors' })}
					</div>
				)}

			{listBookingError && (
				<div className='no-data m-0'>
					{t(MESSAGES.PROCESS_REQUEST_FAILED, { ns: 'errors' })}
				</div>
			)}
			<div id='scrollableDiv'>
				<InfiniteScroll
					dataLength={bookingList.length}
					next={loadMoreItems}
					hasMore={hasMore}
					loader={
						!initialSearch && initialSearch != null ? (
							<LoadingIndicator />
						) : (
							<></>
						)
					}
					scrollThreshold={0.98}
				>
					{bookingList.length > 0 &&
						!isLoading &&
						bookingList.map((result, index) => {
							return (
								<Suspense fallback={<></>}>
									{result && (
										<BookingDetailListMobile
											index={index}
											result={result}
											setNotificationMessage={setNotificationMessage}
											setNotificationType={setNotificationType}
										/>
									)}
								</Suspense>
							)
						})}
				</InfiniteScroll>
			</div>
		</div>
	)
}

export default BookingListMain
