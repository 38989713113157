import { SAVE_PIVOT_INFO_MASTER } from '../constants'

const initState = {
	request: null,
	response: null,
	error: null,
}

export default (state = initState, action) => {
	switch (action.type) {
		case SAVE_PIVOT_INFO_MASTER.LOAD:
			return {
				...state,
				request: action.payload,
				response: null,
				error: null,
			}
		case SAVE_PIVOT_INFO_MASTER.SUCCESS:
			return {
				...state,
				response: action.response,
			}
		case SAVE_PIVOT_INFO_MASTER.ERROR:
			return {
				...state,
				error: action.error,
			}
		default:
			return state
	}
}
