import React, { useState, useEffect } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { FORM_FIELDS } from '../../constants'
import './shipping-item-autocomplete.scss'
import restrictedSCCs from '../../configs/restrictedSCCs'
import MESSAGES from '../../constants/Messages'
import { useTranslation } from 'react-i18next'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage,
	setToLocalStorage,
} from '../../utils/StorageUtils'
const ShippingItemAutocomplete = ({
	fieldType,
	shippingItems,
	selectedShippingItem,
	setSelectedShippingItem,
	error,
	clearError,
	setDisableButton,
	setError,
	placeholder,
	setDisplayShippingItemPopup,
	draftCallback,
	disabled,
}) => {

	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [dgSccCode, setDgSccCode] = useState(JSON.parse(getFromSessionStorage('dgsccCode')))
	const [text, setText] = useState(null)
	const [filteredShippingItems, setFilteredShippingItems] = useState([])

	const myRef = React.createRef()

	useEffect(() => {
		setFilteredShippingItems([...shippingItems])
	}, [])

	const searchShippingItems = ({ query }) => {
		if (!query || !query.trim().length) {
			setFilteredShippingItems([...shippingItems])
			return
		}
		const filteredByCommodityStart =
			shippingItems && shippingItems.length
				? shippingItems.filter((shippingItem) => {
					return shippingItem.description
						.toLowerCase()
						.startsWith(query.toLowerCase())
				})
				: []
		const filteredByCommodityContains =
			shippingItems && shippingItems.length
				? shippingItems.filter((shippingItem) => {
					return shippingItem.description
						.toLowerCase()
						.includes(query.toLowerCase())
				})
				: []

		const filteredByProductStart =
			shippingItems && shippingItems.length
				? shippingItems.filter((shippingItem) => {
					return shippingItem.product
						.toLowerCase()
						.startsWith(query.toLowerCase())
				})
				: []
		const filteredByProductContains =
			shippingItems && shippingItems.length
				? shippingItems.filter((shippingItem) => {
					return shippingItem.product
						.toLowerCase()
						.includes(query.toLowerCase())
				})
				: []
		const filteredBySccContains =
			shippingItems && shippingItems.length
				? shippingItems.filter((shippingItem) => {
					return shippingItem.scc.toLowerCase().includes(query.toLowerCase())
				})
				: []
		let filteredShippingItems = [
			...filteredByCommodityStart,
			...filteredByCommodityContains,
			...filteredByProductStart,
			...filteredByProductContains,
			...filteredBySccContains,
		].filter(
			(item, index, self) =>
				index === self.findIndex((i) => i.description === item.description)
		)

		setFilteredShippingItems(filteredShippingItems)
	}

	const handleOnFocus = (e) => {
		clearError(FORM_FIELDS.SHIPPING_ITEM)
		if (!text && !selectedShippingItem && myRef.current) {
			myRef.current.onDropdownClick(e, '')
		}
	}

	const handleSelect = (e) => {
		const item = e.value
		if (item && restrictedSCCs.includes(item.scc)) {
			if ((item?.code !== 'DOG') && (item?.code !== 'CAT') && !(dgSccCode?.includes(item?.scc))) {
				setDisplayShippingItemPopup && setDisplayShippingItemPopup(true)
			} else {
				setDisplayShippingItemPopup && setDisplayShippingItemPopup(false)
			}
		}
		if ((item?.code === 'DOG') || (item?.code === 'CAT')) {
			setError({
				...error,
				[FORM_FIELDS.LOOSE_ITEMS]: [],
				[FORM_FIELDS.ULD_ITEMS]: [],
				[FORM_FIELDS.TOTAL_WEIGHT]: null,
			})
		} else {
			setError({
				...error,
				[FORM_FIELDS.CAGE_ITEMS]: null
			})
		}
		setSelectedShippingItem(e.value)
		draftCallback()
	}

	const itemTemplate = (item) => {
		return (
			<div className='autocomplete_item'>
				<div className='autocomplete_item_main'>{item.description}</div>
				{fieldType !== 'COMMODITY' ? (
					<div className='autocomplete_item_sub'>
						<div className='autocomplete_item_sub_item'>{item.product}</div>
						<div className='autocomplete_item_sub_item'>{item.scc}</div>
					</div>
				) : (
					<></>
				)}
			</div>
		)
	}

	return (
		<>
			<AutoComplete
				id={FORM_FIELDS.SHIPPING_ITEM}
				name={FORM_FIELDS.SHIPPING_ITEM}
				AutoComplete='false'
				value={
					selectedShippingItem && selectedShippingItem.key
						? selectedShippingItem.description
						: text
				}
				dropdown
				minLength={1}
				ref={myRef}
				suggestions={filteredShippingItems}
				completeMethod={searchShippingItems}
				disabled={disabled ? true : false}
				onChange={(e) => {
					setDisableButton && setDisableButton(false)
					setSelectedShippingItem(null)
					setText(e.value)
				}}
				onSelect={handleSelect}
				onFocus={(e) => {
					handleOnFocus(e)
				}}
				onClick={({ target }) => {
					target.setSelectionRange(0, target.value.length)
				}}
				onBlur={(e) => {
					if (filteredShippingItems && filteredShippingItems.length === 1) {
						const item = filteredShippingItems[0]
						if (item && restrictedSCCs.includes(item.scc)) {

							if ((item?.code !== 'DOG') && (item?.code !== 'CAT') && !(dgSccCode?.includes(item?.scc))) {
								setDisplayShippingItemPopup && setDisplayShippingItemPopup(true)
							} else {
								setDisplayShippingItemPopup && setDisplayShippingItemPopup(false)
							}
						}
						setSelectedShippingItem(filteredShippingItems[0])
						setText(null)
						return
					}
					if (!selectedShippingItem) {
						error
							? setError({
								...error,
								[FORM_FIELDS.SHIPPING_ITEM]: MESSAGES.INVALID_SHIPPING_ITEM,
							})

							: setError({
								[FORM_FIELDS.SHIPPING_ITEM]: MESSAGES.INVALID_SHIPPING_ITEM,
							})
					}
					setText(null)
				}}
				onDropdownClick={() => {
					setText(null)
					const filteredShippingItems =
						shippingItems && shippingItems.length
							? shippingItems.sort((a, b) =>
								a.description > b.description
									? 1
									: b.description > a.description
										? -1
										: 0
							)
							: [...shippingItems]
					setFilteredShippingItems([...filteredShippingItems])
				}}
				className={'form-control autocomplete'}
				placeholder={t(placeholder)}
				itemTemplate={itemTemplate}
			/>
			{selectedShippingItem && fieldType !== 'COMMODITY' ? (
				<div
					className='autocomplete_item_sub selected-item_sub'
				// className={`autocomplete_item_sub selected-item_sub ${
				// 	displayDropdown ? '' : 'no-dropdown'
				// }`}
				>
					<div className='autocomplete_item_sub_item selected-item_sub_item'>
						{selectedShippingItem.product}
					</div>
					<div className='autocomplete_item_sub_item selected-item_sub_item'>
						{selectedShippingItem.scc}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)
}

export default ShippingItemAutocomplete
