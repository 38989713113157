import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'
import getCurrentUser from '../utils/getCurrentUser'

let userDetails = getCurrentUser();

export const getPermanentBookingFileList = async () => {
	let userDetails = getCurrentUser();
	const data = {
		requestId: userDetails?.userId,
		agentCode: userDetails?.agentCode,
	}
	const url = `${envConfigs.baseUrl}${envConfigs.getPermanentBookingFileList}`
	//const url = `${`http://localhost:3001`}${envConfigs.getPermanentBookingFileList}`
	//const url = `${`http://13.126.80.20/api/api`}${envConfigs.getPermanentBookingFileList}`
	const response = await portalAuthHttpClient.post(url, data)
	if(response?.data){
		return response.data
	}
	
}

export const getUserConfig = async () => {
	let userDetails = getCurrentUser();
	const data = {
		agentCode: userDetails?.agentCode,
	}
	const url = `${envConfigs.baseUrl}${envConfigs.getUserConfig}`
	//const url = `${`http://localhost:3001`}${envConfigs.getPermanentBookingFileList}`
	//const url = `${`http://13.126.80.20/api/api`}${envConfigs.getPermanentBookingFileList}`
	const response = await portalAuthHttpClient.post(url, data)
	return response?.data
}

export const getPermanentBookingsForFile = async (fileName) => {
	let userDetails = getCurrentUser();
	const data = {
			requestId: userDetails.userId,
			agentCode: userDetails.agentCode,
			fileName:fileName,
		}
	
	const url = `${envConfigs.baseUrl}${envConfigs.getPermanentBookingsForFile}`
	//const url = `${`http://localhost:3001`}${envConfigs.getPermanentBookingsForFile}?filename=${fileName}`
	//const url = `${envConfigs.baseUrl}${envConfigs.getPermanentBookingsForFile}?filename=${fileName}`
	//const url = `${`http://13.126.80.20/api/api`}${envConfigs.getPermanentBookingsForFile}`
	
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const registerPermanentbooking = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.registerPermanentbooking}`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const reprocessPermanentbooking = async (data) => {
	//const url = `${`http://13.126.80.20/api/api`}${envConfigs.reprocessPermanentbooking}`
	const url = `${envConfigs.baseUrl}${envConfigs.reprocessPermanentbooking}`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const saveSeasonalBooking = async (data) => {
	//const url = `${`http://13.126.80.20/api/api`}${envConfigs.reprocessPermanentbooking}`
	const url = `${envConfigs.baseUrl}${envConfigs.saveSeasonalBooking}`
	const response = await portalAuthHttpClient.post(url, data)
	return response
}

export const getSeasonalBookingList = async (payload) => {
	//const url = `${`http://13.126.80.20/api/api`}${envConfigs.reprocessPermanentbooking}`
	const url = `${envConfigs.baseUrl}${envConfigs.getSeasonalBookingList}`
	const response = await portalAuthHttpClient.post(url,payload)
	return response
}



