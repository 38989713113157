import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import PermanentBookingFileDropDown from './PermanentBookingFileDropDown'
import PermanentBookingFilter from './PermanentBookingFilter';
import PermanentBookingItem from './PermanentBookingItem'
import PermanentBookingListHeader from './PermanentBookingListHeader';

const PermanentBookingList = React.forwardRef(({
    fileList,
    flag,
    setListPageOpen,
    fileName,
    setFileName,
    bookingList,
    setBookingList,
    setRequest,
    setEditButtonActive,
    isEditButtonActive,
    clearToken,
    isRefreshActive,
}, ref) => {

    const [bookingFilter, setBookingFilter] = useState({
        confirmed: true,
        queued: true,
        tobeProcessed: true,
        failed: true,
    })

    const [sortedArray, setSortedArray] = useState([])

    useEffect(() => {
        if (bookingList && bookingList?.bookingList?.length) {
            sortArrayFun(bookingList)
        }
    }, [bookingList])

    // sort array based on date function
    const sortArrayFun = (bookingList) => {
        const reqarray = bookingList?.bookingList.map((item) => {
            JSON.parse(item.requestParameters)
            return item
        })


        const arraySorted = reqarray?.sort((a, b) => {

            const aValue = JSON.parse(a?.requestParameters)
            const bValue = JSON.parse(b?.requestParameters)

            const dateA = aValue?.shipmentDetails?.shippingDate ? new Date(aValue?.shipmentDetails?.shippingDate.split('-').reverse().join(' ')) : new Date();
            const dateB = bValue?.shipmentDetails?.shippingDate ? new Date(bValue?.shipmentDetails?.shippingDate.split('-').reverse().join(' ')) : new Date();
            return dateA - dateB;
        })
        setSortedArray(arraySorted)

    }


    return (<>

        <div className="row">
            <div className="col">
                <div className='dropdownUi'>
                    <PermanentBookingFileDropDown
                        fileList={fileList}
                        setFileName={setFileName}
                        bookingFilter={bookingFilter}
                        setListPageOpen={setListPageOpen}
                        fileName={fileName}
                        clearToken={clearToken}
                        isRefreshActive={isRefreshActive}
                    />
                </div>
            </div>
            {(bookingList?.bookingList?.length !== 0) ?
                <div className="col">
                    <div className='filterUi'>
                        <PermanentBookingFilter
                            bookingFilter={bookingFilter}
                            setBookingFilter={setBookingFilter}
                        />
                    </div>
                </div> : <></>
            }
        </div>

        <div ref={ref}>
            <div id='para'>
                <p className='heading'>{t('PERMANENT_BOOKING.FILE_NAME')}:</p>
                <p className='filename'>{fileName}</p><br />
                <p className='heading'>{t('COMMON.BOOKING LIST')}:</p>
            </div>
            <table class="table table-hover" >

                <thead>
                    <tr>
                        {
                            (bookingList?.bookingList ?
                                <PermanentBookingListHeader
                                    bookingItem={bookingList?.bookingList}
                                /> : <></>
                            )
                        }
                        {

                            (bookingList && bookingList?.bookingList?.length) ?
                                (
                                    sortedArray
                                        .map((bookingItem, index) => (
                                            <>

                                                {/* ({{ const arraySorted = sortedArray.filter(getsortedItem => bookingList?.bookingList.some(sortedOne => sortedOne.bookingGroupId === getsortedItem.bookingId)) }})} */}
                                                <PermanentBookingItem
                                                    flag={flag}
                                                    key={index}
                                                    bookingItem={bookingItem}
                                                    fileName={fileName}
                                                    bookingFilter={bookingFilter}
                                                    setBookingList={setBookingList}
                                                    setAutoRefresh={setRequest}
                                                    setEditButtonActive={setEditButtonActive}
                                                    isEditButtonActive={isEditButtonActive}
                                                    clearToken={clearToken}


                                                />
                                            </>
                                        ))
                                ) :
                                (
                                    bookingList?.bookingList?.length === 0 ?
                                        <div className="mt-5" style={{ textAlign: "center" }}>{t('PERMANENT_BOOKING.NO_DATA')}</div> : <></>
                                )

                        }

                    </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </div>
    </>)
})

export default PermanentBookingList