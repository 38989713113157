import React from 'react'
import { Redirect } from 'react-router-dom'
import bannerImg from '../../assets/images/loginbanner.jpg'
import './login.scss'
import { Button } from 'primereact/button'
import envConfigs from '../../configs/envConfigs'

const Login = ({principal}) => {

	const oktaConfigs = envConfigs.oktaConfigs

	return principal ? (
		<Redirect to='/' />
	) : (
		<div className='login-container'>
			<div className='row col-12'>
				<div className='col-md-6 banner'>
					<div className='banner__item'>
						<img src={bannerImg} alt='Etihad' title='Etihad' />
					</div>
				</div>
				<div className='col-md-6 signin-widget-wrapper'>
					<div>
						<Button
							onClick={() =>
								window.location.replace(oktaConfigs.consumerSamlLink)
							}
						>
							Book now (Consumer)
						</Button>
						<Button
							onClick={() =>
								window.location.replace(oktaConfigs.enterpriseSamlLink)
							}
						>
							Book now (Enterprise)
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
