import axios from 'axios'
import { portalHttpClient, portalAuthHttpClient } from '../utils/HttpRequestUtils'
import envConfigs from '../configs/envConfigs'

export const getUnidInfo = async () => {
    const url = `${envConfigs.baseUrl}${envConfigs.getUnidInfo}`
    const response = await portalAuthHttpClient.get(url)
    return response?.data
}


export const savespecialcommodityattachment = async (data) => {
    const url = `${envConfigs.baseUrl}${envConfigs.savespecialcommodityattachment}`
    const response = await portalAuthHttpClient.post(url, data)
    return response.data
}



export const validateunid = async (data) => {
    const url = `${envConfigs.baseUrl}${envConfigs.validateunid}`
    const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const deletespecialcommodityattachment = async (data) => {
    const url = `${envConfigs.baseUrl}${envConfigs.deletespecialcommodityattachment}`
    const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

