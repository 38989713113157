import {VOLUME_UNIT_VALUES } from '../constants'

export default (volume, volumeUnit) => {
    let volumeInMeters
	if (volume) {
		if (volumeUnit == VOLUME_UNIT_VALUES.CUBIC_CENTIMETERS) {
			volumeInMeters=  (volume * (Math.pow(10,-6))).toFixed(2)
		} else if (volumeUnit == VOLUME_UNIT_VALUES.CUBIC_INCHES) {
			volumeInMeters = (volume * 1.6387 * (Math.pow(10,-5))).toFixed(2)
		} else {
			volumeInMeters = volume.toString()
        }
        return (volumeInMeters > 0.01 ? volumeInMeters :0.01)
	}
}
