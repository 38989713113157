import React from 'react'
import SearchResults from '../SearchResults'
import './quote-details.scss'

const QuoteDetails = () => {
	return (
		<>
			<div className='row'>
				<div className='col-12 pr-0 pl-0'>
					<div className='quote-details-page'>
						<div className='quote-details'>
							{/* <ModifySearch /> */}
							<SearchResults />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default QuoteDetails
