import React, { useEffect, useState } from 'react'
import PortalHeader from './PortalHeader'
import sessionKeys from '../../constants/sessionKeys'
import { getFromLocalStorage } from '../../utils/StorageUtils'


const PageHeading = ({ 
	principal,
	setAccessToken,
	setRefreshToken,
	isLoading,
}) => {

	const [ssoRefreshUrl, setSsoRefreshUrl] = useState(null)
	const [ssoType, setSsoType] = useState(null)
 
	useEffect(() => {
		if (!principal) {
			const accessTokenFromSession = getFromLocalStorage(sessionKeys.ACCESS_TOKEN)
			const refreshTokenFromSession = getFromLocalStorage(sessionKeys.REFRESH_TOKEN)
			if (accessTokenFromSession && accessTokenFromSession.length) {
				setAccessToken(accessTokenFromSession)
			}
			if (refreshTokenFromSession && refreshTokenFromSession.length) {
				setRefreshToken(refreshTokenFromSession)
			}
		}
	}, [])

	useEffect(() => {
		const ssoRefreshUrl = localStorage.getItem(sessionKeys.SSO_REFRESH_URL)
		const ssoOktaType = localStorage.getItem(sessionKeys.SSO_TYPE)
		if (ssoRefreshUrl) {
			setSsoRefreshUrl(ssoRefreshUrl)
		}
		if (ssoOktaType) {
			setSsoType(ssoOktaType)
		}
	}, [principal])
	
	return (!isLoading || principal) ? (
		<div>
			<PortalHeader
				userDetails={principal}
				ssoRefreshUrl={ssoRefreshUrl}
				ssoType={ssoType}
			/>
		</div>
	) : <div></div>
}

export default PageHeading
