import {
	alphaNumbericPattern,
	alphaPattern,
	decimalPattern,
	emailPattern,
	MILESTONES,
	numericPattern,
	phoneNumberPattern,
	PRIVILEGES,
	USER_PRIVLILEGES,
} from '../../constants'
import MESSAGES from '../../constants/Messages'
import sessionKeys from '../../constants/sessionKeys'
import { getUserProfile } from '../../services/userProfileService'
import getCurrentUser from '../../utils/getCurrentUser'

let setErrorFn = null

export const validateName = (name, key, setError, error) => {
	var fieldName = 'first name'
	let errorMessage = MESSAGES.USER_FIRST_NAME_MANDATORY
	if (key === 'lastName') {
		fieldName = 'last name'
		errorMessage = MESSAGES.USER_LAST_NAME_MANDATORY
	}
	if (name.trim() === '') {
		setError((prevState) => ({
			...prevState,
			[key]: errorMessage,
		}))
		return false
	} else if (!validateAlpha(name)) {
		setError((prevState) => ({
			...prevState,
			[key]: MESSAGES.ENTER_ALPHABETES,
		}))
		return false
	} else if (name.length > 20) {
		setError((prevState) => ({
			...prevState,
			[key]: `The ${fieldName} character limit is 30`,
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, [key]: '' }))
		//setError(deleteItem(error, key));
		return true
	}
}

export const validateUserId = async (userId, setError, error) => {
	setErrorFn = setError
	if (userId.trim() === '') {
		setError((prevState) => ({
			...prevState,
			userId: MESSAGES.MANDATORY_USER_ID,
		}))
		return false
	} else if (!validateAlphaNumeric(userId)) {
		setError((prevState) => ({
			...prevState,
			userId: MESSAGES.USER_ID_VALIDATION,
		}))
		return false
	} else {
		// setError(deleteItem(error, 'userId'));
		const isUserIdExist = await checkUserIdExist(userId)
		return !isUserIdExist
	}
}

export const validateEmailAddress = (
	emailId,
	confirmEmailAddress,
	setError,
	error
) => {
	if (emailId !== null && emailId.trim() === '') {
		setError((prevState) => ({
			...prevState,
			emailAddress: 'Please enter Email Address',
		}))
		return false
	} else if (!emailPattern.test(emailId)) {
		setError((prevState) => ({
			...prevState,
			emailAddress: 'Please enter valid Email Address',
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, emailAddress: '' }))
	}

	if (confirmEmailAddress !== null && confirmEmailAddress.trim() === '') {
		setError((prevState) => ({
			...prevState,
			confirmEmailAddress: 'Please confirm Email Address',
		}))
		return false
	} else if (confirmEmailAddress.trim() !== emailId.trim()) {
		setError((prevState) => ({
			...prevState,
			confirmEmailAddress: 'Please enter the same Email Address',
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, confirmEmailAddress: '' }))
		return true
	}
}

export const validateCreateUserEmailAddress = (emailId, setError, error) => {
	if (emailId !== null && emailId.trim() === '') {
		setError((prevState) => ({
			...prevState,
			emailAddress: MESSAGES.ENTER_EMAIL_ADDRESS,
		}))
		return false
	} else if (!emailPattern.test(emailId)) {
		setError((prevState) => ({
			...prevState,
			emailAddress: MESSAGES.ENTER_VALID_MAIL,
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, emailAddress: '' }))
		return true
	}
}

export const validatePhoneNumber = (
	phNumber,
	phContryCode,
	setError,
	error
) => {
	if (phNumber !== null && phNumber.trim() !== '') {
		if (!phoneNumberPattern.test(phNumber)) {
			setError((prevState) => ({
				...prevState,
				phoneNumber: MESSAGES.USER_VALID_TEL_NO,
			}))
			return false
		} else if (
			phoneNumberPattern.test(phNumber) &&
			phContryCode !== null &&
			phContryCode.trim() === ''
		) {
			setError((prevState) => ({
				...prevState,
				phContryCode: MESSAGES.USER_COUNTRY_CODE_MANDATORY,
			}))
			return false
		} else {
			setError((prevState) => ({
				...prevState,
				phoneNumber: '',
				phContryCode: '',
			}))
			return true
		}
	} else {
		setError((prevState) => ({
			...prevState,
			phoneNumber: '',
			phContryCode: '',
		}))
		return true
	}
}

export const validatePhoneCountryCode = (
	phContryCode,
	phoneNumber,
	setError
) => {
	if (
		(phContryCode === null || phContryCode.trim() === '') &&
		phoneNumber.trim() !== '' &&
		phoneNumber !== null
	) {
		setError((prevState) => ({
			...prevState,
			phContryCode: MESSAGES.USER_COUNTRY_CODE_MANDATORY,
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, phContryCode: '' }))
		return true
	}
}

export const validateMobileCountryCode = (
	mobContryCode,
	mobileNumber,
	setError
) => {
	if (
		(mobContryCode == null || mobContryCode.trim() === '') &&
		mobileNumber !== null &&
		mobileNumber.trim() !== ''
	) {
		setError((prevState) => ({
			...prevState,
			mobContryCode: MESSAGES.USER_COUNTRY_CODE_MANDATORY,
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, mobContryCode: '' }))
		return true
	}
}

export const validateMobileNumber = (
	mobNumber,
	mobContryCode,
	setError,
	error
) => {
	if (mobNumber !== null && mobNumber.trim() !== '') {
		if (!phoneNumberPattern.test(mobNumber)) {
			setError((prevState) => ({
				...prevState,
				mobileNumber: MESSAGES.USER_VALID_MOB_NO,
			}))
			return false
		} else if (
			phoneNumberPattern.test(mobNumber) &&
			mobContryCode !== null &&
			mobContryCode.trim() === ''
		) {
			setError((prevState) => ({
				...prevState,
				mobContryCode: MESSAGES.USER_COUNTRY_CODE_MANDATORY,
			}))
			return false
		} else {
			setError((prevState) => ({
				...prevState,
				mobileNumber: '',
				mobContryCode: '',
			}))
			return true
		}
	} else {
		setError((prevState) => ({
			...prevState,
			mobileNumber: '',
			mobContryCode: '',
		}))
		return true
		/*setError((prevState) => ({
			...prevState,
			mobileNumber: MESSAGES.USER_MOB_NO_MANDATORY,
		}))
		return false*/
	}
}

export const validateCity = (city, setError, error) => {
	if (city !== null && city.trim() === '') {
		setError((prevState) => ({ ...prevState, city: 'Please enter a city' }))
		return false
	} else {
		setError((prevState) => ({ ...prevState, city: '' }))
		return true
	}
}

export const validateCountry = (country, setError, error) => {
	if (country !== null && country.trim() === '') {
		setError((prevState) => ({
			...prevState,
			country: 'Please enter a country',
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, country: '' }))
		return true
	}
}

export const validatePostalCode = (postalCode, setError, error) => {
	if (postalCode !== null && postalCode.trim() === '') {
		setError((prevState) => ({
			...prevState,
			postalCode: 'Please enter a postal code',
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, postalCode: '' }))
		return true
	}
}

export const validateAddress = (address, setError, error) => {
	if (address !== null && address.trim() === '') {
		setError((prevState) => ({
			...prevState,
			address: 'Please enter a address',
		}))
		return false
	} else {
		setError((prevState) => ({ ...prevState, address: '' }))
		return true
	}
}

export const validateAlphaNumeric = (fieldValue) => {
	if (!alphaNumbericPattern.test(fieldValue)) {
		return false
	} else {
		return true
	}
}

export const validateAlpha = (fieldValue) => {
	if (!alphaPattern.test(fieldValue)) {
		return false
	} else {
		return true
	}
}

export const validatePhoneNuberPattern = (fieldValue) => {
	if (!phoneNumberPattern.test(fieldValue)) {
		return false
	} else {
		return true
	}
}

export const validateNumericPattern = (fieldValue) => {
	if (!numericPattern.test(fieldValue)) {
		return false
	} else {
		return true
	}
}

export const validateDecimalPattern = (fieldValue) => {
	if (!decimalPattern.test(fieldValue)) {
		return false
	} else {
		return true
	}
}

export const getUserId = () => {
	const userDetails = getCurrentUser()
	if (userDetails && userDetails.userId) {
		return userDetails.userId
	} else {
		return null
	}
}

export const getLoggedInUserProfile = () => {
	return localStorage.getItem(sessionKeys.USER_DETAILS)
		? JSON.parse(localStorage.getItem(sessionKeys.USER_DETAILS))
		: null
}

// export const getUserType = (userProfileResponse) => {
// 	if (userProfileResponse.roleGroup) {
// 		if (
// 			userProfileResponse.roleGroup === 'PORADMUSR' ||
// 			userProfileResponse.roleGroup === 'EYADMIN'
// 		) {
// 			return 'KU'
// 		} else {
// 			return 'EU'
// 		}
// 	} else {
// 		return ''
// 	}
// }

export const getAccountType = (userProfile) => {
	if (userProfile.userType) {
		if (userProfile.userType === 'KU' || userProfile.userType === 'PA') {
			return 'COMMON.ADMIN'
		} else {
			return 'COMMON.USER'
		}
	} else {
		return ''
	}
}

export const getPortalUserType = (userType) => {
	if (userType) {
		if (userType === 'PA') {
			return 'KU'
		} else {
			return userType
		}
	} else {
		return null
	}
}

export const getStatus = (response) => {
	if (response.status) {
		return 'A'
	} else {
		return 'I'
	}
}

export const getPrivileges = (roles, privileges, setPrivileges) => {
	setPrivileges({
		...privileges,
		bookingPrivilege: roles.includes(USER_PRIVLILEGES.BOOKING_PRIVILEGE),
		capturePrivilege: roles.includes(USER_PRIVLILEGES.CAPTURE_PRIVILEGE),
		dashBoardPrivilege: roles.includes(USER_PRIVLILEGES.DASHBOARD_PRIVILEGE),
		informaticsPrivilege: roles.includes(
			USER_PRIVLILEGES.REVENUE_INFOGRAPHICS_PRIVILEGE
		),
		allotmentPrivilege: roles.includes(USER_PRIVLILEGES.ALLOTMENT_PRIVILEGE),
	})
}

export const getRoles = (privileges) => {
	let role = []
	if (privileges.dashBoardPrivilege) {
		role.push(USER_PRIVLILEGES.DASHBOARD_PRIVILEGE)
	}
	if (privileges.bookingPrivilege) {
		role.push(USER_PRIVLILEGES.BOOKING_PRIVILEGE)
	}
	if (privileges.capturePrivilege) {
		role.push(USER_PRIVLILEGES.CAPTURE_PRIVILEGE)
	}
	if (privileges.informaticsPrivilege) {
		role.push(USER_PRIVLILEGES.REVENUE_INFOGRAPHICS_PRIVILEGE)
	}
	if (privileges.allotmentPrivilege) {
		role.push(USER_PRIVLILEGES.ALLOTMENT_PRIVILEGE)
	}
	return role
}

export const checkUserIdExist = (userId, setError) => {
	getUserProfile({ userCode: userId })
		.then((response) => {
			if (response && !response.errorDetails) {
				setErrorFn((prevState) => ({
					...prevState,
					userId: MESSAGES.USER_ID_ALREADY_EXISTS,
				}))
				return true
			}
		})
		.catch((error) => {
			console.log(error)
			setErrorFn((prevState) => ({
				...prevState,
				userId: ``,
			}))
			return true
		})
}

export const getPhoneNo = (phoneNumber) => {
	if (phoneNumber && phoneNumber.indexOf('+') !== -1) {
		const code = getCountryCode(phoneNumber)
		const res = phoneNumber.replace(code, '')
		return res
	} else {
		return ''
	}
}

export const getCountryCode = (phoneNumber) => {
	if (phoneNumber && phoneNumber.indexOf('+') !== -1) {
		const res = phoneNumber.split(' ')[0]
		return res
	} else {
		return ''
	}
}

export const handleMilestone = (
	event,
	notifications,
	setNotifications,
	setMilestones
) => {
	const key = event.target.id
	const isChecked = event.target.checked
	if (key === 'milestone1') {
		if (isChecked) {
			let notificationArray = []
			MILESTONES.map((milestone) => {
				notificationArray.push(milestone.label)
			})
			setNotifications(notificationArray)
		} else {
			setNotifications([])
		}
		MILESTONES.map((milestone) => {
			setMilestones((prevState) => ({
				...prevState,
				[milestone.id]: isChecked,
			}))
		})
	} else {
		if (isChecked) {
			setNotifications([...notifications, event.target.value])
		} else {
			setNotifications(
				notifications.filter((item) => item !== event.target.value)
			)
		}
		setMilestones((prevState) => ({
			...prevState,
			[key]: isChecked,
		}))
	}
}

export const addAllNotifications = (notifications) => {
	let milestonesArray = []
	MILESTONES.map((milestone) => milestonesArray.push(milestone.label))
	let checked = milestonesArray.every((v) => notifications.includes(v))
	return checked
}

export const addAllPrivileges = (privileges) => {
	if(privileges.bookingPrivilege &&
		privileges.capturePrivilege &&
		privileges.dashBoardPrivilege &&
		privileges.allotmentPrivilege &&
		privileges.informaticsPrivilege
	) {
		return true
	}
}

export const getLanguageCode = (language) => {
	if (language === 'zh_CN') {
		return 'zh'
	} else {
		return 'en'
	}
}
