import CustomAutoComplete from './CustomAutoComplete'
import React, { useEffect, useState, useRef } from 'react'

import './index.scss'
const CustomAutoCompleteRoot = ({
	draftList,
	templateList,
	quoteList,
	handleSelectedItem,
	selectedItem,
	setSelectedItem,
	populateQuoteDetailsInSummary,
	listQuoteError,
	listTemplateNamesError,
	listDraftsError,
	isSeasonalBooking,
	setInitialList
}) => {
	return (
		<div>
			<CustomAutoComplete
				templateList={templateList}
				quoteList={quoteList}
				draftList={draftList}
				handleSelectedItem={handleSelectedItem}
				selectedItem={selectedItem}
				setSelectedItem={setSelectedItem}
				populateQuoteDetailsInSummary={populateQuoteDetailsInSummary}
				listQuoteError={listQuoteError}
				listTemplateNamesError={listTemplateNamesError}
				listDraftsError={listDraftsError}
				isSeasonalBooking={isSeasonalBooking}
				setInitialList={setInitialList}
			/>
		</div>
	)
}

export default CustomAutoCompleteRoot
